import PropTypes from 'prop-types';

export default class Auth {

    static propType = () => PropTypes.shape({
        mode: PropTypes.oneOf(['DISABLED', 'BASIC', 'AUTH0']).isRequired,
        auth0: PropTypes.shape({
            domain: PropTypes.string.isRequired,
            clientId: PropTypes.string.isRequired,
            audience: PropTypes.string.isRequired
        })
    })

    constructor({ mode, auth0 }) {
        this.mode = mode;
        this.auth0 = auth0;
        this.enabled = mode !== 'DISABLED';
    }
}
