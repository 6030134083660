import { Component } from 'react';
import PropTypes from 'prop-types';
import { faEdit as editIcon } from '@fortawesome/pro-regular-svg-icons';

import { MAX_PRICE } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import Tooltip from 'component/Tooltip';
import CustomNumberInput from 'component/form/CustomNumberInput';

import './EditCarrierNegotiatedRate.scss';

export default class EditCarrierNegotiatedRate extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        laneCarrier: PropTypes.object.isRequired,
        actionDisabled: PropTypes.bool,
        editNegotiatedRate: PropTypes.func
    }

    static defaultProps = {
        actionDisabled: false,
        editNegotiatedRate: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onOpenEditNegotiatedRatePopup = this._onOpenEditNegotiatedRatePopup.bind(this);
        this._onCloseEditNegotiatedRatePopup = this._onCloseEditNegotiatedRatePopup.bind(this);
        this._onNegotiatedRateChange = this._onNegotiatedRateChange.bind(this);
        this._onNegotiatedRateClear = this._onNegotiatedRateClear.bind(this);
        this._onSubmitNegotiatedRate = this._onSubmitNegotiatedRate.bind(this);
    }

    state = {
        newNegotiatedRate: this.props.laneCarrier.negotiatedRate,
        showEditNegotiatedRatePopup: false,
        invalidNegotiatedRateInput: true,
        resetNegotiatedRateInput: false
    }

    _onSubmitNegotiatedRate() {
        this.props.editNegotiatedRate(this.state.newNegotiatedRate);
        this._onCloseEditNegotiatedRatePopup();
    }

    _onNegotiatedRateChange(value) {
        const { negotiatedRate } = this.props.laneCarrier;
        const invalidNegotiatedRateInput = value === negotiatedRate 
            || ((!!value || value === 0) && value <= 0)    
            || value >= MAX_PRICE
            || (!value && !negotiatedRate);

        this.setState({
            newNegotiatedRate: value,
            invalidNegotiatedRateInput
        });
    }

    _onNegotiatedRateClear() {
        this._onNegotiatedRateChange('');
    }

    _onOpenEditNegotiatedRatePopup() {
        this.setState({ showEditNegotiatedRatePopup: true, resetNegotiatedRateInput: false });
    }

    _onCloseEditNegotiatedRatePopup() {
        this.setState({
            showEditNegotiatedRatePopup: false,
            resetNegotiatedRateInput: true,
            invalidNegotiatedRateInput: true
        });
    }

    render() {
        const { laneCarrier, actionDisabled } = this.props;
        const { negotiatedRate } = laneCarrier;

        return (
            <div className="edit-carrier-negotiated-rate">
                <Popup
                    id="edit-negotiated-rate-popup"
                    size="medium"
                    show={ this.state.showEditNegotiatedRatePopup }
                    onClose={ this._onCloseEditNegotiatedRatePopup }
                    trigger={
                        <div className="action-container">
                            <Button
                                type="tertiary-alt"
                                size="small"
                                className="action"
                                rightIcon={ editIcon }
                                disabled={ actionDisabled }
                                onClick={ this._onOpenEditNegotiatedRatePopup }
                            />
                            <Tooltip direction="top">
                                Edit Negotiated Rate
                            </Tooltip>
                        </div>
                    }
                >
                    <div className="edit-negotiated-rate-popup" >
                        <h6 className="heading">
                            Edit Negotiated Rate
                        </h6>
                        <p className="description">
                            A negotiated rate will limit carrier <b>{ laneCarrier.carrier.name } ({ laneCarrier.carrier.businessId })</b> from 
                            bidding higher than this amount. Once the negotiated rate is applied, the carrier will see it when 
                            bidding on Spot Market. <br/><br/>This will not affect any ongoing auctions and will be applied upon starting a 
                            new auction on this lane.
                        </p>
                        <p className="subheading">
                            Negotiated Rate
                        </p>
                        <CustomNumberInput
                            allowClear={ true }
                            value={ typeof this.state.newNegotiatedRate === 'number' ? this.state.newNegotiatedRate.toString() : this.state.newNegotiatedRate }
                            resetValue={ this.state.resetNegotiatedRateInput }
                            placeholder="0.00"
                            onChange={ this._onNegotiatedRateChange }
                            prefix="$"
                            className="negotiated-rate-value"
                            shouldValidate={ false }
                            isValid={ !this.state.invalidNegotiatedRateInput }
                            initialValue={ negotiatedRate ? negotiatedRate.toFixed(2) : null }
                            onClear={ this._onNegotiatedRateClear }
                        />
                        <div className="buttons-wrapper">
                            <Button
                                type="tertiary"
                                onClick={ this._onCloseEditNegotiatedRatePopup }
                            >
                                Discard
                            </Button>
                            <Button
                                type="primary"
                                disabled={ !!this.state.invalidNegotiatedRateInput }
                                onClick={ this._onSubmitNegotiatedRate }
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
