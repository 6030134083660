import { Children, Component } from 'react';
import PropTypes from 'prop-types';

import AuthorizationService from 'service/AuthorizationService';

/**
 * A Switch component for Access Control.
 * Renders the first (in declaration order) access case child the user can access.
 */
class CanAccessSwitch extends Component {

    state = {
        child: null
    };

    static async _determineCase(children) {
        const authService = AuthorizationService.instance();
        const childrenArray = Children.toArray(children);

        // Attempt to find the first matching access case. Returns immediately when/if found.
        for (const child of childrenArray) {
            if (child.type !== AccessCase) {
                throw Error(
                    child.type + ' is not a valid access case. ' +
                    'Only AccessCase components can be direct children of a CanAccessSwitch.'
                );
            }

            if (!child.props.action) {
                // In case we encounter a default case, skip it for now.
                continue;
            }

            if (await authService.canAccess(child.props.action, child.props.data)) {
                return child;
            }
        }

        // No matching access case could be found. Attempt to find the first default case.
        for (const child of childrenArray) {
            if (!child.props.action) {
                return child;
            }
        }

        // No case was found. Return null to indicate nothing needs to be rendered.
        return null;
    }

    async _updateState() {
       this.setState({ child: await CanAccessSwitch._determineCase(this.props.children) });
    }

    componentDidMount() {
        this._updateState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            this._updateState();
        }
    }

    render() {
        return this.state.child;
    }
}

/**
 * A single access case. Contains no logic of its own, simply a container for case data.
 * Renders its children without any wrapping.
 */
class AccessCase extends Component {

    static propTypes = {
        action: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
        data: PropTypes.object
    };

    render() {
        return this.props.children;
    }
}

export { CanAccessSwitch, AccessCase };
