import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight as rightArrow } from '@fortawesome/pro-light-svg-icons';

import { LANE_TYPE_PROPERTIES, TMW_LOAD_STATUS_MAPPING } from 'common/constants';
import Button from 'component/Button';
import CanAccess from 'component/CanAccess';
import Tooltip from 'component/Tooltip';
import TitledCardComponent from 'component/card/TitledCardComponent';
import LoadActivityLog from 'component/load/LoadActivityLog';
import LaneVisualization from 'component/lane/LaneVisualization';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import StringUtils from 'utils/StringUtils';

import './HeadingComponent.scss';

export default class HeadingComponent extends Component {

    static propTypes = {
        load: PropTypes.object,
        account: PropTypes.object
    }

    static defaultProps = {
        load: null,
        account: null
    };

    render() {
        const { load } = this.props;
        const pickupDate = DateUtils.formatLocal(new Date(load.pickupTime), load.pickupTimeOffset);
        const deliveryDate = DateUtils.formatLocal(new Date(load.deliveryTime), load.deliveryTimeOffset);
        const weight = NumberUtils.formatAsAWholeNumber(load.totalWeight || 'Unknown');
        const laneType = load.routingGuideLane.lane.type;
        
        let activityLog = <></>;
        if (this.props.account?.carrierId) {
            activityLog = (
                <LoadActivityLog
                    loadId={ this.props.load.id }
                    carrierId={ this.props.account?.carrierId }
                />
            );
        }

        return (
            <div className="component main-card heading-component">
                <div className="heading-titles">
                    <div className="title">
                        <TitledCardComponent
                            id="load-order-name"
                            type="small"
                            title="ORDER"
                            className="load-order-name-field"
                        >
                            <h5 className="load-number">#{ load.businessId }</h5>
                            
                        </TitledCardComponent>
                        <div className="icon-div">
                            <FontAwesomeIcon icon={ LANE_TYPE_PROPERTIES[laneType].icon } className="icon" />
                            <Tooltip direction="top">
                                { LANE_TYPE_PROPERTIES[laneType].tooltip }
                            </Tooltip>
                        </div>
                    </div>
                    <div className="lane-origin-and-destination">
                        <LaneVisualization lane={ load.routingGuideLane }  labeled={ true }  size="large"/>
                    </div> 
                    <div className="header-load-button">
                        <CanAccess
                            action="routing-guide:read"
                            yes={
                                <Button 
                                    type="tertiary"  
                                    size="small" 
                                    className="header-load-button"
                                    link={ `/lane/${ load.routingGuideLane.id }` }
                                    rightIcon={ rightArrow }
                                >
                                    See lane details
                                </Button> 
                            }
                        /> 
                        { activityLog }
                    </div>
                </div>
                <div className="fields">
                    <CanAccess
                        action="routing-guide:read"
                        yes={
                            <>
                                { load.routingGuideLane.rfpAuction &&  
                                    <TitledCardComponent 
                                        id="load-routing-guide"
                                        type="small"
                                        title="ROUTING GUIDE"
                                        className="field routing-guide"
                                    >
                                        <Link to={ `/rfp/${ load.routingGuideLane.rfpAuction.id }` } className="routing-guide-link" >
                                            <div className="routing-guide-name" title={ load.routingGuideLane.rfpAuction.name }>
                                                <strong className="content">{ load.routingGuideLane.rfpAuction.name }</strong>
                                            </div>
                                        </Link>
                                    </TitledCardComponent> 
                                }
                            </>
                        }
                    />
                    <CanAccess
                        action="load-tendering:read"
                        yes={
                            <TitledCardComponent 
                                id="load-billto"
                                type="small"
                                title="BILL TO"
                                className="field bill-to"
                            >
                                <div className="bill-to-name" title={ load.billTo }>
                                     <strong className="content">{ load.billTo }</strong>                                    
                                </div>
                            </TitledCardComponent>
                        }
                    />
                    <TitledCardComponent 
                        id="load-pickup-date"
                        type="small"
                        title="PICKUP"
                        className="field pickup-date"
                    >
                        <strong className="content">{ pickupDate }</strong>
                    </TitledCardComponent>
                    <TitledCardComponent 
                        id="load-delivery-date"
                        type="small"
                        title="DELIVERY"
                        className="field delivery-date"
                    >
                        <strong className="content">{ deliveryDate }</strong>
                    </TitledCardComponent>
                    <TitledCardComponent 
                        id="load-weight"
                        type="small"
                        title="WEIGHT"
                        className="field weight"
                        align="right"
                    >
                        <strong className="content">
                            { weight.includes('Unknown') ? '-' : `${ weight } lbs` }
                        </strong>
                    </TitledCardComponent>
                    <CanAccess
                        action="load-tendering:read"
                        yes={
                            <>
                                <TitledCardComponent 
                                    id="load-commodity"
                                    type="small"
                                    title="COMMODITY"
                                    className="field commodity"
                                    align="right"
                                >
                                    <strong className="content">{ StringUtils.equalsIgnoreCase(load.cargoType, 'Unknown') ? '-' : load.cargoType }</strong>
                                </TitledCardComponent>
                                <TitledCardComponent 
                                    id="load-status"
                                    type="small"
                                    title="TMW STATUS"
                                    className="field load-status"
                                    align="right"
                                >
                                    <strong className="content">{ TMW_LOAD_STATUS_MAPPING[load.status] }</strong>
                                </TitledCardComponent>
                            </>
                        }
                    />                  
                </div>
            </div>
        );
    }
}
