import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

import './TitledCardComponent.scss';

export default class TitledCardComponent extends Component {

    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.node.isRequired,
        align: PropTypes.oneOf([ 'left', 'center', 'right' ]),
        className: PropTypes.string,
        type: PropTypes.oneOf([ 'small', 'regular' ])
    };

    static defaultProps = {
        id: undefined,
        align: 'left',
        className: '',
        type: 'regular'
    };

    _titleType() {
        let titleType;

        switch (this.props.type) {
            case 'small':
                titleType = 'sup';
                break;
            case 'regular':
                titleType = 'small';
                break;
            default:
                throw Error("Unknown title card type " + this.props.type);
        }

        return titleType;
    }

    render() {
        return (
            <div id={ this.props.id } className={ `horizontal-card-component-container ${ this.props.align } ${ this.props.className }` }>
                { createElement(this._titleType(), { className: `horizontal-card-component-title ${ this.props.type }` }, this.props.title) }
                <div className="horizontal-card-component-body">{ this.props.children }</div>
            </div>
        );
    }
}
