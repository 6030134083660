import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight as arrow,
    faArrowUp as up,
    faArrowDown as down
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle as warningIcon } from '@fortawesome/pro-solid-svg-icons';

import { 
    AUCTION_PHASE,
    AUCTION_PHASE_MAPPING,
    AUCTION_STATUSES,
    AUCTION_STATUS_CLASS_MAPPING,
    AUCTION_STATUS_MAPPING,
    BID_STATUSES,
    CANDIDATE_TYPE,
    CONFIRMED_AUCTION_STATUSES,
    LANE_TYPES,
    LANE_TYPE_PROPERTIES,
    LOAD_STATUSES,
    OFFERING_AUCTION_STATUSES,
    TERMINAL_AUCTION_BID_CLASS_MAPPING,
    TERMINAL_AUCTION_BID_STATUS_MAPPING,
    TERMINAL_BID_STATUSES,
    WINNING_BID_STATUSES
} from 'common/constants';
import CarrierActions from 'component/load/CarrierActions';
import EquipmentTypeCell from 'component/load/EquipmentTypeCell';
import TableRow from 'component/table/custom/TableRow';
import TableCell from 'component/table/custom/TableCell';
import Tooltip from 'component/Tooltip';
import AuctionUtils from 'utils/AuctionUtils';
import DateUtils from 'utils/DateUtils';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';
import TenderAuctionCarrierApiService from 'service/api/TenderAuctionCarrierApiService';

const CANDIDATE_TYPE_MAPPING = {
    [CANDIDATE_TYPE.SIGNED_CARRIER]: 'Dedicated Carrier',
    [CANDIDATE_TYPE.SPOT_MARKET]: 'Spot Market Carrier',
    [CANDIDATE_TYPE.ON_DEMAND]: 'On-demand Carrier'
};

export default class AuctionTableRow extends Component {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        showBids: PropTypes.bool,
        availableLoadsView: PropTypes.bool,
        searchView: PropTypes.bool,
        onActionPerformed: PropTypes.func
    };

    static defaultProps = {
        showBids: true,
        availableLoadsView: false,
        searchView: false,
        onActionPerformed: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this.state = {
            relevantBid: this._getRelevantBid(),
            link: this._getRelevantPath(),
            isLinkEnabled: true,
            negotiatedRate: null,
            negotiatedRateLoading: true
        }

        this._onLinkEnable = this._onLinkEnable.bind(this);
        this._onLinkDisable = this._onLinkDisable.bind(this);
    }

    componentDidMount() {
        this._setNegotiatedRate();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.auction) !== JSON.stringify(this.props.auction)) {
            this.setState({ relevantBid: this._getRelevantBid() });
        }
    }

    _getRelevantBid() {
        if (this.props.account.carrierId) {
            return AuctionUtils.findRelevantBidOfCarrier(this.props.auction.bids, this.props.account.carrierId);
        }

        return AuctionUtils.findCurrentBid(this.props.auction.bids);
    }

    _onLinkDisable() {
        this.setState({ isLinkEnabled: false });
    }

    _onLinkEnable() {
        this.setState({ isLinkEnabled: true });
    }

    _getRelevantPath() {
        const { account: { carrierId }, auction: { id, load } } = this.props;

        if (carrierId) {
            return `/load/${ load.businessId }/auction/${ id }`;
        }

        return `/load/${ load.businessId }`;
    }

    _setNegotiatedRate() {
        const { id, phase, status } = this.props.auction;

        if (this.props.account.carrierId && AUCTION_PHASE.SPOT_MARKET === phase && AUCTION_STATUSES.OFFERING_TO_CARRIERS === status) {
            TenderAuctionCarrierApiService.getNegotiatedRate(id)
                .then(negotiatedRate => this.setState({ negotiatedRate: negotiatedRate || null, negotiatedRateLoading: false }))
                .catch(error => {
                    console.error('An error occurred while fetching negotiated rate.', error);
                    this.setState({ negotiatedRateLoading: false });
                });
        } else {
            this.setState({ negotiatedRateLoading: false });
        }
    }

    _formEquipmentType() {
        if (this.props.account.carrierId) {
            return <EquipmentTypeCell cargoType={ this.props.auction.load.routingGuideLane.lane.type } link={ this.state.link } />;
        }
    }

    _formTemperature() {
        const load = this.props.auction.load;

        let content;

        if (LANE_TYPES.REFRIGERATED !== load.routingGuideLane.lane.type || (load.minTemperature === null && load.maxTemperature === null)) {
            content = '-';
        } else {
            content = (
                <>
                    <div className="auction-table-cell-title">
                        { NumberUtils.formatWithSign(load.minTemperature) }...{ NumberUtils.formatWithSign(load.maxTemperature) }
                    </div>
                    <div className="auction-table-cell-content">
                        &deg;F
                    </div>
                </>
            );
        }

        return (
            <TableCell padding="regular" alignment="right" className="temperature" link={ this.state.link }>
                <div className="auction-table-cell">
                    { content }
                </div>
            </TableCell>
        );
    }

    _formBookNow() {
        const { auction: { status, bookNowPrice, phase, bids, load }, account: { carrierId }, searchView } = this.props;

        if (carrierId && (searchView || !OFFERING_AUCTION_STATUSES.includes(status))) {
            return;
        }

        let showBookNowPrice = !!bookNowPrice;
        if (showBookNowPrice && carrierId) {
            const negotiatedRate = this.state.negotiatedRate;
            const currentBid = AuctionUtils.findCurrentBid(bids);

            showBookNowPrice = AUCTION_PHASE.SPOT_MARKET === phase && !((!!negotiatedRate && negotiatedRate < bookNowPrice) || (!!currentBid && currentBid.price <= bookNowPrice));
        }

        let content;
        if (this.state.negotiatedRateLoading || !showBookNowPrice) {
            content = '-';
        } else {
            const bookNow = `$${ NumberUtils.formatWithDecimalNotation(bookNowPrice) }`;
            const perMile = `$${ PriceUtils.formatPerMilePrice(bookNowPrice, Number(load.routingGuideLane.distance)) }`;

            content = (
                <>
                    <div className="auction-table-cell-title">
                        { bookNow }
                    </div>
                    <div className="auction-table-cell-content" title={ perMile }>
                        Per Mile { perMile }
                    </div>
                </>
            );
        }

        return (
            <TableCell padding="regular" alignment="right" className="book-now" link={ this.state.link }>
                <div className="auction-table-cell book-now">
                    { content }
                </div>
            </TableCell>
        );
    }

    _formRate() {
        if (this.props.availableLoadsView) {
            return;
        }

        let content;
        if (!this.state.relevantBid) {
            content = '-';
        } else {
            const price = `$${ NumberUtils.formatWithDecimalNotation(this.state.relevantBid.price) }`;
            const perMile = `$${ PriceUtils.formatPerMilePrice(this.state.relevantBid.price, Number(this.props.auction.load.routingGuideLane.distance)) }`;

            content = (
                <>
                    <div className="auction-table-cell-title">
                        { price }
                    </div>
                    <div className="auction-table-cell-content" title={ perMile }>
                        Per Mile { perMile }
                    </div>
                </>
            );
        }

        return (
            <TableCell padding="regular" alignment="right" className="rate" link={ this.state.link }>
                <div className="auction-table-cell rate">
                    { content }
                </div>
            </TableCell>
        );
    }

    _formRank() {
        const { status, phase, bids } = this.props.auction;

        if (!this.props.availableLoadsView && !this.props.searchView && this.props.account.carrierId && OFFERING_AUCTION_STATUSES.includes(status)) {
            let content = '-';

            if (AUCTION_PHASE.SPOT_MARKET === phase && BID_STATUSES.PENDING === this.state.relevantBid?.status) {
                let title, description;

                if (this.state.relevantBid.current) {
                    title = '1st';
                    description = <small className="winning">Winning <FontAwesomeIcon icon={ up } /></small>;
                } else {
                    const position = AuctionUtils.findSpotMarketBids(bids).filter(bid => bid.price < this.state.relevantBid.price).length + 1;
                    title = NumberUtils.ordinal(position);
                    description = <small className="losing">Losing <FontAwesomeIcon icon={ down } /></small>;
                }
                
                content = (
                    <>
                        <div className="auction-table-cell-title">
                            { title }
                        </div>
                        <div className="auction-table-cell-content">
                            { description }
                        </div>
                    </>
                );
            }

            return (
                <TableCell padding="regular" className="rank" link={ this.state.link }>
                    <div className="auction-table-cell rank">
                        { content }
                    </div>
                </TableCell>
            );
        }
    }

    _formExpiration() {
        const { status, phase, deadline } = this.props.auction;

        if (!this.props.searchView && this.props.account.carrierId && OFFERING_AUCTION_STATUSES.includes(status)) {
            const expirationParams = { oneLetterUnit: true, fewSecondsIdentifier: '<1m' };
            let title, style, description;

            if (AUCTION_PHASE.DEDICATED === phase) {
                title = DateUtils.difference(Date.now(), new Date(this.state.relevantBid?.expiration), expirationParams);
                description = 'Until Offer Expires';
            } else {
                title = DateUtils.difference(Date.now(), new Date(deadline), expirationParams);
                description = 'Until Auction Ends';
            }

            // Make the expiration time red for the last 10 minutes
            if (/^<?\dm$/.test(title)) {
                style = 'warning';
            }

            return (
                <TableCell padding="regular" alignment="right" className="expiration" link={ this.state.link }>
                    <div className={ `auction-table-cell expiration ${ style }` }>
                        <div className="auction-table-cell-title">
                            { title }
                        </div>
                        <div className="auction-table-cell-content">
                            { description }
                        </div>
                    </div>
                </TableCell>
            );
        }
    }

    _formCarrierSearchStatus() {
        const { auction: { load, status, phase } } = this.props;
        const { relevantBid, link } = this.state;

        let title, style;

        if (TERMINAL_BID_STATUSES.includes(relevantBid?.status)) {
            title = TERMINAL_AUCTION_BID_STATUS_MAPPING[relevantBid.status];
            style = TERMINAL_AUCTION_BID_CLASS_MAPPING[relevantBid.status];
        } else if (WINNING_BID_STATUSES.includes(relevantBid?.status)) {
            title = AUCTION_STATUS_MAPPING[status];
            style = AUCTION_STATUS_CLASS_MAPPING[status];

            if (LOAD_STATUSES.COMPLETED === load.status && AUCTION_STATUSES.READY === status) {
                title = 'Completed';
            }
        } else if (OFFERING_AUCTION_STATUSES.includes(status)) {
            if (AUCTION_PHASE.DEDICATED === phase) {
                title = 'Waiting for Response';
                style = 'pending';
            } else {
                title = 'Bidding in Progress';
                style = 'progress';
            }
        } else if (AUCTION_STATUSES.AWAITING_WINNER_DECLARATION === status) {
            title = 'Awaiting Winner Declaration';
            style = 'pending';
        } else {
            title = 'Lost Load';
            style = 'error';
        }

        return (
            <TableCell padding="regular" alignment="right" link={ link }>
                <div className={ `auction-table-cell status title ${ style }` }>
                    { title }
                </div>
            </TableCell>
        );
    }
    
    _formStatus() {
        const { auction: { load, status, phase, deadline, tmwDataMismatchType }, account: { carrierId }, searchView } = this.props;
        const yesterday = moment().subtract(1, 'days');

        if (carrierId && searchView) {
            return this._formCarrierSearchStatus();
        }

        if (carrierId && OFFERING_AUCTION_STATUSES.includes(status)) {
            return;
        }

        let title = 'Waiting for Offers';
        let style = 'pending';
        let description = '-';
        let showTmwDataMismatchType;

        if (AUCTION_STATUSES.PENDING === status) {
            return (
                <TableCell padding="regular" alignment="right" link={ this.state.link }>
                    <div className={ `auction-table-cell status ${ style }` }>
                        <div className="auction-table-cell-title">
                            { title }
                        </div>
                        <div className="auction-table-cell-content">
                            { description }
                        </div>
                    </div>
                </TableCell>
            );
        }

        if (OFFERING_AUCTION_STATUSES.includes(status) && (!this.state.relevantBid || BID_STATUSES.NOT_SENT !== this.state.relevantBid?.status)) {
            const expirationParams = { oneLetterUnit: true, fewSecondsIdentifier: '<1m' };

            if (AUCTION_PHASE.DEDICATED === phase) {
                if (!!this.state.relevantBid) {
                    const expiration = DateUtils.difference(Date.now(), new Date(this.state.relevantBid.expiration), expirationParams);
                    title = 'Waiting for Response';
                    style = 'pending';
                    description = <>Offer Expires in: <span className="expiration-time">{ expiration }</span></>;
                }
            } else {
                const expiration = DateUtils.difference(Date.now(), new Date(deadline), expirationParams);
                title = 'Bidding in Progress';
                style = 'progress';
                description = <>Auction Deadline in: <span className="expiration-time">{ expiration }</span></>;
            }

            return (
                <TableCell padding="regular" alignment="right" link={ this.state.link }>
                    <div className={ `auction-table-cell status ${ style }` }>
                        <div className="auction-table-cell-title">
                            { title }
                        </div>
                        <div className="auction-table-cell-content">
                            { description }
                        </div>
                    </div>
                </TableCell>
            );
        } else if (CONFIRMED_AUCTION_STATUSES.includes(status) && LOAD_STATUSES.COMPLETED !== load.status) {
            title = AUCTION_STATUS_MAPPING[status];
            style = AUCTION_STATUSES.READY === status ? 'success' : 'pending';
            showTmwDataMismatchType = true;
        } else if (AUCTION_STATUSES.CANCELED === status) {
            title = 'Canceled';
            style = 'error';
        } else if (LOAD_STATUSES.COMPLETED === load.status) {
            title = 'Completed';
            style = 'success';
        } else if (new Date(load.pickupTime) < yesterday) {
            title = 'Expired';
            style = 'error';
            showTmwDataMismatchType = true;
        } else {
            title = 'Awaiting Winner Declaration';
            style = 'pending';
            showTmwDataMismatchType = true;
        }
        
        let tmwDataMismatchWarning, tmwDataMismatchStyle = '';
        if (tmwDataMismatchType && showTmwDataMismatchType) {
            tmwDataMismatchStyle = 'with-mismatch';
            tmwDataMismatchWarning = (
                <div className="tmw-warning-icon-container">
                    <FontAwesomeIcon icon={ warningIcon } className="tmw-warning-icon" />
                    <Tooltip direction="top">
                        TMW Issue
                    </Tooltip>
                </div>
            )
        }

        return (
            <TableCell padding="regular" alignment="right" link={ this.state.link }>
                <div className={ `auction-table-cell status title ${ style } ${ tmwDataMismatchStyle }` }>
                    { tmwDataMismatchWarning }
                    { title }
                </div>
            </TableCell>
        );
    }

    _formCarrier() {
        if (this.props.availableLoadsView || this.props.account.carrierId) {
            return;
        }

        if (!this.state.relevantBid) {
            return (
                <TableCell padding="regular" className="carrier" link={ this.state.link }>
                    <div className="auction-table-cell carrier-link">
                        -
                    </div>
                </TableCell>
            );
        }

        const candidate = this.state.relevantBid.candidate;
        const carrier = candidate.carrier;
        const title = `${ carrier.businessId } - ${ carrier.name }`;

        return (
            <TableCell padding="regular" className="carrier" link={ this.state.link }>
                <div className="auction-table-cell carrier-link">
                    <Link to={ `/carrier/${ carrier.id }` } className="auction-table-cell-title">
                        <div title={ title }>{ title }</div>
                    </Link>
                    <div className="auction-table-cell-content">
                        { CANDIDATE_TYPE_MAPPING[candidate.type] }
                    </div>
                </div>
            </TableCell>
        );
    }

    _formBids() {
        if (!this.props.showBids) {
            return;
        }

        let bids = [];

        if (AUCTION_PHASE.SPOT_MARKET === this.props.auction.phase) {
            bids = AuctionUtils.findSpotMarketBids(this.props.auction.bids);
        }

        return (
            <TableCell padding="regular" alignment="right" link={ this.state.link }>
                <div className="auction-table-cell">
                    { !bids.length ? '-' : bids.length }
                </div>
            </TableCell>
        );
    }

    _formActions() {
        const { load, status, phase, bids } = this.props.auction;

        if (!this.props.searchView && this.props.account.carrierId && OFFERING_AUCTION_STATUSES.includes(status)) {
            let content;

            if (AUCTION_PHASE.DEDICATED === phase) {
                content = (
                    <CarrierActions
                        type="accept/reject"
                        size="small"
                        acceptRejectOfferProps={ {
                            auction: this.props.auction,
                            price: this.state.relevantBid?.price,
                            lane: load.routingGuideLane.lane,
                            onActionPerformed: this.props.onActionPerformed
                        } }
                        onLinkEnable={ this._onLinkEnable }
                        onLinkDisable={ this._onLinkDisable }
                    />
                );
            } else {
                content = (
                    <CarrierActions
                        type="spot-market"
                        size="small"
                        spotMarketActionsProps={ {
                            auction: this.props.auction,
                            negotiatedRate: this.state.negotiatedRate,
                            currentBid: AuctionUtils.findCurrentBid(bids), 
                            carrierBid: this.state.relevantBid,
                            onActionPerformed: this.props.onActionPerformed
                        } }
                        onLinkEnable={ this._onLinkEnable }
                        onLinkDisable={ this._onLinkDisable }
                    />
                );
            }

            return (
                <TableCell padding="regular" alignment="right" className="actions" link={ this.state.link } isLinkEnabled={ this.state.isLinkEnabled }>
                    <div className="auction-table-cell actions">
                        { content }
                    </div>
                </TableCell>
            );
        }
    }

    render() {
        const { auction: { load, phase } } = this.props;
        const { link } = this.state;

        const rfp = load.routingGuideLane.rfpAuction;
        const lane = load.routingGuideLane.lane;

        const origin = LaneUtils.formatRegion(lane.origin);
        const destination = LaneUtils.formatRegion(lane.destination);

        return (
            <TableRow className={ `auction-table-row ${ AUCTION_PHASE_MAPPING[phase] }` } fontSize="small">
                <TableCell padding="regular" className="load" link={ link }>
                    <div className="auction-table-cell load">
                        <div className="auction-table-cell-title">
                            <div>
                                <div className="cargo-type-icon-container">
                                    <FontAwesomeIcon icon={ LANE_TYPE_PROPERTIES[lane.type].icon } className={ `cargo-type-icon ${ LANE_TYPE_PROPERTIES[lane.type].field }` } />
                                    <Tooltip direction="right" >
                                        { LANE_TYPE_PROPERTIES[lane.type].tooltip }
                                    </Tooltip>
                                </div>
                                <div className="load-link">#{ load.businessId }</div>
                            </div>
                        </div>
                        <div className="auction-table-cell-content" title={ rfp?.name }>
                            { this.props.account.carrierId ?
                                <div title={ rfp?.name }>{ rfp?.name || '-' }</div>
                            :
                                <Link to={ `/rfp/${ rfp.id }` } className="rfp-link">
                                    <div title={ rfp.name }>{ rfp.name }</div>
                                </Link>
                            }
                        </div>

                    </div>
                </TableCell>

                <TableCell padding="regular" className="region" link={ link }>
                    <div className="auction-table-cell region">
                        <div className="auction-table-cell-title" title={ origin }>
                            { origin }
                        </div>
                        <div className="auction-table-cell-content">
                            { DateUtils.formatLocal(new Date(load.pickupTime), load.pickupTimeOffset) }
                        </div>
                    </div>
                </TableCell>

                <TableCell padding="regular" sidePadding={ false } link={ link }>
                    <div className="auction-table-cell arrow">
                        <FontAwesomeIcon icon={ arrow } />
                    </div>
                </TableCell>

                <TableCell padding="regular" className="region" link={ link }>
                    <div className="auction-table-cell region">
                        <div className="auction-table-cell-title" title={ destination }>
                            { destination }
                        </div>
                        <div className="auction-table-cell-content">
                            { DateUtils.formatLocal(new Date(load.deliveryTime), load.deliveryTimeOffset) }
                        </div>
                    </div>
                </TableCell>

                { this._formEquipmentType() }

                <TableCell padding="regular" alignment="right" className="distance" link={ link }>
                    <div className="auction-table-cell">
                        <div className="auction-table-cell-title">
                            { NumberUtils.formatAsAWholeNumber(load.routingGuideLane.distance) }
                        </div>
                        <div className="auction-table-cell-content">
                            mi.
                        </div>
                    </div>
                </TableCell>

                <TableCell padding="regular" alignment="right" className="weight" link={ link }>
                    <div className="auction-table-cell">
                        { !load.totalWeight ? 
                        '-'
                        :
                        <>
                            <div className="auction-table-cell-title">
                                { NumberUtils.formatAsAWholeNumber(load.totalWeight) }
                            </div>
                            <div className="auction-table-cell-content">
                                lbs
                            </div>
                        </>
                        }
                    </div>
                </TableCell>

                { this._formTemperature() }

                { this._formBookNow() }

                { this._formRate() }

                { this._formCarrier() }

                { this._formBids() }

                { this._formRank() }

                { this._formExpiration() }

                { this._formStatus() }

                { this._formActions() }
            </TableRow>
        );
    }
}
