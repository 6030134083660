import { Component } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle as add } from '@fortawesome/pro-solid-svg-icons';
import { faEdit as editIcon, faClone as duplicateIcon } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';

import { MAX_NUMBER_OF_WEEKS, RFP_AUCTION_STATUSES, TERMINAL_RFP_AUCTION_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomRadioButton from 'component/form/CustomRadioButton';
import CustomTextInput from 'component/form/CustomTextInput';
import CustomRangePicker from 'component/form/CustomRangePicker';
import CustomNumberInput from 'component/form/CustomNumberInput';
import CustomMultipleTextInput from 'component/form/CustomMultipleTextInput';
import CustomSwitch from 'component/form/CustomSwitch';
import RestService from 'service/RestService';
import RFPUtils from 'utils/RFPUtils';
import ObjectUtils from 'utils/ObjectUtils';
import DateUtils from 'utils/DateUtils';

import './CreateRFP.scss';

const RADIO_BUTTON_OPTIONS = [ 
    { 
        value: false, 
        label: 'Include RFP Process'
    },
    { 
        value: true, 
        label: 'Skip RFP Process'
    }
];

export default class CreateRFP extends Component {

    static propTypes = {
        rfp: PropTypes.object,
        mode: PropTypes.oneOf(['create', 'edit', 'duplicate']),
        onUpdate: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        mode: 'create',
        onUpdate: () => { /**/ },
        onClose: () => { /**/ }
    };

    constructor(props) {
        super(props);

        const { startDate, endDate, durationWeeks } = this._getEffectivePeriod();
        const isEditing = this._isEditing();
        const isCreating = this._isCreating();

        this.state = {
            showPopup: false,
            showConfirmationPopup: false,
            validInput: false,
            name: isEditing ? this.props.rfp.name : '',
            startDate,
            endDate,
            durationWeeks,
            billTo: isEditing ? this.props.rfp.billTo : [],
            synthetic: !isCreating ? false : null,
            dynamic: isEditing ? this.props.rfp.dynamic : false,
            errorMessage: '',
            invalidNameErrorMessage: '',
            invalidDatesErrorMessage: '',
            invalidDurationErrorMessage: ''
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._onNameChange = this._onNameChange.bind(this);
        this._onDatesChange = this._onDatesChange.bind(this);
        this._onDurationChange = this._onDurationChange.bind(this);
        this._onDurationReset = this._onDurationReset.bind(this);
        this._onBillToAdded = this._onBillToAdded.bind(this);
        this._onBillToRemoved = this._onBillToRemoved.bind(this);
        this._unsetState = this._unsetState.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onShowConfirmationPopup = this._onShowConfirmationPopup.bind(this);
        this._onSyntheticChange = this._onSyntheticChange.bind(this);
        this._onDynamicChange = this._onDynamicChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.showPopup && !ObjectUtils.equal(prevProps.rfp, this.props.rfp)) {
            const isEditing = this._isEditing();
            const { startDate, endDate, durationWeeks } = this._getEffectivePeriod();

            this.setState({
                name: isEditing ? this.props.rfp.name : '',
                startDate,
                endDate,
                durationWeeks,
                billTo: isEditing ? this.props.rfp.billTo : [],
                dynamic: isEditing ? this.props.rfp.dynamic : false
            });
        }
    }

    _getEffectivePeriod() {
        const isEditing = this._isEditing();

        const startDate = isEditing ? moment(this.props.rfp.startDate) : moment().add(1, 'month');
        const endDate = isEditing ? moment(this.props.rfp.endDate) : moment().add(1, 'month').add(1, 'year');
        const durationWeeks = isEditing ? this.props.rfp.durationWeeks : DateUtils.numberOfCalendarWeeks(startDate, endDate);

        return { startDate, endDate, durationWeeks };
    }

    _onSubmit() {
        const body = {
            name: this.state.name.trim(),
            startDate: this.state.startDate.format('YYYY-MM-DD'),
            endDate: this.state.endDate.format('YYYY-MM-DD'),
            durationWeeks: this.state.durationWeeks,
            billTo: this.state.billTo,
            synthetic: this.state.synthetic,
            dynamic: this.state.dynamic
        };

        let response;
        if (this._isEditing()) {
            response = RestService.instance().put(`auction/rfp/${ this.props.rfp.id }`, body);
        } else if (this._isDuplicating()) {
            response = RestService.instance().post(`auction/rfp/${ this.props.rfp.id }/duplicate`, body);
        } else {
            response = RestService.instance().post('auction/rfp', body);
        }

        response
            .then(rfp => this.props.onUpdate(rfp.id))
            .then(this._unsetState)
            .catch(error => this.setState({
                errorMessage: error.response.data.status === 500 ? "Something went wrong." : error.response.data,
                validInput: false
            }));
    }

    _onNameChange(event) {
        this.setState(prevState => {
            let invalidNameErrorMessage = '';

            if (event.target.value.trim().length === 0) {
                invalidNameErrorMessage = 'Routing Guide name cannot be blank.';
            }

            return {
                name: event.target.value,
                validInput: prevState.synthetic !== null && !invalidNameErrorMessage && !prevState.invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage,
                invalidNameErrorMessage,
                errorMessage: ''
            };
        });
    }

    _onDatesChange(dates) {
        if (dates) {
            const [startDate, endDate] = dates;

            this.setState(prevState => {
                const invalidDatesErrorMessage = this._getInvalidDatesErrorMessage(startDate, endDate);

                return {
                    startDate,
                    endDate,
                    durationWeeks: DateUtils.numberOfCalendarWeeks(startDate, endDate),
                    validInput: prevState.synthetic !== null && prevState.name && !prevState.invalidNameErrorMessage && !invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage,
                    invalidDatesErrorMessage,
                    errorMessage: ''
                };
            });
        }
    }

    _onDurationChange(durationWeeks) {
        let invalidDurationErrorMessage = '';
        let valid = true;

        if (durationWeeks > MAX_NUMBER_OF_WEEKS) {
            invalidDurationErrorMessage = 'Number of weeks is too large.';
            valid = false;
        } else if (durationWeeks < 1) {
            invalidDurationErrorMessage = 'Routing Guide must be effective for at least 1 week.';
            valid = false;
        }

        this.setState(prevState => ({
            durationWeeks,
            validInput: valid && prevState.synthetic !== null && prevState.name && !prevState.invalidNameErrorMessage && !prevState.invalidDatesErrorMessage,
            invalidDurationErrorMessage,
            errorMessage: ''
        }));
    }

    _onDurationReset() {
        const defaultDuration = DateUtils.numberOfCalendarWeeks(this.state.startDate, this.state.endDate);
        this._onDurationChange(defaultDuration);
    }

    _onBillToAdded(newBillto) {
        // We use flushSync to ensure bill to ids are set to the state before the submit method is called
        flushSync(() => {
            this.setState(prevState => ({
                billTo: [ ...prevState.billTo, newBillto ],
                validInput: prevState.synthetic !== null && prevState.name && !prevState.invalidNameErrorMessage && !prevState.invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage,
                errorMessage: ''
            }));
        });
    }

    _onBillToRemoved(billTo) {
        this.setState(prevState => {
            const billToArray = [ ...prevState.billTo ];
            const index = billToArray.indexOf(billTo);

            if (index > -1) {
                billToArray.splice(index, 1);
            }

            return {
                billTo: billToArray,
                validInput: prevState.synthetic !== null && prevState.name && !prevState.invalidNameErrorMessage && !prevState.invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage,
                errorMessage: ''
            };
        });
    }

    _getInvalidDatesErrorMessage(startDate, endDate) {
        const latestRound = RFPUtils.latestRound(this.props.rfp);

        if (!startDate || !endDate) {
            return 'Effective dates are required.';
        } else if (latestRound.deadline && startDate.isSameOrBefore(latestRound.deadline, 'day') && !TERMINAL_RFP_AUCTION_STATUSES.includes(this.props.rfp.status)) {
            return 'Start date cannot be before the current round deadline.';
        } else if (this.props.rfp && !moment(this.props.rfp.endDate).isSame(endDate, 'day') && !moment().isSameOrBefore(endDate, 'day')) {
            return 'End date cannot be in the past.';
        } else if (this.props.rfp && !moment(this.props.rfp.startDate).isSame(startDate, 'day') && !moment().isSameOrBefore(startDate, 'day')) {
            return 'Start date cannot be in the past.';
        } else if (!this.props.rfp && !moment().isSameOrBefore(startDate, 'day')) {
            return 'Start date cannot be in the past.';
        }

        return '';
    }

    _formErrorMessage() {
        if (this.state.errorMessage || this.state.invalidNameErrorMessage || this.state.invalidDatesErrorMessage || this.state.invalidDurationErrorMessage) {
            return (
                <small className="error-message">
                    { this.state.errorMessage } { this.state.invalidNameErrorMessage } { this.state.invalidDatesErrorMessage } { this.state.invalidDurationErrorMessage }
                </small>
            );
        }

        return <></>;
    }

    _unsetState() {
        const isEditing = this._isEditing();
        const isCreating = this._isCreating();
        const { startDate, endDate, durationWeeks } = this._getEffectivePeriod();

        this.setState({
            showPopup: false,
            showConfirmationPopup: false,
            validInput: false,
            name: isEditing ? this.props.rfp.name : '',
            startDate,
            endDate,
            durationWeeks,
            billTo: isEditing ? this.props.rfp.billTo : [],
            synthetic: !isCreating ? false : null,
            dynamic: isEditing ? this.props.rfp.dynamic : false,
            errorMessage: '',
            invalidNameErrorMessage: '',
            invalidDatesErrorMessage: '',
            invalidDurationErrorMessage: ''
        });

        this.props.onClose();
    }

    _onOpenPopup(event) {
        event.preventDefault();
        this.setState({ showPopup: true });
    }

    _onShowConfirmationPopup() {
        this.setState({ showConfirmationPopup: true });
    }

    _onSyntheticChange(value) {
        this.setState(prevState => ({
            synthetic: value,
            validInput: prevState.name && !prevState.invalidNameErrorMessage && !prevState.invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage
        }));
    }

    _onDynamicChange() {
        this.setState(prevState => ({
            dynamic: !prevState.dynamic,
            validInput: prevState.synthetic !== null && prevState.name && !prevState.invalidNameErrorMessage && !prevState.invalidDatesErrorMessage && !prevState.invalidDurationErrorMessage
        }));
    }

    _isDuplicating() {
        return 'duplicate' === this.props.mode && !!this.props.rfp;
    }

    _isEditing() {
        return 'edit' === this.props.mode && !!this.props.rfp;
    }

    _isCreating() {
        return 'create' === this.props.mode;
    }

    render() {
        let popupSize = 'medium';
        let heading = '';
        let description = '';
        let trigger = <></>;
        let buttonText = '';
        let skipRFPOptions = <></>;
        const isEditing = this._isEditing();
        const isDuplicating = this._isDuplicating();
        const isCreating = this._isCreating();

        if (isEditing) {
            heading = 'Edit Information';
            description = 'Changes will be effective immediately.';
            if (RFP_AUCTION_STATUSES.PENDING !== this.props.rfp.status) {
                description = description + ' Carriers will be notified if you change the effective dates.';
            }
            trigger = (
                <div className="dropdown-item" onClick={ this._onOpenPopup }>
                    <FontAwesomeIcon className="action-icon" icon={ editIcon }/>
                    <small className="action-name">
                        Edit Information
                    </small>
                </div>
            );
            buttonText = 'Save Changes';
        } else if (isDuplicating) {
            heading = 'Duplicate RFP';
            description = 'Duplicating an RFP will create a new Not Published RFP with the same lanes and carriers as the original. Please enter the details of the new RFP.';
            trigger = (
                <div className="dropdown-item" onClick={ this._onOpenPopup }>
                    <FontAwesomeIcon className="action-icon" icon={ duplicateIcon }/>
                    <small className="action-name">
                        Duplicate RFP
                    </small>
                </div>
            );
            buttonText = 'Confirm';
        } else if (isCreating) {
            heading = 'Create New Routing Guide';
            description = 'To create a new Routing Guide, please enter the following information. If you skip the RFP process, the routing guide will be generated without any lanes.';
            trigger = (
                <Button 
                    type="primary"
                    link="#create-rfp-popup"
                    size="regular"
                    leftIcon={ add }
                    onClick={ this._onOpenPopup }
                >
                    Create New Routing Guide
                </Button>
            );
            buttonText = 'Confirm';
            skipRFPOptions = (
                <CustomRadioButton
                    onChange={ this._onSyntheticChange } 
                    options={ RADIO_BUTTON_OPTIONS } 
                    selectedValue={ this.state.synthetic }
                    direction="horizontal"
                />
            );
        } else {
            console.error('Case not handled appropriately.', this.props.mode);
        }

        let content;
        if (this.state.showConfirmationPopup && isEditing) {
            popupSize = 'small';
            content = (
                <>
                    <p className="heading">{ heading }</p>
                    <p className="description">Editing an Applied RFP may affect the tendering process. Changing effective dates or Bill To IDs will affect which loads are matched to this Routing Guide. Changing Number of weeks will change dedicated carriers' weekly limit. Are you sure you want to continue?</p>
                    { this._formErrorMessage() }
                    <div className="buttons-wrapper">
                        <Button size="small" type="tertiary" onClick={ this._unsetState }>
                            Cancel
                        </Button>

                        <Button disabled={ !this.state.validInput } size="small" onClick={ this._onSubmit }>
                            Save Changes
                        </Button>
                    </div>
                </>
            );
        } else {
            const onSubmit = isEditing && RFP_AUCTION_STATUSES.APPLIED === this.props.rfp.status ? this._onShowConfirmationPopup : this._onSubmit;

            content = (
                <>
                    <h6 className="heading">{ heading }</h6>
                    <p className="description">{ description }</p>

                    { skipRFPOptions }

                    <CustomTextInput 
                        label="Routing Guide Name"
                        className="create-rfp-input-field"
                        required={ true }
                        value={ this.state.name }
                        fieldName="name"
                        placeholder={ `e.g. Routing Guide ${ new Date().getFullYear() }` }
                        onValueChange={ this._onNameChange }
                    />
                    <CustomRangePicker
                        label="Effective"
                        className="create-rfp-input-field"
                        tooltipText={ 'Effective dates determine a period when outcomes of an RFP would apply as an effective Routing Guide. However, this will not be done automatically. RFP Manager should use the "Apply" option available after an RFP has been confirmed.' }
                        dateFrom={ this.state.startDate }
                        dateTo={ this.state.endDate }
                        handleChange={ this._onDatesChange }
                        allowClear={ false }
                    />
                    <CustomNumberInput
                        label="Number of weeks"
                        tooltipText="This number represents the duration of the effective period in weeks and is used to calculate the carriers' weekly limit."
                        className="create-rfp-input-field"
                        initialValue={ this.state.durationWeeks ? this.state.durationWeeks.toString() : null }
                        value={ this.state.durationWeeks ? this.state.durationWeeks.toString() : null }
                        min={ 0 }
                        max={ MAX_NUMBER_OF_WEEKS }
                        decimals={ 0 }
                        onChange={ this._onDurationChange }
                        allowClear={ true }
                        onClear={ this._onDurationReset }
                        shouldValidate={ false }
                    />
                    <CustomMultipleTextInput
                        label="Bill To IDs"
                        tooltipText="To add multiple Bill To Ids press the enter key after each entry."
                        className="create-rfp-input-field bill-to-field"
                        placeholder="Enter Bill To IDs..."
                        values={ this.state.billTo }
                        onValueAdded={ this._onBillToAdded }
                        onValueRemoved={ this._onBillToRemoved }
                    />
                    <CustomSwitch
                        size="small"
                        label="Dynamic Lanes"
                        description="Enabling this option will allow for automatic creation of lanes when loads that match this routing guide become available."
                        checked={ this.state.dynamic }
                        className="dynamic-lanes-switch"
                        onChange={ this._onDynamicChange }
                    />

                    { this._formErrorMessage() }

                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._unsetState }>
                            Cancel
                        </Button>

                        <Button disabled={ !this.state.validInput } onClick={ onSubmit }>
                            { buttonText }
                        </Button>
                    </div>
                </>
            )
        }

        return (
            <Popup
                id="create-rfp-popup"
                size={ popupSize }
                show={ this.state.showPopup }
                onClose={ this._unsetState }
                trigger={ trigger }
            >
                <div className="create-rfp-popup">
                    { content }
                </div>
            </Popup>
        );
    }
}
