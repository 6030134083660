import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisH as menu,
    faTrash as deleteIcon,
    faCheckCircle as completeIcon,
    faCloudDownloadAlt as download,
    faEye as view,
    faBell as bell
} from '@fortawesome/pro-light-svg-icons';

import Popup from 'component/Popup';
import Button from 'component/Button';
import Dropdown from 'component/Dropdown';
import CustomLoader from 'component/form/CustomLoader';
import DownloadFileComponent from 'component/DownloadFileComponent';
import ViewAttachments from 'component/rfp/ViewAttachments';
import UploadAttachments from 'component/rfp/UploadAttachments';
import CreateRFP from 'component/rfp/CreateRFP';
import DateUtils from 'utils/DateUtils';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';

import './ActionDropdown.scss';

export default class ActionDropdown extends Component {

    static propTypes = {
        size: PropTypes.string,
        seeAttachmentsProps: PropTypes.shape({
            disabled: PropTypes.bool,
            attachments: PropTypes.array,
            rfp: PropTypes.object,
            onUpdate: PropTypes.func
        }),
        uploadAttachmentsProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfp: PropTypes.object,
            onUpdate: PropTypes.func
        }),
        editRFPProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfp: PropTypes.object,
            onUpdate: PropTypes.func
        }),
        duplicateRFPProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfp: PropTypes.object,
            onUpdate: PropTypes.func
        }),
        deleteRFPProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfp: PropTypes.object,
            onDelete: PropTypes.func
        }),
        completeRGProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfp: PropTypes.object,
            onComplete: PropTypes.func
        }),
        downloadRoutingGuideProps: PropTypes.shape({
            disabled: PropTypes.bool,
            rfpId: PropTypes.string
        }),
        viewRFPProps: PropTypes.shape({
            disabled: PropTypes.bool,
            onViewRFP: PropTypes.func
        }),
        notificationCenterProps: PropTypes.shape({
            disabled: PropTypes.bool,
            routingGuideId: PropTypes.string
        })
    };

    static defaultProps = {
        size: "regular",
        seeAttachmentsProps: { disabled: true, attachments: [], rfp: null, onUpdate: () => { /* */ } },
        uploadAttachmentsProps: { disabled: true, rfp: null, onUpdate: () => { /* */ } },
        editRFPProps: { disabled: true, onUpdate: () => { /* */ } },
        duplicateRFPProps: { disabled: true, onUpdate: () => { /* */ } },
        deleteRFPProps: { disabled: true,  onDelete: () => { /* */ } },
        completeRGProps: { disabled: true, onComplete: () => { /* */ } },
        downloadRoutingGuideProps: { disabled: true, rfpId: null },
        viewRFPProps: { disabled: true, onViewRFP: () => { /* */ } },
        notificationCenterProps: { disabled: true, routingGuideId: null }
    };

    constructor (props) {
        super(props);
        this._onCloseDeleteRFPPopup = this._onCloseDeleteRFPPopup.bind(this);
        this._onOpenDeleteRFPPopup = this._onOpenDeleteRFPPopup.bind(this);
        this._onOpenCompleteRGPopup = this._onOpenCompleteRGPopup.bind(this);
        this._onCloseCompleteRGPopup = this._onCloseCompleteRGPopup.bind(this);
        this._onDeleteRFP = this._onDeleteRFP.bind(this);
        this._onCompleteRG = this._onCompleteRG.bind(this);
        this._onViewRFP = this._onViewRFP.bind(this);
        this._onOpenActionsDropdown = this._onOpenActionsDropdown.bind(this);
        this._onCloseActionsDropdown = this._onCloseActionsDropdown.bind(this);
    }

    state = {
        showSeeAttachmentsPopup: false,
        showDeleteRFPPopup: false,
        showCompleteRGPopup: false,
        disabled: true,
        loading: false
    }

    _onDeleteRFP() {
        const { rfp } = this.props.deleteRFPProps;

        RFPAuctionApiService.deleteRFP(rfp.id)
            .then(() => this.setState({ showDeleteRFPPopup: false }))
            .then(() => this.props.deleteRFPProps.onDelete());
    }

    _formSeeAttachments(seeAttachmentsProps) {
        return (
            <ViewAttachments
                attachments={ seeAttachmentsProps.attachments }
                rfp={ seeAttachmentsProps.rfp }
                trigger="menu"
                onUpdate={ seeAttachmentsProps.onUpdate }
                onClose={ this._onCloseActionsDropdown }
            />
        );
    }

    _formUploadAttachments(uploadAttachmentsProps) {
        return (
            <UploadAttachments
                rfp={ uploadAttachmentsProps.rfp }
                onUpdate={ uploadAttachmentsProps.onUpdate }
                onClose={ this._onCloseActionsDropdown }
            />
        );
    }

    _formEditRFP(props) {
        return (
            <CreateRFP 
                rfp={ props.rfp } 
                mode="edit" 
                onUpdate={ props.onUpdate } 
                onClose={ this._onCloseActionsDropdown } 
            />
        );
    }

    _formDuplicateRFP(props) {
        return (
            <CreateRFP 
                rfp={ props.rfp } 
                mode="duplicate" 
                onUpdate={ props.onUpdate } 
                onClose={ this._onCloseActionsDropdown } 
            />
        );
    }

    _formDownloadRoutingGuide(props) {
        return (
            <DownloadFileComponent
                trigger={
                    <div className="dropdown-item">
                        <FontAwesomeIcon className="action-icon" icon={ download } />
                        <small className="action-name">
                            Export RFP
                        </small>
                    </div>
                }
                reference={ `auction/rfp/${ props.rfpId }/routing-guide/csv` }
                isStaticResource={ false }
            />
        );
    }

    _onViewRFP() {
        this._onCloseActionsDropdown();
        this.props.viewRFPProps.onViewRFP();
    }

    _formViewRFP() {
        return (
            <div onClick={ this._onViewRFP } className="dropdown-item">
                <FontAwesomeIcon className="action-icon" icon={ view }/>
                <small className="action-name">View RFP</small>
            </div>
        );
    }

    _onOpenDeleteRFPPopup(event) {
        event.preventDefault();
        this.setState({ showDeleteRFPPopup: true });
    }

    _onCloseDeleteRFPPopup() {
        this.setState({ showDeleteRFPPopup: false, showActionsDropdown: false });
    }

    _formDeleteRFP(props) {
        return (
            <Popup 
                id="delete-rfp-popup"
                show={ this.state.showDeleteRFPPopup }
                onClose={ this._onCloseDeleteRFPPopup }
                trigger={ 
                    <div className="dropdown-item" onClick={ this._onOpenDeleteRFPPopup }>
                        <FontAwesomeIcon className="action-icon" icon={ deleteIcon }/>
                        <small className="action-name">
                           Delete RFP
                        </small>
                   </div>
                }
            >
                <div className="action-dropdown-popup">
                    <p className="heading">Delete RFP</p>
                    <p className="description">Are you sure you want to <b>permanently</b> delete <b>{ props.rfp.name }</b>?</p>

                    <div className="buttons-wrapper">
                        <Button 
                            type="tertiary"
                            size="small"
                            onClick={ this._onCloseDeleteRFPPopup } 
                        >
                            Cancel
                        </Button>

                        <Button 
                            type="danger"
                            size="small"
                            onClick={ this._onDeleteRFP } 
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }

    _onOpenCompleteRGPopup(event) {
        event.preventDefault();
        this.setState({ showCompleteRGPopup: true });
    }

    _onCloseCompleteRGPopup() {
        if (!this.state.loading) {
            this.setState({ showCompleteRGPopup: false, showActionsDropdown: false });
        }      
    }

    _onCompleteRG() {
        const { rfp } = this.props.completeRGProps;
        this.setState({ loading: true });

        RFPAuctionApiService.completeRFP(rfp.id)
            .then(() => this.setState({ loading: false }))
            .then(this._onCloseCompleteRGPopup)
            .then(this.props.completeRGProps.onComplete);
    }

    _formCompleteRG(props) {
        const expiresOn = DateUtils.formatDate(props.rfp.endDate);
        let content;

        if (this.state.loading) {
            content = (
                <>
                    <CustomLoader size="large" />
                    <h6 className="heading">Completing routing guide</h6>
                    <p className="description">Please wait...</p>
                </>
            );
        } else {
            content = (
                <>
                    <p className="heading">Complete Routing Guide</p>

                    <p className="description">Are you sure you want to manually complete <b>{ props.rfp.name }</b>?</p>
                    <p className="description">The expiration date for this Routing Guide is <b>{ expiresOn }</b>.</p>

                    <div className="buttons-wrapper">
                        <Button 
                            type="tertiary"
                            size="small"
                            onClick={ this._onCloseCompleteRGPopup } 
                        >
                            Cancel
                        </Button>

                        <Button 
                            type="primary"
                            size="small"
                            onClick={ this._onCompleteRG } 
                        >
                            Complete
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <Popup 
                id="complete-rfp-popup"
                show={ this.state.showCompleteRGPopup }
                onClose={ this._onCloseCompleteRGPopup }
                trigger={
                    <div className="dropdown-item" onClick={ this._onOpenCompleteRGPopup } > 
                        <FontAwesomeIcon className="action-icon" icon={ completeIcon } />
                        <small className="action-name">Complete</small>
                    </div>
                }
            >
                <div className={ `action-dropdown-popup${ this.state.loading ? ' loading' : '' }` }>
                   { content }
                </div>
            </Popup>
        );
    }

    _formViewNotificationCenter(props) {
        return (
            <Link to={ `/rfp/${ props.routingGuideId }/notification-center` }>
                <div className="dropdown-item">
                    <FontAwesomeIcon className="action-icon" icon={ bell } />
                    <small className="action-name">
                        Notification Center
                    </small>
                </div>
            </Link>
        );
    }

    _onOpenActionsDropdown(event) {
        this.setState({ showActionsDropdown: true });
        event.stopPropagation();
    }

    _onCloseActionsDropdown() {
        this.setState({ showActionsDropdown: false });
    }

    _formActions() {
        const {
            seeAttachmentsProps,
            uploadAttachmentsProps,
            editRFPProps,
            duplicateRFPProps,
            deleteRFPProps,
            completeRGProps,
            downloadRoutingGuideProps,
            viewRFPProps,
            notificationCenterProps
        } = this.props;

        let actions = [];

        if (!seeAttachmentsProps.disabled) {
            actions.push(this._formSeeAttachments(seeAttachmentsProps));
        }

        if (!uploadAttachmentsProps.disabled) {
            actions.push(this._formUploadAttachments(uploadAttachmentsProps));
        }

        if (!editRFPProps.disabled) {
            actions.push(this._formEditRFP(editRFPProps));
        }

        if (!duplicateRFPProps.disabled) {
            actions.push(this._formDuplicateRFP(duplicateRFPProps));
        }

        if (!notificationCenterProps.disabled) {
            actions.push(this._formViewNotificationCenter(notificationCenterProps));
        }

        if (!deleteRFPProps.disabled) {
            actions.push(this._formDeleteRFP(deleteRFPProps));
        }

        if (!completeRGProps.disabled) {
            actions.push(this._formCompleteRG(completeRGProps));
        }

        if (!downloadRoutingGuideProps.disabled) {
            actions.push(this._formDownloadRoutingGuide(downloadRoutingGuideProps));
        }

        if (!viewRFPProps.disabled) {
            actions.push(this._formViewRFP());
        }

        if (actions.length === 0) {
            return <></>;
        }

        return (
            <Dropdown
                id="action-dropdown"
                direction="bottom-left"
                show={ this.state.showActionsDropdown }
                onClose={ this._onCloseActionsDropdown }
                trigger={
                    <div>
                        <Button
                            link="#action-dropdown"
                            type="tertiary"
                            onClick={ this._onOpenActionsDropdown }
                            size={ this.props.size }
                            rightIcon={ menu }
                        >
                            Actions
                        </Button>
                    </div>
                }
                actions={ actions }
            />
        );
    }

    render() {
        return (
            <div className="action-dropdown">
                { this._formActions() }
            </div>
        );
    }
}
