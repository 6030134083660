import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink as link } from '@fortawesome/pro-regular-svg-icons';
import { faFileInvoiceDollar as payment, faCloudDownloadAlt as download } from '@fortawesome/pro-solid-svg-icons';

import * as Constants from 'common/constants';
import Button from 'component/Button';
import DownloadFileComponent from 'component/DownloadFileComponent';
import MultipleSearch from 'component/filter/MultipleSearch';
import Pagination from 'component/navigation/Pagination';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableCell from 'component/table/custom/TableCell';
import PaymentDetailsApiService from 'service/api/PaymentDetailsApiService';
import NumberUtils from 'utils/NumberUtils';
import DateUtils from 'utils/DateUtils';

import './PaymentDetailsList.scss';

const SORT_OPTIONS = {
    LOAD_BUSINESS_ID: 'LOAD_BUSINESS_ID',
    PAYMENT_AMOUNT: 'PAYMENT_AMOUNT',
    PAY_DATE: 'PAY_DATE'
};

export default withRouter(class PaymentDetailsList extends Component {
    static propTypes = {
        carrierId: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            page: Constants.DEFAULT_PAGE_NUMBER,
            pageSize: Constants.DEFAULT_PAGE_SIZE,
            loading: false,
            available: 0,
            orderNumbers: []
        }

        this._setPage = this._setPage.bind(this);
        this._setPageSize = this._setPageSize.bind(this);
        this._onOrderNumberAdded = this._onOrderNumberAdded.bind(this);
        this._onOrderNumberRemoved = this._onOrderNumberRemoved.bind(this);
    }

    componentDidMount() {
        const orderNumbers = this._getOrderNumbersFromUrl();
        this.setState({ orderNumbers });
        this._fetchPaymentsData();
    }

    componentDidUpdate(prevProps, prevState) {
        const previousSort = this._getSortFromUrl(prevProps);
        const sort = this._getSortFromUrl();

        if (prevState.page !== this.state.page
            || prevState.pageSize !== this.state.pageSize
            || previousSort !== sort
            || prevState.orderNumbers !== this.state.orderNumbers) {
            this._fetchPaymentsData();
        }
    }

    _fetchPaymentsData() {
        const orderNumbers = this._getOrderNumbersFromUrl();
        const sort = this._getSortFromUrl();
        this.setState({ loading: true });
        PaymentDetailsApiService.get(this.props.carrierId, orderNumbers, sort, this.state.page, this.state.pageSize)
            .then(response => {
                this.setState({
                    payments: response.data,
                    available: response.available,
                    loading: false
                });
            });
    }

    _getSortFromUrl(props) {
        const searchProp = props ? props.location?.search : this.props.location?.search;
        return new URLSearchParams(searchProp).get('sort');
    }

    _getOrderNumbersFromUrl(props) {
        const searchProp = props ? props.location?.search : this.props.location?.search;
        return new URLSearchParams(searchProp).getAll('orderNumber');
    }

    _setPage(page) {
        this.setState({ page });
    }

    _setPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _onSort(value, direction) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        if (Constants.SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete('sort');
        } else {
            searchParams.set('sort', `${ value },${ direction }`);
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value) {
        const sort = this._getSortFromUrl();

        if (!sort) {
            return Constants.SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return Constants.SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }

    _onOrderNumberAdded(orderNumbers) {
        this.setState({ orderNumbers });

        const searchParams = new URLSearchParams(this.props.location?.search);
        searchParams.delete('orderNumber');
        orderNumbers.forEach(orderNumber => {
            searchParams.append('orderNumber', orderNumber)
        });
       
        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _onOrderNumberRemoved(orderNumbers) {
        this.setState({ orderNumbers });

        const searchParams = new URLSearchParams(this.props.location?.search);
        const newSearchParamArray = [];

        for (const [key, newValue] of searchParams.entries()) {
            if (orderNumbers.indexOf(newValue) !== -1) {
                newSearchParamArray.push([key, newValue]);
            }
        }

        const newSearchParams = new URLSearchParams(newSearchParamArray);
        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + newSearchParams.toString()
        });
    }

    _formDownloadPaymentDetails() {
        const sort = this._getSortFromUrl();
        
        const params = {
            loadIds: this.state.orderNumbers, 
            sort: sort ? [sort, ""] : null 
        };

        return (
            <DownloadFileComponent
                trigger={
                    <Button
                        className="payment-details-download"
                        type="primary"
                        size="small"
                        leftIcon={ download }
                    >
                        Download payment details
                    </Button>
                }

                reference={{ endpoint: `payment-details/${ this.props.carrierId }/csv`, params }}
                isStaticResource={ false }
                disabled={ this.state.payments.length === 0 && !this.state.loading }
            />
        );
    }

    render() {
        const orderNumbers = this._getOrderNumbersFromUrl();
        let noResultsMessage = <></>;

        if (this.state.payments.length === 0 && !this.state.loading && !orderNumbers.length) {
            return (
                <div className="carrier-payment-details empty-list">
                    <FontAwesomeIcon className="icon" icon={ payment } />
                    <h5>There are currently no payment details.</h5>
                    <p>When new payment details become available, they will be listed here.</p>
                </div>
            );
        } else if (this.state.payments.length === 0 && !this.state.loading && orderNumbers) {
            noResultsMessage = (
                <div className="no-results">
                    <p>No results matched your search.</p>
                </div>
            );
        }

        return (
            <div className="carrier-payment-details">
                <div className="carrier-payment-details-action">
                    <div className="carrier-payment-details-search">
                        <MultipleSearch
                            label="Search"
                            tooltipText={ "To search multiple Order IDs\npress the enter key after each entry" }
                            className="search-by-order-number"
                            placeholder="Search by order number..."
                            values={ this.state.orderNumbers }
                            onValueAdded={ this._onOrderNumberAdded }
                            onValueRemoved={ this._onOrderNumberRemoved }
                        />
                    </div>
                    { this._formDownloadPaymentDetails() }
                </div>
                <div className="payments-list">
                    <Table>
                        <TableRow isHeader={ true }>
                            <TableHeaderCell
                                className="load-id"
                                sortable={ true }
                                onSort={ (direction) => this._onSort(SORT_OPTIONS.LOAD_BUSINESS_ID, direction) }
                                sortDirection={ this._getSortDirection(SORT_OPTIONS.LOAD_BUSINESS_ID) }>
                                    Order Number
                            </TableHeaderCell>
                            <TableHeaderCell className="type">Pay Type</TableHeaderCell>
                            <TableHeaderCell className="description">Description</TableHeaderCell>
                            <TableHeaderCell
                                className="amount"
                                alignment="right"
                                sortable={ true }
                                onSort={ (direction) => this._onSort(SORT_OPTIONS.PAYMENT_AMOUNT, direction) }
                                sortDirection={ this._getSortDirection(SORT_OPTIONS.PAYMENT_AMOUNT) }>
                                    Amount
                            </TableHeaderCell>
                            <TableHeaderCell
                                className="pay-date"
                                alignment="right"
                                sortable={ true }
                                onSort={ (direction) => this._onSort(SORT_OPTIONS.PAY_DATE, direction) }
                                sortDirection={ this._getSortDirection(SORT_OPTIONS.PAY_DATE) }>
                                    Estimated Pay Date
                            </TableHeaderCell>
                        </TableRow>
                        { this.state.payments.map(payment => {
                            const description = payment.description || '/';
                            let loadBusinessId = payment.loadBusinessId;
                            if (payment.load) {
                                loadBusinessId = (
                                    <Link to={ `/load/${ payment.loadBusinessId }` } className="load-link">
                                        { payment.loadBusinessId }
                                        <FontAwesomeIcon icon={ link } className="load-link-icon" />
                                    </Link>
                                );
                            }

                            return (
                                <TableRow key={ payment.id } fontSize="small">
                                    <TableCell className="table-cell load-id" padding="small">{ loadBusinessId }</TableCell>
                                    <TableCell className="table-cell type" padding="small">{ payment.type }</TableCell>
                                    <TableCell className="table-cell description" padding="small" title={ description }>{ description }</TableCell>
                                    <TableCell className="table-cell amount" alignment="right" padding="small">{ NumberUtils.prefixNumber(payment.amount, '$', false, 'decimal') }</TableCell>
                                    <TableCell className="table-cell pay-date" alignment="right" padding="small">{ payment.payDate ? DateUtils.formatDate(payment.payDate) : 'In Process' }</TableCell>
                                </TableRow>
                            );
                        }) }
                    </Table>

                    { noResultsMessage }

                    <Pagination
                        pageNumber={ this.state.page }
                        pageSize={ this.state.pageSize }
                        available={ this.state.available }
                        onSetPage={ this._setPage }
                        onSetPageSize={ this._setPageSize }
                        colorTheme="light"
                    />
                </div>
            </div>
        );
    }
});
