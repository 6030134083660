import { Component } from 'react';
import PropTypes from 'prop-types';

import CustomMultipleTextInput from 'component/form/CustomMultipleTextInput';

export default class MultipleSearch extends Component {
    static propTypes = {
        label: PropTypes.string,
        tooltipText: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
        onValueAdded: PropTypes.func,
        onValueRemoved: PropTypes.func
    }

    static defaultProps = {
        label: null,
        tooltipText: null,
        className: '',
        placeholder: '',
        values: [],
        onValueAdded: () => { /* */ },
        onValueRemoved: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.state = {
            values: []
        }

        this._onValueAdded = this._onValueAdded.bind(this);
        this._onValueRemoved = this._onValueRemoved.bind(this);
    }

    _onValueAdded(newValue) {
        this.setState(prevState => ({
            values: [ ...prevState.values, newValue ]
        }), () => this.props.onValueAdded(this.state.values));
    }

    _onValueRemoved(value) {
        this.setState(prevState => {
            const values = [ ...prevState.values ];
            const index = values.indexOf(value);

            if (index > -1) {
                values.splice(index, 1);
            }

            return { values };
        }, () => this.props.onValueRemoved(this.state.values));
    }

    render() {
        const { label, tooltipText, className, placeholder, values } = this.props;

        return (
            <div className="multi-search-container">
                <CustomMultipleTextInput
                    label={ label }
                    tooltipText={ tooltipText }
                    className={ className }
                    placeholder={ placeholder }
                    values={ values }
                    onValueAdded={ this._onValueAdded }
                    onValueRemoved={ this._onValueRemoved }
                />
            </div>
        );
    }
};
