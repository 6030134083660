import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding carriers
 */
export default class CarrierApiService {

  /**
   * Gets paginated and searched carrier list
   * 
   * @param {Object} params parameters including search, status, page number, page size and sort
   * @returns {Promise}
   */
  static list(params) {
    return RestService.instance().get('carrier', params);
  }

  /**
   * Gets carrier with forwarded id
   * 
   * @param {string} id carrier id
   * @returns {Promise}
   */
  static get(id) {
    return RestService.instance().get(`carrier/${ id }`);
  }

  /**
   * Gets up to 100 members for carrier
   * 
   * @param {string} carrierId id of carrier for which members are fetched
   * @returns {Promise}
   */
     static getAllMembers(carrierId, search) {
      return this.getMembers(carrierId, search, null, null, null, null);
    }

  /**
   * Gets paginated and searched members that belong to carrier 
   * 
   * @param {string} carrierId id of carrier for which members are fetched
   * @param {string} memberSearch optional string for searching member
   * @param {boolean} active optional status which tells you to fetch members with which status
   * @param {number} pageNumber page for which members are fetched
   * @param {number} pageSize number of members fetched
   * @param {string} sort way to sort members
   * @returns {Promise}
   */
  static getMembers(carrierId, search, status, pageNumber, pageSize, sort) {
    return RestService.instance().get(`carrier/${ carrierId }/members`, { search, status, pageNumber, pageSize, sort });
  }

  /**
   * Marks user as removed 
   * 
   * @param {string} carrierId id of carrier
   * @param {string} memberId id of user to be removed
   * @returns {Promise}
   */
  static removeMember(carrierId, memberId) {
    return RestService.instance().put(`carrier/${ carrierId }/member/${ memberId }/remove`);
  }

  /**
   * Add new carrier with forwarded businessId
   * 
   * @param {string} carrierBusinessId carrier TMW code
   * @returns {Promise}
   */
  static add(carrierBusinessId) {
    return RestService.instance().post('carrier', { carrierBusinessId });
  }
  
  /**
   * Adds new members to carrier 
   * 
   * @param {string} carrierId id of carrier who gets new members
   * @param {array} userEmails list of user emails to be added to carrier
   * @returns {Promise}
   */
  static addMembers(carrierId, userEmails) {
    return RestService.instance().post(`carrier/${ carrierId }/member`, { userEmails });
  }

  /**
   * Checks if carrier has invalid documents
   * 
   * @param {string} carrierId id of carrier 
   * @returns {Promise}
   */
  static hasInvalidDocuments(carrierId) {
    return RestService.instance().get(`carrier/${ carrierId }/has-invalid-documents`);
  }
}
