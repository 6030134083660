import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown as down, faPhone as phoneIcon, faEnvelope as emailIcon } from '@fortawesome/pro-solid-svg-icons';

import Dropdown from 'component/Dropdown';

import './ContactUsPopover.scss';

export default class ContactUsPopover extends Component {
    static propTypes = {
        generalContactInformation: PropTypes.object
    };

    static defaultProps = {
        generalContactInformation: {}
    };

    constructor(props) {
        super(props);

        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
    }

    state = {
        show: false
    };

    _onOpen() {
        this.setState({ show: true });
    }

    _onClose() {
        this.setState({ show: false });
    }

    render() {
        const { email, phoneNumber } = this.props.generalContactInformation;
        return (
            <div className="contact-us-popover">
                <Dropdown
                    id="contact-us-popover"
                    direction="bottom-left"
                    show={ this.state.show }
                    centerArrowOnTrigger={ false }
                    onClose={ this._onClose }
                    trigger={(
                        <a href="#!" className={ `contact-us-icon-container${ this.state.show ? ' active' : '' }` } onClick={ this._onOpen }>
                            <FontAwesomeIcon icon={ down } className="icon" />
                            <small><b>Contact Us</b></small>
                        </a>
                    )}
                >
                    <div className="contact-us-popover-content">
                        <div className="contact-us-popover-content-row">
                            <FontAwesomeIcon icon={ phoneIcon } className="icon" />
                            <a href={ `tel:${ phoneNumber }` }>
                                <p>{ phoneNumber }</p>
                            </a>
                        </div>
                        <div className="contact-us-popover-content-row">
                            <FontAwesomeIcon icon={ emailIcon } className="icon" />
                            <a href={ `mailto:${ email }` }>
                                <p>{ email }</p>
                            </a>
                        </div>
                    </div>
                </Dropdown>
            </div>
        );
    }
}
