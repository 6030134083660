import { Component } from 'react';

import './LoadTenderingTermsOfService.scss'

export default class LoadTenderingTermsOfService extends Component { 
    render() {
        return (
            <>
                <p className="subheading">Terms and Conditions</p>

                <div className="accept-popup-terms-and-conditions">
                    <p>
                        Four Kites is required on all loads PRIOR to arrival at the shipper. Please remember to have the driver(s) download the app to allow for proper tracking to take place. Failure to accept Four Kites tracking will result in a $150 fine. Hot loads will be fined $250.
                    </p>
                    <br />

                    <p>
                        A $250 fine will be imposed if Carrier calls Shipper or Receiver to reschedule appointments. Any date/time change requests MUST go through ShipEX Logistics PRIOR to missing the given appointment/s. 
                    </p>
                    <br />

                    <p>
                        Missed or late pickup/delivery appointments will be subject to a $250 penalty per occurrence. Carrier will also be responsible for any additional cost that may be incurred for missed or late pick up/deliveries from shippers or consignees.
                    </p>
                    <br />

                    <p>
                        Before departing shipper location, Carrier's driver MUST ensure that load has been sealed by shipper. It is the carrier's responsibility to make sure the load is sealed. If there are any issues, do not leave the facility and call ShipEX Logistics immediately.
                    </p>
                    <br />

                    <p>
                        Receiver MUST sign seal intact on the bill of lading before opening trailer. Call broker immediately if receiver will not sign. 
                    </p>
                    <br />

                    <p>
                        It is Carrier's responsibility to ensure the CORRECT/MATCHING bill of lading is provided at the shipper before departing, and the CORRECT/MATCHING bill of lading is signed by receiver prior to departure. The bill of lading should always match the rate confirmation issued. This is required in order to be paid on shipment.
                    </p>
                    <br />

                    <p>
                        Carrier is responsible for immediate notification to ShipEX Logistics of any Overages, Shortage, Damage or mis-shipped product. All OS&D must be reported to ShipEX Logistics before Carrier leaves the receiver. Carrier agrees to assume all liability of loss and/or claim. 
                    </p>
                    <br />

                    <p>
                        If load is double brokered, this confirmation is null & void.
                    </p>
                    <br />

                    <p>
                        Carrier's authorized signature on this confirmation verifies carrier has insurance in the limits required by Broker for any loss or damage to shipment/liability to the general public. 
                    </p>
                    <br />

                    <p>
                        Hours of Service are sole responsibility of the CARRIER & CARRIER agrees to comply with all local, state and federal laws & regulations including but not limited to those set forth by DOT & other governing agencies. 
                    </p>
                    <br />

                    <p>
                        Carrier agrees that any refrigerated equipment will meet the Transport Refrigeration Unit (TRU) regulations. If carrier fails to do so Carrier will be liable for all fines associated with TRU's laws and regulations. 
                    </p>
                    <br />

                    <p>
                        Carrier agrees that the equipment used in the state of California will comply with the California Air Resources Board (ARB). These requirements are codified at title 13 California Code of Regulations, Sections 2477.7 through 2477.11. If carrier fails to do so, carrier will be liable for all fines associated with the ARB's laws and regulations. 
                    </p>
                    <br />

                    <p>
                        Arrival & Departure times at both shipper and receiver must be reported to Broker.
                    </p>
                    <br />

                    <p>
                        Detention is applicable after 4 hours of appointment time. Must have written proof (email) of advising broker about delays encountered prior to reaching the 4-hour threshold. This is paid at $30/hour. Not to exceed $250/day.
                    </p>
                    <br />

                    <p>
                        Layover is applicable after 8 hours of detention at $250/day for Reefer freight and $150/day for Van freight 24 hours from the original delivery date. 
                    </p>
                    <br />

                    <p>
                        Truck orders not used are issued at $150.
                    </p>
                    <br />

                    <p>
                        Lumper reimbursement must be reported to Broker within 14 hours of delivery and will require a valid receipt from the lumper. 
                    </p>
                    <br />

                    <p>
                        Any directions given by ShipEX Logistics or its Customers, whether orally and/or electronically, are for informational purposes only. It is the Carrier's sole responsibility to confirm that it may lawfully and safely operate its vehicle and its contents over any road, highway, bridge, and/or route. Carrier shall be solely responsible for any fines, penalties, or citations that may be levied as a result of operating its vehicle equipment and its content in any way that may be found to be in violation of any regulation, law or ordinance.
                    </p>
                </div>
            </>
        );
    }
}
