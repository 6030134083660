export default class ObjectUtils {

    /**
     * Removes null values from Javascript object
     *
     * @param {Object} object
     * @returns {Object} cleaned object (without keys with null values)
     */
    static removeEmptyKeys(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    }

    /**
     * Checks if two arrays of objects are equal. It checks if the passed parameters are arrays.
     * Then if checks if the length of arrays is the same and if every item equals the corresponding one in the other array
     * utiliying the {@see equal} method
     *
     * @param {Array} a1 first array to compare
     * @param {Array} a2 second array to compare
     * @returns {boolean} true if are equal, otherwise false
     */
    static arraysEqual(a1, a2) {
        if (a1 === a2) {
            return true;
        }

        if (a1.length !== a2.length) {
            return false;
        }

        for (let i = 0; i < a1.length; i++) {
            if (!this.equal(a1[i], a2[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if objects are equal
     *
     * @param {Object} o1 first object to compare
     * @param {Object} o2 second object to compare
     * @returns {boolean} true if they are equal, otherwise false
     */
    static equal(o1, o2) {
        if (o1 === o2) {
            return true;
        }

        if (o1 && o2 && typeof o1 === 'object' && Object.keys(o1).length > 0) {
            return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => ObjectUtils.equal(o1[p], o2[p]))
        }

        return JSON.stringify(o1) === JSON.stringify(o2);
    }

    /**
     * Checks if object is empty or all its properties are falsy (null, undefined, blank, 0) 
     *
     * @param {Object} object
     * @returns {boolean} true if the object is empty or all its properties are falsy, false otherwise
     */
    static isBlankOrEmpty(object) {
        return Object.values(object || {}).every(value => !value);
    }

    /**
     * Checks if all values in object are present (not empty value) and also if object is empty
     * 
     * @param {Object} object
     * @returns {boolean} true if object is not empty and if all values are not empty string, otherwise it's false
     */
    static areAllValuesPresent(object) {
        if (!object || Object.keys(object).length === 0) {
          return false;
        }
      
        return Object.values(object).every(value => !!value && value.toString().trim() !== '');
    }
}
