import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import Account from 'model/Account';
import Token from 'model/Token';
import ConfigurationService from 'service/ConfigurationService';
import RestService from 'service/RestService';

export interface AuthenticationClient {
    isAuthenticated(): Promise<boolean>;
    getAccount(): Promise<Account | undefined>;
    getRoles(): Promise<Array<string>>;
    getPermissions(): Promise<Array<string>>;
    preLogin(): Promise<void>;
    login(): void;
    logout(): void;
    getToken(forceRefresh: boolean): Promise<Token | undefined>;
    getRequestHeaders(): Promise<object>;
}

abstract class BaseAuthenticationClient<C> implements AuthenticationClient {
    protected readonly _client: C;

    protected constructor(client: C) {
        this._client = client;
    }

    abstract isAuthenticated(): Promise<boolean>;

    abstract getAccount(): Promise<Account | undefined>;

    async getPermissions(): Promise<Array<string>> {
        const account = await this.getAccount();
        return account ? account.permissions : [];
    }

    async getRoles(): Promise<Array<string>> {
        const account = await this.getAccount();
        return account ? account.roles : [];
    }

    abstract preLogin(): Promise<void>;
    abstract login(): void;
    abstract logout(): void;

    getToken(forceRefresh: boolean): Promise<Token | undefined> {
        return this._defaultToken();
    }

    abstract getRequestHeaders(): Promise<object>;

    async _defaultRolesPermissions() {
        const { roles, permissions, username } = await RestService.instance(false).get('/bootstrap/authorization') || { roles: [], permissions: [], username: "" }
        return [ roles, permissions, username ];
    }

    async _defaultToken() {
        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);

        return new Token({ accessToken: undefined, expiresOn: nextYear });
    }
}

class Auth0AuthenticationClient extends BaseAuthenticationClient<Auth0Client> {
    private readonly _clientId: string;
    private _roles: Array<string> | undefined;
    private _permissions: Array<string> | undefined;
    private _internalId: string | undefined;
    private _carrierId: string | undefined;

    // eslint-disable-next-line
    constructor(client: Auth0Client, _clientId: string) {
        super(client);
        this._clientId = _clientId;
    }

    isAuthenticated(): Promise<boolean> {
        return this._client.isAuthenticated();
    }

    async getAccount(): Promise<Account | undefined> {
        if (await this._client.isAuthenticated()) {
            const user = await this._client.getUser();

            if (user) {
                if (!this._roles && !this._permissions) {
                     const authorization = await RestService.instance(false).get('/bootstrap/authorization');

                     this._roles = authorization.roles;
                     this._permissions = authorization.permissions;
                }

                if (!this._internalId) {
                    const me = await RestService.instance(false).get('/bootstrap/me');
                    this._internalId = me.id;
                    this._carrierId = me.carrier?.id;
                }

                return new Account({
                    id: user.sub,
                    internalId: this._internalId,
                    carrierId: this._carrierId,
                    name: user.name,
                    email: user.email,
                    picture: user.picture,
                    roles: this._roles,
                    permissions: this._permissions
                });
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    async preLogin(): Promise<void> {
        const isAuthenticated = await this._client.isAuthenticated();
        const query = window.location.search;

        if (!isAuthenticated && query.includes("code=") && query.includes("state=")) {
            // Process the login state
            const redirectResult = await this._client.handleRedirectCallback();
            const appState = redirectResult.appState;

            if (appState && appState.href) {
                // Restore the location the user originally targeted, including query and hash parameters.
                window.location.replace(appState.href);
            }
        }
    }

    login(): void {
        // noinspection JSIgnoredPromiseFromCall; we should not rely on this promise.
        this._client.loginWithRedirect({
            redirect_uri: window.location.origin,
            appState: {
                href: window.location.href
            }
        });
    }

    logout(): void {
        this._client.logout({
            returnTo: window.location.origin,
            client_id: this._clientId
        });
    }

    getToken(forceRefresh: boolean): Promise<Token | undefined> {
        return this._client.getTokenSilently()
            .then(response => ({
                accessToken: response,
                expiresOn: null
            }));
    }

    async getRequestHeaders(): Promise<object> {
        if (await this.isAuthenticated()) {
            return this.getToken(false).then(result => {
                const token = (result || {}).accessToken;

                if (token) {
                    return {
                        Authorization: 'Bearer ' + token
                    };
                } else {
                    return {};
                }
            });
        }

        return {};
    }
}

class NoOpAuthenticationClient extends BaseAuthenticationClient<void> {
    private _cachedAccount: Account | undefined;

    constructor() {
        super(undefined);
    }

    async isAuthenticated(): Promise<boolean> {
        return true;
    }

    async getAccount(): Promise<Account | undefined> {
        if (!this._cachedAccount) {
            const [ roles, permissions ] = await this._defaultRolesPermissions();
            this._cachedAccount = new Account({
                id: '_anonymous',
                internalId: '00000000-0000-0000-0000-000000000000',
                carrierId: undefined,
                name: 'John Doe',
                email: 'johndoe',
                picture: undefined,
                roles: roles,
                permissions: permissions
            });
        }

        return this._cachedAccount;
    }

    async preLogin(): Promise<void> {
        return;
    }

    login(): void {
        // Nothing to do here.
    }

    logout(): void {
        // Nothing to do here.
    }

    async getRequestHeaders(): Promise<object> {
        return {};
    }
}

class BasicAuthenticationClient extends BaseAuthenticationClient<void> {
    private _userId: string | undefined;
    private _cachedAccount: Account | undefined;

    constructor() {
        super(undefined);
    }

    async isAuthenticated(): Promise<boolean> {
        return true;
    }

    async getAccount(): Promise<Account | undefined> {
        if (!this._cachedAccount) {
            const [ roles, permissions, username ] = await this._defaultRolesPermissions();
            const user = await RestService.instance(false).get('/bootstrap/me');

            this._cachedAccount = new Account({
                id: localStorage.getItem("userId") || '_basic',
                internalId: user.id || '00000000-0000-0000-0000-000000000001',
                carrierId: user.carrier?.id || undefined,
                name: username,
                email: user.email || 'basic',
                picture: undefined,
                roles: roles,
                permissions: permissions
            });
        }

        return this._cachedAccount;
    }

    async preLogin(): Promise<void> {
        return undefined;
    }

    login(): void {
        // Nothing to do here.
    }

    logout(): void {
        // Nothing to do here.
    }

    async getToken(forceRefresh: boolean): Promise<Token | undefined> {
        const defaultToken = await this._defaultToken();

        return new Token({
            accessToken: localStorage.getItem('userId') || defaultToken.accessToken,
            expiresOn: defaultToken.expiresOn
        })
    }

    async getRequestHeaders(): Promise<object> {
        this._userId = localStorage.getItem("userId") || undefined;

        if (!this._userId) {
            return {};
        }

        return {
            'X-Impersonate-User': this._userId
        };
    }
}

export default class AuthenticationService {
    static _instance: AuthenticationClient | null = null;

    static instance(): AuthenticationClient | null {
        return this._instance;
    }

    static async init(): Promise<void> {
        const authConfig = ConfigurationService.instance(false).authenticationConfig();

        if (authConfig.enabled) {
            switch (authConfig.mode) {
                case 'AUTH0':
                    this._instance = new Auth0AuthenticationClient(
                        await createAuth0Client({
                            domain: authConfig.auth0.domain,
                            client_id: authConfig.auth0.clientId,
                            audience: authConfig.auth0.audience,
                            cacheLocation: 'localstorage'
                        }),
                        authConfig.auth0.clientId
                    );
                    break;
                case 'BASIC':
                    this._instance = new BasicAuthenticationClient();
                    break;
                default:
                    throw new Error('Failed to create authentication client. Unknown mode ' + authConfig.mode);
            }
        } else {
            this._instance = new NoOpAuthenticationClient();
        }
    }
}
