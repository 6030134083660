import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LANE_TYPE_PROPERTIES } from 'common/constants';
import CanAccess from 'component/CanAccess';
import RFPLaneCardBiddingContent from 'component/card/rfp/RFPLaneCardBiddingContent';
import RFPLaneCardManagementContent from 'component/card/rfp/RFPLaneCardManagementContent';
import RoutingGuideLaneCardContent from 'component/lane/RoutingGuideLaneCardContent'
import RoutingGuideUtils from 'utils/RoutingGuideUtils';

import './RFPLaneCard.scss';

export default class RFPLaneCard extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        viewMode: PropTypes.string,
        rfpLane: PropTypes.object.isRequired,
        rejectReasons: PropTypes.array,
        onUpdated: PropTypes.func,
        version: PropTypes.number
    };

    static defaultProps = {
        viewMode: null,
        rejectReasons: [],
        onUpdated: () => {/**/},
        version: 0
    };

    _formCandidateCardContent() {
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            return (
                <CanAccess 
                    action="rfp:bid"
                    yes={
                        <RFPLaneCardBiddingContent 
                            rfp={ this.props.rfp }
                            rfpLane={ this.props.rfpLane }
                            rejectReasons={ this.props.rejectReasons }
                            version={ this.props.version }
                            onUpdated={ this.props.onUpdated }
                        />
                    }
                />
            );
        }

        return (
            <CanAccess 
                action="rfp:read"
                yes={
                    <RoutingGuideLaneCardContent 
                        routingGuideLane={ this.props.rfpLane }
                        version={ this.props.version }
                        onUpdated={ this.props.onUpdated } 
                    />
                }
            />
        );
    }

    render() {
        const laneType = this.props.rfpLane.lane.type;

        return (
            <div id={ `rfp-lane-card-${ this.props.rfpLane.id }` } className="rfp-lane-card-container">
                <div className="component rfp-lane-card">
                    <small id={ `rfp-lane-card-type-${ this.props.rfpLane.id }` } className="rfp-lane-cargo-type">
                        <FontAwesomeIcon icon={ LANE_TYPE_PROPERTIES[laneType].icon } className="rfp-lane-cargo-type-icon" />
                        <b>{ LANE_TYPE_PROPERTIES[laneType].label }</b>
                    </small>

                    <CanAccess
                        action={ ['rfp:read:all', 'routing-guide:read'] }
                        yes={
                            <RFPLaneCardManagementContent 
                                rfp={ this.props.rfp }
                                viewMode={ this.props.viewMode }
                                rfpLane={ this.props.rfpLane }
                                onUpdated={ this.props.onUpdated } 
                            />
                        }
                        no={ this._formCandidateCardContent() }
                    />
                </div>
            </div>
        );
    }
}
