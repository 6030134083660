import NumberUtils from 'utils/NumberUtils';

export default class PriceUtils {
    
    /**
     * Calculates then formats the per mile price based on the total price and lane distance.
     * 
     * @param {number} totalPrice 
     * @param {number} distance 
     * @returns formatted per mile price
     */
    static formatPerMilePrice(totalPrice, distance) {
        return NumberUtils.formatWithDecimalNotation(this.calculatePerMilePrice(totalPrice, distance));
    }

    /**
     * Calculates the per mile price based on the total price and lane distance.
     *
     * @param {number} totalPrice
     * @param {number} distance
     * @returns {number} calculated per mile price
     */
    static calculatePerMilePrice(totalPrice, distance) {
        return totalPrice / distance;
    }
}
