import { Component } from 'react';
import PropTypes from 'prop-types';

import { NOTIFICATION_CENTER_TYPES } from 'common/constants';
import CSVUpload from 'component/CSVUpload';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import StringUtils from 'utils/StringUtils';

export default class AddNotificationSubscriptionsViaCSVPopup extends Component {

    static propTypes = {
        routingGuideId: PropTypes.string,
        type: PropTypes.oneOf([NOTIFICATION_CENTER_TYPES.RFP_MANAGERS, NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS]),
        size: PropTypes.oneOf(['regular', 'small']),
        onCreated: PropTypes.func
    };

    static defaultProps = {
        routingGuideId: null,
        type: NOTIFICATION_CENTER_TYPES.RFP_MANAGERS,
        size: 'regular',
        onCreated: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this.state = {
            numberOfAddedItems: null,
            errorMessage: null
        };

        this._uploadCSV = this._uploadCSV.bind(this);
        this._onResponseReceived = this._onResponseReceived.bind(this);
    }

    _uploadCSV(file, delimiter) {
        const formData = new FormData();
        formData.append('document', file);

        switch (this.props.type) {
            case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                return RoutingGuideNotificationApiService.addRFPManagersViaCsv(this.props.routingGuideId, formData, delimiter);
            case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                return RoutingGuideNotificationApiService.addDefaultLogisticsCoordinatorsViaCsv(this.props.routingGuideId, formData, delimiter);
            default:
                // This should never happen
                console.error(`Unrecognized type ${ this.props.type }`);
        }
    }

    _onResponseReceived(numberOfAddedItems, errorMessage) {
        this.setState({ numberOfAddedItems, errorMessage });
    }

    _getUploadPopupProps() {
        const loadingDescription = 'This will only take a moment. Please wait...';
        const title = 'Notification Center';
        const buttonText = 'Done';
        const loadingText = 'Adding Users';
        const successText = 'Users Added';
        const emptySuccessText = 'No new Users Added';
        const triggerText = 'Upload CSV File';
        const templateReference = RoutingGuideNotificationApiService.SUBSCRIPTIONS_CSV_TEMPLATE_ENDPOINT;

        let description;

        switch (this.props.type) {
            case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                description = (
                    <div className="csv-upload-descriptipn">
                        <p>
                            Subscribe RFP Managers to receive all RFP related notifications for this routing guide by uploading a CSV file. File needs to contain column <b>User Email</b>.
                        </p>
                    </div>
                );
                break;
            case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                description = (
                    <div className="csv-upload-descriptipn">
                        <p>
                            Subscribe Brokers to receive notifications for loads on lanes not covered by lane specific LCs by uploading a CSV file. File needs to contain column <b>User Email</b>.
                        </p>
                    </div>
                );
                break;
            default:
                // This should never happen
                console.error(`Unrecognized type ${ this.props.type }`);
        }

        const labels = { title, buttonText, triggerText };

        const loadingElement = (
            <div>
                <h6>{ loadingText }</h6>
                <small>{ loadingDescription }</small>
            </div>
        );

        const { numberOfAddedItems } = this.state;

        const successDescription = (
            <>
                <b>{ numberOfAddedItems }</b> { StringUtils.pluralize(numberOfAddedItems, 'user', 's') } { numberOfAddedItems === 1 ? 'has': 'have' } been successfully added.
            </>
        );

        const successElement = (
            <div>
                <h6>{ successText }</h6>
                <small>{ successDescription }</small>
            </div>
        );

        const emptySuccessElement = (
            <div>
                <h6>{ emptySuccessText }</h6>
                <small>
                    The list of users remains unchanged.<br />
                    If this is unexpected, make sure all of the rows in the CSV file contain new and valid data.
                </small>
            </div>
        );

        const errorElement = (
            <div>
                <h6>File Not Uploaded</h6>
                <small>
                    { this.state.errorMessage }
                    <br />
                    <br />
                    Please make sure that the uploaded CSV file contains the required column,
                    that all rows contain valid data, and that you have selected the correct delimiter.
                </small>
            </div>
        );

        return {
            labels,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        };
    }

    render() {
        const {
            labels,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        } = this._getUploadPopupProps();

        return (
            <CSVUpload 
                size={ this.props.size }
                withUnloadConfirmation={ true }
                labels={ labels }
                triggerButtonType="tertiary"
                description={ description }
                loadingElement={ loadingElement }
                successElement={ successElement }
                emptySuccessElement={ emptySuccessElement }
                errorElement={ errorElement }
                uploadCSVPromise={ this._uploadCSV }
                onResponseReceived={ this._onResponseReceived }
                onCreated={ this.props.onCreated }
                templateReference={ templateReference } 
            />
        );
    }
}
