import { Component } from 'react';
import PropTypes from 'prop-types';

import AddCarrier from 'component/AddCarrier';
import RestService from 'service/RestService';

export default class RFPAddCarrier extends Component {

    static propTypes = {
        rfpId: PropTypes.string.isRequired,
        size: PropTypes.oneOf(['small', 'regular']),
        onCreated: PropTypes.func
    };

    static defaultProps = {
        size: 'regular',
        onCreated: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._addCarrier = this._addCarrier.bind(this);
        this._onValueChanged = this._onValueChanged.bind(this);
    }

    state = {
        showPopup: false,
        carrierBusinessId: undefined,
        status: null,
        errorMessage: null
    };

    _addCarrier(carrierBusinessId) {
        const data = { carrierBusinessId: carrierBusinessId.trim() };
        return RestService.instance().post(`auction/rfp/${ this.props.rfpId }/candidate`, data);
    }

    _onValueChanged(carrierBusinessId) {
        this.setState({ carrierBusinessId });
    }

    render() {
        const successElement = (
            <div>
                <h6>Carrier Added</h6>
                <p>Carrier with carrier code <b>{ this.state.carrierBusinessId }</b> has been added to the RFP.</p>
            </div>
        );

        return (
            <AddCarrier
                buttonSize={ this.props.size }
                successElement={ successElement }
                addCarrier={ this._addCarrier }
                onCreated={ this.props.onCreated }
                onValueChanged={ this._onValueChanged }
            />
        );
    }
}
