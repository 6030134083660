import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset as activeLCIcon, faClipboardList as activeRFPManagersIcon } from '@fortawesome/pro-solid-svg-icons';
import { faTrash as deleteIcon } from '@fortawesome/pro-regular-svg-icons';
import { faHeadset as inactiveLCIcon, faClipboardList as inactiveRFPManagersIcon } from '@fortawesome/pro-light-svg-icons';

import { 
    DEFAULT_PAGE_NUMBER, 
    DEFAULT_PAGE_SIZE, 
    LANE_TYPE_PROPERTIES,
    NOTIFICATION_CENTER_TYPES,
    NOTIFICATION_SUBSCRIPTION_TYPES, 
    RFP_AUCTION_STATUSES, 
    SORT_DIRECTIONS 
} from 'common/constants';
import Button from 'component/Button';
import AddLaneSpecificLogisticsCoordinatorsPopup from 'component/popups/AddLaneSpecificLogisticsCoordinatorsPopup';
import AddNotificationSubscriptionsManuallyPopup from 'component/popups/AddNotificationSubscriptionsManuallyPopup';
import AddNotificationSubscriptionsViaCSVPopup from 'component/popups/AddNotificationSubscriptionsViaCSVPopup';
import Dropdown from 'component/Dropdown';
import Search from 'component/filter/Search';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Pagination from 'component/navigation/Pagination';
import Tab from 'component/navigation/Tab';
import CanAccess from 'component/CanAccess';
import PageHeader from 'component/PageHeader';
import Popup from 'component/Popup';
import Table from 'component/table/custom/Table';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableCell from 'component/table/custom/TableCell';
import TableRow from 'component/table/custom/TableRow';
import { ReactComponent as UsersIcon } from 'file/svg/users_icon.svg';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import AuthorizationService from 'service/AuthorizationService';
import DateUtils from 'utils/DateUtils';
import LaneUtils from 'utils/LaneUtils';

import './NotificationCenter.scss';

const SORT_OPTIONS = {
    CREATED: 'CREATED'
};

class NotificationCenter extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired
    };

    _hasNotificationsWritePermission = AuthorizationService.instance().canUserAccess(this.props.account, 'notification-subscriptions:write');

    constructor(props) {
        super(props);

        this._fetchRFPManagers = this._fetchRFPManagers.bind(this);
        this._fetchLaneSpecificLogisticsCoordinators = this._fetchLaneSpecificLogisticsCoordinators.bind(this);
        this._fetchDefaultLogisticsCoordinators = this._fetchDefaultLogisticsCoordinators.bind(this);
        this._fetchSubscriptions = this._fetchSubscriptions.bind(this);

        this._onRfpManagersPageNumberChange = this._onRfpManagersPageNumberChange.bind(this);
        this._onRfpManagersPageSizeChange = this._onRfpManagersPageSizeChange.bind(this);
        this._onLaneSpecificLCPageNumberChange = this._onLaneSpecificLCPageNumberChange.bind(this);
        this._onLaneSpecificLCPageSizeChange = this._onLaneSpecificLCPageSizeChange.bind(this);
        this._onDefaultLCPageNumberChange = this._onDefaultLCPageNumberChange.bind(this);
        this._onDefaultLCPageSizeChange = this._onDefaultLCPageSizeChange.bind(this);

        this._onRFPManagerSearchChanged = this._onRFPManagerSearchChanged.bind(this);
        this._onLaneSpecificLCSearchChanged = this._onLaneSpecificLCSearchChanged.bind(this);
        this._onDefaultLCSearchChanged = this._onDefaultLCSearchChanged.bind(this);

        this._onCloseRemoveSubscriptionPopup = this._onCloseRemoveSubscriptionPopup.bind(this);
        this._onRemoveSubscription = this._onRemoveSubscription.bind(this);

        this._openAdditionalInformationPopover = this._openAdditionalInformationPopover.bind(this);
        this._closeAdditionalInformationPopover = this._closeAdditionalInformationPopover.bind(this);

        this._getSortDirection = this._getSortDirection.bind(this);
    }

    state = {
        rfp: null,
        activeTab: this._getValidTab(),
        rfpManagers: {
            data: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
            search: null
        },
        laneSpecificLC: {
            data: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
            search: null
        },
        defaultLC: {
            data: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
            search: null
        },
        generalContactInformation: {},
        showRemoveSubscriptionPopup: false,
        removeSubscriptionId: null,
        showAdditionalInformationPopover: false,
        additionalInformationId: null
    };

    componentDidMount() {
        const rfpId = this.props.match.params.id;
        this._fetchRFP(rfpId);
        this._fetchSubscriptions(rfpId);
        this._fetchGeneralContactInformation();
    }

    componentDidUpdate(prevProps, prevState) {
        const previousSortRFPManager = this._getParamFromUrl(prevProps, 'sort');
        const sortRFPManager = this._getParamFromUrl(this.props, 'sort');

        const previousSortSpecific = this._getParamFromUrl(prevProps, 'sortSpecific');
        const sortSpecific = this._getParamFromUrl(this.props, 'sortSpecific');

        const previousSortDefault = this._getParamFromUrl(prevProps, 'sortDefault');
        const sortDefault = this._getParamFromUrl(this.props, 'sortDefault');

        const sortChange = previousSortRFPManager !== sortRFPManager
            || previousSortSpecific !== sortSpecific 
            || previousSortDefault !== sortDefault;

        if (prevProps.location.pathname !== this.props.location.pathname || sortChange) {
            const rfpId = this.props.match.params.id;
            this.setState({ 
                activeTab: this._getValidTab(),
                rfpManagers: { ...prevState.rfpManagers, search: null },
                laneSpecificLC: { ...prevState.laneSpecificLC, search: null },
                defaultLC: { ...prevState.defaultLC, search: null }
            }, () => this._fetchSubscriptions(rfpId));
        }
    }

    _getValidTab() {
        const activeTab = this.props.location.pathname.split('/').pop();
        
        for (const tab in NOTIFICATION_CENTER_TYPES) {
            if (NOTIFICATION_CENTER_TYPES[tab] === activeTab) {
                return activeTab;
            }
        }

        return NOTIFICATION_CENTER_TYPES.RFP_MANAGERS;
    }

    _fetchRFP(id) {
        RFPAuctionApiService.get(id)
            .then(rfp => this.setState({ rfp }))
            .catch(() => this.props.history.replace('/404'));
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _fetchRFPManagers(optionalId) {
        const rfpId = optionalId ? optionalId : this.state.rfp.id;
        const sort = this._getParamFromUrl(this.props, 'sort');
        const { search, pageNumber, pageSize } = this.state.rfpManagers;

        const params = {
            type: NOTIFICATION_SUBSCRIPTION_TYPES.RFP_MANAGER,
            search,
            pageNumber,
            pageSize,
            sort: sort ? [sort, ''] : null
        };

        RoutingGuideNotificationApiService.filter(rfpId, params)
            .then(rfpManagers => this.setState({ rfpManagers: { ...rfpManagers, search } }))
            .catch(error => console.error('An error occurred while fetching RFP Managers.', error));
    }

    _fetchLaneSpecificLogisticsCoordinators(optionalId) {
        const rfpId = optionalId ? optionalId : this.state.rfp.id;
        const { search, pageNumber, pageSize } = this.state.laneSpecificLC;
        const sort = this._getParamFromUrl(this.props, 'sortSpecific');

        const params = {
            type: NOTIFICATION_SUBSCRIPTION_TYPES.LANE_SPECIFIC_LOGISTICS_COORDINATOR,
            search,
            pageNumber,
            pageSize,
            sort: sort ? [sort, ''] : null
        };

        RoutingGuideNotificationApiService.filter(rfpId, params)
            .then(laneSpecificLC => this.setState({ laneSpecificLC: { ...laneSpecificLC, search } }))
            .catch(error => console.error('An error occurred while fetching lane specific logistics coordinators.', error));
    }

    _fetchDefaultLogisticsCoordinators(optionalId) {
        const rfpId = optionalId ? optionalId : this.state.rfp.id;
        const { search, pageNumber, pageSize } = this.state.defaultLC;
        const sort = this._getParamFromUrl(this.props, 'sortDefault');

        const params = {
            type: NOTIFICATION_SUBSCRIPTION_TYPES.DEFAULT_LOGISTICS_COORDINATOR,
            search,
            pageNumber,
            pageSize,
            sort: sort ? [sort, ''] : null
        };

        RoutingGuideNotificationApiService.filter(rfpId, params)
            .then(defaultLC => this.setState({ defaultLC: { ...defaultLC, search } }))
            .catch(error => console.error('An error occurred while fetching default logistics coordinators.', error));
    }

    _fetchSubscriptions(optionalId) {
        const rfpId = optionalId ? optionalId : this.state.rfp.id;
        
        switch (this.state.activeTab) {
            case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                this._fetchRFPManagers(rfpId);
                break;
            case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                this._fetchLaneSpecificLogisticsCoordinators(rfpId);
                this._fetchDefaultLogisticsCoordinators(rfpId);
                break;
            default:
                // Nothing to do here
                console.error(`Unrecognized tab ${ this.state.activeTab }`);
        }
    }

    _fetchGeneralContactInformation() {
        RoutingGuideNotificationApiService.getGeneralContactInformation()
            .then(generalContactInformation => this.setState({ generalContactInformation }))
            .catch(error => console.error('An error occurred while fetching general contact information.', error));
    }

    _onRfpManagersPageNumberChange(pageNumber) {
        this.setState(previousState => ({
            rfpManagers: {
                ...previousState.rfpManagers,
                pageNumber
            }
        }), this._fetchRFPManagers);
    }

    _onRfpManagersPageSizeChange(pageSize) {
        this.setState(previousState => ({
            rfpManagers: {
                ...previousState.rfpManagers,
                pageSize
            }
        }), this._fetchRFPManagers);
    }

    _onLaneSpecificLCPageNumberChange(pageNumber) {
        this.setState(previousState => ({
            laneSpecificLC: {
                ...previousState.laneSpecificLC,
                pageNumber
            }
        }), this._fetchLaneSpecificLogisticsCoordinators);
    }

    _onLaneSpecificLCPageSizeChange(pageSize) {
        this.setState(previousState => ({
            laneSpecificLC: {
                ...previousState.laneSpecificLC,
                pageSize
            }
        }), this._fetchLaneSpecificLogisticsCoordinators);
    }

    _onDefaultLCPageNumberChange(pageNumber) {
        this.setState(previousState => ({
            defaultLC: {
                ...previousState.defaultLC,
                pageNumber
            }
        }), this._fetchDefaultLogisticsCoordinators);
    }

    _onDefaultLCPageSizeChange(pageSize) {
        this.setState(previousState => ({
            defaultLC: {
                ...previousState.defaultLC,
                pageSize
            }
        }), this._fetchDefaultLogisticsCoordinators);
    }

    _onRFPManagerSearchChanged(search) {
        this.setState(previousState => ({
            rfpManagers: {
                ...previousState.rfpManagers,
                search
            }
        }), this._fetchRFPManagers);
    }

    _onLaneSpecificLCSearchChanged(search) {
        this.setState(previousState => ({
            laneSpecificLC: {
                ...previousState.laneSpecificLC,
                search
            }
        }), this._fetchLaneSpecificLogisticsCoordinators);
    }

    _onDefaultLCSearchChanged(search) {
        this.setState(previousState => ({
            defaultLC: {
                ...previousState.defaultLC,
                search
            }
        }), this._fetchDefaultLogisticsCoordinators);
    }

    _onSort(value, direction, field) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        if (SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete(field);
        } else {
            searchParams.set(field, `${ value },${ direction }`);
        }

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value, field) {
        const sort = this._getParamFromUrl(this.props, field);

        if (!sort) {
            return SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }

    _onOpenRemoveSubscriptionPopup(subscriptionId) {
        this.setState({ showRemoveSubscriptionPopup: true, removeSubscriptionId: subscriptionId });
    }

    _onCloseRemoveSubscriptionPopup() {
        this.setState({ showRemoveSubscriptionPopup: false });
    }

    _onRemoveSubscription() {
        RoutingGuideNotificationApiService.removeSubscription(this.state.rfp.id, this.state.removeSubscriptionId)
            .then(this._onCloseRemoveSubscriptionPopup)
            .then(this._fetchSubscriptions);
    }

    _openAdditionalInformationPopover(id) {
        this.setState({ showAdditionalInformationPopover: true, additionalInformationId: id });
    }

    _closeAdditionalInformationPopover() {
        this.setState({ showAdditionalInformationPopover: false });
    }

    _formRemoveSubscriptionConfirmation(subscription) {
        return (
            <Popup
                id={ `remove-subscription-popup-${ subscription.id }` }
                closeButton={ false }
                show={ this.state.showRemoveSubscriptionPopup && this.state.removeSubscriptionId === subscription.id }
                onClose={ this._onCloseRemoveSubscriptionPopup }
                trigger={
                    <a href="#remove-subscription-popup">
                        <FontAwesomeIcon
                            className="remove-subscription-action"
                            icon={ deleteIcon }
                            onClick={ () => this._onOpenRemoveSubscriptionPopup(subscription.id) }
                        />
                    </a>
                }
            >
                <div className="remove-subscription-popup">
                    <p className="heading">Remove User</p>
                    <p className="description">Are you sure you want to remove user <b>{ subscription.user.name }</b> from this subscription list?</p>
                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._onCloseRemoveSubscriptionPopup }>
                            Cancel
                        </Button>

                        <Button type="danger" size="small" onClick={ this._onRemoveSubscription }>
                            Remove
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }

    _formBreadcrumbs() {
        return (
            <Breadcrumbs crumbs={ [
                { path: '/rfp/list', name: 'Routing Guides', backCrumb: false },
                { path: `/rfp/${ this.state.rfp?.id }`, name: this.state.rfp?.name, backCrumb: false },
                { path: '', name: 'Notification Center', backCrumb: false },
                { path: `/rfp/${ this.state.rfp?.id }`, name: `Back to ${ this.state.rfp?.name }`, backCrumb: true }
            ] } />
        );
    }

    _formAdditionalInformationPopover(id, title, information) {
        return (
            <div className="additional-information-popover">
                <Dropdown
                    id={ `additional-information-dropdown-${ id }` }
                    direction="right"
                    show={ this.state.showAdditionalInformationPopover && this.state.additionalInformationId === id }
                    onClose={ this._closeAdditionalInformationPopover }
                    trigger={(
                        <a href="#!" className="popover-icon-container" onClick={ () => this._openAdditionalInformationPopover(id) }>
                            { title }
                        </a>
                    )}
                >
                    <div className="additional-information-content">
                        <div>
                            <sub className="additional-information-content-title">Full Name</sub>
                            <small><b>{ information.name }</b></small>
                        </div>
                        <div>
                            <sub className="additional-information-content-title">Email</sub>
                            <small><b>{ information.email }</b></small>
                        </div>
                        <div>
                            <sub className="additional-information-content-title">Phone</sub>
                            <small><b>{ information.phoneNumber || '----' }</b></small>
                        </div>
                    </div>
                </Dropdown>
            </div>
        );
    }

    _formSearchResultsDisplayedEmptyState() {
        return (
            <div className="empty-list">
                <UsersIcon className="empty-list-icon" />
                <strong><b>No results matched your search.</b></strong>
            </div>
        );
    }

    _formRFPManagersContent() {
        const isRoutingGuideCompleted = RFP_AUCTION_STATUSES.COMPLETED === this.state.rfp?.status;
        const searchResultsDisplayed = !!this.state.rfpManagers.search;

        const summary = (
            <div className="summary">
                <strong><b>RFP Managers</b></strong>
                <small>All RFP Managers will have access to view and modify this RFP. However, only individuals who have been added here will be notified of any RFP-related updates or notifications.</small>
            </div>
        );

        let content, actions;

        if (this.state.rfpManagers.available === 0 && !searchResultsDisplayed) {
            content = (
                <div className="empty-list">
                    <UsersIcon className="empty-list-icon" />
                    <strong><b>No RFP Managers Added</b></strong>

                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={
                                <>
                                    <p className="empty-list-description">To proceed, please add RFP Managers to this routing guide.</p>
                                    <div className="subscriptions-add-actions">
                                        <AddNotificationSubscriptionsViaCSVPopup 
                                            type={ this.props.activeTab } 
                                            routingGuideId={ this.state.rfp?.id } 
                                            size="small"
                                            onCreated={ this._fetchRFPManagers }
                                        />
                                        <AddNotificationSubscriptionsManuallyPopup 
                                            type={ this.state.activeTab } 
                                            routingGuideId={ this.state.rfp?.id }
                                            size="small"
                                            onSubmit={ this._fetchRFPManagers } 
                                        />
                                    </div>
                                </>
                            }
                        />
                    }
                </div>
            );
        } else {
            actions = (
                <div className="notification-subscriptions-actions">
                    <Search 
                        placeholder="Search by RFP Manager's name, email address..." 
                        label="Search" 
                        allowClear={ true }
                        updateUrl={ false }
                        onSubmit={ this._onRFPManagerSearchChanged }
                    />
                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={ 
                                <>
                                    <AddNotificationSubscriptionsViaCSVPopup 
                                        type={ this.state.activeTab } 
                                        routingGuideId={ this.state.rfp?.id } 
                                        onCreated={ this._fetchRFPManagers }
                                    />
                                    <AddNotificationSubscriptionsManuallyPopup 
                                        type={ this.state.activeTab } 
                                        routingGuideId={ this.state.rfp?.id }
                                        onSubmit={ this._fetchRFPManagers } 
                                    />
                                </>
                            }
                        />
                    }
                </div>
            );

            if (this.state.rfpManagers.available === 0 && searchResultsDisplayed) {
                content = this._formSearchResultsDisplayedEmptyState();
            } else {
                const showEditAction = !isRoutingGuideCompleted && this._hasNotificationsWritePermission;
                content = (
                    <>
                        <Table>
                            <TableRow isHeader={ true }>
                                <TableHeaderCell>RFP Manager</TableHeaderCell>
                                <TableHeaderCell>Email</TableHeaderCell>
                                <TableHeaderCell 
                                    alignment={ showEditAction ? 'left' : 'right' }
                                    sortable={ true }
                                    onSort={ direction => this._onSort(SORT_OPTIONS.CREATED, direction, 'sort') }
                                    sortDirection={ this._getSortDirection(SORT_OPTIONS.CREATED, 'sort') }
                                >
                                    Date Added
                                </TableHeaderCell>
                                { showEditAction && <TableHeaderCell alignment="center">Actions</TableHeaderCell> }
                            </TableRow>
    
                            { this.state.rfpManagers.data.map(subscription => {
                                return (
                                    <TableRow key={ subscription.id } fontSize="small">
                                        <TableCell 
                                            id={ `subscription-name-${ subscription.id }` } 
                                            className="subscription-name" 
                                            title={ subscription.user.name }
                                            padding="small"
                                        >
                                            { subscription.user.name }
                                        </TableCell>
                                        <TableCell 
                                            id={ `subscription-email-${ subscription.id }` } 
                                            className="subscription-email" 
                                            title={ subscription.user.email }
                                            padding="small"
                                        >
                                            { subscription.user.email }
                                        </TableCell>
                                        <TableCell id={ `subscription-date-added-${ subscription.id }` } padding="small" alignment={ showEditAction ? 'left' : 'right' }>
                                            { DateUtils.format(new Date(subscription.created)) }
                                        </TableCell>
                                        { showEditAction && (
                                            <TableCell id={ `subscription-remove-${ subscription.id }` } alignment="center" padding="small">
                                                { this._formRemoveSubscriptionConfirmation(subscription) }
                                            </TableCell> 
                                        ) }
                                    </TableRow>
                                );
                            }) }
                        </Table>
    
                        <Pagination 
                            pageSize={ this.state.rfpManagers.pageSize }
                            pageNumber={ this.state.rfpManagers.pageNumber }
                            available={ this.state.rfpManagers.available }
                            onSetPage={ this._onRfpManagersPageNumberChange }
                            onSetPageSize={ this._onRfpManagersPageSizeChange }
                            fixed={ false }
                            colorTheme="light" 
                            resultsText="RFP managers"
                        />
                    </>
                );
            }
        }

        return (
            <div className="notification-center-tab-section">
                { summary }
                <div className="notification-center-tab-content">
                    { actions }
                    { content }
                </div>
            </div>
        );
    }

    _formLaneSpecificLogisticsCoordinatorsContent() {
        const isRoutingGuideCompleted = RFP_AUCTION_STATUSES.COMPLETED === this.state.rfp?.status;
        const searchResultsDisplayed = !!this.state.laneSpecificLC.search;

        const summary = (
            <div className="summary">
                <strong><b>Lane Specific Coordinator</b></strong>
                <small>Users will be assigned as logistics coordinators to specific lanes and receive notifications for loads on those lanes.</small>
            </div>
        );

        let content, actions;

        if (this.state.laneSpecificLC.available === 0 && !searchResultsDisplayed) {
            content = (
                <div className="empty-list">
                    <UsersIcon className="empty-list-icon" />
                    <strong><b>No Lane Specific Coordinators Added</b></strong>

                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={
                                <>
                                    <p className="empty-list-description">To proceed, please add lane specific coordinators to this routing guide.</p>
                                    <div className="subscriptions-add-actions">
                                        <AddLaneSpecificLogisticsCoordinatorsPopup 
                                            routingGuideId={ this.state.rfp?.id }
                                            size="small"
                                            onSubmit={ this._fetchLaneSpecificLogisticsCoordinators } 
                                        />
                                    </div>
                                </>
                            }
                        />
                    }
                </div>
            );
        } else {
            actions = (
                <div className="notification-subscriptions-actions">
                    <Search 
                        placeholder="Search by LC's name, email address, origin, destination..." 
                        label="Search" 
                        allowClear={ true }
                        updateUrl={ false }
                        onSubmit={ this._onLaneSpecificLCSearchChanged }
                    />
                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={ 
                                <AddLaneSpecificLogisticsCoordinatorsPopup 
                                    routingGuideId={ this.state.rfp?.id }
                                    onSubmit={ this._fetchLaneSpecificLogisticsCoordinators } 
                                />
                            }
                        />
                    }
                </div>
            );
            
            if (this.state.laneSpecificLC.available === 0 && searchResultsDisplayed) {
                content = this._formSearchResultsDisplayedEmptyState();
            } else {
                const showEditAction = !isRoutingGuideCompleted && this._hasNotificationsWritePermission;
                content = (
                    <>
                        <Table>
                            <TableRow isHeader={ true }>
                                <TableHeaderCell>User</TableHeaderCell>
                                <TableHeaderCell>Origin</TableHeaderCell>
                                <TableHeaderCell>Destination</TableHeaderCell>
                                <TableHeaderCell>Cargo Type</TableHeaderCell>
                                <TableHeaderCell 
                                    alignment={ showEditAction ? 'left' : 'right' }
                                    sortable={ true }
                                    onSort={ direction => this._onSort(SORT_OPTIONS.CREATED, direction, 'sortSpecific') }
                                    sortDirection={ this._getSortDirection(SORT_OPTIONS.CREATED, 'sortSpecific') }
                                >
                                    Date Added
                                </TableHeaderCell>
                                { showEditAction && <TableHeaderCell alignment="center">Actions</TableHeaderCell> }
                            </TableRow>
    
                            { this.state.laneSpecificLC.data.map(subscription => {
                                const origin = !subscription.origin ? 'Any origin' : LaneUtils.formatRegion(subscription.origin);
                                const destination = !subscription.destination ? 'Any destination' : LaneUtils.formatRegion(subscription.destination);
                                const laneType = LANE_TYPE_PROPERTIES[subscription.laneType]?.label || 'Any Type';

                                const additionalInformation = this._formAdditionalInformationPopover(
                                    subscription.id,
                                    subscription.user.name,
                                    subscription.user
                                );
    
                                return (
                                    <TableRow key={ subscription.id } fontSize="small">
                                        <TableCell 
                                            className="lane-specific-logistics-coordinator-cell subscription-name" 
                                            title={ subscription.user.name }
                                            padding="small"
                                        >
                                            { additionalInformation }
                                        </TableCell>
                                        <TableCell padding="small" className="lane-specific-logistics-coordinator-cell" title={ origin }>
                                            { origin }
                                        </TableCell>
                                        <TableCell padding="small" className="lane-specific-logistics-coordinator-cell" title={ destination }>
                                            { destination }
                                        </TableCell>
                                        <TableCell padding="small" className="lane-specific-logistics-coordinator-cell" title={ laneType }>
                                            { laneType }
                                        </TableCell>
                                        <TableCell padding="small" className="lane-specific-logistics-coordinator-cell" alignment={ showEditAction ? 'left' : 'right' }>
                                            { DateUtils.format(new Date(subscription.created)) }
                                        </TableCell>
                                        { showEditAction && (
                                            <TableCell id={ `subscription-remove-${ subscription.id }` } alignment="center" padding="small">
                                                { this._formRemoveSubscriptionConfirmation(subscription) }
                                            </TableCell> 
                                        ) }
                                    </TableRow>
                                );
                            }) }
                        </Table>
    
                        <Pagination 
                            pageSize={ this.state.laneSpecificLC.pageSize }
                            pageNumber={ this.state.laneSpecificLC.pageNumber }
                            available={ this.state.laneSpecificLC.available }
                            onSetPage={ this._onLaneSpecificLCPageNumberChange }
                            onSetPageSize={ this._onLaneSpecificLCPageSizeChange }
                            fixed={ false }
                            colorTheme="light"
                            resultsText="logistics coordinators"
                        />
                    </>
                );
            }
        }
        
        return (
            <div className="notification-center-tab-section">
                { summary }
                <div className="notification-center-tab-content">
                    { actions }
                    { content }
                </div>
            </div>
        );
    }

    _formDefaultLogisticsCoordinatorsContent() {
        const isRoutingGuideCompleted = RFP_AUCTION_STATUSES.COMPLETED === this.state.rfp?.status;
        const searchResultsDisplayed = !!this.state.defaultLC.search;

        const generalContactInformation = this._formAdditionalInformationPopover(
            'general-contact-information',
            <small><b>General Contact Information for Carriers</b></small>,
            this.state.generalContactInformation
        );

        const summary = (
            <div className="summary">
                <strong><b>Default Coordinator</b></strong>
                <small>Users will be assigned to any lanes not specified in the Lane Specific section, and will be receiving notifications for loads on those lanes.</small>
                { generalContactInformation }
            </div>
        );

        let content, actions;

        if (this.state.defaultLC.available === 0 && !searchResultsDisplayed) {
            content = (
                <div className="empty-list">
                    <UsersIcon className="empty-list-icon" />
                    <strong><b>No Default Coordinators Added</b></strong>

                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={
                                <>
                                    <p className="empty-list-description">To proceed, please add default coordinators to this routing guide.</p>
                                    <div className="subscriptions-add-actions">
                                        <AddNotificationSubscriptionsViaCSVPopup 
                                            type={ this.props.activeTab } 
                                            routingGuideId={ this.state.rfp?.id } 
                                            size="small"
                                            onCreated={ this._fetchDefaultLogisticsCoordinators }
                                        />
                                        <AddNotificationSubscriptionsManuallyPopup 
                                            type={ this.state.activeTab } 
                                            routingGuideId={ this.state.rfp?.id }
                                            size="small"
                                            onSubmit={ this._fetchDefaultLogisticsCoordinators } 
                                        />
                                    </div>
                                </>
                            }
                        />
                    }
                </div>
            );
        } else {
            actions = (
                <div className="notification-subscriptions-actions">
                    <Search 
                        placeholder="Search by LC's name, email address..." 
                        label="Search" 
                        allowClear={ true }
                        updateUrl={ false }
                        onSubmit={ this._onDefaultLCSearchChanged }
                    />
                    { !isRoutingGuideCompleted && 
                        <CanAccess
                            action="notification-subscriptions:write"
                            yes={ 
                                <>
                                    <AddNotificationSubscriptionsViaCSVPopup 
                                        type={ this.state.activeTab } 
                                        routingGuideId={ this.state.rfp?.id } 
                                        onCreated={ this._fetchDefaultLogisticsCoordinators }
                                    />
                                    <AddNotificationSubscriptionsManuallyPopup 
                                        type={ this.state.activeTab } 
                                        routingGuideId={ this.state.rfp?.id }
                                        onSubmit={ this._fetchDefaultLogisticsCoordinators } 
                                    />
                                </>
                            }
                        />
                    }
                </div>
            );

            if (this.state.defaultLC.available === 0 && searchResultsDisplayed) {
                content = this._formSearchResultsDisplayedEmptyState();
            } else {
                const showEditAction = !isRoutingGuideCompleted && this._hasNotificationsWritePermission;
                content = (
                    <>
                        <Table>
                            <TableRow isHeader={ true }>
                                <TableHeaderCell>User</TableHeaderCell>
                                <TableHeaderCell 
                                    alignment={ showEditAction ? 'left' : 'right' }
                                    sortable={ true }
                                    onSort={ direction => this._onSort(SORT_OPTIONS.CREATED, direction, 'sortDefault') }
                                    sortDirection={ this._getSortDirection(SORT_OPTIONS.CREATED, 'sortDefault') }
                                >
                                    Date Added
                                </TableHeaderCell>
                                { showEditAction && <TableHeaderCell alignment="center">Actions</TableHeaderCell> }
                            </TableRow>

                            { this.state.defaultLC.data.map(subscription => {
                                const additionalInformation = this._formAdditionalInformationPopover(
                                    subscription.id,
                                    subscription.user.name,
                                    subscription.user
                                );

                                return (
                                    <TableRow key={ subscription.id } fontSize="small">
                                        <TableCell 
                                            id={ `subscription-name-${ subscription.id }` } 
                                            className="subscription-name default-logistics-coordinator-name" 
                                            title={ subscription.user.name }
                                            padding="small"
                                        >
                                            { additionalInformation }
                                        </TableCell>
                                        <TableCell id={ `subscription-date-added-${ subscription.id }` } padding="small" alignment={ showEditAction ? 'left' : 'right' }>
                                            { DateUtils.format(new Date(subscription.created)) }
                                        </TableCell>
                                        { showEditAction && (
                                            <TableCell id={ `subscription-remove-${ subscription.id }` } alignment="center" padding="small">
                                                { this._formRemoveSubscriptionConfirmation(subscription) }
                                            </TableCell> 
                                        ) }
                                    </TableRow>
                                );
                            }) }
                        </Table>

                        <Pagination 
                            pageSize={ this.state.defaultLC.pageSize }
                            pageNumber={ this.state.defaultLC.pageNumber }
                            available={ this.state.defaultLC.available }
                            onSetPage={ this._onDefaultLCPageNumberChange }
                            onSetPageSize={ this._onDefaultLCPageSizeChange }
                            fixed={ false }
                            colorTheme="light"
                            resultsText="logistics coordinators"
                        />
                    </>
                );
            }
        }
        
        return (
            <div className="notification-center-tab-section">
                { summary }
                <div className="notification-center-tab-content">
                    { actions }
                    { content }
                </div>
            </div>
        );
    }

    render() {
        let content = <></>;
        switch (this.state.activeTab) {
            case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                content = this._formRFPManagersContent();
                break;
            case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                content = (
                    <>
                        { this._formLaneSpecificLogisticsCoordinatorsContent() }
                        { this._formDefaultLogisticsCoordinatorsContent() }
                    </>
                );
                break;
            default:
                // This should never happen
                console.error(`Unrecognized tab ${ this.state.activeTab }`);
        }
        
        return (
            <div className="page notification-center-page">
                { this._formBreadcrumbs() }
                <PageHeader title="Notification Center" />
                <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                    <Tab default id={ NOTIFICATION_CENTER_TYPES.RFP_MANAGERS } title="RFP Managers" activeIcon={ activeRFPManagersIcon } inactiveIcon={ inactiveRFPManagersIcon }>
                        { content }
                    </Tab>
                    <Tab id={ NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS } title="Logistics Coordinators" activeIcon={ activeLCIcon } inactiveIcon={ inactiveLCIcon }>
                        { content }
                    </Tab>
                </NavigableTabs>
            </div>
        );
    }
}

export default withRouter(NotificationCenter);
