import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as warning } from '@fortawesome/pro-solid-svg-icons';
import { faClock as clock } from '@fortawesome/pro-regular-svg-icons';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, TENDER_CANDIDATE_STATUS } from 'common/constants';
import TimeDisplay from 'component/TimeDisplay';
import ContentCard from 'component/card/ContentCard';
import TenderCandidateLaneHeaderCard from 'component/lane/TenderCandidateLaneHeaderCard';
import TenderCandidateOfferActions from 'component/lane/TenderCandidateOfferActions';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import Pagination from 'component/navigation/Pagination';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableCell from 'component/table/custom/TableCell';
import RoutingGuideLaneApiService from 'service/api/RoutingGuideLaneApiService';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';
import DateUtils from 'utils/DateUtils';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './CarrierLaneDetails.scss';

class CarrierLaneDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            routingGuideLane: null,
            relevantOffer: null,
            offers: [],
            page: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
        }

        this._fetchData = this._fetchData.bind(this);
        this._fetchRelevantOffer = this._fetchRelevantOffer.bind(this);
        this._fetchOfferHistory = this._fetchOfferHistory.bind(this);
        this._acceptOffer = this._acceptOffer.bind(this);
        this._rejectOffer = this._rejectOffer.bind(this);
        this._setPage = this._setPage.bind(this);
        this._setPageSize = this._setPageSize.bind(this);
    }

    static propTypes = {
        id: PropTypes.string.isRequired,
        account: PropTypes.object.isRequired
    }

    componentDidMount() {
        this._fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this._fetchData();
        } else if (prevState.page !== this.state.page || prevState.pageSize !== this.state.pageSize) {
            this._fetchOfferHistory();
        }
    }

    _fetchData() {
        this._fetchLane();
    }

    _fetchLane() {
        RoutingGuideLaneApiService.get(this.props.id)
            .then(routingGuideLane => this.setState({ routingGuideLane }))
            .then(this._fetchRelevantOffer)
            .then(this._fetchOfferHistory)
            .catch(() => this.props.history.replace('/404'));
    }

    _fetchRelevantOffer() {
        TenderCandidateApiService.getRelevantOfferForUser(this.state.routingGuideLane.rfpAuction.id, this.state.routingGuideLane.id)
            .then(relevantOffer => this.setState({ relevantOffer }))
            .catch(error => console.error('An error occurred while fetching relevant offer.', error));
    }

    _fetchOfferHistory() {
        if (this.state.routingGuideLane) {
            TenderCandidateApiService.listAll(this.state.routingGuideLane.rfpAuction.id, this.state.routingGuideLane.id, this.state.page, this.state.pageSize)
                .then(response => this.setState({
                    offers: response.data.map(offer => {
                        const pricePerMile = PriceUtils.calculatePerMilePrice(offer.price.value, this.state.routingGuideLane.distance);
                        const status = TENDER_CANDIDATE_STATUS[offer.status];
                        return { ...offer, pricePerMile, status };
                    }),
                    available: response.available
                }))
                .catch(error => console.error('An error occurred while fetching offer history.', error));
        }
    }

    _acceptOffer() {
        TenderCandidateApiService.accept(this.state.routingGuideLane.rfpAuction.id, this.state.routingGuideLane.id, this.state.relevantOffer.id)
            .then(this._fetchData)
            .catch(error => console.error('An error occurred while accepting offer.', error));
    }

    _rejectOffer() {
        TenderCandidateApiService.reject(this.state.routingGuideLane.rfpAuction.id, this.state.routingGuideLane.id, this.state.relevantOffer.id)
            .then(this._fetchData)
            .catch(error => console.error('An error occurred while rejecting offer.', error));
    }

    _setPage(page) {
        this.setState({ page });
    }

    _setPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _formPendingOfferHeader() {
        const { routingGuideLane, relevantOffer, relevantOffer: { offerExpiration } } = this.state;

        if (TENDER_CANDIDATE_STATUS.PENDING.value === relevantOffer.status) {
            const timeLeft = DateUtils.difference(new Date(), new Date(offerExpiration)).split(' ');
            return (
                <div className="component pending-offer-header">
                    <div className="flex-component offer-information">
                        <FontAwesomeIcon icon={ warning } className="icon" />
                        <p>There has been a change to your rate. Accept the <b>pending</b> offer to continue receiving load offers for this lane.</p>
                    </div>
                    <div className="flex-component offer-actions">
                        <div className="flex-component offer-remaining-time">
                            <FontAwesomeIcon icon={ clock } className="icon" />
                            <TimeDisplay duration={ timeLeft } numberStyle="small" />
                        </div>
                        <TenderCandidateOfferActions
                            tenderCandidate={ relevantOffer }
                            routingGuideLane={ routingGuideLane }
                            onAccept={ this._acceptOffer }
                            onReject={ this._rejectOffer }
                        />
                    </div>
                </div>
            );
        }

        return <></>;
    }

    _formOffersList() {
        return (
            <ContentCard
                isExpandable={ true }
                heading="Price History"
                expandLabel="Show Details"
                collapseLabel="Hide Details"
                className="price-history-content-card"
            >
                <div className="price-history-table">
                    <Table>
                        <TableRow isHeader={ true }>
                            <TableHeaderCell className="updated-date">Date of Change</TableHeaderCell>
                            <TableHeaderCell className="price" alignment="right">Price Per Mile</TableHeaderCell>
                            <TableHeaderCell className="price" alignment="right">Total Price</TableHeaderCell>
                            <TableHeaderCell className="volume" alignment="right">Volume</TableHeaderCell>
                            <TableHeaderCell className="offer-status" alignment="right">Status</TableHeaderCell>
                        </TableRow>
                        { this.state.offers.map(offer => (
                            <TableRow key={ offer.id } fontSize="small">
                                <TableCell className="updated-date" padding="small">
                                    { DateUtils.format(offer.updated) }
                                </TableCell>
                                <TableCell className="price" alignment="right" padding="small" >
                                    { NumberUtils.prefixNumber(offer.pricePerMile, '$', false, 'decimal') }
                                </TableCell>
                                <TableCell className="price" alignment="right" padding="small" >
                                    { NumberUtils.prefixNumber(offer.price.value, '$', false, 'decimal') }
                                </TableCell>
                                <TableCell className="volume" alignment="right" padding="small">
                                    { offer.volume }
                                </TableCell>
                                <TableCell className={ `offer-status ${ offer.status.style }` } alignment="right" padding="small">
                                    { offer.status.candidateFacingText }
                                </TableCell>
                            </TableRow>
                        )) }
                    </Table>
                </div>

                <Pagination
                    pageNumber={ this.state.page }
                    pageSize={ this.state.pageSize }
                    available={ this.state.available }
                    onSetPage={ this._setPage }
                    onSetPageSize={ this._setPageSize }
                    colorTheme="light"
                    fixed={ false }
                />
            </ContentCard>
        );
    }

    render() {
        const { routingGuideLane, relevantOffer } = this.state;

        if (!routingGuideLane || !relevantOffer) {
            return <></>;
        }

        const origin = LaneUtils.formatRegion(routingGuideLane.lane.origin);
        const destination = LaneUtils.formatRegion(routingGuideLane.lane.destination);
        const pendingOfferHeader = this._formPendingOfferHeader();
        const offerList = this._formOffersList();

        return (
            <div className="page routing-guide-lane-details-page">
                <Breadcrumbs crumbs={ [
                    { path: '/rfp/list', name: 'Routing Guides', backCrumb: false },
                    { path: `/rfp/${ this.state.routingGuideLane.rfpAuction.id }`, name: this.state.routingGuideLane.rfpAuction.name, backCrumb: false },
                    { path: '', name: `${ origin } -> ${ destination }`, backCrumb: false },
                    { path: `/rfp/${ this.state.routingGuideLane.rfpAuction.id }`, name: `Back to ${ this.state.routingGuideLane.rfpAuction.name }`, backCrumb: true }
                ] } />

                { pendingOfferHeader }
                <TenderCandidateLaneHeaderCard routingGuideLane={ routingGuideLane } relevantOffer={ relevantOffer } />

                { offerList }
            </div>
        );
    }
}

function RoutedCarrierLaneDetails({ children, ...props }) {
    const parsedProps = {
        ...props,
        id: props.match.params.laneId,
    };

    return <CarrierLaneDetails { ...parsedProps } />;
}

export default withRouter(RoutedCarrierLaneDetails);
