import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { RFP_AUCTION_STATUSES, RFP_BID_STATUSES } from 'common/constants';
import TitledCardComponent from 'component/card/TitledCardComponent';
import Tooltip from 'component/Tooltip';
import RFPUtils from 'utils/RFPUtils';

import './RFPLaneStatus.scss';

export default class RFPLaneStatus extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        rfpLane: PropTypes.object.isRequired,
        bid: PropTypes.object,
        variant: PropTypes.oneOf([ 'carrier', 'manager' ]),
        type: PropTypes.oneOf([ 'small', 'regular' ]),
        className: PropTypes.string
    };

    static defaultProps = {
        variant: 'carrier',
        type: 'regular',
        className: ''
    };

    _isConfirmationRound() {
        return RFPUtils.isConfirmationLatest(this.props.rfp);
    }

    _status() {
        switch (this.props.rfp.status) {
            case RFP_AUCTION_STATUSES.PENDING:
                return this._pendingStatus();
            case RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS:
                return this._offeringBidStatus();
            case RFP_AUCTION_STATUSES.IN_REVIEW:
                return this._inReviewStatus();
            case RFP_AUCTION_STATUSES.CONFIRMED:
            case RFP_AUCTION_STATUSES.APPLIED:
            case RFP_AUCTION_STATUSES.COMPLETED:
                return this._completedStatus();
            default:
                throw new Error('Unknown rfp status ' + this.props.rfp.status);
        }
    }

    _pendingStatus() {
        return (<span className="pending">Not Published</span>);
    }

    _offeringBidStatus() {
        switch (this.props.variant) {
            case 'carrier':
                const bid = this.props.bid;

                if (this._isConfirmationRound()) {
                    switch ((bid || {}).status) {
                        case RFP_BID_STATUSES.PROPOSED:
                            return (<span className="offered">Offered</span>);
                        case RFP_BID_STATUSES.ACCEPTED:
                            return (<span className="accepted">Accepted</span>);
                        case RFP_BID_STATUSES.REJECTED:
                            return (
                                <span className="rejected">
                                    Rejected

                                    { bid.rejectReason && (
                                        <span className="reject-reason-tooltip-container">
                                            <FontAwesomeIcon icon={ info } className="reject-reason-tooltip-icon" />
                                            <Tooltip direction="bottom" align="left-align">
                                                { bid.rejectReason.reason }
                                            </Tooltip>
                                        </span>
                                    )}
                                </span>
                            );
                        default:
                            return (<span className="lost">Lost</span>);
                    }
                } else {
                    if (bid) {
                        return (
                            <span className="submitted">
                                <span>Bid Submitted</span>
                                { RFPUtils.latestRound(this.props.rfp).ordinal > 0 && (
                                    <sup>(Round #{bid.round.ordinal + 1})</sup>
                                )}
                            </span>
                        );
                    } else {
                        return (<span className="waiting-for-bid">Waiting for Bid</span>);
                    }
                }
            case 'manager':
                return (
                    <span className={ `in-progress ${this._isConfirmationRound() ? 'confirmation' : 'bidding'}` }>
                        { this._isConfirmationRound() ? 'Waiting for Responses' : 'Bidding in Progress' }
                    </span>
                );
            default:
        }
    }

    _inReviewStatus() {
        switch (this.props.variant) {
            case 'carrier':
                if (this._isConfirmationRound()) {
                    if (RFP_BID_STATUSES.PROPOSED === (this.props.bid || {}).status) {
                        return (<span className="expired">Expired</span>);
                    }
                } else {
                    if (!this.props.bid) {
                        return (<span className="no-bid">No Bid</span>);
                    }
                }

                return this._offeringBidStatus();
            case 'manager':
                if (this._isConfirmationRound()) {
                    const fullyAwarded = this.props.rfpLane.awardedVolume === this.props.rfpLane.volume;

                    return (
                        <span className={`review ${fullyAwarded ? 'awarded' : 'not-awarded'}`}>
                            {fullyAwarded ? 'Awarded' : 'Not Awarded'}
                        </span>
                    );
                } else {
                    return (<span className="review">Bids in Review</span>);
                }
            default:
                throw Error('Unknown lane status variant ' + this.props.variant);
        }
    }

    _completedStatus() {
        return this._inReviewStatus();
    }

    render() {
        return (
            <TitledCardComponent type={ this.props.type }
                                 title="Status"
                                 className={ `rfp-lane-status ${ this.props.className }` }>
                <strong><b>{ this._status() }</b></strong>
            </TitledCardComponent>
        );
    }
}
