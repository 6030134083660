import { Component } from 'react';
import PropTypes from 'prop-types';

import './ActivityEmptyState.scss';

export default class ActivityEmptyState extends Component {

    static propTypes = {
        className: PropTypes.string
    }

    static defaultProps = {
        className: ''
    }

    render() {
        return (
            <div className={ `activity-empty-state ${ this.props.className }` }>
                <div className="activity-empty-state-content">
                <div className="activity-empty-state-image">
                    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="150" cy="150" rx="150" ry="150" fill="#F5F7FA"/>
                        <rect x="26.7627" y="123.214" width="246.429" height="176.785" rx="7.14285" fill="white"/>
                        <ellipse cx="58.9057" cy="155.357" rx="10.7143" ry="10.7143" fill="#FFBDBD"/>
                        <rect x="83.9053" y="146.428" width="167.857" height="17.8571" rx="3.57143" fill="#CBD2D9"/>
                        <ellipse cx="58.9057" cy="187.5" rx="10.7143" ry="10.7143" fill="#FFF3C4"/>
                        <rect x="83.9053" y="178.571" width="167.857" height="17.8571" rx="3.57143" fill="#E4E7EB"/>
                        <ellipse cx="58.9057" cy="219.643" rx="10.7143" ry="10.7143" fill="#FFBDBD"/>
                        <rect x="83.9053" y="210.714" width="167.857" height="17.8571" rx="3.57143" fill="#E4E7EB"/>
                        <ellipse cx="58.9057" cy="251.786" rx="10.7143" ry="10.7143" fill="#BAE3FF"/>
                        <rect x="83.9053" y="242.857" width="167.857" height="17.8571" rx="3.57143" fill="#CBD2D9"/>
                    </svg>
                </div>
                <p className="activity-empty-state-description">There are no activities</p>
                </div>
            </div>
        );
    }
}
