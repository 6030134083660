import { Component } from 'react';
import PropTypes from 'prop-types';

import { TRIGGER_TYPES } from 'common/constants';
import AddCarrier from 'component/AddCarrier';
import LaneCarrierApiService from 'service/api/LaneCarrierApiService';

export default class AddLaneCarrier extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        trigger: PropTypes.oneOf([TRIGGER_TYPES.BUTTON, TRIGGER_TYPES.LABEL]),
        setShouldUpdate: PropTypes.func
    };

    static defaultProps = {
        trigger: TRIGGER_TYPES.BUTTON,
        setShouldUpdate: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._addCarrier = this._addCarrier.bind(this);
        this._addExcludedCarrier = this._addExcludedCarrier.bind(this);
        this._onValueChanged = this._onValueChanged.bind(this);
    }

    state = {
        carrierBusinessId: undefined
    };

    _addCarrier(carrierBusinessId) {
        const data = { carrierBusinessId: carrierBusinessId.trim() };
        return LaneCarrierApiService.add(this.props.lane.id, data);
    }

    _addExcludedCarrier(carrierBusinessId) {
        return LaneCarrierApiService.activate(this.props.lane.id, carrierBusinessId.trim());
    }

    _onValueChanged(carrierBusinessId) {
        this.setState({ carrierBusinessId });
    }

    render() {
        const successElement = (
            <div>
                <h6>Carrier Added</h6>
                <p>Carrier with carrier code <b>{ this.state.carrierBusinessId }</b> has been added to this lane.</p>
            </div>
        );

        const confirmationElement = (
            <>
                <p className="description">Carrier <b>{ this.state.carrierBusinessId }</b> is currently excluded from this lane. Are you sure you want to add them to the <b>Active</b> list?</p>
                <p className="description">This will remove the carrier from the <b>Excluded</b> list and enable them to receive invitations for future spot market auctions on this lane.</p>
                <p className="description">Ongoing auctions will not be affected.</p>
            </>
        );

        return (
            <AddCarrier 
                buttonSize="small"
                trigger={ this.props.trigger }
                successElement={ successElement }
                confirmationElement={ confirmationElement }
                popupTitle="Add Spot Market Carrier"
                submitButtonText="Add Carrier"
                addCarrier={ this._addCarrier }
                retryAddCarrier={ this._addExcludedCarrier }
                onValueChanged={ this._onValueChanged }
                setShouldUpdate={ this.props.setShouldUpdate }
            />
        );
    }
}
