import { Children, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Tab from 'component/navigation/Tab';

import './Tabs.scss';

/**
 * A Simple Tab wrapper. The current tab selection is lost on page reload.
 */
export default class SimpleTabs extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        onTabChange: PropTypes.func
    }

    static defaultProps = {
        onTabChange: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.state = {
            currentTab: null
        }

        this._changeTab = this._changeTab.bind(this);
    }

    componentDidMount() {
        if (!this.state.currentTab && this.props.children && this.props.children.length) {
            this.setState({ currentTab: this.props.children[0].props.id });
        }
    }

    _changeTab(id) {
        this.setState({ currentTab: id }, () => this.props.onTabChange(id));
    }

    _getLinks() {
        return Children.map(this.props.children, child => {
            if (!child) {
                return <></>;
            }

            if (child.type !== Tab) {
                throw new Error('Invalid child provided to Tabs component. All children must be tabs.');
            }

            let icon = <></>;
            if (child.props.activeIcon && child.props.id === this.state.currentTab) {
                icon = <FontAwesomeIcon icon={ child.props.activeIcon } />;
            } else if (child.props.inactiveIcon) {
                icon = <FontAwesomeIcon icon={ child.props.inactiveIcon } />;
            }

            const activeClass = child.props.id === this.state.currentTab ? 'active' : '';

            return (
                <div onClick={ () => this._changeTab(child.props.id) } className={ `tab-link light ${ activeClass }` } data-id={ child.props.id }>
                    { icon }
                    <span className={ `tab-title ${ child.props.activeTab && 'active' }` }>{ child.props.title }</span>
                </div>
            );
        })
    }

    _getCurrentTab() {
        return this.props.children.find(child => child.props.id === this.state.currentTab);
    }

    render() {
        return (
            <div className="tab-container">
                <div className="tab-navigation-container">
                    <div className="tab-placeholder left" />
                    { this._getLinks() }
                    <div className="tab-placeholder" />
                </div>
                <div className="tab-content-container light">
                    <div className="tab-content">
                        { this._getCurrentTab() }
                    </div>
                </div>
            </div>
        );
    }
}
