import RestService from 'service/RestService';
/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction bids
 */
export default class TenderAuctionExclusionApiService {

    /**
     * Gets a list of all exclusions which are part of auction
     * 
     * @param auctionId id of auction for which exclusions are 
     * @returns list of exclusions
     */
    static async fetch(auctionId) {
        const exclusions = await RestService.instance().get(`auction/tendering/${ auctionId }/exclusions`);
        return exclusions;
    }
}
