import RestService from 'service/RestService';

/**
  * Service class that provides static methods and endpoints for API calls regarding routing guide lanes
  */
export default class RoutingGuideLaneApiService {

    /**
     * Gets the routing guide lane with the provided id.
     * 
     * @param {string} id id of the routing guide lane
     * @returns {Promise}
     */
    static get(id) {
        return RestService.instance().get(`routing-guide-lanes/${ id }`);
    }

    /**
     * Sets the book now price on a routing guide lane.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} id id of the routing guide lane
     * @param {number} price book now price
     * @returns {Promise}
     */
    static setBookNowPrice(routingGuideId, id, price) {
        return RestService.instance()
            .put(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ id }/book-now-price`, { price });
    }
}
