import { Component } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomMultipleTextInput from 'component/form/CustomMultipleTextInput';
import FormStatusModal from 'component/form/FormStatusModal';
import StringUtils from 'utils/StringUtils';
import UserApiService from 'service/api/UserApiService';
import CarrierApiService from 'service/api/CarrierApiService';

import './AddNewMember.scss';

export default class AddNewMember extends Component {

    static propTypes = {
        carrier: PropTypes.object.isRequired,
        onAddMembers: PropTypes.func
    };

    static defaultProps = {
        onAddMembers: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onAdd = this._onAdd.bind(this);
        this._onEmailAdded = this._onEmailAdded.bind(this);
        this._onEmailRemoved = this._onEmailRemoved.bind(this);
        this._areInputValuesValid = this._areInputValuesValid.bind(this);
    }

    state = {
        showPopup: false,
        loading: null,
        numberOfAddedMembers: 0,
        emails: [],
        valid: false
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.emails !== this.state.emails) {
            this._areInputValuesValid();
        }
    }
    
    _openPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true, emails: [] });
    }

    _closePopup() {
        this.setState({ showPopup: false, loading: null });
    }

    _areInputValuesValid() {
        let valid;
        if (this.state.emails.length === 0) {
            valid = false;
        } else {
            const emails = [ ...this.state.emails ];
            const invalidIndex = emails.findIndex(email => !email.valid);
    
            valid = invalidIndex === -1;
        }

        this.setState({ valid });
    }

    _onEmailAdded(userEmail) {
        // We use flushSync to ensure users are set to the state before the submit method is called
        flushSync(() => {
            UserApiService.isEmailUsed(userEmail, this.props.carrier.id)
                .then(emailUsed => this.setState(prevState => ({ emails: [ ...prevState.emails, { value: userEmail, valid: !emailUsed } ] })));
        });
    }

    _onEmailRemoved(userEmail) {
        this.setState(prevState => {
            const emails = [ ...prevState.emails ];
            const index = emails.findIndex(email => email.value === userEmail);

            if (index > -1) {
                emails.splice(index, 1);
            }

            return { emails };
        });
    }

    _onAdd() {
        this.setState({ loading: true });

        const userEmails = this.state.emails.map(email => email.value);

        CarrierApiService.addMembers(this.props.carrier.id, userEmails)
            .then(numberOfAddedMembers => this.setState({ numberOfAddedMembers }))
            .then(this.props.onAddMembers)
            .finally(() => this.setState({ loading: false }));
    }

    _formPopUpContent() {
        if (this.state.loading === null) {
            return (
                <>
                    <p className="heading">Add New Members</p>
                        
                    <p className="description">
                        To invite new members to { this.props.carrier.name }, you can input their email addresses and send them an invitation.
                    </p>
                    <CustomMultipleTextInput
                        label="Users"
                        tooltipText="To add multiple users press the enter key after each email entry."
                        className="add-members-input-field"
                        placeholder="Enter User Emails..."
                        values={ this.state.emails }
                        onValueAdded={ this._onEmailAdded }
                        onValueRemoved={ this._onEmailRemoved }
                    />
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._closePopup } >
                            Cancel
                        </Button>

                        <Button type="primary" onClick={ this._onAdd } disabled={ !this.state.valid }>
                            Continue
                        </Button>
                    </div>
                </>
            );
        } else if (this.state.loading) {
            return (
                <FormStatusModal status={ FORM_STATUSES.LOADING } onContinue={ this._closePopup }>
                    <h6>Adding Members</h6>
                    <small>This will only take a moment. Please wait....</small>
                </FormStatusModal>
            );
        } else if (this.state.numberOfAddedMembers === 0) {
            return (
                <FormStatusModal status={ FORM_STATUSES.EMPTY_SUCCESS } onContinue={ this._closePopup}>
                    <h6>No New Members Added</h6>
                    <small>The list of members remains unchanged. Make sure you have entered valid user emails.</small>
                </FormStatusModal>
            );
        } else {
            return (
                <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._closePopup }>
                    <h6>Members Added</h6>
                    <small>
                        { this.state.numberOfAddedMembers} { StringUtils.pluralize(this.state.numberOfAddedMembers, 'Member') } { this.state.numberOfAddedMembers > 1 ? 'have' : 'has' } been successfully added to { this.props.carrier.name }.
                    </small>
                </FormStatusModal>
            );
        }
    }

    render() {
        return (
            <div className="add-members">
                <Popup
                    id="popup-members-add"
                    size="medium"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={
                        <Button
                            type="primary"
                            className="action"
                            leftIcon={ addIcon }
                            disabled={ !this.props.carrier.active }
                            onClick={ this._openPopup }
                        >
                            Add New Members
                        </Button>    

                    }
                >
                    <div className="add-members-popup">
                       { this._formPopUpContent() }
                    </div>
                </Popup>
            </div>
        )
    }
}
