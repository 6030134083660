import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck as truck, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import { faAngleRight as arrowHead } from '@fortawesome/pro-light-svg-icons';

import Tooltip from 'component/Tooltip';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';

import './LaneVisualization.scss';

export default class LaneVisualization extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        size: PropTypes.oneOf(['large', 'small']),
        className: PropTypes.string,
        labeled: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        size: 'small',
        labeled: false
    };

    _formRegionLabel(regionLabel, zipCode) {
        return (
            <small className="label with-tooltip">
                { regionLabel }
                <div className="tooltip-container">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="right" className="tooltip-icon">
                        <span> ZIP Code { zipCode }</span>
                    </Tooltip>
                </div>
            </small>
        );
    }

    render() {
        const origin = LaneUtils.formatRegion(this.props.lane.lane.origin);
        const destination = LaneUtils.formatRegion(this.props.lane.lane.destination);
        const labeledStyle = this.props.labeled ? 'labeled' : '';

        let originLabel = <></>;
        let destinationLabel = <></>;
        if (this.props.labeled) {
            originLabel = this._formRegionLabel("Origin", this.props.lane.originZipCode);
            destinationLabel = this._formRegionLabel("Destination", this.props.lane.destinationZipCode);
        }

        return (
            <div className={ `lane-visualization ${ this.props.className } ${ labeledStyle } ${ this.props.size }` }>
                <strong className="lane-origin">
                    { originLabel }
                    <b id={ `lane-card-origin-${ this.props.lane.id }` } className="content" title={ origin }>
                        { origin }
                    </b>
                </strong>

                <div className="lane-illustration">
                    <FontAwesomeIcon icon={ truck } className="icon" />
                    <div className="arrow-container">
                        <sub className="distance">{ NumberUtils.formatAsAWholeNumber(this.props.lane.distance) + ' mi.' }</sub>
                        <div className="arrow">
                            <FontAwesomeIcon icon={ arrowHead } className="icon" />
                        </div>
                    </div>
                </div>

                <strong className="lane-destination">
                    { destinationLabel }
                    <b id={ `lane-card-destination-${ this.props.lane.id }` } className="content" title={ destination }>
                        { destination }
                    </b>
                </strong>
            </div>
        )
    }
}
