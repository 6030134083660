import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy as awardIcon } from '@fortawesome/pro-solid-svg-icons';

import { RFP_BID_STATUSES } from 'common/constants';
import CustomNumberInput from 'component/form/CustomNumberInput';
import CustomSelect from 'component/form/CustomSelect';
import Button from 'component/Button';
import Popup from 'component/Popup';
import NumberUtils from 'utils/NumberUtils';
import RestService from 'service/RestService';

import './RFPAwardBid.scss';

const BID_STATUSES = [
    {
        "title": 'ACCEPTED',
        "label": 'Accepted Offer'
    },
    {
        "title": "REJECTED",
        "label": 'Rejected Offer'
    }
];

export default class RFPAwardBid extends Component {

    static propTypes = {
        bid: PropTypes.object.isRequired,
        numberOfAcceptedBids: PropTypes.number.isRequired,
        maxAwardedVolume: PropTypes.number.isRequired,
        isConfirmationLatest: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        onAward: PropTypes.func
    };

    static defaultProps = {
        disabled: false,
        onAward: () => {/* */}
    };

    constructor(props) {
        super(props);
        this._onBidStatusChange = this._onBidStatusChange.bind(this);
        this._onAwardedChange = this._onAwardedChange.bind(this);
        this._onRankChange = this._onRankChange.bind(this);
        this._unsetState = this._unsetState.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
    }

    state = {
        showPopup: false,
        status: RFP_BID_STATUSES.PROPOSED === this.props.bid.status ? null : this.props.bid.status,
        awarded: this.props.bid.awardedVolume,
        rank: this.props.bid.rank,
        validAward: this._validateAward(this.props.bid.awardedVolume),
        validRank: this._validateRank(this.props.bid.rank),
        onAward: () => {/* */}
    };

    _onBidStatusChange(status) {
        this.setState({
            status,
            validAward: this._validateAward(this.state.awarded),
            validRank: this._validateRank(this.state.rank)
        });
    }

    _onAwardedChange(awarded) {
        awarded = Number(awarded ? awarded : 0);
        this.setState({ awarded, validAward: this._validateAward(awarded) });
    }

    _onRankChange(rank) {
        rank = Number(rank ? rank : 0);
        this.setState({ rank, validRank: this._validateRank(rank) });
    }

    _submitEnabled() {
        if (RFP_BID_STATUSES.REJECTED === this.state.status && this.state.status === this.props.bid.status) {
            return false;
        } else if (!this.props.isConfirmationLatest) {
            return this.state.validAward;
        } else if (RFP_BID_STATUSES.ACCEPTED === this.state.status) {
            return this.state.validRank && this.state.validAward;
        } else if (RFP_BID_STATUSES.REJECTED === this.state.status) {
            return true;
        }

        return false;
    }

    _unsetState() {
        this.setState({
            showPopup: false,
            status: RFP_BID_STATUSES.PROPOSED === this.props.bid.status ? null : this.props.bid.status,
            awarded: this.props.bid.awardedVolume,
            rank: this.props.bid.rank,
            validAward: this._validateAward(this.props.bid.awardedVolume),
            validRank: this._validateRank(this.props.bid.rank)
        });
    }

    _onOpenPopup() {
        this.setState({ showPopup: true });
    }

    _validateRank(rank) {
        const maxRank = RFP_BID_STATUSES.ACCEPTED !== this.props.bid.status ? this.props.numberOfAcceptedBids + 1 : this.props.numberOfAcceptedBids;
        return rank >= 1 && rank <= maxRank;
    }

    _validateAward(awarded) {
        return awarded >= 0 && awarded <= this.props.maxAwardedVolume;
    }

    async _onSubmit() {
        await RestService.instance()
            .post(
                `auction/rfp/${ this.props.bid.auction.id }/lane/${ this.props.bid.lane.id }/bid/${ this.props.bid.id }/update-bid`,
                {
                    bidStatus: this.state.status || RFP_BID_STATUSES.PROPOSED,
                    awardedVolume: this.state.awarded,
                    rank: this.state.rank
                }
            );

        this.props.onAward();
    }

    render() {
        const bid = this.props.bid;
        const maxRank = RFP_BID_STATUSES.ACCEPTED !== this.props.bid.status ? this.props.numberOfAcceptedBids + 1 : this.props.numberOfAcceptedBids;

        return (
            <Popup id={ `rfp-award-bid-${ bid.id }` }
                   size="medium"
                   show={ this.state.showPopup }
                   onClose={ this._unsetState }
                   trigger={
                       <span>
                            <FontAwesomeIcon icon={ awardIcon }
                                             disabled={ this.props.disabled }
                                             onClick={ !this.props.disabled ? this._onOpenPopup : null }
                                             className={ `award-bid-action ${ this.props.disabled ? 'disabled' : '' }` } />
                       </span>
                   } >
                <div className="rfp-award-bid-popup">
                    <h6 className="heading">Award Carrier</h6>
                    <p>Carrier <b>{ bid.candidate.carrier.name }</b> placed a bid with the <b>Price per Mile</b> of <b>${ NumberUtils.formatWithDecimalNotation(bid.price) }</b> and the <b>Bid Volume</b> of <b>{ bid.volume }</b> out of <b>{ bid.lane.volume } Lane Volume</b>.</p>
                    <br />
                    <p>In order to award this carrier, please fill in the fields below.</p>
                    <br />
                    { this.props.isConfirmationLatest &&
                        <>
                            <CustomSelect
                                label="Status"
                                values={ BID_STATUSES }
                                selectedValue={ this.state.status }
                                onSelect={ this._onBidStatusChange }
                            />
                            <br />
                        </>
                    }

                    { (!this.props.isConfirmationLatest || RFP_BID_STATUSES.ACCEPTED === this.state.status) &&
                        <CustomNumberInput 
                            label={ <small className="input-label"><b>Award Volume</b><span className="input-label-description"> (max. { this.props.maxAwardedVolume })</span></small> }
                            placeholder="Enter award volume amount..."
                            initialValue={ String(this.state.awarded ? this.state.awarded : '0') }
                            onChange={ this._onAwardedChange }
                            isValid={ this.state.validAward }
                            min={ 0 }
                            max={ this.props.maxAwardedVolume }
                            decimals={ 0 }
                        />
                    }

                    { this.props.isConfirmationLatest && RFP_BID_STATUSES.ACCEPTED === this.state.status &&
                        <>
                            <br />
                            <CustomNumberInput 
                                label={ <small className="input-label"><b>Rank.</b><span className="input-label-description"> (max. { maxRank })</span></small> }
                                placeholder={ "e.g. 1" }
                                initialValue={ String(this.state.rank ? this.state.rank : '') }
                                onChange={ this._onRankChange }
                                isValid={ this.state.validRank }
                                min={ 1 }
                                max={ maxRank }
                                decimals={ 0 }
                                autofocus={ false }
                            />
                        </>
                    }

                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._unsetState }>Cancel</Button>
                        <Button disabled={ !this._submitEnabled() } onClick={ this._onSubmit }>Award Carrier</Button>
                    </div>
                </div>
            </Popup>
        )
    }
}
