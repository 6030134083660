import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import AsyncUtils from 'utils/AsyncUtils';
import AuthenticationService from 'service/AuthenticationService';
import ConfigurationService from 'service/ConfigurationService';

/**
 * Provides a generic interface for WebSocket interactions regardless of the implementation.
 * <p>
 * This component will also handle any authentication if necessary.
 * <p>
 * Users are not expected to use this directly.
 *
 * @see WSComponent
 */
export default class WebSocketService {
    static _instance = new WebSocketService();

    /**
     * The STOMP js client.
     *
     * @type {Client}
     * @private
     */
    _client = null;

    /**
     * Whether the WebSocketService is fully initiazlied.
     *
     * @type {boolean}
     * @private
     */
    _initialized = false;
    _initializedWithError = false;

    /**
     * An array of handlers to invoke when a WS connection is established. Will also be invoked on re-connects.
     *
     * @type {[{ topic, handler }]}
     * @private
     */
    _onConnectHandlers = [];

    /**
     * An array of handlers to invoke when a WS connection is closed.
     *
     * @type {[{ topic, handler }]}
     * @private
     */
    _onDisconnectHandlers = [];

    static instance() {
        return this._instance;
    }

    async _initClientTokenRefresh(skipRefresh) {
        const authenticationService = AuthenticationService.instance();
        const authResult = await authenticationService.getToken(!skipRefresh);

        const configurationService = ConfigurationService.instance();
        const wsUrl = configurationService.backendWS() +
            (authResult.accessToken ? '?access_token=' + encodeURIComponent(authResult.accessToken) : '');

        this._client.webSocketFactory = () => new SockJS(wsUrl, null, { transports: 'websocket' });

        setTimeout(
            this._initClientTokenRefresh.bind(this),
            Math.max(0, (authResult.expiresOn - Date.now()) - 30000)
        );
    }

    async init() {
        if (this._client) {
            throw new Error(
                'Detected attempt to initialize web socket service for which initialization was already invoked!'
            );
        }

        if (!this._initialized) {
            this._client = new Client();

            await this._initClientTokenRefresh(true);

            this._client.onConnect = receipt => {
                this._initialized = true;
                this._onConnectHandlers.forEach(handler => handler(receipt));
            };

            this._client.onWebSocketClose = event => {
                console.error("Websocket closed.", event && event.reason, event);
                console.warn("Proceeding as initialized WebSocketService, but WebSocket connection will not be available.");
                this._client.deactivate();
                this._initialized = true;
                this._initializedWithError = true;
                this._onDisconnectHandlers.forEach(handler => handler(event));
            };

            this._client.activate();
            return AsyncUtils.waitForPredicate(() => this._initialized);
        }

        return null;
    }

    registerOnConnectHandler(handler) {
        this._onConnectHandlers.push(handler);
    }

    unregisterOnConnectHandler(handler) {
        const index = this._onConnectHandlers.indexOf(handler);

        if (index > -1) {
            this._onConnectHandlers.splice(index, 1);
        }
    }

    registerOnDisconnectHandler(handler) {
        this._onDisconnectHandlers.push(handler);
    }

    unregisterOnDisconnectHandler(handler) {
        const index = this._onDisconnectHandlers.indexOf(handler);

        if (index > -1) {
            this._onDisconnectHandlers.splice(index, 1);
        }
    }

    async subscribe(destination, onMessageReceived) {
        if (!this._initialized) {
            await this.init();
        }
        if (this._initializedWithError) {
            return null;
        }

        return this._client.subscribe(destination, onMessageReceived);
    }

    /**
     * Unsubscribe a subscription with a specific id.
     * Users are advised to use the unsubscribe function, returned to them in the subscription object.
     *
     * @param subscriptionId
     */
    unsubscribe(subscriptionId) {
        if (this._initialized) {
            if (this._initializedWithError) {
                return;
            }

            this._client.unsubscribe(subscriptionId);
        }
    }

    isInitializedWithError() {
        return this._initializedWithError;
    }

    async send(destination, message) {
        if (!this._initialized) {
            await this.init();
        }
        if (this._initializedWithError) {
            return;
        }

        this._client.publish({
            destination,
            body: JSON.stringify(message)
        });
    }

    close() {
        if (this._initialized) {
            return this._client.deactivate()
                .then(result => {
                    this._initialized = false;
                    this._initializedWithError = false;
                    return result;
                });
        }
    }
}
