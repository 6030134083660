import PropTypes from 'prop-types';

export default class Account {

    static propType = () => PropTypes.shape({
        id: PropTypes.string.isRequired,
        internalId: PropTypes.string.isRequired,
        carrierId: PropTypes.string,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        picture: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired
    })

    constructor({ id, internalId, carrierId, name, email, picture, roles, permissions }) {
        this.id = id;
        this.internalId = internalId;
        this.carrierId = carrierId;
        this.name = name;
        this.email = email;
        this.picture = picture;
        this.roles = roles;
        this.permissions = permissions;
    }
}
