import RestService from 'service/RestService';
import TenderAuctionCandidateApiService from 'service/api/TenderAuctionCandidateApiService';
import TenderAuctionBidApiService from 'service/api/TenderAuctionBidApiService';
import TenderAuctionExclusionApiService from 'service/api/TenderAuctionExclusionApiService';

/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction for broker
 */
export default class TenderAuctionBrokerApiService {

    /**
     * Gets auction with forwarded id.
     *
     * @param {string} auctionId id of an auction that we want to fetch
     * @returns auction with forwarded id
     */
    static async getAuction(auctionId) {
        return RestService.instance().get(`broker/auction/tendering/${ auctionId }`);
    }
    
    /**
     * Gets spot market bids
     * 
     * @param {string} auctionId id of auction for which bids are 
     * @param {string} skippedBidId id of the skipped bid
     * @param {number} pageNumber number of page for the bids
     * @param {number} pageSize number of bids taken
     * @returns {Promise}
     */
    static getSpotMarketBids(auctionId, skippedBidId, search, pageNumber, pageSize) {
        return RestService.instance().get(`broker/auction/tendering/${ auctionId }/spot-market-bids`, { skippedBidId, search, pageNumber, pageSize });
    }

    /**
     * Gets the most relevant spot market bid
     * 
     * @param {string} auctionId id of auction for which bids are 
     * @returns {Promise}
     */
    static getMostRelevantSpotMarketBid(auctionId) {
        return  RestService.instance().get(`broker/auction/tendering/${ auctionId }/spot-market-relevant-bid`);
    }

    /**
     * Changes book now price on auction
     * 
     * @param {string} auctionId id of auction for which book now is posted
     * @param {number} price book now price
     * @returns {Promise}
     */
    static setBookNowPrice(auctionId, price) {
        return RestService.instance().put(`broker/auction/tendering/${ auctionId }/book-now-price`, { price });
    }

    /**
     * Approves the carrier that used the Book Now option allowing them to receive dispatch details
     * 
     * @param {string} auctionId id of the auction
     * @returns {Promise}
     */
    static dispatchCarrier(auctionId) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/dispatch-carrier`);
    }

    /**
     * Gets cancel reason for auction
     * 
     * @param {string} auctionId id of auction that was canceled
     * @returns {Promise}
     */
    static getCancelReason(auctionId) {
        return RestService.instance().get(`broker/auction/tendering/${ auctionId }/cancel-reason`);
    }

    /**
     * Gets boolean value for include limit exceeded candidates flag
     * 
     * @returns {Promise}
     */
    static includeLimitExceededCandidates() {
        return RestService.instance().get('broker/auction/tendering/include-limit-exceeded-candidates');
    }

    /**
     * Updates driver info required
     * 
     * @param {string} auctionId id of auction for which we need to update driver info required
     * @returns {Promise}
     */
    static updateDriverInfoRequired(auctionId) {
        return RestService.instance().post(`/broker/auction/tendering/${ auctionId }/driver-info-required`);
    }

    /**
     * Fetches bids, candidates, exclusions as promise so that existing frontend code which uses auction.candidates etc
     * could work. Proper way of using this in new code would be to fetch these entities per auction id where they are needed.
     * 
     * @param auction parameter which does not contain bids, candidates, exclusions.
     * @returns enriched auction
     */
    static async enrichTenderAuction(auction) {
        const [bids, dedicatedCandidates, exclusions] = await Promise.all([
            TenderAuctionBidApiService.fetch(auction.id),
            TenderAuctionCandidateApiService.fetchOfferedCandidates(auction.id),
            TenderAuctionExclusionApiService.fetch(auction.id)
        ]);

        return {
            ...auction,
            bids,
            dedicatedCandidates,
            exclusions
        }
    }

    /**
     * Fetches bids so that existing frontend code which uses auction.bids etc
     * could work. Proper way of using this in new code would be to fetch these entites per auction id where they are needed.
     * 
     * @param auction parameter which does not contain bids.
     * @returns enriched auction
     */
    static async enrichTenderAuctionWithBids(auction) {
        const bids = await TenderAuctionBidApiService.fetch(auction.id);

        return {
            ...auction,
            bids
        }
    }

    /**
     * Sends carrier details
     *
     * @param {object} details
     * @param {string} auctionId
     * @returns {Promise}
     */
    static sendCarrierDetails(details, auctionId) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/details`, details);
    }

    /**
     * Declares carrier a winner
     *
     * @param {string} auctionId 
     * @param {object} body body which contains candidate and bid id
     * @returns {Promise}
     */
    static declareWinner(auctionId, body) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/declare-winner`, body);
    }

    /**
     * Falloff winner from auction
     *
     * @param {string} auctionId 
     * @param {string} reasonId
     * @returns {Promise}
     */
    static falloff(auctionId, reasonId) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/falloff?reasonId=${ reasonId }`);
    }

    /**
     * Extend offer for carrier
     *
     * @param {string} auctionId 
     * @param {string} bidId
     * @param {Date} expiration
     * @returns {Promise}
     */
    static extendOffer(auctionId, bidId, expiration) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/extend-offer-expiration/${ bidId }`, expiration);
    }
    
    /**
     * Reject offer for carrier
     *
     * @param {string} auctionId 
     * @param {string} bidId
     * @param {string} reasonId
     * @param {string} note
     * @returns {Promise}
     */
    static rejectOffer(auctionId, bidId, reasonId, note) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/bid/${ bidId }/reject`, { id: reasonId, note });
    }

    /**
     * Change auction deadline
     *
     * @param {string} id id of auction whose deadline will change
     * @param {Date} deadline new deadline
     * @returns {Promise}
     */
    static changeAuctionDeadline(id, deadline) {
        return RestService.instance().post(`broker/auction/tendering/${ id }/edit-deadline`, { deadline });
    }

    /**
     * Get cancel reasons that auction can have
     *
     * @returns {Promise}
     */
    static getAvailableCancelReasons() {
        return RestService.instance().get('broker/auction/tendering/cancel-reasons');
    }

    /**
     * Cancel tender auction
     *
     * @param {string} auctionId id of auction which will be canceled
     * @param {object} cancelReason reason why auction is canceled and its note in case it is required
     * @returns {Promise}
     */
    static cancelAuction(auctionId, cancelReason) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/cancel`, cancelReason);
    }

    /**
     * Restart tender auction
     *
     * @param {string} auctionId id of auction which will be restarted
     * @param {object} restartParams properties that new auction will have
     * @returns {Promise}
     */
    static restartAuction(auctionId, restartParams) {
        return RestService.instance().post(`broker/auction/tendering/${ auctionId }/restart`, restartParams);
    }
}
