import { Component } from 'react';
import PropTypes from 'prop-types';

import Separator from 'component/Separator';

import './LabeledSeparator.scss';

export default class LabeledSeparator extends Component {
    static propTypes = {
       label: PropTypes.string.isRequired
    };

    render() {
        return (
            <div className="labeled-separator">
                <Separator />
                <p className="label">{ this.props.label }</p>
            </div>
        );
    }
}
