import PropTypes from 'prop-types';
 import Order from 'model/Order'

 export default class Notification {

    static propType = () => PropTypes.shape({
        id: PropTypes.any.isRequired,
        auctionId: PropTypes.string,
        load: Order.propType(),
        message: PropTypes.string.isRequired,
        severity: PropTypes.oneOf(['INFO', 'SUCCESS', 'ALERT']).isRequired,
        type: PropTypes.oneOf(['LOAD', 'DOCUMENT']).isRequired,
        time: PropTypes.instanceOf(Date).isRequired,
        seen: PropTypes.bool
    });

    constructor({ id, auctionId, load, message, severity, type, time }, seen) {
        this.id = id;
        this.auctionId = auctionId;
        this.load = load;
        this.message = message;
        this.severity = severity;
        this.type = type;
        this.time = new Date(time);
        this.seen = seen || false;
    }
}
