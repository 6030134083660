import { Component } from 'react';
import { Link } from 'react-router-dom';

import CanAccess from 'component/CanAccess';
import Banner from 'component/Banner';
import AccountInfo from 'component/navigation/AccountInfo';
import HolidayNotice from 'component/notifications/HolidayNotice';
import NotificationOverlay from 'component/notifications/NotificationOverlay';
import QuickActions from 'component/navigation/QuickActions';
import ConnectionMonitor from './ConnectionMonitor';
import GlobalSearch from 'component/filter/GlobalSearch';
import AuthenticationService from 'service/AuthenticationService';

import './Header.scss';

export default class Header extends Component {
    state = {
        showWelcomeInfo: false,
        account: null
    }
    
    constructor(props) {
        super(props);
        this._hideWelcomeInfo = this._hideWelcomeInfo.bind(this);
    }

    componentDidMount() {
        AuthenticationService.instance().getAccount().then(account => {
            this.setState({
                showWelcomeInfo: account.carrierId ? !JSON.parse(localStorage.getItem(`welcome-box-seen-by-${ account.id }`)) : false,
                account: account
            });  
        }).catch(error => console.error(error.message));
    }

    _hideWelcomeInfo() {
        localStorage.setItem(`welcome-box-seen-by-${ this.state.account.id }`, true);
        this.setState({
            showWelcomeInfo: false
        });
    }

    render() {
        return (
            <header className="site-header">
                <CanAccess
                    action={ ['load:list', 'carrier-load:list'] }
                    yes={ <GlobalSearch /> }
                />
                <CanAccess
                    action="holiday:read"
                    yes={ <HolidayNotice /> }
                />
                { this.state.showWelcomeInfo && 
                    <Banner 
                        content={
                            <div className="welcome-message">
                                Welcome! For a complete experience, ensure that all your&nbsp;
                                <Link to={ `/user/${ this.state.account.internalId }` } onClick={ this._hideWelcomeInfo }>contact information</Link>
                                &nbsp;are submitted.
                            </div>
                        }
                        allowDiscard={ true }
                        onDiscard={ this._hideWelcomeInfo }
                    />
                }
                <ConnectionMonitor />
                <QuickActions />
                <AccountInfo />
                <NotificationOverlay />
            </header>
        );
    }
}
