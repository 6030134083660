import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH as menu } from '@fortawesome/pro-light-svg-icons';
import { faPowerOff as restart } from '@fortawesome/pro-solid-svg-icons';

import { 
    AUCTION_STATUSES,
    BID_STATUSES,
    CONFIRMED_AUCTION_STATUSES,
    FALLOFF_BID_STATUSES,
    FORM_STATUSES,
    FAILED_BID_STATUSES,
    LOAD_STATUSES,
    WINNING_BID_STATUSES,
    CANDIDATE_TYPE,
    OFFERING_AUCTION_STATUSES
} from 'common/constants';
import Banner from 'component/Banner';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import FormStatusModal from 'component/form/FormStatusModal';
import DownloadRateConfirmationPopup from 'component/load/DownloadRateConfirmationPopup';
import OfferInformationComponent from 'component/load/OfferInformationComponent';
import DispatchDetailsComponent from 'component/load/DispatchDetailsComponent';
import LogisticsCoordinators from 'component/load/LogisticsCoordinators';
import WinnerDetailsComponent from 'component/load/WinnerDetailsComponent';
import HeadingComponent from 'component/load/HeadingComponent';
import RestartAuctionPopup from 'component/load/RestartAuctionPopup';
import RetryOnDemandCarrierSync from 'component/load/RetryOnDemandCarrierSync';
import TenderingCandidatesComponent from 'component/load/TenderingCandidatesComponent';
import CoordinatorDetailsComponent from 'component/load/CoordinatorDetailsComponent';
import RejectCurrentOfferPopup from 'component/load/RejectCurrentOfferPopup';
import DeclareWinnerPopup from 'component/load/DeclareWinnerPopup';
import ExtendOfferPopup from 'component/load/ExtendOfferPopup';
import PaymentDetailsCard from 'component/load/PaymentDetailsCard';
import WSComponent from 'component/WSComponent';
import RestService from 'service/RestService';
import DateUtils from 'utils/DateUtils';
import AuctionUtils from 'utils/AuctionUtils';
import DocumentUtils from 'utils/DocumentUtils';
import ObjectUtils from 'utils/ObjectUtils';
import WebSocketService from 'service/WebSocketService';
import Dropdown from 'component/Dropdown';
import Popup from 'component/Popup';
import CustomSelect from 'component/form/CustomSelect';
import Button from 'component/Button';
import PaymentDetailsApiService from 'service/api/PaymentDetailsApiService';
import TenderAuctionApiService from 'service/api/TenderAuctionApiService';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';

import './LoadDetails.scss';

export default class LoadDetails extends WSComponent {

    constructor(props) {
        super(props);

        this.subscriptions = [
            {
                topic: `/topic/loads/${ this._parseLoadId() }`,
                handler: this._handleAuctionUpdate.bind(this)
            }
        ];

        this.state = {
            auctions: [],
            time: Date.now(),
            carriersWithWeeklyLoadCount: [],
            isLatestAuctionCancelReasonInternal: null,
            dispatchDetails: null,
            showBrokerActionsDropdown: false,
            documentTypes: null,
            manualRejectReasons: [],
            paymentDetails: [],
            availableCarrierDispatchers: [],
            driverInfoRequired: null,
            tmwDataMismatchType: null,
            brokerFalloffReason: null,
            showFalloffCarrierConfirmationPopup: false,
            falloffCarrierPopupStatus: undefined,
            falloffCarrierErrorMessage: null
        };

        this._fetchData = this._fetchData.bind(this);
        this._addExistingCarrierDispatcher = this._addExistingCarrierDispatcher.bind(this);
        this._addNewCarrierDispatcher = this._addNewCarrierDispatcher.bind(this);
        this._editCarrierDispatcher = this._editCarrierDispatcher.bind(this);
        this._removeCarrierDispatcher = this._removeCarrierDispatcher.bind(this);
        this._carrierDispatchersContentProvider = this._carrierDispatchersContentProvider.bind(this);
        this._onExtendOffer = this._onExtendOffer.bind(this);
        this._onFalloffReasonSelected = this._onFalloffReasonSelected.bind(this);
        this._onLatestAuctionCancelReasonChange = this._onLatestAuctionCancelReasonChange.bind(this);
        this._onDriverInfoRequiredChange = this._onDriverInfoRequiredChange.bind(this);
        this._setDriverInfoRequiredChange = this._setDriverInfoRequiredChange.bind(this);
        this._onChangeBookNow = this._onChangeBookNow.bind(this);
        this._openBrokerActionsDropdown = this._openBrokerActionsDropdown.bind(this);
        this._closeBrokerActionsDropdown = this._closeBrokerActionsDropdown.bind(this);
        this._onOpenCarrierFalloffPopup = this._onOpenCarrierFalloffPopup.bind(this);
        this._onCloseFalloffCarrierPopup = this._onCloseFalloffCarrierPopup.bind(this);
    }

    static propTypes = {
        ws: PropTypes.instanceOf(WebSocketService),
        account: PropTypes.object
    }

    static defaultProps = {
        ws: WebSocketService.instance(),
        account: null
    }

    /**
     * The interval id for rerendering the component.
     *
     * @type { Number }
     * @private
     */
    _interval;

    componentDidMount() {
        super.componentDidMount();

        this._fetchData();
        this._fetchCarriersWithExceededLimit();
        this._fetchAvailableBrokerFalloffReasons();
        this._fetchManualRejectReasons();
        this._fetchDispatchDetails();
        this._fetchDocumentTypes();
        this._interval = setInterval(() => {
            this.setState({
                time: Date.now()
            })
        }, 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!ObjectUtils.equal(this.props.match.params, prevProps.match.params)) {
            this._unsubscribeAll();
            this._fetchData();

            this.subscriptions = [
                {
                    topic: `/topic/loads/${ this._parseLoadId() }`,
                    handler: this._handleAuctionUpdate.bind(this)
                }
            ];
            this._subscribeAll();

            this.setState({ driverInfoRequired: null })
        } else if (!ObjectUtils.arraysEqual(prevState.auctions, this.state.auctions)) {
            this._fetchCarriersWithExceededLimit();

            const newSubscriptions = this.state.auctions.filter(a => !this.subscriptions.some(sub => sub.topic.includes(a.id)))
                .flatMap(a => {
                    return [
                        {
                            topic: `/topic/auctions/${ a.id }`,
                            handler: this._handleAuctionUpdate.bind(this)
                        },
                        {
                            topic: `/topic/auctions/${ a.id }/offers`,
                            handler: this._handleAuctionUpdate.bind(this)
                        },
                        {
                            //TODO: Fix this in the future so that it only refreshes winner details on the page
                            topic: `/topic/auctions/${ a.id }/winner-details`,
                            handler: this._handleAuctionUpdate.bind(this)
                        },
                        {
                            topic: `/topic/auctions/${ a.id }/tmw-data-mismatch`,
                            handler: this._handleTMWDataMismatchUpdate.bind(this)
                        }
                    ];
                });

            if (newSubscriptions.length > 0) {
                this.subscriptions = this.subscriptions.concat(newSubscriptions);
                this._subscribeAll();
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this._interval);
        super.componentWillUnmount();
    }

    _parseLoadId() {
        return this.props.match.params?.loadId;
    }

    _latestAuction(auctions) {
        const relevantAuctions = auctions || this.state.auctions;
        return relevantAuctions[this.state.auctions.length - 1];
    }

    async _fetchDocumentTypes() {
        const documentTypes = await DocumentUtils.fetchDocumentTypes(false);
        this.setState({ documentTypes });
    }

    _fetchAvailableBrokerFalloffReasons() {
        const apiUrl = "broker/auction/tendering/falloff-reasons";
        RestService.instance().get(apiUrl).then(reasons => {
            this.setState({ reasons: reasons.data })
        });
    }

    _fetchManualRejectReasons() {
        const apiUrl = "broker/auction/tendering/reject-offer-reasons";
        RestService.instance().get(apiUrl).then(reasons => {
            this.setState({ manualRejectReasons: reasons.data });
        });
    }

    _onFalloffReasonSelected(_value, option) {
        this.setState({ brokerFalloffReason: JSON.parse(option.key), note: "" });
    }

    async _fetchData() {
        const { data: tenderAuctions } = await TenderAuctionApiService.list({
            loadBusinessId: this._parseLoadId()
        });
        const auctionPromises = await tenderAuctions.map(auction => {
            return TenderAuctionBrokerApiService.enrichTenderAuction(auction);
        });

        const auctions = await Promise.all(auctionPromises);
        const numberOfAuctions = auctions.length;

        if (numberOfAuctions) {
            this.setState({ auctions }, () => {
                this._fetchAvailableCarrierDispatchers();
                this._fetchWinnerPaymentDetails();
            });
            this.setState({ tmwDataMismatchType: auctions[numberOfAuctions - 1].tmwDataMismatchType });
        } else {
            this.props.history.replace("/404");
        }
    }

    _fetchAvailableCarrierDispatchers() {
        const latestAuction = this._latestAuction();
        const bookingBid = this._determineBookingBid(latestAuction.bids, latestAuction.status);
        
        if (!bookingBid) {
            return;
        }

        RestService.instance()
            .get('broker/carrier-dispatchers', { carrierId: bookingBid.candidate.carrier.id })
            .then(response => this.setState(prevState => {
                const auction = this._latestAuction(prevState.auctions);
                const notAlreadyAdded = response.data.filter(dispatcher => !auction.carrierDispatchers.find(cd => cd.id === dispatcher.id));
                return { 
                    availableCarrierDispatchers: notAlreadyAdded
                };
            }));
    }

    _addExistingCarrierDispatcher(dispatcher) {
        return RestService.instance()
            .put(`broker/auction/tendering/${ this._latestAuction().id }/carrier-dispatchers/${ dispatcher.id }`)
            .then(this._fetchData);
    }

    async _onDriverInfoRequiredChange(auctionId) {
        try {
            const response = await TenderAuctionBrokerApiService.updateDriverInfoRequired(auctionId);

            this.setState({ driverInfoRequired: response })
        } catch(error) {
            console.error(error.message);
        }
    }

    _setDriverInfoRequiredChange() {
        const latestAuction = this._latestAuction();
        const bookingBid = this._determineBookingBid(latestAuction.bids, latestAuction.status);

        if (!bookingBid) {
            return;
        }

        this.setState({ driverInfoRequired: bookingBid.candidate.driverInfoRequired })
    }

    _addNewCarrierDispatcher(dispatcher) {
        const latestAuction = this._latestAuction();
        const bookingBid = this._determineBookingBid(latestAuction.bids, latestAuction.status);
        const auctionConfirmed = CONFIRMED_AUCTION_STATUSES.includes(latestAuction.status);
        
        if (!auctionConfirmed) {
            return;
        }

        const body = { ...dispatcher, carrierId: bookingBid.candidate.carrier.id };

        return RestService.instance()
            .post(`broker/auction/tendering/${ this._latestAuction().id }/carrier-dispatchers`, body)
            .then(this._fetchData);
    }

    _editCarrierDispatcher(id, dispatcher) {
        RestService.instance()
            .put(`carrier-dispatchers/${ id }`, dispatcher)
            .then(this._fetchData);
    }

    _removeCarrierDispatcher(id) {
        RestService.instance()
            .delete(`broker/auction/tendering/${ this._latestAuction().id }/carrier-dispatchers/${ id }`)
            .then(this._fetchData);
    }

    _onChangeBookNow(auctionId, price) {
        TenderAuctionBrokerApiService.setBookNowPrice(auctionId, price)
            .then(this._fetchData);
    }

    _openBrokerActionsDropdown() {
        this.setState({ showBrokerActionsDropdown: true });
    }

    _closeBrokerActionsDropdown() {
        this.setState({ showBrokerActionsDropdown: false });
    }

    _onOpenCarrierFalloffPopup() {
        this.setState({ showFalloffCarrierConfirmationPopup: true, falloffCarrierPopupStatus: undefined });
    }

    _onCloseFalloffCarrierPopup() {
        if (FORM_STATUSES.LOADING !== this.state.falloffCarrierPopupStatus) {
            this.setState({ showFalloffCarrierConfirmationPopup: false, showBrokerActionsDropdown: false, brokerFalloffReason: null });
            this._fetchData();
        }
    }

    _formFalloffCarrierAction(auctionId) {
        let content;
        let popupSize = 'small';

        switch (this.state.falloffCarrierPopupStatus) {
            case FORM_STATUSES.LOADING:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.LOADING }>
                        <div>
                            <h6>Falling Off a Carrier</h6>
                            <p>This will only take a moment. Please wait...</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            case FORM_STATUSES.ERROR:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseFalloffCarrierPopup }>
                        <div>
                            <h6>Carrier Falloff Failure</h6>
                            <p>{ this.state.falloffCarrierErrorMessage }</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            default:
                content = (
                    <>
                        <p className="heading">Falloff carrier from Load</p>
                        <p className="description">
                            Are you sure you want to falloff carrier from this load? This means the carrier will lose this auction and they will
                            not be able to be offered or bid to an auction for this load.
                        </p>
                        <p className="description">
                            Please select the reason for falling off the carrier from this load.
                        </p>
                        <small className="subheading">Reason</small>
                        <CustomSelect
                            values={ this.state.reasons }
                            selectedValue={ this.state.brokerFalloffReason ? this.state.brokerFalloffReason.reason : null }
                            onSelect={ this._onFalloffReasonSelected }
                            fieldName="reason"
                        />
                        <div className="buttons-wrapper">
                            <Button 
                                type="tertiary" 
                                size="small"
                                onClick={ this._onCloseFalloffCarrierPopup } 
                            >
                                Discard
                            </Button>

                            <Button
                                type="danger"
                                size="small"
                                onClick={ () => {
                                    this.setState({ falloffCarrierPopupStatus: FORM_STATUSES.LOADING });
                                    this._onFalloff(auctionId, this.state.brokerFalloffReason?.id);
                                }}
                                disabled={ !this.state.brokerFalloffReason }
                            >
                                OK
                            </Button>
                        </div>
                    </>
                );
        }

        return (
            <div className="falloff-popup-container">
                <Popup
                    id="popup-falloff-carrier"
                    size={ popupSize }
                    show={ this.state.showFalloffCarrierConfirmationPopup }
                    onClose={ this._onCloseFalloffCarrierPopup }
                    trigger={
                        <a href="#popup-falloff-carrier" onClick={ this._onOpenCarrierFalloffPopup } className="dropdown-item">
                            <small className="action-name">Falloff carrier from Load</small>
                        </a>
                    }
                >
                     <div className="broker-actions-popup">
                        { content }
                     </div>
                </Popup>
            </div>
        );
    }

    _fetchCarriersWithExceededLimit() {
        RestService.instance()
            .get(`auction/tendering/candidates-weekly-load-count?loadId=${ this._parseLoadId() }`, {})
            .then(carriersWithWeeklyLoadCount => this.setState({ carriersWithWeeklyLoadCount }));
    }

    _formConfirmedBrokerActions(auction, bookingBid) {
        const isPendingOffer = BID_STATUSES.PENDING === bookingBid?.status;
        const isNotSentOffer = BID_STATUSES.NOT_SENT === bookingBid?.status;
        const isFailedOffer = FAILED_BID_STATUSES.includes(bookingBid?.status);
        const isAuctionCanceled = AUCTION_STATUSES.CANCELED === auction?.status;

        let actions = [];

        if (bookingBid && WINNING_BID_STATUSES.includes(bookingBid.status) && !AuctionUtils.isAuctionCompleted(auction)) {
            actions.push(this._formFalloffCarrierAction(auction.id));
        }

        if (isPendingOffer && bookingBid.candidate.carrier.active && !isAuctionCanceled) {
            actions.push(
                <ExtendOfferPopup
                    bid={ bookingBid }
                    auction={ auction }
                    onExtendOffer={ (date) => this._onExtendOffer(auction, bookingBid, date) }
                    onCancelExtendOffer={ this._closeBrokerActionsDropdown }
                />
            );
        }

        if ((isPendingOffer || isFailedOffer) && bookingBid?.candidate.carrier.active && !isAuctionCanceled) {
            actions.push(
                <DeclareWinnerPopup
                    auctionId={ auction.id }
                    bid={ bookingBid }
                    candidate={ bookingBid.candidate }
                    onDeclareWinner={ this._fetchData }
                    onCancelWinnerDeclaration={ this._closeBrokerActionsDropdown }
                />
            );
        }

        if ((isPendingOffer || isNotSentOffer) && !isAuctionCanceled) {
            actions.push(
                <RejectCurrentOfferPopup
                    onManualRejectOffer={ (reason, note) => this._onManualRejectOffer(auction.id, bookingBid.id, reason, note) }
                    onCancel={ this._closeBrokerActionsDropdown }
                    manualRejectReasons = { this.state.manualRejectReasons }
                    candidate={ bookingBid.candidate }
                />
            );
        }

        if (WINNING_BID_STATUSES.includes(bookingBid?.status) && CONFIRMED_AUCTION_STATUSES.includes(auction.status)) {
            actions.push(
                <DownloadRateConfirmationPopup
                    auctionId={ auction.id }
                    carrierId={ bookingBid.candidate.carrier.id }
                    carrierAction={ false }
                    onDownload={ this._closeBrokerActionsDropdown }
                />
            );
        }

        if (actions.length > 0) {
            return (
                <div className="broker-actions">
                    <Dropdown 
                        id="broker-actions-dropdown"
                        direction="bottom-left"
                        show={ this.state.showBrokerActionsDropdown }
                        onClose={ this._closeBrokerActionsDropdown }
                        trigger={(
                            <a href="#!" onClick={ this._openBrokerActionsDropdown }>
                                <FontAwesomeIcon icon={ menu } className="icon broker-menu" />
                            </a>
                        )}
                        actions={ actions }
                    />
                </div>
            );
        }

        return <></>;
    }

    _formRestartCanceledAuctionActionPopup(auction) {
        if (AUCTION_STATUSES.CANCELED === auction.status
            && LOAD_STATUSES.AVAILABLE === auction.load.status
            && !this.state.isLatestAuctionCancelReasonInternal) {
            return (
                <div className="canceled-auction-restart">
                    <RestartAuctionPopup
                        auctionId={ auction.id }
                        onRestart={ this._fetchData }
                        restartIcon={ restart }
                        pickupDate={ new Date(auction.load.pickupTime) }
                        laneBookNowPrice={ auction.load.routingGuideLane.bookNowPrice }
                        auctionBookNowPrice={ auction.bookNowPrice }
                    />
                    <span>
                        Currently, there is no active auction on this load. You can restart it to start the tendering process again.
                    </span>
                </div>
            );
        }

        return <></>;
    }

    _formTMWDataMismatchBanner(auction) {
        let content = <></>;
        let actions = <></>;
        let style = 'tmw-data-mismatch-message';
        let type = 'error';

        switch (this.state.tmwDataMismatchType) {
            case 'DIFFERENT_CARRIER_ASSIGNED':
                if (CONFIRMED_AUCTION_STATUSES.includes(auction.status)) {
                    content = (
                        <>
                            <p><span className="mismatch-label">TMW Data Mismatch</span> - A different carrier is currently assigned to this load on the TMW. The following actions can be performed in order to resolve this issue:</p>
                            <ul>
                                <li><p>If the carrier from the TMW is the right carrier, falloff the current winner and declare the carrier assigned in the TMW as a winner</p></li>
                                <li><p>If the carrier from the TMW is not the right carrier, assign the correct one in the TMW first, than declare them as a winner here in the application</p></li>
                            </ul>
                        </>
    
                    );
                } else {
                    content = (
                        <>
                            <p><span className="mismatch-label">TMW Data Mismatch</span> - A carrier is currently assigned to this load on the TMW. The following actions can be performed in order to resolve this issue:</p>
                            <ul>
                                <li><p>If the carrier from the TMW is the right carrier, award them here in the application</p></li>
                                <li><p>If the carrier from the TMW is not the right carrier, assign the correct one in the TMW first, than declare them as a winner here in the application</p></li>
                            </ul>
                        </>
                    );
                }
                
                break;
            case 'CARRIER_NOT_UPDATED_SPLIT_TRIP':
                content = (
                    <>
                        <p><span className="mismatch-label">Split Trip Load Type</span> - Carrier could not be updated in the TMW due to a split trip load type. The following actions can be performed in order to resolve this issue:</p>
                        <ul>
                            <li><p>If the carrier from the TMW is the right carrier, falloff the current winner and declare the carrier assigned in the TMW as a winner</p></li>
                            <li><p>If the carrier from the TMW is not the right carrier, assign the correct one in the TMW first, than declare them as a winner here in the application</p></li>
                        </ul>
                    </>
                );
                break;
            case 'CARRIER_NOT_UPDATED_CONNECTION_ISSUES':
                content = (
                    <p>Carrier could not be updated in TMW due to connection issues. Please resolve this issue in TMW manually.</p>
                );
                break;
            case 'CARRIER_NOT_UPDATED_NOT_CONFIGURED':
                content = (
                    <p>Carrier could not be updated in TMW due to configuration issues. Please resolve this issue in TMW manually.</p>
                );
                break;
            case 'DRIVER_DETAILS_NOT_SUBMITTED_SPLIT_TRIP':
                content = (
                    <>
                        <p><span className="mismatch-label">Split Trip Load Type</span> - Driver Details could not be updated in TMW due to a split trip load type. To resolve this, please perform the following actions:</p>
                        <ul>
                            <li><p>Update driver details in TMW manually</p></li>
                            <li><p>Turn off the requirement for driver details using the toggle</p></li>
                        </ul>
                    </>
                );
                break;
            case 'DRIVER_DETAILS_NOT_SUBMITTED_CONNECTION_ISSUES':
                content = (
                    <p><span className="mismatch-label">TMW Sync Failed</span> - Driver Details could not be saved in TMW due to connection issues. Please resolve this issue in TMW manually.</p>
                );
                break;
            case 'DRIVER_DETAILS_NOT_SUBMITTED_NOT_CONFIGURED':
                content = (
                    <p><span className="mismatch-label">TMW Sync Failed</span> - Driver Details could not be saved in TMW due to configuration issues. Please resolve this issue in TMW manually.</p>
                );
                break;
            case 'CARRIER_TERMINATED':
                const latestAuction = this._latestAuction();
                const notSendBid = latestAuction.bids.find(bid => bid.current && BID_STATUSES.NOT_SENT === bid.status);

                content = (
                    <p className="carrier-terminated-message">
                        <span className="mismatch-label">Terminated Carrier</span> - This carrier is terminated on TMW. Please resolve this issue within the TMW first, then retry syncing again.
                    </p>
                );

                actions = (
                    <RetryOnDemandCarrierSync
                        auctionId={ latestAuction.id }
                        bidId={ notSendBid?.id } 
                        businessId={ notSendBid?.candidate.carrier.businessId } 
                        onActionCompleted={ this._fetchData }
                    />
                );

                style = 'tmw-data-mismatch-message with-actions';
                break;    
            case 'LOAD_ASSIGNED_IN_TMW':
                content = (
                    <p><span className="mismatch-label">Carrier assigned in TMW</span> is not assigned here in the application. To assign them here, please add them as an <span className="mismatch-label">on-demand carrier</span> or <span className="mismatch-label">declare them as a winner</span> on this load.</p>
                );
                type = 'warn';
                break;
            default:
                return content;
        }

        return <Banner className={ style } type={ type } size="medium" content={ content } action={ actions } />;
    }

    async _handleAuctionUpdate(notification) {
        const auctionId = AuctionUtils.parseFromNotification(notification);
        const baseAuction = await TenderAuctionBrokerApiService.getAuction(auctionId);
        const auction = await TenderAuctionBrokerApiService.enrichTenderAuction(baseAuction);
        const index = this.state.auctions.findIndex(a => a.id.toString() === auction.id.toString());
        
        if (index !== -1) {
            this.setState(prevState => ({
                auctions: [...prevState.auctions.slice(0, index), auction, ...prevState.auctions.slice(index + 1)]
            }), this._setDriverInfoRequiredChange);
            this._fetchDispatchDetails();
            this._fetchAvailableCarrierDispatchers();
            this._fetchWinnerPaymentDetails();
        } else if (this._parseLoadId().toString() === auction.load.businessId.toString()) {
            this.setState(prevState => ({ auctions: [...prevState.auctions, auction] }), this._setDriverInfoRequiredChange);
            this._fetchDispatchDetails();
            this._fetchAvailableCarrierDispatchers();
            this._fetchWinnerPaymentDetails();
            this.setState({ tmwDataMismatchType: auction.tmwDataMismatchType });
        }
    }

    async _handleTMWDataMismatchUpdate(notification) {
        const auctionId = AuctionUtils.parseFromNotification(notification);
        const auction = await TenderAuctionBrokerApiService.getAuction(auctionId);
        this.setState({ tmwDataMismatchType: auction.tmwDataMismatchType });
    }

    /**
     * Sends carrier details
     *
     * @param { Object } details
     * @param { String } auctionId
     * @private
     */
    _sendDetails(details, auctionId) {
        return TenderAuctionBrokerApiService.sendCarrierDetails(details, auctionId)
            .finally(this._fetchData);
    }

    _fetchDispatchDetails() {
        const apiUrl = `broker/auction/tendering/${ this._parseLoadId() }/dispatch-details`;
        RestService.instance().get(apiUrl).then(dispatchDetails => {
            this.setState({ dispatchDetails });
        });
    }

    _fetchWinnerPaymentDetails() {
        const latestAuction = this._latestAuction();

        if (CONFIRMED_AUCTION_STATUSES.includes(latestAuction.status)) {
            PaymentDetailsApiService.getOnAuction(latestAuction.id)
                .then(response => this.setState({ paymentDetails: response.data }))
                .catch(error => console.error('An error occurred while fetching payment details for auction', error));
        }
    }

    /**
     * Determines if there is a winning bid in the auction and returns it if so. Checks for confirmed or winner bids first, and if there are none and the auction is not ongoing, checks
     * for falloff bids. If neither exist, null is returned.
     *
     * @param {Array} bids
     * @param {string} status
     * @returns {Object}
     * @private
     */
    _determineBookingBid(bids, status) {
        const winningBid = bids.find(bid => WINNING_BID_STATUSES.includes(bid.status));
        const currentOnDemandOffer = bids.find(bid => CANDIDATE_TYPE.ON_DEMAND === bid.candidate.type && bid.current);

        if (winningBid) {
            return winningBid;
        } else if (AUCTION_STATUSES.CARRIER_FALLOFF === status) {
            return bids.find(bid => FALLOFF_BID_STATUSES.includes(bid.status));
        } else if (currentOnDemandOffer) {
            return currentOnDemandOffer;
        }

        return null;
    }

    /**
     * Determines the breadcrumbs based on the relevant auction status which positions it on a certain screen
     *
     * @param {Object} auction
     * @param {string} loadBusinessId
     * @param {Object} bookingBid
     * @returns {Object}
     * @private
     */
    _determineBreadcrumbs(auction, loadBusinessId, bookingBid) {
        const yesterday = moment().subtract(1, 'days');
        
        if (CONFIRMED_AUCTION_STATUSES.includes(auction.status) && LOAD_STATUSES.COMPLETED !== auction.load.status) {
            return (
                <Breadcrumbs crumbs={ [
                    { path: '/booked-loads', name: 'Booked Loads', backCrumb: false },
                    { path: '', name: `Order #${ loadBusinessId }`, backCrumb: false },
                    { path: '/booked-loads', name: 'Back to Booked Loads', backCrumb: true }
                ] } />
            );
        } else if (((OFFERING_AUCTION_STATUSES.includes(auction.status) && (!bookingBid || BID_STATUSES.NOT_SENT !== bookingBid?.status))) || (new Date(auction.load.pickupTime) >= yesterday && AUCTION_STATUSES.CANCELED !== auction.status && LOAD_STATUSES.COMPLETED !== auction.load.status)) {
            return (
                <Breadcrumbs crumbs={ [
                    { path: '/load-board', name: 'Load Board', backCrumb: false },
                    { path: '', name: `Order #${ loadBusinessId }`, backCrumb: false },
                    { path: '/load-board', name: 'Back to Load Board', backCrumb: true }
                ] } />
            );
        } else {
            return (
                <Breadcrumbs crumbs={ [
                    { path: '/load-history', name: 'Load History', backCrumb: false },
                    { path: '', name: `Order #${ loadBusinessId }`, backCrumb: false },
                    { path: '/load-history', name: 'Back to Load History', backCrumb: true }
                ] } />
            );
        }
    }

    _onFalloff(auctionId, reasonId) {
        TenderAuctionBrokerApiService.falloff(auctionId, reasonId)
            .then(() => this.setState({ falloffCarrierPopupStatus: FORM_STATUSES.SUCCESS }, this._onCloseFalloffCarrierPopup))
            .catch(error => this.setState({ 
                falloffCarrierPopupStatus: FORM_STATUSES.ERROR, 
                falloffCarrierErrorMessage: error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data
            }));
    }

    _onExtendOffer(auction, bid, expiration) {
        TenderAuctionBrokerApiService.extendOffer(auction.id, bid.id, expiration)
            .then(this._fetchData);
    }
    
    _onManualRejectOffer(auctionId, bidId, reason, note) {
        TenderAuctionBrokerApiService.rejectOffer(auctionId, bidId, reason.id, note)
            .then(this._fetchData);
    }

    _onLatestAuctionCancelReasonChange(isLatestAuctionCancelReasonInternal) {
        this.setState({ isLatestAuctionCancelReasonInternal });
    }

    _carrierDispatchersContentProvider(dispatcher) {
        const emptyInfoText = 'There are currently no Carrier Dispatchers for this load.';
        const removePopupHeading = <p className="heading">Remove Carrier Dispatcher</p>;

        let removePopupDescription = <></>;
        if (dispatcher) {
            removePopupDescription = (
                <p className="description">
                    Are you sure you want to remove the Carrier Dispatcher <b> { dispatcher.name } </b> from this load?
                </p>
            );
        }

        const editInfoText = 'Editing will affect this Carrier Dispatcher\'s information on all loads they are assigned to.';
        const editPopupHeading = <p className="heading">Edit Carrier Dispatcher</p>;
        const editPopupDescription = (
            <>
                <p className="description">
                    Are you sure you want to submit these changes?
                </p>
                <p className="description">
                    Please note that the changes will be applied to all loads this dispatcher is assigned to.
                </p>
            </>
        );

        const addExistingButtonText = 'Add Existing Dispatcher';
        const addExistingButtonDescription = 'Assign one of the existing dispatchers to this load.';
        const addNewButtonText = 'Add New Dispatcher';
        const addNewButtonDescription = 'Input details for a new dispatcher and assign them to this load.';
        const mainLabel = 'Dispatcher';

        return { 
            emptyInfoText,
            removePopupHeading,
            removePopupDescription,
            editInfoText,
            editPopupHeading,
            editPopupDescription,
            addExistingButtonText,
            addExistingButtonDescription,
            addNewButtonText,
            addNewButtonDescription,
            mainLabel
        };
    }

    _formCarrierDispatcherDetails() {
        const latestAuction = this._latestAuction();
        const bookingBid = this._determineBookingBid(latestAuction.bids, latestAuction.status);
        const auctionConfirmed = CONFIRMED_AUCTION_STATUSES.includes(latestAuction.status);
        
        if (!bookingBid) {
            return <></>;
        }

        const dispatchers = latestAuction.carrierDispatchers.filter(cd => cd.carrier.id === bookingBid.candidate.carrier.id);

        return (
            <div className="carrier-dispatchers-card">
                <CoordinatorDetailsComponent 
                    heading="Carrier Dispatchers"
                    editable={ auctionConfirmed }
                    coordinators={ dispatchers }
                    existingCoordinators={ this.state.availableCarrierDispatchers }
                    onAddExisting={ this._addExistingCarrierDispatcher }
                    onAddNew={ this._addNewCarrierDispatcher }
                    onEdit={ this._editCarrierDispatcher }
                    onRemove={ this._removeCarrierDispatcher }
                    contentProvider={ this._carrierDispatchersContentProvider } 
                />
            </div>
        );
    }

    render() {
        const { auctions } = this.state;
        let content;

        if (auctions.length) {
            const numberOfAuctions = auctions.length;
            const latestAuction = auctions[numberOfAuctions - 1], load = latestAuction.load;
            const laneType = load.routingGuideLane.lane.type
            const previousAuctions = auctions.slice(0, -1);

            if (load) {
                const bookingBid = this._determineBookingBid(latestAuction.bids, latestAuction.status);
                const breadCrumbs = this._determineBreadcrumbs(latestAuction, load.businessId, bookingBid);
                const restartCanceledAuctionAction = this._formRestartCanceledAuctionActionPopup(latestAuction);
                const brokerActions = this._formConfirmedBrokerActions(latestAuction, bookingBid);

                let offerInformation = <></>, winnerDetails = <></>, currentTenderingCandidateCard;

                const previousTenderingCandidateCards = previousAuctions.map(auction => {
                    return (
                        <TenderingCandidatesComponent
                            key={ auction.id }
                            isExpandable={ true }
                            expandLabel="See Details"
                            collapseLabel="Hide Details"
                            auction={ auction }
                            onDeclareWinner={ this._fetchData }
                            onExtendOffer={ this._onExtendOffer }
                            onManualRejectOffer={ (bidId, reason, note) => this._onManualRejectOffer(auction.id, bidId, reason, note) }
                            account={ this.props.account }
                            carriersWithWeeklyLoadCount={ this.state.carriersWithWeeklyLoadCount }
                            manualRejectReasons={ this.state.manualRejectReasons }
                        />
                    );
                });

                let driver = null;

                if (bookingBid) {
                    // The Booked Loads details view
                    offerInformation = (
                        <OfferInformationComponent
                            account={ this.props.account }
                            carrier={ bookingBid.candidate.carrier.name }
                            hasInvalidDocuments={ DocumentUtils.hasInvalidDocuments(bookingBid.candidate.carrier.documents, this.state.documentTypes) }
                            hasBeenTerminated={ !bookingBid.candidate.carrier.active }
                            bid={ bookingBid }
                            load={ load }
                            auctionId={ latestAuction.id }
                            auctionDeadline={ new Date(latestAuction.deadline) }
                            auctionStatus={ latestAuction.status }
                            showBrokerActions={ true }
                            brokerActions={ brokerActions }
                        />
                    );

                    currentTenderingCandidateCard = (
                        <TenderingCandidatesComponent
                            key={ latestAuction.id }
                            initiallyExpanded={ true }
                            expandLabel="See Details"
                            collapseLabel="Hide Details"
                            auction={ latestAuction }
                            showBrokerActions={ AUCTION_STATUSES.CANCELED !== latestAuction.status }
                            onExtendOffer={ this._onExtendOffer }
                            onManualRejectOffer={ (bidId, reason, note) => this._onManualRejectOffer(latestAuction.id, bidId, reason, note) }
                            account={ this.props.account }
                            carriersWithWeeklyLoadCount={ this.state.carriersWithWeeklyLoadCount }
                            onCancelReasonChange={ this._onLatestAuctionCancelReasonChange }
                            manualRejectReasons={ this.state.manualRejectReasons }
                            onChangeBookNow={ this._onChangeBookNow }
                            onActionCompleted= { this._fetchData }
                        />
                    );

                    if (CONFIRMED_AUCTION_STATUSES.includes(latestAuction.status) || FALLOFF_BID_STATUSES.includes(bookingBid.status)) {
                        driver = bookingBid.details.find(details => details.current === true);
                        const detailsSubmissionDeadline = DateUtils.isBefore(new Date(load.pickupTime), new Date(latestAuction.deadline)) ? latestAuction.deadline : load.pickupTime;
                        const detailsSubmissionDeadlinePassed = DateUtils.isBefore(new Date(detailsSubmissionDeadline), new Date());
                        const driverInfoRequired = this.state.driverInfoRequired !== null ? this.state.driverInfoRequired : bookingBid.candidate.driverInfoRequired;

                        winnerDetails = (
                            <WinnerDetailsComponent
                                driver={ driver }
                                initiallyEditing={ false }
                                canEditAndSend={ AUCTION_STATUSES.CANCELED !== latestAuction.status && AUCTION_STATUSES.CARRIER_FALLOFF !== latestAuction.status && bookingBid.candidate.carrier.active }
                                onSendDetails={ (details) => this._sendDetails(details, latestAuction.id) }
                                carrierName={ bookingBid.candidate.carrier.name }
                                showInformMessage={ true }
                                showWarningMessage={ !driver && detailsSubmissionDeadlinePassed && AUCTION_STATUSES.CARRIER_FALLOFF !== latestAuction.status }
                                laneType={ laneType }
                                driverInfoRequired={ !!driverInfoRequired }
                                bidApproved={ bookingBid.approved }
                                auctionId={ latestAuction.id }
                                disableDriverInfoRequiredSwitch={ !bookingBid.candidate.carrier.active || AUCTION_STATUSES.AWAITING_WINNER_DETAILS !== latestAuction.status || !driverInfoRequired }
                                onDriverInfoRequiredChange={ this._onDriverInfoRequiredChange }
                            />
                        );
                    }
                } else {
                    // The Load Tendering details view
                    currentTenderingCandidateCard = (
                        <TenderingCandidatesComponent
                            key={ latestAuction.id }
                            initiallyExpanded={ true }
                            auction={ latestAuction }
                            showBrokerActions={ AUCTION_STATUSES.CANCELED !== latestAuction.status }
                            onExtendOffer={ this._onExtendOffer }
                            onManualRejectOffer={ (bidId, reason, note) => this._onManualRejectOffer(latestAuction.id, bidId, reason, note) }
                            account={ this.props.account }
                            carriersWithWeeklyLoadCount={ this.state.carriersWithWeeklyLoadCount }
                            onCancelReasonChange={ this._onLatestAuctionCancelReasonChange }
                            manualRejectReasons={ this.state.manualRejectReasons }
                            onChangeBookNow={ this._onChangeBookNow }
                            onActionCompleted= { this._fetchData }
                        />
                    );
                }

                const dispatchDetails = (
                    <DispatchDetailsComponent
                        dispatchDetails={ this.state.dispatchDetails }
                        carrierName={ bookingBid?.candidate.carrier.name }
                        dispatchDisabled={ !bookingBid || !bookingBid.candidate.carrier.active }
                        loadBusinessId={ load.businessId }
                        auctionId={ latestAuction.id }
                        providedWinnerDetails={ bookingBid && (!!driver || !bookingBid.candidate.driverInfoRequired) }
                        isTenderCanceled={ AUCTION_STATUSES.CANCELED === latestAuction.status }
                        didCarrierFalloff={ AUCTION_STATUSES.CARRIER_FALLOFF === latestAuction.status }
                        isBidApproved={ bookingBid?.approved }
                        toSAccepted={ bookingBid?.candidate.acceptedTermsOfService } 
                        onCarrierDispatch={ this._fetchData }
                    />
                );

                let paymentDetails = <></>;
                if (CONFIRMED_AUCTION_STATUSES.includes(latestAuction.status)) {
                    paymentDetails = <PaymentDetailsCard paymentDetails={ this.state.paymentDetails } showCarrier={ true } />;
                }

                content = (
                    <div className="page tendering-details-page">
                        { breadCrumbs }
                        { this._formTMWDataMismatchBanner(latestAuction) }
                        <HeadingComponent load={ load } />
                        { restartCanceledAuctionAction }
                        { offerInformation }
                        { winnerDetails }
                        { dispatchDetails }
                        { paymentDetails }
                        { previousTenderingCandidateCards }
                        { currentTenderingCandidateCard }
                        <LogisticsCoordinators routingGuideLane={ load.routingGuideLane } />
                        { this._formCarrierDispatcherDetails() }
                    </div>
                );
            }
        } else {
            content = (
                <div className="page tendering-details-page loading" />
            );
        }

        return content;
    }
}
