import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight as right, faArrowLeft as left } from '@fortawesome/pro-solid-svg-icons';

import './Breadcrumbs.scss';

export default class Breadcrumbs extends Component {

    static propTypes = {
        crumbs: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
            backCrumb: PropTypes.bool
        }))
    }

    static defaultProps = {
        crumbs: []
    }

    /**
     * The initial state.
     *
     * @type { Number }
     */
    state = {
        lastIndex: this._lastCrumbIndex()
    }

    _lastCrumbIndex() {
        let index = this.props.crumbs.length - 1;
        while (index >= 0) {
            if (this.props.crumbs[index].backCrumb === false) {
                return index;
            }
            index--;
        }
    }

    _isLast(index) {
        return index === this.state.lastIndex;
    }

    render() {
        return (
            <nav className="breadcrumb">
                {
                    this.props.crumbs.map((crumb, index) => {
                        const isLast = this._isLast(index);
                        const status = isLast ? ' active' : ' inactive';
                        const icon = isLast ? <></> : <FontAwesomeIcon icon={ right } className={ 'breadcrumb-icon' + status } />;

                        if (crumb.backCrumb === false) {
                            return (
                                <div className="breadcrumb-item" key={ index }>
                                    <NavLink exact to={ crumb.path || "#!" } className={ 'breadcrumb-text' + status } activeClassName="">
                                        { crumb.name }
                                    </NavLink>
                                    { icon }
                                </div>
                            );
                        } else {
                            return (
                                <div className="back-item" key={ index }>
                                    <FontAwesomeIcon icon={ left } className="breadcrumb-back-icon" />
                                    <NavLink exact to={ crumb.path || "# " } className="breadcrumb-back">
                                        { crumb.name }
                                    </NavLink>
                                </div>
                            );
                        }
                    })
                }
            </nav>
        );
    }
}
