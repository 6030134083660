import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faColumns as columnIconSolid,
    faExclamationTriangle as warningIcon,
    faTasksAlt as activityIconSolid
 } from '@fortawesome/pro-solid-svg-icons';
import {
    faColumns as columnIconLight,
    faTasksAlt as activityIconLight
 } from '@fortawesome/pro-light-svg-icons';

import { USER_STATUS } from 'common/constants';
import Tooltip from 'component/Tooltip';
import MemberActivityLog from 'component/carrier/MemberActivityLog';
import ProfileInformation from 'component/carrier/ProfileInformation';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Tab from 'component/navigation/Tab';
import RestService from 'service/RestService';
import AuthorizationService from 'service/AuthorizationService';
import StringUtils from 'utils/StringUtils';
import ObjectUtils from 'utils/ObjectUtils';

import './UserProfile.scss';

export default class UserProfile extends Component {

    static propTypes = {
        account: PropTypes.object
    }

    static defaultProps = {
        account: null
    }

    constructor(props){
        super(props);

        this._fetchUserInfo = this._fetchUserInfo.bind(this);
        this._canUserEditProfile = this._canUserEditProfile.bind(this);
    }

    state = {
        user: null,
        canEdit: true
    };


    componentDidMount() {
        const userId = this._getUserId();

        if (userId) {
            this._fetchUserInfo(userId);
        }
    }

    componentDidUpdate(prevProps) {
        if (!ObjectUtils.equal(this.props.match.params, prevProps.match.params)) {
            const userId = this._getUserId();

            if (userId) {
                this._fetchUserInfo(userId);
            }
        }
    }

    _getUserId() {
        return this.props.match.params?.id;
    }

    _canUserEditProfile() {
        if (this.state.user) {
            const isCurrentUser = this.props.account.internalId === this._getUserId();
            const hasPermission = AuthorizationService.instance().canUserAccess(this.props.account, 'user:write');
            this.setState({ canEdit: (isCurrentUser || hasPermission ) && USER_STATUS.REMOVED.field !== this.state.user.status && this.state.user.carrier?.active });
        }
    }

    _fetchUserInfo(id) {
        if (id) {
            RestService.instance().get(`user/internal/${ id }/user`).then(user => {
                // TODO: Fix.
                if (user?.roles.find(role => role.name === 'CARRIER')) {
                    this.setState({ user }, this._canUserEditProfile);
                }
            })
            .catch(() => this.props.history.replace("/404"));
        }
    }

    _formBreadcrumbs() {
        if (this.props.account.carrierId && this.props.account.internalId !== this._getUserId()) {
            return (
                <Breadcrumbs 
                    crumbs={[
                        { path: `/user`, name: 'My Profile', backCrumb: false },
                        { path: `/carrier/${ this.props.account.carrierId }`, name: this.state.user.carrier.name, backCrumb: false },
                        { path: '', name: this.state.user.name, backCrumb: false }  
                    ]}
                />
            );
        } else if (!this.props.account.carrierId) {
            if (AuthorizationService.instance().canUserAccess(this.props.account, 'user:read:all')) {
                return (
                    <Breadcrumbs 
                        crumbs={[
                            { path: `/users`, name: 'Users Overview', backCrumb: false },
                            { path: `/carrier/${ this.state.user.carrier.id }`, name: this.state.user.carrier.name, backCrumb: false },
                            { path: '', name: this.state.user.name, backCrumb: false }  
                        ]}
                    />
                );    
            } 
            return (
                <Breadcrumbs 
                    crumbs={[
                        { path: `/carrier/${ this.state.user.carrier.id }`, name: this.state.user.carrier.name, backCrumb: false },
                        { path: '', name: this.state.user.name, backCrumb: false }  
                    ]}
                />
            );    
        }

        return <></>;
    }

    render() {
        let activityTab;
        if (AuthorizationService.instance().canUserAccess(this.props.account, 'activity:read')) {
            activityTab = (
                <Tab id="activity-log" title="Activity Log" activeIcon={ activityIconSolid } inactiveIcon={ activityIconLight }>
                    <MemberActivityLog user={ this.state.user } />
                </Tab>
            );
        }

        if (this.state.user) {
            const { user: { name, status, carrier } } = this.state;

            const initials = StringUtils.firstTwoInitials(name);
            const invalid = USER_STATUS.REMOVED.field === status || !carrier.active;
            let warning = <></>;
            if (invalid) {
                warning = (
                    <div className="account-warning-icon">
                        <FontAwesomeIcon icon={ warningIcon } />
                        <Tooltip direction="bottom">Terminated or removed user</Tooltip>
                    </div>
                );
            }

            return (
                <div className="page user-profile-page">
                    { this._formBreadcrumbs() }
                    <div className="heading-card">
                        <div className={ `user-name-icon  ${ invalid ? 'invalid' : '' }` }>
                            <div className="initials" title={ initials }>
                                { initials }
                            </div>
                            { warning }
                        </div>
                        <strong className="user-name">
                            <b>{ name }</b>
                        </strong>
                    </div>
                    <div className="profile-tabs">
                        <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                            <Tab default id="profile" title="Profile" activeIcon={ columnIconSolid } inactiveIcon={ columnIconLight } >
                                <ProfileInformation user={ this.state.user } canEdit={ this.state.canEdit } refreshProfile={ this._fetchUserInfo } />
                            </Tab>
                            { activityTab }
                        </NavigableTabs>
                    </div>
                </div>
            );
        }

        return (
            <div className="profile-not-found">
                <h5 className="heading">
                    No Profile Information
                </h5>
                 <p>
                    There is no profile information to display for this user.
                </p>
            </div>
        );
    }
}
