import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt as upload, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import { faCloudUploadAlt as regularUpload } from '@fortawesome/pro-regular-svg-icons';

import UploadFilePopup from 'component/UploadFilePopup';
import Button from 'component/Button';
import CustomSelect from 'component/form/CustomSelect';
import CustomTextArea from 'component/form/CustomTextArea';
import Tooltip from 'component/Tooltip';
import { FORM_STATUSES, OTHER_DELIMITER, TRIGGER_TYPES } from 'common/constants';

import './CSVUpload.scss';

const VALUES = [
    {
        "title": ',',
        "label": 'Comma ","'
    },
    {
        "title": ';',
        "label": 'Semicolon ";"'
    },
    {
        "title": '\t',
        "label": 'Tab "\t"'
    },
    {
        "title": ' ',
        "label": 'Space " "'
    },
    {
        "title": 'Other',
        "label": 'Other'
    }
];

export default class CSVUpload extends Component {

    static propTypes = {
        size: PropTypes.oneOf(['regular', 'small']),
        trigger: PropTypes.oneOf([TRIGGER_TYPES.BUTTON, TRIGGER_TYPES.LABEL]),
        withUnloadConfirmation: PropTypes.bool,
        labels: PropTypes.shape({
            title: PropTypes.string,
            buttonText: PropTypes.string,
            triggerText: PropTypes.string
        }),
        triggerButtonType: PropTypes.string,
        description: PropTypes.element,
        loadingElement: PropTypes.element,
        successElement: PropTypes.element,
        emptySuccessElement: PropTypes.element,
        errorElement: PropTypes.element,
        uploadCSVPromise: PropTypes.func.isRequired,
        templateReference: PropTypes.string,
        onCreated: PropTypes.func,
        onResponseReceived: PropTypes.func
    };

    static defaultProps = {
        size: 'regular',
        trigger: TRIGGER_TYPES.BUTTON,
        withUnloadConfirmation: false,
        labels: {
            title: 'Upload CSV',
            buttonText: 'Upload',
            triggerText: 'Upload CSV'
        },
        triggerButtonType: 'primary',
        description: <></>,
        loadingElement: <></>,
        successElement: <></>,
        emptySuccessElement: <></>,
        errorElement: <></>,
        templateReference: null,
        onCreated: () => { /* */ },
        onResponseReceived: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onUnload = this._onUnload.bind(this);
        this._uploadCSV = this._uploadCSV.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._onDelimiterChange = this._onDelimiterChange.bind(this);
        this._onInputValueChange = this._onInputValueChange.bind(this);
    }

    state = {
        showPopup: false,
        uploadStatus: null,
        delimiter: ',',
        customDelimiter: ''
    };

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.uploadStatus !== this.state.uploadStatus &&
            FORM_STATUSES.LOADING === this.state.uploadStatus &&
            this.props.withUnloadConfirmation) {
            window.addEventListener('beforeunload', this._onUnload);
        }

        if (prevState.uploadStatus !== this.state.uploadStatus &&
            FORM_STATUSES.LOADING === prevState.uploadStatus &&
            this.props.withUnloadConfirmation) {
            window.removeEventListener('beforeunload', this._onUnload);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this._onUnload);
    }

    _onUnload(event) {
        event.preventDefault();
        event.returnValue = "Please note that leaving the page while the upload is in progress can cause unpredictable application behavior due to partial synchronization. We advise you to wait until the process is finished.";
    }

    _onDelimiterChange(delimiter) {
        this.setState({ delimiter });
    }

    _onOpenPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _onClosePopup() {
        this.setState({
            showPopup: false,
            uploadStatus: null,
            numberOfAddedItems: null,
            delimiter: ',',
            customDelimiter: ''
        });

        if (FORM_STATUSES.SUCCESS === this.state.uploadStatus) {
            this.props.onCreated();
        }
    }

    _uploadCSV(file) {
        this.setState({ uploadStatus: FORM_STATUSES.LOADING });

        const delimiter = this.state.delimiter !== 'Other' ? this.state.delimiter : this.state.customDelimiter;

        this.props.uploadCSVPromise(file, delimiter)
            .then(numberOfAddedItems => {
                this.setState({
                    uploadStatus: parseInt(numberOfAddedItems) > 0 ? FORM_STATUSES.SUCCESS : FORM_STATUSES.EMPTY_SUCCESS
                });
                this.props.onResponseReceived(numberOfAddedItems);
            })
            .catch(error => {
                let errorMessage = "Something went wrong.";
                const errorResponse = (error || {}).response || {};
                const errorData = errorResponse.data || {};

                if (errorResponse.status && errorResponse.status !== 500) {
                    errorMessage = errorData;
                }

                this.setState({ uploadStatus: FORM_STATUSES.ERROR });
                this.props.onResponseReceived(null, errorMessage);
            });
    }

    _formChooseDelimiter() {
        return (
            <div>
                <small>
                    <b>Column Delimiter</b>
                    <div className="icon-div">
                        <FontAwesomeIcon icon={ info } className="icon" />
                        <Tooltip direction="top">
                            The column delimiter represents the character <br/>
                            used to separate data into columns. <br/> <br/>
                            You can find it by checking your export or <br/>
                            regional settings, or opening the csv in a <br/>
                            standard text editor.
                        </Tooltip>
                    </div>
                </small>
                <CustomSelect
                    values={ VALUES }
                    selectedValue={ this.state.delimiter }
                    onSelect={ this._onDelimiterChange }
                />
            </div>
        );
    }

    /**
     * Handles custom delimiter input change
     *
     * @param {Object} event
     */
    _onInputValueChange(event) {
        const value = event.target.value;
        if (value !== '\n') {
            this.setState({ customDelimiter: value });
        }
    }

    _formEnterCustomDelimiter() {
        if ('Other' === this.state.delimiter) {
            return (
                <CustomTextArea
                    label="Other"
                    rows={ 1 }
                    placeholder="Enter your custom delimiter..."
                    required={ true }
                    acceptsWhitespace= { true }
                    value={ this.state.customDelimiter }
                    fieldName="customDelimiter"
                    onValueChange={ this._onInputValueChange }
                    maxLength= { 1 }
                />
            );
        }
    }

    _getUploadPopupProps() {
        const { description, loadingElement, successElement, emptySuccessElement, errorElement } = this.props;

        const labels = {
            title: this.props.labels.title,
            buttonText: this.props.labels.buttonText,
            uploadSubtitle: 'Upload File',
            acceptedFormats: '(.csv, .tsv)'
        };

        let trigger;

        switch (this.props.trigger) {
            case TRIGGER_TYPES.BUTTON:
                trigger = ( 
                    <Button 
                        type={ this.props.triggerButtonType }
                        size={ this.props.size }
                        leftIcon={ upload }
                        onClick={ this._onOpenPopup }
                    >
                        { this.props.labels.triggerText }
                    </Button>
                );
                break;
            case TRIGGER_TYPES.LABEL:
                trigger = ( 
                    <a href="#popup-upload-file" onClick={ this._onOpenPopup } className="dropdown-item">
                        <FontAwesomeIcon icon={ regularUpload } className="action-icon" />
                        <small className="action-name">{ this.props.labels.triggerText }</small>
                    </a>
                );
                break;
            default:
                console.error(`Unsupported trigger "${ this.props.trigger }"`);
        }

        const chooseDelimiter = this._formChooseDelimiter();

        return {
            labels,
            description,
            trigger,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            chooseDelimiter
        };
    }

    render() {
        const {
            labels,
            description,
            trigger,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            chooseDelimiter
        } = this._getUploadPopupProps();

        return (
            <div className="csv-upload-container">
                <UploadFilePopup 
                    show={ this.state.showPopup }
                    labels={ labels }
                    trigger={ trigger }
                    children={ <>{ description }{ chooseDelimiter }{ this._formEnterCustomDelimiter() }</> }
                    loadingElement={ loadingElement }
                    successElement={ successElement }
                    emptySuccessElement={ emptySuccessElement }
                    errorElement={ errorElement }
                    uploadStatus={ this.state.uploadStatus }
                    addDisabled={ OTHER_DELIMITER === this.state.delimiter && this.state.customDelimiter === '' }
                    acceptedFormats=".csv,.tsv"
                    onUploadFile={ this._uploadCSV }
                    onClose={ this._onClosePopup }
                    templateReference={ this.props.templateReference }
                    delimiter={ OTHER_DELIMITER !== this.state.delimiter ? this.state.delimiter : this.state.customDelimiter } 
                />
            </div>
        );
    }
}
