import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip as attachmentIcon } from '@fortawesome/pro-light-svg-icons';
import { faCloudDownload as downloadIcon, faTrash as removeIcon } from '@fortawesome/pro-solid-svg-icons';

import { FINISHED_RFP_AUCTION_STATUSES, RFP_AUCTION_STATUSES } from 'common/constants';
import Button from 'component/Button';
import Popup from 'component/Popup';
import Separator from 'component/Separator';
import DownloadFileComponent from 'component/DownloadFileComponent';
import CanAccess from 'component/CanAccess';
import AuthenticationService from 'service/AuthenticationService';
import RestService from 'service/RestService';

import './ViewAttachments.scss';

export default class ViewAttachments extends Component {

    static propTypes = {
        attachments: PropTypes.array.isRequired,
        rfp: PropTypes.object.isRequired,
        trigger: PropTypes.oneOf(['menu', 'button']).isRequired,
        onUpdate: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        onUpdate: () => { /* */ },
        onClose: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            showRemoveAttachmentPopup: false,
            removeAttachment: null,
            isCarrier: true,
            isRFPCompleted: RFP_AUCTION_STATUSES.COMPLETED === this.props.rfp.status
        };

        this._removeAttachment = this._removeAttachment.bind(this); 
        this._onOpenViewPopup = this._onOpenViewPopup.bind(this);
        this._onCloseViewPopup = this._onCloseViewPopup.bind(this);
        this._onCloseRemoveAttachmentPopup = this._onCloseRemoveAttachmentPopup.bind(this);
    }

    componentDidMount() {
        AuthenticationService.instance().getAccount().then(account => {
            if (account.permissions && account.permissions.includes('rfp:write')) {
                this.setState({
                    isCarrier: false
                });
            }
        } );
    }

    _onOpenViewPopup(event) {
        event.preventDefault();
        this.setState({ showPopup: true });
    }

    _onCloseViewPopup() {
        this.setState({ showPopup: false, showRemoveAttachmentPopup: false });
        this.props.onClose();
    }

    _onOpenRemoveAttachmentPopup(attachmentId, attachmentName) {
        this.setState({ 
            removeAttachment: { id: attachmentId, name: attachmentName },
            showRemoveAttachmentPopup: true
        });
    }

    _onCloseRemoveAttachmentPopup() {
        this.setState({ showRemoveAttachmentPopup: false });
    }

    _removeAttachment() {
        RestService.instance().delete(`auction/rfp/${ this.props.rfp.id }/attachment/${ this.state.removeAttachment.id }`)
        .then(this.props.onUpdate)
        .then(this._onCloseRemoveAttachmentPopup);
    }

    _formSeeAttachmentsTrigger() {
        switch (this.props.trigger) {
            case 'menu':
                return (
                    <div onClick={ this._onOpenViewPopup } className="dropdown-item">
                        <FontAwesomeIcon className="action-icon" icon={ attachmentIcon }/>
                        <small className="action-name">
                            See Attachments ({ this.props.attachments.length })
                        </small>
                    </div>
                );
            case 'button':
                return (
                    <Button
                        onClick={ this._onOpenViewPopup }
                        leftIcon={ attachmentIcon }
                        type="tertiary"
                        size="small"
                    >
                        See attachments <span>({ this.props.attachments.length })</span>
                    </Button>
                );
            default:
                // Nothing to do here.
        }
    }

    _mapAttachments() {
        const { attachments, rfp } = this.props;

        return attachments.map((attachment, index) => {
            const separator = index === attachments.length - 1 ? <></> : <Separator />;
            return (
                <Fragment key={ attachment.id }>
                    <div className="attachment">
                        <small className="attachment-name" title={ attachment.name }><b>{ attachment.name }</b></small>
                        <div className="actions-wrapper">
                            <DownloadFileComponent
                                reference={ `rfp/${ rfp.id }/attachment/${ attachment.id }` }
                                isStaticResource={ true }
                                trigger={
                                    <FontAwesomeIcon className="download-icon" icon={ downloadIcon } />
                                }
                            />
                            <CanAccess
                                action="rfp:write"
                                yes={
                                    !this.state.isRFPCompleted && <FontAwesomeIcon className="remove-icon" icon={ removeIcon } onClick={ () => this._onOpenRemoveAttachmentPopup(attachment.id, attachment.name) }/>
                                }
                            />
                        </div>
                    </div>
                    { separator }
                </Fragment>
            );
        });
    }

    render() {
        const { attachments, rfp } = this.props;
        const variant = FINISHED_RFP_AUCTION_STATUSES.includes(rfp.status) ? 'Routing Guide' : 'RFP';

        let heading, body, actions, className = 'see-attachments-popup', size = 'medium';
        if (this.state.showRemoveAttachmentPopup) {
            heading = <p className="heading">Remove Attachment</p>;
            body = <>Are you sure you want to remove <b>{ this.state.removeAttachment.name }</b> from this { variant }?</>;
            className = 'remove-attachment-popup';
            size = 'small';

            actions = (
                <>
                    <Button 
                        type="tertiary" 
                        size="small"
                        onClick={ this._onCloseRemoveAttachmentPopup }
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="danger" 
                        size="small"
                        onClick={ this._removeAttachment }
                    >
                        Remove
                    </Button>
                </>
            );
        } else if (attachments.length === 0) {
            heading = <h6 className="heading">There are no attachments uploaded on this { variant }</h6>;

            if (this.state.isCarrier) {
                body = !this.state.isRFPCompleted ? 'Please come back later and check again.' : `New attachments will not be uploaded to this ${ variant } since it has been completed.`;
            } else {
                body = !this.state.isRFPCompleted ? 'Please go back and upload your attachments by clicking the "Actions" button and by selecting the "Add Attachments" action.' : `You can no longer add new attachments to this ${ variant } since it has been completed.`;
            }

            actions = (
                <Button
                    type="tertiary"
                    onClick={ this._onCloseViewPopup }
                >
                    Close
                </Button>
            );
        } else {
           heading = <h6 className="heading">{ variant } Attachments</h6>;

            if (this.state.isCarrier) {
                body = `Below are all attachments pertaining to this ${ variant }. You can download them by clicking the download icon.`;
            } else {
                body = 'This is the list of attachments all invited carriers will be able to see.';
            }

            actions = (
                <Button
                    type="tertiary"
                    onClick={ this._onCloseViewPopup }
                >
                    Close
                </Button>
            );
        }

        return (
            <div className="view-attachments-container">
                <Popup
                    id="popup-see-attachments"
                    size={ size }
                    show={ this.state.showPopup }
                    onClose={ this._onCloseViewPopup }
                    trigger={ this._formSeeAttachmentsTrigger() }
                >
                    <div className={ className }>
                        { heading }
                        <p className="description">
                            { body }
                        </p>
                        { !this.state.showRemoveAttachmentPopup && attachments.length > 0 &&
                            <div className="attachment-list">
                                <sup className="attachments-heading">List of Attachments</sup>
                                { this._mapAttachments() }
                            </div>
                        }
                        <div id="buttons-wrapper" className="buttons-wrapper">
                            { actions }
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
