import StringUtils from 'utils/StringUtils';

export default class DateUtils {

    static isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    static format(date) {
        if (date) {
            if (!(date instanceof Date)) {
                date = new Date(date);
            }

            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            });
        } else {
            return '( invalid date )'
        }
    }

    static formatLocal(date, offsetSeconds = 0) {
        if (date) {
            if (!(date instanceof Date)) {
                date = new Date(date);
            }

            date.setUTCSeconds(date.getUTCSeconds() + offsetSeconds);

            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC'
            });
        } else {
            return '( invalid date )'
        }
    }

    static formatDate(date) {
        if (date) {
            if (!(date instanceof Date)) {
                date = new Date(date);
            }

            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            });
        } else {
            return '( invalid date )';
        }
    }

    static difference(start, end, params = { largestUnitOnly: false, oneLetterUnit: false }) {
        if (start && end) {
            const { largestUnitOnly, oneLetterUnit, fewSecondsIdentifier } = params;

            const spaceBeforeUnit = oneLetterUnit ? '' : ' ';
            let returnValue = '';
            let seconds = Math.abs(end - start) / 1000;

            const days = Math.floor(seconds / 86400);
            if (days > 0) {
                returnValue += `${ days } ${ oneLetterUnit ? 'd' : StringUtils.pluralize(days, 'day') } `;
            }

            seconds -= days * 86400;

            const hours = Math.floor(seconds / 3600) % 24;
            if (hours > 0) {
                returnValue += `${ hours } ${ oneLetterUnit ? 'h' : StringUtils.pluralize(hours, 'hour') } `;
            }

            seconds -= hours * 3600;

            const minutes = Math.floor(seconds / 60) % 60;
            if (minutes > 0) {
                returnValue += `${ minutes } ${ oneLetterUnit ? 'm' : StringUtils.pluralize(minutes, 'min') }`;
            } else if (returnValue === '') {
                return fewSecondsIdentifier || 'A few seconds';
            }

            const values = returnValue.split(' ');

            if (largestUnitOnly || values.length === 2) {
                return `${ values[0] }${ spaceBeforeUnit }${ values[1] }`;
            }
            
            if (values.length >= 4) {
                return `${ values[0] }${ spaceBeforeUnit }${ values[1] } ${ values[2] }${ spaceBeforeUnit }${ values[3] }`;
            }

            return returnValue;
        } else {
            return '( invalid date )';
        }
    }

    static getDaysDifference(start, end) {
        if (start && end) {
            if (!(start instanceof Date)) {
                start = new Date(start);
            }

            if (!(end instanceof Date)) {
                end = new Date(end);
            }

            const  difference = Math.abs(end.setHours(0, 0, 0, 0) - start.setHours(0, 0, 0, 0));
            return Math.floor(difference / (1000 * 3600 * 24));
        }

        return null;
    }

    static getMinuteDifference(start, end) {
        if (start && end) {
            let seconds = Math.abs(end - start) / 1000;
            return Math.floor(seconds / 60);
        }
    }

    /**
     * Checks whether first date is before the second
     * 
     * @param {Date} date1
     * @param {Date} date2
     * @returns {boolean}
     */
    static isBefore(date1, date2) {
        return date1 < date2;
    }

    /**
     * Checks whether first date is after the second
     * 
     * @param {Date} date1
     * @param {Date} date2
     * @returns {boolean}
     */
    static isAfter(date1, date2) {
        return date1 > date2;
    }

    /**
     * Finds the time from/until date2 from the perspective of date1
     *
     * @param {Date} date1
     * @param {Date} date2
     *
     * @returns {Array} the remaining duration as the first element of the array, and date descriptor (ago/left) as the second
     */
    static findTimeBetween(date1, date2) {
        let timeDescriptor;
         if (date1 > date2) {
            timeDescriptor = 'ago';
        } else {
            timeDescriptor = 'left';
        }

        const remainingDuration = this.difference(date1, date2).split(' ');

        return [ remainingDuration, timeDescriptor ];
    }

    /**
     * Calculates the number of calendar weeks the period spans across.
     * Monday is considered the start of the week.
     * In case the end date is before start, it returns a negative number of weeks.
     * In case any of the dates in null, it returns null;
     * 
     * @param {moment} start start of the period
     * @param {moment} end end of the period
     * 
     * @returns {number} number of calendar weeks
     */
    static numberOfCalendarWeeks(start, end) {
        if (!start || !end) {
            return null;
        }

        let startDate = start.clone();
        let endDate = end.clone();
        let multiplier = 1;

        if (start.isAfter(end)) {
            startDate = end.clone();
            endDate = start.clone();
            multiplier = -1;
        }

        const startAdjusted = startDate.startOf('isoWeek');
        const endAdjusted = endDate.endOf('isoWeek');

        return multiplier * Math.round((endAdjusted.diff(startAdjusted, 'days') + 1) / 7);
    }
}
