import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH as filterIcon } from '@fortawesome/pro-solid-svg-icons'

import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import Button from 'component/Button';
import Popup from 'component/Popup';
import CustomSelect from 'component/form/CustomSelect';
import LaneUtils from 'utils/LaneUtils';
import ObjectUtils from 'utils/ObjectUtils';

import './FilterPopup.scss';

const SELECT_SORT_OPTION = 'Select';

const ALL_OPTION = {
    title: 'All',
    field: 'all'
};

const CARGO_TYPE_OPTIONS = [
    ALL_OPTION,
    {
        title: 'Refrigerated',
        field: 'REFRIGERATED'
    },
    {
        title: 'Dry',
        field: 'DRY'
    },
    {
        title: 'Flatbed',
        field: 'FLATBED'
    }
];

const TENDER_CANDIDATE_STATUS_OPTIONS = [
    ALL_OPTION,
    {
        title: TENDER_CANDIDATE_STATUS.ACCEPTED.candidateFacingText,
        field: TENDER_CANDIDATE_STATUS.ACCEPTED.value
    },
    {
        title: TENDER_CANDIDATE_STATUS.PENDING.candidateFacingText,
        field: TENDER_CANDIDATE_STATUS.PENDING.value
    },
    {
        title: TENDER_CANDIDATE_STATUS.EXPIRED.candidateFacingText,
        field: TENDER_CANDIDATE_STATUS.EXPIRED.value
    },
    {
        title: TENDER_CANDIDATE_STATUS.REJECTED.candidateFacingText,
        field: TENDER_CANDIDATE_STATUS.REJECTED.value
    },
    {
        title: TENDER_CANDIDATE_STATUS.DELETED.candidateFacingText,
        field: TENDER_CANDIDATE_STATUS.DELETED.value
    }
];

const SORT_OPTIONS = [
    {
        title: 'Origin (Ascending)',
        key: 'ORIGIN',
        type: 'ASC'
    },
    {
        title: 'Origin (Descending)',
        key: 'ORIGIN',
        type: 'DESC'
    },
    {
        title: 'Destination (Ascending)',
        key: 'DESTINATION',
        type: 'ASC'
    },
    {
        title: 'Destination (Descending)',
        key: 'DESTINATION',
        type: 'DESC'
    }
];

export default class FilterPopup extends Component {
    static propTypes = {
        defaultFilter: PropTypes.shape({
            origin: PropTypes.string,
            destination: PropTypes.string,
            cargo_type: PropTypes.string,
            tender_candidate_status: PropTypes.string,
            sort: PropTypes.string
        }),
        showTenderStatusFilter: PropTypes.bool,
        fetchOrigins: PropTypes.func,
        fetchDestinations: PropTypes.func,
        onFilterChange: PropTypes.func
    }

    static defaultProps = {
        defaultFilter: {},
        showTenderStatusFilter: false,
        fetchOrigins:  () => { /* */ },
        fetchDestinations: () => { /* */ },
        onFilterChange: () => { /* */ }
    }

    constructor(props) {
        super(props);
        
        this._onSelectOrigin = this._onSelectOrigin.bind(this);
        this._onSelectDestination = this._onSelectDestination.bind(this);
        this._onOriginSearchChange = this._onOriginSearchChange.bind(this);
        this._onDestinationSearchChange = this._onDestinationSearchChange.bind(this);
        this._onSelectCargoType = this._onSelectCargoType.bind(this);
        this._onSelectTenderCandidateStatus = this._onSelectTenderCandidateStatus.bind(this);
        this._onSelectSort = this._onSelectSort.bind(this);
        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onApply = this._onApply.bind(this);
    }

    state = {
        showModal: false,
        filter: {}
    }

    componentDidMount() {
        const filter = ObjectUtils.removeEmptyKeys(this.props.defaultFilter || {});
        this.setState({ filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.defaultFilter) !== JSON.stringify(this.props.defaultFilter)) {
            let filter = Object.assign({}, this.props.defaultFilter);
            filter = ObjectUtils.removeEmptyKeys(filter);
            if (JSON.stringify(this.state.filter) !== JSON.stringify(filter)) {
                this.setState({
                    filter: filter
                }, () => {
                    if (prevProps.defaultFilter.origin !== this.props.defaultFilter.origin) {
                        const [ originCity, originState ] = this._getRegionFromFilter(this.props.defaultFilter.origin);
                        this._fetchDestinationsForOrigin({ originCity, originState });
                    }

                    if (prevProps.defaultFilter.destination !== this.props.defaultFilter.destination) {
                        const [ destinationCity, destinationState ] = this._getRegionFromFilter(this.props.defaultFilter.destination);
                        this._fetchOriginsForDestination({ destinationCity, destinationState });
                    }
                });
            }
        }
    }

    /**
     * Helper function to get city and state from region.
     * Region format: City, State.
     * 
     * @param {Object} region
     * @returns {Array}
     */
    _getRegionFromFilter(region) {
        const city = region ? region.split(', ')[0] : undefined;
        const state = region ? region.split(', ')[1] : undefined;
        return [ city, state ];
    }

    _fetchRegions() {
        const [ destinationCity, destinationState ] = this._getRegionFromFilter(this.props.defaultFilter.destination);
        this._fetchOriginsForDestination({ destinationCity, destinationState });

        const [ originCity, originState ] = this._getRegionFromFilter(this.props.defaultFilter.origin);
        this._fetchDestinationsForOrigin({ originCity, originState });
    }

    async _fetchDestinationsForOrigin(params) {
        const response = await this.props.fetchDestinations(params);
        this.setState({
            destinationRegions: [ALL_OPTION, ...response.data.map(region => ({
                title: LaneUtils.formatRegion(region),
                field: region
            }))]
        });
    }

    async _fetchOriginsForDestination(params) {
        const response = await this.props.fetchOrigins(params);
        this.setState({
            originRegions: [ALL_OPTION, ...response.data.map(region => ({
                title: LaneUtils.formatRegion(region),
                field: region
            }))]
        });
    }

    _onSelectOrigin(_value, selectedOrigin) {
        if (selectedOrigin) {
            const origin = JSON.parse(selectedOrigin.key);

            if (ALL_OPTION.field === origin.field) {
                this.setState(prevState => {
                    const filter = prevState.filter;
                    delete filter['origin'];
                    return { filter };
                }, () => this._fetchDestinationsForOrigin());
            } else {
                this.setState(
                    prevState => ({ filter: { ...prevState.filter, origin: LaneUtils.formatRegion(origin.field) } }),
                    () => this._fetchDestinationsForOrigin({ originId: origin.field.id })
                );
            }
        }
    }

    _onSelectDestination(_value, selectedDestination) {
        if (selectedDestination) {
            const destination = JSON.parse(selectedDestination.key);

            if (ALL_OPTION.field === destination.field) {
                this.setState(prevState => {
                    const filter = prevState.filter;
                    delete filter['destination'];
                    return { filter };
                }, () => this._fetchOriginsForDestination());
            } else {
                this.setState(
                    prevState => ({ filter: { ...prevState.filter, destination: LaneUtils.formatRegion(destination.field) } }),
                    () => this._fetchOriginsForDestination({ destinationId: destination.field.id })
                );
            }
        }
    }

    _onOriginSearchChange(value) {
        const [ destinationCity, destinationState ] = this._getRegionFromFilter(this.state.filter.destination);
        this._fetchOriginsForDestination({ destinationCity, destinationState, search: value });
    }

    _onDestinationSearchChange(value) {
        const [ originCity, originState ] = this._getRegionFromFilter(this.state.filter.origin);
        this._fetchDestinationsForOrigin({ originCity, originState, search: value });
    }

    _onSelectCargoType(_value, selectedCargoType) {
        if (selectedCargoType) {
            const cargoType = JSON.parse(selectedCargoType.key);

            this.setState(prevState => {
                if (ALL_OPTION.field === cargoType.field) {
                    const filter = prevState.filter;
                    delete filter['cargo_type'];
                    return { filter };
                }

                return { filter: { ...prevState.filter, cargo_type: cargoType.field } }
            });
        }
    }

    _getSelectedCargoType() {
        let selectedCargoType = ALL_OPTION;
        
        if (this.state.filter.cargo_type) {
            const cargoTypeOption = CARGO_TYPE_OPTIONS.find(option => option.field === this.state.filter.cargo_type);
            selectedCargoType = cargoTypeOption || selectedCargoType;
        }

        return selectedCargoType.title;
    }

    _onSelectTenderCandidateStatus(_value, selectedStatus) {
        if (selectedStatus) {
            const status = JSON.parse(selectedStatus.key);

            this.setState(prevState => {
                if (ALL_OPTION.field === status.field) {
                    const filter = prevState.filter;
                    delete filter['tender_candidate_status'];
                    return { filter };
                }

                return { filter: { ...prevState.filter, tender_candidate_status: status.field } }
            });
        }
    }

    _getSelectedTenderStatus() {
        let selectedTenderStatus = ALL_OPTION;

        if (this.state.filter.tender_candidate_status) {
            const statusOption = TENDER_CANDIDATE_STATUS_OPTIONS.find(option => option.field === this.state.filter.tender_candidate_status);
            selectedTenderStatus = statusOption || selectedTenderStatus;
        }

        return selectedTenderStatus.title;
    }

    _onSelectSort(_value, selectedSort) {
        if (selectedSort) {
            const sort = JSON.parse(selectedSort.key);
            this.setState({
                filter: {
                    ...this.state.filter,
                    sort: sort.key + ',' + sort.type
                }
            });
        }
    }

    _getSelectedSort() {
        let selectedSortOption = SELECT_SORT_OPTION;

        if (this.state.filter.sort) {
            const [key, type] = this.state.filter.sort.split(',');
            const sortOption = SORT_OPTIONS.find(option => option.key === key && option.type === type);
            selectedSortOption = sortOption ? sortOption.title : selectedSortOption;
        }

        return selectedSortOption;
    }

    _onOpen() {
        this.setState({ showModal: true });
        this._fetchRegions();
    }

    _onClose() {
        this.setState({ showModal: false, filter: this.props.defaultFilter }); 
    }

    _onApply() {
        this.props.onFilterChange(this.state.filter);
        this.setState({ showModal: false });
    }

    _formTenderCandidateStatusFilter() {
        if (!this.props.showTenderCandidateStatusFilter) {
            return <></>;
        }

        const selectedStatusOptionValue = this._getSelectedTenderStatus();

        return (
            <div className="field">
                <CustomSelect
                    label="Status"
                    selectedValue={ selectedStatusOptionValue }
                    onSelect={ this._onSelectTenderCandidateStatus }
                    values={ TENDER_CANDIDATE_STATUS_OPTIONS }
                />
            </div>
        );
    }

    render() {
        const selectedSort = this._getSelectedSort();
        const selectedCargoType = this._getSelectedCargoType();
        const tenderCandidateStatusFilter = this._formTenderCandidateStatusFilter();

        return (
            <Popup
                id="filter-popup"
                size="medium"
                show={ this.state.showModal }
                onClose={ this._onClose }
                trigger={ (
                    <Button className="filter-button"
                            type="tertiary"
                            size="small"
                            leftIcon={ filterIcon }
                            onClick={ this._onOpen } >
                        Filter
                    </Button>
                ) }>
                <div className="filter-popup" >
                    <h6 className="heading">
                        <FontAwesomeIcon className="filter-icon" icon={ filterIcon } />
                        Filter
                    </h6>
                    <div className="filter-fields">
                        <div className="field">
                            <CustomSelect
                                label="Origin"
                                selectedValue={ this.state.filter.origin || ALL_OPTION.title }
                                onSelect={ this._onSelectOrigin }
                                withSearch={ true }
                                values={ this.state.originRegions }
                                onSearch={ this._onOriginSearchChange }
                            />
                        </div>
                        <div className="field">
                            <CustomSelect
                                label="Destination"
                                selectedValue={ this.state.filter.destination || ALL_OPTION.title }
                                onSelect={ this._onSelectDestination }
                                withSearch={ true }
                                values={ this.state.destinationRegions }
                                onSearch={ this._onDestinationSearchChange }
                            />
                        </div>
                        <div className="field">
                            <CustomSelect
                                label="Cargo Type"
                                selectedValue={ selectedCargoType }
                                onSelect={ this._onSelectCargoType }
                                values={ CARGO_TYPE_OPTIONS }
                            />
                        </div>
                        { tenderCandidateStatusFilter }
                        <div className="field">
                            <CustomSelect
                                label="Sort By"
                                selectedValue={ selectedSort }
                                onSelect={ this._onSelectSort }
                                values={ SORT_OPTIONS } 
                            />
                        </div>
                    </div>
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onClose }>
                            Cancel
                        </Button>
                        <Button onClick={ this._onApply } disabled={ Object.keys(ObjectUtils.removeEmptyKeys(this.state.filter)).length === 0 }>
                            Apply Filters
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
