import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle as appDetailsIcon,
    faGavel as dashboardIcon,
    faTrailer as bookedLoadsIcon,
    faHistory as historyIcon,
    faClipboardList as rfpIcon,
    faWrench as settingsIcon,
    faUsers as usersIcon
} from '@fortawesome/pro-light-svg-icons';

import { ReactComponent as LogoIcon } from 'file/svg/logo_icon.svg';
import CanAccess from 'component/CanAccess';
import Tooltip from 'component/Tooltip';
import HelpCenterInfoBubble from 'component/HelpCenterInfoBubble';

import './Navbar.scss';

export default class Navbar extends Component {

    render() {
        return (
            <nav className="page-navigation">
                <NavLink to="/" id="nav-link-base" className="page-navigation-header">
                    <LogoIcon />
                </NavLink>
                <div className="page-navigation-container">
                    <div className="top">
                        <CanAccess
                            action={ ['load-tendering:read', 'offered-loads:read'] }
                            yes={
                                <NavLink to="/load-board" id="nav-link-load-board" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ dashboardIcon } />
                                    <Tooltip direction="right">
                                        Load Board
                                    </Tooltip>
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ ['booked-loads:read', 'carrier-booked-loads:read'] }
                            yes={
                                <NavLink to="/booked-loads" id="nav-link-booked-loads" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ bookedLoadsIcon } />
                                    <Tooltip direction="right">
                                        Booked Loads
                                    </Tooltip>
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ ['load-history:read', 'tender-history:read'] }
                            yes={
                                <NavLink to="/load-history" id="nav-link-load-history" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ historyIcon } />
                                    <Tooltip direction="right">
                                        Load History
                                    </Tooltip>
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ ['rfp:read', 'rfp:read:all', 'routing-guide:read'] }
                            yes={
                                <NavLink to="/rfp/list" id="nav-link-rfp" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ rfpIcon } />
                                    <Tooltip direction="right">
                                        Routing Guides
                                    </Tooltip>
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action="user:read:all"
                            yes={
                                <NavLink to="/users" id="nav-link-users" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ usersIcon } />
                                    <Tooltip direction="right">
                                        Users Overview
                                    </Tooltip>
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action="application-details:read"
                            yes={
                                <NavLink to="/application-details" id="nav-link-app-details" className="page-navigation-item">
                                    <FontAwesomeIcon icon={ appDetailsIcon } />
                                </NavLink>
                            }
                        />
                    </div>

                    <div className="page-navigation-bottom">
                        <CanAccess
                            action="help-center:read"
                            yes={ <HelpCenterInfoBubble /> }
                        />

                        <CanAccess
                            action="sm-carriers-sync-config:read"
                            yes={
                                <NavLink to="/settings" id="nav-link-settings" className="page-navigation-item settings-item">
                                    <FontAwesomeIcon icon={ settingsIcon } />
                                    <Tooltip direction="right">
                                        Settings
                                    </Tooltip>
                                </NavLink>
                            }
                        />
                    </div>
                </div>
            </nav>
        );
    }
}
