import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding regions
 */
export default class RegionApiService {

    /**
     * Gets regions
     * 
     * @param {string} search search string
     * @returns {Promise}
     */
    static fetchRegions(search) {
        return RestService.instance().get('regions', { search });
    }
}
