import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as Constants from 'common/constants';
import StringUtils from 'utils/StringUtils';
import DateUtils from 'utils/DateUtils';
import Tag from 'component/Tag';

import './TagList.scss';

export default class TagList extends Component {

    static propTypes = {
        filter: PropTypes.object,
        mapper: PropTypes.func, // Custom mapper function to map filter items into appropriate title and text pairs to show as tags
        onFilterChange: PropTypes.func
    }

    static defaultProps = {
        filter: {},
        mapper: null,
        onFilterChange: () => {/* */}
    }

    constructor(props) {
        super(props);
        this._clearAllFilters = this._clearAllFilters.bind(this);
    }

    _removeFilter(key) {
        const filter = this.props.filter;
        Object.keys(filter).forEach(k => (filter[k] == null || k === key) && delete filter[k]);
        this.props.onFilterChange(filter);
    }

    _clearAllFilters() {
        this.props.onFilterChange({});
    }

    _mapFilterItem(key, value) {
        const mapped = this.props.mapper && this.props.mapper(key, value);

        if (mapped) {
            return mapped;
        }

        return {
            title: StringUtils.capitalize(key.split('_').join(' ')),
            text: this._transformText(key, value)
        }
    }

    _getTags(filter) {
        const tagList = [];
        for (const key in filter) {
            if (filter[key]) {
                const tag = this._mapFilterItem(key, filter[key]);
                tagList.push(
                    <Tag key={ key } title={ tag.title } text={ tag.text } onClose={ () => this._removeFilter(key) } />
                );
            }
        }

        if (tagList.length > 0) {
            tagList.push(
                <a href="# " key="clear-all" onClick={ this._clearAllFilters }>
                    <small className="clear-all-link">Clear All</small>
                </a>
            );
        }

        return tagList;
    }


    // TODO: refactor this method
    _transformText(key, text) {
        let transformed = text || "";

        if (key.toLowerCase().includes("status")) {
            transformed = Constants.AUCTION_STATUSES_FILTER_MAPPING[transformed];
        } else if (typeof text === 'string' || text instanceof String) {
            transformed = text.split("_").join(" ");
            if (StringUtils.isUpperCase(transformed)) {
                transformed = StringUtils.capitalize(transformed, true);
            }
        } else if (moment.isMoment(text)) {
            transformed = DateUtils.formatDate(text);
        }

        if (key.toLowerCase() === "sort") {
            const sortTitle = StringUtils.capitalize(transformed.split(",")[0], true);
            const sortType = (transformed.split(",")[1] || "").toUpperCase() === "ASC" ? "Ascending" : "Descending";
            transformed = `${ sortTitle } (${ sortType })`;
        }

        return transformed;
    }

    render() {
        return (
            this._getTags(this.props.filter)
        );
    }
}
