import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { faUsers as usersIcon, faBell as bellIcon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'component/Button';
import CanAccess from 'component/CanAccess';
import ContentCard from 'component/card/ContentCard';
import Table from 'component/table/custom/Table';
import TableCell from 'component/table/custom/TableCell';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import RoutingGuideLaneNotificationApiService from 'service/api/RoutingGuideLaneNotificationApiService';

import './LogisticsCoordinators.scss';

class LogisticsCoordinators extends Component {

    static propTypes = {
        routingGuideLane: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            logisticsCoordinators: []
        };

        this._goToNotificationCenter = this._goToNotificationCenter.bind(this);
    }

    componentDidMount() {
        this._fetchLogisticsCoordinators();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.routingGuideLane.id !== this.props.routingGuideLane.id) {
            this._fetchLogisticsCoordinators();
        }
    }

    _fetchLogisticsCoordinators() {
        RoutingGuideLaneNotificationApiService.getSubscriptions(this.props.routingGuideLane.id)
            .then(response => this.setState({ logisticsCoordinators: response.data }))
            .catch(error => console.error('An error occurred while trying to fetch logistics coordinators.', error));
    }

    _goToNotificationCenter() {
        this.props.history.push({
            pathname: `/rfp/${ this.props.routingGuideLane.rfpAuction?.id }/notification-center/logistics-coordinators`
        });
    }

    render() {
        let content;
        
        if (this.state.logisticsCoordinators.length > 0) {
            content = (
                <Table className="logistics-coordinators-table">
                    <TableRow isHeader={ true }>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Email</TableHeaderCell>
                        <TableHeaderCell alignment="right">Phone</TableHeaderCell>
                    </TableRow>
                    { this.state.logisticsCoordinators.map((coordinator, index) => (
                        <TableRow key={ index } fontSize="small">
                            <TableCell padding="small" className="user-name-content" title={ coordinator.name }>
                                { coordinator.name }
                            </TableCell>
                            <TableCell padding="small" className="user-email-content" title={ coordinator.email }>
                                { coordinator.email }
                            </TableCell>
                            <TableCell padding="small" alignment="right">{ coordinator.phoneNumber || '----' }</TableCell>
                        </TableRow>
                    )) }
                </Table>
            );
        } else {
            content = (
                <div className="logistics-coordinators-table empty-table">
                    <h4><FontAwesomeIcon icon={ usersIcon } /></h4>
                    <strong><b>No Logistics Coordinators Here</b></strong>
                    <CanAccess
                        action="notification-subscriptions:write"
                        yes={ <p className="empty-table-description">To proceed, please go to notification center.</p> }
                    />
                </div>
            );
        }

        const actions = (
            <CanAccess
                action="notification-subscriptions:read"
                yes={
                    <Button 
                        type="tertiary" 
                        size="small" 
                        onClick={ this._goToNotificationCenter }
                        leftIcon={ bellIcon }
                    >
                        Notification Center
                    </Button> 
                }
            />
        );

        return (
            <ContentCard
                isExpandable={ true }
                heading="Logistics Coordinators"
                actions={ actions }
                expandLabel="Show Details"
                collapseLabel="Hide Details"
                className="logistics-coordinators-card"
            >
                { content }
            </ContentCard>
        );
    }
}

export default withRouter(LogisticsCoordinators);
