import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding holidays
 */
export default class HolidayApiService {

    /**
     * Gets upcoming holiday
     * 
     * @param threshold how much in advance we want to look for holiday
     * @returns {Promise}
     */
    static getUpcoming(threshold) {
        return RestService.instance().get('holiday/upcoming', { threshold });
    }
}
