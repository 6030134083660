import RestService from 'service/RestService';

export default class RFPUtils {

    static latestRound(rfp) {
        return ((rfp || {}).rounds || []).reduce((a, b) => a.ordinal > b.ordinal ? a : b, {});
    }

    static isReview(rfp) {
        return 'IN_REVIEW' === rfp.status
    }

    static isConfirmationLatest(rfp) {
        return 'CONFIRMATION' === this.latestRound(rfp).type;
    }

    static isConfirmationReview(rfp) {
        if (!this.isReview(rfp)) {
            return false;
        }

        return this.isConfirmationLatest(rfp);
    }

    static fetchRFPOrigins(rfp, params) {
        return RestService.instance().get(`auction/rfp/${ rfp.id }/lane/region/origin`, params);
    }

    static fetchRFPDestinations(rfp, params) {
        return RestService.instance().get(`auction/rfp/${ rfp.id }/lane/region/destination`, params);
    }
}
