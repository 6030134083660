import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faArrowUp as submitIcon,
    faCheck as acceptIcon,
    faPen as updateIcon,
    faTimes as rejectIcon
} from '@fortawesome/pro-regular-svg-icons';

import { ACTIVITY_TYPES } from 'common/constants';

import './ActivityTypeIcon.scss';

const ICONS = {
    SUBMIT: { icon: submitIcon, class: 'submit'},
    ACCEPT: { icon: acceptIcon, class: 'accept'},
    REJECT: { icon: rejectIcon, class: 'reject'},
    UPDATE: { icon: updateIcon, class: 'update'}
}

export default class ActivityTypeIcon extends Component {
    static propTypes = {
        activityType: PropTypes.string.isRequired
    }

    render() {
        const activityInfo = ACTIVITY_TYPES[this.props.activityType];
        return (
            <div className={ `activity-type-icon ${ ICONS[activityInfo.iconType].class }` }>
                <FontAwesomeIcon icon={  ICONS[activityInfo.iconType].icon } className="icon" />
            </div>
        );
    }
}
