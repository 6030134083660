import { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import 'antd/dist/antd.css';
import './SurveyRadioButtonGroup.scss';

export default class SurveyRadioButtonGroup extends Component {

    static propTypes = {
        selectedValue: PropTypes.any,
        onChange: PropTypes.func,
        numberOfRates: PropTypes.number
    }

    static defaultProps = {
        selectedValue: null,
        onChange: () => { /* */ },
        numberOfRates: 5
    }

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(event) {
        this.props.onChange(event);
    }

    render() {
        const solidRadioButtons = Array.from({ length: this.props.numberOfRates },
            ((number, i) => {
                const index = i + 1;
                return <Radio.Button key={ "radio-button-" + index } value={ index }>{ index }</Radio.Button>;
            })
        );

        return (
            <div className="survey-radion-button-group-container">
                <Radio.Group className="survey-radio-button-group" buttonStyle="solid" onChange={ this._onChange } value={ this.props.selectedValue }>            
                    { solidRadioButtons }
                </Radio.Group>
            </div>
        );
    }
}
