import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';
import Tag from 'component/Tag';

import './CustomMultipleTextInput.scss';

const ENTER_KEY = 'Enter';
const BACKSPACE_KEY = 'Backspace';

export default class CustomMultipleTextInput extends Component {

    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        tooltipText: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string, 
            PropTypes.shape({
                value: PropTypes.string,
                valid: PropTypes.bool
            })
        ])),
        note: PropTypes.string,
        onValueAdded: PropTypes.func,
        onValueRemoved: PropTypes.func
    }

    static defaultProps = {
        id: undefined,
        label: null,
        tooltipText: null,
        className: '',
        placeholder: '',
        values: [],
        note: '',
        onValueAdded: () => { /* */ },
        onValueRemoved: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }

        this._onInputChanged = this._onInputChanged.bind(this);
        this._onKeyPressed = this._onKeyPressed.bind(this);
        this._onBlur = this._onBlur.bind(this);
    }

    _onInputChanged(event) {
        this.setState({ value: event.target.value });
    }

    _onKeyPressed(event) {
        const { values } = this.props;

        if (ENTER_KEY === event.key) {
            this._addNewValue();
        } else if (BACKSPACE_KEY === event.key && this.state.value === '' && values.length > 0) {
            this.props.onValueRemoved(values[values.length - 1]);
        }
    }

    _onBlur() {
        this._addNewValue();
    }

    _addNewValue() {
        let newValue = this.state.value.trim();
        const alreadyAdded = this.props.values.find(element => element === newValue || (element || {}).value === newValue);

        if (!alreadyAdded && newValue) {
            this.props.onValueAdded(newValue);
        }

        this.setState({ value: '' });
    }
 
    render() {
        let tooltip = <></>;
        if (this.props.label && this.props.tooltipText) {
            tooltip = (
                <div className="icon-div">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="right">
                        { this.props.tooltipText }
                    </Tooltip>
                </div>
            );
        }

        let label = <></>;
        if (this.props.label) {
            label = (
                <small className="input-label">
                    { this.props.label }
                    { tooltip }
                </small>
            );
        }

        const tags = this.props.values.map((element, index) => {
            const value = (element || {}).value ? element.value : element;
            return <Tag key={ `input-${ index }` } className={ (element || {}).valid === false ? 'error' : '' } text={ value } onClose={ () => this.props.onValueRemoved(value) } />;
        });

        const placeholder = this.props.values.length === 0 ? this.props.placeholder : '';

        return (
            <div id={ this.props.id } className={ `multiple-input-field-container ${ this.props.className }` }>
                { label }
                <div className="multiple-input-field">
                    { tags }
                    <input 
                        type="text"
                        placeholder={ placeholder }
                        onChange={ this._onInputChanged }
                        onKeyDown={ this._onKeyPressed }
                        value={ this.state.value }
                        onBlur={ this._onBlur }
                    />
                </div>
                { this.props.note && <small className="multiple-text-input-note">{ this.props.note }</small> }
            </div>
        );
    }
}
