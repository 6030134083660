import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding payment details
 */
export default class PaymentDetailsApiService {

    /**
     * Gets payment details for carrier
     *
     * @param {string} carrierId id of the carrier for which the payment details are
     * @param {array} loadIds list of load ids for which we are fetching payment details
     * @param {string} sort type of the sort which is used for sorting payment details
     * @param {number} pageNumber page number
     * @param {number} pageSize page size
     * @returns {Promise}
     */
    static get(carrierId, loadIds, sort, pageNumber, pageSize) {
        return RestService.instance()
            .get('payment-details', { carrierId, loadIds, sort: sort ? [sort, ''] : null, pageNumber, pageSize });
    }

    /**
     * Gets payment details for auction
     * 
     * @param {string} auctionId id of the auction
     * @returns {Promise}
     */
    static getOnAuction(auctionId) {
        return RestService.instance().get(`auction/tendering/${ auctionId }/payment-details`);
    }
}
