import RestService from 'service/RestService';

/**
  * Service class that provides static methods and endpoints for API calls regarding routing guide lane notifications
  */
export default class RoutingGuideLaneNotificationApiService {    
    /**
     * Fetches notification subscriptions on the routing guide lane with the provided id
     * 
     * @param {string} id if of the routing guide lane
     * @param {number} pageNumber number of the page to fetch
     * @param {number} pageSize size of the page to fetch
     * @returns {Promise}
     */
    static getSubscriptions(id, pageNumber = 1, pageSize = 100) {
        return RestService.instance().get(`routing-guide-lanes/${ id }/subscriptions`, { pageNumber, pageSize });
    }

    /**
     * Checks if the logged in user is subscribed to the routing guide lane with the provided id
     * 
     * @param {string} id id of the routing guide lane
     * @returns {Promise}
     */
    static isSubscribed(id) {
        return RestService.instance().get(`routing-guide-lanes/${ id }/subscriptions/subscribed`);
    }
}
