import { Component } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle as add } from '@fortawesome/pro-solid-svg-icons';

import Button from 'component/Button';
import Banner from 'component/Banner';
import CustomSelect from 'component/form/CustomSelect';
import ContentCard from 'component/card/ContentCard';
import CoordinatorOverviewCard from 'component/load/CoordinatorOverviewCard';

import './CoordinatorDetailsComponent.scss';

export default class CoordinatorDetailsComponent extends Component {
    static propTypes = {
        coordinators: PropTypes.array,
        existingCoordinators: PropTypes.array,
        editable: PropTypes.bool,
        heading: PropTypes.string.isRequired,
        showSubmittedInfo: PropTypes.bool,
        onAddExisting: PropTypes.func,
        onAddNew: PropTypes.func,
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        contentProvider: PropTypes.func
    };

    static defaultProps = {
        coordinators: [],
        existingCoordinators: [],
        editable: true,
        showSubmittedInfo: true,
        onAddExisting: () => { /* */ },
        onAddNew: () => { /* */ },
        onEdit: () => { /* */ },
        onRemove: () => { /* */ },
        contentProvider: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._showAddExistingForm = this._showAddExistingForm.bind(this);
        this._hideAddExistingForm = this._hideAddExistingForm.bind(this);
        this._onAddExisting = this._onAddExisting.bind(this);
        this._onSelectedCoordinatorChanged = this._onSelectedCoordinatorChanged.bind(this);
        this._selectedValuesMapper = this._selectedValuesMapper.bind(this);
        this._showAddNewForm = this._showAddNewForm.bind(this);
        this._hideAddNewForm = this._hideAddNewForm.bind(this);
        this._onAddNew = this._onAddNew.bind(this);
        this._showEditForm = this._showEditForm.bind(this);
        this._hideEditForm = this._hideEditForm.bind(this);
        this._onEdit = this._onEdit.bind(this);
    }

    state = {
        addingExisting: false,
        addingNew: false,
        editing: false,
        selectedCoordinator: null,
        addExistingErrorMessage: null
    }

    _onSelectedCoordinatorChanged(_value, option) {
        this.setState({ selectedCoordinator: JSON.parse(option.key), addExistingErrorMessage: null });
    }

    _selectedValuesMapper(coordinator) {
        return (
            <div className="select-option" title={ `${ coordinator.name } (${ coordinator.email })` }>
                { coordinator.name }<span className="select-option-email"> ({ coordinator.email })</span>
            </div>
        );
    }

    _showAddExistingForm() {
        this.setState({ addingExisting: true });
    }

    _hideAddExistingForm() {
        this.setState({ addingExisting: false, selectedCoordinator: null, addExistingErrorMessage: null });
    }

    _onAddExisting() {
        this.props.onAddExisting(this.state.selectedCoordinator)
            .then(this._hideAddExistingForm)
            .catch(error => this.setState({ addExistingErrorMessage: error?.response ? error.response.data : 'Something went wrong. Please try again' }));
    }

    _showAddNewForm() {
        this.setState({ addingNew: true });
    }

    _hideAddNewForm() {
        this.setState({ addingNew: false });
    }

    _onAddNew(coordinator) {
        return this.props.onAddNew(coordinator).then(this._hideAddNewForm);
    }

    _showEditForm() {
        this.setState({ addingExisting: false, addingNew: false, editing: true });
    }

    _hideEditForm() {
        this.setState({ editing: false });
    }

    _onEdit(id, coordinator) {
        this.props.onEdit(id, coordinator);
        this._hideEditForm();
    }

    _formEmptyCardContent() {
        let content;

        if (this.props.editable) {
            const { addExistingButtonText, addExistingButtonDescription, addNewButtonText, addNewButtonDescription } = this.props.contentProvider();
            content = (
                <div className="empty-card">
                    <div className="content-box">
                        <Button 
                            type="tertiary"
                            leftIcon={ add }
                            size="small"
                            disabled={ this.props.existingCoordinators.length === 0 }
                            onClick={ this._showAddExistingForm }
                        >
                            { addExistingButtonText }
                        </Button>
                        <small className="description">
                            { addExistingButtonDescription }
                        </small>
                    </div>
                    <div className="content-box">
                        <Button 
                            type="tertiary"
                            leftIcon={ add }
                            size="small"
                            onClick={ this._showAddNewForm }
                        >
                            { addNewButtonText }
                        </Button>
                        <small className="description">
                            { addNewButtonDescription }
                        </small>
                    </div>
                </div>
            );
        } else {
            const { emptyInfoText } = this.props.contentProvider();
            content = (
                <div className="empty-card" >
                    <Banner content={ emptyInfoText } />
                </div>
            );
        }

        return content;
    }

    _formActionableContent() {
        let content;

        if (this.props.editable && this.state.addingExisting) {
            content = this._formAddExistingCardContent();
        } else if (this.props.editable && this.state.addingNew) {
            content = this._formAddNewCardContent();
        } else if (this.props.editable && !this.state.editing) {
            content = this._formActionButtons();
        }

        return content;
    }

    _formAddExistingCardContent() {
        let errorMessage = <></>;
        if (this.state.addExistingErrorMessage) {
            errorMessage = (
                <div className="add-existing-error-message">
                    <Banner type="error" content={ this.state.addExistingErrorMessage } />
                </div>
            );
        }

        const { mainLabel } = this.props.contentProvider();
        const addDisabled = !this.state.selectedCoordinator || this.state.addExistingErrorMessage;

        return (
            <form className="add-existing">
                <strong className="title">
                    Add an Existing { mainLabel }
                </strong>
                <div className="content">
                    <div className="existing-select">
                        <small className="select-label">{ mainLabel }</small>
                        <CustomSelect 
                            size="regular" 
                            values={ this.props.existingCoordinators }
                            selectedValue={ this.state.selectedCoordinator ? JSON.stringify(this.state.selectedCoordinator) : null }
                            onSelect={ this._onSelectedCoordinatorChanged }
                            customValueMapper={ this._selectedValuesMapper }
                        />
                    </div>
                    <Button type="tertiary" onClick={ this._hideAddExistingForm }>
                        Cancel
                    </Button>
                    <Button onClick={ this._onAddExisting } disabled={ !!addDisabled }>
                        Add { mainLabel }
                    </Button>
                    { errorMessage }
                </div>
            </form>
        );
    }

    _formAddNewCardContent() {
        return (
            <CoordinatorOverviewCard 
                key="add-new-form"
                showActions={ false }
                onAdd={ this._onAddNew }
                onCancel={ this._hideAddNewForm }
                contentProvider={ this.props.contentProvider }
            />
        );
    }

    _formActionButtons() {
        const { addExistingButtonText, addNewButtonText } = this.props.contentProvider();
        return (
            <div className="add-coordinator-buttons">
                <Button size="small" type="tertiary" leftIcon={ add } onClick={ this._showAddNewForm }>
                    { addNewButtonText }
                </Button>
                <Button size="small" type="primary" leftIcon={ add } disabled={ this.props.existingCoordinators.length === 0 } onClick={ this._showAddExistingForm }>
                    { addExistingButtonText }
                </Button>
            </div>
        );
    }

    render() {
        let content;
        let infoDiv = <></>;

        if (this.props.coordinators.length === 0 && !this.state.addingExisting && !this.state.addingNew) {
            content = this._formEmptyCardContent();
            const { emptyInfoText } = this.props.contentProvider();
            infoDiv = this.props.editable ? <Banner content={ emptyInfoText } /> : <></>;
        } else {
            const coordinatorCards = (
                this.props.coordinators.map(coordinator => {
                    return (
                        <CoordinatorOverviewCard 
                            key={ coordinator.id }
                            coordinator={ coordinator }
                            showActions={ this.props.editable }
                            showSubmittedInfo={ this.props.showSubmittedInfo }
                            onStartEditing={ this._showEditForm }
                            onEdit={ this._onEdit }
                            onRemove={ this.props.onRemove }
                            onCancel={ this._hideEditForm }
                            contentProvider={ this.props.contentProvider }
                        />
                    );
                })
            );

            content = (
                <>
                    { coordinatorCards }
                    { this._formActionableContent() }
                </>
            );
        }

        return (
            <div className="coordinators">
                <ContentCard 
                    heading={ this.props.heading }
                    isExpandable={ true }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    className="coordinators-content-card"
                    info={ infoDiv }
                >
                    { content }
                </ContentCard>
            </div>
        );
    }
}
