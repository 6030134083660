import axios from 'axios';
import AuthenticationService from 'service/AuthenticationService';
import ConfigurationService from 'service/ConfigurationService';

var qs = require('qs');

/**
 * Provides a generic interface for REST interactions regardless of the implementation.
 * <p>
 * This component will also handle any authentication if necessary, such that users can focus on their API invocations.
 */
export default class RestService {

    /**
     * @type {RestService|null}
     * @private
     */
    static _instance = null;

    constructor({ baseUrl, downloadBaseUrl, client, authEnabled }) {
        this.baseUrl = baseUrl || '';
        this.downloadBaseUrl = downloadBaseUrl || '';
        this.axios = client || axios;
        this.authEnabled = !!authEnabled;
    }

    /**
     * Return the global rest service instance.
     *
     * @param refresh {boolean|undefined}
     * @returns {RestService}
     */
    static instance(refresh) {
        if (!this._instance || refresh) {
            const configurationService = ConfigurationService.instance();

            if (configurationService.isInitialized() && configurationService.authenticationConfig().enabled) {
                this._instance = new RestService({
                    baseUrl: configurationService.backendRESTv1(),
                    downloadBaseUrl: configurationService.backendDownload(),
                    authEnabled: true
                });
            } else if (configurationService.isInitialized()) {
                console.warn('Initialized RestService without authorization.');

                this._instance = new RestService({
                    baseUrl: configurationService.backendRESTv1(),
                    downloadBaseUrl: configurationService.backendDownload(),
                    authEnabled: false
                });
            } else {
                console.warn('Initialized RestService with default settings.');

                this._instance = new RestService({
                    baseUrl: '',
                    downloadBaseUrl: '',
                    authEnabled: false
                });
            }
        }

        return this._instance;
    }

    /**
     * Executes a GET request for the requested url with the included params.
     *
     * @param url
     * @param params
     * @returns {Promise<*>}
     */
    async get(url, params = null) {
        if (params) {
            return this._request(
                this.axios.get,
                url,
                {
                    ...await this._options(),
                    params,
                    paramsSerializer: ps => { return qs.stringify(ps, { arrayFormat: 'repeat' }) }
                }
            );
        } else {
            return this._request(this.axios.get, url, await this._options());
        }
    }

    /**
     * Executes a POST request to the requested url, with the provided data.
     *
     * @param url
     * @param data
     * @param headers
     * @param params
     * @returns {Promise<*>}
     */
    async post(url, data, headers, params = null) {
        if (params) {
            return this._request(
                this.axios.post,
                url,
                data,
                {
                    ...await this._options(headers),
                    params,
                    paramsSerializer: ps => { return qs.stringify(ps, { arrayFormat: 'repeat' }) }
                }
            );
        }

        return this._request(this.axios.post, url, data, await this._options(headers));
    }

    /**
     * Executes a PUT request to the requested url, with the provided data.
     *
     * @param url
     * @param data
     * @returns {Promise<*>}
     */
    async put(url, data) {
        return this._request(this.axios.put, url, data, await this._options());
    }

    /**
     * Executes a PATCH request to the requested url, with the provided data.
     *
     * @param url
     * @param data
     * @returns {Promise<*>}
     */
    async patch(url, data) {
        return this._request(this.axios.patch, url, data, await this._options());
    }

    /**
     * Executes a DELETE request for the requested url.
     *
     * @param url
     * @returns {Promise<*>}
     */
    async delete(url) {
        return this._request(this.axios.delete, url, await this._options());
    }

    /**
     * Executes a GET request used to download a file.
     *
     * @param {string} reference
     * @returns {Promise<*>}
     */
    async download(reference, isStaticResource = true, params = null) {
        const url = isStaticResource ? this.downloadBaseUrl : this.baseUrl;

        const cancelToken = this.axios.CancelToken.source();

        return {
            responsePromise: this.axios.get(
                url + RestService._cleanUrl(reference),
                {
                    ...await this._options(),
                    params,
                    paramsSerializer: ps => { return qs.stringify(ps, { arrayFormat: 'repeat' }) },
                    responseType: 'blob',
                    cancelToken: cancelToken.token
                }
            ),
            cancel: () => cancelToken.cancel()
        };
    }

    /**
     * Internal method to invoke the provided HTTP method using the provided arguments.
     *
     * @param method
     * @param url
     * @param data (or options, if GET or DELETE)
     * @param options (only if data is present)
     * @returns {Promise<*>}
     * @private
     */
    _request(method, url, data, options) {
        return RestService._extract(method(this.baseUrl + RestService._cleanUrl(url), data, options));
    }

    /**
     * @param headers
     * @returns {Promise<{}|*>}
     * @private
     */
    async _options(headers) {
        const options = {};

        if (this.authEnabled) {
            const authenticationService = AuthenticationService.instance();

            if (authenticationService) {
                options.headers = await authenticationService.getRequestHeaders();
            }
        }

        if (headers) {
            options.headers = { ...options.headers, ...headers };
        }

        return options;
    }

    /**
     * Extracts the data from the axios response, and throws exceptions if request failed.
     *
     * @param promise the promise to extract.
     * @returns {Promise<*>} promise of actual response.
     * @private
     */
    static _extract(promise) {
        return promise
            .then(response => response.data)
            .catch(error => {
                console.error(error.message, error.toJSON());
                throw error;
            }
        );
    }

    /**
     * Ensures that the url has only a single leading forward-slash.
     * 
     * @param url the url to clean
     * @returns {string} the cleaned url.
     * @private
     */
    static _cleanUrl(url) {
        let result = url;

        if (result) {
            while (result.indexOf('/') === 0) {
                result = result.slice(1);
            }
        }

        return '/' + result;
    }
}
