import { cloneElement, Component, createRef } from 'react';
import PropTypes from 'prop-types';

import './Dropdown.scss';

export default class Dropdown extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        trigger: PropTypes.element.isRequired,
        centerArrowOnTrigger: PropTypes.bool,
        direction: PropTypes.oneOf(['bottom', 'bottom-right', 'bottom-left', 'top', 'top-right', 'top-left', 'right']).isRequired,
        show: PropTypes.bool,
        onClose: PropTypes.func,
        isBackgroundClickable: PropTypes.bool,
        // In case you want to use this as an actionable dropdown, component will render actions instead of children
        actions: PropTypes.arrayOf(PropTypes.element)
    }

    static defaultProps = {
        centerArrowOnTrigger: true,
        show: false,
        onClose: () => { /* */ },
        isBackgroundClickable: false,
        actions: null
    }

    /**
     *
     * @type {React.RefObject | {current: null}}
     * @private
     */
    _triggerRef = createRef();

    /**
     *
     * @type {React.RefObject | {current: null}}
     * @private
     */
    _dropdownRef = createRef();

    constructor(props) {
        super(props);
        this._computeDirectionalPosition = this._computeDirectionalPosition.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this._computeDirectionalPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._computeDirectionalPosition);
    }

    _computeDirectionalPosition() {
        const triggerElement = this._triggerRef.current;
        const dropdownContainerElement = this._dropdownRef.current;

        if (!triggerElement || !dropdownContainerElement) {
            return { };
        }

        const triggerDomRect = triggerElement.getBoundingClientRect();
        const dropdownDomRect = dropdownContainerElement.children.item(1).getBoundingClientRect();

        const parent = triggerElement.offsetParent;
        const parentDomRect = parent.getBoundingClientRect();

        const globalXOffset = window.pageXOffset - window.scrollX;
        const globalYOffset = window.pageYOffset - window.scrollY;

        // Following variables are in pixels
        //
        // Represents the distance between trigger element and the dropdown
        const topOffset = 12;
        // Used to offset the dropdown to the side so that the arrow is at the center of the trigger element (if needed)
        const sideOffset = this.props.centerArrowOnTrigger ? 16 : 10;
        // Used to position the whole dropdown at the center of the trigger (if needed)
        const centeringOffset = this.props.centerArrowOnTrigger ? (triggerDomRect.width / 2) : 0;

        const containerPosition = {
            top: - parentDomRect.y,
            left: - parentDomRect.x
        };

        let dropdownStyle = { };
        let arrowClass = '';

        switch (this.props.direction) {
            case 'bottom': {
                const originX = (triggerDomRect.x + globalXOffset) + (triggerDomRect.width / 2);
                const originY = (triggerDomRect.y + globalYOffset);

                dropdownStyle =  {
                    position: 'absolute',
                    top: originY + triggerDomRect.height + topOffset,
                    left: originX - (dropdownDomRect.width / 2)
                };

                arrowClass = 'arrow-top';
                break;
            }
            case 'bottom-right': {
                const originX = triggerDomRect.x + globalXOffset;
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY + triggerDomRect.height + topOffset,
                    left: originX + centeringOffset - sideOffset
                };

                arrowClass = 'arrow-top-left';
                break;
            }
            case 'bottom-left': {
                const originX = triggerDomRect.x + globalXOffset;
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY + triggerDomRect.height + topOffset,
                    left: originX - (dropdownDomRect.width - triggerDomRect.width) - centeringOffset + sideOffset
                };

                arrowClass = 'arrow-top-right';
                break;
            }
            case 'top': {
                const originX = triggerDomRect.x + globalXOffset + (triggerDomRect.width / 2);
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY - dropdownDomRect.height - topOffset,
                    left: originX - (dropdownDomRect.width / 2)
                };

                arrowClass = 'arrow-bottom';
                break;
            }
            case 'top-right': {
                const originX = triggerDomRect.x + globalXOffset;
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY - dropdownDomRect.height - topOffset,
                    left: originX + centeringOffset - sideOffset
                };

                arrowClass = 'arrow-bottom-left';
                break;
            }
            case 'top-left': {
                const originX = triggerDomRect.x + globalXOffset;
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY - dropdownDomRect.height - topOffset,
                    left: originX - (dropdownDomRect.width - triggerDomRect.width) - centeringOffset + sideOffset
                };

                arrowClass = 'arrow-bottom-right';
                break;
            }
            case 'right': {
                const originX = triggerDomRect.x + globalXOffset + triggerDomRect.width;
                const originY = triggerDomRect.y + globalYOffset;

                dropdownStyle = {
                    position: 'absolute',
                    top: originY - sideOffset,
                    left: originX + sideOffset / 2
                };

                arrowClass = 'arrow-right';
                break;
            }
            default:
                throw new Error(`Unsupported direction "${ this.props.direction }"`);
        }

        return { dropdownStyle, containerPosition, arrowClass };

    }

    render() {
        const visibilityClass = this.props.show ? " visible" : " hidden";
        const { dropdownStyle, containerPosition, arrowClass } = this._computeDirectionalPosition();
        const className = `dropdown-container dynamic ${ arrowClass } ${ visibilityClass } ${ this.props.isBackgroundClickable ? 'clickable' : '' }`;

        // eslint-disable-next-line
        const overlay = <a className="overlay" href="#!" onClick={ (e) => { e.stopPropagation(); this.props.onClose(); }}/>

        let content;
        if (this.props.actions) {
            content = this.props.actions.map((action, index) => <div key={ index } className="action">{ action }</div>);
        } else {
            content = this.props.children;
        }

        return (
            <>
                { cloneElement(this.props.trigger, { ref: this._triggerRef }) }
                <div ref={ this._dropdownRef } id={ this.props.id } className={ className } style={ containerPosition }>
                    { overlay }
                    <div className="dropdown" style={ dropdownStyle } onClick={ e => e.stopPropagation() } >
                        { content }
                    </div>
                </div>
            </>
        );
    }
}
