import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight as arrow } from '@fortawesome/pro-regular-svg-icons';

import { LANE_TYPE_PROPERTIES } from 'common/constants';
import Button from 'component/Button';
import Popup from 'component/Popup';
import Separator from 'component/Separator';
import CustomCheckbox from 'component/form/CustomCheckbox';
import LoadTenderingTermsOfService from 'component/load/LoadTenderingTermsOfService';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import LaneUtils from 'utils/LaneUtils';
import PriceUtils from 'utils/PriceUtils';

export default class LoadTermsOfServicePopup extends Component {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        price: PropTypes.number.isRequired,
        showPopup: PropTypes.bool,
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.element,
        trigger: PropTypes.element,
        onClosePopup: PropTypes.func,
        onActionPerformed: PropTypes.func
    }

    static defaultProps = {
        showPopup: false,
        id: 'popup-accept-tos',
        title: 'Accept Offer',
        description: (
            <>
                By selecting "Accept" you are agreeing to service this shipment and will be expected to be in compliance with the agreement between your company and ShipEX Logistics. 
            </>
        ),
        trigger: <></>,
        onClosePopup: () => { /* */ },
        onActionPerformed: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onCheckboxChange = this._onCheckboxChange.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
    }

    state = {
        acceptTermsAndConditions: false
    }

    _onCheckboxChange() {
        this.setState(prevState => ({ acceptTermsAndConditions: !prevState.acceptTermsAndConditions }));
    }

    _onClosePopup(event) {
        this.setState({ acceptTermsAndConditions: false });
        this.props.onClosePopup(event);
    }

    render() {
        const { auction, price, id, showPopup, trigger, title, description, onActionPerformed } = this.props;

        const lane = auction.load.routingGuideLane.lane;
        const origin = LaneUtils.formatRegion(lane.origin);
        const destination = LaneUtils.formatRegion(lane.destination);

        return (
            <Popup
                id={ id }
                size="large"
                show={ showPopup }
                onClose={ this._onClosePopup }
                trigger={ trigger }
            >
                <div className="offer-popup accept-popup book-now-popup">
                    <div className="popup-header">
                        <div className="popup-header-section">
                            <div className="popup-header-section-title">#{ auction.load.businessId }</div>
                            <div className="popup-header-section-description">
                                <FontAwesomeIcon icon={ LANE_TYPE_PROPERTIES[lane.type].icon } className="popup-header-icon" />
                                <div>{ LANE_TYPE_PROPERTIES[lane.type].label }</div>
                            </div>
                        </div>

                        <Separator orientation="vertical" />

                        <div className="popup-header-section region">
                            <div className="popup-header-section-title" title={ origin }>{ origin }</div>
                            <div className="popup-header-section-description">
                                { DateUtils.formatLocal(new Date(auction.load.pickupTime), auction.load.pickupTimeOffset) }
                            </div>
                        </div>

                        <div className="popup-header-section">
                            <FontAwesomeIcon icon={ arrow } className="popup-header-icon arrow" />
                        </div>

                        <div className="popup-header-section region">
                            <div className="popup-header-section-title" title={ destination }>{ destination }</div>
                            <div className="popup-header-section-description">
                                { DateUtils.formatLocal(new Date(auction.load.deliveryTime), auction.load.deliveryTimeOffset) }
                            </div>
                        </div>

                        { price && 
                            <div className="popup-header-section price">
                                <div className="popup-header-section-title">
                                    <span>Total price:</span> ${ NumberUtils.formatWithDecimalNotation(price) }
                                </div>
                                <div className="popup-header-section-description">
                                    Per mile: ${ PriceUtils.formatPerMilePrice(price, Number(auction.load.routingGuideLane.distance)) }
                                </div>
                            </div>
                        }
                    </div>
                
                    <Separator />
                    
                    <h5 className="heading">{ title }</h5>
                    <p className="description">{ description }</p>

                    <p className="description">
                        To proceed, you must first accept the Terms and Conditions of this rate confirmation by electronically checking the box marked "I accept these Terms and Conditions".
                    </p>

                    <LoadTenderingTermsOfService />
                    <Separator />

                    <div className="accept-popup-actions">
                        <CustomCheckbox
                            onChange={ this._onCheckboxChange }
                            checked={ this.state.acceptTermsAndConditions }
                            text="I accept these Terms and Conditions" 
                        />

                        <div className="buttons-wrapper">
                            <Button type="tertiary" onClick={ this._onClosePopup }>
                                Cancel
                            </Button>

                            <Button
                                disabled={ !this.state.acceptTermsAndConditions }
                                onClick={ onActionPerformed }
                            >
                                Accept
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}
