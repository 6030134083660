import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSort as sort,
    faSortUp as sortedAsc,
    faSortDown as sortedDesc,
    faSortAmountUpAlt as sortAsc,
    faSortAmountDown as sortDesc,
    faUndo as unsort
} from '@fortawesome/pro-solid-svg-icons';

import { SORT_DIRECTIONS } from 'common/constants';
import Dropdown from 'component/Dropdown';

import './TableHeaderCell.scss';

export default class TableHeaderCell extends Component {
    static propTypes = {
        sortable: PropTypes.bool,
        onSort: PropTypes.func,
        sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
        alignment: PropTypes.oneOf(['left', 'right', 'center']),
        className: PropTypes.string
    }

    static defaultProps = {
        sortable: false,
        onSort: () => { /* */ },
        sortDirection: SORT_DIRECTIONS.NONE,
        alignment: 'left',
        className: ''
    }

    state = {
        showSortingDropdown: false,
        direction: SORT_DIRECTIONS.NONE
    }

    constructor(props) {
        super(props);

        this._toggleSortingDropdown = this._toggleSortingDropdown.bind(this);
        this._onSortColumn = this._onSortColumn.bind(this);
    }

    componentDidMount() {
        this.setState({ direction: this.props.sortDirection });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sortDirection !== this.props.sortDirection) {
            this.setState({ direction: this.props.sortDirection });
        }
    }

    _toggleSortingDropdown() {
        this.setState(prevState => {
            return { showSortingDropdown: !prevState.showSortingDropdown }
        });
    }

    _onSortColumn(direction) {
        this.setState({
            showSortingDropdown: false,
            direction
        });

        this.props.onSort(direction);
    }

    render() {
        const { className, sortable, onSort, alignment, sortDirection, ...filteredProps } = this.props;

        let actions = [
            (
                <div onClick={ () => this._onSortColumn(SORT_DIRECTIONS.ASCENDING) } className={ `dropdown-item ${ SORT_DIRECTIONS.ASCENDING === this.state.direction ? 'active' : '' }` }>
                    <FontAwesomeIcon className="action-icon" icon={ sortAsc } />
                    <small className="action-name">
                        Sort Ascending
                    </small>
                </div>
            ),
            (
                <div onClick={ () => this._onSortColumn(SORT_DIRECTIONS.DESCENDING) } className={ `dropdown-item ${ SORT_DIRECTIONS.DESCENDING === this.state.direction ? 'active' : '' }` }>
                    <FontAwesomeIcon className="action-icon" icon={ sortDesc } />
                    <small className="action-name">
                        Sort Descending
                    </small>
                </div>
            )
        ];

        if (SORT_DIRECTIONS.NONE !== this.state.direction) {
            actions.push(
                <div onClick={ () => this._onSortColumn(SORT_DIRECTIONS.NONE) } className="dropdown-item">
                    <FontAwesomeIcon className="action-icon" icon={ unsort } />
                    <small className="action-name">
                        Unsort
                    </small>
                </div>
            );
        }

        let sortDropdown;
        let activeState = 'inactive';
        if (sortable) {
            let sortIcon;

            switch (this.state.direction) {
                case SORT_DIRECTIONS.ASCENDING:
                    sortIcon = sortedAsc;
                    activeState = 'active';
                    break;
                case SORT_DIRECTIONS.DESCENDING:
                    sortIcon = sortedDesc;
                    activeState = 'active';
                    break;
                default:
                    sortIcon = sort;
                    break;
            }

            sortDropdown = (
                <Dropdown
                    id="sorting-dropdown"
                    direction="bottom-right"
                    show={ this.state.showSortingDropdown }
                    onClose={ this._toggleSortingDropdown }
                    trigger={(
                        <div className="sort-dropdown-trigger" onClick={ this._toggleSortingDropdown }>
                            <FontAwesomeIcon className="sort-icon" icon={ sortIcon } />
                        </div>
                    )}
                    actions={ actions }
                />
            );
        }

        return (
            <th className={ `header-cell ${ className } ${ alignment }` } { ...filteredProps }>
                <div className={ `header-cell-content ${ activeState }` }>
                    { this.props.children }
                    { sortDropdown }
                </div>
            </th>
        );
    }
}
