import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';

import './CustomTextInput.scss';

export default class CustomTextInput extends Component {

    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        tooltipText: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        prefixIcon: PropTypes.element,
        suffixIcon: PropTypes.element,
        value: PropTypes.string,
        fieldName: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        locked: PropTypes.bool,
        onValueChange: PropTypes.func,
        inputClassName: PropTypes.string
    }

    static defaultProps = {
        id: undefined,
        label: '',
        tooltipText: '',
        className: '',
        placeholder: '',
        value: '',
        required: false,
        fieldName: null,
        disabled: false,
        locked: false,
        onValueChange: () => { /* */ },
        inputClassName: '',
        prefixIcon: <></>,
        suffixIcon: <></>
    }

    constructor(props) {
        super(props);
        this._onValueChange = this._onValueChange.bind(this);
    }

    state = {
        isValid: true
    }

    _onValueChange(event) {
        const value = event.target.value || '';
        const isValid = !this.props.required || value.trim() !== '';

        this.setState({ isValid });
        this.props.onValueChange(event);
    }
 
    render() {
        const inputStyle = `${ this.props.inputClassName } ${ !this.state.isValid ? 'invalid' : 'completed' }`
        const textInputStyle = this.props.disabled ? 'text-input disabled' : 'text-input';
        
        let labelField = <></>;
        if (this.props.label) {
            const requiredIndicator = this.props.required ? <span className="required-indicator">*</span> : <></>;
            
            let tooltip = <></>;
            if (this.props.tooltipText) {
                tooltip = (
                    <div className="icon-div">
                        <FontAwesomeIcon icon={ info } className="icon" />
                        <Tooltip direction="top">
                            { this.props.tooltipText }
                        </Tooltip>
                    </div>
                );
            }

            labelField = (
                <small className="input-label">
                    { this.props.label }
                    { requiredIndicator }
                    { tooltip }
                </small>
            );
        }

        return (
            <div id={ this.props.id } className={ `input-field ${ this.props.className } ${ this.props.disabled ? 'disabled' : '' }` }>
                { labelField }
                <div className={ textInputStyle }>
                    { this.props.prefixIcon }
                    <input 
                        type="text" 
                        placeholder= { this.props.placeholder }
                        name={ this.props.fieldName } 
                        onChange={ this._onValueChange } 
                        value={ this.props.value } 
                        className={ inputStyle }
                        disabled={ this.props.disabled || this.props.locked }
                    />
                    { this.props.suffixIcon }
                </div>
            </div>
        );
    }
}
