import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff as restart } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment'

import { AUCTION_PHASE, FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomCheckbox from 'component/form/CustomCheckbox';
import CustomRadioButton from 'component/form/CustomRadioButton';
import CustomDateTimePicker from 'component/form/CustomDateTimePicker';
import NumberUtils from 'utils/NumberUtils';
import FormStatusModal from 'component/form/FormStatusModal';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';

import './RestartAuctionPopup.scss';

export default class RestartAuctionPopup extends Component {
    static propTypes = {
        auctionId: PropTypes.string.isRequired,
        onRestart: PropTypes.func,
        onClosePopup: PropTypes.func,
        restartIcon: PropTypes.object,
        pickupDate: PropTypes.instanceOf(Date),
        laneBookNowPrice: PropTypes.number,
        auctionBookNowPrice: PropTypes.number
    };

    static defaultProps = {
        onRestart: () => { /* */ },
        onClosePopup: () => { /* */ },
        restartIcon: restart,
        pickupDate: Date.now(),
        laneBookNowPrice: null,
        auctionBookNowPrice: null
    };

    constructor(props) {
        super(props);
        this._onIncludeRejectedOffersCheckboxChange = this._onIncludeRejectedOffersCheckboxChange.bind(this);
        this._onInheritPropertiesCheckboxChange = this._onInheritPropertiesCheckboxChange.bind(this);
        this._onDeadlineChange = this._onDeadlineChange.bind(this);
        this._onRestartOptionChange = this._onRestartOptionChange.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._onRestartAuction = this._onRestartAuction.bind(this);
    }

    state = {
        restartType: AUCTION_PHASE.DEDICATED,
        shouldIncludeRejectedOffers: false,
        shouldInheritProperties: false,
        newDeadline: this.props.pickupDate,
        showPopup: false,
        popupStatus: undefined
    };

    /**
     * Defines the actions which should be performed each time the popup is opened.
     */
    _onOpenPopup() {
        this.setState({ showPopup: true, newDeadline: this.props.pickupDate });
    }

    /**
     * Defines the actions which should be performed each time the popup is closed.
     */
    _onClosePopup() {
        if (FORM_STATUSES.LOADING !== this.state.popupStatus) {
            this.setState({ 
                restartType: AUCTION_PHASE.DEDICATED,
                shouldIncludeRejectedOffers: false,
                shouldInheritProperties: false,
                newDeadline: this.props.pickupDate,
                showPopup: false,
                popupStatus: undefined
            });

            this.props.onClosePopup();
        }
    }

    /**
     * Defines the actions that should be performed when the user clicks the restart button.
     */
    _onRestartAuction() {
        const { restartType, shouldIncludeRejectedOffers, shouldInheritProperties, newDeadline } = this.state;
        const requestParams = {
            phase: restartType,
            shouldIncludeRejectedOffers,
            shouldInheritProperties,
            newDeadline
        };
    
        this.setState({ popupStatus: FORM_STATUSES.LOADING });
        TenderAuctionBrokerApiService.restartAuction(this.props.auctionId, requestParams)
            .then(() => this.setState({ popupStatus: FORM_STATUSES.SUCCESS }))
            .then(this.props.onRestart)
            .catch(() => this.setState({ popupStatus: FORM_STATUSES.ERROR }));
    }

    /**
     * Forms restart auction popup trigger button
     *
     * @returns restart auction popup trigger button
     * @private
     */
    _formRestartTenderButton() {
        return (
            <div href="#popup-restart" onClick={ this._onOpenPopup } className="dropdown-item">
                <FontAwesomeIcon className="action-icon" icon={ this.props.restartIcon }/>
                <small className="action-name">Restart Auction</small>
            </div>
        );
    }

    /**
     * Handles restart option radio button change
     *
     * @param {Object} value selected type
     * @private
     */
    _onRestartOptionChange(value) {
        this.setState({ restartType: value });
    }

    /**
     * Handles restarted auction rejected offers inclusion checkbox change
     *
     * @private
     */
    _onIncludeRejectedOffersCheckboxChange() {
        this.setState(prevState => ({
            shouldIncludeRejectedOffers: !prevState.shouldIncludeRejectedOffers
        }));
    }

    /**
     * Handles restarted auction property inheritance checkbox change
     *
     * @private
     */
    _onInheritPropertiesCheckboxChange() {
        this.setState(prevState => ({
            shouldInheritProperties: !prevState.shouldInheritProperties
        }));
    }

    _onDeadlineChange(value) {
        const newDeadline = value ? value.toDate() : this.props.pickupDate;
        this.setState({ newDeadline });
    }

    /**
     * Forms restarted auction rejected offers inclusion checkbox if the
     * option to start the new auction from the beginning is selected.
     *
     * @returns restarted auction should include rejected offers checkbox
     * @private
     */
    _formIncludeRejectedOffersCheckbox() {
        if (AUCTION_PHASE.DEDICATED === this.state.restartType) {
            return (
                <CustomCheckbox
                    onChange={ this._onIncludeRejectedOffersCheckboxChange }
                    checked={ this.state.shouldIncludeRejectedOffers }
                    text="The new auction should include the carriers that initially rejected the offer."
                />
            );
        }
        
        return <></>;
    }

    _formDescription() {
        const { laneBookNowPrice, auctionBookNowPrice } = this.props;
        const bookNowPrice = this.state.shouldInheritProperties ? auctionBookNowPrice : laneBookNowPrice;

        let description;
        if (!bookNowPrice) {
            description = 'The book now price is not set. If the auction is restarted from the Spot Market, this price cannot be set.';
        } else {
            description = `The book now price is defaulted to ($${ NumberUtils.formatWithDecimalNotation(bookNowPrice) }). If the auction is restarted from the Spot Market, this price cannot be changed.`;
        }

        return (
            <p className="description">
                { description }
            </p>
        );
    }

    _formLoadingPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                <div>
                    <h6>Restarting Auction</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </FormStatusModal>
        );
    }

    _formSuccessPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                <div>
                    <h6>Auction Restarted</h6>
                    <p>You have successfully restarted this auction.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formErrorPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClosePopup } />
        );
    }

    _formRestartAuctionPopup() {
        const radioButtonOptions = [
            {
                value: AUCTION_PHASE.DEDICATED,
                label: "Start auction from the beginning"
            },
            {
                value: AUCTION_PHASE.SPOT_MARKET,
                label: "Start auction from Spot Market"
            }
        ];

        return (
            <div className="broker-actions-popup restart-auction-popup">
                <h6 className="heading">Restart Auction</h6>
                <p className="description">
                    To restart the auction process you need to choose the stage the new auction will start from.
                </p>
                { this._formDescription() }
                <div className="restart-options">
                    <CustomRadioButton onChange={ this._onRestartOptionChange } options={ radioButtonOptions } selectedValue={ this.state.restartType } />
                </div>
                <CustomCheckbox
                    onChange={ this._onInheritPropertiesCheckboxChange }
                    checked={ this.state.shouldInheritProperties }
                    text="The new auction should exclude fallen off carriers and inherit the previous auction's book now price."
                />
                { this._formIncludeRejectedOffersCheckbox() }
                <div className="deadline-picker">
                    <p className="label">New Auction Deadline <span className="deadline-picker-description">(Defaulted to ship date)</span></p>
                    <CustomDateTimePicker
                        className="date-picker"
                        date={ moment(this.state.newDeadline) }
                        minDate={ moment() }
                        maxDate={ moment(this.props.pickupDate).add(1, 'month') }
                        allowClear={ true }
                        handleChange={ this._onDeadlineChange }
                    />
                </div>
                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this._onClosePopup } >
                        Discard
                    </Button>

                    <Button type="danger" onClick={ this._onRestartAuction }>
                        Restart Auction
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const { popupStatus } = this.state;
        let content;

        switch (popupStatus) {
            case FORM_STATUSES.LOADING:
                content = this._formLoadingPopup();
                break;
            case FORM_STATUSES.SUCCESS:
                content = this._formSuccessPopup();
                break;
            case FORM_STATUSES.ERROR:
                content = this._formErrorPopup();
                break;
            default:  
                content = this._formRestartAuctionPopup();      
        }

        return (
            <Popup
                id="popup-restart"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._onClosePopup }
                trigger={ this._formRestartTenderButton() }
            >
                { content }
            </Popup>
        );
    }
}
