import { Component } from 'react';
import PropTypes from 'prop-types';

import ConfigurationService from 'service/ConfigurationService';

export default class Integrate extends Component {
    static propTypes = {
        account: PropTypes.object.isRequired
    };

    state = {
        loading: true
    };

    componentDidMount() {
        this._integratePendo(window, document, 'script', 'pendo');
        this.setState({ loading: false });
    }

    _integratePendo(p, e, n, d, o) {
        const configurationService = ConfigurationService.instance();
        const apiKey = configurationService.pendoApiKey();

        // Don't try to integrate Pendo if the api key is not provided or user is not logged in
        if (!apiKey || !this.props.account) {
            return;
        }

        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) (function(m) {
            o[m] = o[m] || function() {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
        this._initializePendo();
    }

    _initializePendo() {
        const { internalId, email, name } = this.props.account;

        // This function creates visitors and accounts in Pendo
        window.pendo.initialize({
            // End users that are using the appication
            visitor: {
                id: internalId, // Must be globally unique
                email: email,
                full_name: name
            },
            // Groups of visitors
            account: {
                id: '9ee79026-406b-4f92-8c4a-b81d8566848c',
                name: 'Managed Transportation | ShipEX Logistics'
            }
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div>Configuring integrations...</div>
            );
        }

        return (
            <>{ this.props.children }</>
        );
    }
}
