import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction for carrier
 */
export default class TenderAuctionCarrierApiService {

    /**
     * Gets relevant bid for auction and for user
     * 
     * @param {string} auctionId id of auction for which bids are 
     * @returns {Promise}
     */
    static getRelevantBids(auctionId) {
        return RestService.instance().get(`carrier/auction/tendering/${ auctionId }/relevant-bids`);
    }

    /**
     * Gets paginated spot market bids for auction
     * 
     * @param {string} auctionId id of auction for which bids are 
     * @param {number} pageNumber number of page for the bids
     * @param {number} pageSize number of bids taken
     * @returns {Promise}
     */
    static getSpotMarketBids(auctionId, pageNumber, pageSize) {
        return RestService.instance().get(`carrier/auction/tendering/${ auctionId }/spot-market-bids`, { pageNumber, pageSize });
    }

    /**
     * Gets latest tender auction for load
     * 
     * @param {string} loadId id of a load for which latest auction is looked
     * @returns {Promise}
     */
    static getLatestTenderAuction(loadId) {
        return RestService.instance().get(`carrier/auction/tendering/load/${ loadId }/latest`);
    }

    /**
     * Gets tender auction with forwarded id
     * 
     * @param {string} auctionId id of auction from load
     * @param {string} loadId id of load
     * @returns {Promise}
     */
    static getTenderAuction(auctionId, loadBusinessId) {
        return RestService.instance().get(`carrier/auction/tendering/${ auctionId }`, { loadBusinessId });
    }

    /**
     * Gets falloff reasons for carrier
     * 
     * @returns {Promise}
     */
    static getFalloffReasons() {
        return RestService.instance().get('carrier/auction/tendering/falloff-reasons');
    }

    /**
     * Gets dispatch details for carrier for auction
     * 
     * @param {string} auctionId id of auction 
     * @returns {Promise}
     */
    static getDispatchDetails(auctionId) {
        return RestService.instance().get(`carrier/auction/tendering/${ auctionId }/dispatch-details`);
    }

    /**
     * Gets available carrier dispatchers
     * 
     * @returns {Promise}
     */
    static getAvailableCarrierDispatchers() {
        return RestService.instance().get('carrier/carrier-dispatchers');
    }

    /**
     * Adds existing carrier dispatcher to the auction
     * 
     * @param {string} auctionId id of auction 
     * @param {string} dispatcherId id of existing dispatcher
     * @returns {Promise}
     */
    static addExistingCarrierDispatcher(auctionId, dispatcherId) {
        return RestService.instance().put(`carrier/auction/tendering/${ auctionId }/carrier-dispatchers/${ dispatcherId }`);
    }

    /**
     * Adds new carrier dispatcher to the auction
     * 
     * @param {string} auctionId id of auction 
     * @param {Object} dispatcher dispatcher information
     * @returns {Promise}
     */
    static addNewCarrierDispatcher(auctionId, dispatcher) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/carrier-dispatchers`, dispatcher);
    }

    /**
     * Edits information about carrier dispatcher
     * 
     * @param {string} id id of dispatcher
     * @param {Object} dispatcher dispatcher information
     * @returns {Promise}
     */
    static editCarrierDispatcher(id, dispatcher) {
        return RestService.instance().put(`carrier-dispatchers/${ id }`, dispatcher);
    }

    /**
     * Removes carrier dispatcher from auction
     * 
     * @param {string} auctionId id of auction 
     * @param {string} id id of dispatcher to be removed
     * @returns {Promise}
     */
    static removeCarrierDispatcher(auctionId, id) {
        return RestService.instance().delete(`carrier/auction/tendering/${ auctionId }/carrier-dispatchers/${ id }`);
    }

    /**
     * Sends carrier details
     *
     * @param {string} auctionId id of auction 
     * @param {Object} details carrier details
     * @returns {Promise}
     */
    static sendDetails(auctionId, details) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/details`, details);
    }

    /**
     * Cancels given offer on auction
     * 
     * @param {string} auctionId id of auction 
     * @param {string} reasonId if of cancel reason
     * @returns {Promise}
     */
    static cancelOffer(auctionId, reasonId) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/falloff?reasonId=${ reasonId }`);
    }

    /**
     * Posts a bid on auction
     * 
     * @param {string} auctionId id of auction 
     * @param {number} price bid to be posted
     * @returns {Promise}
     */
    static postBid(auctionId, price) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/bid`, { price });
    }

    /**
     * Books load for book now price 
     * 
     * @param {string} auctionId id of auction 
     * @returns {Promise}
     */
    static bookNow(auctionId) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/book-now`);
    }

    /**
     * Gets negotiated rate of a carrier for auction
     * 
     * @param {string} auctionId id of auction 
     * @returns {Promise}
     */
     static getNegotiatedRate(auctionId) {
        return RestService.instance().get(`carrier/auction/tendering/${ auctionId }/negotiated-rate`);
    }

    /**
     * Accept ToS for candidate
     * 
     * @param auctionId id of auction
     * @returns {Promise} 
     */
    static acceptToS(auctionId) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/offer/accept/tos`);
    }

    /**
     * Accepts tender auction offer
     * 
     * @param {string} auctionId id of auction for with carrier is accepting offer
     * @returns {Promise} 
     */
    static acceptOffer(auctionId) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/offer/accept`);
    }

    /**
     * Reject tender auction offer
     * 
     * @param {string} auctionId id of auction for with carrier is rejecting offer
     * @returns {Promise} 
     */ 
    static rejectOffer(auctionId) {
        return RestService.instance().post(`carrier/auction/tendering/${ auctionId }/offer/reject`);
    }

    /**
     * Exclude from lane
     * 
     * @param {string} laneId id of lane for with carrier is being excluded from
     * @param {date} endDate date when exclusion finishes
     * @returns {Promise} 
     */ 
    static excludeFromLane(laneId, endDate) {
        return RestService.instance().post(`lanes/${ laneId }/exclude`, { endTime: new Date(endDate) });
    }
}
