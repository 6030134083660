import PropTypes from 'prop-types';

import AuctionTableRow from 'component/load/AuctionTableRow';
import WSComponent from 'component/WSComponent';
import TenderAuctionCarrierApiService from 'service/api/TenderAuctionCarrierApiService';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';
import WebSocketService from 'service/WebSocketService';
import AuctionUtils from 'utils/AuctionUtils';

export default class AuctionCard extends WSComponent {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        ws: PropTypes.instanceOf(WebSocketService),
        showBids: PropTypes.bool,
        searchView: PropTypes.bool,
        availableLoadsView: PropTypes.bool,
        onAuctionChanged: PropTypes.func,
        onActionPerformed: PropTypes.func
    };

    static defaultProps = {
        ws: WebSocketService.instance(),
        showBids: true,
        availableLoadsView: false,
        searchView: false,
        onAuctionChanged: () => { /* */ },
        onActionPerformed: () => { /* */ }
    };

    constructor(props) {
        super(props);

        if (props.auction) {
            this.subscriptions = [
                {
                    topic: `/topic/auctions/${ props.auction.id }/spot-market/bid`,
                    handler: () => this._handleBidChange()
                }
            ]
            if (props.account?.carrierId) {
                this.subscriptions = this.subscriptions.concat([
                    {
                        topic: `/topic/carriers/${ props.account.carrierId }/auctions/${ props.auction.id }/offers`,
                        handler: (notification) => this._handleAuctionChange(notification, props.account.carrierId)
                    }
                ]);
            } else {
                this.subscriptions = this.subscriptions.concat([
                    {
                        topic: `/topic/auctions/${ props.auction.id }/offers`,
                        handler: (notification) => this._handleAuctionChange(notification)
                    }
                ]);
            }
        }

        this._handleAuctionChange = this._handleAuctionChange.bind(this);
    }

    async _handleAuctionChange(notification, carrierId = null) {
        const auctionId = AuctionUtils.parseFromNotification(notification);
        let auction;

        if (carrierId) {
            auction = await TenderAuctionCarrierApiService.getTenderAuction(auctionId);
        } else {
            auction = await TenderAuctionBrokerApiService.getAuction(auctionId);

        }
        const enrichedAuction = await TenderAuctionBrokerApiService.enrichTenderAuctionWithBids(auction);
        this.props.onAuctionChanged(enrichedAuction);
    }

    async _handleBidChange() {
        const enrichedAuction = await TenderAuctionBrokerApiService.enrichTenderAuctionWithBids(this.props.auction);
        this.props.onAuctionChanged(enrichedAuction);
    }

    render() {
        const { auction, account, showBids, availableLoadsView, searchView, onActionPerformed } = this.props;

        return (
            <AuctionTableRow
                auction={ auction }
                account={ account }
                showBids={ showBids }
                searchView={ searchView }
                availableLoadsView={ availableLoadsView }
                onActionPerformed={ onActionPerformed }
            />
        );
    }
}
