import { Component } from 'react';
import PropTypes from 'prop-types';
import { faTrash as deleteIcon } from '@fortawesome/pro-regular-svg-icons';

import Popup from 'component/Popup';
import Button from 'component/Button';

import './MemberActions.scss';

export default class RemoveMember extends Component {

    static propTypes = {
        member: PropTypes.object.isRequired,
        onRemoveMember: PropTypes.func
    };

    static defaultProps = {
        onRemoveMember: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onRemove = this._onRemove.bind(this);
    }

    state = {
        showPopup: false
    };

    _openPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.setState({ showPopup: false });
    }

    _onRemove() {
        this.props.onRemoveMember();
        this._closePopup();
    }

    render() {
        const { member } = this.props;

        return (
            <div className="remove-member">
                <Popup
                    id="popup-remove-member"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={
                        <Button
                            type="tertiary-alt"
                            size="small"
                            disabled={ member.email === member.carrier.email || !member.carrier.active }
                            className="action"
                            rightIcon={ deleteIcon }
                            onClick={ this._openPopup }
                        />
                    }
                >
                    <div className="remove-member-popup">
                        <p className="heading">Remove Member</p>
                        
                        <p className="description">
                            Are you sure you want to remove User <b> { member.name } </b>?
                        </p>

                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._closePopup } >
                                Cancel
                            </Button>

                            <Button size="small" type="danger" onClick={ this._onRemove }>
                                Remove 
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}
