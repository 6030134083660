export default class AsyncUtils {

    static timerPromise(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    static waitForPredicate(predicate, maxWait) {
        return new Promise(async resolve => {
            const timer = AsyncUtils.timerPromise;
            let totalWait = 0;

            while (!predicate() && (!maxWait || totalWait < maxWait)) {
                await timer(50);
                totalWait += 50;
            }

            resolve();
        });
    }
}
