import { Component } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';
import { faEdit as editIcon } from '@fortawesome/pro-regular-svg-icons';

import { FORM_STATUSES, MAX_PRICE, TENDER_CANDIDATE_STATUS } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomTextInput from 'component/form/CustomTextInput';
import CustomNumberInput from 'component/form/CustomNumberInput';
import FormStatusModal from 'component/form/FormStatusModal';
import NumberUtils from 'utils/NumberUtils';
import StringUtils from 'utils/StringUtils';
import PriceUtils from 'utils/PriceUtils';

import './DedicatedCarrierForm.scss';

export default class DedicatedCarrierForm extends Component {

    static propTypes = {
        mode: PropTypes.oneOf(['add', 'edit']).isRequired,
        routingGuideLane: PropTypes.object.isRequired,
        dedicatedCarrier: PropTypes.object,
        maxPosition: PropTypes.number,
        onSubmit: PropTypes.func,
        onActionPerformed: PropTypes.func
    };

    static defaultProps = {
        mode: 'add',
        dedicatedCarrier: null,
        maxPosition: 1,
        onSubmit: () => { /* */ },
        onActionPerformed: () => { /* */ }
    }

    constructor(props) {
        super(props);

        if (this._isEditing() && !this.props.dedicatedCarrier.carrier.businessId) {
            console.error('Encountered carrier without business id.');
        }

        const { businessId, price, weeklyLimit, position } = this._getDefaultFormInputValues();

        this.state = {
            showPopup: false,
            status: null,
            errorMessage: null,
            businessId,
            price,
            weeklyLimit,
            position,
            maxPosition: this._maxPosition() // Maximum position that can be entered on the form
        };

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onResponseReceived = this._onResponseReceived.bind(this);
        this._onBusinessIdChanged = this._onBusinessIdChanged.bind(this);
        this._onPriceChanged = this._onPriceChanged.bind(this);
        this._onWeeklyLimitChanged = this._onWeeklyLimitChanged.bind(this);
        this._onPositionChanged = this._onPositionChanged.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.maxPosition !== this.props.maxPosition) {
            this.setState({ maxPosition: this._maxPosition() });
        }
    }

    _openPopup(event) {
        event.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        if (FORM_STATUSES.LOADING !== this.state.status) {
            this._resetState();
        }
    }
    
    _isPopupDisabled() {
        return this._isEditing() && (TENDER_CANDIDATE_STATUS.ACCEPTED.value !== this.props.dedicatedCarrier.status || !this.props.dedicatedCarrier.carrier.active);
    }

    _resetState() {
        this.setState({ showPopup: false, status: null, errorMessage: null, ...this._getDefaultFormInputValues() });
    }

    _getDefaultFormInputValues() {
        const editing = this._isEditing();

        return {
            businessId: (editing && this.props.dedicatedCarrier.carrier.businessId) ? this.props.dedicatedCarrier.carrier.businessId : '',
            price: editing ? this.props.dedicatedCarrier.price.value : '',
            weeklyLimit: editing ? this.props.dedicatedCarrier.weeklyLimit : '',
            position: editing ? this.props.dedicatedCarrier.position : ''
        };
    }

    _isEditing() {
        return 'edit' === this.props.mode && !!this.props.dedicatedCarrier;
    }

    _isEdited() {
        return this._isEditing() && (this.state.price !== this.props.dedicatedCarrier.price.value
                || this.state.weeklyLimit !== this.props.dedicatedCarrier.weeklyLimit
                || this.state.position !== this.props.dedicatedCarrier.position);
    }

    /**
     * Returns the maximum valid position that can be set on the form.
     * In case of editing that is the maximum active position that is fetched from the backend.
     * In case of adding that is the maximum active position increased by 1.
     */
    _maxPosition() {
        return this._isEditing() ? this.props.maxPosition : this.props.maxPosition + 1;
    }

    _onBusinessIdChanged(event) {
        this.setState({ businessId: event.target.value });
    }

    _onPriceChanged(price) {
        this.setState({ price });
    }

    _onWeeklyLimitChanged(weeklyLimit) {
        this.setState({ weeklyLimit });
    }

    _onPositionChanged(position) {
        this.setState({ position });
    }

    _isBusinessIdValid(businessId) {
        return !StringUtils.isBlank(businessId);
    }

    _isPriceValid(price) {
        return !!price && price <= MAX_PRICE;
    }

    _isWeeklyLimitValid(weeklyLimit) {
        return weeklyLimit !== null && weeklyLimit >= 0 && weeklyLimit <= MAX_PRICE;
    }

    _isPositionValid(position) {
        return position > 0 && position <= this.state.maxPosition;
    }

    _isFormValid() {
        return this._isBusinessIdValid(this.state.businessId)
            && this._isPriceValid(this.state.price)
            && this._isWeeklyLimitValid(this.state.weeklyLimit)
            && this._isPositionValid(this.state.position);
    }

    _isSubmitDisabled() {
        return !this._isFormValid() || (this._isEditing() && !this._isEdited());
    }

    _onSubmit() {
        const body = {
            businessId: this.state.businessId.trim(),
            price: NumberUtils.truncate(this.state.price, 2),
            position: this.state.position,
            weeklyLimit: this.state.weeklyLimit
        };

        this.setState({ status: FORM_STATUSES.LOADING }, () => {
            this.props.onSubmit(body)
                .then(() => this.setState({ status: FORM_STATUSES.SUCCESS }))
                .catch(error => this.setState({ 
                    status: FORM_STATUSES.ERROR,
                    errorMessage: error.response.data.status === 500 ? `An error occurred while trying to ${ this.props.mode } carrier. Please try again later.` : error.response.data
                }));
        });
    }

    _onResponseReceived() {
        this._closePopup();
        this.props.onActionPerformed();
    }

    _getAddPopupContent() {
        const trigger = (
            <Button
                type="primary"
                size="small"
                className="add-dedicated-carriers-button"
                leftIcon={ addIcon }
                onClick={ this._openPopup }
            >
                Add Carrier
            </Button>
        );

        const heading = <h6 className="heading">Add Dedicated Carrier</h6>;
        
        const description = (
            <>
                <p className="description">
                    To add a new Dedicated Carrier, please enter the required information. After you click <i>Invite Carrier</i>, an offer will be sent to the Carrier. 
                </p>
                <p className="description">
                    Carrier will not appear in future auctions until they accept the offer. They cannot be removed or edited while they have a pending offer.
                </p>
            </>
        );
        
        const primaryButtonText = 'Invite Carrier';
        const loadingHeading = 'Adding Carrier';
        const successHeading = 'Offer Sent to Carrier';
        const successDescription = <small>An offer has been sent to <b>{ this.state.businessId }</b>. They will not appear in future auctions until they accept the offer.</small>;

        return { trigger, heading, description, primaryButtonText, loadingHeading, successHeading, successDescription };
    }

    _getEditPopupContent() {
        const trigger = (
            <Button
                type="tertiary-alt"
                className="action"
                rightIcon={ editIcon }
                disabled={ this._isPopupDisabled() }
                onClick={ this._openPopup }
            />
        );
        
        const heading = <h6 className="heading">Edit Dedicated Carrier</h6>;

        const description = (
            <>
                <p className="description">
                    To edit the details for this Carrier, please enter the updated information. If you make changes to the price, a new offer will be sent to the Carrier. 
                </p>
                <p className="description">
                    In case of a price change, this Carrier will not appear in future auctions until they accept the new offer. They cannot be removed or edited while they have a pending offer.
                </p>
            </>
        );
        
        const primaryButtonText = 'Save Changes';
        const loadingHeading = 'Updating Carrier';
        const successHeading = 'Carrier Updated';
        const successDescription = <small>Carrier <b>{ this.state.businessId }</b> has been updated. In case of a price update, an offer will be sent to them and they will not appear in future auctions until they accept the offer.</small>;

        return { trigger, heading, description, primaryButtonText, loadingHeading, successHeading, successDescription };
    }

    _getPopupContent() {
        const popupSpecificContent = 'add' === this.props.mode ? this._getAddPopupContent() : this._getEditPopupContent();

        const description = (
            <>
                { popupSpecificContent.description }
                <p className="description">
                    This Carrier will not appear in future auctions until they accept the new offer. They cannot be removed or edited while they have a pending offer.
                </p>
            </>
        );
        
        return { description, ...popupSpecificContent };
    }
    
    render() {
        const {
            trigger,
            heading,
            description,
            primaryButtonText,
            loadingHeading,
            successHeading,
            successDescription 
        } = this._getPopupContent();

        let content;
        if (FORM_STATUSES.LOADING === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status } onContinue={ this._closePopup }>
                    <h6>{ loadingHeading }</h6>
                    <small>This will only take a moment. Please wait...</small>
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.SUCCESS === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status } onContinue={ this._onResponseReceived }>
                    <h6>{ successHeading }</h6>
                    { successDescription }
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.ERROR === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status } onContinue={ this._onResponseReceived }>
                    <h6>Something Went Wrong</h6>
                    <small>{ this.state.errorMessage }</small>
                </FormStatusModal>
            );
        } else {
            const perMilePrice = this.state.price ? PriceUtils.calculatePerMilePrice(this.state.price, this.props.routingGuideLane.distance) : null;
            const perMilePriceToShow = perMilePrice ? NumberUtils.prefixNumber(perMilePrice, '$', false, 'decimal') : '-';
            
            const positionInputLabel = (
                <small className="input-label">
                    <b>Position</b><span className="input-label-description"> (max. { this.state.maxPosition })</span>
                </small>
            );

            content = (
                <div className="edit-dedicated-carrier-popup">
                    { heading }
                    { description }

                    <CustomTextInput
                        label="TMW Carrier Code"
                        value={ this.state.businessId }
                        placeholder="Enter the TMW Carrier Code..."
                        disabled={ this._isEditing() }
                        onValueChange={ this._onBusinessIdChanged }
                    />
                    <br />

                    <CustomNumberInput
                        label="Total Price"
                        placeholder="Enter Price..."
                        prefix="$"
                        initialValue={ this.state.price ? this.state.price.toString() : null }
                        value={ this.state.price ? this.state.price.toString() : null }
                        onChange={ this._onPriceChanged }
                        min={ 0 }
                        max={ MAX_PRICE }
                        decimals={ 2 }
                        isValid={ this._isPriceValid(this.state.price) }
                        focusOnMount={ false }
                    />
                    <small className="per-mile-price">Per Mile Price: { perMilePriceToShow }</small>
                    <br />

                    <div className="input-fields-row">
                        <CustomNumberInput
                            label="Weekly Capacity"
                            placeholder="Enter Capacity..."
                            initialValue={ this.state.weeklyLimit || this.state.weeklyLimit === 0 ? this.state.weeklyLimit.toString() : null }
                            value={ this.state.weeklyLimit || this.state.weeklyLimit === 0 ? this.state.weeklyLimit.toString() : null }
                            onChange={ this._onWeeklyLimitChanged }
                            min={ 0 }
                            max={ MAX_PRICE }
                            decimals={ 2 }
                            isValid={ this._isWeeklyLimitValid(this.state.weeklyLimit) }
                            focusOnMount={ false }
                        />

                        <CustomNumberInput
                            label={ positionInputLabel }
                            placeholder="Enter Position..."
                            initialValue={ this.state.position ? this.state.position.toString() : null }
                            value={ this.state.position ? this.state.position.toString() : null }
                            onChange={ this._onPositionChanged }
                            min={ 1 }
                            max={ this.state.maxPosition }
                            decimals={ 0 }
                            isValid={ this._isPositionValid(this.state.position) }
                            focusOnMount={ false }
                        />
                    </div>

                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="regular" onClick={ this._closePopup } >
                            Cancel
                        </Button>
                        <Button type="primary" size="regular" disabled={ this._isSubmitDisabled() } onClick={ this._onSubmit }>
                            { primaryButtonText }
                        </Button>
                    </div>
                </div>
            );
        }

        return(
            <div className="edit-dedicated-carrier">
                <Popup
                    key={ this.state.showPopup ? "opened-dedicated-carrier-form" : "closed-dedicated-carrier-form" }
                    id="popup-edit-dedicated-carrier"
                    size="medium"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={ trigger }>
                        { content }
                </Popup>
            </div>
        );
    }
}
