import { Component } from 'react';
import PropTypes from 'prop-types';
import { faTrash as deleteIcon } from '@fortawesome/pro-regular-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import Tooltip from 'component/Tooltip';
import FormStatusModal from 'component/form/FormStatusModal';

import './RemoveCarrier.scss';

export default class RemoveLaneCarrier extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        laneCarrier: PropTypes.object.isRequired,
        onRemoveCarrier: PropTypes.func,
        setShouldUpdate: PropTypes.func
    };

    static defaultProps = {
        onRemoveCarrier: () => { /* */ },
        setShouldUpdate: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onRemove = this._onRemove.bind(this);
    }

    state = {
        showPopup: false,
        status: undefined
    }

    _openPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.props.setShouldUpdate(true);
        this.setState({ showPopup: false, status: undefined });
    }

    _onRemove() {
        this.setState({ status: FORM_STATUSES.LOADING });
        this.props.setShouldUpdate(false);

        this.props.onRemoveCarrier()
            .then(() => this.setState({ status: FORM_STATUSES.SUCCESS }))
            .catch(error => {
                this._closePopup();
                console.error('Failed to remove carrier. ', error)
            });
    }

    _formPopupContent() {
        const { laneCarrier } = this.props;
        const { status } = this.state;

        if (FORM_STATUSES.LOADING === status) {
            return (
                <FormStatusModal status={ status }>
                    <div>
                        <h6>Excluding Carrier</h6>
                        <p>This will only take a moment. Please wait...</p>
                    </div>
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.SUCCESS === status) {
            return (
                <FormStatusModal status={ status } onContinue={ this._closePopup }>
                    <div>
                        <h6>Carrier Removed</h6>
                        <p>Carrier <b>{ laneCarrier.carrier.name }</b> has been successfully removed from this lane.</p>
                    </div>
                </FormStatusModal>
            );
        } else {
            return (
                <div className="remove-lane-carrier-popup">
                    <h6 className="heading">{ laneCarrier.excluded ? 'Remove Exclusion' : 'Remove Carrier' }</h6>
                        
                    <p className="description">
                        Are you sure you want to remove spot market carrier<b> { laneCarrier.carrier.name } </b>from the spot market carriers list? 
                        Changes made on lane will not affect any ongoing auctions and will be applied upon starting a new auction on this lane.
                        <br /><br />
                        This carrier will still be able to receive invitations for certain spot market auctions on this lane.
                    </p>

                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._closePopup } >
                            Cancel
                        </Button>

                        <Button size="small" type="danger" onClick={ this._onRemove }>
                            Remove Carrier
                        </Button>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="remove-lane-carrier">
                <Popup
                    id="popup-remove-lane-carrier"
                    size="medium"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={
                        <div className="action-container">
                            <Button
                                type="tertiary-alt"
                                size="small"
                                className="action"
                                rightIcon={ deleteIcon }
                                onClick={ this._openPopup }
                            />
                            <Tooltip direction="top">
                                Remove { this.props.laneCarrier.excluded ? 'Exclusion' : 'Carrier' }
                            </Tooltip>
                        </div>
                    }
                >
                    { this._formPopupContent() }
                </Popup>
            </div>
        )
    }
}
