import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding lane carriers
 */
export default class LaneCarrierApiService {
    static TEMPLATE_ENDPOINT = 'lane-carriers/template';
    static BULK_TEMPLATE_ENDPOINT = 'lane-carriers/bulk/template';

    /**
     * Gets active/excluded lane carriers
     *
     * @param {string} laneId id of lane for which active spot market carriers are fetched
     * @param {boolean} excluded carrier status, false we want active carriers, true we want excluded carriers
     * @param {number} pageNumber page for which active spot market carriers are fetched
     * @param {number} pageSize number of active spot market carriers fetched
     * @returns {Promise}
     */
    static filter(laneId, excluded, pageNumber, pageSize) {
        return RestService.instance().get(`lanes/${ laneId }/lane-carriers`, { excluded, pageNumber, pageSize });
    }

    /**
     * Gets number of active/excluded lane carriers on lane
     *
     * @param {string} laneId id of lane 
     * @param {boolean} isExcluded do we want number of active or excluded carriers
     * @returns {Promise}
     */
    static count(laneId, isExcluded) {
        return RestService.instance().get(`lanes/${ laneId }/lane-carriers/count`, { isExcluded });
    }

    /**
     * Sets negotiated rate for lane carrier
     *
     * @param {string} laneId id of lane for which negotiated rate is being set
     * @param {string} carrierId id of carrier whose negotiated rate is being set
     * @param {number} price negotiated rate
     * @returns {Promise}
     */
    static setNegotiatedRate(laneId, carrierId, price) {
        return RestService.instance().put(`lanes/${ laneId }/lane-carriers/${ carrierId }/negotiated-rate`, { price });
    }

    /**
     * Removes lane carrier from lane 
     *
     * @param {string} laneId id of lane from which carrier is being removed
     * @param {string} carrierId id of carrier to be removed
     * @returns {Promise}
     */
    static remove(laneId, carrierId) {
        return RestService.instance().delete(`lanes/${ laneId }/lane-carriers/${ carrierId }`);
    }

    /**
     * Adds new lane carriers to lane from csv 
     *
     * @param {string} laneId id of lane on which carriers are added
     * @param {Object} formData file with data
     * @param {string} delimiter sign with which data is separated inside of a file
     * @returns {Promise}
     */
    static addToLaneByCSV(laneId, formData, delimiter) {
        return RestService.instance().post(`lanes/${ laneId }/lane-carriers/csv`, formData, null, { delimiter });
    }

    /**
     * Adds new lane carriers to all lanes
     *
     * @param {Object} formData file with data
     * @param {string} delimiter sign with which data is separated inside of a file
     * @returns {Promise}
     */
    static addByCSV(formData, delimiter) {
        return RestService.instance().post(`lanes/lane-carriers/csv`, formData, null, { delimiter });
    }

    /**
     * Adds lane carrier to lane
     *
     * @param {string} laneId id of lane for which carrier will be added
     * @param {Object} data carrier data
     * @returns {Promise}
     */
    static add(laneId, data) {
        return RestService.instance().post(`lanes/${ laneId }/lane-carriers`, data);
    }

    /**
     * Adds lane carrier to exclusion
     *
     * @param {string} laneId id of lane for which carrier will be excluded
     * @param {string} laneCarrierId if of lane carrier to be excluded
     * @returns {Promise}
     */
    static exclude(laneId, laneCarrierId) {
        return RestService.instance().put(`lanes/${ laneId }/lane-carriers/${ laneCarrierId }/exclude`);
    }

    /**
     * Adds carriers with forwarded businessIds to exclusion
     *
     * @param {string} laneId id of lane for which carriers will be excluded
     * @param {array} carrierBusinessIds list of carrier businessIds
     * @returns {Promise}
     */
    static excludeByBusinessId(laneId, carrierBusinessIds) {
        return RestService.instance().post(`lanes/${ laneId }/lane-carriers/exclude`, carrierBusinessIds);
    }

    /**
     * Adds carriers from excluded list to active list on the lane
     *
     * @param {string} laneId id of lane for which carriers should be activated
     * @param {string} carrierBusinessId carrier to be activated
     * @returns {Promise}
     */
    static activate(laneId, carrierBusinessId) {
        return RestService.instance().put(`lanes/${ laneId }/lane-carriers/activate`, { carrierBusinessId });
    }

}
