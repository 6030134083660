import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardList as list } from '@fortawesome/pro-regular-svg-icons';

import Dropdown from 'component/Dropdown';

import './LoadRequirementsPopover.scss';

export default class LoadRequirementsPopover extends Component {
    constructor(props) {
        super(props);

        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
    }

    state = {
        show: false
    };

    _onOpen() {
        this.setState({ show: true });
    }

    _onClose() {
        this.setState({ show: false });
    }

    render() {
        return (
            <div className="load-requirements-popover">
                <Dropdown
                    id="load-requirements-popover"
                    direction="bottom-left"
                    show={ this.state.show }
                    centerArrowOnTrigger={ false }
                    onClose={ this._onClose }
                    trigger={(
                        <a href="#!" className={ `load-requirements-icon-container${ this.state.show ? ' active' : '' }` } onClick={ this._onOpen }>
                            <FontAwesomeIcon icon={ list } className="list-icon" />
                            <small className="prompt"><b>Load Requirements</b></small>
                            <FontAwesomeIcon icon={ info } className="info-icon" />
                        </a>
                    )}
                >
                    <div className="load-requirements-popover-content">
                        <div className="load-requirements-popover-content-row">
                            <p><b>Requirements for all Loads</b></p>
                        </div>
                        <div className="load-requirements-popover-content-row">
                            <ul>
                                <li><p><span className="semibold">Fourkites</span> tracking</p></li>
                                <li><p>Clean and odor free 53' trailer</p></li>
                                <li><p>2 Load locks or straps at the shipper</p></li>
                            </ul>
                        </div>
                    </div>
                </Dropdown>
            </div>
        );
    }
}
