import { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from "antd";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt as calendar } from '@fortawesome/pro-light-svg-icons';

import 'antd/dist/antd.css';
import './CustomDatePicker.scss';

export default class CustomDatePicker extends Component {

    static propTypes = {
        date: PropTypes.instanceOf(moment),
        minDate: PropTypes.instanceOf(moment),
        dateFormat: PropTypes.string,
        handleChange: PropTypes.func,
        allowClear: PropTypes.bool
    }

    static defaultProps = {
        date: null,
        minDate: null,
        dateFormat: 'YYYY-MM-DD',
        handleChange: () => { /* */ },
        allowClear: false
    }

    constructor(props) {
        super(props);

        this._isDateDisabled = this._isDateDisabled.bind(this);
    }

    _isDateDisabled(current) {
        if (this.props.minDate) {
            return current && this.props.minDate.isAfter(current, 'day');
        }
    }

    render() {
        return (
            <div className="custom-date-picker">
                <FontAwesomeIcon icon={ calendar } className="date-picker-icon"/>
                <DatePicker
                    getPopupContainer={ triggerNode => triggerNode.parentNode }
                    bordered={ false }
                    format={ this.props.dateFormat }
                    suffixIcon={ null }
                    value={ this.props.date }
                    disabledDate={ this._isDateDisabled }
                    onChange={ this.props.handleChange }
                    onSelect={ this.props.handleChange }
                    allowClear={ this.props.allowClear }
                />
            </div>
        );
    }
}
