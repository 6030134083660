import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoveseat as dryLoad, faSnowflake as reeferLoad, faPalletAlt as flatbedLoad } from '@fortawesome/pro-solid-svg-icons';

import TitledCardComponent from 'component/card/TitledCardComponent';
import * as Constants from 'common/constants';

import './CargoTypeComponent.scss';

export default class CargoTypeComponent extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired
    };

    _cargoTypeNode() {
        let laneType = null;

        switch (this.props.type) {
            case Constants.LANE_TYPES.DRY:
                laneType = (
                    <>
                        <FontAwesomeIcon icon={ dryLoad } className="lane-cargo-type-icon" />
                        <b>Dry</b>
                    </>
                );
                break;
            case Constants.LANE_TYPES.REFRIGERATED:
                laneType = (
                    <>
                        <FontAwesomeIcon icon={ reeferLoad } className="lane-cargo-type-icon" />
                        <b>Refrigerated</b>
                    </>
                );
                break;
            case Constants.LANE_TYPES.FLATBED:
                laneType = (
                    <>
                        <FontAwesomeIcon icon={ flatbedLoad } className="lane-cargo-type-icon" />
                        <b>Flatbed</b>
                    </>
                );
                break;
            default:
                // Nothing to do here.
                break;
        }

        return laneType;
    }

    render() {
        return (
            <TitledCardComponent type="small" title="CARGO TYPE" className="cargo-type-component">
                <strong className="lane-cargo-type">{ this._cargoTypeNode() }</strong>
            </TitledCardComponent>
        );
    }
}
