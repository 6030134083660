import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

export default class TableColumn extends Component {

    static propTypes = {
        id: PropTypes.string,
        header: PropTypes.bool,
        className: PropTypes.string,
        align: PropTypes.oneOf([ 'left', 'center', 'right' ]),
        title: PropTypes.string,
    };

    static defaultProps = {
        id: undefined,
        header: false,
        className: '',
        align: 'left',
        title: null
    };

    render() {
        return createElement(
            this.props.header ? 'th' : 'td',
            { 
                id: this.props.id,
                title: this.props.title,
                className: `simple-table-column ${ this.props.className } ${ this.props.align } ${ this.props.header ? 'simple-table-header-col' : '' }`
            },
            this.props.children
        );
    }
}
