import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as warningIcon } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';
import DocumentUtils from 'utils/DocumentUtils';

export default class CarrierStatusIcon extends Component {

    static propTypes = {
        carrier: PropTypes.object,
        className: PropTypes.string
    }

    static defaultProps = {
        carrier: {},
        className: ''
    }

    state = {
        invalidDocuments: false
    };

    componentDidMount() {
        const invalidDocuments = DocumentUtils.hasInvalidDocument(this.props.carrier.id);
        this.setState({ invalidDocuments });
    }

    render() {
        if (!this.props.carrier.active || this.state.invalidDocuments) {
            let warningDescription = '';
            if (!this.props.carrier.active) {
                warningDescription = 'Terminated carrier';
            } else if (this.state.invalidDocuments) {
                warningDescription = 'There are missing and/or expired documents.';
            }

            return (
                <div className={ `warning-icon ${ this.props.className}` }>
                    <FontAwesomeIcon icon={ warningIcon } className='icon' />
                    <Tooltip direction="bottom">{ warningDescription }</Tooltip>
                </div>
            );
        } 
        
        return <></>;
    }        
}
