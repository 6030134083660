import RestService from 'service/RestService';

/**
 * Service class that provides static methods for API calls regarding Spot Market carriers sync
 */
export default class SMCarriersSyncApiService {

    /**
     * Fetches active rules for dynamic sync of Spot Market carriers
     * 
     * @returns {Promise}
     */
    static get() {
        return RestService.instance().get('spot-market-sync-configuration');
    }

    /**
     * Updates configuration for dynamic sync of Spot Market Carriers
     * 
     * @param {object} configuration updated configuration for dynamic sync
     * @returns {Promise}
     */
    static update(configuration) {
        return RestService.instance().post('spot-market-sync-configuration', configuration);  
    }
}
