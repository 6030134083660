import { Component } from 'react';
import PropTypes from 'prop-types';

import Banner from 'component/Banner';
import Separator from 'component/Separator';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './BidsList.scss';

export default class BidsList extends Component {
    static propTypes = {
        bids: PropTypes.array,
        distance: PropTypes.number,
        carrierId: PropTypes.string
    };

    static defaultProps = {
        bids: [],
        distance: null,
        carrierId: null,
    };

    static _formStyleClass(isLowestBid, isCurrentCarrierBid) {
        let classes = [];
        if (isLowestBid) {
            classes.push("lowest-bid");
        }

        if (isLowestBid && isCurrentCarrierBid) {
            classes.push("winning-bid");
        }

        if (!isLowestBid && isCurrentCarrierBid) {
            classes.push("losing-bid");
        }

        return classes.join(" ");
    }

    static _formListRow(bid, distance, carrierId) {
        const isLowestBid = bid.current;
        const isCurrentCarrierBid =  bid.candidate.carrier?.id === carrierId;
        const styleClass = BidsList._formStyleClass(isLowestBid, isCurrentCarrierBid);

        return (
            <div className={ `row ${ styleClass }` }>
                <small className="list-item column-bidder">
                    { isCurrentCarrierBid ? "You" : "Other carrier" }
                    { isLowestBid ? <span className="lowest-bidder-label">{ " (Lowest Bidder)" }</span> : "" }
                </small>
                <small className="list-item column-bid align-right">${ NumberUtils.formatWithDecimalNotation(bid.price) }</small>
                <small className="list-item column-mile align-right">${ PriceUtils.formatPerMilePrice(bid.price, distance) }</small>
                <small className="list-item column-date align-right">{ DateUtils.format(new Date(bid.time)) }</small>
            </div>
        );
    }

    static _formListHeader() {
        return (
            <div className="list-header">
                <small className="list-item column-bidder">Bidder</small>
                <small className="list-item column-bid align-right">Bid</small>
                <small className="list-item column-mile align-right">Per Mile</small>
                <small className="list-item column-date align-right">Date of Bid</small>
            </div>
        );
    }

    _formListContent() {
        const { bids, distance, carrierId } = this.props;

        if (bids.length === 0) {
            return <Banner size="medium" content="There are currently no bids for this load." />;
        } else {
            let banner;
            if (bids.length === 1 && bids[0].candidate.carrier?.id === carrierId) {
                banner = (
                    <>
                        <div className="top-padding">
                            <Separator />
                        </div>
                        <div className="banner-div">
                            <Banner size="medium" content="You are the first carrier to bid on this load." />
                        </div>
                    </>
                );
            }

            return (
                <div className="component bid-list" id="scrollable-container">
                    { BidsList._formListHeader() }
                    <div className="list-content">
                        { bids.map((bid, index) => {
                                const separator = index === bids.length - 1 ? <></> : <Separator />;
                                return (
                                    <div key={ bid.id }>
                                        { BidsList._formListRow(bid, distance, carrierId) }
                                        { separator }
                                    </div>
                                );
                            })
                        }
                    </div>
                    { banner }
                </div>
            );
        }
    }

    render() {
        return this._formListContent();
    }
}
