import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle as info,
    faArrowDown as losing,
    faArrowUp as winning,
    faGavel as gavel
} from '@fortawesome/pro-solid-svg-icons';

import * as Constants from 'common/constants';
import Tooltip from 'component/Tooltip';
import Separator from 'component/Separator';
import BiddingForm from 'component/load/BiddingForm';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './CurrentBid.scss';

export default class CurrentBid extends Component {
    static propTypes = {
        auction: PropTypes.object,
        lowestBid: PropTypes.object,
        carrierBestBid: PropTypes.object,
        negotiatedRate: PropTypes.number,
        enableBookNow: PropTypes.bool,
        popupActions: PropTypes.bool,
        version: PropTypes.number,
        onPostBid: PropTypes.func,
        onBookNow: PropTypes.func,
        onCancel: PropTypes.func
    };

    static defaultProps = {
        auction: null,
        lowestBid: null,
        carrierBestBid: null,
        negotiatedRate: null,
        enableBookNow: true,
        popupActions: false,
        version: 0,
        onPostBid: () => { /* */ },
        onBookNow: () => { /* */ },
        onCancel: () => { /* */ }
    };

    _formPriceSegment(bid, isWinning, withStyle = false) {
        const price = bid ? bid.price : null;

        let styleClass = "";
        if (withStyle) {
            styleClass = isWinning ? "winning-bid" : "losing-bid";
        }

        const noBidsLabel = isWinning !== null ? 'No Bids' : '-';

        return (
            <div className="flex-segment">
                <div className="price-summary">
                    <small className="label">Total Price</small>
                    <h4 className={ `price ${ styleClass }` }>
                        { price ? `$${ NumberUtils.formatWithDecimalNotation(price) }` : noBidsLabel }
                    </h4>
                </div>
                <div>
                    <small className="label">Per Mile</small>
                    <h4 className={ `price ${ styleClass }` }>
                        { price ? `$${ PriceUtils.formatPerMilePrice(price, this.props.auction.load.routingGuideLane.distance) }` : noBidsLabel }
                    </h4>
                </div>
            </div>
        );
    }

    /**
     * Forms the segment which shows the carrier's latest bid
     *
     * @private
     */
    _formYourBidSegment() {
        const isWinning = this.props.carrierBestBid?.current;
        return (
            <>
                <div>
                    <h6 className="title">
                        Your Bid
                        { this.props.carrierBestBid &&
                            <span className={ `win-indicator ${ isWinning ? "winning-bid" : "losing-bid" }` }>
                                { isWinning ? "Winning" : "Losing" }
                                <FontAwesomeIcon icon={ isWinning ? winning : losing } className="icon" />
                            </span>
                        }
                    </h6>
                </div>
                { this._formPriceSegment(this.props.carrierBestBid, isWinning, !!this.props.carrierBestBid) }
                <Separator />
            </>
        );
    }

    /**
     * Forms the title of the place bid segment with suggested price and tooltip. The suggested price should be displayed
     * if it's available on the auction in case there are no spot market bids, or if the lowest spot market bid is worse
     * than the suggested price that would be displayed.
     *
     * @private
     */
    _formPlaceBidTitle() {
        let negotiatedRateDiv = <></>;
        if (this.props.negotiatedRate) {
            negotiatedRateDiv = (
                <small className="negotiated-rate">
                    Negotiated Rate: 
                    <span>
                        <small className="bold-price">${ NumberUtils.formatWithDecimalNotation(this.props.negotiatedRate) }</small>
                        <div className="icon-div">
                            <FontAwesomeIcon icon={ info } className="icon" />
                            <Tooltip direction="top">
                                This is the highest rate you negotiated. 
                                For any further change <br/> or question, please contact ShipEX Logistics.
                            </Tooltip>
                        </div>
                    </span>
                </small>
            );
        }

        return (
            <div className="title">
                <h6>Place a New Bid</h6>
                { negotiatedRateDiv }
            </div>
        );
    }

    render() {
        if (!this.props.auction) {
            return <></>;
        }

        let content = <></>;
        if (Constants.AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.auction.status) {
            const lowestBidSegment = this._formPriceSegment(this.props.lowestBid);
            const yourBidSegment = this._formYourBidSegment();
            const placeBidTitle = this._formPlaceBidTitle();
            const biddingForm = (
                <>
                    { placeBidTitle }
                    <BiddingForm
                        auction={ this.props.auction }
                        distance={ this.props.auction.load.routingGuideLane.distance }
                        bookNowPrice={ this.props.auction.bookNowPrice }
                        negotiatedRate={ this.props.negotiatedRate }
                        lowestBid={ this.props.lowestBid }
                        bestCarrierBid={ this.props.carrierBestBid }
                        enableBookNow={ this.props.enableBookNow }
                        popupActions={ this.props.popupActions }
                        version={ this.props.version }
                        onPostBid={ this.props.onPostBid }
                        onBookNow={ this.props.onBookNow }
                        onCancel={ this.props.onCancel }
                    />
                </>
            );

            content = (
                <div className="content">
                    <h6 className="title main-title">Current Bid</h6>
                    { lowestBidSegment }
                    <Separator />
                    { yourBidSegment }
                    { biddingForm }
                </div>
            );
        } else if (Constants.AUCTION_STATUSES.AWAITING_WINNER_DECLARATION === this.props.auction.status) {
            content = (
                <div className="auction-closed">
                    <FontAwesomeIcon icon={ gavel } className="auction-closed-icon" />
                    <h6>Bidding is Closed</h6>
                    <small>Winner verification is in progress, we will inform you about the updates as soon as possible.</small>
                </div>
            );
        }

        return (
            <div className="component current-bid">
                { content }
            </div>
        );
    }
}
