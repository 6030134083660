import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import Separator from 'component/Separator';
import Tooltip from 'component/Tooltip';
import TitledCardComponent from 'component/card/TitledCardComponent';
import LaneVisualization from 'component/lane/LaneVisualization';
import TenderCandidateOfferActions from 'component/lane/TenderCandidateOfferActions';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './RoutingGuideLaneCardContent.scss';

export default withRouter(class RoutingGuideLaneCardContent extends Component {

    static propTypes = {
        routingGuideLane: PropTypes.object.isRequired,
        version: PropTypes.number,
        onUpdated: PropTypes.func
    };

    static defaultProps = {
        version: 0,
        onUpdated: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.state = {
            relevantOffer: null,
            loading: true
        };

        this._fetchRelevantOffer = this._fetchRelevantOffer.bind(this);
        this._acceptOffer = this._acceptOffer.bind(this);
        this._rejectOffer = this._rejectOffer.bind(this);
        this._onCardClick = this._onCardClick.bind(this);
    }

    componentDidMount() {
        this._fetchRelevantOffer();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.version !== this.props.version) {
            this._fetchRelevantOffer();
        }
    }

    _fetchRelevantOffer() {
        TenderCandidateApiService.getRelevantOfferForUser(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id)
            .then(relevantOffer => this.setState({ relevantOffer, loading: false }))
            .catch(error => console.error('An error occurred while fetching relevant offer.', error));
    }

    _acceptOffer(tenderCandidate) {
        TenderCandidateApiService.accept(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, tenderCandidate.id)
            .then(this.props.onUpdated)
            .catch(error => console.error('An error occurred while accepting offer.', error));
    }

    _rejectOffer(tenderCandidate) {
        TenderCandidateApiService.reject(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, tenderCandidate.id)
            .then(this.props.onUpdated)
            .catch(error => console.error('An error occurred while rejecting offer.', error));
    }

    _onCardClick(event) {
        event.stopPropagation();
        this.props.history.push(`/lane/${ this.props.routingGuideLane.id }`);
    }

    _formStatusTooltip() {
        const { relevantOffer } = this.state;

        if (relevantOffer && TENDER_CANDIDATE_STATUS.DELETED.value === relevantOffer.status) {
            return (
                <div className="status-tooltip-container">
                    <FontAwesomeIcon icon={ info } className="tooltip-icon" />
                    <Tooltip direction="bottom">
                        You have been removed from this lane.<br/> 
                        Please contact ShipEX for more information.
                    </Tooltip>
                </div>
            );
        }

        return <></>;
    }

    _formActions() {
        const { relevantOffer } = this.state;
        
        if (relevantOffer && TENDER_CANDIDATE_STATUS.PENDING.value === relevantOffer.status) {
            return (
                <TenderCandidateOfferActions
                    tenderCandidate={ relevantOffer }
                    routingGuideLane={ this.props.routingGuideLane }
                    onAccept={ this._acceptOffer }
                    onReject={ this._rejectOffer }
                />
            );
        }

        return <></>;
    }

    render() {
        const { relevantOffer } = this.state;

        const pricePerMile = relevantOffer ? PriceUtils.calculatePerMilePrice(relevantOffer.price.value, this.props.routingGuideLane.distance) : null;
        const priceDisplay = relevantOffer ? NumberUtils.prefixNumber(relevantOffer.price.value, '$', false, 'decimal') : 'No Rate';
        const pricePerMileDisplay = pricePerMile ? NumberUtils.prefixNumber(pricePerMile, '$', false, 'decimal') : 'No Rate';

        const status = relevantOffer && TENDER_CANDIDATE_STATUS[relevantOffer.status];
        const inactive = relevantOffer && TENDER_CANDIDATE_STATUS.DELETED.value === relevantOffer.status;
        const actions = this._formActions();

        return (
            <div className="routing-guide-lane-card" onClick={ this._onCardClick }>
                <div className="routing-guide-lane-card-visualization-container">
                    <LaneVisualization lane={ this.props.routingGuideLane } className="neutral" labeled={ true } />
                </div>

                <Separator orientation="vertical" />

                <TitledCardComponent
                    title="Per Mile"
                    align="right"
                    className="routing-guide-lane-card-column-container price"
                >
                    <strong><b>{ !inactive ? pricePerMileDisplay : '-' }</b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                <TitledCardComponent 
                    title="Per Load"
                    align="right"
                    className="routing-guide-lane-card-column-container price"
                >
                    <strong><b>{ !inactive ? priceDisplay : '-' }</b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                <TitledCardComponent 
                    title="Awarded Vol."
                    align="right"
                    className="routing-guide-lane-card-column-container volume"
                >
                    <strong><b>{ relevantOffer && !inactive ? relevantOffer.volume : '-' }</b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                <TitledCardComponent
                    title="Status"
                    className={ `routing-guide-lane-card-column-container status ${ (status || {}).style }` }
                >
                    <>
                        <strong><b>{ status ? status.candidateFacingText : '-' }</b></strong>
                        { this._formStatusTooltip() }
                    </>
                </TitledCardComponent>

                <div className="routing-guide-lane-card-actions-container">
                    { actions }
                </div>
            </div>
        );
    }
});
