import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle as addAttachmentIcon } from '@fortawesome/pro-light-svg-icons';

import { FINISHED_RFP_AUCTION_STATUSES, FORM_STATUSES } from 'common/constants';
import UploadFilePopup from 'component/UploadFilePopup';
import RestService from 'service/RestService';

import './UploadAttachments.scss';

export default class UploadAttachments extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        onUpdate: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        onUpdate: () => { /* */ },
        onClose: () => { /* */ }
    };

    constructor(props) {
        super(props);
        this._uploadAttachment = this._uploadAttachment.bind(this);
        this._onCloseUploadPopup = this._onCloseUploadPopup.bind(this);
        this._onOpenUploadPopup = this._onOpenUploadPopup.bind(this);
    }

    state = {
        showPopup: false,
        uploadStatus: null,
        errorMessage: null
    };

    _onOpenUploadPopup(event) {
        event.preventDefault();
        this.setState({ showPopup: true });
    }

    _onCloseUploadPopup() {
        this.setState({ showPopup: false, uploadStatus: null });
        this.props.onClose();
    }

    _uploadAttachment(file) {
        this.setState({ uploadStatus: FORM_STATUSES.LOADING });

        const formData = new FormData();
        formData.append('document', file);

        const headers = {
            'X-Uploaded-File-Size': file.size
        };

        RestService.instance().post(`auction/rfp/${ this.props.rfp.id }/attachment`, formData, headers)
            .then(() => this.setState({ uploadStatus: FORM_STATUSES.SUCCESS }))
            .then(() => this.props.onUpdate())
            .catch((error) => this.setState({
                uploadStatus: FORM_STATUSES.ERROR,
                errorMessage: typeof(((error || {}).response || {}).data) === 'string' ? error.response.data : "Something went wrong while uploading the attachment."
            }));
    }

    _formUploadProps() {
        const isRFPFinished = FINISHED_RFP_AUCTION_STATUSES.includes(this.props.rfp.status);
        const variant = isRFPFinished ? 'Routing Guide' : 'RFP';
        const labels = {
            title: 'Add Attachments',
            buttonText: 'Add Attachments',
            uploadSubtitle: 'Upload Attachments',
            acceptedFormats: '(PDF, txt, word, ...)'
        };

        const description = (
            <div className="attachment-upload-description">
                Please select the file you want to upload to the { variant }. Files must be uploaded one at a time. <br />
                { !isRFPFinished && 'Please note that once the RFP has begun, all uploaded attachments will be visible to the invited carriers.' }
            </div>
        );

        const trigger = (
            <div onClick={ this._onOpenUploadPopup } className="dropdown-item">
                <FontAwesomeIcon className="action-icon" icon={ addAttachmentIcon }/>
                <small className="action-name">
                    Add Attachments
                </small>
            </div>
        );

        const loadingElement = (
            <div>
                <h6>Uploading attachment</h6>
                <small>This will only take a moment. Please wait...</small>
            </div>
        );

        const successElement = (
            <div>
                <h6>Attachment uploaded</h6>
                <small>
                    Your attachment was successfully added to <b>{ this.props.rfp.name }</b>.
                </small>
            </div>
        );

        const errorElement = (
            <div>
                <h6>File Not Uploaded</h6>
                <small>
                    { this.state.errorMessage }
                </small>
            </div>
        );

        return { labels, description, trigger, loadingElement, successElement, errorElement };
    }

    render() {
        const { labels, description, trigger, loadingElement, successElement, errorElement } = this._formUploadProps();

        return (
            <div className="upload-attachments-container">
                <UploadFilePopup
                    show={ this.state.showPopup }
                    labels={ labels }
                    trigger={ trigger }
                    children={ description }
                    loadingElement={ loadingElement }
                    successElement={ successElement }
                    errorElement={ errorElement }
                    uploadStatus={ this.state.uploadStatus }
                    onUploadFile={ this._uploadAttachment }
                    onClose={ this._onCloseUploadPopup }
                />
            </div>
        );
    }
}
