import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleLeft as firstIcon,
    faAngleDoubleRight as lastIcon,
    faAngleLeft as previousIcon,
    faAngleRight as nextIcon
} from '@fortawesome/pro-light-svg-icons';

import { DEFAULT_PAGINATION_VALUES } from 'common/constants';
import CustomSelect from 'component/form/CustomSelect';

import './Pagination.scss';

export default class Pagination extends PureComponent {

    static propTypes = {
        pageNumber: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        available: PropTypes.number.isRequired,
        onSetPage: PropTypes.func.isRequired,
        onSetPageSize: PropTypes.func.isRequired,
        paginationValues: PropTypes.arrayOf(PropTypes.shape({ size: PropTypes.number })),
        fixed: PropTypes.bool,
        colorTheme: PropTypes.oneOf(['light', 'medium']),
        resultsText: PropTypes.string
    }

    static defaultProps = {
        paginationValues: DEFAULT_PAGINATION_VALUES,
        fixed: true,
        colorTheme: 'medium',
        resultsText: 'results'
    }

    constructor(props) {
        super(props);

        this._onInputChange = this._onInputChange.bind(this);
        this._onFirst = this._onFirst.bind(this);
        this._onLast = this._onLast.bind(this);
        this._onNext = this._onNext.bind(this);
        this._onPrevious = this._onPrevious.bind(this);
        this._onPageSizeSelect = this._onPageSizeSelect.bind(this);
    }

    componentDidUpdate() {
        if (this.props.pageNumber !== 1 && this._isPageEmpty()) {
            this.props.onSetPage(this._lastPage());
        }
    }

    _onFirst() {
        if (this.props.pageNumber > 1) {
            this.props.onSetPage(1);
        }
    }

    _onNext() {
        if (this.props.pageNumber < this._lastPage()) {
            this.props.onSetPage(this.props.pageNumber + 1);
        }
    }

    _onPrevious() {
        if (this.props.pageNumber > 1) {
            this.props.onSetPage(this.props.pageNumber - 1);
        }
    }

    _onLast() {
        if (this.props.pageNumber < this._lastPage()) {
            this.props.onSetPage(this._lastPage());
        }
    }

    _lastPage() {
        return Math.ceil(this.props.available / this.props.pageSize) || 1;
    }

    _onInputChange(event) {
        const rawValue = event.target.value;

        // If the input is not numeric, abort.
        if (!/\d+/.test(rawValue)) {
            return;
        }

        const page = parseInt(event.target.value);

        // If the input is not within the page range, abort.
        if (page < 1 || page > this._lastPage()) {
            return;
        }

        // TODO: This may need some form of debouncing.
        this.props.onSetPage(page);
    }

    _onPageSizeSelect(pageSize) {
        this.props.onSetPageSize(parseInt(pageSize));
    }

    _isPageEmpty() {
        return (this.props.pageNumber - 1) * this.props.pageSize >= this.props.available;
    }

    _getShowingResultsSize() {
        if (this.props.available >= this.props.pageNumber * this.props.pageSize) {
            return this.props.pageSize;
        } else {
            return this.props.available - (this.props.pageNumber - 1) * this.props.pageSize;
        }
    }

    render() {
        let paginationContainer = <></>;

        if (this.props.available > 0) {
            paginationContainer = (
                <div className={ `pagination-container ${ this.props.fixed ? 'fixed' : '' } ${ this.props.colorTheme }` }>
                    <div className="pagination-quantity-display-container">
                        <small>Showing <b>{ this._getShowingResultsSize() }</b> out of <b>{ this.props.available }</b> { this.props.resultsText }.</small>
                    </div>

                    <div className="pagination-control-container">
                        <div className={ 'pagination-control' + ( this.props.pageNumber === 1 ? ' disabled' : '' ) } onClick={ this._onFirst }>
                            <FontAwesomeIcon
                                className="pagination-control-icon"
                                icon={ firstIcon }
                            />
                        </div>

                        <div className={ 'pagination-control' + ( this.props.pageNumber === 1 ? ' disabled' : '' ) } onClick={ this._onPrevious }>
                            <FontAwesomeIcon
                                className="pagination-control-icon"
                                icon={ previousIcon }
                            />
                        </div>

                        <div className="pagination-display-container">
                            <small>Page</small>
                            <small>
                                <input
                                    type="text"
                                    className="pagination-input"
                                    value={ this.props.pageNumber }
                                    onChange={ this._onInputChange } />
                            </small>
                            <small>out of <b>{ this._lastPage() }</b></small>
                        </div>

                        <div className={ 'pagination-control' + ( this.props.pageNumber === this._lastPage() ? ' disabled' : '' ) } onClick={ this._onNext }>
                            <FontAwesomeIcon
                                className="pagination-control-icon"
                                icon={ nextIcon }
                            />
                        </div>

                        <div className={ 'pagination-control' + ( this.props.pageNumber === this._lastPage() ? ' disabled' : '' ) } onClick={ this._onLast }>
                            <FontAwesomeIcon
                                className="pagination-control-icon"
                                icon={ lastIcon }
                            />
                        </div>
                    </div>

                    <div className="pagination-quantity-container">
                        <small>Display</small>
                        <CustomSelect
                            values={ this.props.paginationValues }
                            selectedValue={ this.props.pageSize.toString() }
                            onSelect={ this._onPageSizeSelect }
                            fieldName="size"
                            direction="top"
                            size="small"
                        />
                        <small>{ this.props.resultsText }.</small>
                    </div>
                </div>
            );
        }

        return paginationContainer;
    }
}
