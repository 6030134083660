import React from 'react';
import SafeStateComponent from 'component/SafeStateComponent';
import PropTypes from 'prop-types';
import { faFolder as folderClosed, faFolderOpen as folderOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tag from 'component/Tag';

import './CustomFileInput.scss';

export default class CustomFileInput extends SafeStateComponent {

    static propTypes = {
        id: PropTypes.string,
        file: PropTypes.instanceOf(File),
        acceptedFormats: PropTypes.string,
        onSelectedFilesChange: PropTypes.func
    }

    static defaultProps = {
        id: undefined,
        acceptedFormats: null,
        onSelectedFileChange: () => { /* */ }
    }

    _fileInputRef = React.createRef();

    componentDidMount() {
        if (!this.props.file) {
            this._deselectFile();
        }
    }

    componentDidUpdate() {
        if (!this.props.file) {
            this._deselectFile();
        }
    }

    _deselectFile() {
        this._fileInputRef.current.value = null;
    }

    _determineUploadStyle() {
        if (this.props.file) {
            return {
                icon: folderOpen,
                style: 'uploaded'
            }
        } else {
            return {
                icon: folderClosed,
                style: ''
            }
        }
    }

    _onTagClose() {
        this.props.onSelectedFileChange(null);
        this._deselectFile();
    }

    _onFileSelected(event) {
        this.props.onSelectedFileChange(event.target.files[0]);
    }

    render() {
        const { icon, style } = this._determineUploadStyle();

        let tag = <></>;
        if (this.props.file) {
            tag = <Tag text={ this.props.file.name } closable={ true } onClose={ this._onTagClose.bind(this) } />;
        }

        return (
            <div id={ this.props.id } className={ `upload-container ${ style }` }>
                <label className="upload-region">
                    <div className="upload-icon">
                        <FontAwesomeIcon icon={ icon }/>
                    </div>
                    <small className="upload-text">Select or drag and drop a file to this area to upload.</small>
                    <input
                        type="file"
                        accept={ this.props.acceptedFormats }
                        ref={ this._fileInputRef }
                        className="file-input"
                        onChange={ this._onFileSelected.bind(this) }
                    />
                </label>
                { tag }
            </div>
        );
    }
}
