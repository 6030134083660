import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit as edit } from '@fortawesome/pro-solid-svg-icons';

import { MAX_PRICE } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomNumberInput from 'component/form/CustomNumberInput';
import RoutingGuideLaneApiService from 'service/api/RoutingGuideLaneApiService';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './EditLaneBookNowPricePopup.scss';

export default class EditLaneBookNowPricePopup extends Component {

    static propTypes = {
        routingGuideLane: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this._onOpenBookNowPopup = this._onOpenBookNowPopup.bind(this);
        this._onCloseBookNowPopup = this._onCloseBookNowPopup.bind(this);
        this._onBookNowPriceChange = this._onBookNowPriceChange.bind(this);
        this._onBookNowPriceClear = this._onBookNowPriceClear.bind(this);
        this._onSubmitBookNowPrice = this._onSubmitBookNowPrice.bind(this);
        this._onToggleConfirmationDialog = this._onToggleConfirmationDialog.bind(this);
    }

    state = {
        newBookNowPrice: null,
        perMilePrice: null,
        showEditBookNowPopup: false,
        invalidBookNowInput: true,
        resetBookNowInput: false,
        showBookNowChangeConfirmationDialog: false
    }

    componentDidMount() {
        const { bookNowPrice, distance } = this.props.routingGuideLane;
        this.setState({
            perMilePrice: bookNowPrice ? PriceUtils.calculatePerMilePrice(bookNowPrice, distance) : null
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showEditBookNowPopup !== this.state.showEditBookNowPopup) {
            this.setState({
                invalidBookNowInput: true
            });
        }

        if (prevProps.routingGuideLane.bookNowPrice !== this.props.routingGuideLane.bookNowPrice) {
            const { bookNowPrice, distance } = this.props.routingGuideLane;
            this.setState({
                perMilePrice: bookNowPrice ? PriceUtils.calculatePerMilePrice(bookNowPrice, distance) : null
            });
        }
    }

    _onToggleConfirmationDialog() {
        this.setState(prevState => {
            let { newBookNowPrice, invalidBookNowInput, perMilePrice } = prevState;

            if (prevState.showBookNowChangeConfirmationDialog) {
                newBookNowPrice = this.props.routingGuideLane.bookNowPrice || null;
                perMilePrice = PriceUtils.formatPerMilePrice(newBookNowPrice, this.props.routingGuideLane.distance);
                invalidBookNowInput = true;
            }

            return {
                showBookNowChangeConfirmationDialog: !prevState.showBookNowChangeConfirmationDialog,
                newBookNowPrice,
                perMilePrice,
                invalidBookNowInput
            };
        });
    }

    _onSubmitBookNowPrice(price) {
        const { routingGuideLane } = this.props;
        RoutingGuideLaneApiService.setBookNowPrice(routingGuideLane.rfpAuction.id, routingGuideLane.id, price)
            .then(() => {
                this.setState({ showEditBookNowPopup: false, showBookNowChangeConfirmationDialog: false });
            });
    }

    _onBookNowPriceChange(value) {
        const { distance, bookNowPrice } = this.props.routingGuideLane;
        const perMilePrice = value !== null ? PriceUtils.calculatePerMilePrice(value, distance) : null;
        const invalidBookNowInput = value === bookNowPrice 
            || ((!!value || value === 0) && (value <= 0 || value >= MAX_PRICE))
            || (!value && !bookNowPrice);

        this.setState({
            newBookNowPrice: value,
            perMilePrice,
            invalidBookNowInput
        });
    }

    _onBookNowPriceClear() {
        this._onBookNowPriceChange('');
    }

    _onOpenBookNowPopup() {
        this.setState({ showEditBookNowPopup: true, resetBookNowInput: false });
    }

    _onCloseBookNowPopup() {
        const { routingGuideLane } = this.props;
        const { bookNowPrice, distance } = routingGuideLane;

        this.setState({
            showEditBookNowPopup: false,
            newBookNowPrice: null,
            perMilePrice: bookNowPrice ? PriceUtils.calculatePerMilePrice(bookNowPrice, distance) : null,
            resetBookNowInput: true,
            showBookNowChangeConfirmationDialog: false
        });
    }

    render() {
        const { routingGuideLane } = this.props;
        const { newBookNowPrice, showEditBookNowPopup, invalidBookNowInput, perMilePrice, resetBookNowInput } = this.state;
        const { bookNowPrice } = routingGuideLane;

        let bookNowPriceString;
        if (bookNowPrice) {
            bookNowPriceString = (
                <span>
                The currently set book now price for the lane is <b>${ NumberUtils.formatWithDecimalNotation(bookNowPrice) }</b>.
                </span>
            );
        } else {
            bookNowPriceString = ' The book now price is not currently set.';
        }

        const applicableToString = "Editing the book now price won't affect current auctions, the price will be just applied to new auctions on this lane in the future.";

        const shownPerMilePrice = perMilePrice ? `$${ NumberUtils.formatWithDecimalNotation(perMilePrice) }` : '-';

        let description;
        if (newBookNowPrice !== '') {
            if (bookNowPrice) {
                description = (
                    <>
                        Please confirm that you want to change the book now price from <b>${ NumberUtils.formatWithDecimalNotation(bookNowPrice) }</b> (${ PriceUtils.formatPerMilePrice(bookNowPrice, routingGuideLane.distance) } per mile) to <b>${ NumberUtils.formatWithDecimalNotation(newBookNowPrice) }</b> ({ shownPerMilePrice } per mile) for the <b>{ LaneUtils.formatRegion(routingGuideLane.lane.origin) }</b> to <b>{ LaneUtils.formatRegion(routingGuideLane.lane.destination) }</b> lane.
                    </>
                );
            } else {
                description = (
                    <>
                        Please confirm that you want to set the book now price to <b>${ NumberUtils.formatWithDecimalNotation(newBookNowPrice) }</b> ({ shownPerMilePrice } per mile) for the <b>{ LaneUtils.formatRegion(routingGuideLane.lane.origin) }</b> to <b>{ LaneUtils.formatRegion(routingGuideLane.lane.destination) }</b> lane.
                    </>
                );
            }
        } else {
            description = (
                <>
                    Please confirm that you want to remove the book now price for the <b>{ LaneUtils.formatRegion(routingGuideLane.lane.origin) }</b> to <b>{ LaneUtils.formatRegion(routingGuideLane.lane.destination) }</b> lane.
                </>
            );
        }

        let body, size;
        if (this.state.showBookNowChangeConfirmationDialog) {
            size = 'small';
            body = (
                <div className="edit-book-now-popup">
                    <p className="heading">
                        Confirm Changes
                    </p>
                    <p className="description">
                        { description } { applicableToString }
                    </p>
                    <div className="buttons-wrapper">
                        <Button
                            type="tertiary"
                            size="small"
                            onClick={ this._onToggleConfirmationDialog }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            disabled={ !!invalidBookNowInput }
                            onClick={ () => this._onSubmitBookNowPrice(newBookNowPrice) }
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            );
        } else {
            size = 'medium';
            body = (
                <div className="edit-booknow-popup">
                    <h6 className="heading">
                        Edit Book Now Price
                    </h6>
                    <div className="description">
                        <p>
                            { applicableToString } All book now prices are shown and counted as total prices. { bookNowPriceString }
                        </p>
                    </div>
                    <p className="subheading">
                        Price <span className="total-price-label">(Total Price)</span>
                    </p>
                    <CustomNumberInput
                        allowClear={ true }
                        value={ typeof newBookNowPrice === 'number' ? newBookNowPrice.toString() : newBookNowPrice }
                        placeholder="Enter your new price"
                        onChange={ this._onBookNowPriceChange }
                        onClear={ this._onBookNowPriceClear }
                        prefix="$"
                        className="booknow-price"
                        shouldValidate={ false }
                        isValid={ !invalidBookNowInput }
                        initialValue={ bookNowPrice ? bookNowPrice.toFixed(2) : null }
                        resetValue={ resetBookNowInput }
                    />
                    <small className="per-mile-price-label">
                        Per Mile Price: { shownPerMilePrice }
                    </small>
                    <div className="buttons-wrapper">
                        <Button
                            type="tertiary"
                            link="#"
                            onClick={ this._onCloseBookNowPopup }
                        >
                            Discard
                        </Button>
                        <Button
                            type="primary"
                            disabled={ !!invalidBookNowInput }
                            onClick={ this._onToggleConfirmationDialog }
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="edit-lane-booknow-component">
                <Popup
                    id="edit-booknow-popup"
                    size={ size }
                    closeButton={ false }
                    show={ showEditBookNowPopup }
                    onClose={ this._onCloseBookNowPopup }
                    trigger={
                        <a href="#edit-booknow-popup" className="booknow-button" onClick={ this._onOpenBookNowPopup }>
                            <FontAwesomeIcon icon={ edit } />
                        </a>
                    }
                >
                    { body }
                </Popup>
            </div>
        );
    }
}
