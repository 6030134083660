import { Component } from 'react';
import PropTypes from 'prop-types';
import {
    faAngleUp as arrowUp,
    faAngleDown as arrowDown
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateUtils from 'utils/DateUtils';

import './DispatchDetailsStopOverview.scss';

export default class DispatchDetailsStopOverview extends Component {
    static propTypes = {
        stop: PropTypes.shape({
            id: PropTypes.string,
            stopType: PropTypes.oneOf(['PICKUP', 'DELIVERY']),
            ordinal: PropTypes.number,
            earliestTime: PropTypes.string,
            latestTime: PropTypes.string,
            reference: PropTypes.string,
            companyId: PropTypes.string,
            companyName: PropTypes.string,
            companyLocation: PropTypes.object
        }),
        isCarrier: PropTypes.bool
    }

    static defaultProps = {
        stop: null,
        isCarrier: false
    }

    constructor(props) {
        super(props);

        this._toggleReferenceNumbersExpanded = this._toggleReferenceNumbersExpanded.bind(this);
    }

    state = {
        referenceNumbersExpanded: false
    }

    _toggleReferenceNumbersExpanded() {
        this.setState(prevState => { 
            return { referenceNumbersExpanded: !prevState.referenceNumbersExpanded }
        });
    }

    static _formatCompanyAddress(companyName, companyLocation) {
        if (!companyName && !companyLocation) {
            return <small>----</small>;
        }

        const name = companyName ? <small className="content" title={ companyName }>{ companyName } </small> : <></>;

        let location = <></>;
        if (companyLocation) {
            const { region, address, zipCode } = companyLocation;
            const { city, state } = region;
            const locationText = `${ city }, ${ state } ${ zipCode }`;

            location = (
                <>
                    <small className="content" title={  address.trim() }>{ address.trim() }</small>
                    <br/>
                    <small className="content" title={ locationText }>{ locationText }</small>
                </>
            );
        }
        
        return (
            <small className="content location">
                { name }
                <br/>
                { location }
            </small>
        );
    }

    static _formatDateDisplay(date, offset) {
        return date ? DateUtils.formatLocal(new Date(date), offset) : '----';
    }

    _formExpandButton(totalItems, itemsShown) {
        const { referenceNumbersExpanded } = this.state;
        const showExpandButton = (referenceNumbersExpanded && itemsShown === totalItems) || (!referenceNumbersExpanded && itemsShown < totalItems);
        
        let expandButton = <></>;
        if (showExpandButton) {
            expandButton = (
                <small className="toggle-expand" onClick={ this._toggleReferenceNumbersExpanded }>
                    { referenceNumbersExpanded ? 'Show Less ' : `See More (${ totalItems - itemsShown }) ` }
                    <FontAwesomeIcon icon={ referenceNumbersExpanded ? arrowUp : arrowDown }/>
                </small>
            );
        }

        return expandButton;
    }

    _formReferenceNumbersList() {
        const { stop } = this.props;
        const { referenceNumbersExpanded } = this.state;
        const stopReferenceNumbers = stop.referenceNumbers || [];

        let referenceNumbersDiv = <></>;
        if (stopReferenceNumbers.length) {
            const totalItems = stopReferenceNumbers.length;
            const itemsShown = referenceNumbersExpanded ? totalItems : 3;

            let referenceNumbers = <></>;
            for (let i = 0; i < itemsShown; i++) {
                const reference = stopReferenceNumbers[i];
                referenceNumbers = (
                    <>
                        { referenceNumbers }
                        <small key={ `${ i }-${ reference }` } className="content" title={ reference }>
                            { reference }
                        </small>
                    </>
                );
            }

            referenceNumbersDiv = (
                <div className="field ref-numbers">
                    <small className="title">
                        Notes
                    </small>
                    <div className={ `ref-numbers-content ${ !referenceNumbersExpanded ? 'expand-on-side' : ''}` }>
                        <div className="reference-list"> { referenceNumbers } </div>
                        <div className="expand-label">{ this._formExpandButton(totalItems, itemsShown) }</div>
                    </div>
                </div>
            );
        }

        return referenceNumbersDiv;
    }

    render() {
        const { stop, isCarrier } = this.props;
        const isPickup = stop.stopType === 'PICKUP';
        const companyId = stop.companyId || '----';
        const oiNumber = stop.reference || '----';

        let oiNumberDiv = <></>;
        if (isPickup && !isCarrier) {
            oiNumberDiv = (
                <div className="field text">
                    <small className="title">
                        OI
                    </small>
                    <small className="content" title={ oiNumber }>
                        { oiNumber }
                    </small>
                </div>
            );
        }

        let companyIdDiv = <></>;
        if (!isCarrier) {
            companyIdDiv = (
                <div className="field text">
                    <small className="title">
                        { isPickup ? 'PU' : 'CON' }
                    </small>
                    <small className="content" title={ companyId }>
                        { companyId }
                    </small>
                </div>
            );
        }


        return (
            <div className="stop-details component" key={ stop.id }>
                <strong className="title">
                    { isPickup ? 'Pickup' : 'Delivery' }
                </strong>
                <div className="info-provided">
                    <div className="field location">
                        <small className="title">
                            Location
                        </small>
                        { DispatchDetailsStopOverview._formatCompanyAddress(stop.companyName, stop.companyLocation) }
                    </div>
                    <div className="field date">
                        <small className="title">
                            Earliest Date
                        </small>
                        <small className="content">
                            { DispatchDetailsStopOverview._formatDateDisplay(stop.earliestTime, stop.earliestTimeOffset) }
                        </small>
                    </div>
                    <div className="field date">
                        <small className="title">
                            Latest Date
                        </small>
                        <small className="content">
                            { DispatchDetailsStopOverview._formatDateDisplay(stop.latestTime, stop.latestTimeOffset) }
                        </small>
                    </div>
                    { companyIdDiv }
                    { oiNumberDiv }
                    { this._formReferenceNumbersList() }
                </div>
            </div>
        );
    }
}
