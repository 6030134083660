import { Component } from 'react';
import PropTypes from 'prop-types';

import ActivityLogDrawer from 'component/activity/ActivityLogDrawer';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'common/constants';
import CarrierActivityLogApiService from 'service/api/CarrierActivityLogApiService';

export default class LoadActivityLog extends Component {
    static propTypes = {
        loadId: PropTypes.string.isRequired,
        carrierId: PropTypes.string
    }

    static defaultProps = {
        carrierId: null
    }

    constructor(props) {
        super(props);

        this._incrementPageNumber = this._incrementPageNumber.bind(this);
        this._fetchLoadActivities = this._fetchLoadActivities.bind(this);
    }

    state = {
        activities: [],
        hasMore: false,
        isLoading: false,
        pageNumber: DEFAULT_PAGE_NUMBER,
        createdBefore: new Date()
    }

    componentDidMount() {
        this._fetchLoadActivities();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pageNumber !== this.state.pageNumber) {
            this._fetchLoadActivities();
        }

        if (prevProps.loadId !== this.props.loadId) {
            this.setState({ createdBefore: new Date(), pageNumber: DEFAULT_PAGE_NUMBER }, this._fetchLoadActivities)
        }
    }

    _fetchLoadActivities() {
        if (this.props.carrierId) {
            this.setState({ isLoading: true });
            const props = {
                pagination: {
                    pageNumber: this.state.pageNumber,
                    pageSize: DEFAULT_PAGE_SIZE,
                },
                load: this.props.loadId,
                createdBefore: this.state.createdBefore
            }

            CarrierActivityLogApiService.filter(this.props.carrierId, props)
                .then((activities) => {
                    const hasMore = (this.state.pageNumber * DEFAULT_PAGE_SIZE) < activities.available;
                    this.setState(prevState => {
                        return {
                            activities: this.state.pageNumber !== DEFAULT_PAGE_NUMBER ? prevState.activities.concat(activities.data) : activities.data,
                            hasMore,
                            isLoading: false
                        };
                    });    
                });
        }
    }

    _incrementPageNumber() {
        this.setState(prevState => { 
            return { pageNumber: prevState.pageNumber + 1 };
        });
    }

    render() {
        return (
            <ActivityLogDrawer 
                activities={ this.state.activities } 
                hasMore={ this.state.hasMore } 
                isLoading={ this.state.isLoading }
                fetchMoreData={ this._incrementPageNumber }
                description="Activity log from all carrier members on this Load"
            />
        );
    }
}
