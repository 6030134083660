import { Component } from 'react';
import PropTypes from 'prop-types';

import './TableRow.scss';

export default class TableRow extends Component {
    static propTypes = {
        isHeader: PropTypes.bool,
        fontSize: PropTypes.oneOf(['small', 'regular']),
        className: PropTypes.string
    }

    static defaultProps = {
        isHeader: false,
        fontSize: 'regular',
        className: ''
    }

    render() {
        const { isHeader, fontSize, className, ...filteredProps } = this.props;

        const style = isHeader ? 'header-row' : `content-row ${ fontSize }`;

        return (
            <tr className={ `${ style } ${ className }` } { ...filteredProps } />
        );
    }
}
