import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird as spinnerIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faHeartBroken as failIcon } from '@fortawesome/pro-solid-svg-icons';

import Integrate from 'init/Integrate';
import Router from 'Router';
import AuthenticationService from 'service/AuthenticationService';
import ConfigurationService from 'service/ConfigurationService';
import WebSocketService from 'service/WebSocketService';

import './App.scss';

/**
 * The Main Application Component.
 * Handles user login, and global static service initialization.
 */
export default class App extends Component {

    /**
     * The initial state.
     *
     * @type {{ isLoggedIn: boolean, loginError: object|null }}
     */
    state = {
        isLoggedIn: false,
        loginError: null
    }

    /**
     * A global event listener to prevent scroll events when users click on anchor links.
     *
     * @param event
     * @private
     */
    _globalClickListener(event) {
        const href = ((event || {}).target || {}).href || "";
        const index = href.indexOf('#');

        if (href && index !== -1) {
            event.preventDefault();
            const x = window.scrollX;
            const y = window.scrollY;

            window.location.href = href.substr(index);

            window.scroll(x, y);
        }
    }

    async componentDidMount() {
        // Register the global click event listener.
        window.addEventListener('click', this._globalClickListener);

        // Initialize the configuration service.
        await ConfigurationService.instance().init();

        // Initialize the authentication service. It's a blocking operation so no await is needed here.
        await AuthenticationService.init();

        // The first thing we must do when the application is mounted is to make sure a user is logged in.
        // There can be no other application interaction before a user has logged in.
        const authenticationService = AuthenticationService.instance();

        // Register redirection handlers. Awaits completion of handler before proceeding. Very important.
        let preLoginError = await authenticationService.preLogin()
            .then(() => null)
            .catch(error => error);

        // In case redirect handler responded with an authorization error,
        // we cannot proceed with rendering the page, and we must display the error to the user.
        if (preLoginError) {
            this.setState({ isLoggedIn: false, loginError: preLoginError });
            return;
        }

        const account = await authenticationService.getAccount();

        if (account) {
            // Authentication was successful. Now, we need to try and get a token.
            // This will force a token refresh, in case the current one has expired.
            await authenticationService.getToken()
                .then(async () => {
                    // Initialize the web socket service.
                    await WebSocketService.instance().init();

                    // Token is valid, we can jump right in.
                    this.setState({ isLoggedIn: true, account });
                })
                .catch(error => {
                    this.setState({ isLoggedIn: false, loginError: error });
                });
        } else {
            // No account is present in cache, we need to invoke login.
            authenticationService.login();
        }
    }

    async componentWillUnmount() {
        // Unregister the global click event listener.
        window.removeEventListener('click', this._globalClickListener);

        // Close down the web socket service.
        await WebSocketService.instance().close();
    }

    render() {
        if (this.state.isLoggedIn) {
            // In case the login process has completed fully, and successfully, we can render the full application.
            return (
                <Integrate account={ this.state.account }>
                    <Router account={ this.state.account } />
                </Integrate>
            );
        } else if (this.state.loginError) {
            // In case the login process has completed fully, but unsuccessfully, we must render the login error page.
            return (
                <div className="login-screen">
                    <FontAwesomeIcon className="fail-icon" icon={ failIcon } />
                    <p>Failed to log you in.<br />Please forward the data below to your administrator.</p>
                    <pre>{ JSON.stringify(this.state.loginError, null, 2) }</pre>
                </div>
            );
        } else {
            // In case the login process is in progress, we need to render the login-in-progress page.
            return (
                <div className="login-screen">
                    <FontAwesomeIcon className="loading-icon" icon={ spinnerIcon } spin />
                    <p>Logging in...</p>
                </div>
            );
        }
    }
}
