import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomDateTimePicker from 'component/form/CustomDateTimePicker';
import DateUtils from 'utils/DateUtils';

import './ExtendOfferPopup.scss';

export default class ExtendOfferPopup extends Component {

    static propTypes = {
        bid: PropTypes.object.isRequired,
        auction: PropTypes.object.isRequired,
        onExtendOffer: PropTypes.func,
        onCancelExtendOffer: PropTypes.func
    };

    static defaultProps = {
        onExtendOffer: () => { /* */ },
        onCancelExtendOffer: () => { /* */ }
    };

    constructor (props) {
        super(props);

        this._resetState = this._resetState.bind(this);
        this._onTriggerClick = this._onTriggerClick.bind(this);
        this._handleExpirationChange = this._handleExpirationChange.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onExtendOffer = this._onExtendOffer.bind(this);
    }

    state = {
        showPopup: false,
        expiration: new Date(),
        disableSubmitButton: true
    }

    componentDidMount() {
        this.setState({ expiration: this._defaultExpiration() });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bid.expiration !== this.props.bid.expiration) {
            this.setState({ expiration: this._defaultExpiration() });
        }
    }

    _defaultExpiration() {
        return this.props.bid?.expiration || new Date();
    }

    _handleExpirationChange(value) {
        const expiration = value ? value.toDate() : this._defaultExpiration();
        const disableSubmitButton = !expiration || expiration <= new Date(this.props.bid.expiration) || expiration >= new Date(this.props.auction.deadline);
        this.setState({
            expiration,
            disableSubmitButton
        });
    }

    _resetState() {
        this.setState({ 
            showPopup: false,
            expiration: this._defaultExpiration() 
        });
    }

    _onTriggerClick(event) {
        event.stopPropagation();
        this.setState({ showPopup: true });
    }

    _onClose(event) {
        this._resetState();
        this.props.onCancelExtendOffer();
        if (event) {
            event.stopPropagation();
        }
    }

    _onExtendOffer() {
        this.props.onExtendOffer(moment(this.state.expiration).startOf('minute').toDate());
        this._onClose();
    }

    render() {
        const { showPopup, disableSubmitButton } = this.state;
        const { bid, auction } = this.props;

        return (
            <div className="extend-offer-popup-container">
                <Popup
                    id="popup-extend-offer"
                    show={ showPopup } 
                    onClose={ this._onClose }
                    trigger={ 
                         <a href="#popup-extend-offer" 
                            className="dropdown-item"
                            onClick={ this._onTriggerClick }>
                            <small className="action-name">Extend Offer Time</small>
                        </a>
                    }
                >
                    <div className="extend-offer-popup">
                        <p className="heading">Extend Offer Time</p>
                        <p className="description">
                            Choose a new offer expiration time. Offers can only be extended in the future
                            up until the auction deadline. Current offer will expire on 
                            <span className="expiration">{` ${ DateUtils.format(new Date(bid.expiration)) }.`}</span>
                        </p>

                        <div className="end-date-picker">
                            <small className="label">Extend up to</small>
                            <CustomDateTimePicker
                                className="date-picker"
                                date={ moment(this.state.expiration) }
                                minDate={ moment(bid.expiration) }
                                maxDate={ moment(auction.deadline) }
                                handleChange={ this._handleExpirationChange }
                            />
                        </div>

                        <div className="buttons-wrapper">
                            <Button 
                                type="tertiary" 
                                size="small"
                                onClick={ this._onClose }
                            >
                                Cancel
                            </Button>
                            <Button 
                                type="primary"  
                                size="small"
                                onClick={ this._onExtendOffer } 
                                disabled={ disableSubmitButton }
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
