import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';

import './PageHeader.scss';

export default class PageHeader extends Component {

    static propTypes = {
        title: PropTypes.any.isRequired,
        tooltip: PropTypes.node
    };

    render() {
        let tooltip = null;
        if (this.props.tooltip) {
            tooltip = (
                <div className="page-title-tooltip-container">
                    <FontAwesomeIcon icon={ info } className="page-title-tooltip-icon" />
                    <Tooltip direction="right" align="left-align">{ this.props.tooltip }</Tooltip>
                </div>
            );
        }

        return (
            <div className="page-header-container">
                <div className="page-title-container">
                    <h5 className="page-title">{ this.props.title }</h5>
                    { tooltip }
                </div>
                { this.props.children }
            </div>
        )
    }
}
