import { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as deleteIcon, faUserAlt as user } from '@fortawesome/pro-solid-svg-icons';

import { TERMINAL_RFP_AUCTION_STATUSES, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, RG_DETAILS_VIEW_MODE } from 'common/constants';
import Search from 'component/filter/Search';
import RFPAddCarrier from 'component/rfp/RFPAddCarrier';
import Pagination from 'component/navigation/Pagination';
import ContentCard from 'component/card/ContentCard';
import Table from 'component/table/simple/Table';
import TableRow from 'component/table/simple/TableRow';
import TableColumn from 'component/table/simple/TableColumn';
import RFPCSVUpload from 'component/rfp/RFPCSVUpload';
import Popup from 'component/Popup';
import Button from 'component/Button';
import RFPUtils from 'utils/RFPUtils';
import RoutingGuideUtils from 'utils/RoutingGuideUtils';
import ObjectUtils from 'utils/ObjectUtils';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';
import RoutingGuideApiService from 'service/api/RoutingGuideApiService';

import './RFPCarrierList.scss';

class RFPCarrierList extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        viewMode: PropTypes.string,
        onUpdated: PropTypes.func
    };

    static defaultProps = {
        viewMode: null,
        onUpdated: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onPageNumberChange = this._onPageNumberChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
        this._fetchCarriers = this._fetchCarriers.bind(this);
        this._onCloseRemoveCarrierPopup = this._onCloseRemoveCarrierPopup.bind(this);
        this._onDelete = this._onDelete.bind(this);
    }

    state = {
        deletePopupShown: false,
        data: [],
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        available: 0,
        loading: true,
        showRemoveCarrierPopup: false,
        removeCarrierId: null
    };

    componentDidMount() {
        this._fetchCarriers();
    }

    componentDidUpdate(prevProps) {
        const oldSearchParam = this._getSearchFromUrl(prevProps);
        const newSearchParam = this._getSearchFromUrl(this.props);

        if (!ObjectUtils.equal(this.props.rfp, prevProps.rfp) || oldSearchParam !== newSearchParam || prevProps.viewMode !== this.props.viewMode) {
            this.setState({ pageNumber: DEFAULT_PAGE_NUMBER }, this._fetchCarriers);
        }

        const searchParams = new URLSearchParams(this.props.location.search);
        const mode = searchParams.get('mode');

        if (RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === this.props.viewMode && !mode) {
            searchParams.append('mode', 'read_only');
            this.props.history.replace({
                pathname: window.location.pathname,
                search: `?${ searchParams.toString() }`
            });
        }
    }

    _getSearchFromUrl(props) {
        const searchParams = new URLSearchParams(props.location.search);
        return searchParams.get('carrier_name');
    }

    _onPageNumberChange(pageNumber) {
        this.setState({ pageNumber }, this._fetchCarriers);
    }

    _onPageSizeChange(pageSize) {
        this.setState({ pageSize }, this._fetchCarriers);
    }

    _fetchCarriers() {
        this.setState({ loading: true });

        let candidatesApiPromise;
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            candidatesApiPromise = RFPAuctionApiService
                .getCandidates(this.props.rfp.id, this._getSearchFromUrl(this.props), this.state.pageNumber, this.state.pageSize);
        } else {
            candidatesApiPromise = RoutingGuideApiService
                .getCandidates(this.props.rfp.id, this._getSearchFromUrl(this.props), this.state.pageNumber, this.state.pageSize);
        }

        candidatesApiPromise
            .then(data => this.setState({
                ...data,
                loading: false
            }))
            .then(this.props.onUpdated);
    }

    _onDelete() {
        RFPAuctionApiService.removeCandidate(this.props.rfp.id, this.state.removeCarrierId)
            .then(this._onCloseRemoveCarrierPopup)
            .then(this._fetchCarriers);
    }

    _onOpenRemoveCarrierPopup(carrierId) {
        this.setState({ showRemoveCarrierPopup: true, removeCarrierId: carrierId });
    }

    _onCloseRemoveCarrierPopup() {
        this.setState({ showRemoveCarrierPopup: false });
    }

    _formRemoveCarrierConfirmation(candidate) {
        return (
            <Popup
                id={ `remove-carrier-popup-${ candidate.id }` }
                closeButton={ false }
                show={ this.state.showRemoveCarrierPopup && this.state.removeCarrierId === candidate.id }
                onClose={ this._onCloseRemoveCarrierPopup }
                trigger={
                    <a href="#remove-carrier-popup">
                        <FontAwesomeIcon
                            id={ `rfp-carrier-delete-button-${ candidate.id }` }
                            icon={ deleteIcon }
                            className="delete-candidate-action"
                            onClick={ () => this._onOpenRemoveCarrierPopup(candidate.id) }
                        />
                    </a>
                }
            >
                <div className="remove-carrier-popup">
                    <p className="heading">Remove Carrier</p>
                    <p className="description">Are you sure you want to remove <b>{ candidate.carrier.name }</b> from <b>{ this.props.rfp.name }</b>? All of their interaction with this RFP will be lost.</p>
                    <div className="buttons-wrapper">
                        <Button
                            type="tertiary"
                            size="small"
                            onClick={ this._onCloseRemoveCarrierPopup }
                        >
                            Cancel
                        </Button>

                        <Button
                            type="danger"
                            size="small"
                            onClick={ this._onDelete }
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        const isConfirmationLatest = RFPUtils.isConfirmationLatest(this.props.rfp);

        const searchFilter = (
            <Search 
                placeholder="Search by Carrier name..." 
                label="Search" 
                fieldName="carrier_name"
                allowClear={ true } 
                replace={ false } 
            />
        );

        let heading, content, actions;

        if (this.state.data.length === 0 && !this.state.loading && this.state.pageNumber === 1) {
            const searchResultsDisplayed = !!this._getSearchFromUrl(this.props);
            const message = searchResultsDisplayed ? 'No results matched your search.' : 'There are currently no carriers added.';

            heading = searchResultsDisplayed ? <>List of Carriers</> : null;
            content = (
                <>
                    { searchResultsDisplayed && searchFilter }
                    <div className="empty-list">
                        <FontAwesomeIcon className="user-icon" icon={ user } />
                        <h5>{ message }</h5>

                        { !isConfirmationLatest && !searchResultsDisplayed && !TERMINAL_RFP_AUCTION_STATUSES.includes(this.props.rfp.status) &&
                            <>
                                <p>Please add carriers in order to continue with RFP.</p>

                                <div className="carrier-add-actions">
                                    <RFPAddCarrier rfpId={ this.props.rfp.id }
                                                    onCreated={ this._fetchCarriers }/>

                                    <RFPCSVUpload rfp={ this.props.rfp}
                                                    type="candidate"
                                                    onCreated={ this._fetchCarriers }/>
                                </div>
                            </>
                        }
                    </div>
                </>
            );
        } else {
            heading = <>List of Carriers</>;
            actions = !isConfirmationLatest && !TERMINAL_RFP_AUCTION_STATUSES.includes(this.props.rfp.status) && (
                <div className="carrier-add-actions">
                    <RFPAddCarrier rfpId={ this.props.rfp.id }
                                   size="small"
                                   onCreated={ this._fetchCarriers } />
    
                    <RFPCSVUpload rfp={ this.props.rfp }
                                  type="candidate"
                                  size="small"
                                  onCreated={ this._fetchCarriers } />
                </div>
            );

            content = (
                <>
                    { searchFilter }
                    <Table>
                        <TableRow header={ true }>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Email</TableColumn>
                            <TableColumn>Phone</TableColumn>
                            { !TERMINAL_RFP_AUCTION_STATUSES.includes(this.props.rfp.status) && <TableColumn align="right">Actions</TableColumn> }
                        </TableRow>

                        { this.state.data.map(candidate => {
                            const carrier = candidate.carrier ? candidate.carrier : candidate;

                            return (
                                <TableRow key={ carrier.id }>
                                    <TableColumn id={ `rfp-carrier-name-${ carrier.id }` } className="rfp-candidate-name">
                                        <Link to={ `/carrier/${ carrier.id }` } className="link" title={ carrier.name }>
                                            { carrier.name }
                                        </Link>
                                    </TableColumn>
                                    <TableColumn id={ `rfp-carrier-email-${ carrier.id }` }>
                                        { carrier.email }
                                    </TableColumn>
                                    <TableColumn id={ `rfp-carrier-phone-${ carrier.id }` }>
                                        { (carrier.profileInformation || carrier).phone || '-' }
                                    </TableColumn>
                                    { !TERMINAL_RFP_AUCTION_STATUSES.includes(this.props.rfp.status) && 
                                        <TableColumn id={ `rfp-carrier-delete-${ carrier.id }` } align="right">
                                            { this._formRemoveCarrierConfirmation(candidate) }
                                        </TableColumn>
                                    }
                                </TableRow>
                            );
                        }) }
                    </Table>

                    <Pagination pageSize={ this.state.pageSize }
                                pageNumber={ this.state.pageNumber }
                                available={ this.state.available }
                                onSetPage={ this._onPageNumberChange }
                                onSetPageSize={ this._onPageSizeChange }
                                fixed={ false }
                                colorTheme="light" />
                </>
            );
        }

        return (
            <div className="rfp-candidate-list-container">
                <ContentCard heading={ heading } actions={ actions }>
                    { content }
                </ContentCard>
            </div>
        );
    }
}

export default withRouter(RFPCarrierList);
