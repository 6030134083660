import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt as fileIcon } from '@fortawesome/pro-light-svg-icons';
import { faDownload as downloadIcon } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import DownloadFileComponent from 'component/DownloadFileComponent';
import Popup from 'component/Popup';
import FormStatusModal from 'component/form/FormStatusModal';
import HelpCenterApiService from 'service/api/HelpCenterApiService';

import './DownloadPDFCard.scss';

export default class DownloadPDFCard extends Component {
    static propTypes = {
        fileTitle: PropTypes.string.isRequired,
        fileId: PropTypes.string.isRequired
    }

    state = {
        loading: false,
        failed: false
    }
    
    constructor(props) {
        super(props);

        this._onDownloadStart = this._onDownloadStart.bind(this);
        this._onDownloadFinish = this._onDownloadFinish.bind(this);
        this._onDownloadFailed = this._onDownloadFailed.bind(this);
        this._onCancelDownloadFailedPopup = this._onCancelDownloadFailedPopup.bind(this);
    }

    _onDownloadStart() {
        this.setState({
            loading: true
        });
    }

    _onDownloadFinish() {
        this.setState({
            loading: false,
        });
    }

    _onDownloadFailed() {
        this.setState({
            failed: true
        });
    }

    _onCancelDownloadFailedPopup() {
        this.setState({ 
            failed: false
        }); 
    }

    render() {
        let popupContent = <></>;
        if (this.state.failed) {
            popupContent = (
                <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCancelDownloadFailedPopup }>
                    <h6>Failed to download manual</h6>
                </FormStatusModal>
            );
        } else {
            popupContent = ( 
                <FormStatusModal status={ FORM_STATUSES.LOADING }>
                    <h6 className="heading">Downloading { this.props.fileTitle } manual</h6>
                    <p className="description">Please wait...</p>
                </FormStatusModal> 
            );   
        }

        return (
            <Popup
                show={ this.state.loading || this.state.failed }
                id="download-manual-loading-popup"
                trigger={
                    <DownloadFileComponent
                        reference={ HelpCenterApiService.getDownloadPDFManualURL(this.props.fileId) }
                        onDownload={ this._onDownloadFinish }
                        onDownloadFailed={ this._onDownloadFailed }
                        trigger={
                            <div className="download-pdf-card" onClick={ this._onDownloadStart }>
                                <FontAwesomeIcon icon={ fileIcon } className="pdf-file-icon" />
                                <div className="download-pdf-info">
                                    USER MANUAL
                                    <strong className="download-pdf-title"> 
                                        <b>{ this.props.fileTitle }</b>
                                    </strong>
                                </div> 
                                <div className="download-pdf-option">
                                    <small>
                                        <b>Download PDF</b>
                                    </small>
                                    <FontAwesomeIcon className="download-pdf-icon" icon={ downloadIcon } />  
                                </div>
                            </div>
                        } 
                    />
                }>
                <div className="downloading-manual-popup">
                    { popupContent }
                </div>
            </Popup>
        );
    }
}
