import RestService from 'service/RestService';

/**
  * Service class that provides static methods and endpoints for API calls regarding users
  */
export default class UserApiService {
    /**
     * Checks if user with the provided email is active and has any of the provided roles
     * 
     * @param {string} userEmail email of the user in question
     * @param {string} roles eligible roles
     * @returns {Promise}
     */
    static isRoleActive(userEmail, roles) {
        return RestService.instance().get('user/role-active', { userEmail, roles });
    }
  
    /**
     * Checks if user with the provided email already exists
     * 
     * @param {string} userEmail email of the user in question
     * @param {string} carrierId id of carrier to which user should exist
     * @returns {Promise}
     */
    static isEmailUsed(userEmail, carrierId) {
      return RestService.instance().get('user/email-used', { userEmail, carrierId });
    }

    /**
     * Marks user as active 
     * 
     * @param {string} userId id of user to be reactivated
     * @returns {Promise}
    */
    static activate(userId) {
      return RestService.instance().put(`user/${ userId }/activate`);
    }

    /**
     * Update information for user with forwarded id
     * 
     * @param {string} userId
     * @param {object} changes
     * @returns {Promise}
     */
    static update(userId, changes) {
      return RestService.instance().put(`user/${ userId }`, changes);
    }

    /**
     * Lists paginated users with applied search and role filter
     * 
     * @param {object} params filtering parameters
     * @returns {Promise}
     */
    static list(params) {
        return RestService.instance().get('user', params);
    }
}
