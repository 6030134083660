import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding carrier activity logs
 */
export default class CarrierActivityLogApiService {

    /**
     * Gets paginated carrier activity log
     * 
     * @param carrierId id of carrier for which activities are fetched
     * @param props object which hold information about request props pageNumber, pageSize and createdUntil
     * @returns {Promise}
     */
    static filter(carrierId, props) {
        return RestService.instance().post(`carrier/${ carrierId }/activity-log`, props);
    }
}
