import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { RFP_BID_STATUSES, RFP_AUCTION_STATUSES, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, TERMINAL_RFP_AUCTION_STATUSES, ONGOING_RFP_AUCTION_STATUSES } from 'common/constants';
import Banner from 'component/Banner';
import SafeStateComponent from 'component/SafeStateComponent';
import RFPLaneHeaderCard from 'component/card/rfp/RFPLaneHeaderCard';
import RFPRoundOverviewCard from 'component/card/rfp/RFPRoundOverviewCard';
import ContentCard from 'component/card/ContentCard';
import Table from 'component/table/simple/Table';
import TableRow from 'component/table/simple/TableRow';
import TableColumn from 'component/table/simple/TableColumn';
import Pagination from 'component/navigation/Pagination';
import RFPAwardBid from 'component/rfp/RFPAwardBid';
import Tooltip from 'component/Tooltip';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import RFPUtils from 'utils/RFPUtils';
import NumberUtils from 'utils/NumberUtils';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';

import './RFPLaneDetails.scss';

class RFPLaneDetails extends SafeStateComponent {

    constructor(props) {
        super(props);

        this.state = {
            rfpLane: undefined,
            bids: {
                data: [],
                pageNumber: DEFAULT_PAGE_NUMBER,
                pageSize: DEFAULT_PAGE_SIZE,
                available: 0
            },
            loading: {
                lane: true,
                bids: true
            }
        };

        this._fetchLane = this._fetchLane.bind(this);
        this._fetchBids = this._fetchBids.bind(this);
        this._onAward = this._onAward.bind(this);
        this._onPageNumberChange = this._onPageNumberChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
    }

    static propTypes = {
        rfpId: PropTypes.string.isRequired,
        laneId: PropTypes.string.isRequired,
        account: PropTypes.object.isRequired
    }

    componentDidMount() {
        super.componentDidMount();
        this._fetchLane();
        this._fetchBids();
    }

    _fetchLane() {
        this.setState(prev => ({ loading: { lane: true, bids: prev.loading.bids } }));
        RFPAuctionApiService.getLane(this.props.rfpId, this.props.laneId )
            .then(rfpLane => this.setState(prev => ({
                rfpLane,
                loading: { lane: false, bids: prev.loading.bids }
            })))
            .catch(error => {
                console.error('An error occurred while fetching lane data.', error);
                this.props.history.replace('/404');
            });
    }

    _fetchBids() {
        this.setState(prev => ({ loading: { lane: prev.loading.lane, bids: true } }));

        RFPAuctionApiService
                .getBids(this.props.rfpId, this.props.laneId, this.state.bids.pageSize, this.state.bids.pageNumber)
                .then(bids => this.setState(prev => ({
                    bids,
                    loading: { lane: prev.loading.lane, bids: false }
                })));
    }

    _onPageNumberChange(pageNumber) {
        this.setState(prev => ({ bids: { ...prev.bids, pageNumber } }), this._fetchBids);
    }

    _onPageSizeChange(pageSize) {
        this.setState(prev => ({ bids: { ...prev.bids, pageSize } }), this._fetchBids);
    }

    _isTerminal() {
        return TERMINAL_RFP_AUCTION_STATUSES.includes(this.state.rfpLane.auction.status);
    }

    _bidStatus(bid) {
        const isConfirmationReview = RFPUtils.isConfirmationReview(this.state.rfpLane.auction);
        const isTerminal = this._isTerminal();

        switch (bid.status) {
            case RFP_BID_STATUSES.ACCEPTED:
                return (<span className="accepted">Accepted</span>);
            case RFP_BID_STATUSES.REJECTED:
                return (
                    <span className="rejected">
                        Rejected
                        { bid.rejectReason &&
                            <span className="reject-reason-tooltip-container">
                                <FontAwesomeIcon icon={ info } className="tooltip-icon" />
                                <Tooltip direction="bottom" align="left-align">{ bid.rejectReason.reason }</Tooltip>
                            </span>
                        }
                    </span>
                );
            default:
                return (
                    <span className={ isConfirmationReview || isTerminal ? 'expired' : 'proposed' }>
                        { isConfirmationReview || isTerminal ? 'Expired' : 'Waiting for Response' }
                    </span>
                );
        }
    }

    _laneName() {
        const origin = `${ this.state.rfpLane.lane.origin.city }, ${ this.state.rfpLane.lane.origin.state }`;
        const destination = `${ this.state.rfpLane.lane.destination.city }, ${ this.state.rfpLane.lane.destination.state }`;

        return `${ origin } -> ${ destination }`;
    }

    _maxAwardedBidVolume(bid, isConfirmationLatest) {
        if (isConfirmationLatest && RFP_BID_STATUSES.ACCEPTED !== bid.status) {
            return this.state.rfpLane.volume - this.state.rfpLane.awardedVolume;
        }

        return this.state.rfpLane.volume - this.state.rfpLane.awardedVolume + bid.awardedVolume;
    }

    _onAward() {
        this._fetchLane();
        this._fetchBids();
    }

    render() {
        if (this.state.loading.lane || this.state.loading.bids) {
            return (<>Loading...</>);
        }

        let actionsDisabledInfo = <></>;
        if (RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.state.rfpLane.auction.status) {
            actionsDisabledInfo = (
                <Banner content="Awarding lanes is disabled until the bidding closes." />
            );
        } else if (RFP_AUCTION_STATUSES.IN_REVIEW === this.state.rfpLane.auction.status) {
            actionsDisabledInfo = (
                <Banner content={ `${ this.state.rfpLane.volume - this.state.rfpLane.awardedVolume } volume left to be awarded.` } />
            );
        }

        let bidsTableContent;

        if (this.state.bids.data.length === 0) {
            bidsTableContent = (
                <div className="empty-list">
                    <h5>Currently, there are no bids on this lane.</h5>
                    <p>All bids will be loaded on this screen after the carriers start to bid.</p>
                </div>
            );
        } else {
            const isTerminal = this._isTerminal();
            const isReview = RFPUtils.isReview(this.state.rfpLane.auction);
            const isConfirmationLatest = RFPUtils.isConfirmationLatest(this.state.rfpLane.auction);
            const isConfirmationReview = RFPUtils.isConfirmationReview(this.state.rfpLane.auction);

            bidsTableContent = (
                <>
                    <Table className="rfp-lane-bids-table">
                        <TableRow header={ true }>
                            { (isTerminal || isConfirmationReview) && <TableColumn align="right" className="rfp-lane-bids-rank">Rank</TableColumn> }
                            <TableColumn>Carrier Name</TableColumn>
                            <TableColumn>Submitted in</TableColumn>
                            <TableColumn align="right">Per Mile</TableColumn>
                            <TableColumn align="right">Per Load</TableColumn>
                            <TableColumn align="right">Bid Vol.</TableColumn>
                            <TableColumn align="right">Awarded Vol.</TableColumn>
                            { (isTerminal || isConfirmationLatest) && <TableColumn>Status</TableColumn> }
                            <TableColumn align="right">Actions</TableColumn>
                        </TableRow>

                        { this.state.bids.data.map(bid => (
                            <TableRow key={ bid.id }>
                                { (isTerminal || isConfirmationReview) && <TableColumn align="right" className="rfp-lane-bids-rank">{ bid.rank || '/' }</TableColumn> }
                                <TableColumn className="rfp-lane-bids-candidate-name">
                                    <Link to={ `/carrier/${ bid.candidate.carrier.id }` } className="link" title={ bid.candidate.carrier.name }>
                                        { bid.candidate.carrier.name }
                                    </Link>
                                </TableColumn>
                                <TableColumn>Round #{ bid.round.ordinal + 1 }</TableColumn>
                                <TableColumn align="right">${ NumberUtils.formatWithDecimalNotation(bid.price) }</TableColumn>
                                <TableColumn align="right">${ NumberUtils.formatWithDecimalNotation(bid.price * this.state.rfpLane.distance) }</TableColumn>
                                <TableColumn align="right">{ bid.volume }/{ this.state.rfpLane.volume }</TableColumn>
                                <TableColumn align="right">{ bid.awardedVolume }/{ this.state.rfpLane.volume }</TableColumn>
                                { (isTerminal || isConfirmationLatest) && <TableColumn className="rfp-lane-bids-status">{ this._bidStatus(bid) }</TableColumn> }
                                <TableColumn align="right">
                                    <RFPAwardBid bid={ bid }
                                                 numberOfAcceptedBids={ this.state.rfpLane.participation.acceptedBids }
                                                 maxAwardedVolume={ this._maxAwardedBidVolume(bid, isConfirmationLatest) }
                                                 isConfirmationLatest={ isConfirmationLatest }
                                                 disabled={ !isReview }
                                                 onAward={ this._onAward } />
                                </TableColumn>
                            </TableRow>
                        )) }
                    </Table>

                    <Pagination pageSize={ this.state.bids.pageSize }
                                pageNumber={ this.state.bids.pageNumber }
                                available={ this.state.bids.available }
                                onSetPage={ this._onPageNumberChange }
                                onSetPageSize={ this._onPageSizeChange }
                                colorTheme="light"
                                fixed={ false } />
                </>
            );
        }

        let mode = '';
        if ('read_only' === (new URLSearchParams(this.props.location.search)).get('mode')) {
            mode = `?mode=read_only`;
        }

        return (
            <div className="page rfp-lane-details-page">
                <Breadcrumbs crumbs={ [
                    { path: '/rfp/list', name: 'Routing Guides', backCrumb: false },
                    { path: `/rfp/${ this.props.rfpId }${ mode }`, name: this.state.rfpLane.auction.name, backCrumb: false },
                    { path: '', name: this._laneName(), backCrumb: false },
                    { path: `/rfp/${ this.props.rfpId }${ mode }`, name: `Back to ${ this.state.rfpLane.auction.name }`, backCrumb: true }
                ] } />

                <RFPLaneHeaderCard rfp={ this.state.rfpLane.auction } rfpLane={ this.state.rfpLane } account={ this.props.account } />
                { ONGOING_RFP_AUCTION_STATUSES.includes(this.state.rfpLane.auction.status) && 
                    <RFPRoundOverviewCard rfp={ this.state.rfpLane.auction } account={ this.props.account } />
                }

                <ContentCard heading="Bidding List" info={ actionsDisabledInfo }>
                    { bidsTableContent }
                </ContentCard>
            </div>
        );
    }
}

function RoutedRFPLaneDetails({ children, ...props }) {
    const parsedProps = {
        ...props,
        rfpId: props.match.params.id,
        laneId: props.match.params.laneId,
    };

    return (<RFPLaneDetails { ...parsedProps } />);
}

export default withRouter(RoutedRFPLaneDetails);
