import { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    faPen as edit,
    faTimes as remove
} from '@fortawesome/pro-solid-svg-icons';

import Button from 'component/Button';
import Popup from 'component/Popup';
import Banner from 'component/Banner';
import CustomTextInput from 'component/form/CustomTextInput';
import DateUtils from 'utils/DateUtils';

import './CoordinatorOverviewCard.scss';

export default class CoordinatorOverviewCard extends Component {

    static propTypes = {
        coordinator: PropTypes.object,
        showActions: PropTypes.bool,
        showSubmittedInfo: PropTypes.bool,
        onAdd: PropTypes.func,
        onStartEditing: PropTypes.func,
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        onCancel: PropTypes.func,
        contentProvider: PropTypes.func
    }

    static defaultProps = {
        coordinator: null,
        showActions: true,
        showSubmittedInfo: true,
        onAdd: () => { /* */ },
        onStartEditing: () => { /* */ },
        onEdit: () => { /* */ },
        onRemove: () => { /* */ },
        onCancel: () => { /* */ },
        contentProvider: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onAdd = this._onAdd.bind(this);
        this._startEditing = this._startEditing.bind(this);
        this._onEdit = this._onEdit.bind(this);
        this._onRemove = this._onRemove.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onInputValueChange = this._onInputValueChange.bind(this);
        this._showRemoveConfirmationPopup = this._showRemoveConfirmationPopup.bind(this);
        this._closeRemoveConfirmationPopup = this._closeRemoveConfirmationPopup.bind(this);
        this._showEditConfirmationPopup = this._showEditConfirmationPopup.bind(this);
        this._closeEditConfirmationPopup = this._closeEditConfirmationPopup.bind(this);
    }

    static _defaultFormContent = {
        email: '',
        name: '',
        phone: ''
    }

    state = {
        formContent: CoordinatorOverviewCard._defaultFormContent,
        editing: false,
        showRemoveConfirmationPopup: false,
        showEditConfirmationPopup: false,
        errorMessage: null
    }

    _onAdd() {
        this.props.onAdd(this.state.formContent)
            .catch(error => this.setState({ errorMessage: error?.response ? error.response.data : 'Something went wrong. Please try again' }));
    }

    _startEditing() {
        const { email, name, phone } = this.props.coordinator;
        this.setState({ formContent: { email, name, phone }, editing: true, errorMessage: null });
        this.props.onStartEditing();
    }

    _onEdit() {
        this.props.onEdit(this.props.coordinator.id, this.state.formContent);
        this._closeEditConfirmationPopup();
        this.setState({ editing: false });
    }

    _onRemove() {
        this.props.onRemove(this.props.coordinator.id);
        this._closeRemoveConfirmationPopup();
    }

    _onCancel() {
        this.props.onCancel();
        this.setState({ formContent: CoordinatorOverviewCard._defaultFormContent, editing: false, errorMessage: null });
    }

    _onInputValueChange(event) {
        const value = event.target.value;
        const fieldName = event.target.name;
        if (fieldName) {
            this.setState(prevState => {
                return {
                    formContent: {
                        ...prevState.formContent,
                        [fieldName]: value
                    },
                    errorMessage: fieldName === 'email' && prevState.errorMessage ? null : prevState.errorMessage
                }
            });
        }
    }

    _isFormValid() {
        const changedWithEdit = this.state.editing && (
            this.state.formContent.name.trim() !== this.props.coordinator.name ||
            this.state.formContent.phone.trim() !== this.props.coordinator.phone
        );
        
        return Object.values(this.state.formContent).every(field => field.trim() !== '') && (!this.state.editing || changedWithEdit) && !this.state.errorMessage;
    }

    _showRemoveConfirmationPopup() {
        this.setState({ showRemoveConfirmationPopup: true });
    }

    _closeRemoveConfirmationPopup() {
        this.setState({ showRemoveConfirmationPopup: false });
    }

    _showEditConfirmationPopup() {
        this.setState({ showEditConfirmationPopup: true });
    }

    _closeEditConfirmationPopup() {
        this.setState({ showEditConfirmationPopup: false });
    }

    _formPreviewCardContent() {
        const { name, phone, email, submittedBy, submittedAt } = this.props.coordinator;

        let actions = <></>;
        if (this.props.showActions) {
            actions = (
                <div className="field actions">
                    <Button className="top-button" rightIcon={ edit } size="small" type="tertiary" onClick={ this._startEditing } />
                    { this._formRemoveConfirmationPopup() }
                </div>
            );
        }

        let submittedInfo = <></>;
        if (this.props.showSubmittedInfo && submittedBy) {
            submittedInfo = (
                <>
                    <div className="field submitted-by">
                        <small className="title">
                            Submitted by
                        </small>
                        <p className="content" title={ submittedBy.name }>
                            { submittedBy.name }
                        </p>
                    </div>
                    <div className="field submitted-at">
                        <small className="title">
                            Submitted at
                        </small>
                        <p className="content" title={ DateUtils.format(submittedAt) }>
                            { DateUtils.format(submittedAt) }
                        </p>
                    </div>
                </>
            );
        }

        return (
            <div className="component coordinator-overview">
                <div className="field name">
                    <small className="title">
                        Full Name
                    </small>
                    <p className="content" title={ name }>
                        { name }
                    </p>
                </div>
                <div className="field phone">
                    <small className="title">
                        Phone number
                    </small>
                    <p className="content" title={ phone }>
                        { phone }
                    </p>
                </div>
                <div className="field email">
                    <small className="title">
                        Email
                    </small>
                    <p className="content" title={ email }>
                        { email }
                    </p>
                </div>
                { submittedInfo }
                { actions }
            </div>
        );
    }

    _formInputCardContent() {
        const { mainLabel } = this.props.contentProvider();

        let infoDiv = <></>;
        let submitButton = (
            <Button disabled={ !this._isFormValid() } onClick={ this._onAdd }>
                Add { mainLabel }
            </Button>
        );

        if (this.state.editing) {
            const { editInfoText } = this.props.contentProvider(this.props.coordinator);
            infoDiv = <Banner content={ editInfoText } />;
            submitButton = this._formEditConfirmationPopup();
        } else if (this.state.errorMessage) {
            infoDiv = <Banner type="error" content={ this.state.errorMessage } />;
        }

        return (
            <form className="coordinator-input-form">
                <strong className="form-title">
                    { this.state.editing ? `Edit ${ mainLabel }` : `Add a New ${ mainLabel }` }
                </strong>
                <div className="input-fields">
                    <CustomTextInput 
                        label="Full Name" 
                        placeholder="Enter full name..."
                        required={ true }
                        fieldName="name" 
                        value={ this.state.formContent.name }
                        onValueChange={ this._onInputValueChange }
                    />
                    <CustomTextInput 
                        label="Phone number" 
                        placeholder="Enter phone number..."
                        required={ true } 
                        value={ this.state.formContent.phone }
                        fieldName="phone" 
                        onValueChange={ this._onInputValueChange }
                    />
                    <CustomTextInput 
                        label="Email"  
                        placeholder="Enter email..."
                        required={ true } 
                        value={ this.state.formContent.email }
                        fieldName="email"
                        disabled={ this.state.editing }
                        onValueChange={ this._onInputValueChange }
                    />
                </div>
                <div className="input-field actions">
                    { infoDiv }
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onCancel }>
                            Cancel
                        </Button>
                        { submitButton }
                    </div>
                </div>
            </form>
        );
    }

    _formEditConfirmationPopup() {
        const { editPopupHeading, editPopupDescription } = this.props.contentProvider(this.props.coordinator);

        return (
            <div className="remove-coordinator">
                <Popup
                    id="popup-remove-coordinator"
                    show={ this.state.showEditConfirmationPopup }
                    onClose={ this._closeEditConfirmationPopup }
                    trigger={ <Button disabled={ !this._isFormValid() } onClick={ this._showEditConfirmationPopup }>Submit Changes</Button> }
                >
                    <div className="remove-coordinator-popup">
                        { editPopupHeading }
                        { editPopupDescription }
                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._closeEditConfirmationPopup } >
                                Cancel
                            </Button>

                            <Button size="small" onClick={ this._onEdit }>
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }

    _formRemoveConfirmationPopup() {
        const { removePopupHeading, removePopupDescription } = this.props.contentProvider(this.props.coordinator);

        return (
            <div className="remove-coordinator">
                <Popup
                    id="popup-remove-coordinator"
                    show={ this.state.showRemoveConfirmationPopup }
                    onClose={ this._closeRemoveConfirmationPopup }
                    trigger={ <Button rightIcon={ remove }  size="small" type="tertiary" onClick={ this._showRemoveConfirmationPopup } /> }
                >
                    <div className="remove-coordinator-popup">
                        { removePopupHeading }
                        { removePopupDescription }
                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._closeRemoveConfirmationPopup } >
                                Cancel
                            </Button>

                            <Button type="danger" size="small" onClick={ this._onRemove }>
                                Remove
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }

    render() {
        let content;

        if (this.props.coordinator && !this.state.editing) {
            content = this._formPreviewCardContent();
        } else {
            content = this._formInputCardContent();
        }

        return (
            <div className="coordinator-overview-card" >
                { content }
            </div>
        );
    }
}
