import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle as regularAddIcon } from '@fortawesome/pro-regular-svg-icons';

import { FORM_STATUSES, TRIGGER_TYPES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomTextInput from 'component/form/CustomTextInput';
import FormStatusModal from 'component/form/FormStatusModal';
import StringUtils from 'utils/StringUtils';

/**
 * This component can be used whenever there is a need to add a carrier using their business id.
 */
export default class AddCarrier extends Component {

    static propTypes = {
        buttonSize: PropTypes.oneOf(['small', 'regular']),
        trigger: PropTypes.oneOf([TRIGGER_TYPES.BUTTON, TRIGGER_TYPES.LABEL]),
        successElement: PropTypes.element,
        confirmationElement: PropTypes.element,
        popupTitle: PropTypes.string,
        buttonText: PropTypes.string,
        buttonType: PropTypes.string,
        submitButtonText: PropTypes.string,
        addCarrier: PropTypes.func.isRequired,
        retryAddCarrier: PropTypes.func,
        onCreated: PropTypes.func,
        onValueChanged: PropTypes.func,
        setShouldUpdate: PropTypes.func
    };

    static defaultProps = {
        buttonSize: 'regular',
        trigger: TRIGGER_TYPES.BUTTON,
        successElement: <></>,
        confirmationElement: <></>,
        popupTitle: 'Add Carrier',
        buttonText: 'Add Carrier',
        buttonType: 'tertiary',
        submitButtonText: 'Create',
        retryAddCarrier: () => { /* */ },
        onCreated: () => { /* */ },
        onValueChanged: () => { /* */ },
        setShouldUpdate: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onCreateCarrier = this._onCreateCarrier.bind(this);
        this._showPopup = this._showPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onCarrierValueChanged = this._onCarrierValueChanged.bind(this);
    }

    state = {
        showPopup: false,
        carrierBusinessId: undefined,
        status: undefined,
        errorMessage: undefined
    };

    _onCreateCarrier() {
        const promise = FORM_STATUSES.CONFIRMATION !== this.state.status ? this.props.addCarrier(this.state.carrierBusinessId) : this.props.retryAddCarrier(this.state.carrierBusinessId);
        this.setState({ status: FORM_STATUSES.LOADING });
        this.props.setShouldUpdate(false);

        promise
            .then(this._closePopup)
            .then(() => this.setState({ status: FORM_STATUSES.SUCCESS }))
            .catch((error) => {
                if (error.response.status === 409) {
                    this.setState({ 
                        status: FORM_STATUSES.CONFIRMATION,
                    });
                } else {
                    this.setState({ 
                        status: FORM_STATUSES.ERROR,
                        errorMessage: error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data
                    });
                }
            });
    }

    _isAddDisabled() {
        return StringUtils.isBlank(this.state.carrierBusinessId);
    }

    _showPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        if (FORM_STATUSES.LOADING === this.state.status) {
            return;
        }

        this.setState({ showPopup: false, carrierBusinessId: undefined, status: undefined });
        this.props.setShouldUpdate(true);

        if (FORM_STATUSES.SUCCESS === this.state.status) {
            this.props.onCreated();
        }
    }

    _onCarrierValueChanged(e) {
        this.setState({ carrierBusinessId: e.target.value }, () => this.props.onValueChanged(this.state.carrierBusinessId));
    }

    _formTrigger() {
        const { trigger, buttonType, buttonSize, buttonText } = this.props;

        switch (trigger) {
            case TRIGGER_TYPES.BUTTON:
                return ( 
                    <Button 
                        type={ buttonType }
                        size={ buttonSize }
                        leftIcon={ addIcon }
                        onClick={ this._showPopup }
                    >
                        { buttonText }
                    </Button>
                );
            case TRIGGER_TYPES.LABEL:
                return ( 
                    <a href="#add-single-carrier" onClick={ this._showPopup } className="dropdown-item">
                        <FontAwesomeIcon icon={ regularAddIcon } className="action-icon" />
                        <small className="action-name">{ buttonText }</small>
                    </a>
                );
            default:
                console.error(`Unsupported trigger "${ trigger }"`);    
        }
    }

    render() {
        let content;
        if (FORM_STATUSES.LOADING === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status }>
                    <div>
                        <h6>Adding carrier</h6>
                        <p>This will only take a moment...</p>
                    </div>
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.SUCCESS === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status } onContinue={ this._closePopup }>
                    { this.props.successElement }
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.ERROR === this.state.status) {
            content = (
                <FormStatusModal status={ this.state.status } onContinue={ this._closePopup }>
                    <div>
                        <h6>Failed to Add Carrier</h6>
                        <p>{ this.state.errorMessage }</p>
                    </div>
                </FormStatusModal>
            );
        } else if (FORM_STATUSES.CONFIRMATION === this.state.status) {
            content = (
                <div>
                    <p className="heading">Confirm Adding Carrier</p>

                    { this.props.confirmationElement }

                    <div className="buttons-wrapper">
                        <Button size="small" type="tertiary" onClick={ this._closePopup }>Cancel</Button>
                        <Button size="small" type="danger" onClick={ this._onCreateCarrier }>{ this.props.buttonText }</Button>
                    </div>
                </div>
            );
        } else {
            content = (
                <div>
                    <h6 className="heading"><b>{ this.props.popupTitle }</b></h6>
                    
                    <p className="description-section">If the carrier does not already have an account for the application, a new one will be created, and they will be informed via email. This process may take a couple of seconds.</p>

                    <br />
                    <CustomTextInput
                        label="TMW Carrier Code"
                        placeholder="Enter TMW Carrier Code..."
                        required={ true }
                        value={ this.state.carrierBusinessId }
                        fieldName="carrierBusinessId"
                        onValueChange={ this._onCarrierValueChanged }
                    />

                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._closePopup }>Cancel</Button>
                        <Button disabled={ this._isAddDisabled() } onClick={ this._onCreateCarrier }>{ this.props.buttonText }</Button>
                    </div>
                </div>
            );
        }

        return (
            <Popup
                id="add-single-carrier"
                size={ FORM_STATUSES.CONFIRMATION !== this.state.status ? 'medium' : 'small' }
                show={ this.state.showPopup }
                onClose={ this._closePopup }
                trigger={ this._formTrigger() }
            >
                { content }
            </Popup>
        );
    }
}
