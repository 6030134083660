import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faInfoCircle as info,
    faDownload as download,
    faPen as edit,
    faCheckCircle as approve,
    faTimesCircle as reject
} from '@fortawesome/pro-solid-svg-icons';

import CanAccess from 'component/CanAccess';
import Popup from 'component/Popup';
import Tooltip from 'component/Tooltip';
import Button from 'component/Button';
import DownloadFileComponent from 'component/DownloadFileComponent';
import CustomDatePicker from 'component/form/CustomDatePicker';
import CustomSelect from 'component/form/CustomSelect';
import CustomTextArea from 'component/form/CustomTextArea';
import CustomRadioButton from 'component/form/CustomRadioButton';
import DateUtils from 'utils/DateUtils';
import DocumentUtils from 'utils/DocumentUtils';
import { USER_DOCUMENT_STATUSES } from 'common/constants';

import './DocumentCard.scss';

export default class DocumentCard extends Component {

    static propTypes = {
        account: PropTypes.object.isRequired,
        document: PropTypes.object.isRequired,
        availableRejectReasons: PropTypes.array,
        onDocumentApprove: PropTypes.func,
        onDocumentReject: PropTypes.func,
        onDocumentExpirationEdit: PropTypes.func
    }

    static defaultProps = {
        availableRejectReasons: [],
        onDocumentApprove: () => { /* */ },
        onDocumentReject: () => { /* */ },
        onDocumentExpirationEdit: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onExpirationChange = this._onExpirationChange.bind(this);
        this._onReasonSelected = this._onReasonSelected.bind(this);
        this._onNoteInputValueChange = this._onNoteInputValueChange.bind(this);
        this._onRestartOptionChange = this._onRestartOptionChange.bind(this);
        this._onApproveDocument = this._onApproveDocument.bind(this);
        this._onRejectDocument = this._onRejectDocument.bind(this);
        this._onEditDocument = this._onEditDocument.bind(this);
        this._openApprovePopup = this._openApprovePopup.bind(this);
        this._openRejectPopup = this._openRejectPopup.bind(this);
        this._openEditPopup = this._openEditPopup.bind(this);
        this._closeApprovePopup = this._closeApprovePopup.bind(this);
        this._closeRejectPopup = this._closeRejectPopup.bind(this);
        this._closeEditPopup = this._closeEditPopup.bind(this);
    }

    state = {
        showApprovePopup: false,
        showRejectPopup: false,
        showEditPopup: false,
        rejectionData: null,
        status: null,
        expirationDate: null,
        rejectReason: null,
        note: null
    }

    _radioButtonOptions = [ 
        { 
            value: USER_DOCUMENT_STATUSES.APPROVED.value, 
            label: USER_DOCUMENT_STATUSES.APPROVED.label
        },
        { 
            value: USER_DOCUMENT_STATUSES.REJECTED.value, 
            label: USER_DOCUMENT_STATUSES.REJECTED.label,
            disabled: this.props.document.status === USER_DOCUMENT_STATUSES.REJECTED.value || !this.props.document.reference
        }
    ];

    componentDidMount() {
        this._fetchRejectReason();
    }

    componentDidUpdate(prevProps) {
        if (USER_DOCUMENT_STATUSES.REJECTED.value !== prevProps.document.status && USER_DOCUMENT_STATUSES.REJECTED.value === this.props.document.status) {
            this._fetchRejectReason();
        }
    }

    _fetchRejectReason() {
        if (USER_DOCUMENT_STATUSES.REJECTED.value === this.props.document.status) {
            DocumentUtils.fetchRejectionReason(this.props.document.id).then(response => {
                this.setState({ rejectionData: { 
                    reason: response.rejectReason.reason,
                    note: response.note,
                    internal: response.rejectReason.internal 
                }});
            });
        }
    }

    /**
     * Determines the default expiration date for the date picker which is the document expiration
     * in case it is present, otherwise null. If the expiration is present but is in the past,
     * default expiration is set to the current date.
     * 
     * @returns default expiration date
     */
    _getDefaultExpirationDate() {
        let expiration = this.props.document.expiresOn ? moment(this.props.document.expiresOn) : null;
        const now = moment();

        if (expiration && DateUtils.isBefore(expiration, now)) {
            expiration = now;
        }

        return expiration;
    }

    /**
     * Handles the change of document expiration date on the date picker.
     * 
     * @param {moment} value 
     */
    _onExpirationChange(value) {
        const expirationDate = value ? value.endOf('day') : this._getDefaultExpirationDate();
        this.setState({ expirationDate });
    }

    /**
     * Handles the change of the selected reject reason by setting the selected reason to the state variable
     * 
     * @param {Object} event 
     */
    _onReasonSelected(_value, option) {
        this.setState({ rejectReason: JSON.parse(option.key), note: "" });
    }

    /**
     * Handles input value change for the reject reason note text area/
     * 
     * @param {Object} event 
     */
    _onNoteInputValueChange(event) {
        const value = event.target.value;
        this.setState({ note: value });
    }

     /**
     * Handles status radio button change.
     * 
     * @param {Object} value selected status 
     * @private
     */
    _onRestartOptionChange(value) {
        this.setState({ status: value });
    }

    /**
     * Determines if the note is valid.
     * If the note is required, it is valid when it is not blank. Otherwise, note is always valid.
     * 
     * @returns {boolean}
     */
    _isNoteValid() {
        return !this.state.rejectReason.noteRequired || (this.state.note && this.state.note.trim().length !== 0);
    }

    /**
     * Determines if the selected document status is different than the current document status.
     * If so returns true. Otherwise returns false.
     * 
     * @returns {boolean}
     */
    _statusChanged() {
        return this.state.status !== this.props.document.status;
    }

    /**
     * Determines if the selected document expiration date is different than the current documents expiration.
     * If so returns true. Otherwise returns false.
     * 
     * @returns {boolean}
     */
    _expirationChanged() {
        return this.state.expirationDate.toDate().getTime() !== new Date(this.props.document.expiresOn).getTime()
    }

    /**
     * Determines if the edit button on the edit popup should be disabled.
     * It is disabled in case the radio button Approved has been selected but no expiration date was provided
     * or in case the radio button Rejected has been selected and the reason or the note for a reason that requires it wasn't provided.
     * The edit button is also disabled if no change has been made.
     * 
     * @returns {boolean}
     */
    _isEditDisabled() {
        if (USER_DOCUMENT_STATUSES.APPROVED.value === this.state.status && 
            (!this.state.expirationDate || (!this._statusChanged() && !this._expirationChanged()))) {
            return true;
        }

        return USER_DOCUMENT_STATUSES.REJECTED.value === this.state.status && 
            (!this.state.rejectReason || !this._isNoteValid() || !this._statusChanged());
    }
    
    _onApproveDocument() {
        this.props.onDocumentApprove(this.state.expirationDate);
        this._closeApprovePopup();
    }

    _onRejectDocument() {
        this.props.onDocumentReject(this.state.rejectReason, this.state.note);
        this._closeRejectPopup();
    }

    _onEditDocument() {
        const { status, expirationDate, rejectReason, note } = this.state;
        const { document } = this.props;

        if (USER_DOCUMENT_STATUSES.APPROVED.value === status && USER_DOCUMENT_STATUSES.REJECTED.value === document.status) {
            this.props.onDocumentApprove(expirationDate);
        } else if (USER_DOCUMENT_STATUSES.APPROVED.value === status && USER_DOCUMENT_STATUSES.APPROVED.value === document.status) {
            this.props.onDocumentExpirationEdit(expirationDate);
        } else if (USER_DOCUMENT_STATUSES.REJECTED.value === status && USER_DOCUMENT_STATUSES.APPROVED.value === document.status) {
            this.props.onDocumentReject(rejectReason, note);
        }

        this.setState({ showEditPopup: false });
    }

    _openApprovePopup() {
        this.setState({ showApprovePopup: true });
    }

    _openRejectPopup() {
        this.setState({ showRejectPopup: true });
    }

    _openEditPopup() {
        const status = this._radioButtonOptions.find(option => option.value === this.props.document.status);
        this.setState({ showEditPopup: true, status: status.value, expirationDate: this._getDefaultExpirationDate() });
    }

    _closeApprovePopup() {
        this.setState({ showApprovePopup: false, expirationDate: null });
    }

    _closeRejectPopup() {
        this.setState({ showRejectPopup: false, rejectReason: null, note: "" });
    }

    _closeEditPopup() {
        this.setState({ showEditPopup: false, expirationDate: null, rejectReason: null, note: "" });
    }

    _formRejectedReasonTooltipText() {
        let tooltipText = '';
        const canReadAllReasons = DocumentUtils.canReadAllDocumentReasons(this.props.account);

        if (this.state.rejectionData && (canReadAllReasons || !this.state.rejectionData.internal)) {
            const note = this.state.rejectionData.note ? `\nand with note "${ this.state.rejectionData.note }"` : '';
            tooltipText = `Document rejected with reason "${ this.state.rejectionData.reason }"${ note }`;
        }

        return tooltipText;
    }

    /**
     * Based on the document status, determines the style class, status text and optional tooltip text
     */
    _formStatusDisplay() {
        const { document } = this.props;
        switch (document.status) {
            case USER_DOCUMENT_STATUSES.PENDING.value:
                return {
                    statusClass: 'pending',
                    statusText: 'Waiting for Approval'
                }
            case USER_DOCUMENT_STATUSES.APPROVED.value:
                if (DocumentUtils.documentExpired(document)) {
                    return {
                        statusClass: 'expired',
                        statusText: 'Expired',
                        tooltipText: 'Renewing documents is done by sending them directly to ShipEX.'
                    }
                } else {
                    return {
                        statusClass: 'approved',
                        statusText: 'Approved'
                    }
                }
            case USER_DOCUMENT_STATUSES.REJECTED.value:
                const tooltipText = this._formRejectedReasonTooltipText(document);
                
                return {
                    statusClass: 'rejected',
                    statusText: 'Rejected',
                    tooltipText: tooltipText
                }
            default:
                return {
                    statusClass: 'missing',
                    statusText: 'Document Not Uploaded'
                }
        }
    }

    _formExpirationDatePicker() {
        return (
            <>
                <small className="subheading">Expiration Date</small>
                <CustomDatePicker
                    className="date-picker"
                    date={ this.state.expirationDate }
                    minDate={ moment() }
                    handleChange={ this._onExpirationChange }
                />
            </>
        );
    }

    _formRejectReasonSelect() {
        if (USER_DOCUMENT_STATUSES.REJECTED.value === this.props.document.status) {
            return <></>;
        }

        let noteTextArea = <></>;
        if ((this.state.rejectReason || {}).noteRequired) {
            noteTextArea = (
                <CustomTextArea 
                    label="Note" 
                    rows={ 3 }
                    placeholder="Enter your reason..."
                    required={ true } 
                    value={ this.state.note } 
                    fieldName="reason" 
                    onValueChange={ this._onNoteInputValueChange }
                />
            );
        }

        return (
            <>
                <div className="document-reject-reason-select">
                    <small className="subheading">Reason</small>
                    <CustomSelect 
                        values={ this.props.availableRejectReasons } 
                        selectedValue={ this.state.rejectReason ? this.state.rejectReason.reason : null }
                        onSelect={ this._onReasonSelected }
                        fieldName="reason"
                    />
                </div>

                { noteTextArea }
            </>
        );
    }

    _formDownloadAction() {
        const { document } = this.props;
        const downloadDisabledClass = !document.reference ? 'disabled' : '';
        const trigger = (
            <>
                <FontAwesomeIcon icon={ download } className="icon" />
                <Tooltip direction="bottom">
                    { document.reference ? "Download document" : "Document is not available for download." }
                </Tooltip>
            </>
        );

        return (
             <div className={ `tooltip-container action-icon ${ downloadDisabledClass }` }>
                <DownloadFileComponent reference={ document.reference } trigger={ trigger } />
            </div>
        );
    }

    _formApproveAction() {
        const { document } = this.props;
        if (USER_DOCUMENT_STATUSES.PENDING.value !== document.status) {
            return <></>;
        }
        
        return (
            <CanAccess
                action="user-documents:review"
                yes={
                    <Popup
                        id="popup-approve-document"
                        closeButton={ false }
                        show={ this.state.showApprovePopup }
                        onClose={ this._closeApprovePopup }
                        trigger={
                            <div className="tooltip-container action-icon approve">
                                <FontAwesomeIcon icon={ approve } className="icon" onClick={ this._openApprovePopup } />
                                <Tooltip direction="bottom">
                                    Approve document
                                </Tooltip>
                            </div>
                        }
                    >
                        <div className="popup-approve-document">
                            <p className="heading">Approve Document</p>
                            <p className="description">
                                Are you sure you want to approve this document? When approving a document you must specify an expiration date for it.
                            </p>

                            { this._formExpirationDatePicker() }

                            <div className="buttons-wrapper">
                                <Button type="tertiary" size="small" onClick={ this._closeApprovePopup } >
                                    Discard
                                </Button>

                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={ this._onApproveDocument }
                                    disabled={ !this.state.expirationDate }
                                >
                                    Approve
                                </Button>
                            </div>
                        </div>
                    </Popup>
                }
            />
        );
    }

    _formRejectAction() {
        const { document } = this.props;
        if (USER_DOCUMENT_STATUSES.PENDING.value !== document.status) {
            return <></>;
        }

        return (
            <CanAccess
                action="user-documents:review"
                yes={
                    <Popup
                        id="popup-reject-document"
                        closeButton={ false }
                        show={ this.state.showRejectPopup }
                        onClose={ this._closeRejectPopup }
                        trigger={
                            <div className="tooltip-container action-icon reject">
                                <FontAwesomeIcon icon={ reject } className="icon" onClick={ this._openRejectPopup  } />
                                <Tooltip direction="bottom">
                                    Reject document
                                </Tooltip>
                            </div>
                        }
                    >
                        <div className="popup-reject-document">
                            <p className="heading">Reject Document</p>
                            <p className="description">
                                Are you sure you want to reject this document? When rejecting a document you must provide a reason.
                            </p>
                            
                            { this._formRejectReasonSelect() }

                            <div className="buttons-wrapper">
                                <Button type="tertiary" size="small" onClick={ this._closeRejectPopup } >
                                    Discard
                                </Button>

                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={ this._onRejectDocument }
                                    disabled={ !this.state.rejectReason }
                                >
                                    Reject
                                </Button>
                            </div>
                        </div>
                    </Popup>
                }
            />
        );
    }

    _formEditAction() {
        const { document } = this.props;
        if (USER_DOCUMENT_STATUSES.APPROVED.value !== document.status && USER_DOCUMENT_STATUSES.REJECTED.value !== document.status) {
            return <></>;
        }

        let additionalData = <></>;
        let radioButtonClass = '';
        if (USER_DOCUMENT_STATUSES.APPROVED.value === this.state.status) {
            additionalData = <div className="additional-edit-data">{ this._formExpirationDatePicker() }</div>;
            radioButtonClass = 'approved';
        } else if (USER_DOCUMENT_STATUSES.REJECTED.value === this.state.status) {
            additionalData = <div className="additional-edit-data">{ this._formRejectReasonSelect() }</div>;
            radioButtonClass = 'rejected';
        }

        return (
            <CanAccess
                action="user-documents:review"
                yes={
                    <Popup
                        id="popup-edit-document"
                        closeButton={ false }
                        show={ this.state.showEditPopup }
                        onClose={ this._closeEditPopup  }
                        trigger={
                            <div className="tooltip-container action-icon">
                                <FontAwesomeIcon icon={ edit } className="icon" onClick={ this._openEditPopup } />
                                <Tooltip direction="bottom">
                                    Edit document
                                </Tooltip>
                            </div>
                        }
                    >
                        <div className="popup-edit-document">
                            <p className="heading">Edit Document</p>
                            <p className="description">
                                You can change the status of the <span className="bold-text">{ document.name }</span> document to approved or rejected.
                                Additionally, you can change the expiration date of an approved document.
                                Note that changes made within the application are not transferred to TMW.
                            </p>

                            <div className={ `status-options ${ radioButtonClass }` }>
                                <small className="subheading">Document Status</small>
                                <CustomRadioButton 
                                    onChange={ this._onRestartOptionChange } 
                                    options={ this._radioButtonOptions } 
                                    selectedValue={ this.state.status }
                                    direction="horizontal"
                                />
                            </div>

                            { additionalData }

                            <div className="buttons-wrapper">
                                <Button type="tertiary" size="small" onClick={ this._closeEditPopup } >
                                    Discard
                                </Button>

                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={ this._onEditDocument }
                                    disabled={ this._isEditDisabled() }
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </Popup>
                }
            />
        );
    }

    _formDocumentActions() {
        const downloadAction = this._formDownloadAction();
        const approveAction = this._formApproveAction();
        const rejectAction = this._formRejectAction();
        const editAction = this._formEditAction();

        return (
            <>
                { downloadAction }
                { editAction }
                { approveAction }
                { rejectAction }
            </>
        );
    }

    _formDocumentDisplay() {
        const { document } = this.props;
        const { statusClass, statusText, tooltipText } = this._formStatusDisplay();

        let tooltip = <></>;
        if (tooltipText) {
            tooltip = (
                <div className="icon-div">
                    <FontAwesomeIcon icon={ info } className="icon" />
                    <Tooltip direction="bottom">
                        { tooltipText }
                    </Tooltip>
                </div>
            );
        }

        let uploadedBy = '----';
        if (document.status) {
            uploadedBy = document.uploadedBy ? document.uploadedBy.name : 'Sent via email';
        }
        
        return (
            <React.Fragment>
                <div key={ document.id } className="document-card">
                    <div className="field name">
                        <small className="field-title">
                            Document
                        </small>
                        <strong className={ `field-content ${ statusClass === 'expired' ? statusClass : '' }` }>
                            { document.name }
                            <span className="required">
                                { document.required ? '*' : '' }
                            </span>
                        </strong>
                    </div>
                    <div className="field uploaded-by">
                        <small className="field-title">
                            Uploaded by
                        </small>
                        <small className="field-content" title={ uploadedBy }>
                            { uploadedBy }
                        </small>
                    </div>
                    <div className="field date">
                        <small className="field-title">
                            Date Added
                        </small>
                        <small className="field-content">
                            { document.created ? DateUtils.formatDate(new Date(document.created)) : '----' }
                        </small>
                    </div>
                    <div className="field expiration">
                        <small className="field-title">
                            Expiration Date
                        </small>
                        <small className={ `field-content ${ statusClass === 'expired' ? statusClass : '' }` }>
                            { document.expiresOn ? DateUtils.formatDate(new Date(document.expiresOn)) : '----' }
                        </small>
                    </div>
                    <div className="field status">
                        <small className="field-title">
                            Status
                        </small>
                        <small className={ `field-content ${ statusClass }` }>
                            { statusText }
                            { tooltip }
                        </small>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const documentCard = this._formDocumentDisplay();
        return <>{ documentCard }</>
    }
}
