import { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';

import 'antd/dist/antd.css';
import './CustomRadioButton.scss';

export default class CustomRadioButton extends Component {

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            disabled: PropTypes.bool
        })),
        selectedValue: PropTypes.any,
        direction: PropTypes.string,
        onChange: PropTypes.func
    }

    static defaultProps = {
        options: [],
        selectedValue: null,
        direction: 'vertical', 
        onChange: () => {/* */}
    }

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(event) {
        if (event) {
            this.props.onChange(event.target.value);
        }
    }

    render() {
        return (
            <Radio.Group className="custom-radio-group" onChange={ this._onChange } value={ this.props.selectedValue }>
                <Space direction={ this.props.direction }>
                    {
                        this.props.options.map(option =>
                            <Radio disabled={ option.disabled } key={ option.value } value={ option.value }>{ option.label }</Radio>
                        )
                    }
                </Space>
            </Radio.Group>
        );
    }
}
