import { Component } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisH as menu } from '@fortawesome/pro-light-svg-icons';

import Button from 'component/Button';
import Dropdown from 'component/Dropdown';
import RestartAuctionPopup from 'component/load/RestartAuctionPopup';
import AddOnDemandCarrierPopup from 'component/load/AddOnDemandCarrierPopup';
import ChangeAuctionDeadlinePopup from 'component/load/ChangeAuctionDeadlinePopup';
import CancelTenderAuctionPopup from 'component/load/CancelTenderAuctionPopup';

import 'component/ActionDropdown.scss';

export default class TenderAuctionActionDropdown extends Component {

    static propTypes = {
        auction: PropTypes.object.isRequired,
        showCancelTenderAuctionAction: PropTypes.bool,
        showRestartTenderAuctionAction: PropTypes.bool,
        showChangeDeadlineAction: PropTypes.bool,
        showOnDemandCarrierAction: PropTypes.bool,
        onActionCompleted: PropTypes.func,
    };

    static defaultProps = {
        showCancelTenderAuctionAction: false,
        showRestartTenderAuctionAction: false,
        showChangeDeadlineAction: false,
        showOnDemandCarrierAction: false,
        onActionCompleted: () => { /* */ }
    };

    state = {
        showActionsDropdown: false
    }

    constructor (props) {
        super(props);
        this._onOpenActionsDropdown = this._onOpenActionsDropdown.bind(this);
        this._onCloseActionsDropdown = this._onCloseActionsDropdown.bind(this);
    }

    _onOpenActionsDropdown(event) {
        this.setState({ showActionsDropdown: true });
        event.stopPropagation();
    }

    _onCloseActionsDropdown() {
        this.setState({ showActionsDropdown: false });
    }

    _formActions() {
        const {
            showCancelTenderAuctionAction,
            showRestartTenderAuctionAction,
            showChangeDeadlineAction,
            showOnDemandCarrierAction,
            auction,
            onActionCompleted
        } = this.props;

        let actions = [];
        if (showOnDemandCarrierAction) {
            actions.push(
                <AddOnDemandCarrierPopup 
                    auction={ auction }
                    onClosePopup={ this._onCloseActionsDropdown }
                    onAddOnDemandCarrier={ onActionCompleted }
                />
            );
        }
        
        if (showChangeDeadlineAction) {
            actions.push(
                <ChangeAuctionDeadlinePopup 
                    auction={ auction } 
                    onPopupClose={ this._onCloseActionsDropdown }
                    onDeadlineChanged={ onActionCompleted } 
                />
            );
        }

        if (showRestartTenderAuctionAction) {
            actions.push(
                <RestartAuctionPopup
                    auctionId={ auction.id }
                    onRestart={ onActionCompleted }
                    onClosePopup={ this._onCloseActionsDropdown }
                    pickupDate={ new Date(auction.load.pickupTime) }
                    laneBookNowPrice={ auction.load.routingGuideLane.bookNowPrice }
                    auctionBookNowPrice={ auction.bookNowPrice }
                />
            );
        }

        if (showCancelTenderAuctionAction) {
           actions.push(
                <CancelTenderAuctionPopup 
                    auction={ auction }
                    onClosePopup={ this._onCloseActionsDropdown }
                    onCancelTenderAuction={ onActionCompleted }
                />   
           );
        }

        if (actions.length === 0) {
            return <></>;
        }

        return (
            <Dropdown
                id="action-dropdown"
                direction="bottom-left"
                show={ this.state.showActionsDropdown }
                onClose={ this._onCloseActionsDropdown }
                trigger={
                    <div>
                        <Button
                            link="#action-dropdown"
                            type="tertiary"
                            onClick={ this._onOpenActionsDropdown }
                            rightIcon={ menu }
                        >
                            Actions
                        </Button>
                    </div>
                }
                actions={ actions }
            />
        );
    }

    render() {
        return (
            <div className="action-dropdown">
                { this._formActions() }
            </div>
        );
    }
}
