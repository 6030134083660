import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync as retry } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import FormStatusModal from 'component/form/FormStatusModal';
import Popup from 'component/Popup';
import TenderAuctionBidApiService from 'service/api/TenderAuctionBidApiService';

import './RetryOnDemandCarrierSync.scss';

export default class RetryOnDemandCarrierSync extends Component {
    static propTypes = {
        bidId: PropTypes.string,
        auctionId: PropTypes.string,
        businessId: PropTypes.string,
        onActionCompleted: PropTypes.func
    }

    static defaultProps = {
        bidId: '',
        auctionId: '',
        businessId: '',
        onActionCompleted: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onRetrySync = this._onRetrySync.bind(this);
        this._onClosePopup =  this._onClosePopup.bind(this);
    }

    state = {
        popupState: undefined
    };

    _onRetrySync() {
        this.setState({ popupState: FORM_STATUSES.LOADING });

        TenderAuctionBidApiService.retrySendOffer(this.props.auctionId, this.props.bidId)
            .then(response => {
                if (response) {
                    this.setState({ popupState: FORM_STATUSES.SUCCESS });
                } else {
                    this.setState({ popupState: FORM_STATUSES.ERROR });
                }
            }).catch(() => this.setState({ popupState: FORM_STATUSES.ERROR })); 
    }

    _onClosePopup() {
        if (FORM_STATUSES.SUCCESS === this.state.popupState) {
            this.props.onActionCompleted();
        }
        
        this.setState({ popupState: undefined });
    }

    _formLoadingPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                <div>
                    <h6>Syncing With TMW</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </FormStatusModal>
        );
    }

    _formSuccessPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                <div className="retry-sync-popup">
                    <h6>Offer Sent to Carrier</h6>
                    <p>An on-demand offer has been sent to the carrier with carrier code <span className="carrier-code">{ this.props.businessId }</span>.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formErrorPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClosePopup } >
                <div>
                    <h6>TMW Sync Failed</h6>
                    <p>Offer was not sent to carrier due to their terminated status in the TMW.</p>
                </div>
            </FormStatusModal>
        );
    }

    render() {
        const { popupState } = this.state;
        let content = <></>;
        if (FORM_STATUSES.LOADING === popupState) {
            content = this._formLoadingPopup();
        } else if (FORM_STATUSES.SUCCESS === popupState) {
            content = this._formSuccessPopup();
        } else if (FORM_STATUSES.ERROR === popupState) { 
            content = this._formErrorPopup();
        }

        return (
            <Popup
                id="popup-retry"
                size="medium"
                show={ !!popupState }
                onClose={ this._onClosePopup }
                trigger={
                    <p className="retry-sync-trigger" onClick={ this._onRetrySync }>
                        Retry Sync
                        <FontAwesomeIcon icon={ retry } className="icon" />
                    </p>
                }
            >
                { content }
            </Popup>
        );
    }
}
