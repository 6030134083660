import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import {
    faAngleDown as down,
    faAngleUp as up
} from '@fortawesome/pro-light-svg-icons';

import {
    AUCTION_PHASE,
    AUCTION_STATUSES,
    CUSTOM_AUCTION_PHASE,
    BID_STATUSES,
    FALLOFF_BID_STATUSES,
    CANDIDATE_TYPE,
    LOAD_CANCELABLE_AUCTION_STATUSES,
    FAILED_BID_STATUSES,
    LOAD_STATUSES
} from 'common/constants';
import CanAccess from 'component/CanAccess';
import Dropdown from 'component/Dropdown';
import Banner from 'component/Banner';
import Separator from 'component/Separator';
import Tooltip from 'component/Tooltip';
import ContentCard from 'component/card/ContentCard';
import CandidateOverviewCard from 'component/load/CandidateOverviewCard';
import SpotMarketDetailsCard from 'component/load/SpotMarketDetailsCard';
import TenderAuctionActionDropdown from './TenderAuctionActionDropdown';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';
import TenderAuctionCandidateApiService from 'service/api/TenderAuctionCandidateApiService';
import TenderAuctionApiService from 'service/api/TenderAuctionApiService';
import AuctionUtils from 'utils/AuctionUtils';
import DateUtils from 'utils/DateUtils';

import './TenderingCandidatesComponent.scss';

const PHASE_BOX_CONTENT = {
    [CUSTOM_AUCTION_PHASE.DEDICATED]: {
        message: 'Auction is currently in the Dedicated Phase.',
        tooltip: ''
    },
    [CUSTOM_AUCTION_PHASE.SPOT_MARKET]: {
        message: 'Spot Market bidding in progress.',
        tooltip: ''
    },
    [CUSTOM_AUCTION_PHASE.TRANSITION]: {
        message: 'Load is going to be published on Spot Market.',
        tooltip:
            <>
                Due to unsuccessful offers for Dedicated Carriers, auction will be transitioning to the Spot Market Phase.
                <br />This means that some of functionalities are disabled for the certain period of time.
            </>
    }
}

export default class TenderingCandidatesComponent extends Component {

    static propTypes = {
        isExpandable: PropTypes.bool,
        initiallyExpanded: PropTypes.bool,
        expandLabel: PropTypes.string,
        collapseLabel: PropTypes.string,
        auction: PropTypes.object,
        carriersWithWeeklyLoadCount: PropTypes.arrayOf(PropTypes.shape({
            carrierId: PropTypes.string,
            numberOfLoads: PropTypes.number
        })),
        showBrokerActions: PropTypes.bool,
        account: PropTypes.object,
        manualRejectReasons: PropTypes.array,
        onExtendOffer: PropTypes.func,
        onCancelReasonChange: PropTypes.func,
        onManualRejectOffer: PropTypes.func,
        onChangeBookNow: PropTypes.func,
        onActionCompleted: PropTypes.func
    }

    static defaultProps = {
        isExpandable: false,
        initiallyExpanded: false,
        expandLabel: null,
        collapseLabel: null,
        auction: null,
        carriersWithWeeklyLoadCount: [],
        showBrokerActions: false,
        onExtendOffer: () => { /* */ },
        onCancelReasonChange: () => { /* */ },
        onManualRejectOffer: () => { /* */ },
        onChangeBookNow: () => { /* */ },
        onActionCompleted: () => { /* */ },
        account: null,
        manualRejectReasons: []
    }

    constructor(props) {
        super(props);

        this._openCarrierOrderInfoPopover = this._openCarrierOrderInfoPopover.bind(this);
        this._closeCarrierOrderInfoPopover = this._closeCarrierOrderInfoPopover.bind(this);
    }

    state = {
        unsuccessfulOffersExpanded: false,
        showRestartPopup: false,
        showCancelPopup: false,
        restartType: "DEDICATED",
        cancelReason: null,
        showBrokerActionsDropdown: false,
        showOrderPopover: false,
        includeExceededCandidates: false,
        hasSpotMarketCandidates: false,
        hasSpotMarketPhasePassed: false
    }

    componentDidMount() {
        this._fetchCancelReason(this.props.auction);
        this._fetchIncludeExceededCandidatesFlag();
        this._checkSpotMarketStatus(this.props.auction.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auction?.status !== this.props.auction?.status) {
            this._fetchCancelReason(this.props.auction);
            this._checkSpotMarketStatus(this.props.auction.id)
        }
    }

    _fetchIncludeExceededCandidatesFlag() {
        TenderAuctionBrokerApiService.includeLimitExceededCandidates()
            .then(includeExceededCandidates => this.setState({ includeExceededCandidates }))
            .catch(error => console.error(error.message));
    }

    _openCarrierOrderInfoPopover(event) {
        this.setState({ showOrderPopover: true });
        event.stopPropagation();
    }

    _closeCarrierOrderInfoPopover() {
        this.setState({ showOrderPopover: false });
    }

    _toggleExpandUnsuccessfulOffers() {
        this.setState(previousState => ({
            unsuccessfulOffersExpanded: !previousState.unsuccessfulOffersExpanded
        }));
    }

    _checkSpotMarketStatus(auctionId) {
        Promise.all([
            TenderAuctionApiService.hasSpotMarketPhasePassed(auctionId),
            TenderAuctionCandidateApiService.hasSpotMarketCarriers(auctionId)
        ])
        .then(([hasSpotMarketPhasePassed, hasSpotMarketCandidates]) => this.setState({ hasSpotMarketPhasePassed, hasSpotMarketCandidates }))
        .catch(e => console.error('There was an error while checking spot market status.', e));
    }

    _fetchCancelReason(auction) {
        if (AUCTION_STATUSES.CANCELED === auction.status) {
            TenderAuctionBrokerApiService.getCancelReason(auction.id)
                .then(data => {
                    if (data) {
                        this.setState({ cancelReason: { broker: data.user?.name, reason: data.cancelReason.reason, note: data.note }});
                        this.props.onCancelReasonChange(data.cancelReason.internal);
                    }
                })
                .catch(() => console.error('Trying to fetch cancel reason of auction that was restarted.'));
        }
    }

    /**
     * Determines whether a bid is unsuccessful which means:
     * it is rejected or expired or (falloff and not current) or (pending and not current).
     * This definition of unsuccessful bid is used when forming the waterfall tendering candidates card.
     *
     * @param {Object} bid
     * @returns {boolean} true if it is unsuccessful, otherwise false
     */
    static _isBidUnsuccessful(bid) {
        return (
            BID_STATUSES.REJECTED === bid.status ||
            BID_STATUSES.REJECTED_BY_BROKER === bid.status ||
            BID_STATUSES.EXPIRED === bid.status ||
            (FALLOFF_BID_STATUSES.indexOf(bid.status) !== -1 && !bid.current) ||
            (BID_STATUSES.PENDING === bid.status && !bid.current)
        );
    }

    /**
     * Finds and returns all unsuccessful offers (candidates and their bids) for an auction.
     * Candidates with unsuccessful offers are the following:
     * - candidates with rejected or expired bid OR
     * - candidates with falloff bid that is not current
     * - candidates with pending bid that is not current
     * - candidates that are excluded
     * This function is used when creating the unsuccessful part of the tendering candidates card
     *
     * @returns {Array} array of objects {candidate, bid} where bid can be null
     */
    _findUnsuccessfulOffers() {
        const { auction } = this.props;
        const { dedicatedCandidates, bids, exclusions } = auction;
        const unsuccessfulOffers = [];

        dedicatedCandidates.forEach(candidate => {
            const candidateBid = candidate.bids[0];
            if (candidateBid) {
                if (TenderingCandidatesComponent._isBidUnsuccessful(candidateBid)) {
                    unsuccessfulOffers.push({ candidate, bid: candidateBid });
                }
            } else {
                const isExcluded = exclusions.find(exclusion => exclusion.candidate.carrier.id === candidate.carrier.id);
                const latestDedicatedBid = AuctionUtils.findLatestDedicatedBid(bids);
                if (isExcluded && (AUCTION_PHASE.SPOT_MARKET === auction.phase || (latestDedicatedBid && latestDedicatedBid.candidate.position > candidate.position))) {
                    unsuccessfulOffers.push({ candidate, bid: null });
                }
            }
        });

        return unsuccessfulOffers;
    }

    /**
     * Finds all upcoming candidates in an auction.
     *
     * @returns {Array}
     */
    _findFutureDedicatedCandidates() {
        const { auction } = this.props;
        const { dedicatedCandidates, bids } = auction;
        return dedicatedCandidates.filter((candidate) => {
            const candidateBid = candidate.bids[0];
            const latestDedicatedCandidateBid = AuctionUtils.findLatestDedicatedBid(bids);
            const exclusion = AuctionUtils.findCandidateExclusion(auction, candidate);
            const isPhaseCorrect = AUCTION_PHASE.DEDICATED === auction.phase;
            return (
                !candidateBid &&
                isPhaseCorrect &&
                (!exclusion || !latestDedicatedCandidateBid || latestDedicatedCandidateBid.candidate.position < candidate.position)
            );
        });
    }

    _formBrokerActions(currentBid) {
        if (this.props.showBrokerActions && CUSTOM_AUCTION_PHASE.TRANSITION !== this._determineAuctionPhase(currentBid)) {
            const { auction, onActionCompleted } = this.props;
            const isAuctionCompleted = AuctionUtils.isAuctionCompleted(auction);
            return (
                <TenderAuctionActionDropdown 
                    auction={ auction } 
                    onActionCompleted={ onActionCompleted }
                    showChangeDeadlineAction={ !isAuctionCompleted }
                    showOnDemandCarrierAction={ !isAuctionCompleted && !LOAD_CANCELABLE_AUCTION_STATUSES.includes(auction?.status) }
                    showCancelTenderAuctionAction={ !isAuctionCompleted }
                    showRestartTenderAuctionAction={ !isAuctionCompleted && !LOAD_CANCELABLE_AUCTION_STATUSES.includes(auction?.status) && LOAD_STATUSES.AVAILABLE === auction?.load.status }
                />
            );
        } else {
            return null;
        }
    }

    _formInfoPopover() {
        const iconClassSuffix = this.state.showOrderPopover ? ' active' : '';
        let orderAffectingFactors;
        if (this.state.includeExceededCandidates) {
            orderAffectingFactors = (
                <>
                    &nbsp;However, if a <br />carrier has met their <b>weekly <br />capacity</b> and their bid is higher <br />
                    then the first carrier's bid by <b>20% <br />
                    or more</b>, they will be moved <br />
                    to the end of the list.
                </>
            );    
        } else {
            orderAffectingFactors = (
                <>
                    &nbsp;However, if a <br />carrier  has met their <b>weekly <br />capacity</b>, they will be skipped.
                </>
            );  
        }
        return (
            <div className="carrier-order-popover">
                <Dropdown 
                    id="carrier-order-info-dropdown"
                    direction="bottom-left"
                    show={ this.state.showOrderPopover }
                    centerArrowOnTrigger={ false }
                    onClose={ this._closeCarrierOrderInfoPopover }
                    trigger={(
                        <a href="#!" className="popover-icon-container" onClick={ this._openCarrierOrderInfoPopover }>
                            <small className="prompt" onClick={ this._openCarrierOrderInfoPopover }>Why this carrier order?</small>
                            <FontAwesomeIcon icon={ info } className={ `icon ${ iconClassSuffix }` } />
                        </a>
                    )}
                >
                    <div className="order-popover-content">
                        <p className="title">Why are Carriers in this order?</p>
                        <small>
                            The bidding order is determined by <br />
                            the <b>Routing Guide</b>. 
                            { orderAffectingFactors }
                        </small>
                    </div>
                </Dropdown>
            </div>
        );
    }

    _formAuctionSubheading() {
        const { auction } = this.props;
        const isAuctionCanceled = AUCTION_STATUSES.CANCELED === auction.status;
        if (isAuctionCanceled && this.state.cancelReason) {
            const { broker, reason, note } = this.state.cancelReason;
            let tooltipText = `Canceled with reason: "${ reason }"`;

            if (broker) {
                const additionalNote = note ? `, note: "${ note }"` : "";
                tooltipText = `Canceled by ${ broker } with reason: "${ reason }"${ additionalNote }`;
            }

            return (
                <span className="content-card-subheading">
                    (Canceled Tender on { DateUtils.format(new Date(auction.updated)) })
                    <span className="icon">
                        <FontAwesomeIcon icon={ info } />
                        <Tooltip direction="right">
                            { tooltipText }
                        </Tooltip>
                    </span>
                </span>
            );
        } else {
            return (
                <span className="content-card-subheading">
                    (Deadline { DateUtils.format(new Date(auction.deadline)) })
                    <span className="icon">
                        <FontAwesomeIcon icon={ info } />
                        <Tooltip direction="right">
                            Auction will be completed on this date and <br />
                            in case of Spot Market, the winner will <br />
                            be chosen at this moment.
                        </Tooltip>
                    </span>
                </span>
            );
        }
    }

    /**
     * Determines where the Spot Market card should be placed within the Waterfall. If an auction has a Spot Market phase,
     * it can either be current or upcoming in the Waterfall, as any case which would lead to it being in the unsuccessful
     * offers section will also lead to the view switching to the Tendering Candidates List. If there are no Spot Market
     * carriers in the auction, there is no Spot Market phase.
     *
     * @returns {string}
     */
    _determineSpotMarketPositioning() {
        const { auction } = this.props;

        if (AUCTION_PHASE.SPOT_MARKET === auction.phase) {
            return 'current';
        } else if (AUCTION_PHASE.DEDICATED === auction.phase) {
            return 'future';
        } else {
            // TODO - Remove this after testing and making sure everything works as expected. It should never happen.
            console.error("Case not handled appropriately.", auction);
        }
    }

    /**
     * Forms the unsuccessful offers display based on the number of offers and whether the user has chosen to expand them or not.
     *
     * @param {Array} unsuccessfulOffers
     * @param {Object} currentBid
     * @private
     */
    _mapUnsuccessfulOffers(unsuccessfulOffers, currentBid) {
        const { auction, carriersWithWeeklyLoadCount, account, manualRejectReasons } = this.props;
        let showAllUnsuccessfulLabel = <></>;
        let displayedUnsuccessfulOffers;
        const isTransitioning = CUSTOM_AUCTION_PHASE.TRANSITION === this._determineAuctionPhase(currentBid);

        if (unsuccessfulOffers.length > 1) {
            if (this.state.unsuccessfulOffersExpanded) {
                showAllUnsuccessfulLabel = (
                    <span className="expansion-prompt" onClick={ this._toggleExpandUnsuccessfulOffers.bind(this) }>
                        Show the most recent one <FontAwesomeIcon size="lg" className="expand-icon" icon={ up } />
                    </span>
                );

                displayedUnsuccessfulOffers = (
                    unsuccessfulOffers.map((offer, index) => {
                        const card = (
                            <CandidateOverviewCard
                                key={ offer.candidate.id }
                                bordered={ false }
                                auction={ auction }
                                bid={ offer.bid }
                                candidate={ offer.candidate }
                                currentBid={ currentBid }
                                relevant={ false }
                                onDeclareWinner={ this.props.onActionCompleted }
                                onExtendOffer={ this.props.onExtendOffer }
                                account={ account }
                                carriersWithWeeklyLoadCount={ carriersWithWeeklyLoadCount }
                                manualRejectReasons={ manualRejectReasons }
                                displayActions={ !isTransitioning }
                            />
                        );

                        const separator = index !== unsuccessfulOffers.length - 1 ? <Separator key={ index } /> : <></>;

                        return (
                            <div key={ index }>
                                { card }
                                { separator }
                            </div>
                        );
                    })
                );
            } else {
                showAllUnsuccessfulLabel = (
                    <span className="expansion-prompt" onClick={ this._toggleExpandUnsuccessfulOffers.bind(this) }>
                        Show All
                        <span className="amount">({ unsuccessfulOffers.length })</span>
                        <FontAwesomeIcon size="lg" className="expand-icon" icon={ down } />
                    </span>
                );

                displayedUnsuccessfulOffers = (
                    <CandidateOverviewCard
                        key={ unsuccessfulOffers[unsuccessfulOffers.length - 1].candidate.id }
                        bordered={ false }
                        auction={ auction }
                        bid={ unsuccessfulOffers[unsuccessfulOffers.length - 1].bid }
                        currentBid={ currentBid }
                        candidate={ unsuccessfulOffers[unsuccessfulOffers.length - 1].candidate }
                        relevant={ false }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onExtendOffer={ this.props.onExtendOffer }
                        account={ account }
                        carriersWithWeeklyLoadCount={ carriersWithWeeklyLoadCount }
                        manualRejectReasons={ manualRejectReasons }
                        displayActions={ !isTransitioning }
                    />
                );
            }
        } else {
            displayedUnsuccessfulOffers = (
                <CandidateOverviewCard
                    key={ unsuccessfulOffers[0].candidate.id }
                    bordered={ false }
                    auction={ auction }
                    bid={ unsuccessfulOffers[0].bid }
                    currentBid={ currentBid }
                    candidate={ unsuccessfulOffers[0].candidate }
                    relevant={ false }
                    onDeclareWinner={ this.props.onActionCompleted }
                    onExtendOffer={ this.props.onExtendOffer }
                    account={ account }
                    carriersWithWeeklyLoadCount={ carriersWithWeeklyLoadCount }
                    manualRejectReasons={ manualRejectReasons }
                    displayActions={ !isTransitioning }
                />
            );
        }

        return (
            <div className="unsuccessful-bids">
                <strong className="candidates-heading">Unsuccessful Offers</strong>
                { showAllUnsuccessfulLabel }
                <div className="component">
                    { displayedUnsuccessfulOffers }
                </div>
            </div>
        );
    }

    _determineAuctionPhase(currentBid) {
        const { auction } = this.props;
        const { hasSpotMarketCandidates } = this.state;

        if (AUCTION_STATUSES.OFFERING_TO_CARRIERS === auction.status) {
            const futureDedicatedCandidates = this._findFutureDedicatedCandidates();
  
            if (!currentBid && !futureDedicatedCandidates.length && AUCTION_PHASE.DEDICATED === auction.phase) {
                if (hasSpotMarketCandidates) {
                    return CUSTOM_AUCTION_PHASE.TRANSITION;
                } 

                return null;
            } else if (AUCTION_PHASE.SPOT_MARKET === auction.phase) {
                return CUSTOM_AUCTION_PHASE.SPOT_MARKET;
            }

            return CUSTOM_AUCTION_PHASE.DEDICATED;
        }

        return null;
    }

    _formWaterfallCardContent(currentBid) {
        const { auction, account, carriersWithWeeklyLoadCount, manualRejectReasons } = this.props;
        const currentDedicatedBid = currentBid && CANDIDATE_TYPE.SIGNED_CARRIER === currentBid.candidate.type ? currentBid : null;
        const unsuccessfulOffers = this._findUnsuccessfulOffers();
        const isTransitioning = CUSTOM_AUCTION_PHASE.TRANSITION === this._determineAuctionPhase(currentBid);

        const spotMarketPosition = this._determineSpotMarketPositioning();

        let unsuccessfulOffersDisplay = <></>;
        if (unsuccessfulOffers.length > 0) {
            unsuccessfulOffersDisplay = this._mapUnsuccessfulOffers(unsuccessfulOffers, currentDedicatedBid);
        }

        let currentBidDisplay = <></>;
        let displayedCurrentBid;

        if (currentDedicatedBid) {
            displayedCurrentBid = (
                <CandidateOverviewCard
                    key={ currentDedicatedBid.candidate.id }
                    auction={ auction }
                    bid={ currentDedicatedBid }
                    candidate={ currentDedicatedBid.candidate }
                    currentBid={ currentDedicatedBid }
                    relevant={ true }
                    onDeclareWinner={ this.props.onActionCompleted }
                    onExtendOffer={ this.props.onExtendOffer }
                    onManualRejectOffer={ (reason, note) => this.props.onManualRejectOffer(currentDedicatedBid.id, reason, note) }
                    account={ account }
                    carriersWithWeeklyLoadCount={ carriersWithWeeklyLoadCount }
                    manualRejectReasons={ manualRejectReasons }
                    displayActions={ !isTransitioning }
                />
            );
        } else if (spotMarketPosition === 'current') {
            displayedCurrentBid = (
                <SpotMarketDetailsCard
                    key={ 'spot-market' + auction.id }
                    isExpandable={ true }
                    expandLabel= "Show details"
                    collapseLabel= "Hide details"
                    auction={ auction }
                    account={ this.props.account }
                    cardState={ spotMarketPosition }
                    disableBookNowEdit={ isTransitioning }
                    onDeclareWinner={ this.props.onActionCompleted }
                    onChangeBookNow={ this.props.onChangeBookNow }
                />
            );
        }

        if (displayedCurrentBid) {
            currentBidDisplay = (
                <div className="offer-display">
                    { unsuccessfulOffers.length > 0 ? <br /> : <></> }
                    <div className="current-bid">
                        <strong className="candidates-heading">Current Offer</strong>
                    </div>
                    { displayedCurrentBid }
                </div>
            );
        }

        const futureDedicatedCandidates = this._findFutureDedicatedCandidates();
        let futureCandidatesDisplay = <></>
        let displayedFutureDedicatedCandidates, displayedSpotMarketCard;
        let hasFutureSection = false;

        if (futureDedicatedCandidates.length > 0) {
            hasFutureSection = true;
            displayedFutureDedicatedCandidates = futureDedicatedCandidates.map((candidate, index) => {
                let isFutureRelevant = false;
                if (BID_STATUSES.PENDING === currentDedicatedBid?.status) {
                    isFutureRelevant = true;
                }

                const card = (
                    <CandidateOverviewCard
                        key={ candidate.id }
                        bordered={ false }
                        auction={ auction }
                        bid={ null }
                        candidate={ candidate }
                        currentBid={ currentDedicatedBid }
                        relevant={ isFutureRelevant }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onExtendOffer={ this.props.onExtendOffer }
                        account={ account }
                        carriersWithWeeklyLoadCount={ carriersWithWeeklyLoadCount }
                        manualRejectReasons={ manualRejectReasons }
                    />
                );

                const separator = index !== futureDedicatedCandidates.length - 1 ? <Separator key={ index }/> : <></>;

                return (
                    <div key={ index }>
                        { card }
                        { separator }
                    </div>
                );
            });

            displayedFutureDedicatedCandidates = (
                <div className="component dedicated">
                    { displayedFutureDedicatedCandidates }
                </div>
            );
        }

        if (spotMarketPosition === 'future') {
            hasFutureSection = true;
            displayedSpotMarketCard = (
                <SpotMarketDetailsCard
                    key={ 'spot-market' + auction.id }
                    isExpandable={ true }
                    initiallyExpanded={ false }
                    expandLabel= "Show details"
                    collapseLabel= "Hide details"
                    auction={ auction }
                    account={ account }
                    cardState={ spotMarketPosition }
                    disableBookNowEdit={ isTransitioning }
                    onDeclareWinner={ this.props.onActionCompleted }
                    onChangeBookNow={ this.props.onChangeBookNow }
                />
            );
        }

        if (hasFutureSection) {
            futureCandidatesDisplay = (
                <>
                    { !!displayedCurrentBid || unsuccessfulOffers.length > 0 ? <br /> : <></> }
                    <div className="future-candidates">
                        <strong className="candidates-heading">Up Next</strong>
                        { displayedFutureDedicatedCandidates }
                    </div>
                    { displayedSpotMarketCard }
                </>
            );
        }

        return (
            <>
                { unsuccessfulOffersDisplay }
                { currentBidDisplay }
                { futureCandidatesDisplay }
            </>
        );
    }

    /** The Spot Market card can be in one of three states on the Candidates List view:
     * current - if the auction is in the Spot Market phase
     * past - if there are Spot Market carriers, but the last listed candidate is a Dedicated carrier
     * skipped - in all other circumstances where the Candidates List is shown.
     *
     * @returns {string}
     * @private
     */
    _determineSpotMarketCardState() {
        const { auction } = this.props;

        let spotMarketState;
        if (AUCTION_PHASE.SPOT_MARKET === auction.phase) {
            spotMarketState = 'current';
        } else if (this.state.hasSpotMarketPhasePassed) {
            spotMarketState = 'past';
        } else {
            spotMarketState = 'skipped';
        }

        return spotMarketState;
    }

    /**
     * In case we don't have spot market candidates, that means that there is no spot market auction and our spotMarketIndex should be -1
     * In case we have spot market candidates and difference in position of the last and second from behind candidate (from dedicatedCandidates object),
     * is greater than 1, that means that we have declared dedicated carrier as a winner and that there is spot market phase in between, so we return candidates.length - 1
     * as spotMarketIndex
     * Otherwise, we return candidates.length as spotMarketIndex, because spot market is last in the order
     */
    _calculateSpotMarketIndex(dedicatedCandidates) {
        if (this.state.hasSpotMarketCandidates) {
            if (this.state.hasSpotMarketPhasePassed) {
                return dedicatedCandidates.length - 1;
            }

            return dedicatedCandidates.length;
        } else {
            return -1;
        }
    }

    _formTenderingCandidatesListCardContent(currentBid) {
        const { auction } = this.props;
        const { dedicatedCandidates } = auction;
        const currentDedicatedBid = currentBid && CANDIDATE_TYPE.SIGNED_CARRIER === currentBid.candidate.type ? currentBid : null;
        const auctionCanceled = AUCTION_STATUSES.CANCELED === auction.status;
        const isTransitioning = CUSTOM_AUCTION_PHASE.TRANSITION === this._determineAuctionPhase(currentBid);

        const spotMarketIndex = this._calculateSpotMarketIndex(dedicatedCandidates);
        let content;

        if (dedicatedCandidates.length === 0 && !this.state.hasSpotMarketCandidates) {
            return (
                <div className="candidates-list">
                    <div>
                        <Banner type="warn" size="medium" content="There are no candidates on this auction." />
                    </div>
                </div>
            );
        } else if (spotMarketIndex === -1) {
            const cards = dedicatedCandidates.map((candidate, index) => {
                const candidateBid = candidate.bids[0];
                const card = (
                    <CandidateOverviewCard
                        key={ candidate.id }
                        auction={ auction }
                        bid={ candidateBid }
                        currentBid={ currentDedicatedBid }
                        candidate={ candidate }
                        bordered={ false }
                        carrierNameColored={ true }
                        relevant={ candidateBid?.current && !auctionCanceled && !FAILED_BID_STATUSES.includes(candidateBid?.status) }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onExtendOffer={ this.props.onExtendOffer }
                        onManualRejectOffer={ (reason, note) => this.props.onManualRejectOffer(candidateBid.id, reason, note) }
                        account={ this.props.account }
                        carriersWithWeeklyLoadCount={ this.props.carriersWithWeeklyLoadCount }
                        manualRejectReasons={ this.props.manualRejectReasons }
                    />
                );
                const separator = index !== dedicatedCandidates.length - 1 ? <Separator key={ index }/> : <></>;
                return (
                    <div key={ index }>
                        { card }
                        { separator }
                    </div>
                );
            });

            content = (
                <div className="component">
                    { cards }
                </div>
            );
        } else {
            let waterfallProcessCandidateCards;
            for (let i = 0; i < spotMarketIndex; i++) {
                const candidate = dedicatedCandidates[i];
                const candidateBid = candidate.bids[0];

                const card = (
                    <CandidateOverviewCard
                        key={ candidate.id }
                        auction={ auction }
                        bid={ candidateBid }
                        currentBid={ currentDedicatedBid }
                        candidate={ candidate }
                        bordered={ false }
                        carrierNameColored={ true }
                        relevant={ candidateBid?.current && !auctionCanceled && !FAILED_BID_STATUSES.includes(candidateBid?.status) }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onExtendOffer={ this.props.onExtendOffer }
                        onManualRejectOffer={ (reason, note) => this.props.onManualRejectOffer(candidateBid.id, reason, note) }
                        account={ this.props.account }
                        carriersWithWeeklyLoadCount={ this.props.carriersWithWeeklyLoadCount }
                        manualRejectReasons={ this.props.manualRejectReasons }
                    />
                );
                const separator = i !== spotMarketIndex - 1 ? <Separator key={ i } /> : <></>;

                waterfallProcessCandidateCards = (
                    <>
                        { waterfallProcessCandidateCards }
                        <div key={ i }>
                            { card }
                            { separator }
                        </div>
                    </>
                );
            }

            if (waterfallProcessCandidateCards) {
                content = (
                    <div className="component waterfall-cards">
                        { waterfallProcessCandidateCards }
                    </div>
                );
            }

            let spotMarketState = this._determineSpotMarketCardState();

            // Spot Market candidates are guaranteed to all be listed together as it isn't possible to declare a Spot Market
            // winner while the auction is in the dedicated phase.
            content = (
                <>
                    { content }
                    <SpotMarketDetailsCard
                        key={ 'spot-market' + auction.id }
                        isExpandable={ true }
                        initiallyExpanded={ false }
                        expandLabel= "Show details"
                        collapseLabel= "Hide details"
                        auction={ auction }
                        account={ this.props.account }
                        cardState={ spotMarketState }
                        disableBookNowEdit={ isTransitioning }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onChangeBookNow={ this.props.onChangeBookNow }
                    />
                </>
            );

            let winnerDeclarationCandidateCards;
            for (let i = spotMarketIndex; i < dedicatedCandidates.length; i++) {
                const candidate = dedicatedCandidates[i];
                if (CANDIDATE_TYPE.SPOT_MARKET === candidate.type) {
                    continue; // Skipping Spot Market carriers, as they are handled in the Spot Market card
                }

                const candidateBid = candidate.bids[0];

                const card = (
                    <CandidateOverviewCard
                        key={ candidate.id }
                        auction={ auction }
                        bid={ candidateBid }
                        currentBid={ currentDedicatedBid }
                        candidate={ candidate }
                        bordered={ false }
                        carrierNameColored={ true }
                        relevant={ candidateBid?.current && !auctionCanceled && !FAILED_BID_STATUSES.includes(candidateBid?.status) }
                        onDeclareWinner={ this.props.onActionCompleted }
                        onExtendOffer={ this.props.onExtendOffer }
                        onManualRejectOffer={ (reason, note) => this.props.onManualRejectOffer(candidateBid.id, reason, note) }
                        account={ this.props.account }
                        carriersWithWeeklyLoadCount={ this.props.carriersWithWeeklyLoadCount }
                        manualRejectReasons={ this.props.manualRejectReasons }
                    />
                );
                const separator = i !== dedicatedCandidates.length - 1 ? <Separator key={ i }/> : <></>;

                winnerDeclarationCandidateCards = (
                    <>
                        { winnerDeclarationCandidateCards }
                        <div key={ i }>
                            { card }
                            { separator }
                        </div>
                    </>
                );
            }

            if (winnerDeclarationCandidateCards) {
                content = (
                    <>
                        { content }
                        <div className="component winner-declaration-cards">
                            { winnerDeclarationCandidateCards }
                        </div>
                    </>
                );
            }
        }

        return (
            <div className="candidates-list">
                <strong className="candidates-heading">Candidates List</strong>
                { content }
            </div>
        );
    }

    _formPhaseBanner(currentBid) {
        const phase = this._determineAuctionPhase(currentBid);

        if (phase !== null) {
            return (
                <div className="phase-box">
                    <Banner content={ PHASE_BOX_CONTENT[phase].message } />
                    { PHASE_BOX_CONTENT[phase].tooltip &&
                        <Tooltip align="center-align" direction="top">
                            { PHASE_BOX_CONTENT[phase].tooltip }
                        </Tooltip>
                    }
                </div>
            );
        }

        return null;
    }

    render() {
        const { isExpandable, initiallyExpanded, expandLabel, collapseLabel, auction } = this.props;
        const subheading = this._formAuctionSubheading();
        const currentBid = AuctionUtils.findCurrentBid(auction.bids);

        const showWaterfall = AUCTION_STATUSES.OFFERING_TO_CARRIERS === auction.status;

        let cardContent;
        if (showWaterfall) {
            cardContent = this._formWaterfallCardContent(currentBid);
        } else {
            cardContent = this._formTenderingCandidatesListCardContent(currentBid);
        }

        const brokerActions = this._formBrokerActions(currentBid);

        let infoPopover;
        if (auction.dedicatedCandidates.length) {
            infoPopover = (
                <CanAccess
                    action="load-tendering:read"
                    yes={ this._formInfoPopover() }
                />
            );
        }

        const phaseBanner = this._formPhaseBanner(currentBid);
        return (
            <div className="tendering-candidates">
                <ContentCard
                    isExpandable={ isExpandable }
                    initiallyExpanded={ initiallyExpanded }
                    heading="Tendering Candidates"
                    info={ phaseBanner }
                    subheading={ subheading }
                    expandLabel={ expandLabel }
                    collapseLabel={ collapseLabel }
                    actions={ brokerActions }
                    infoPopover={ infoPopover }
                    className="main-card"
                >
                    { cardContent }
                </ContentCard>
            </div>
        );
    }
}
