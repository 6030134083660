import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction bids
 */
export default class TenderAuctionBidApiService {
    /**
     * Gets a list of all bids which are part of auction
     * 
     * @param auctionId id of auction for which bids are 
     * @returns list of bids
     */
    static async fetch(auctionId) {
        const bids = await RestService.instance().get(`auction/tendering/${ auctionId }/bids`);
        return bids;
    }

    /**
     * Retry sending an offer to on demand carrier for offer which was not successfully sent
     * 
     * @param {string} bidId id of not sent bid
     * @param {string} auctionId id of relevant auction
     * @returns true if offer was sent successfully, otherwise false
     */
    static retrySendOffer(auctionId, bidId) {
        return RestService.instance().post(`auction/tendering/${ auctionId }/bid/${ bidId }/retry-send-offer`);
    }

    /**
     * Add on-demand carrier to auction
     *
     * @param {string} auctionId id of auction on which on-demand carrier is added
     * @param {string} businessId carrier code of on-demand carrier
     * @param {Number} price price which will be offer to on-demand carrier
     * @returns {Promise}
     */
    static addOnDemandOffer(auctionId, businessId, price) {
        return RestService.instance().post(`auction/tendering/${ auctionId }/on-demand-offer`, { businessId, price });
    }
}
