import { Component } from 'react';
import PropTypes from 'prop-types';

import { FORM_STATUSES } from 'common/constants';
import Button from 'component/Button';
import CustomSelect from 'component/form/CustomSelect';
import FormStatusModal from 'component/form/FormStatusModal';
import Popup from 'component/Popup';

import './CancelLoadPopup.scss';

export default class CancelLoadPopup extends Component {
    static propTypes = {
        onCancelOffer: PropTypes.func,
        falloffReasons: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        onCancelOffer: () => { /* */ },
        falloffReasons: [],
        disabled: false
    };

    constructor(props) {
        super(props);

        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onCancelOffer = this._onCancelOffer.bind(this);
        this._onReasonSelected = this._onReasonSelected.bind(this);
    }

    state = {
        initialReason: null,
        cancelReason: null,
        reasons: [],
        showPopup: false,
        popupStatus: undefined,
        errorMessage: null
    };

    componentDidMount() {
        this._setReasonsFromProps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.falloffReasons !== this.props.falloffReasons) {
            this._setReasonsFromProps();
        }
    }

    /**
     * Sets selected reason and reasons array based on props
     *
     * @private
     */
    _setReasonsFromProps() {
        const reasons = this.props.falloffReasons;
        const reason = reasons.length > 0 ? reasons[0]: null;
        this.setState({ reasons, initialReason: reason, cancelReason: reason});
    }

    /**
     * Handles reason select event
     *
     * @param { String } value
     * @param { Object } option
     * @private
     */
    _onReasonSelected(_value, option) {
        this.setState({ cancelReason: JSON.parse(option.key)});
    }

    _onCancelOffer() {
        if (this.state.cancelReason) {
            this.setState({ popupStatus: FORM_STATUSES.LOADING });
            this.props.onCancelOffer(this.state.cancelReason)
                .then(() => this.setState({ popupStatus: FORM_STATUSES.SUCCESS }, this._onClose))
                .catch(error => this.setState({ 
                    popupStatus: FORM_STATUSES.ERROR,
                    errorMessage: error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data 
                }));
        }
    }

    _onOpen() {
        this.setState({ showPopup: true, popupStatus: undefined });
    }

    _onClose() {
        if (FORM_STATUSES.LOADING !== this.state.popupStatus) {
            this.setState({ showPopup: false, cancelReason: this.state.initialReason });
        }
    }

    render() {
        let content;
        let popupSize = 'small';

        switch (this.state.popupStatus) {
            case FORM_STATUSES.LOADING:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.LOADING }>
                        <div>
                            <h6>Canceling the load</h6>
                            <p>This will only take a moment. Please wait...</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            case FORM_STATUSES.ERROR:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClose }>
                        <div>
                            <h6>Load Cancellation Failure</h6>
                            <p>{ this.state.errorMessage }</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            default:
                content = (
                    <>
                        <p className="heading">Cancel this load?</p>
                        <p className="description">
                            Please select the reason behind this cancelation. Note, cancelling loads you have already committed to may negatively impact your rating within ShipEX Logistics. 
                        </p>
                        <p className="subheading">Reason</p>
                        <CustomSelect
                            values={ this.state.reasons }
                            selectedValue={ this.state.cancelReason ? this.state.cancelReason.reason : null }
                            onSelect={ this._onReasonSelected }
                            fieldName="reason"
                        />
                        <div className="buttons-wrapper">
                            <Button type="tertiary" 
                                    size="small"
                                    onClick={ this._onClose }>
                                Discard
                            </Button>
                            <Button
                                type="danger"
                                size="small"
                                disabled={ !this.state.reasons || this.state.reasons.length <= 0 }
                                onClick={ this._onCancelOffer }
                            >
                                OK
                            </Button>
                        </div>
                    </>
                );
        }

        return (
            <div className="cancel-load-popup-container">
                <Popup
                    id="cancel-offer-popup"
                    size={ popupSize }
                    show={ this.state.showPopup }
                    onClose={ this._onClose }
                    trigger={
                        <Button
                            type="tertiary"
                            link="#cancel-offer-popup"
                            disabled={ this.props.disabled }
                            onClick={ this._onOpen }>
                            Cancel Load
                        </Button>
                    }>
                    <div className="cancel-offer-popup">
                        { content }
                    </div>
                </Popup>
            </div>
        );
    }
}
