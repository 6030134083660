import { Component } from 'react';
import PropTypes from 'prop-types';
import { faTrash as deleteIcon } from '@fortawesome/pro-regular-svg-icons';

import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import Banner from 'component/Banner';

import './RemoveCarrier.scss';

export default class RemoveDedicatedCarrier extends Component {

    static propTypes = {
        dedicatedCarrier: PropTypes.object.isRequired,
        onRemoveCarrier: PropTypes.func
    };

    static defaultProps = {
        onRemoveCarrier: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onRemove = this._onRemove.bind(this);
    }

    state = {
        showPopup: false
    }

    _openPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.setState({ showPopup: false });
    }

    _onRemove() {
        this.props.onRemoveCarrier(this.props.dedicatedCarrier);
        this._closePopup();
    }

    render() {
        return (
            <div className="remove-dedicated-carrier">
                <Popup
                    id="popup-remove-dedicated-carrier"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={
                        <Button
                            type="tertiary-alt"
                            className="action"
                            rightIcon={ deleteIcon }
                            disabled={ TENDER_CANDIDATE_STATUS.ACCEPTED.value !== this.props.dedicatedCarrier.status }
                            onClick={ this._openPopup }
                        />
                    }
                >
                    <div className="remove-dedicated-carrier-popup">
                        <p className="heading">Remove Dedicated Carrier</p>
                        
                        <p className="description">
                            Are you sure you want to remove <b>{ this.props.dedicatedCarrier.carrier.name }</b>?
                        </p>
                        
                        <div className="description-info">
                            <Banner
                                size="medium"
                                content="Please note that removing a dedicated carrier from a lane won't affect the already started auctions for loads on that lane."
                            />
                        </div>

                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._closePopup } >
                                Cancel
                            </Button>

                            <Button type="danger" size="small" onClick={ this._onRemove }>
                                Remove Carrier
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
