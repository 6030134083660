import { Component } from 'react';
import PropTypes from 'prop-types';
import { faCommentAltSmile as messageIcon } from '@fortawesome/pro-regular-svg-icons';

import Button from 'component/Button';
import Dropdown from 'component/Dropdown';
import Tooltip from 'component/Tooltip';
import AuthenticationService from 'service/AuthenticationService';

import './SurveyFloatingButton.scss';

export default class SurveyFloatingButton extends Component {
    constructor(props) {
        super(props); 

        this._onUpdateSurveyReminderSeen = this._onUpdateSurveyReminderSeen.bind(this);
    }
    
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        remainingTime: PropTypes.string.isRequired,
        survey: PropTypes.object.isRequired
    }

    state = {
        surveyReminderSeen: false,
        account: null
    }

    /**
     *
     * @type {Timeout | {current: null} | {current: null}}
     * @private
     */
    _timer = null;

    async componentDidMount() {
        const account = await AuthenticationService.instance().getAccount();
        Array.from(Array(localStorage.length).keys())
            .forEach(i => {
                const surveyLocalStorage = localStorage.key(i);
                if (surveyLocalStorage?.includes('survey') && !surveyLocalStorage?.includes(this.props.survey?.id)) {
                    localStorage.removeItem(surveyLocalStorage);
                }
            });
        
        this.setState({
            surveyReminderSeen: JSON.parse(localStorage.getItem(`survey-${ this.props.survey.id }-reminder-seen-by-${ account.id }`)),
            account: account
        }, this._onUpdateSurveyReminderSeen);
    }

    componentWillUnmount() {
       if (this._timer) {
            clearTimeout(this._timer);
            this._updateLocalStorageSurvey();
       }
    }

    _onUpdateSurveyReminderSeen() {
        if (!this.state.surveyReminderSeen) {
            this._timer = setTimeout(() => {
                this.setState({ surveyReminderSeen: true }, () =>  this._updateLocalStorageSurvey())
            }, 8000);
        }    
    }

    _updateLocalStorageSurvey() {
        localStorage.setItem(`survey-${ this.props.survey.id }-reminder-seen-by-${ this.state.account.id }`, true);
    }

    render() {
        let tooltip;
        const remainingDuration = this.props.remainingTime.split(' ');
        const timeDisplay = (isNaN(remainingDuration[0]) ? remainingDuration : remainingDuration.splice(0, 4)).join(" ");

        if (this.state.surveyReminderSeen) {
            tooltip = (
                <Tooltip direction="left">
                    If you ever feel like giving us your feedback,<br /> you can start here.<br />
                    This survey will end in <b>{ timeDisplay }</b>.
                </Tooltip>
            );
        }

        return (
            <div className="survey-floating-button-container">
                <Dropdown
                    direction="top-left"
                    id="survey-dropdown"
                    show={ !this.state.surveyReminderSeen }
                    isBackgroundClickable={ true }
                    trigger={
                        <div>
                            { tooltip }
                            <Button rightIcon={ messageIcon } onClick={ this.props.onClick } className="floating-button"/>
                        </div>
                    }
                >
                    <p className="survey-reminder">
                        <b>Not a good time for a survey?</b><br /><br />
                        If you ever feel like giving us your <br />feedback, you can start here.<br /><br />
                        This survey will end in <b>{ timeDisplay }</b>.
                    </p>   
                </Dropdown>
            </div>
        );
    }
}
