import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction
 */
export default class TenderAuctionApiService {

    /**
     * Filters all available loads with no spot market bids yet. If a carrier is making 
     * this request, it lists all available loads they haven't placed any spot market bids for.
     * 
     * @param {Object} params filter parameters
     * @returns list of available load auctions
     */
    static availableLoads(params) {
        return RestService.instance().get('auction/tendering/available-loads', params);
    }

    /**
     * Filters all available loads with any spot market bids. If a carrier is making
     * this request, it lists all available loads they have placed a spot market bid for.
     * 
     * @param {Object} params filter parameters
     * @returns list of available load auctions with spot market bids
     */
    static currentBids(params) {
        return RestService.instance().get('auction/tendering/current-bids', params);
    }

    /**
     * Filters all loads that are currently offered to a dedicated or an on demand carrier.
     * If a carrier is making this request, it lists all loads currently offered to them.
     * 
     * @param {Object} params filter parameters
     * @returns list of offered load auctions
     */
    static loadOffers(params) {
        return RestService.instance().get('auction/tendering/load-offers', params);
    }

    /**
     * Filters all loads which require a Broker action, e.g. loads form which the winning
     * carrier has fallen off from, spot market auctions after their deadline passes,
     * auctions for which the on demand offer failed. Only applies to auctions which
     * pickup time is not older than yesterday.
     *  
     * @param {Object} params filter parameters
     * @returns list of action required auctions
     */
    static actionRequired(params) {
        return RestService.instance().get('auction/tendering/action-required', params);
    }

    /**
     * Filters all booked loads, meaning loads for which there is a winning carrier that
     * accepted the offer, used the book now feature or was declared as a winner. If a 
     * carrier is making this request, it lists all loads they are a winning carrier on.
     * Only applies to loads that haven't been canceled or completed yet.
     * 
     * @param {Object} params filter parameters
     * @returns list of booked load auctions
     */
    static booked(params) {
        return RestService.instance().get('auction/tendering/booked', params);
    }

    /**
     * Filters all historic loads, meaning loads that have been canceled or completed, or
     * that require a Broker actions but their pickup time is older than yesterday.
     * If a carrier is making this request, it lists all loads that they have completed.
     * 
     * @param {Object} params filter parameters
     * @returns list of historic load auctions
     */
    static history(params) {
        return RestService.instance().get('auction/tendering/history', params);
    }

    /**
     * Lists all loads the user can access that have the searched string in their business id.
     * If a broker is making this request, it also lists auctions which have a candidate with a
     * searched string in their carrier business id.
     * 
     * @param {Object} params search params
     * @returns list of searched load auctions
     */
    static search(params) {
        return RestService.instance().get('auction/tendering/search', params);
    }

    /**
     * Lists all loads the user can access. Supports filtering them by load business id, phase or status.
     * 
     * @param {Object} params filter params
     * @returns list of load auctions
     */
    static list(params) {
        return RestService.instance().get('auction/tendering', params);
    }

    /**
     * Checks if spot market phase has passed
     * 
     * @param {string} auctionId
     * @returns {Promise}
     */
    static hasSpotMarketPhasePassed(auctionId) {
        return RestService.instance().get(`auction/tendering/${ auctionId }/has-spot-market-phase-passed`);
    }

    /**
     * Gets download rate confirmation url
     * 
     * @param {string} auctionId id of the auction
     * @returns rate confirmation url
     */
    static getDownloadRateConfirmationURL(auctionId) {
        return `auction/tendering/${ auctionId }/rate-confirmation`;
    }
}
