import { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationItem from 'component/notifications/NotificationItem';
import Notification from 'model/Notification'

import './NotificationOverlay.scss';

export default class NotificationOverlay extends Component {

    static propTypes = {
        notifications: PropTypes.arrayOf(Notification.propType()),
        onNotificationRead: PropTypes.func,
        onNotificationClose: PropTypes.func,
        onNotificationTimeout: PropTypes.func
    }

    static defaultProps = {
        notifications: [],
        onNotificationRead: () => { /* */ },
        onNotificationClose: () => { /* */ },
        onNotificationTimeout: () => { /* */ }
    }

    async _closeNotification(id) {
        await this.props.onNotificationClose(id);
    }

    async _seeNotification(id) {
        await this.props.onNotificationRead(id);
    }

    async _onNotificationTimeout(id) {
        await this.props.onNotificationTimeout(id);
    }

    render() {
        const notificationNodes = this.props.notifications.map(n => (
            <NotificationItem
                key={ n.id }
                id={ n.id }
                notification={ n }
                timeoutSeconds={ 5 }
                onNotificationTimeout={ this._onNotificationTimeout.bind(this) }
                onClose={ this._closeNotification.bind(this) }
                onSeen={ this._seeNotification.bind(this) }
            />
        ));

        if (!this.props.notifications || this.props.notifications.length <= 0) {
            return <></>;
        }

        return (
            <div className="notification-overlay">
                { notificationNodes }
            </div>
        );
    }
}
