import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * A Pseudo-Wrapper component for tabs. It is necessary that all content inside a Tabs container is wrapped in this.
 * The main purpose is to ensure that the component requires the id and title props to properly render the navigation
 * and to control url parts.
 */
export default class Tab extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
        activeIcon: PropTypes.object,
        inactiveIcon: PropTypes.object,
        default: PropTypes.bool
    }

    static defaultProps = {
        default: false
    }

    render() {
        // Just render the contents. No need for any wrapping.
        return this.props.children;
    }
}
