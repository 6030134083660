import * as Constants from 'common/constants';
import RestService from 'service/RestService';
import DateUtils from 'utils/DateUtils';

export default class AuctionUtils {

    /**
     * Find exclusion object for this candidate if exists. Exclusion object has a candidate field which indicates
     * that that candidate is excluded from the waterfall process of this auction.
     *
     * @param {Object} auction
     * @param {Object} candidate
     * @returns {Object} exclusion object or undefined
     */
    static findCandidateExclusion(auction, candidate) {
        return auction.exclusions.find(exclusion => exclusion.candidate.carrier.id === candidate.carrier.id);
    }

    /**
     * Finds the latest bid made by a dedicated carrier
     * 
     * @param {Array} bids
     * @returns {Object} bid
     */
    static findLatestDedicatedBid(bids) {
        const dedicatedBids = bids.filter(bid => Constants.CANDIDATE_TYPE.SIGNED_CARRIER === bid.candidate.type);
        if (dedicatedBids.length === 0) {
            return null;
        }

        return dedicatedBids.reduce((a,b) => a.time > b.time ? a : b);
    }

    /**
     * Finds the next dedicated carrier after the current candidate
     *
     * @param {Object} auction
     * @param {Object} currentCandidate
     * @returns {Object} candidate
     */
    static findNextDedicatedCandidate(auction, currentCandidate) {
        const { candidates } = auction;
        return candidates.find(candidate => {
            return Constants.CANDIDATE_TYPE.SIGNED_CARRIER === candidate.type &&
                candidate.position > currentCandidate.position &&
                !this.findCandidateExclusion(auction, candidate);
        });
    }

    /**
     * Finds and returns the current bid for an auction.
     * The most relevant bid at any point is the one that has the current flag set to true.
     *
     * @param {Array} bids
     * @param {string} candidateType
     * @returns {Object}
     */
    static findCurrentBid(bids, candidateType = null) {
        const result = bids.filter(bid => ((candidateType && bid.candidate.type === candidateType) || !candidateType) && bid.current === true);

        if (result.length === 0) {
            return null;
        } else if (result.length > 1) {
            console.error('There is more than one relevant bid', result);
        }

        return result[0];
    }

    /**
     * Finds and returns the relevant bid of a given carrier for an auction.
     *
     * @param {Array} bids
     * @param {string} carrierId
     * @returns {Object}
     */
    static findRelevantBidOfCarrier(bids, carrierId) {
        const result = bids.filter(bid => ((bid.candidate || {}).carrier || {}).id === carrierId && bid.relevantForUser === true);

        if (result.length === 0) {
            return null;
        } else if (result.length > 1) {
            console.error('There is more than one bid that is marked as the relevant for the carrier.', result);
        }

        return result[0];
    }

    /**
     * Finds and returns the winning bid for an auction.
     *
     * @param {Array} bids
     * @returns {Object}
     */
    static findWinningBid(bids) {
        const result = bids.filter(bid => Constants.WINNING_BID_STATUSES.includes(bid.status));

        if (result.length === 0) {
            return null;
        } else if (result.length > 1) {
            console.error('There is more than one bid that is marked as confirmed or winner.', result);
        }

        return result[0];
    }

    /**
     * Determines the time remaining between now and the most relevant expiration and formats it to include the time,
     * unit and descriptor of whether it is in the past or future.
     *
     * @param {Object} auction
     * @param {Object} currentOffer
     * @returns {Array<String>}
     * @private
     */
    static determineTimeRemaining(auction, currentOffer) {
        const now = Date.now();
        let expirationDate;

        if (!currentOffer) {
            expirationDate = new Date(auction.deadline);
        } else if (Constants.TERMINAL_BID_STATUSES.includes(currentOffer.status)) {
            expirationDate = new Date(currentOffer.updated);
        } else if (Constants.OFFERING_AUCTION_STATUSES.includes(auction.status) && Constants.AUCTION_PHASE.DEDICATED === auction.phase) {
            expirationDate = new Date(currentOffer.expiration);
        } else if (Constants.ONGOING_AUCTION_STATUSES.includes(auction.status)) {
            expirationDate = new Date(auction.deadline);
        } else if (Constants.AUCTION_STATUSES.READY === auction.status) {
            expirationDate = new Date(auction.load.pickupTime);
        } else if (Constants.TERMINAL_AUCTION_STATUSES.includes(auction.status)) {
            expirationDate = new Date(auction.load.deliveryTime);
        } else {
            expirationDate = new Date(auction.deadline);
        }

        return DateUtils.findTimeBetween(now, expirationDate);
    }

    /**
     * Determines whether the date is less than threshold minutes from current date
     *
     * @param {Date} date
     * @param {number} threshold default 20
     * @returns {boolean}
     * @private
     */
    static isCloseToExpiration(date, threshold = 20) {
        const minutesUntilExpiration = DateUtils.getMinuteDifference(new Date(), date);
        return minutesUntilExpiration <= threshold;
    }

    /**
     * Determines whether the auction is completed. Auction is considered completed if its status is CANCELED,
     * or the status of the relevant load is COMPLETED.
     *
     * @param {Object} auction
     * @returns
     */
    static isAuctionCompleted(auction) {
        if (auction) {
            return Constants.AUCTION_STATUSES.CANCELED === auction.status || Constants.STARTED_LOAD_STATUSES.includes(auction.load.status);
        }
        return false;
    }

    /**
     * Parses the auction id from the notification
     *
     * @param {Object} notification
     * @returns {string} parsed auction id
     */
    static parseFromNotification(notification) {
        return JSON.parse(notification).auctionId;
    }

    /**
     * Fetches bid falloff reason if the bid is in FALLOFF or FALLOFF_BY_BROKER status by calling
     * the appropriate api determined using the account of the logged in user
     *
     * @param {Object} bid
     * @param {Object} account
     * @returns {Promise}
     */
    static fetchBidFalloffReason(bid, account) {
        if (bid !== null && Constants.FALLOFF_BID_STATUSES.indexOf(bid.status) !== -1 && account) {
            const isBroker = account.roles.indexOf(Constants.USER_ROLES_MAPPING.BROKER) !== -1;
            const isAdministrator = account.roles.indexOf(Constants.USER_ROLES_MAPPING.ADMINISTRATOR) !== -1;
            const apiPrefix = isBroker || isAdministrator ? 'broker' : 'carrier';
            const apiUrl = `${ apiPrefix }/auction/tendering/bid/${ bid.id }/falloff-reason`;
            return RestService.instance().get(apiUrl);
        }

        return Promise.resolve();
    }

    /**
     * Fetches the rejection data for the bid in case the status is REJECTED_BY_BROKER
     *
     * @param {Object} bid
     * @param {Object} account
     * @returns {Promise}
     */
    static fetchRejectedOfferData(bid, account) {
        if (bid !== null && Constants.BID_STATUSES.REJECTED_BY_BROKER === bid.status && account) {
            const isBroker = account.roles.indexOf(Constants.USER_ROLES_MAPPING.BROKER) !== -1;
            const isAdministrator = account.roles.indexOf(Constants.USER_ROLES_MAPPING.ADMINISTRATOR) !== -1;
            const apiPrefix = isBroker || isAdministrator ? 'broker' : 'carrier';
            const apiUrl = `${ apiPrefix }/auction/tendering/bid/${ bid.id }/reject-reason`;
            return RestService.instance().get(apiUrl);
        }

        return Promise.resolve();
    }

    /**
     * Checks if auction is visible to carrier. Auction is visible to carrier in following cases:
     *      - Auction is in the SM phase
     *      - Auction was in the SM phase
     *      - Carrier's relevant bid exists
     *
     * @param {Object} auction
     * @param {Object} relevantBid
     * @param {boolean} hasSpotMarketPhasePassed
     * @returns {boolean}
     */
    static isAuctionVisibleToCarrier(auction, relevantBid, hasSpotMarketPhasePassed) {
        return Constants.AUCTION_PHASE.SPOT_MARKET === auction.phase
            || hasSpotMarketPhasePassed
            || relevantBid;
    }

    /**
     * Finds and returns all Spot Market candidates from the provided list of candidates.
     *
     * @param {Array} candidates
     * @returns {Array}
     */
    static findSpotMarketCandidates(candidates) {
        return candidates.filter(candidate => Constants.CANDIDATE_TYPE.SPOT_MARKET === candidate.type);
    }

    /**
     * Finds and returns all Spot Market bids from the provided list of bids.
     *
     * @param {Array} bids
     * @returns {Array}
     */
    static findSpotMarketBids(bids) {
        return bids.filter(bid => Constants.CANDIDATE_TYPE.SPOT_MARKET === bid.candidate.type);
    }

    /**
     * Determines whether the auction is in one of the allowed statuses for declaring a winner.
     *
     * @param {Object} auction
     * @returns {boolean}
     */
    static canDeclareWinner(auction) {
        return Constants.DECLARE_WINNER_ENABLED_STATUSES.indexOf(auction.status) !== -1;
    }

    /**
     * Finds and returns the most relevant Spot Market bid. The relevance of bids is determined by the following
     * hierarchy:
     * 1. The bid that has the current flag set to true
     * 2. The most recently updated bid that has its status set to FALLOFF or FALLOFF_BY_BROKER
     * 3. The most recent bid
     *
     * @param {Array} bids
     * @returns {Object}
     */
    static findMostRelevantSpotMarketBid(bids) {
        const spotMarketBids = AuctionUtils.findSpotMarketBids(bids);

        let mostRecentFalloffBid = null, mostRecentBid = null;
        for (const bid of spotMarketBids) {
            if (bid.current === true) {
                return bid;
            }

            if (Constants.FALLOFF_BID_STATUSES.indexOf(bid.status) !== -1 && (!mostRecentFalloffBid || DateUtils.isBefore(new Date(mostRecentFalloffBid.updated), new Date(bid.updated)))) {
                mostRecentFalloffBid = bid;
            }

            if (!mostRecentBid || DateUtils.isBefore(new Date(mostRecentBid.time), new Date(bid.time))) {
                mostRecentBid = bid;
            }
        }

        return (mostRecentFalloffBid ? mostRecentFalloffBid : mostRecentBid);
    }
}
