import PropTypes from 'prop-types';

import SafeStateComponent from 'component/SafeStateComponent';
import TitledCardComponent from 'component/card/TitledCardComponent';
import LaneVisualization from 'component/lane/LaneVisualization';
import Separator from 'component/Separator';
import SubmitRFPBid from 'component/rfp/SubmitRFPBid';
import RFPOfferActions from 'component/rfp/RFPOfferActions';
import RFPLaneStatus from 'component/rfp/RFPLaneStatus';
import RestService from 'service/RestService';
import NumberUtils from 'utils/NumberUtils';
import RFPUtils from 'utils/RFPUtils';
import {
    RFP_AUCTION_STATUSES,
    RFP_BID_STATUSES
} from 'common/constants';

export default class RFPLaneCardBiddingContent extends SafeStateComponent {

    constructor(props) {
        super(props);

        this.state = {
            bid: undefined,
            loading: true
        };

        this._fetchBid = this._fetchBid.bind(this);
        this._onOfferActionPerformed = this._onOfferActionPerformed.bind(this);
    }

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        rfpLane: PropTypes.object.isRequired,
        rejectReasons: PropTypes.array,
        version: PropTypes.number,
        onUpdated: PropTypes.func
    };

    static defaultProps = {
        rejectReasons: [],
        version: 0,
        onUpdated: () => {/**/}
    }

    componentDidMount() {
        super.componentDidMount();
        this._fetchBid();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.version !== this.props.version) {
            this._fetchBid();
        }
    }

    _fetchBid() {
        RestService.instance()
            .get(`auction/rfp/${ this.props.rfp.id }/lane/${ this.props.rfpLane.id }/bid/relevant`)
            .then(bid => this.setState({
                bid,
                loading: false
            }));
    }

    _onOfferActionPerformed() {
        this._fetchBid();
        this.props.onUpdated();
    }

    _formActionButtons() {
        const isConfirmationRound = RFPUtils.isConfirmationLatest(this.props.rfp);

        if (this.state.bid && RFP_BID_STATUSES.PROPOSED === this.state.bid.status && isConfirmationRound && RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status) {
            return (
                <RFPOfferActions bid={ this.state.bid }
                                 rejectReasons={ this.props.rejectReasons }
                                 onActionPerformed={ this._onOfferActionPerformed } />
            );
        } else if (RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status && ((!this.state.bid && !isConfirmationRound) || RFP_BID_STATUSES.PROPOSED === (this.state.bid || {}).status)) {
            return (
                <SubmitRFPBid
                    rfp={ this.props.rfp }
                    rfpLane={ this.props.rfpLane }
                    bidPresent={ !!this.state.bid }
                    onBid={ this._fetchBid }
                    onSubmitInitialBid={ this.props.onUpdated }
                />
            );
        } else {
            return <></>;
        }
    }

    render() {
        const isConfirmationLatest = RFPUtils.isConfirmationLatest(this.props.rfp);

        let awardedVolume = '-';
        if (this.state.bid && isConfirmationLatest) {
            awardedVolume = this.state.bid.awardedVolume;
        } else if (this.state.bid) {
            awardedVolume = this.state.bid.awardedVolume || '-';
        }

        const bid = this.state.bid || {};

        let bidLost = false;
        if (RFP_BID_STATUSES.REJECTED === bid.status 
            || (isConfirmationLatest && !this.state.bid)
            || (isConfirmationLatest && RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS !== this.props.rfp.status && RFP_BID_STATUSES.PROPOSED === bid.status)) {
            bidLost = true;
        }

        let priceStyle = '';
        if (!isConfirmationLatest && bid.category) {
            priceStyle = bid.category.toLowerCase();
        }

        return (
            <>
                <div className="rfp-lane-card-visualization-container">
                    <LaneVisualization lane={ this.props.rfpLane } className={ bidLost ? 'lost' : '' } labeled={ true } />
                </div>

                <Separator orientation="vertical" />

                <TitledCardComponent title="Per Mile"
                                     align="right"
                                     className={ `rfp-lane-card-column-container price-per-mile ${ priceStyle }` }>
                    <strong className={ !this.state.bid || bidLost ? 'light' : '' }><b>{ bid.price ? '$' + NumberUtils.formatWithDecimalNotation(bid.price) : 'No Bid' }</b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                <TitledCardComponent title="Per Load"
                                     align="right"
                                     className={ `rfp-lane-card-column-container price-per-load ${ priceStyle }` }>
                    <strong className={ !this.state.bid || bidLost ? 'light' : '' }><b>{ bid.price ? '$' + NumberUtils.formatWithDecimalNotation(bid.price * this.props.rfpLane.distance) : 'No Bid' }</b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                <TitledCardComponent title="Bid Vol."
                                     align="right"
                                     className="rfp-lane-card-column-container bid-vol">
                    <strong><b>{ bid.volume || '-' }<span className="light">/{ this.props.rfpLane.volume }</span></b></strong>
                </TitledCardComponent>

                <Separator orientation="vertical" />

                { (!RFPUtils.isReview(this.props.rfp) || RFPUtils.isConfirmationReview(this.props.rfp)) &&
                    <>
                        <TitledCardComponent title="Awarded Vol."
                                             align="right"
                                             className="rfp-lane-card-column-container awarded-vol">
                            <strong><b>{ awardedVolume }<span className="light">/{ this.props.rfpLane.volume }</span></b></strong>
                        </TitledCardComponent>

                        <Separator orientation="vertical" />
                    </>
                }

                <RFPLaneStatus rfp={ this.props.rfp }
                               rfpLane={ this.props.rfpLane }
                               bid={ this.state.bid }
                               className="rfp-lane-card-column-container bid-status"/>

                <div className="rfp-lane-card-column-container actions">
                    { this.state.loading ? <></> : this._formActionButtons() }
                </div>
            </>
        );
    }
}
