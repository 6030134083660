import { Component } from 'react';
import PropTypes from 'prop-types';
import './TimeDisplay.scss';

/**
 * The TimeDisplay Component.
 * Handles displaying expiration time in different places with different styles.
 */
export default class TimeDisplay extends Component {

    static propTypes = {
        duration: PropTypes.array,
        descriptor: PropTypes.string,
        numberStyle: PropTypes.string
    }

    static defaultProps = {
        duration: ['0', 'min'],
        descriptor: null,
        numberStyle: 'default'
    }

    _getElement(index, value, style) {
        return <span key={ index } className={ style }>{ value }</span>;
    }

    _formTimeDisplay() {
        const { duration, descriptor, numberStyle } = this.props;
        const numberClass = "number " + numberStyle;
        const descriptionClass = "description " + numberStyle;

        const content = [];

        // A few seconds part
        if (isNaN(duration[0])) {
            if (descriptor) {
                duration.push(descriptor);
            }
            content.push(this._getElement(0, duration.join(" ") + "...", "emphasized"));
            return content;
        }

        content.push(this._getElement(0, duration[0], numberClass));
        content.push(this._getElement(1, duration[1], descriptionClass));

        if (duration.length > 3) {
            content.push(this._getElement(2, duration[2], numberClass));
            content.push(this._getElement(3, duration[3], descriptionClass));
        }

        if (descriptor) {
            content.push(this._getElement(4, ' ' + descriptor, descriptionClass));
        }

        return content;
    }

    render() {
        return (
            <div className="time-display">
                { this._formTimeDisplay() }
            </div>
        );
    }
}
