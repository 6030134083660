import { Component } from 'react';
import PropTypes from 'prop-types';

import { TRIGGER_TYPES } from 'common/constants';
import CSVUpload from 'component/CSVUpload';
import LaneCarrierApiService from 'service/api/LaneCarrierApiService';
import StringUtils from 'utils/StringUtils';

export default class LaneCarrierCSVUpload extends Component {

    static propTypes = {
        lane: PropTypes.object,
        bulk: PropTypes.bool,
        size: PropTypes.string,
        trigger: PropTypes.oneOf([TRIGGER_TYPES.BUTTON, TRIGGER_TYPES.LABEL]),
    };

    static defaultProps = {
        lane: null,
        bulk: false,
        size: 'small',
        trigger: TRIGGER_TYPES.BUTTON
    }

    constructor(props) {
        super(props);

        this._uploadCSV = this._uploadCSV.bind(this);
        this._onResponseReceived = this._onResponseReceived.bind(this);
    }

    state = {
        numberOfAddedItems: null,
        errorMessage: null
    };

    _uploadCSV(file, delimiter) {
        const formData = new FormData();
        formData.append('document', file);

        if (!this.props.bulk) {
            return LaneCarrierApiService.addToLaneByCSV(this.props.lane.id, formData, delimiter);
        }

        return LaneCarrierApiService.addByCSV(formData, delimiter);
    }

    _onResponseReceived(numberOfAddedItems, errorMessage) {
        this.setState({ numberOfAddedItems, errorMessage });
    }

    _createDescription() {
        if (!this.props.bulk) {
            return (
                <div className="csv-upload-descriptipn">
                    <p>
                        Add carriers to the <b>Active</b> list of spot market carriers
                        by uploading a CSV file that contains the
                        following columns: <b>User Business ID</b>
                    </p>
                    <p className="description-section">
                        If there are any excluded or terminated carriers,
                        they will not be added to the <b>Active</b> list.
                    </p>
                    <p className="description-section">
                        All changes will apply to newly created auctions on
                        this lane.
                    </p>
                </div>
            );
        }

        return (
            <div className="csv-upload-descriptipn">
                <p>
                    Add spot market carriers to lanes by uploading a CSV file that contains the column <b> User Business ID.</b>
                </p>
                <p className="description-section">
                    Further specify the lanes by including any of these optional columns:
                    <b> Origin City, Origin State, Origin Country, Destination City, Destination State, Destination Country, Lane Type </b> (Dry, Reefer or Flatbed)<b>.</b>
                </p>
                <p className="description-section">If there are any excluded carriers, they will not be added to <b>Active</b> lists on lanes from which they were excluded.</p>
                <p className="description-section">Changes will apply to newly created auctions.</p>
            </div>
        );
    }

    _getUploadPopupProps() {
        const title = 'Add SM Carriers via CSV';
        const buttonText = 'Add Carriers';
        const triggerText = 'Add SM Carriers via CSV';
        const description = this._createDescription();
        const triggerButtonType = this.props.bulk ? 'tertiary' : 'primary';
        const labels = { title, buttonText, triggerText };

        const loadingElement = (
            <div>
                <h6>Adding Carriers</h6>
                <small>This will only take a moment. Please wait...</small>
            </div>
        );

        let successDescription, templateReference = null;
        const { numberOfAddedItems } = this.state;
        if (this.props.bulk) {
            successDescription = (
                <>
                    <b>{ numberOfAddedItems }</b> { StringUtils.pluralize(numberOfAddedItems, 'row', 's') } { numberOfAddedItems === 1 ? 'has': 'have' } been processed.
                </>
            );

            templateReference = LaneCarrierApiService.BULK_TEMPLATE_ENDPOINT;
        } else {
            successDescription = (
                <>
                    <b>{ numberOfAddedItems }</b> { StringUtils.pluralize(numberOfAddedItems, 'carrier', 's') } { numberOfAddedItems === 1 ? 'has': 'have' } been successfully added.
                </>
            );

            templateReference = LaneCarrierApiService.TEMPLATE_ENDPOINT;
        }

        const successElement = (
            <div>
                <h6>Carriers Added</h6>
                <small>{ successDescription }</small>
            </div>
        );

        const emptySuccessElement = (
            <div>
                <h6>No New Carriers Added</h6>
                <small>
                    { this.props.bulk ? 'All lists' : 'The list' } of spot market carriers { this.props.bulk ? 'remain' : 'remains' } unchanged.<br />
                    If this is unexpected, make sure all of the rows in the CSV file contain new and valid data or that no <b>excluded</b> or <b>terminated</b> carriers were added.
                </small>
            </div>
        );

        const errorElement = (
            <div>
                <h6>File Not Uploaded</h6>
                <small>
                    { this.state.errorMessage }
                    <br />
                    <br />
                    Please make sure that the uploaded CSV file contains all of the required columns,
                    that all rows contain valid data, and that you have selected the correct delimiter.
                </small>
            </div>
        );

        return {
            labels,
            triggerButtonType,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        };
    }

    render() {
        const {
            labels,
            triggerButtonType,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        } = this._getUploadPopupProps();

        return (
            <CSVUpload 
                size={ this.props.size }
                trigger={ this.props.trigger }
                withUnloadConfirmation={ true }
                labels={ labels }
                triggerButtonType={ triggerButtonType }
                description={ description }
                loadingElement={ loadingElement }
                successElement={ successElement }
                emptySuccessElement={ emptySuccessElement }
                errorElement={ errorElement }
                uploadCSVPromise={ this._uploadCSV }
                onResponseReceived={ this._onResponseReceived }
                templateReference={ templateReference }
            />
        );
    }
}
