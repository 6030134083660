import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown as down,
    faAngleUp as up
} from '@fortawesome/pro-light-svg-icons';

import Separator from 'component/Separator';

import './ContentCard.scss';

export default class ContentCard extends Component {

    static propTypes = {
        isExpandable: PropTypes.bool,
        initiallyExpanded: PropTypes.bool,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        headingSeparator: PropTypes.bool,
        subheading: PropTypes.any,
        info: PropTypes.node,
        expandLabel: PropTypes.string,
        collapseLabel: PropTypes.string,
        actions: PropTypes.node,
        menu: PropTypes.node,
        infoPopover: PropTypes.node,
        children: PropTypes.any,
        tooltip: PropTypes.node,
        className: PropTypes.string,
        onExpandedStateChanged: PropTypes.func
    }

    static defaultProps = {
        isExpandable: false,
        initiallyExpanded: true,
        heading: null,
        headingSeparator: true,
        subheading: null,
        info: null,
        expandLabel: null,
        collapseLabel: null,
        actions: null,
        menu: null,
        infoPopover: null,
        children: null,
        className: '',
        onExpandedStateChanged: () => { /* */ }
    }

    state = {
        isExpanded: this.props.initiallyExpanded
    }

    componentDidUpdate(prevProps, _prevState) {
        if (this.props.initiallyExpanded !== prevProps.initiallyExpanded) {
            this.setState({
                isExpanded: this.props.initiallyExpanded
            }, () => this.props.onExpandedStateChanged(this.state.isExpanded));
        }
    }

    _toggleExpand() {
        this.setState(previousState => ({
            isExpanded: !previousState.isExpanded
        }), () => this.props.onExpandedStateChanged(this.state.isExpanded));
    }

    render() {
        const expandableStyle = this.props.isExpandable ? 'expandable' : '';
        const expandedStyle = this.state.isExpanded ? 'expanded' : 'not-expanded';

        let expansionView = ( <></> );
        if (this.props.isExpandable) {

            let infoLabel;
            if (this.props.expandLabel || this.props.collapseLabel) {
                infoLabel = <small className="info-label"> { this.state.isExpanded ? this.props.collapseLabel : this.props.expandLabel } </small>;
            }

            expansionView = (
                <span className="expansion-view">
                    { infoLabel }
                    <FontAwesomeIcon size="lg" className="expand-icon" icon={ this.state.isExpanded ? up : down } />
                </span>
            );
        }

        let children = ( <></> );
        if (this.state.isExpanded) {
            const separator = this.props.heading && this.props.headingSeparator ? <Separator className="separator" /> : <></>;
            children = (
                <>
                    { separator }
                    <div className="col-12 content-card-content">
                        { this.props.children }
                    </div>
                </>
            );
        }

        let actions;
        if (this.props.actions) {
            actions = (
                <div className="actions">
                    { this.props.actions }
                </div>
            );
        }

        let menu;
        if (this.props.menu) {
            menu = (
                <div className="menu">
                    { this.props.menu }
                </div>
            );
        }

        let infoPopover;
        if (this.props.infoPopover && this.state.isExpanded) {
            infoPopover = (
                <div className="popover">
                    { this.props.infoPopover }
                </div>
            );
        }

        return (
            <div className={ `component content-card row ${ expandedStyle } ${ this.props.className }` }>
                <div className={ `col-12 header ${ expandableStyle }` } onClick={ this.props.isExpandable ? this._toggleExpand.bind(this) : null }>
                    <h6 className="heading">
                        { this.props.heading }
                    </h6>
                    { this.props.subheading }
                    <div className="info-group">
                        { infoPopover }
                        { this.props.info }
                        { actions }
                        { menu }
                        { expansionView }
                    </div>
                </div>
                { children }
            </div>
        );
    }
}
