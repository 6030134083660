import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload as download } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import { FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import DownloadFileComponent from 'component/DownloadFileComponent';
import CustomFileInput from 'component/form/CustomFileInput';
import FormStatusModal from 'component/form/FormStatusModal';
import ObjectUtils from 'utils/ObjectUtils';

import './UploadFilePopup.scss';

export default class UploadFilePopup extends Component {

    static _defaultLabels = {
        title: 'Add File',
        uploadSubtitle: 'Upload File',
        buttonText: 'Add File'
    };

    static propTypes = {
        show: PropTypes.bool,
        labels: PropTypes.shape({
            title: PropTypes.string,
            uploadSubtitle: PropTypes.string,
            acceptedFormats: PropTypes.string,
            buttonText: PropTypes.string
        }),
        loadingElement: PropTypes.element,
        successElement: PropTypes.element,
        emptySuccessElement: PropTypes.element,
        errorElement: PropTypes.element,
        trigger: PropTypes.element.isRequired,
        children: PropTypes.element, // Other elements of the popup controlled by the parent
        uploadStatus: PropTypes.oneOf(Object.values(FORM_STATUSES)),
        addDisabled: PropTypes.bool,
        acceptedFormats: PropTypes.string,
        onUploadFile: PropTypes.func,
        onClose: PropTypes.func,
        templateReference: PropTypes.string,
        delimiter: PropTypes.string
    }

    static defaultProps = {
        show: false,
        labels: UploadFilePopup._defaultLabels,
        loadingElement: this._formDefaultStatusElement('Uploading File', 'This will take only a moment. Please wait...'),
        successElement: this._formDefaultStatusElement('File Uploaded', 'Your file was sucessfully uploaded.'),
        errorElement: this._formDefaultStatusElement('File Not Uploaded', 'There was an error while uploading. Please try again.'),
        children: <></>,
        uploadStatus: null,
        addDisabled: false,
        acceptedFormats: null,
        onUploadFile: () => { /* */ },
        onClose: () => { /* */ },
        templateReference: null,
        delimiter: ','
    }

    constructor(props) {
        super(props);
        this._onCancel = this._onCancel.bind(this);
        this._onSelectedFileChange = this._onSelectedFileChange.bind(this);
        this._onAddFile = this._onAddFile.bind(this);
    }

    state = {
        file: null,
        labels: UploadFilePopup._defaultLabels
    }

    componentDidMount() {
        this.setState(prevState => {
            const labels = { ...prevState.labels, ...this.props.labels };
            return { labels };
        });
    }

    componentDidUpdate(prevProps) {
        if (!ObjectUtils.equal(prevProps.labels, this.props.labels)) {
            this.setState(prevState => {
                const labels = { ...prevState.labels, ...this.props.labels };
                return { labels };
            });
        }
    }

    _onSelectedFileChange(file) {
        this.setState({ file: file });
    }

    _isAddDisabled() {
        return !this.state.file || this.props.addDisabled;
    }

    _onCancel(event) {
        if (event) {
            event.preventDefault();
        }

        if (FORM_STATUSES.LOADING === this.props.uploadStatus) {
            return;
        }

        this.setState({ file: null });
        this.props.onClose();
    }

    _onAddFile(event) {
        event.preventDefault();
        this.props.onUploadFile(this.state.file);
    }

    static _formDefaultStatusElement(title, text) {
        return (
            <div>
                <h6>{ title }</h6>
                <small>{ text }</small>
            </div>
        );
    }

    _formStatusLayout() {
        let content;
        switch (this.props.uploadStatus) {
            case FORM_STATUSES.LOADING:
                content = this.props.loadingElement;
                break;
            case FORM_STATUSES.SUCCESS:
                content = this.props.successElement;
                break;
            case FORM_STATUSES.EMPTY_SUCCESS:
                content = this.props.emptySuccessElement;
                break;
            case FORM_STATUSES.ERROR:
                content = this.props.errorElement;
                break;
            default:
                return <></>;
        }

        return (
            <FormStatusModal status={ this.props.uploadStatus } onContinue={ this._onCancel }>
                { content }
            </FormStatusModal>
        );
    }

    _formUploadLayout() {
        return (
             <div className="upload-file-popup">
                <h6 className="heading">
                    { this.state.labels.title }
                </h6>

                <div className="popup-section">
                    { this.props.children }
                </div>

                <div className="popup-section">
                    <small className="subheading">
                        <div className="upload-prompt">
                            { this.state.labels.uploadSubtitle }
                            <span className="accepted-formats"> { this.state.labels.acceptedFormats }</span>
                        </div>
                        { this.props.templateReference != null ? this._formDownloadTemplateAction() : <></> }
                    </small>
                    <CustomFileInput id="custom-file-upload-region"
                                     file={ this.state.file }
                                     acceptedFormats={ this.props.acceptedFormats }
                                     onSelectedFileChange={ this._onSelectedFileChange } />
                </div>

                <div className="buttons-wrapper">
                    <Button
                        id="custom-file-upload-cancel-button"
                        type="tertiary"
                        onClick={ this._onCancel }
                    >
                        Cancel
                    </Button>
                    <Button
                        id="custom-file-upload-button"
                        type="primary"
                        loading={ this.state.loading }
                        disabled={ this._isAddDisabled() }
                        onClick={ this._onAddFile }
                    >
                        { this.state.labels.buttonText }
                    </Button>
                </div>
            </div>
        );
    }

    _formDownloadTemplateAction() {
        const { templateReference, delimiter } = this.props;

        return (
            <DownloadFileComponent
                trigger={
                    <div className="download-template-action">
                        <FontAwesomeIcon className="action-icon" icon={ download } />
                        <small className="action-name">
                            CSV Template
                        </small>
                    </div>
                }
                reference={{ endpoint: templateReference, params: { delimiter } }}
                isStaticResource={ false }
            />
        );
    }

    render() {
        let content;
        if (this.props.uploadStatus) {
            content = this._formStatusLayout();
        } else {
            content = this._formUploadLayout();
        }

        return (
            <div className="upload-file-popup-container">
                <Popup
                    id="popup-upload-file"
                    size="medium"
                    show={ this.props.show }
                    onClose={ this._onCancel }
                    trigger={ this.props.trigger }
                >
                   { content }
                </Popup>
            </div>
        );
    }
}
