import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTruck as activeTruckIcon, 
    faUsers as activeUsersIcon, 
    faUserTie as brokerIcon,
    faBriefcase as rfpManagerIcon, 
    faLaptop as adminIcon 
} from '@fortawesome/pro-solid-svg-icons';
import { faTruck as inactiveTruckIcon, faUsers as inactiveUsersIcon } from '@fortawesome/pro-light-svg-icons';

import { DEFAULT_PAGE_NUMBER, SORT_DIRECTIONS, USER_ROLES, USER_STATUS } from 'common/constants';
import CanAccess from 'component/CanAccess';
import Dropdown from 'component/Dropdown';
import PageHeader from 'component/PageHeader';
import Tag from 'component/Tag';
import AddNewCarrier from 'component/carrier/AddNewCarrier';
import Search from 'component/filter/Search';
import Select from 'component/form/CustomSelect';
import CustomLoader from 'component/form/CustomLoader';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Tab from 'component/navigation/Tab';
import Pagination from 'component/navigation/Pagination';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableCell from 'component/table/custom/TableCell';
import AuthorizationService from 'service/AuthorizationService';
import CarrierApiService from 'service/api/CarrierApiService';
import UserApiService from 'service/api/UserApiService';
import DateUtils from 'utils/DateUtils';

import './UsersOverview.scss';

const PAGINATION_VALUES = [
    { size: 12 },
    { size: 24 },
    { size: 36 }
];
const CARRIERS_STATUS_VALUES = [
    { title: 'All', field: 'ALL' },
    { title: 'Active', field: 'ACTIVE' },
    { title: 'Terminated', field: 'TERMINATED' }
];
const SHIPEX_MEMBERS_STATUS_VALUES = [
    { title: 'All', field: 'ALL' },
    { title: 'Active', field: 'ACTIVE' },
    { title: 'Pending', field: 'PENDING' },
    { title: 'Removed', field: 'REMOVED' }
];
const SORT_OPTIONS = {
    NAME: 'NAME',
    CREATED: 'CREATED'
};
const TABS = {
    CARRIERS: 'carriers',
    SHIPEX_MEMBERS: 'shipex-members'
};
const USER_ROLES_LABELS = {
    [USER_ROLES.BROKER]: {
        label: 'Broker',
        icon: brokerIcon
    },
    [USER_ROLES.RFP_MANAGER]: {
        label: 'RFP Manager',
        icon: rfpManagerIcon
    },
    [USER_ROLES.ADMINISTRATOR]: {
        label: 'Administrator',
        icon: adminIcon
    }
};
const USER_STATUS_STYLE_MAPPING = {
    [USER_STATUS.ACTIVE.field]: 'active-user',
    [USER_STATUS.PENDING.field]: 'pending-user',
    [USER_STATUS.REMOVED.field]: 'terminated-user'
};

export default class UsersOverview extends Component {
    static propTypes = {
        account: PropTypes.object
    };

    static defaultProps = {
        account: null
    };

    _hasCarrierReadPermission = AuthorizationService.instance().canUserAccess(this.props.account, 'carrier:read');

    constructor(props) {
        super(props);

        this.state = {
            selectedStatus: null,
            activeTab: this._getValidTab(),
            users: {
                data: [],
                pageNumber: DEFAULT_PAGE_NUMBER,
                pageSize: 12,
                available: 0
            },
            loading: false,
            selectedUserId: null,
            showRolesPopover: false
        };

        this._fetchUsers = this._fetchUsers.bind(this);
        this._onPageNumberChange = this._onPageNumberChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
        this._onSelectStatus = this._onSelectStatus.bind(this);
        this._showRolesPopover = this._showRolesPopover.bind(this);
        this._closeRolesPopover = this._closeRolesPopover.bind(this);
    }

    componentDidMount() {
        const status = this._getParamFromUrl(this.props, 'status');
        const statusValues = this._getStatusValues(this.state.activeTab);

        this.setState({
            selectedStatus: status ? statusValues.find(statusValue => statusValue.field === status)?.title : 'All',
        }, this._fetchUsers);
    }

    componentDidUpdate(prevProps) {
        const previousStatus = this._getParamFromUrl(prevProps, 'status');
        const status = this._getParamFromUrl(this.props, 'status');

        const previousSearch = this._getParamFromUrl(prevProps, 'userSearch');
        const search = this._getParamFromUrl(this.props, 'userSearch');

        const previousSort = this._getParamFromUrl(prevProps, 'sort');
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (prevProps.location.pathname !== this.props.location.pathname ||
            previousStatus !== status ||
            previousSearch !== search ||
            previousSort !== sort) {
            const activeTab = this._getValidTab();
            const statusValues = this._getStatusValues(activeTab);
            
            this.setState({
                activeTab,
                selectedStatus: statusValues.find(statusValue => statusValue.field === status)?.title,
                users: {
                    data: [],
                    pageNumber: DEFAULT_PAGE_NUMBER,
                    pageSize: 12,
                    available: 0
                },
                loading: true
            }, this._fetchUsers);
        }
    }

    _getValidTab() {
        const activeTab = this.props.location.pathname.split('/').pop();
        
        for (const tab in TABS) {
            if (TABS[tab] === activeTab) {
                return activeTab;
            }
        }

        return TABS.CARRIERS;
    }

    _getStatusValues(activeTab) {
        switch (activeTab) {
            case TABS.CARRIERS:
                return CARRIERS_STATUS_VALUES;
            case TABS.SHIPEX_MEMBERS:
                return SHIPEX_MEMBERS_STATUS_VALUES;
            default:
                // This should never happen
                console.error(`Unrecognized tab ${ activeTab }`);
        }

        return CARRIERS_STATUS_VALUES;
    }

    _fetchUsers() {
        this.setState({ loading: true });

        const statusValues = this._getStatusValues(this.state.activeTab);
        const sort = this._getParamFromUrl(this.props, 'sort');
        const status = statusValues.find(statusValue => statusValue.title === this.state.selectedStatus)?.field;

        let active = null;
        switch (status) {
            case USER_STATUS.ACTIVE.field:
                active = true;
                break;
            case 'TERMINATED':
                active = false;
                break;
            default:
                // Nothing to do here
        }

        const params = {
            pageSize: this.state.users.pageSize,
            pageNumber: this.state.users.pageNumber,
            status: status !== 'ALL' ? status : null,
            active,
            search: this._getParamFromUrl(this.props, 'userSearch'),
            sort: sort ? [sort, ''] : null
        };

        switch (this.state.activeTab) {
            case TABS.CARRIERS:
                CarrierApiService.list(params)
                    .then(users => {
                        if (TABS.CARRIERS === this.state.activeTab) {
                            this.setState({ users, loading: false });
                        }
                    })
                    .catch(error => console.error('An error occurred while fetching carriers.', error));
                break;
            case TABS.SHIPEX_MEMBERS:
                UserApiService.list({ ...params, roles: [USER_ROLES.BROKER, USER_ROLES.RFP_MANAGER, USER_ROLES.ADMINISTRATOR] })
                    .then(users => {
                        if (TABS.SHIPEX_MEMBERS === this.state.activeTab) {
                            this.setState({ users, loading: false });
                        }
                    })
                    .catch(error => console.error('An error occurred while fetching users.', error));
                break;
            default:
                // This should never happen
                console.error(`Unrecognized tab ${ this.state.activeTab }`);
        }
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _onPageNumberChange(pageNumber) {
        this.setState(previousState => ({
            users: {
                ...previousState.users,
                pageNumber
            }
        }), this._fetchUsers);
    }

    _onPageSizeChange(pageSize) {
        this.setState(previousState => ({
            users: {
                ...previousState.users,
                pageSize
            }
        }), this._fetchUsers);
    }

    _onSelectStatus(_value, status) {
        if (status) {
            status = JSON.parse(status.key);
            const searchParams = new URLSearchParams(this.props.location?.search);

            if ('ALL' === status.field) {
                this.setState({
                    selectedStatus: null
                });

                searchParams.delete('status');
            } else {
                this.setState({
                    selectedStatus: status.title
                });

                searchParams.set('status', status.field);
            }

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSort(value, direction) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        if (SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete('sort');
        } else {
            searchParams.set('sort', `${ value },${ direction }`);
        }

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value) {
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (!sort) {
            return SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }

    _showRolesPopover(selectedUserId) {
        this.setState({ selectedUserId, showRolesPopover: true });
    }

    _closeRolesPopover() {
        this.setState({ showRolesPopover: false });
    }

    _formatRoles(user) {
        let roles = [];

        for (const role of user.roles) {
            roles.push(
                <Tag 
                    className="user-role-label main-role" 
                    key={ role.id }
                    text={ USER_ROLES_LABELS[role.name].label }
                    leftIcon={ <FontAwesomeIcon icon={ USER_ROLES_LABELS[role.name].icon } /> }
                    closeable={ false }
                />
            );
        }

        if (user.roles.length > 2) {
            // Display all roles in a popover
            const allRoles = roles;

            // Only keep the first two elements
            roles = roles.slice(0, 2);

            roles.push(
                <div className="user-roles-popover" key="user-role-label">
                    <Dropdown 
                        id="user-roles-popover"
                        direction="right"
                        show={ this.state.showRolesPopover && user.id === this.state.selectedUserId }
                        onClose={ this._closeRolesPopover }
                        trigger={(
                            <a href="#!" onClick={ () => this._showRolesPopover(user.id) }>
                                <Tag 
                                    className="user-role-label additional-roles" 
                                    text={ `+${ user.roles.length - 2 }` }
                                    closeable={ false }
                                />
                            </a>
                        )}
                    >
                        <div className="user-roles-popover-content">
                            { allRoles }
                        </div>
                    </Dropdown>
                </div>
            );            
        }

        return roles;
    }

    _getUserStatusAndStyle(user) {
        let status, style;

        if (!!user.status) {
            status = SHIPEX_MEMBERS_STATUS_VALUES.find(statusValue => statusValue.field === user.status)?.title;
            style = USER_STATUS_STYLE_MAPPING[user.status];
        } else if (user.active) {
            status = 'Active';
            style = USER_STATUS_STYLE_MAPPING.ACTIVE;
        } else {
            status = 'Terminated';
            style = USER_STATUS_STYLE_MAPPING.REMOVED;
        }

        return { status, style };
    }

    render() {
        const { activeTab, users, loading } = this.state;

        const availableActions = (
            <div className="actions-container">
                <div className="action-field search">
                    <Search 
                        placeholder="Search by user's name, email address..." 
                        label="Search" 
                        fieldName="userSearch" 
                        allowClear={ true } 
                        replace={ false } 
                    />
                </div>
                <div className="action-field status">
                    <Select
                        selectedValue={ this.state.selectedStatus || 'All' }
                        onSelect={ this._onSelectStatus }
                        values={ this._getStatusValues(activeTab) }
                        label="Status"
                    />
                </div>
                { TABS.CARRIERS === activeTab && (
                    <CanAccess
                        action="carrier:write"
                        yes={ <div className="action-field"><AddNewCarrier /></div> }
                    />
                ) }
            </div>
        );

        const searchResultsDisplayed = !!this._getParamFromUrl(this.props, 'userSearch') || !!this._getParamFromUrl(this.props, 'status');

        let content, pagination;
        if (loading) {
            content = (
                 <div className="user-loader">
                    <CustomLoader size="large" />
                    <p>Loading { this._getValidTab() === TABS.CARRIERS ? 'carriers' : 'ShipEX members' }...</p>
                </div>
            );
        } else {
            if (users.data.length > 0) {
                content = (
                    <Table className="users-table">
                        <TableRow isHeader={ true }>
                            <TableHeaderCell
                                sortable={ true }
                                onSort={ direction => this._onSort(SORT_OPTIONS.NAME, direction) }
                                sortDirection={ this._getSortDirection(SORT_OPTIONS.NAME) }
                            >
                                Name
                            </TableHeaderCell>
                            { TABS.SHIPEX_MEMBERS === activeTab && <TableHeaderCell>Role</TableHeaderCell>}
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell>Phone</TableHeaderCell>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell
                                alignment="right"
                                sortable={ true }
                                onSort={ direction => this._onSort(SORT_OPTIONS.CREATED, direction) }
                                sortDirection={ this._getSortDirection(SORT_OPTIONS.CREATED) }
                            >
                                Date Added
                            </TableHeaderCell>
                        </TableRow>
                        { this.state.users.data.map(user => {
                            const { status, style } = this._getUserStatusAndStyle(user);
    
                            return (
                                <TableRow key={ user.id } fontSize="small" className={ style }>
                                    <TableCell padding="small" className="user-name-content" title={ user.name }>
                                        { TABS.CARRIERS === activeTab && this._hasCarrierReadPermission ? <Link to={ `/carrier/${ user.id }` }>{ user.name }</Link> : user.name }
                                    </TableCell>
                                    { TABS.SHIPEX_MEMBERS === activeTab && <TableCell padding="small">{ this._formatRoles(user) }</TableCell>}
                                    <TableCell padding="small" className="user-email-content" title={ user.email }>{ user.email }</TableCell>
                                    <TableCell padding="small" className="user-phone-content">{ user.phone || '----' }</TableCell>
                                    <TableCell padding="small" className="user-status">{ status }</TableCell>
                                    <TableCell padding="small" alignment="right">{ user.dateAdded ? DateUtils.format(new Date(user.dateAdded)) : '-' }</TableCell>
                                </TableRow>
                            );
                        }) }
                    </Table>
                );
    
                pagination = (
                    <Pagination
                        pageNumber={ users.pageNumber }
                        pageSize={ users.pageSize }
                        available={ users.available }
                        paginationValues={ PAGINATION_VALUES }
                        onSetPage={ this._onPageNumberChange }
                        onSetPageSize={ this._onPageSizeChange }
                        resultsText={ this._getValidTab() === TABS.CARRIERS ? 'carriers' : 'members' }
                        colorTheme="light"
                    />
                );
            } else if (!searchResultsDisplayed) {
                content = (
                    <div className="users-list empty-list">
                        <FontAwesomeIcon className="users-icon" icon={ activeUsersIcon } />
                        <h5>No Users Here</h5>
                        <p>There are currently no available users to show on this screen.</p>
                    </div>
                );
            } else {
                content = (
                    <div className="users-list empty-list">
                        <FontAwesomeIcon className="users-icon" icon={ activeUsersIcon } />
                        <h5>No Users Here</h5>
                        <p>No results matched your search.</p>
                    </div>
                );
            }
        }

        return (
            <div className="page users-list-page">
                <PageHeader 
                    title="Users Overview" 
                    tooltip="View a comprehensive overview of all users within the application, including their roles."
                />
                <div className="users-tabs">
                    <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                        <Tab default id={ TABS.CARRIERS } title="Carriers" activeIcon={ activeTruckIcon } inactiveIcon={ inactiveTruckIcon }>
                            { availableActions }
                            { content }
                            { pagination }
                        </Tab>
                        <Tab id={ TABS.SHIPEX_MEMBERS } title="ShipEX Members" activeIcon={ activeUsersIcon } inactiveIcon={ inactiveUsersIcon }>
                            { availableActions }
                            { content }
                            { pagination }
                        </Tab>
                    </NavigableTabs>
                </div>
            </div>
        );
    }
}
