import { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from "antd";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

import Tooltip from 'component/Tooltip';

import 'antd/dist/antd.css';
import './CustomRangePicker.scss';

const { RangePicker } = DatePicker;

export default class CustomRangePicker extends Component {

    static propTypes = {
        label: PropTypes.string,
        className: PropTypes.string,
        tooltipText: PropTypes.string,
        size: PropTypes.oneOf(['small', 'regular']),
        dateFrom: PropTypes.instanceOf(moment),
        dateTo: PropTypes.instanceOf(moment),
        dateFormat: PropTypes.string,
        allowClear: PropTypes.bool,
        showTime: PropTypes.bool,
        handleChange: PropTypes.func,
        disabledDate: PropTypes.func
    };

    static defaultProps = {
        label: null,
        className: '',
        tooltipText: null,
        size: 'regular',
        dateFrom: null,
        dateTo: null,
        dateFormat: 'YYYY-MM-DD',
        allowClear: true,
        showTime: false,
        handleChange: () => { /* */ },
        disabledDate: () => false,
    };

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(key) {
        if (!key) {
            this.props.handleChange(key);
            return;
        }

        let from = key[0];
        let to = key[1];

        if (this.props.dateFrom && to && to.isBefore(this.props.dateFrom)) {
            from = null;
        } else if (this.props.dateTo && from && from.isAfter(this.props.dateTo)) {
            to = null;
        } else if (!from) {
            from = this.props.dateFrom;
        } else if (!to) {
            to = this.props.dateTo;
        }

        this.props.handleChange([from, to]);
    }

    render() {
        const showTime = this.props.showTime ? { defaultValue: moment() } : false;
        
        let tooltip = <></>;
        if (this.props.label && this.props.tooltipText) {
            tooltip = (
                <div className="icon-div">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="right">
                        { this.props.tooltipText }
                    </Tooltip>
                </div>
            );
        }

        let label = <></>;
        if (this.props.label) {
            label = (
                <small className="label">
                    { this.props.label }
                    { tooltip }
                </small>
            );
        }

        return (
            <div className={ `custom-range-picker-container ${ this.props.className }` }>
                { label }
                <RangePicker 
                    className={ this.props.size }
                    format={ this.props.dateFormat }
                    allowEmpty={ [true, true] }
                    suffixIcon={ null }
                    placeholder={ ["From", "To"] }
                    value={ [ this.props.dateFrom, this.props.dateTo ] }
                    separator={ <div className=""> <FontAwesomeIcon icon={ faArrowRight } /> </div>}
                    onChange={ this._onChange }
                    allowClear={ this.props.allowClear }
                    showTime={ showTime }
                    disabledDate={ this.props.disabledDate }
                />
            </div>
        );
    }
}
