import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasksAlt as activityIcon } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar as calendarIcon } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, ACTIVITY_TYPES, ACTIVITY_TYPE_OPTIONS } from 'common/constants';
import Search from 'component/filter/Search';
import Select from 'component/form/CustomSelect';
import CascadeSelect from 'component/form/CustomCascadeSelect';
import ActivityEmptyState from 'component/activity/ActivityEmptyState';
import ActivityLogInfiniteScroll from 'component/activity/ActivityLogInfiniteScroll';
import CarrierActivityLogApiService from 'service/api/CarrierActivityLogApiService';

import './MemberActivityLog.scss';

const TIMESTAMP = [
    { title: 'Last 7 days', field: moment().subtract(7, 'days').startOf('day'), keyWord: '7_DAYS' },
    { title: 'Last 15 days', field: moment().subtract(15, 'days').startOf('day'), keyWord: '15_DAYS' },
    { title: 'Last 30 days', field: moment().subtract(30, 'days').startOf('day'), keyWord: null },
    { title: 'Last 3 months', field: moment().subtract(3, 'months').startOf('day'), keyWord: '3_MONTHS' }
];

class MemberActivityLog extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this._incrementPageNumber = this._incrementPageNumber.bind(this);

        this._onSelectType = this._onSelectType.bind(this);
        this._onUnselectType = this._onUnselectType.bind(this);
        this._onClearType = this._onClearType.bind(this);

        this._onSelectTime = this._onSelectTime.bind(this);
    }

    state = {
        activities: [],
        pageNumber: DEFAULT_PAGE_NUMBER,
        selectedTypes: [],
        hasMore: false,
        isLoading: false,
        createdBefore: new Date(),
        selectedTime: null
    }

    componentDidMount() {
        this._populateFromURL();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevSearch = this._getParamFromUrl(prevProps, 'locationSearch');
        const search = this._getParamFromUrl(this.props, 'locationSearch');

        const prevTimestamp = this._getParamFromUrl(prevProps, 'time');
        const timestamp = this._getParamFromUrl(this.props, 'time');

        const changeOccurred = prevSearch !== search
            || prevTimestamp !== timestamp
            || prevState.selectedTypes !== this.state.selectedTypes;

        if (changeOccurred && this.state.pageNumber !== DEFAULT_PAGE_NUMBER) {
            this.setState({ pageNumber: DEFAULT_PAGE_NUMBER });
        } else if (prevState.pageNumber !== this.state.pageNumber || changeOccurred) {
            this._fetchActivity();
        }
    }

    _fetchActivity() {
        this.setState({ isLoading: true });
        const activityProps = { 
            pagination: {
                pageNumber: this.state.pageNumber,
                pageSize: DEFAULT_PAGE_SIZE
            },
            members: [this.props.user.id],
            createdBefore: this.state.createdBefore,
            locationSearch: this._getParamFromUrl(this.props, 'locationSearch'),
            activityTypes: this.state.selectedTypes.length !== 0 ? this._getAllParamsFromUrl(this.props, 'activity') : null,
            createdAfter: TIMESTAMP.find(time => time.keyWord === this._getParamFromUrl(this.props, 'time'))?.field
        }

        CarrierActivityLogApiService.filter(this.props.user.carrier.id, activityProps)
            .then((activities) => {
                const hasMore = (this.state.pageNumber * DEFAULT_PAGE_SIZE) < activities.available;
                this.setState(prevState => {
                    return {
                        activities: this.state.pageNumber !== DEFAULT_PAGE_NUMBER ? prevState.activities.concat(activities.data) : activities.data,
                        hasMore,
                        isLoading: false
                    };
                });
            });
    }
    
    _populateFromURL() {
        const timestamp = this._getParamFromUrl(this.props, 'time');
        const selectedTime= TIMESTAMP.find(time => time.keyWord === timestamp)?.title;

        const activityTypes = this._getAllParamsFromUrl(this.props, 'activity');
        const selectedTypes = Object.values(ACTIVITY_TYPES).filter(activity => activityTypes.includes(activity.value)).map(a => a.label);
        
        this.setState({ selectedTime, selectedTypes });
    }

    _incrementPageNumber() {
        this.setState(prevState => { 
            return { pageNumber: prevState.pageNumber + 1 };
        });
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _getAllParamsFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).getAll(param);
    }

    _clearParamFromURL(param) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        searchParams.delete(param);

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

   _onSelectType(value) {
        const activityType = ACTIVITY_TYPES[value];
        this.setState((prevState) => {
            return { selectedTypes: prevState.selectedTypes.concat(activityType.label) };
        });

        const searchParams = new URLSearchParams(this.props.location?.search);

        searchParams.append('activity', value);

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _onUnselectType(value) {
        const activityType = ACTIVITY_TYPES[value];
        this.setState((prevState) => {
            const selectedTypes = [ ...prevState.selectedTypes ];
            const index = selectedTypes.findIndex(type => type === activityType.label);

            if (index > -1) {
                selectedTypes.splice(index, 1);
            }

            return { selectedTypes };
        });

        const searchParams = new URLSearchParams(this.props.location?.search);
        const newSearchParamArray = [];

        for (const [key, newValue] of searchParams.entries()) {
            const activityTypeValue = ACTIVITY_TYPES[value];
            if (newValue !== value && this.state.selectedTypes.indexOf(activityTypeValue.label) !== -1) {
                newSearchParamArray.push([key, newValue]);
            }
        }

            const newSearchParams = new URLSearchParams(newSearchParamArray);
            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + newSearchParams.toString()
            });
            
    }

    _onClearType() {
        this.setState({ selectedTypes: [] });
        this._clearParamFromURL('activity');
    }

    _onSelectTime(_value, time) {
        if (time) {
            time = JSON.parse(time.key);
            const searchParams = new URLSearchParams(this.props.location?.search);
            
            this.setState({
                selectedTime: time.title
            });

            if ('Last 30 days' === time.title) {
                searchParams.delete('time');
            } else {
                searchParams.set('time', time.keyWord);
            }

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _formActions() {
        return (
            <div className="actions-container">
                <div className="action-field search">
                    <Search placeholder="Search by lane, routing guide..." label="Search" fieldName="locationSearch" allowClear={ true } replace={ false } />
                </div>
                <div className="action-field activity-type">
                    <CascadeSelect
                        label="Activity Type"
                        values={ ACTIVITY_TYPE_OPTIONS }
                        multiple={ true }
                        onSelect={ this._onSelectType }
                        onUnselect={ this._onUnselectType }
                        onClear={ this._onClearType }
                        allowClear={ true }
                        selectedValue={ this.state.selectedTypes }
                        placeholder="All Types"
                    />
                </div>
            </div>   
        );
    }

    _formContent() {
        if (this.state.activities.length !== 0) {
            return(
                <ActivityLogInfiniteScroll
                    activities={ this.state.activities }
                    hasMore={ this.state.hasMore }
                    isLoading={ this.state.isLoading }
                    fetchMoreData={ this._incrementPageNumber }
                    className="user-activity-infinite-scroll" 
                />
            );
        } 
        
        return (
            <ActivityEmptyState className="activity-log-empty-state" />
        );
    }

    render() {
        return (
            <div className="user-activity-log">
                <div className="heading">
                    <div className="activity-log-information">
                        <h5 className="title">
                            <FontAwesomeIcon icon={ activityIcon } className="icon" />
                            Activity
                        </h5>
                        <strong className="description">
                            Activity log based on all user interactions.
                        </strong>
                    </div>
                    <div className="timestamp">
                        <Select
                            selectedValue={ this.state.selectedTime || 'Last 30 days' }
                            onSelect={ this._onSelectTime }
                            values={ TIMESTAMP }
                            prefixIcon={ <FontAwesomeIcon icon={ calendarIcon } className="icon" />}
                        />
                    </div>
                </div>
                { this._formActions() }
                { this._formContent() }
            </div>
        );
    }
}

export default withRouter(MemberActivityLog);
