import PropTypes from 'prop-types';
import Auth from 'model/bootstrap/Auth';

export default class Configuration {

    static propType = () => PropTypes.shape({
        auth: Auth.propType(),
        pendoApiKey: PropTypes.string
    })

    /**
     * @param auth {Auth}
     * @param pendoApiKey {string}
     */
    constructor({ auth, pendoApiKey }) {
        this.auth = auth;
        this.pendoApiKey = pendoApiKey;
    }
}
