import WSComponent from 'component/WSComponent';
import { faExclamationTriangle as warningIcon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'component/Tooltip';

import './ConnectionMonitor.scss';

export default class ConnectionMonitor extends WSComponent {
    constructor(props) {
        super(props);

        this.state = {
            connectionError: this.props.ws.isInitializedWithError()
        };

        this.onConnect = () => this.setState({ connectionError: false });
        this.onDisconnect = () => this.setState({ connectionError: true });
    }

    render() {
        if (!this.state.connectionError) {
            return null;
        }

        return (
            <div className="header-connection-monitor">
                <div className="connection-monitor">
                    <span className="connection-monitor-badge">
                        <FontAwesomeIcon icon={ warningIcon } />
                        <Tooltip direction="bottom">
                            Live reloading is currently disabled.<br />Try to refresh your screen or contact your administrator<br />if the issue persists.
                        </Tooltip>
                    </span>

                </div>
            </div>
        );
    }
}