import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouseUser as spotMarket,
    faInfoCircle as info,
    faExclamationTriangle as warning,
    faGavel as gavel,
    faUsers as usersIcon
} from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH as menu } from '@fortawesome/pro-light-svg-icons'; 

import {
    AUCTION_STATUSES,
    AUCTION_PHASE,
    BID_STATUSES,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE,
    FALLOFF_BID_STATUSES,
    SORT_DIRECTIONS
} from 'common/constants';
import ContentCard from 'component/card/ContentCard';
import DeclareWinnerPopup from 'component/load/DeclareWinnerPopup';
import EditBookNowPricePopup from 'component/load/EditBookNowPricePopup';
import Dropdown from 'component/Dropdown';
import Pagination from 'component/navigation/Pagination';
import TextEmphasisBox from 'component/TextEmphasisBox';
import TimeDisplay from 'component/TimeDisplay';
import Tooltip from 'component/Tooltip';
import WSComponent from 'component/WSComponent';
import SimpleTabs from 'component/navigation/SimpleTabs';
import Tab from 'component/navigation/Tab';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableEmptyState from 'component/table/TableEmptyState';
import SpotMarketBidTableRow from 'component/load/SpotMarketBidTableRow';
import SpotMarketParticipantsTableRow from 'component/load/SpotMarketParticipantsTableRow';
import Search from 'component/filter/Search';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';
import TenderAuctionCandidateApiService from 'service/api/TenderAuctionCandidateApiService';
import WebSocketService from 'service/WebSocketService';
import AuctionUtils from 'utils/AuctionUtils';
import DocumentUtils from 'utils/DocumentUtils';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './SpotMarketDetailsCard.scss';

const BID_PAGINATION_VALUES = [
    { size: 5 },
    { size: 10 },
    { size: 15 }
];

const TABS = {
    BIDS: 'bids',
    INVITED_PARTICIPANTS: 'invited-participants'
};

const SORT_OPTIONS = {
    NAME: 'NAME',
    ORIGIN: 'ORIGIN',
};

const DEFAULT_BIDS_PAGE_SIZE = 5;

class SpotMarketDetailsCard extends WSComponent {
    static propTypes = {
        ws: PropTypes.instanceOf(WebSocketService),
        isExpandable: PropTypes.bool,
        initiallyExpanded: PropTypes.bool,
        heading: PropTypes.string,
        expandLabel: PropTypes.string,
        collapseLabel: PropTypes.string,
        auction: PropTypes.object.isRequired,
        disableBookNowEdit: PropTypes.bool,
        account: PropTypes.object,
        cardState: PropTypes.oneOf(['current', 'future', 'past', 'skipped']),
        onDeclareWinner: PropTypes.func,
        onChangeBookNow: PropTypes.func
    }

    static defaultProps = {
        ws: WebSocketService.instance(),
        isExpandable: false,
        initiallyExpanded: true,
        heading: null,
        expandLabel: null,
        collapseLabel: null,
        disableBookNowEdit: false,
        account: null,
        onDeclareWinner: () => { /* */ },
        onChangeBookNow: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.subscriptions = [
            {
                topic: `/topic/auctions/${ this.props.auction.id }/candidates`,
                handler: this._handleBidsUpdate.bind(this)
            },
            {
                topic: `/topic/auctions/${ this.props.auction.id }/spot-market/bid`,
                handler: this._handleBidsUpdate.bind(this)
            }
        ];

        this.state = {
            isExpanded: this.props.initiallyExpanded,
            showFocusedBidDropdown: false,
            falloffReason: null,
            documentTypes: null,
            bids: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_BIDS_PAGE_SIZE,
            bidsAvailable: 0,
            relevantBid: null,
            activeTab: TABS.BIDS,
            invitedParticipants: [],
            participantsAvailable: 0,
            spotMarketBidsSearch: '',
            spotMarketParticipantsSearch: ''
        }

        this._setPageSize = this._setPageSize.bind(this);
        this._setPageNumber = this._setPageNumber.bind(this);
        this._onSetBookNowPrice = this._onSetBookNowPrice.bind(this);
        this._onTabChange = this._onTabChange.bind(this);
        this._formBidsTable = this._formBidsTable.bind(this);
        this._formParticipantsTable = this._formParticipantsTable.bind(this);
        this._fetchInvitedParticipants = this._fetchInvitedParticipants.bind(this);
        this._setSpotMarketSearchValue = this._setSpotMarketSearchValue.bind(this);
        this._setIsExpanded = this._setIsExpanded.bind(this);
        this._onDeclareWinner = this._onDeclareWinner.bind(this);
        this._onOpenFocusedBidDropdown = this._onOpenFocusedBidDropdown.bind(this);
        this._onCloseFocusedBidDropdown = this._onCloseFocusedBidDropdown.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        this._fetchDocumentTypes();
        this._fetchInvitedParticipants();
        await this._fetchMostRelevantBid();
        this._fetchBids();

        if (AUCTION_PHASE.SPOT_MARKET !== this.props.auction.phase && AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.auction.status) {
            this.setState({ activeTab: TABS.INVITED_PARTICIPANTS });
            this._setPageSize(DEFAULT_PAGE_SIZE);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const previousSort = this._getParamFromUrl(prevProps, 'sort');
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (prevState.pageNumber !== this.state.pageNumber
            || prevState.pageSize !== this.state.pageSize
            || prevState.relevantBid !== this.state.relevantBid
            || prevState.spotMarketBidsSearch !== this.state.spotMarketBidsSearch
            || prevState.spotMarketParticipantsSearch !== this.state.spotMarketParticipantsSearch
            || previousSort !== sort
            || prevState.isExpanded !== this.state.isExpanded) {
                this._fetchBids();
                this._fetchInvitedParticipants();
            }

        if (prevState.isExpanded !== this.state.isExpanded) {
            this.setState({ spotMarketBidsSearch: '', spotMarketParticipantsSearch: '' })
        }

        if (prevProps.auction.status !== this.props.auction.status) {
            this._handleBidsUpdate();
        }
    }

    _handleBidsUpdate() {
        this._fetchBids();
        this._fetchMostRelevantBid();
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _clearParamsFromURL(params) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        params.forEach(param => searchParams.delete(param));

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    async _fetchDocumentTypes() {
        const documentTypes = await DocumentUtils.fetchDocumentTypes(false);
        this.setState({ documentTypes });
    }

    _onDeclareWinner() {
        this.setState({
            showFocusedBidDropdown: false
        });

        this.props.onDeclareWinner();
    }

    _onOpenFocusedBidDropdown(event) {
        event.stopPropagation();
        this.setState({ showFocusedBidDropdown: true });
    }

    _onCloseFocusedBidDropdown() {
        this.setState({ showFocusedBidDropdown: false });
    }

    _setPageNumber(pageNumber) {
        this.setState({ pageNumber });
    }

    _setPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _setSpotMarketSearchValue(searchValue) {
        if (TABS.BIDS === this.state.activeTab) {
            this.setState({ spotMarketBidsSearch: searchValue });
        } else {
            this.setState({ spotMarketParticipantsSearch: searchValue });
        }
    }

    _onSort(value, direction) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        if (SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete('sort');
        } else {
            searchParams.set('sort', `${ value },${ direction }`);
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value) {
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (!sort) {
            return SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }

    _fetchBids() {
        TenderAuctionBrokerApiService.getSpotMarketBids(
            this.props.auction.id,
            this.state.relevantBid?.id,
            this.state.spotMarketBidsSearch,
            this.state.pageNumber,
            this.state.pageSize
        ).then(bids => this.setState({ bids: bids.data, bidsAvailable: bids.available }))
         .catch(error => console.error('Failed to fetch spot market bids.', error));
    }

    _fetchInvitedParticipants() {
        const sort = this._getParamFromUrl(this.props, 'sort');

        TenderAuctionCandidateApiService.filterSMCandidates(
            this.props.auction.id,
            this.state.spotMarketParticipantsSearch,
            this.state.pageNumber,
            this.state.pageSize,
            sort ? [sort, ""] : null
        ).then(result => this.setState({ invitedParticipants: result.data, participantsAvailable: result.available }))
        .catch(error => console.error('Failed to fetch invited participants.', error));
    }

    async _fetchMostRelevantBid() {
       const result = await TenderAuctionBrokerApiService.getMostRelevantSpotMarketBid(this.props.auction.id);

       this.setState({ relevantBid: result });
    }

    _fetchBidFalloffReason(bid) {
        AuctionUtils.fetchBidFalloffReason(bid, this.props.account).then(falloffReason => {
            if (falloffReason) {
                this.setState({
                    falloffReason: falloffReason.reason
                });

                return falloffReason.reason;
            }
        });

        return null;
    }

    _onSetBookNowPrice(price) {
        this.props.onChangeBookNow(this.props.auction.id, price);
    }

    _onTabChange(tabId) {
        if (!Object.values(TABS).includes(tabId)) {
            console.error('Unrecognized tab id.');
            return;
        } else if (tabId === this.state.activeTab) {
            // Nothing to do here
            return;
        }

        this.setState({
            activeTab: tabId,
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: TABS.BIDS === tabId ? DEFAULT_BIDS_PAGE_SIZE : DEFAULT_PAGE_SIZE,
            spotMarketBidsSearch: '',
            spotMarketParticipantsSearch: ''
        });
    }

    _bidEmphasisStatusMapping(bid) {
        switch (bid.status) {
            case BID_STATUSES.PENDING:
                return {
                    emphasis: 'spot-market',
                    textEmphasisBoxColor: 'blue',
                    text: 'Awaiting Broker\'s Action'
                };
            case BID_STATUSES.CONFIRMED:
                return {
                    emphasis: 'pending',
                    textEmphasisBoxColor: 'orange',
                    text: 'Carrier Details are Missing'
                };
            case BID_STATUSES.WINNER:
                return AUCTION_STATUSES.READY === this.props.auction.status ?
                {
                    emphasis: 'ready',
                    textEmphasisBoxColor: 'cyan',
                    text: 'Ready to go'
                } : {
                    emphasis: 'under-review',
                    textEmphasisBoxColor: 'orange',
                    text: 'Under review'
                };
            case BID_STATUSES.FALLOFF:
            case BID_STATUSES.FALLOFF_BY_BROKER:
                return {
                    emphasis: 'falloff',
                    textEmphasisBoxColor: 'grey',
                    text: 'Falloff'
                };
            default:
                return {
                    emphasis: 'spot-market',
                    textEmphasisBoxColor: 'blue',
                    text: ''
                };
        }
    }

    _formFalloffTooltip(bid) {
        const falloffReason = this.state.falloffReason ? this.state.falloffReason : this._fetchBidFalloffReason(bid);

        return (
            <div className="title-tooltip">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction="bottom">
                    { falloffReason }
                </Tooltip>
            </div>
        );
    }

    /**
     * Forms the status field on the Spot Market card.
     *
     * The status on the Spot Market card is displayed if the Spot Market is either currently going on or happened in the
     * past, but the auction status is something other than Offering to Carriers. If the Spot Market phase has passed, someone
     * from the dedicated phase was declared as the winner and the status is skipped with no timestamp. If the Spot Market
     * phase is the current phase, and there is no relevant bid, the status is awaiting winner declaration with the auction
     * deadine as the timestamp. If there is a relevant bid, the status of the bid is the status that should be displayed.
     *
     * @param {Object} bid the relevant bid the status may refer to
     * @returns {Array} the status text, tooltip and timestamp
     */
    _formStatusDisplay(bid) {
        const { auction, cardState } = this.props;

        let status, tooltip, timestamp;
        if (cardState === 'past') {
            status = <b className="status-label skipped">Skipped</b>;
            tooltip = (
                <div className="title-tooltip">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="bottom">
                        Someone else was declared as the winner.
                    </Tooltip>
                </div>
            );
        } else {
            let duration, timeDescriptor;

            if (bid) {
                let emphasis, statusText;
                let end = new Date(bid.updated);

                if (AUCTION_STATUSES.CANCELED === auction.status) {
                    emphasis = 'canceled';
                    statusText = 'Canceled';
                    end = new Date(auction.updated);
                } else {
                    const mapping = this._bidEmphasisStatusMapping(bid);
                    emphasis = mapping.emphasis;
                    statusText = mapping.text;

                    if (FALLOFF_BID_STATUSES.indexOf(bid.status) !== -1) {
                        tooltip = this._formFalloffTooltip(bid);
                    } else if (BID_STATUSES.PENDING === bid.status) {
                        end = new Date(auction.updated);
                    }
                }

                status = (
                    <b className={ `status-label ${ emphasis }` }>
                        { statusText }
                    </b>
                );

                const start = Date.now();

                [ duration, timeDescriptor ] = DateUtils.findTimeBetween(start, end);
                timestamp = <TimeDisplay duration={ duration } descriptor={ timeDescriptor } numberStyle="normal" />;
            }
        }

        return [status, tooltip, timestamp];
    }

    _formRelevantBidDisplay(bid, distance, shouldIncludeStatus = false) {
        let name, perMilePrice, date;
        let warningIcon, fullPriceDisplay, bidEmphasis, textEmphasisBoxColor;

        if (bid) {
            const candidate = bid.candidate;

            const emphasisStatusMapping = this._bidEmphasisStatusMapping(bid);

            bidEmphasis = emphasisStatusMapping.emphasis;
            textEmphasisBoxColor = emphasisStatusMapping.textEmphasisBoxColor;

            name = (
                <Link to={ `/carrier/${ candidate.carrier.id }` } title={ candidate.carrier.name }>
                    { candidate.carrier.name }
                </Link>
            );
            fullPriceDisplay = (
                <h6 className="field-content">
                    <TextEmphasisBox
                        children= { `$${ NumberUtils.formatWithDecimalNotation(bid.price) }` }
                        color={ textEmphasisBoxColor }
                        size="medium"
                    />
                </h6>
            );
            perMilePrice = `$${ PriceUtils.formatPerMilePrice(bid.price, distance) }`;
            date = DateUtils.format(new Date(bid.time));

            if (!candidate.carrier.active) {
                bidEmphasis += ' error';
                warningIcon = (
                    <div className="icon-wrapper terminated-carrier">
                        <FontAwesomeIcon icon={ warning } className="warning-icon" />
                        <Tooltip direction="bottom">
                            Terminated carrier
                        </Tooltip>
                    </div>
                );
            } else if (DocumentUtils.hasInvalidDocuments(candidate.carrier.documents, this.state.documentTypes)) {
                bidEmphasis += ' error';
                warningIcon = (
                    <div className="icon-wrapper missing-documents">
                        <FontAwesomeIcon icon={ warning } className="warning-icon" />
                        <Tooltip direction="bottom">
                            Missing or expired documents
                        </Tooltip>
                    </div>
                );
            }
        } else {
            name = 'No Bids';
            fullPriceDisplay = (
                <small className="field-content">
                    ----
                </small>
            );
            perMilePrice = date = '----';
        }

        let statusDisplay;
        if (shouldIncludeStatus) {
            const [ status, tooltip, timestamp ] = this._formStatusDisplay(bid);

            if (status) {
                statusDisplay = (
                    <div className="field status">
                        <small className="field-title">
                            Status
                            { status } { tooltip }
                        </small>
                        <small className="field-content">
                            { timestamp }
                        </small>
                    </div>
                );
            }
        }

        return (
            <div className="focused-bid">
                <div className="field name">
                    <small className="field-title spot-market">
                        Spot Market <FontAwesomeIcon icon={ spotMarket } />
                    </small>
                    <strong className={ `field-content ${ bidEmphasis }` }>
                        { warningIcon } { name }
                    </strong>
                </div>
                <div className="field bid-total">
                    <small className="field-title">
                        Total Price
                    </small>
                    { fullPriceDisplay }
                </div>
                <div className="field bid-per-mile">
                    <small className="field-title">
                        Per Mile
                    </small>
                    <small className="field-content">
                        { perMilePrice }
                    </small>
                </div>
                <div className="field date">
                    <small className="field-title">
                        Date of Offer
                    </small>
                    <small className="field-content">
                        { date }
                    </small>
                </div>
                { statusDisplay }
            </div>
        );
    }

    _formPriceOptions() {
        const { auction, cardState, disableBookNowEdit } = this.props;
        const { bookNowPrice, suggestedPrice } = auction;
        const distance = auction.load.routingGuideLane.distance;
        const laneBookNowPrice = auction.load.routingGuideLane.bookNowPrice;

        // The book now price can only be edited if the auction will reach the Spot Market phase in the future on its own.
        let editBookNow;
        if (cardState === 'future' && !disableBookNowEdit) {
            editBookNow = (
                <EditBookNowPricePopup
                    onSubmitPrice={ this._onSetBookNowPrice }
                    initialPrice={ bookNowPrice }
                    distance={ distance }
                    lanePrice={ laneBookNowPrice }
                />
            );
        }

        let suggestedComponent;
        
        const bookNowComponent = (
            <div className="book-now">
                <small className="book-now-label">
                    Book now
                </small>
                <small className="price">
                    { bookNowPrice ? `$${ NumberUtils.formatWithDecimalNotation(bookNowPrice) }` : '-' }                        
                    { editBookNow }
                </small>
            </div>
        );
 
        if (suggestedPrice) {
            suggestedComponent = (
                <div className="suggested">
                    <small>
                        Suggested
                        <div className="icon-div">
                            <FontAwesomeIcon icon={ info } className="icon" />
                            <Tooltip direction="top">
                                This is the suggested DAT price for today.
                            </Tooltip>
                        </div>
                    </small>
                    <small className="price">
                        ${ NumberUtils.formatWithDecimalNotation(auction.suggestedPrice) }
                    </small>
                </div>
            );
        }

        if (bookNowComponent || suggestedComponent) {
            return (
                <div className="price-options">
                    <div className={ `prices ${ bookNowPrice ? 'exists' : '' }` }>
                        { bookNowComponent }
                        { suggestedComponent }
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    _setIsExpanded(isExpanded) {
        this.setState({ isExpanded })
    }

    _formBidsTable() {
        const { bidsAvailable, relevantBid, pageNumber, pageSize, spotMarketBidsSearch } = this.state;
        const { auction } = this.props;
        let bidsTable, bidsPagination;

        if (bidsAvailable > 0 && relevantBid) {
            const showActions = AuctionUtils.canDeclareWinner(auction) && AUCTION_PHASE.SPOT_MARKET === auction.phase;

            bidsPagination = (
                <Pagination 
                    pageNumber={ pageNumber }
                    pageSize={ pageSize }
                    paginationValues={ BID_PAGINATION_VALUES }
                    available={ bidsAvailable }
                    onSetPage={ this._setPageNumber }
                    onSetPageSize={ this._setPageSize }
                    fixed={ false }
                    colorTheme="light"
                />
            );

            const bidTableRows = this.state.bids.map((bid, index) => (
                <SpotMarketBidTableRow
                    key={ bid.id }
                    bid={ bid }
                    auction={ auction }
                    showActions={ showActions }
                    onDeclareWinner={ this.props.onDeclareWinner }
                    lastElement={ index === this.state.bids.length - 1 }
                />
            ));

            bidsTable = (
                <Table className="sm-bids-table">
                    <TableRow isHeader={ true }>
                        <TableHeaderCell className="name">Carrier Name</TableHeaderCell>
                        <TableHeaderCell className="bid" alignment="right">Bid</TableHeaderCell>
                        <TableHeaderCell className="per-mile" alignment="right">Per Mile</TableHeaderCell>
                        <TableHeaderCell className="date-of-bid">Date of Bid</TableHeaderCell>
                        <TableHeaderCell className="actions" alignment="right">Actions</TableHeaderCell>
                    </TableRow>
                    { bidTableRows }
                </Table>
            );
        } else if (bidsAvailable === 0 && !spotMarketBidsSearch) {
            bidsTable = (
                <TableEmptyState
                    icon={ gavel }
                    title="No Previous Bids"
                    description={ <>Once the bids are placed, they will be shown here.</> }
                />
            );
        } else {
            bidsTable = (
                <TableEmptyState
                    icon={ gavel }
                    title="No Previous Bids"
                    description={ <>No results matched your search.</> }
                />
            );
        }

        return (
            <div className="sm-carriers-bids-table">
                { bidsTable }
                { bidsPagination }
            </div>
        );
    }

    _formParticipantsTable() {
        const { invitedParticipants, participantsAvailable } = this.state;
        let participantsTable, participantsPagination;

        if (participantsAvailable > 0) {
            participantsPagination = (
                <Pagination 
                    pageNumber={ this.state.pageNumber }
                    pageSize={ this.state.pageSize }
                    available={ participantsAvailable }
                    onSetPage={ this._setPageNumber }
                    onSetPageSize={ this._setPageSize }
                    fixed={ false }
                    colorTheme="light"
                />
            );

            const participantsTableRows = invitedParticipants.map((participant, index) => (
                <SpotMarketParticipantsTableRow key={ participant.id } candidate={ participant } lastElement={ index === this.state.invitedParticipants.length - 1 }/>
            ));

            participantsTable = (
                <Table className="sm-participants-table">
                    <TableRow isHeader={ true }>
                        <TableHeaderCell
                            className="name"
                            sortable={ true } 
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.NAME, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.NAME) }
                        >
                                Carrier Name
                        </TableHeaderCell>
                        <TableHeaderCell
                            className="origin"
                            sortable={ true } 
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.ORIGIN, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.ORIGIN) }
                        >
                                Origin
                        </TableHeaderCell>
                        <TableHeaderCell className="email">Email</TableHeaderCell>
                        <TableHeaderCell className="phone" alignment="right">Phone</TableHeaderCell>
                    </TableRow>
                    { participantsTableRows }
                </Table>
            );
        } else {
            participantsTable = (
                <TableEmptyState
                    icon={ usersIcon }
                    title="No Invited Participants"
                    description={ <>No results matched your search.</> }
                />
            );
        }

        return (
            <div className="sm-carriers-participants-table">
                { participantsTable }
                { participantsPagination }
            </div>
        );
    }

    _determineCandidateSectionTitle(cardState) {
        let candidateSectionTitle;

        if (cardState === 'current' || cardState === 'past') {
            candidateSectionTitle = 'Invited Participants';
        } else if (cardState === 'skipped') {
            candidateSectionTitle = 'Carriers that would be invited';
        } else if (cardState === 'future') {
            candidateSectionTitle = 'Carriers that will be invited';
        }

        return candidateSectionTitle;
    }

    _formSpotMarketsDetailsTabs() {
        const { auction } = this.props;
        const { bidsAvailable, participantsAvailable } = this.state;

        let tabs = [{
            id: TABS.INVITED_PARTICIPANTS,
            title: 
                <>
                    Invited Participants <span className="number">({ participantsAvailable })</span>
                </>,
            activeTab: TABS.INVITED_PARTICIPANTS === this.state.activeTab,
            formTable: this._formParticipantsTable,
            showSearch: participantsAvailable > 0 || this.state.spotMarketParticipantsSearch,
            searchPlaceholder: 'Search invited participants'
        }];

        if (AUCTION_PHASE.SPOT_MARKET === auction.phase || AUCTION_STATUSES.OFFERING_TO_CARRIERS !== auction.status) {
            tabs.unshift({
                id: TABS.BIDS,
                title:
                    <>
                        Previous Bids <span className="number">({ bidsAvailable })</span>
                    </>,
                activeTab: TABS.BIDS === this.state.activeTab,
                formTable: this._formBidsTable,
                showSearch: bidsAvailable > 0 || this.state.spotMarketBidsSearch,
                searchPlaceholder: 'Search previous bids by carrier name or carrier ID'
            });
        }
        
        const content = tabs.map(tab => (
            <Tab id={ tab.id } title={ tab.title } key={ tab.id } activeTab={ tab.activeTab }>
                <div className={ `sm-candidates-search ${ tab.showSearch ? 'active' : '' }` }>
                    { tab.showSearch && 
                        <Search 
                            placeholder={ tab.searchPlaceholder }
                            allowClear={ true }
                            updateUrl={ false }
                            onSubmit={ this._setSpotMarketSearchValue } 
                        />
                    }
                </div>
                { tab.formTable() }
            </Tab>
        ));

        return (
            <SimpleTabs id="spot-market-details-tabs" onTabChange={ this._onTabChange }>
                { content }
            </SimpleTabs>
        );
    }

    render() {
        const { isExpandable, initiallyExpanded, cardState, auction } = this.props;
        let { expandLabel, collapseLabel } = this.props;
        let heading, priceActions = null, relevantBidMenu = null, bidState = '';

        const isPastCardState = 'past' === cardState;

        if ('current' === cardState || isPastCardState) {
            // Getting here means that the spot market phase is either currently happening or happened in the past. The
            // most relevant bid (or lack thereof) should be in focus in the card header, while the remaining bids should
            // be listed within the content.

            if (this.state.relevantBid || isPastCardState) {
                expandLabel = null;
                collapseLabel = null;
            }

            // The book now and suggested price should only be shown if the auction is in the offering to carriers state. In
            // other possibilities which would land us here, the general status for the card should be shown instead.
            if (AUCTION_STATUSES.OFFERING_TO_CARRIERS === auction.status) {
                heading = this._formRelevantBidDisplay(this.state.relevantBid, auction.load.routingGuideLane.distance, false);
                priceActions = this._formPriceOptions();
            } else {
                heading = this._formRelevantBidDisplay(this.state.relevantBid, auction.load.routingGuideLane.distance, true);
                bidState = this.state.relevantBid ? this._bidEmphasisStatusMapping(this.state.relevantBid).emphasis : '';
            }

            let menuOptions = [];
            if (this.state.relevantBid && this.state.relevantBid.candidate.carrier.active && AuctionUtils.canDeclareWinner(auction) && AUCTION_PHASE.SPOT_MARKET === auction.phase) {
                menuOptions.push(
                    <DeclareWinnerPopup
                        auctionId={ auction.id }
                        bid={ this.state.relevantBid }
                        candidate={ this.state.relevantBid.candidate }
                        onDeclareWinner={ this._onDeclareWinner }
                        onCancelWinnerDeclaration={ this._onCloseFocusedBidDropdown }
                    />
                );
            }

            if (menuOptions.length > 0) {
                relevantBidMenu = (
                    <div className="focused-bid-menu">
                        <Dropdown 
                            id="focused-bid-dropdown"
                            direction="bottom-left"
                            show={ this.state.showFocusedBidDropdown }
                            onClose={ this._onCloseFocusedBidDropdown }
                            trigger={
                                <a href="#!" className="menu-icon-container" onClick={ this._onOpenFocusedBidDropdown }>
                                    <FontAwesomeIcon icon={ menu } className="icon" />
                                </a>
                            }
                            actions={ menuOptions }
                        />
                    </div>
                );
            }
        } else {
            heading = (
                <span className={ `${ cardState }` }>
                    <FontAwesomeIcon className="heading-icon" icon={ spotMarket } />
                    Spot Market
                </span>
            );

            priceActions = this._formPriceOptions();
        }

        return (
            <div className={ `spot-market-details ${ cardState } ${ bidState || 'no-bids' } ` }>
                <ContentCard
                    key={ `spot-market-${ auction.id }` }
                    isExpandable={ isExpandable }
                    initiallyExpanded={ initiallyExpanded }
                    heading={ heading }
                    actions={ priceActions }
                    menu={ relevantBidMenu }
                    expandLabel={ expandLabel }
                    collapseLabel={ collapseLabel }
                    onExpandedStateChanged={ this._setIsExpanded }
                >
                    { this._formSpotMarketsDetailsTabs() }
                </ContentCard>
            </div>
        );
    }
}

export default withRouter(SpotMarketDetailsCard);
