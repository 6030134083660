import { Component } from 'react';
import PropTypes from 'prop-types';

import './TableCell.scss';

export default class TableCell extends Component {
    static propTypes = {
        alignment: PropTypes.oneOf(['left', 'right', 'center']),
        className: PropTypes.string,
        padding: PropTypes.oneOf(['small', 'regular', 'large']),
        sidePadding: PropTypes.bool,
        link: PropTypes.string,
        isLinkEnabled: PropTypes.bool
    }

    static defaultProps = {
        alignment: 'left',
        className: '',
        padding: 'large',
        sidePadding: true,
        link: null,
        isLinkEnabled: true
    }

    render() {
        const { className, alignment, padding, sidePadding, link, isLinkEnabled, children } = this.props;

        let content, style = `table-cell ${ className } ${ alignment } ${ padding } ${ sidePadding ? 'side-padding' : '' } ${ link ? 'linked-table-cell' : '' }`;

        if (link) {
            content = (
                <td className={ style }>
                    <a href={ isLinkEnabled ? link : '#' }>
                        { children }
                    </a>
                </td>
            );
        } else {
            content = (
                <td className={ style }>{ children }</td>
            );
        }

        return content;
    }
}
