import { Component } from 'react';
import PropTypes from 'prop-types';

import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableRow from 'component/table/custom/TableRow';

export default class AuctionTableHeaderRow extends Component {
    static propTypes = {
        isCarrier: PropTypes.bool.isRequired,
        showBookNow: PropTypes.bool,
        showRate: PropTypes.bool,
        showRank: PropTypes.bool,
        showBids: PropTypes.bool,
        showExpiration: PropTypes.bool,
        showCarrier: PropTypes.bool,
        showStatus: PropTypes.bool,
        showActions: PropTypes.bool
    };

    static defaultProps = {
        showBookNow: true,
        showRank: false,
        showRate: true,
        showBids: true,
        showExpiration: false,
        showCarrier: false,
        showStatus: true,
        showActions: false
    };

    render() {
        const { isCarrier, showBookNow, showRate, showRank, showBids, showExpiration, showCarrier, showStatus, showActions } = this.props;

        return (
            <TableRow isHeader={ true } className="auction-table-row">
                <TableHeaderCell className="load">
                    Load
                </TableHeaderCell>
                <TableHeaderCell className="region">
                    Pick Up 
                </TableHeaderCell>
                <TableHeaderCell className="arrow-header-cell">
                    {/* Arrow column */}
                </TableHeaderCell>
                <TableHeaderCell className="region">
                    Delivery 
                </TableHeaderCell>
                { isCarrier && 
                    <TableHeaderCell className="equipment-type">
                        EQ Type
                    </TableHeaderCell>
                }
                <TableHeaderCell alignment="right" className="distance">
                    Miles
                </TableHeaderCell>
                <TableHeaderCell alignment="right" className="weight">
                    Weight
                </TableHeaderCell>
                <TableHeaderCell alignment="right" className="temperature">
                    Temp.
                </TableHeaderCell>
                { showBookNow &&
                    <TableHeaderCell alignment="right" className="book-now">
                        Book Now
                    </TableHeaderCell>
                }
                { showRate &&
                    <TableHeaderCell alignment="right" className="rate">
                        { isCarrier ? 'Your Rate' : 'Rate' }
                    </TableHeaderCell>
                }
                { showRank &&
                    <TableHeaderCell className="rank">
                        Your Rank
                    </TableHeaderCell>
                }
                { showExpiration &&
                    <TableHeaderCell alignment="right" className="expiration">
                        Expiration
                    </TableHeaderCell>
                }
                { showCarrier && 
                    <TableHeaderCell className="carrier">
                        Carrier
                    </TableHeaderCell>
                }
                { showBids &&
                    <TableHeaderCell alignment="right" className="bids">
                        Bids
                    </TableHeaderCell>
                }
                { showActions &&
                    <TableHeaderCell alignment="right">
                        Actions
                    </TableHeaderCell>
                }
                { showStatus &&
                    <TableHeaderCell alignment="right" className="status">
                        Status
                    </TableHeaderCell>
                }
            </TableRow>
        );
    }
}
