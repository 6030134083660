import { Component } from 'react';
import PropTypes from 'prop-types';

import Banner from 'component/Banner';
import CSVUpload from 'component/CSVUpload';
import RestService from 'service/RestService';
import StringUtils from 'utils/StringUtils';

export default class RFPCSVUpload extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        type: PropTypes.oneOf(['lane', 'candidate', 'bid']),
        size: PropTypes.oneOf(['regular', 'small']),
        onCreated: PropTypes.func
    };

    static defaultProps = {
        type: 'lane',
        size: 'regular',
        onCreated: () => { /* */ }
    }

    state = {
        numberOfAddedItems: null,
        errorMessage: null
    }

    constructor(props) {
        super(props);

        this._uploadCSV = this._uploadCSV.bind(this);
        this._onResponseReceived = this._onResponseReceived.bind(this);
    }

    _uploadCSV(file, delimiter) {
        const formData = new FormData();
        formData.append('document', file);

        return RestService.instance()
            .post(`auction/rfp/${ this.props.rfp.id }/${ this.props.type }/csv`, formData, null, { delimiter });
    }

    _onResponseReceived(numberOfAddedItems, errorMessage) {
        this.setState({ numberOfAddedItems, errorMessage });
    }

    _createLaneDescription() {
        return (
            <div className="csv-upload-descriptipn">
                <p>
                    Add lanes by uploading a CSV file that contains the following columns:
                    <b> Origin City, Origin State, Origin Zip, Destination City, Destination State, Destination Zip, Load Mode </b>(Dry, Reefer or Flatbed)<b>, Distance and Volume.</b>
                </p>
            </div>
        );
    }

    _createCandidateDescription() {
        return (
            <div className="csv-upload-descriptipn">
                <p>
                    Add carriers by uploading a CSV file that contains the following columns:
                    <b> User Business ID</b>
                </p>
            </div>
        );
    }

    _createBidDescription() {
        return (
            <div className="csv-upload-descriptipn">
                <p>Bid on multiple lanes by uploading a completed bid template as a .csv file.</p>
            </div>
        );
    }

    _getUploadPopupProps() {
        let title, buttonText, loadingText, loadingWarning, successText, emptySuccessText, triggerText, description, templateReference = null;
        let loadingDescription = 'This will only take a moment. Please wait...';
        switch (this.props.type) {
            case 'lane':
                title = 'Add Lanes via CSV';
                buttonText = 'Add Lanes';
                loadingText = 'Adding Lanes';
                successText = 'Lanes Added';
                emptySuccessText = 'No new Lanes Added';
                triggerText = 'Add Lanes via CSV';
                description = this._createLaneDescription();
                templateReference = 'auction/rfp/lane/template';
                break;
            case 'candidate':
                title = 'Add Carriers via CSV';
                buttonText = 'Add Carriers';
                loadingText = 'Adding Carriers';
                loadingDescription = 'This may take a while. Please wait...';
                loadingWarning = 'Please note that leaving the page while the upload is in progress can cause unpredictable application behavior due to partial synchronization. We advise you to wait until the process is finished.';
                successText = 'Carriers Added';
                emptySuccessText = 'No new Carriers Added';
                triggerText = 'Add Carriers via CSV';
                description = this._createCandidateDescription();
                templateReference = 'auction/rfp/carrier/template';
                break;
            case 'bid':
                title = 'Upload Bids via Template';
                buttonText = 'Submit Bids';
                loadingText = 'Submitting Bids';
                emptySuccessText = 'No new Bids Submitted';
                successText = 'Bids Submitted';
                triggerText = 'Upload Bids via Template';
                description = this._createBidDescription();
                break;
            default:
                throw new Error('Unknown item ' + this.props.type);
        }

        const labels = { title, buttonText, triggerText };

        let loadingWarningElement = <></>;
        if (loadingWarning) {
            loadingWarningElement = (
                <div className="loading-warning"><Banner type="warn" content={ loadingWarning } /></div>
            );
        }

        const loadingElement = (
            <div>
                <h6>{ loadingText }</h6>
                <small>{ loadingDescription }</small>
                { loadingWarningElement }
            </div>
        );

        const successElement = (
            <div>
                <h6>{ successText }</h6>
                <small>
                    <b>{ this.state.numberOfAddedItems }</b> { StringUtils.pluralize(this.state.numberOfAddedItems, this.props.type, 's') } { this.state.numberOfAddedItems === 1 ? 'has': 'have' } been successfully added and applied to <b>{ this.props.rfp.name }</b>.
                </small>
            </div>
        );

        const emptySuccessElement = (
            <div>
                <h6>{ emptySuccessText }</h6>
                <small>
                    <b>{ this.props.rfp.name }</b> remains unchanged.<br />
                    If this is unexpected, make sure all of the rows in the CSV file contain new and valid data.
                </small>
            </div>
        );

        const errorElement = (
            <div>
                <h6>File Not Uploaded</h6>
                <small>
                    { this.state.errorMessage }
                    <br />
                    <br />
                    Please make sure that the uploaded CSV file contains all of the required columns,
                    that all rows contain valid data, and that you have selected the correct delimiter.
                    <br />
                    <br />
                    If you are still having trouble, please contact our support team at
                    <a href="mailto:rfpsupport@shipexlogistics.com"> rfpsupport@shipexlogistics.com</a>,
                    and attach the CSV file you are trying to upload.
                </small>
            </div>
        );

        return {
            labels,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        };
    }

    render() {
        const {
            labels,
            description,
            loadingElement,
            successElement,
            emptySuccessElement,
            errorElement,
            templateReference
        } = this._getUploadPopupProps();

        return (
            <CSVUpload size={ this.props.size }
                       withUnloadConfirmation={ 'candidate' === this.props.type }
                       labels={ labels }
                       description={ description }
                       loadingElement={ loadingElement }
                       successElement={ successElement }
                       emptySuccessElement={ emptySuccessElement }
                       errorElement={ errorElement }
                       uploadCSVPromise={ this._uploadCSV }
                       onCreated={ this.props.onCreated }
                       onResponseReceived={ this._onResponseReceived }
                       templateReference={ templateReference } />
        );
    }
}
