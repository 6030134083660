import { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';

import 'antd/dist/antd.css';
import './CustomSelect.scss';

const { Option } = Select;

export default class CustomSelect extends Component {

    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        tooltipText: PropTypes.string, 
        multiple: PropTypes.bool,
        withSearch: PropTypes.bool,
        allowClear: PropTypes.bool,
        showArrow: PropTypes.bool,
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        values: PropTypes.arrayOf(PropTypes.object),
        fieldName: PropTypes.string,
        direction: PropTypes.oneOf(['top', 'bottom']),
        size: PropTypes.oneOf(['small', 'regular']),
        onSearch: PropTypes.func,
        onSelect: PropTypes.func,
        onUnselect: PropTypes.func,
        onClear: PropTypes.func,
        customValueMapper: PropTypes.func,
        disabled: PropTypes.bool,
        customTagRenderer: PropTypes.func,
        customDropdownRenderer: PropTypes.func,
        maxTagCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        maxTagPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        placeholder: PropTypes.string,
        prefixIcon: PropTypes.element
    }

    static defaultProps = {
        id: undefined,
        label: undefined,
        tooltipText: undefined,
        placeholder: 'Select',
        multiple: false,
        withSearch: false,
        allowClear: false,
        showArrow: true,
        selectedValue: [],
        values: [],
        fieldName: 'title',
        direction: 'bottom',
        size: 'regular',
        onSearch: () => { /* */ },
        onSelect: () => { /* */ },
        disabled: false,
        onUnselect: () => { /* */ },
        onClear: () => { /* */ },
        customValueMapper: undefined,
        customTagRenderer: undefined,
        customDropdownRenderer: undefined,
        maxTagCount: 'responsive',
        maxTagPlaceholder: '',
        prefixIcon: null
    }

    constructor(props) {
        super(props);

        this._onSelect = this._onSelect.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this._customValueMapper = this.props.customValueMapper || this._customValueMapper.bind(this);
    }

    state = {
        currentValue: null
    }

    /**
     *
     * @type {Timeout | {current: null} | {current: null}}
     * @private
     */
    _timer = null;

    _customValueMapper(value) {
        return value.label || value[this.props.fieldName];
    }

    _mapValuesToOptions(values) {
        return values.map(value => 
            <Option key={ JSON.stringify(value) } value={ value[this.props.fieldName] }>
                { this._customValueMapper(value) }
            </Option>
        );
    }

    _onSelect(key, options) {
        this._handleSearch(null);
        this.props.onSelect(key, options); 
    }

    _getDirection() {
        const overflow = {
            adjustX: 0,
            adjustY: 1,
        };

        if (this.props.direction === "top") {
            return {
                points: ["bl", "tl"],
                offset: [0, -4],
                overflow
            };
        }

        return {
            points: ["tl", "bl"],
            offset: [0, 4],
            overflow
        };
    }

    _formTooltip() { 
        return (
            <span className="tooltip-icon">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction="top">
                    { this.props.tooltipText }
                </Tooltip>
            </span>
        );
    }

    _handleSearch(value) {
        if (value !== this.state.currentValue) {
            this.setState({ currentValue: value });

            if (this._timer) {
                clearTimeout(this._timer);
                this._timer = null;
            }

            this._timer = setTimeout(() => this.props.onSearch(value), 300);
        }
    }

    render() {
        return (
            <div className="custom-select-container">
                { this.props.label ? <small className="label">{ this.props.label } { this.props.tooltipText && this._formTooltip() }</small> : <></> }
                { this.props.prefixIcon ? <div className="prefix-icon"> { this.props.prefixIcon }</div> : <></> }
                <Select
                    id={ this.props.id }
                    getPopupContainer={ trigger => trigger.parentNode }
                    mode={ this.props.multiple ? "multiple" : null }
                    showSearch={ this.props.withSearch }
                    showArrow={ this.props.showArrow }
                    allowClear={ this.props.allowClear }
                    className={ `select ${ this.props.size } ${ this.props.prefixIcon ? 'padding' : '' }` }
                    placeholder={ this.props.placeholder }
                    value={ this.props.selectedValue }
                    suffixIcon={ <FontAwesomeIcon className="chevron-icon" icon={ faChevronDown } /> }
                    onSearch={ this.props.withSearch ? this._handleSearch : null }
                    onChange={ this._onSelect }
                    onDeselect={ this.props.onUnselect }
                    onClear={ this.props.onClear }
                    filterOption={ false }
                    dropdownAlign={ this._getDirection() }
                    disabled={ this.props.disabled }
                    tagRender={ this.props.customTagRenderer }
                    dropdownRender={this.props.customDropdownRender }
                    maxTagCount={ this.props.maxTagCount }
                    maxTagPlaceholder={ this.props.maxTagPlaceholder }
                >
                    { this._mapValuesToOptions(this.props.values) }
                </Select>
            </div>
        );
    }
}
