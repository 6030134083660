import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle as warning,
    faCheckCircle as ready,
    faMinusCircle as falloff,
    faInfoCircle as info,
    faClock as time
} from '@fortawesome/pro-solid-svg-icons';

import {
    AUCTION_STATUSES,
    AUCTION_BID_STATUS_MAPPING,
    AUCTION_STATUS_MAPPING,
    BID_STATUSES,
    CANDIDATE_TYPE,
    FAILED_BID_STATUSES,
    FALLOFF_BID_STATUSES,
    LOST_STYLE_BID_STATUSES,
    LOAD_STATUSES,
    OFFERING_AUCTION_STATUSES,
    TERMINAL_BID_STATUSES,
    TERMINAL_AUCTION_BID_STATUS_MAPPING,
    USER_ROLES_MAPPING,
    WINNING_BID_STATUSES,
    AUCTION_STATUSES_FILTER_MAPPING
} from 'common/constants';
import Banner from 'component/Banner';
import TextEmphasisBox from 'component/TextEmphasisBox';
import Tooltip from 'component/Tooltip';
import TimeDisplay from 'component/TimeDisplay';
import RestService from 'service/RestService';
import AuctionUtils from 'utils/AuctionUtils';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import ObjectUtils from 'utils/ObjectUtils';
import PriceUtils from 'utils/PriceUtils';

import './OfferInformationComponent.scss';

export default class OfferInformationComponent extends Component {
    static propTypes = {
        account: PropTypes.object,
        title: PropTypes.string,
        carrier: PropTypes.string,
        hasInvalidDocuments: PropTypes.bool,
        hasBeenTerminated: PropTypes.bool,
        bid: PropTypes.object,
        load: PropTypes.object,
        showStatus: PropTypes.bool,
        auctionId: PropTypes.string,
        auctionDeadline: PropTypes.instanceOf(Date),
        auctionStatus: PropTypes.string,
        actions: PropTypes.element,
        isOffered: PropTypes.bool,
        offerExpiration: PropTypes.shape({
            expirationDate: PropTypes.instanceOf(Date),
            duration: PropTypes.array
        }),
        brokerActions: PropTypes.element
    }

    static defaultProps = {
        account: null,
        title: 'Offer Information',
        carrier: '',
        hasInvalidDocuments: false,
        hasBeenTerminated: false,
        bid: null,
        load: null,
        showStatus: true,
        auctionId: null,
        auctionDeadline: null,
        auctionStatus: null,
        actions: <></>,
        isOffered: false, // To determine the style of the card for offered loads when shown to carrier,
        offerExpiration: new Date(),
        brokerActions: <></>
    }

    state = {
        falloffReason: null,
        rejectReason: null,
        cancelReason: null,
        carrierNameTooltip: null
    }

    componentDidMount() {
        this._fetchBidFalloffReason();
        this._fetchBidRejectReason()
        this._fetchAuctionCancelReason();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auctionStatus !== this.props.auctionStatus || !this.props.bid || (prevProps.bid && prevProps.bid.status !== this.props.bid.status)) {
            this._fetchBidFalloffReason();
            this._fetchBidRejectReason();
            this._fetchAuctionCancelReason();
        }
    }

    _fetchBidFalloffReason() {
        AuctionUtils.fetchBidFalloffReason(this.props.bid, this.props.account).then(falloffReason => {
            if (falloffReason) {
                this.setState({
                    falloffReason: falloffReason.reason
                });
            }
        });
    }

    _fetchBidRejectReason() {
        AuctionUtils.fetchRejectedOfferData(this.props.bid, this.props.account).then(rejectReason => {
            if (rejectReason) {
                this.setState({
                    rejectReason: { reason: rejectReason.reason, note: rejectReason.note }
                });
            }
        });
    }

    _fetchAuctionCancelReason() {
        if (AUCTION_STATUSES.CANCELED === this.props.auctionStatus && this.props.account) {
            const authorizedRoles = [USER_ROLES_MAPPING.BROKER, USER_ROLES_MAPPING.ADMINISTRATOR];

            if (this.props.account.roles.some(role => authorizedRoles.includes(role))) {
                const apiUrl = `broker/auction/tendering/${ this.props.auctionId }/cancel-reason`;
                RestService.instance().get(apiUrl).then(data => {
                    if (data) {
                        this.setState({ cancelReason: { ...data.cancelReason, note: data.note }});
                    }
                });
            }
        }
    }

    /**
     * Determines UI elements such as icons and class name additions taking the bid status,
     * carrier's expirations, carrier's activity and the auction status into consideration.
     *
     * @param {string} bidStatus
     * @param {boolean} bidApproved
     * @param {boolean} hasInvalidDocuments
     * @param {boolean} hasBeenTerminated
     * @param {string} auctionStatus
     * @private
     */
    _determineDisplayStyles(bidStatus, bidApproved, bidCurrent, hasInvalidDocuments, hasBeenTerminated, auctionStatus) {
        let expirationAddition = "";
        let textEmphasisType = "";
        let textEmphasisBoxColor = "";
        let statusIcon;

        if (hasInvalidDocuments || hasBeenTerminated) {
            expirationAddition = " red";
        }

        if (this.props.isOffered) {
            statusIcon = <FontAwesomeIcon className="warning" icon={ warning } />;
            textEmphasisType = "ready";
            textEmphasisBoxColor = "cyan";
        } else if (AUCTION_STATUSES.AWAITING_WINNER_RESPONSE === auctionStatus && BID_STATUSES.PENDING === bidStatus && bidCurrent) {
            statusIcon = <FontAwesomeIcon className="warning" icon={ time } />;
            textEmphasisType = "pending";
            textEmphasisBoxColor = "orange";
        } else if (!bidStatus || LOST_STYLE_BID_STATUSES.includes(bidStatus) || this._isAuctionCanceledStyleVisible(auctionStatus, bidStatus)) {
            statusIcon = <FontAwesomeIcon className="cancel" icon={ falloff } />;
            textEmphasisType = "cancel";
            textEmphasisBoxColor = "grey";
        } else if (TERMINAL_BID_STATUSES.includes(bidStatus)) {
            if (!this.props.account.carrierId && FAILED_BID_STATUSES.includes(bidStatus)) {
                statusIcon = <FontAwesomeIcon className="cancel" icon={ falloff } />;
                textEmphasisType = "cancel";
            } else {
                statusIcon = <FontAwesomeIcon className="falloff" icon={ falloff } />;
                textEmphasisType = "falloff";
            }

            textEmphasisBoxColor = "grey";
        } else if (BID_STATUSES.NOT_SENT === bidStatus) {
            statusIcon = <FontAwesomeIcon className="cancel" icon={ falloff } />;
            textEmphasisType = "not-sent";
            textEmphasisBoxColor = "grey";
        } else if (BID_STATUSES.WINNER === bidStatus && bidApproved) {
            statusIcon = <FontAwesomeIcon className="ready" icon={ ready } />;
            textEmphasisType = "ready";
            textEmphasisBoxColor = "cyan";
        } else if (AUCTION_STATUSES.UNDER_REVIEW === auctionStatus && !bidApproved) {
            statusIcon = <FontAwesomeIcon className="warning" icon={ ready } />;
            textEmphasisType = "pending";
            textEmphasisBoxColor = "orange";
        } else {
            statusIcon = <FontAwesomeIcon className="warning" icon={ warning } />;
            textEmphasisType = "pending";
            textEmphasisBoxColor = "orange";
        }

        return [ expirationAddition, statusIcon, textEmphasisType, textEmphasisBoxColor ];
    }

    /**
     * Checks if auction canceled style is visible to user
     *
     * @param {string} auctionStatus
     * @param {string} bidStatus
     * @returns
     */
    _isAuctionCanceledStyleVisible(auctionStatus, bidStatus) {
        return AUCTION_STATUSES.CANCELED === auctionStatus && (this.props.showBrokerActions || WINNING_BID_STATUSES.includes(bidStatus));
    }

    _formCarrierNameTooltip(element) {
        if (!element) {
            return;
        }

        let carrierNameTooltip = <></>;
        if (element.scrollWidth > element.clientWidth) {
            carrierNameTooltip = (
                <Tooltip direction="bottom" align="left-align">
                    { this.props.carrier }
                </Tooltip>
            );
        }

        if (!this.state.carrierNameTooltip || !ObjectUtils.equal(this.state.carrierNameTooltip.props, carrierNameTooltip.props)) {
            this.setState({ carrierNameTooltip });
        }
    }

    /**
     * Forms deadline field if there is a winning bid and carrier details have not been provided yet
     *
     * @param {string} bidStatus
     * @param {string} auctionStatus
     * @param {Date} auctionDeadline
     * @param {Date} pickupTime
     * @param {Date} bidExpiration
     * @returns
     */
    _formDeadlineField(bidStatus, auctionStatus, auctionDeadline, pickupTime, bidExpiration) {
        const isBidConfirmed = BID_STATUSES.CONFIRMED === bidStatus && AUCTION_STATUSES.CANCELED !== auctionStatus;
        const isOnDemandOffer = AUCTION_STATUSES.AWAITING_WINNER_RESPONSE === auctionStatus && BID_STATUSES.NOT_SENT !== bidStatus;

        if (isBidConfirmed || (isOnDemandOffer && !this.props.account.carrierId)) {
            const detailsSubmissionDeadline = DateUtils.isBefore(new Date(pickupTime), new Date(auctionDeadline)) ? auctionDeadline : pickupTime;
            const expired = DateUtils.isBefore(new Date(isBidConfirmed ? detailsSubmissionDeadline : bidExpiration), new Date());
            const remainingDuration = DateUtils.format(new Date(isBidConfirmed ? detailsSubmissionDeadline : bidExpiration));
            const expiredText = expired && isBidConfirmed ? 'Expired: ' : '';
            const expiredStyle = expired && isBidConfirmed ? 'expired' : '';
            return (
                <div className="field deadline">
                    <strong className="field-title">{ isBidConfirmed ? 'Deadline for Submission' : 'Expiring On' }</strong>
                    <p className={ `time ${ expiredStyle }` }>
                        { expiredText + remainingDuration }
                    </p>
                </div>
            );
        }

        return <></>;
    }

    /**
     * Forms total price field based on relevant bid price, distance and with appropriate style
     *
     * @param {Object} bid
     * @param {string} textEmphasisType
     * @param {string} textEmphasisBoxColor
     * @returns
     */
    _formTotalPriceField(bid, textEmphasisType, textEmphasisBoxColor) {

        let totalPrice;
        if (bid) {
            totalPrice = <TextEmphasisBox children={ "$" + NumberUtils.formatWithDecimalNotation(bid.price) } color={ textEmphasisBoxColor } size="large" />;
        } else {
            totalPrice = <h5 className={ textEmphasisType }>No Bid</h5>;
        }

        return (
            <div className="field total">
                <div className="title field-title">
                    <strong>Total Price</strong>
                </div>
                { totalPrice }
            </div>
        );
    }

    /**
     * Determines UI status text based on bid, auction and load statuses
     *
     * @param {string} bidStatus
     * @param {string} auctionStatus
     * @param {string} loadStatus
     */
    _determineStatusText(bidStatus, bidCurrent, auctionStatus, loadStatus) {
        const { falloffReason, rejectReason, cancelReason } = this.state;
        let statusText;

        if (!bidStatus || (BID_STATUSES.PENDING === bidStatus && (!OFFERING_AUCTION_STATUSES.includes(auctionStatus) || !bidCurrent))) {
            statusText = TERMINAL_AUCTION_BID_STATUS_MAPPING.PENDING;
        }  else if (BID_STATUSES.REJECTED_BY_BROKER === bidStatus && rejectReason) {
            statusText = AUCTION_BID_STATUS_MAPPING[bidStatus] + " - " + rejectReason.reason;
        } else if (FAILED_BID_STATUSES.includes(bidStatus)) {
            statusText = TERMINAL_AUCTION_BID_STATUS_MAPPING[bidStatus];
        } else if (this._isAuctionCanceledStyleVisible(auctionStatus, bidStatus)) {
            const reason = cancelReason ? " - " + cancelReason.reason : "";
            statusText = AUCTION_STATUSES_FILTER_MAPPING.CANCELED + reason;
        } else if (LOAD_STATUSES.COMPLETED === loadStatus && WINNING_BID_STATUSES.includes(bidStatus)) {
            statusText = AUCTION_STATUSES_FILTER_MAPPING.COMPLETED;
        } else if (FALLOFF_BID_STATUSES.indexOf(bidStatus) !== -1 && falloffReason) {
            statusText = AUCTION_BID_STATUS_MAPPING[bidStatus] + " - " + falloffReason;
        } else if (AUCTION_STATUSES.UNDER_REVIEW === auctionStatus) {
            statusText = AUCTION_STATUS_MAPPING.UNDER_REVIEW;
        } else {
            statusText = AUCTION_BID_STATUS_MAPPING[bidStatus];
        }
        return statusText;
    }

    _formStatusTooltip() {
        let tooltipText;
        let tooltipDirection;
        if (this.state.cancelReason && this.state.cancelReason.note) {
            tooltipText = this.state.cancelReason.note;
            tooltipDirection = 'right';
        } else if (this.state.rejectReason && this.state.rejectReason.note) {
            tooltipText = this.state.rejectReason.note;
            tooltipDirection = 'right';
        } else if (BID_STATUSES.NOT_SENT === this.props.bid?.status) {
            tooltipText = `Offer was not sent to carrier due to \n their terminated status in TMW.`;
            tooltipDirection = 'top';
        } else {
            return <></>;
        }

        return (
            <div className="icon-div">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction={ tooltipDirection }>
                    { tooltipText }
                </Tooltip>
            </div>
        )
    }

    render() {
        const { title, carrier, hasInvalidDocuments, hasBeenTerminated, bid, load, auctionDeadline, auctionStatus, showStatus, isOffered, brokerActions } = this.props;

        const [ expirationAddition, statusIcon, textEmphasisType, textEmphasisBoxColor ] = this._determineDisplayStyles(bid?.status, bid?.approved, bid?.current, hasInvalidDocuments, hasBeenTerminated, auctionStatus);
        const falloffStyle = ['falloff', 'cancel'].includes(textEmphasisType) ? 'falloff' : expirationAddition;

        const bidPerMile = bid ? '$' + PriceUtils.formatPerMilePrice(bid.price, load.routingGuideLane.distance) : 'No Bid';

        const totalPrice = this._formTotalPriceField(bid, textEmphasisType, textEmphasisBoxColor);

        let carrierDetails = <></>;
        if (carrier) {
            carrierDetails = (
                <div className="field carrier">
                    <strong className="field-title">{ CANDIDATE_TYPE.ON_DEMAND === bid.candidate.type ? 'On-Demand' : '' } Carrier</strong>
                    <h6 className={ `name ${ falloffStyle }` } ref={ r => this._formCarrierNameTooltip(r) }>
                        { this.state.carrierNameTooltip }
                        <Link to={ `/carrier/${ bid.candidate.carrier.id }` }>
                            { carrier }
                        </Link>
                    </h6>
                </div>
            )
        }

        let expirationBox = <></>;
        if (hasBeenTerminated) {
            expirationBox = <Banner type="error" content="Terminated carrier" size="medium" textStyle="bold" />;
        } else if (hasInvalidDocuments) {
            expirationBox = <Banner type="error" content="Missing or expired documents" size="medium" textStyle="bold" />;
        }

        const deadlineField = this._formDeadlineField(bid?.status, auctionStatus, auctionDeadline, load.pickupTime, bid?.expiration);

        const carrierActions = (
            <div className="field carrier-actions">
                { this.props.actions }
            </div>
        );

        let statusField = <></>;
        if (showStatus) {
            const tooltip = this._formStatusTooltip();
            statusField = (
                <div className="field status">
                    <strong className="field-title">Status { statusIcon }</strong>
                    <span className={ `description ${ textEmphasisType }` }>
                        { this._determineStatusText(bid?.status, bid?.current, auctionStatus, load.status) }
                        { tooltip }
                    </span>
                </div>
            );
        }

        let expiresInField = <></>;
        if (isOffered) {
            const { duration, expirationDate } = this.props.offerExpiration || {};
            const closeToExpirationStyle = AuctionUtils.isCloseToExpiration(expirationDate) ? " warning" : "";

            expiresInField = (
                <div className="field expires-in">
                    <div className="title field-title">
                        <strong>Expiring in</strong>
                        { CANDIDATE_TYPE.ON_DEMAND !== bid.candidate.type && 
                            <div className="icon-div">
                                <FontAwesomeIcon icon={ info } />
                                <Tooltip direction="right" align="left-align">
                                    { "Offer is valid for 90 minutes\nand will be offered to the next\ncarrier after it expires." }
                                </Tooltip>
                            </div>
                        }
                    </div>
                    <TimeDisplay duration={ duration } numberStyle={ "big" + closeToExpirationStyle } />
                </div>
            );
        }

        return (
            <div className="component main-card accepted-load">
                <h6 className="heading">
                    { title }
                    { brokerActions }
                </h6>
                <div className="details">
                    <div className="bid-information-details">
                        { carrierDetails }
                        { totalPrice }
                        <div className="field per-mile">
                            <strong className="field-title">Price per Mile</strong>
                            <h5 className={ `amount ${ falloffStyle }` }>{ bidPerMile }</h5>
                        </div>
                        { statusField }
                        { expiresInField }
                        { deadlineField }
                    </div>
                    <div className="bid-information-actions">
                        { carrierActions }
                    </div>
                </div>
                <div className="banner-wrapper">
                    { expirationBox }
                </div>
            </div>
        );
    }
}
