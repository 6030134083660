import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird as spinnerIcon } from '@fortawesome/pro-duotone-svg-icons';

import RestService from 'service/RestService';
import AuthenticationService from 'service/AuthenticationService';

export async function deriveTosLocalStorageKey() {
    const account = await AuthenticationService.instance().getAccount();
    return `rfp-tos-accepted-by-${ account.id }`;
}

class EnsureRFPTermsOfService extends Component {
    state = {
        accepted: false,
        loading: true
    };

    constructor(props) {
        super(props);
        this.onTosResponse = this.onTosResponse.bind(this);
    }

    async componentDidMount() {
        const tosLocalStorageKey = await deriveTosLocalStorageKey();
        const accepted = 'true' === localStorage.getItem(tosLocalStorageKey);

        this.setState({
            accepted,
            loading: !accepted
        });

        if (!accepted) {
            RestService.instance().get(`auction/rfp/tos`).then(this.onTosResponse);
        }
    }

    async onTosResponse(response) {
        const tosLocalStorageKey = await deriveTosLocalStorageKey();

        const accepted = response;
        this.setState({
            accepted,
            loading: false
        });

        localStorage.setItem(tosLocalStorageKey, String(this.state.accepted));
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="login-screen">
                    <FontAwesomeIcon className="loading-icon" icon={ spinnerIcon } spin />
                    <p>Checking Terms of Service status...</p>
                </div>
            );
        }

        if (!this.state.accepted) {
            return (<Redirect to={{ pathname: '/rfp/terms-of-service', state: this.props.location }} />);
        }

        return (
            <>
                {this.props.children}
            </>
        );
    }
}

const EnsureRFPTermsOfServiceWithRouter = withRouter(EnsureRFPTermsOfService);

/**
 * RFPTosProtectedPage is a standard Page, but guards accessing to it/rendering
 * unless ToS is accepted. If the ToS is not accepted a redirection to /rfp/terms-of-service
 * is done.
 * 
 * Use this inside the Route for defining /rfp routes that have ToS requirements.
 * 
 * Example usage:
 * 
 * <Route exact path="/rfp">
 *  <RFPTosProtectedPage>
 *      ...
 *  </RFPTosProtectedPage>
 * </Route>
 */
export default function RFPTosProtectedPage({ children }) {
    return (
        <EnsureRFPTermsOfServiceWithRouter>
            {children}
        </EnsureRFPTermsOfServiceWithRouter>
    );
}
