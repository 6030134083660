import { Component } from 'react';
import { faSync as reloadIcon } from '@fortawesome/pro-duotone-svg-icons';

import Button from 'component/Button';
import PageHeader from 'component/PageHeader';
import RestService from 'service/RestService';

import './ApplicationDetails.scss';

const CACHE = {
    details: null
};

export default class ApplicationDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            details: null
        }
    }

    componentDidMount() {
        if (!this.state.details) {
            // In case the state does not already contain
            return this._fetchInfo();
        }
    }

    async _fetchInfo() {
        this.setState({ details: null });

        if (CACHE.details) {
            this.setState({ details: CACHE.details });
        } else {
            RestService.instance()
                .get("management/info")
                .then(details => {
                    this.setState({ details });
                    CACHE.details = details;
                });
        }
    }

    _refresh() {
        CACHE.details = null;
        return this._fetchInfo();
    }

    render() {
        let content;

        if (this.state.details) {
            content = (
                <>
                    <p>Hash: { this.state.details.git.commit.id.full }</p>
                    <p>Version: { this.state.details.build.version }</p>
                    <p>Built at: { this.state.details.build.time }</p>
                </>
            );
        } else {
            content = (
                <>
                    <div className="loading-line" />
                    <div className="loading-line" />
                    <div className="loading-line" />
                </>
            );
        }

        return (
            <div className="page application-details-page">
                <PageHeader title="Application Details">
                    <Button type="tertiary" size="small" leftIcon={ reloadIcon } onClick={ this._refresh.bind(this) }>
                        Refresh
                    </Button>
                </PageHeader>
                <div className="component content">
                    { content }
                </div>
            </div>
        );
    }
}
