import { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import 'antd/dist/antd.css';
import './CustomCheckbox.scss';

export default class CustomCheckbox extends Component {

    static propTypes = {
        checked: PropTypes.bool.isRequired,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        preventDefaultAction: PropTypes.bool,
        size: PropTypes.oneOf(['regular', 'large']),
        onChange: PropTypes.func
    }

    static defaultProps = {
        text: '',
        size: 'regular',
        preventDefaultAction: false,
        onChange: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._preventDefault = this._preventDefault.bind(this);
    }

    _preventDefault(e) {
        if (this.props.preventDefaultAction) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <Checkbox className={ `custom-checkbox ${ this.props.size }` } onChange={ this.props.onChange } checked={ this.props.checked }>
                <small className="checkbox-label" onClick={ this._preventDefault }>
                    { this.props.text }
                </small>
            </Checkbox>
        );
    }
}
