import { Component } from 'react';
import PropTypes from 'prop-types';
import { faSync as reactivateIcon } from '@fortawesome/pro-regular-svg-icons';

import Popup from 'component/Popup';
import Button from 'component/Button';

import './MemberActions.scss';

export default class ReactivateMember extends Component {

    static propTypes = {
        member: PropTypes.object.isRequired,
        onReactivateMember: PropTypes.func
    };

    static defaultProps = {
        onReactivateMember: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onReactivate = this._onReactivate.bind(this);
    }

    state = {
        showPopup: false
    }

    _openPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.setState({ showPopup: false });
    }

    _onReactivate() {
        this.props.onReactivateMember();
        this._closePopup();
    }

    render() {
        const { member } = this.props;

        return (
            <div className="reactivate-member">
                <Popup
                    id="popup-reactivate-member"
                    show={ this.state.showPopup }
                    onClose={ this._closePopup }
                    trigger={
                        <Button
                            type="tertiary-alt"
                            size="small"
                            disabled={ !member.carrier.active }
                            className="action"
                            rightIcon={ reactivateIcon }
                            onClick={ this._openPopup }
                        />
                    }
                >
                    <div className="reactivate-member-popup">
                        <p className="heading">Reactivate Member</p>
                        
                        <p className="description">
                            Are you sure you want to reactivate User <b> { member.name } </b>?
                        </p>

                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._closePopup } >
                                Cancel
                            </Button>

                            <Button size="small" type="primary" onClick={ this._onReactivate }>
                                Reactivate 
                            </Button>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}
