import { Component } from 'react';
import { withRouter } from 'react-router';
import CanAccess from 'component/CanAccess';
import PropForwardingComponent from 'component/PropForwardingComponent';
import Header from 'component/navigation/Header';
import Navbar from 'component/navigation/Navbar';
import SurveyPopup from 'component/survey/SurveyPopup';

import './WithNavigationPage.scss';


export default withRouter(class WithNavigationPage extends Component {

    render() {
        return (
            <div className="page-with-navigation">
                <Header />
                <Navbar />
                <CanAccess action="survey:read" yes={ <SurveyPopup /> } />
                <div className="page-content">
                    { PropForwardingComponent.processedChildren(this.props) }
                </div>
            </div>
        );
    }
});
