import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTruck as vanIcon,
    faSnowflake as reeferIcon,
    faPalletAlt as flatbedIcon
} from '@fortawesome/pro-solid-svg-icons';

import { LANE_TYPES, LANE_TYPE_PROPERTIES } from 'common/constants';
import TableCell from 'component/table/custom/TableCell';
import Tooltip from 'component/Tooltip';

import './EquipmentTypeCell.scss';

/**
 * Forms equipment type column by the logic provided by the clients:
 *  - Dry loads can be driven by van or reefer trailers
 *  - Reefer loads can be driven by reefer trailers
 *  - Flatbed loads can be driven by flatbed trailers
 */
export default class EquipmentTypeCell extends Component {
    static propTypes = {
        cargoType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    };

    render() {
        const cargoType = this.props.cargoType;
        let content;

        switch (cargoType) {
            case LANE_TYPES.DRY:
                content = (
                    <>
                        <div className={ `icon-container ${ LANE_TYPE_PROPERTIES[LANE_TYPES.REFRIGERATED].field }` }>
                            <FontAwesomeIcon icon={ reeferIcon } />
                            <Tooltip direction="right">Reefer Equipment Type</Tooltip>
                        </div>
                        <div className={ `icon-container ${ LANE_TYPE_PROPERTIES[LANE_TYPES.DRY].field }` }>
                            <FontAwesomeIcon icon={ vanIcon } />
                            <Tooltip direction="right">Van Equipment Type</Tooltip>
                        </div>
                    </>
                );
                break;
            case LANE_TYPES.REFRIGERATED:
                content = (
                    <div className={ `icon-container ${ LANE_TYPE_PROPERTIES[LANE_TYPES.REFRIGERATED].field }` }>
                        <FontAwesomeIcon icon={ reeferIcon } />
                        <Tooltip direction="right">Reefer Equipment Type</Tooltip>
                    </div>
                );
                break;
            case LANE_TYPES.FLATBED:
                content = (
                    <div className={ `icon-container ${ LANE_TYPE_PROPERTIES[LANE_TYPES.FLATBED].field }` }>
                        <FontAwesomeIcon icon={ flatbedIcon } />
                        <Tooltip direction="right">Flatbed Equipment Type</Tooltip>
                    </div>
                );
                break;
            default:
                // This should never happen
                console.error(`Unrecognized cargo type ${ cargoType }`);
        }


        return (
            <TableCell padding="regular" link={ this.props.link }>
                <div className="auction-table-cell equipment-type">
                    { content }
                </div>
            </TableCell>
        );
    }
}
