import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from 'component/Tooltip';
import NumberUtils from 'utils/NumberUtils';

import "antd/dist/antd.css";
import "./CustomNumberInput.scss";

export default class CustomNumberInput extends Component {
    static propTypes = {
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        tooltipText: PropTypes.string,
        className: PropTypes.string,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        placeholder: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        value: PropTypes.string,
        initialValue: PropTypes.string,
        onChange: PropTypes.func,
        onInvalidInput: PropTypes.func,
        shouldValidate: PropTypes.bool,
        isValid: PropTypes.bool,
        resetValue: PropTypes.bool,
        allowClear: PropTypes.bool,
        onClear: PropTypes.func,
        disabled: PropTypes.bool,
        allowMaxLimit: PropTypes.bool,
        allowMinLimit: PropTypes.bool,
        focusOnMount: PropTypes.bool,
    };

    static defaultProps = {
        label: null,
        tooltipText: null,
        className: "",
        prefix: "",
        suffix: "",
        placeholder: "",
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER,
        value: "",
        initialValue: "",
        onChange: () => { /* */ },
        onInvalidInput: () => { /* */ },
        shouldValidate: true,
        isValid: false,
        resetValue: false,
        allowClear: false,
        onClear:  () => { /* */ },
        disabled: false,
        allowMaxLimit: false,
        allowMinLimit: false,
        focusOnMount: true
    };

    state = {
        valid: false,
        touched: false,
        value: this.props.initialValue
    }

    constructor(props) {
        super(props);
        this.numberInput = React.createRef();
        this._onInputValueChange = this._onInputValueChange.bind(this);
    }

    componentDidMount() {
        this._focusInput();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialValue !== this.props.initialValue || (this.props.resetValue && this.props.resetValue !== prevProps.resetValue)) {
            this.setState({
                value: this.props.initialValue
            });
        } else if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            const valid = this._isValueValid(Number(this.props.value));
            const touched = this._isTouched();
            const value = this.props.value;
            this.setState({ valid, touched, value });
        }
    }

    _focusInput() {
        if (this.props.focusOnMount) {
            this.numberInput.current.focus();
        }
    }

    _onInputValueChange(event) {
        let newValue = event.target.value;

        if (event.type === 'click') {
            this.props.onClear();
            return;
        }

        if (newValue.trim() === "") {
            this.props.onChange(null);
            this.setState({ valid: true, touched: false, value: "" });
            return;
        } else if (isNaN(Number(newValue)) || newValue.indexOf('-') !== -1) {
            // Ignore invalid inputs
            newValue = this.state.value;
            this._focusInput();
        } else if (newValue && newValue.indexOf(".") !== -1) {
            // Truncate numbers with more decimals
            const flooredValue = NumberUtils.truncate(newValue, this.props.decimals);
            const valueString = newValue.toString();
            const flooredValueString = flooredValue.toString();

            if (this.props.decimals === 0 || valueString.substring(valueString.indexOf('.')).length > flooredValueString.substring(flooredValueString.indexOf(".")).length) {
                newValue = flooredValueString;
                this._focusInput();
            }
        }

        const valid = this._isValueValid(Number(newValue));
        this.props.onChange(Number(newValue), valid);
        this.setState({ valid, touched: true });

        if (!valid) {
            this.props.onInvalidInput(Number(newValue));
        }

        this.setState({
            value: newValue
        });
    }

    _isTouched() {
        return this.props.value !== CustomNumberInput.defaultProps.value && this.props.value !== null;
    }

    _isValueValid(value) {
        const checkMaxLimit = this.props.allowMaxLimit ? this.props.max < value : this.props.max <= value;
        const checkMinLimit = this.props.allowMinLimit ? this.props.min > value : this.props.min >= value; 
        return value === null || value === "" || !(checkMinLimit || checkMaxLimit || !isFinite(value) || isNaN(value));
    }

    render() {
        let tooltip = <></>;
        if (this.props.label && this.props.tooltipText) {
            tooltip = (
                <div className="icon-div">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="right">
                        { this.props.tooltipText }
                    </Tooltip>
                </div>
            );
        }

        let label = <></>;
        if (this.props.label) {
            label = (
                <small className="label">
                    { this.props.label }
                    { tooltip }
                </small>
            );
        }

        const invalidClass = (this.props.shouldValidate && !this.props.isValid && this.state.touched) ? "invalid-input" : "";
        return (
            <div className={ `input-container ${ this.props.className || '' }` }>
                { label }
                <Input
                    allowClear={ this.props.allowClear }
                    disabled={ this.props.disabled }
                    prefix={ this.props.prefix }
                    suffix={ this.props.suffix }
                    className={ invalidClass }
                    placeholder={ this.props.placeholder }
                    onChange={ this._onInputValueChange }
                    value={ this.state.value }
                    ref = { this.numberInput }
                />
            </div>
        );
    }
}
