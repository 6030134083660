import { Component } from 'react';
import PropTypes from 'prop-types';

import * as Constants from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomNumberInput from 'component/form/CustomNumberInput';
import RestService from 'service/RestService';
import NumberUtils from 'utils/NumberUtils';
import LaneUtils from 'utils/LaneUtils';

import './SubmitRFPBid.scss';

export default class SubmitRFPBid extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        rfpLane: PropTypes.object.isRequired,
        onBid: PropTypes.func,
        onSubmitInitialBid: PropTypes.func,
        bidPresent: PropTypes.bool
    };

    static defaultProps = {
        onBid: () => {/**/},
        onSubmitInitialBid: () => {/**/},
        bidPresent: false
    };

    constructor(props) {
        super(props);
        this._onBid = this._onBid.bind(this);
        this._onPriceChange = this._onPriceChange.bind(this);
        this._onVolumeChange = this._onVolumeChange.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._unsetState = this._unsetState.bind(this);
    }

    state = {
        showPopup: false,
        price: undefined,
        volume: undefined,
        validPrice: false,
        validVolume: false
    };

    _onBid() {
        RestService.instance()
            .post(`auction/rfp/${ this.props.rfp.id }/lane/${ this.props.rfpLane.id }/bid`, { price: this.state.price, volume: this.state.volume })
            .then(() => {
                this._unsetState();
                this.props.onBid();

                if (!this.props.bidPresent) {
                    this.props.onSubmitInitialBid();
                }
            });
    }

    _onPriceChange(value, isValid) {
        this.setState({ price: value, validPrice: isValid });
    }

    _onVolumeChange(value, isValid) {
        this.setState({ volume: value, validVolume: isValid });
    }

    _onOpenPopup() {
        this.setState({ showPopup: true });
    }

    _unsetState() {
        this.setState({
            showPopup: false,
            price: undefined,
            volume: undefined,
            validPrice: false,
            validVolume: false
        });
    }

    render() {
        const origin = LaneUtils.formatRegion(this.props.rfpLane.lane.origin);
        const destination = LaneUtils.formatRegion(this.props.rfpLane.lane.destination);
        const distance = NumberUtils.formatAsAWholeNumber(this.props.rfpLane.distance);

        const title = this.props.bidPresent ? 'Improve Bid' : 'Place a Bid';
        const description = this.props.bidPresent ? 'Place an updated bid' : 'Place your bid';
        const perLoadPriceToShow = this.state.price ? `$${ NumberUtils.formatWithDecimalNotation(this.state.price * this.props.rfpLane.distance) }` : '-';

        return (
            <Popup
                id="rfp-bid"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._unsetState }
                trigger={
                    <Button type="tertiary"
                            size="small"
                            onClick={ this._onOpenPopup }>
                        { title }
                    </Button>
                }>
                <div className="rfp-bid-popup">
                    <h6 className="heading">{ title }</h6>
                    <p className="description">
                        { description } on the <b>{ origin }</b> to <b>{ destination }</b> lane for a chance to be awarded volume on this lane.
                    </p>
                    <p><b>Your Bid</b></p>
                    <p>
                        By entering a bid, you will have an opportunity to compete for awarded volume.
                    </p>
                    <br/>
                    <div className="inputs">
                        <div className="field">
                            <small className="label"><b>Per Mile</b></small>
                            <CustomNumberInput
                                prefix="$"
                                placeholder="1.00"
                                min={ 0 }
                                max={ Constants.MAX_PRICE }
                                value={ this.state.price || this.state.price === 0 ? String(this.state.price) : null }
                                onChange={ this._onPriceChange }
                                isValid={ this.state.validPrice }
                            />
                        </div>
                        <div className="field">
                            <small className="label"><b>Bid Volume</b></small>
                            <CustomNumberInput
                                suffix={ `/${ this.props.rfpLane.volume }` }
                                placeholder="24"
                                min={ 0 }
                                max={ Number(this.props.rfpLane.volume) + 1 }
                                value={ this.state.volume || this.state.volume === 0 ? String(this.state.volume) : null }
                                decimals={ 0 }
                                onChange={ this._onVolumeChange }
                                isValid={ this.state.validVolume }
                            />
                        </div>
                    </div>
                    <small className="per-load-description">
                        Distance: { distance } mi.<br />
                        Per Load: { perLoadPriceToShow }
                    </small>
                    <div className="buttons-wrapper">
                        <Button
                            type="tertiary"
                            onClick={ this._unsetState }
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={ this._onBid }
                            disabled={ !this.state.validPrice || !this.state.validVolume }
                        >
                            Submit Bid
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
