import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock as editDeadline } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';

import { BID_STATUSES, CANDIDATE_TYPE, FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import Banner from 'component/Banner';
import CustomDateTimePicker from 'component/form/CustomDateTimePicker';
import FormStatusModal from 'component/form/FormStatusModal';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';
import DateUtils from 'utils/DateUtils';
import AuctionUtils from 'utils/AuctionUtils';

export default class ChangeAuctionDeadlinePopup extends Component {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        onPopupClose: PropTypes.func,
        onDeadlineChanged: PropTypes.func,
    };

    static defaultProps = {
        onPopupClose: () => { /* */ },
        onDeadlineChanged: () => { /* */ } 
    };

    constructor (props) {
        super(props);
        this._onDeadlineChange = this._onDeadlineChange.bind(this);
        this._onDeadlineSubmit = this._onDeadlineSubmit.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
    }

    state = {
        showDeadlineChangePopup: false,
        deadline: new Date(this.props.auction.deadline) || Date.now(),
        minDate: new Date(),
        loading: false,
        loadingStatus: undefined,
        disabledDeadline: false
    }

    _onDeadlineChange(value) {
        const currentDeadline = new Date(this.props.auction.deadline);
        const deadline = value ? value.toDate() : currentDeadline;
        const disabledDeadline = !deadline || deadline === currentDeadline || deadline < this.state.minDate || deadline <= Date.now() || deadline > moment(this.props.auction.load.pickupTime).add(1, 'month');
        this.setState({
            deadline,
            disabledDeadline
        });
    }

    _onDeadlineSubmit() {
        const newDeadline = new Date(this.state.deadline).setSeconds(0);

        // Don't send request if user waits for current time to pass or enters invalid input
        if (newDeadline <= Date.now() || newDeadline > moment(this.props.auction.load.pickupTime).add(1, 'month') || newDeadline < this.state.minDate) {
            this.setState({ disabledDeadline: true });
            return;
        }

        this.setState({ loading: true });
    
        TenderAuctionBrokerApiService.changeAuctionDeadline(this.props.auction.id, moment(newDeadline))
            .then(this.props.onDeadlineChanged)
            .then(() => this.setState({ loading: false, loadingStatus: FORM_STATUSES.SUCCESS }))
            .catch(() => this.setState({ loading: false, loadingStatus: FORM_STATUSES.ERROR }));
    }

    _onOpenPopup() {
        const currentBid = AuctionUtils.findCurrentBid(this.props.auction.bids);

        if (currentBid && BID_STATUSES.PENDING === currentBid.status && CANDIDATE_TYPE.SIGNED_CARRIER === currentBid.candidate.type) {
            this.setState({ minDate: new Date(currentBid.expiration) });
        } else {
            this.setState({ minDate: new Date() });
        }

        this.setState({ showDeadlineChangePopup: true, deadline: new Date(this.props.auction.deadline) || Date.now() });
    }

    _onClosePopup() {
        this.setState({
            showDeadlineChangePopup: false,
            deadline: this.props.auction.deadline || Date.now(),
            loading: false,
            loadingStatus: undefined
        });

        this.props.onPopupClose();
    }

    _formEditAuctionDeadlineButton() {
        return (
            <div onClick={ this._onOpenPopup } className="dropdown-item">
                <FontAwesomeIcon className="action-icon" icon={ editDeadline }/>
                <small className="edit-auction-deadline-action-name action-name">Edit Auction Deadline</small>
            </div>
        );
    }

    _formLoadingPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.LOADING }>
               <div>
                    <h6>Editing Auction Deadline</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </FormStatusModal>
        );
    } 

    _formSuccessPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                <div>
                    <h6>Auction Deadline Changed</h6>
                    <p>You have successfully changed the deadline for this auction.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formErrorPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClosePopup }>
                <div>
                    <h6>Failed to Edit Deadline</h6>
                    <p>Something went wrong. Please try again.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formChangeDeadlineContent() {
        const { auction } = this.props;

        let warningBanner;
        if (this.state.minDate > Date.now()) {
            warningBanner = <Banner type="warn" size="medium" content="The auction deadline cannot be before the current bid offer expiration." />;
        }

        return (
            <div className="action-dropdown-popup change-deadline-popup">
                <h6 className="heading">Edit Auction Deadline</h6>
                { warningBanner }
                <p className="description">
                    The current deadline is on <span className="bold">{ DateUtils.format(new Date(auction.deadline)) || 'N/A' }. </span>
                    Changing this deadline may affect the auction.
                </p>
                <p className="subheading">New Auction Deadline</p>
                <CustomDateTimePicker
                    className="date-picker"
                    date={ moment(this.state.deadline) }
                    minDate={ moment(this.state.minDate) }
                    maxDate={ moment(auction.load.pickupTime).add(1, 'month') }
                    handleChange={ this._onDeadlineChange }
                />
                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this._onClosePopup }>
                        Discard
                    </Button>

                    <Button
                        type="primary"
                        onClick={ this._onDeadlineSubmit }
                        disabled={ this.state.disabledDeadline }
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        let content;

        if (this.state.loading) {
            content = this._formLoadingPopup();
        } else if (FORM_STATUSES.SUCCESS === this.state.loadingStatus) {
            content = this._formSuccessPopup();
        } else if (FORM_STATUSES.ERROR === this.state.loadingStatus) { 
            content = this._formErrorPopup();
        } else {
            content = this._formChangeDeadlineContent();
        }

        return (
            <Popup
                id="popup-edit-deadline"
                size="medium"
                show={ this.state.showDeadlineChangePopup }
                onClose={ this._onClosePopup }
                trigger={ this._formEditAuctionDeadlineButton() }
            >
               { content }
            </Popup>
        );
    }
}
