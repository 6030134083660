import { Component } from 'react';

/**
 * @deprecated Usage of this class is deprecated (for deletion) since it is trying to solve the issue of
 * setting the state of an unmounted component but while doing that might cover up a
 * memory leak in the application in case an event listener stayed attached after the component umnounted.
 * Furthermore, in the 18.0.0 release of React (since March 29, 2022), the warning message about setting the state
 * on an unmounted component is removed since the workarounds such as this component make the code worse.
 */
export default class SafeStateComponent<P, S> extends Component<P, S> {

    _mounted: boolean = false;

    componentDidMount?(): void {
        this._mounted = true;
    }

    componentWillUnmount?(): void {
        this._mounted = false;
    }

    setState<K extends keyof S>(
        state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null),
        callback?: () => void
    ): void {
        if (this._mounted) {
            return this.setStateUnsafe(state, callback);
        }
    }

    setStateUnsafe<K extends keyof S>(
        state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null),
        callback?: () => void
    ): void {
        super.setState(state, callback);
    }
}