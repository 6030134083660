import { Component } from 'react';
import PropTypes from 'prop-types';
import { faUsers as spotMarketUsers } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH as dotsIcon } from '@fortawesome/pro-regular-svg-icons';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, TRIGGER_TYPES } from 'common/constants';
import CanAccess from 'component/CanAccess';
import Button from 'component/Button';
import Dropdown from 'component/Dropdown';
import ContentCard from 'component/card/ContentCard';
import CustomLoader from 'component/form/CustomLoader';
import Pagination from 'component/navigation/Pagination';
import AddLaneCarrier from 'component/lane/AddLaneCarrier';
import LaneCarrierCSVUpload from 'component/lane/LaneCarrierCSVUpload';
import LaneCarrierTableRow from 'component/lane/LaneCarrierTableRow';
import TableEmptyState from 'component/table/TableEmptyState';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import LaneCarrierApiService from 'service/api/LaneCarrierApiService';
import AuthorizationService from 'service/AuthorizationService';

import './LaneCarriers.scss';

export default class LaneCarriers extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        version: PropTypes.number
    };

    static defaultProps = {
        version: 0
    }

    constructor(props) {
        super(props);

        this._onLaneCarriersCardExpandChanged = this._onLaneCarriersCardExpandChanged.bind(this);
        this._editNegotiatedRate = this._editNegotiatedRate.bind(this);
        this._removeLaneCarrier = this._removeLaneCarrier.bind(this);
        this._setPageNumber = this._setPageNumber.bind(this);
        this._setPageSize = this._setPageSize.bind(this);
        this._onOpenActionsDropdown = this._onOpenActionsDropdown.bind(this);
        this._onCloseActionsDropdown = this._onCloseActionsDropdown.bind(this);
        this._setShouldUpdate = this._setShouldUpdate.bind(this);
    }

    state = {
        laneCarriersCardExpanded: true,
        carriers: [],
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        available: 0,
        showActionsDropdown: false,
        loading: false,
        canEdit: false,
        shouldUpdate: true
    }

    componentDidMount() {
        this._fetchLaneCarriers();
        this._checkCanEdit();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.version !== this.props.version 
            || prevState.pageNumber !== this.state.pageNumber
            || prevState.pageSize !== this.state.pageSize
            || prevState.shouldUpdate !== this.state.shouldUpdate)
            && this.state.shouldUpdate) {
                this._fetchLaneCarriers();
        }
    }

    _checkCanEdit() {
        AuthorizationService.instance().canAccess('routing-guide:write')
            .then(result => this.setState({ canEdit: result }))
    }

    _setPageNumber(pageNumber) {
        this.setState({ pageNumber });
    }

    _setPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _onLaneCarriersCardExpandChanged(isExpanded) {
        this.setState({ laneCarriersCardExpanded: isExpanded });
    }

    _fetchLaneCarriers() {
        LaneCarrierApiService.filter(this.props.lane.id, false, this.state.pageNumber, this.state.pageSize)
            .then(result => {
                this.setState({
                    carriers: result.data,
                    available: result.available,
                    loading: false
                });
            });
    }

    _editNegotiatedRate(carrierId, price) {
        LaneCarrierApiService.setNegotiatedRate(this.props.lane.id, carrierId, price)
            .catch(error => console.error(error.message));
    }

    _removeLaneCarrier(carrierId) {
        return LaneCarrierApiService.remove(this.props.lane.id, carrierId);
    }

    _setShouldUpdate(shouldUpdate) {
        this.setState({ shouldUpdate });
    }

    _formSMCarrierTable() {
        const carriersRows = this.state.carriers.map(smCandidate => 
            <LaneCarrierTableRow
                lane={ this.props.lane }
                laneCarrier={ smCandidate }
                key={ smCandidate.id }
                canEdit={ this.state.canEdit }
                editNegotiatedRate={ (price) => this._editNegotiatedRate(smCandidate.id, price) }
                removeCarrier={ () => this._removeLaneCarrier(smCandidate.id) }
                setShouldUpdate={ this._setShouldUpdate }
            />
        );

        return (
            <div className="sm-carriers-table">
                <Table>
                    <TableRow isHeader={ true }>
                        <TableHeaderCell className="name">Carrier Name</TableHeaderCell>
                        <TableHeaderCell className="negotiated-rate" alignment="right">Negotiated Rate</TableHeaderCell>
                        <TableHeaderCell className="email">Email</TableHeaderCell>
                        <TableHeaderCell className="date" alignment={ this.state.canEdit ? "left" : "right" }>Date Added</TableHeaderCell>
                        <CanAccess
                            action="routing-guide:write"
                            yes={
                                <TableHeaderCell className="actions" alignment="right">Actions</TableHeaderCell>
                            }
                         />
                    </TableRow>
                    { carriersRows }
                </Table>

                <Pagination 
                    pageNumber={ this.state.pageNumber }
                    pageSize={ this.state.pageSize }
                    available={ this.state.available }
                    onSetPage={ this._setPageNumber }
                    onSetPageSize={ this._setPageSize }
                    fixed={ false }
                    colorTheme="light"
                    resultsText="carriers"
                />
            </div>
        );         
    }

    _formEmptyState() { 
        const { lane } = this.props;

        const actions = (
            <CanAccess
                action="routing-guide:write"
                yes={
                    <div className="sm-quick-actions">
                        <AddLaneCarrier lane={ lane } setShouldUpdate={ this._setShouldUpdate } />
                        <LaneCarrierCSVUpload lane={ lane } />
                    </div>
                }
            />
        );

        return (
            <TableEmptyState
                icon={ spotMarketUsers }
                title="No Carriers Added"
                description={ <>To proceed, please add spot market carriers to this lane.</> }
                actions={ actions }
                role="routing-guide:write"
            />
        );
    }

    _formLoader() {
        return  (
            <div className="sm-carrier-loader">
               <CustomLoader size="large" />
           </div>
       );
    }

    _onOpenActionsDropdown(event) {
        this.setState({ showActionsDropdown: true });
        event.stopPropagation();
    }

    _onCloseActionsDropdown() {
        this.setState({ showActionsDropdown: false });
    }

    _formActions() {
        const actions = [
            <AddLaneCarrier key={ `${ this.props.lane.id }-add-lane-carrier` } lane={ this.props.lane } trigger={ TRIGGER_TYPES.LABEL } />,
            <LaneCarrierCSVUpload key={ `${ this.props.lane.id }-lane-carrier-csv-upload` } lane={ this.props.lane } trigger={ TRIGGER_TYPES.LABEL } />,
        ];
        
        if (this.state.laneCarriersCardExpanded) {
            return (
                <CanAccess 
                    action="routing-guide:write"
                    yes={
                        <>
                            <div className="sm-actions">
                                <Dropdown 
                                    id="sm-actions-dropdown"
                                    direction="bottom-left"
                                    show={ this.state.showActionsDropdown }
                                    onClose={ this._onCloseActionsDropdown }
                                    trigger={
                                        <div>
                                            <Button
                                                link="#sm-actions-dropdown"
                                                type="tertiary"
                                                onClick={ this._onOpenActionsDropdown }
                                                rightIcon={ dotsIcon }
                                                size="small"
                                            >
                                                Actions
                                            </Button>
                                        </div>
                                    }
                                    actions={ actions }
                                />
                            </div>
                        </>
                    }
                    no={ <></> }
                />
            );
        }

        return null;
    }

    render() {
        let content;
        if (this.state.loading) {
            content = this._formLoader();
        } else if (this.state.available) {
            content = this._formSMCarrierTable();
        } else {
            content = this._formEmptyState();
        }

        return (
            <div className="sm-carriers-card">
                <ContentCard
                    isExpandable={ true }
                    heading="Spot Market Carriers"
                    headingSeparator={ false }
                    actions={ this._formActions() }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    onExpandedStateChanged={ this._onLaneCarriersCardExpandChanged }
                    className="spot-market-candidates-card main-card"
                >
                   { content }
                </ContentCard>
            </div>
        );
    }
}
