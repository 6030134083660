import { Component, } from 'react';
import PropTypes from 'prop-types';

import './MessageBubble.scss';

export default class MessageBubble extends Component {
    static propTypes = {
        direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
    }

    static defaultProps = {
        direction: 'right'
    }

    render() {
        const className = `message-bubble ${ this.props.direction }`;
        return (
            <div className={ className }>
                <div className="message-bubble-arrow" />
                <div className="message-bubble-content">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
