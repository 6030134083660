export default class StringUtils {

    static initials(text) {
        if (!text) {
            return '';
        }

        return text
            .split(' ')
            .map(word => word.charAt(0))
            .join('')
            .toUpperCase();
    }

    static firstTwoInitials(text) {
        if (!text) {
            return '';
        }

        return text
            .split(' ')
            .map(word => word.charAt(0))
            .join('')
            .toUpperCase()
            .substring(0, 2);
    }

    static pluralize(amount, word, suffix = 's') {
        return amount === 1 ? word : word + suffix;
    }

    /**
     * Capitalizes first letters of words in string.
     *
     * The beginning of a word is any character that meets the following requirements:
     *   1. it is the first character in the string
     *   2. it is the first character after a whitespace (with whitespace being defined as any character whose value is less than 32 - the space character)
     *   3. it is the first character after any of the following characters: ", ', (, [, {, -, /
     * 
     * @param {string} str String to be modified
     * @param {boolean=false} lower Whether all other letters should be lowercased
     * @return {string}
     * @usage
     *   capitalize('fix this string');     // -> 'Fix This String'
     *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
     *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
     *   capitalize('java/javascript')      // -> 'Java/Javascript'
     */
    static capitalize(str, lower = false) {
        if (!str) {
            return '';
        }

        if (lower) {
            str = str.toLowerCase();
        }

        let result = '';
        const delimiters = ['"', '\'', '(', '[', '{', '-', '/'];
        let isWordBeginning = true;
        for (let i = 0; i < str.length; i++) {
            if (isWordBeginning) {
                result += str[i].toUpperCase();
            } else {
                result += str[i];
            }

            isWordBeginning = delimiters.includes(str[i]) || str[i].charAt(0) <= 32;
        }

        return result;
    }

    static isLowerCase(str) {
        return str === str.toLowerCase() && str !== str.toUpperCase();
    }

    static isUpperCase(str) {
        return str === str.toUpperCase() && str !== str.toLowerCase();
    }

    static replaceBlankWithPlaceholder(str, placeholder) {
        if (!str || str.trim().length === 0) {
            return placeholder;
        }

        return str;
    }

    /**
     * Determines if the string is blank.
     * 
     * String is considered blank if it is null or contains only whitespace characters.
     * @param {string} str
     * @returns true if the string is blank, false otherwise.
     */
    static isBlank(str) {
        return !str || str.trim() === '';
    }

    /**
     * Compares two strings, ignoring case considerations. Two strings are considered equal
     * ignoring case if they are of the same length and corresponding Unicode code points 
     * in the two strings are equal ignoring case.
     * 
     * @param {string} a 
     * @param {string} b 
     * @returns true if both parameters are of type string and are equal ignoring case, true
     *          if both parameters aren't of type string but are equal, false otherwise.
     */
    static equalsIgnoreCase(a, b) {
        return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
    }
}
