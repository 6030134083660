import { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

import Separator from 'component/Separator'
import NotificationItem from 'component/notifications/NotificationItem';
import Notification from 'model/Notification';

import './NotificationPane.scss';

export default class NotificationPane extends Component {

    static propTypes = {
        notifications: PropTypes.arrayOf(PropTypes.instanceOf(Notification)),
        unreadNotificationCount: PropTypes.number,
        onNotificationRead: PropTypes.func,
        onDropdownClose: PropTypes.func,
        scrolledToTop: PropTypes.bool
    }

    static defaultProps = {
        notifications: [],
        unreadNotificationCount: 0,
        onNotificationRead: () => {/* */},
        onDropdownClose: () => {/* */},
        scrolledToTop: true,
    }

    state = {
        shouldScrollToTop: true
    }

    _markRead(id) {
        this.setState({
            shouldScrollToTop: false
        }, () => {
            this.props.onNotificationRead(id);
        });
    }

    _onSeeAllNotificationsClick(e) {
        if (this.props.notifications.length === 0) {
            e.preventDefault();
        }
    }

    /**
     *
     * @type {React.RefObject | {current: null}}
     * @private
     */
    _notificationsRef = createRef();

    componentDidMount() {
        this._notificationsRef.current.scrollTo(0, 0);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.scrolledToTop !== this.props.scrolledToTop) {
            this.setState({
                shouldScrollToTop: this.props.scrolledToTop
            });
        }

        if (this.state.shouldScrollToTop) {
            this._notificationsRef.current.scrollTo(0, 0);
        }
    }
    
    render() {
        let notificationPaneContent;
        const className = 'notification-pane';
        const buttonsStyleClass = this.props.notifications.length === 0 ? 'disabled' : '';

        if (this.props.notifications.length === 0) {
            notificationPaneContent = (
                <div className="notification-pane-empty">
                    <h4><FontAwesomeIcon icon={ faBell } className="notification-pane-icon"/></h4>
                    <h6><b>No Notifications</b></h6>
                    <small className="notification-pane-empty-description">We will let you know when there is something new for you.</small>
                </div>
            );
        } else {
            notificationPaneContent = this.props.notifications.map((n, index) => (
                <span key={ n.id }>
                    <NotificationItem key={ n.id }
                                      id={ n.id }
                                      embedded={ true }
                                      notification={ n }
                                      onClose={ () => this.props.onDropdownClose() }
                                      onSeen={ () => this._markRead(n.id) } />
                    { n.seen && (index !== this.props.notifications.length - 1 ) && <Separator /> }
                </span>
            ));
        }

        const unreadNotificationCount = this.props.unreadNotificationCount;
        const areAllRead = unreadNotificationCount === 0;

        let markAllReadAction;
        if (!areAllRead) {
            markAllReadAction = (
                <sup className={ 'link col-4 ' } onClick={ () => this._markRead()  }>
                    Mark All as Read { ("(" + unreadNotificationCount + ")") }
                </sup>
            );
        } else {
            markAllReadAction = (
            <sup className={ 'link col-4 disabled ' }>
                Mark All as Read
            </sup>
            )
        }

        return (
            <div className={ className }>
                <div className="notification-header row">
                    <p className="title col-8">
                        Notifications
                    </p>
                    { markAllReadAction }
                </div>
                <div className="notification-list" ref={ this._notificationsRef }>
                    { notificationPaneContent }
                </div>
                <div className="notification-footer">
                    <NavLink 
                        to="/notifications"
                        onClick={ this._onSeeAllNotificationsClick.bind(this) }
                        className={ `notification-footer-link ${ buttonsStyleClass }` }>
                        <sup> See All Notifications </sup>
                    </NavLink>
                </div>
            </div>
        );
    }
}
