import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle as info,
    faExclamationTriangle as warn,
    faExclamationCircle as error,
    faCheckCircle as success,
} from '@fortawesome/pro-solid-svg-icons';

import { faTimes as exit } from '@fortawesome/pro-light-svg-icons';

import './Banner.scss';

export default class Banner extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['info', 'success', 'warn', 'error']),
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        textStyle: PropTypes.oneOf(['regular', 'bold']),
        action: PropTypes.element,
        allowDiscard: PropTypes.bool,
        className: PropTypes.string,
        onDiscard: PropTypes.func
    };

    static defaultProps = {
        type: 'info',
        size: 'large',
        content: '',
        textStyle: 'regular',
        action: undefined,
        allowDiscard: false,
        className: '',
        onDiscard: () => { /* */ }
    };

    static iconMapping = {
        info: info,
        warn: warn,
        error: error,
        success: success
    };

    render() {
        const { type, size, content, textStyle, className, action } = this.props;
        const icon = Banner.iconMapping[type];

        let exitContainer = <></>, actionContainer= <></>;

        if (this.props.allowDiscard) {
            exitContainer = <FontAwesomeIcon className="icon exit" icon={ exit } onClick={ this.props.onDiscard } />;
        }

        if (action) {
            actionContainer = (
                <div className="banner-actions">
                    { action }
                </div>
            );
        }

        return (
            <div className={ `banner ${ type } ${ size } ${ className }` }>
                <FontAwesomeIcon className="icon" icon={ icon } />
                <small className={ `banner-content ${ textStyle }` }>{ content }</small>
                { actionContainer }
                { exitContainer }
            </div>
        );
    }
}
