import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt as download, faRoad as lane, faSearch as search } from '@fortawesome/pro-solid-svg-icons';

import {
    RFP_AUCTION_STATUSES,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE,
    RFP_ROUND_TYPE,
    RG_DETAILS_VIEW_MODE,
    TENDER_CANDIDATE_STATUS
} from 'common/constants';
import CanAccess from 'component/CanAccess';
import Button from 'component/Button';
import PageHeader from 'component/PageHeader';
import Banner from 'component/Banner';
import DownloadFileComponent from 'component/DownloadFileComponent';
import RFPLaneCard from 'component/card/rfp/RFPLaneCard';
import ContentCard from 'component/card/ContentCard';
import FilterPopup from 'component/filter/FilterPopup';
import TagList from 'component/filter/TagList';
import TenderCandidateOfferActions from 'component/lane/TenderCandidateOfferActions';
import Pagination from 'component/navigation/Pagination';
import RFPCSVUpload from 'component/rfp/RFPCSVUpload';
import RFPOfferActions from 'component/rfp/RFPOfferActions';
import ObjectUtils from 'utils/ObjectUtils';
import RFPUtils from 'utils/RFPUtils';
import RoutingGuideUtils from 'utils/RoutingGuideUtils';
import StringUtils from 'utils/StringUtils';
import RestService from 'service/RestService';
import AuthorizationService from 'service/AuthorizationService';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';

import './RFPLaneList.scss';

export default withRouter(class RFPLaneList extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        viewMode: PropTypes.string,
        version: PropTypes.number,
        onUpdated: PropTypes.func
    };

    static defaultProps = {
        viewMode: null,
        version: 0,
        onUpdated: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
            loading: true,
            filter: {},
            rejectReasons: [],
            numberOfPendingOffers: 0,
            candidateView: false,
            version: 0
        };

        this._onFilterChange = this._onFilterChange.bind(this);
        this._mapFilterForTags = this._mapFilterForTags.bind(this);
        this._onPageNumberChange = this._onPageNumberChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
        this._fetchLanes = this._fetchLanes.bind(this);
        this._onUpdated = this._onUpdated.bind(this);
        this._fetchOrigins = this._fetchOrigins.bind(this);
        this._fetchDestinations = this._fetchDestinations.bind(this);
        this._acceptAllTenderCandidateOffers = this._acceptAllTenderCandidateOffers.bind(this);
        this._rejectAllTenderCandidateOffers = this._rejectAllTenderCandidateOffers.bind(this);
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const candidateView = await AuthorizationService.instance().canAccess('rfp:read');

        this.setState({ 
            filter: RFPLaneList._getFilterFromSearchParams(searchParams),
            candidateView
        }, this._fetchLanes);

        if (RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status) {
            this._fetchRejectReasons();
        }

        this._fetchNumberOfPendingOffers();
    }

    componentDidUpdate(prevProps, prevState) {
        const searchParams = new URLSearchParams(this.props.location.search);
        const filter = RFPLaneList._getFilterFromSearchParams(searchParams);

        if (!ObjectUtils.equal(filter, prevState.filter)) {
            this.setState({ filter, pageNumber: DEFAULT_PAGE_NUMBER }, this._fetchLanes);
        } else if (prevProps.version !== this.props.version 
            || !ObjectUtils.equal(this.props.rfp, prevProps.rfp) 
            || prevProps.viewMode !== this.props.viewMode) {
            this._fetchLanes();
        }

        const mode = searchParams.get('mode');

        if (RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === this.props.viewMode && !mode) {
            searchParams.append('mode', 'read_only');
            this.props.history.replace({
                pathname: window.location.pathname,
                search: `?${ searchParams.toString() }`
            });
        }
    }

    static _getFilterFromSearchParams(searchParams) {
        return {
            origin: searchParams.get('origin'),
            destination: searchParams.get('destination'),
            cargo_type: searchParams.get('cargo_type'),
            tender_candidate_status: searchParams.get('tender_candidate_status'),
            sort: searchParams.get('sort')
        };
    }

    _onFilterChange(filter) {
        const searchParams = new URLSearchParams(this.props.location.search);
        const mode = searchParams.get('mode');
        filter = ObjectUtils.removeEmptyKeys(filter);

        if (mode) {
            filter.mode = mode;
        }

        this.props.history.push({
            search: '?' + new URLSearchParams(filter).toString(),
            pathname: window.location.pathname
        });

        this.setState({ filter, pageNumber: DEFAULT_PAGE_NUMBER });
    }

    _mapFilterForTags(key, value) {
        if ('tender_candidate_status' === key) {
            return { 
                title: 'Status',
                text: TENDER_CANDIDATE_STATUS[value].candidateFacingText
            };
        }
    }

    _onPageNumberChange(pageNumber) {
        this.setState({ pageNumber }, this._fetchLanes);
    }

    _onPageSizeChange(pageSize) {
        this.setState({ pageSize }, this._fetchLanes);
    }

    _canBid() {
        return RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status
            && RFP_ROUND_TYPE.BIDDING === RFPUtils.latestRound(this.props.rfp).type;
    }

    _onUpdated() {
        this._fetchLanes();
        this._fetchNumberOfPendingOffers();
        this.props.onUpdated();
    }

    _fetchLanes() {
        this.setState({ loading: true });

        const originFilter = this.state.filter.origin ? this.state.filter.origin.split(', ') : [];
        const destionationFilter = this.state.filter.destination ? this.state.filter.destination.split(', ') : [];
        
        let lanesApiUrl;
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            lanesApiUrl = `auction/rfp/${ this.props.rfp.id }/lane`;
        } else {
            lanesApiUrl = `routing-guide/${ this.props.rfp.id }/lane`;
        }

        RestService.instance()
            .get(
                lanesApiUrl,
                {
                    pageNumber: this.state.pageNumber,
                    pageSize: this.state.pageSize,
                    sort: this.state.filter.sort ? [this.state.filter.sort, ""] : null,
                    originCity: originFilter[0],
                    originState: originFilter[1],
                    destinationCity: destionationFilter[0],
                    destinationState: destionationFilter[1],
                    type: this.state.filter.cargo_type,
                    status: this.state.filter.tender_candidate_status
                }
            )
            .then(data => this.setState(prevState => ({
                ...data,
                loading: false,
                version: prevState.version + 1
            })));
    }

    _fetchRejectReasons() {
        RestService.instance().get('/auction/rfp/reject-reasons')
            .then(response => this.setState({
                rejectReasons: response.data
            }));
    }

    _fetchOrigins(params) {
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            return RFPUtils.fetchRFPOrigins(this.props.rfp, params);
        }

        return RoutingGuideUtils.fetchRoutingGuideOrigins(this.props.rfp, params);
    }

    _fetchDestinations(params) {
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            return RFPUtils.fetchRFPDestinations(this.props.rfp, params);
        }

        return RoutingGuideUtils.fetchRoutingGuideDestinations(this.props.rfp, params);
    }

    _fetchNumberOfPendingOffers() {
        if (RFP_AUCTION_STATUSES.APPLIED === this.props.rfp.status && this.state.candidateView) {
            TenderCandidateApiService.getNumberOfPendingOffers(this.props.rfp.id)
                .then(numberOfPendingOffers => this.setState({ numberOfPendingOffers }))
                .catch(error => console.error('An error occurred while fetching number of pending offers.', error));
        }
    }

    _acceptAllTenderCandidateOffers() {
        TenderCandidateApiService.acceptAll(this.props.rfp.id).then(this._onUpdated)
            .catch(error => console.error('An error occurred while accepting all offers.', error));
    }

    _rejectAllTenderCandidateOffers() {
        TenderCandidateApiService.rejectAll(this.props.rfp.id).then(this._onUpdated)
            .catch(error => console.error('An error occurred while rejecting all offers.', error));
    }

    _formBidActions() {
        const { origin, destination, cargo_type: type } = this.state.filter;
        const [ originCity, originState ] = origin ? origin.split(', ') : [];
        const [ destinationCity, destinationState ] = destination ? destination.split(', ') : [];

        const params = {
            originCity,
            originState,
            destinationCity,
            destinationState,
            type
        };

        let bidActions = <></>;
        if (this._canBid()) {
            bidActions = (
                <CanAccess
                    action="rfp:bid"
                    yes={
                        <>
                            <DownloadFileComponent
                                trigger={
                                    <Button type="tertiary" size="small" leftIcon={ download }>
                                        Download Bid Template
                                    </Button>
                                }
                                reference={{ endpoint: RFPAuctionApiService.getDownloadBidTemplateURL(this.props.rfp.id), params }}
                                isStaticResource={ false } 
                            />

                            <RFPCSVUpload
                                rfp={ this.props.rfp }
                                size="small"
                                onCreated={ this._onUpdated }
                                type="bid" 
                            />
                        </>
                    }
                />
            );
        } else if (RFPUtils.isConfirmationLatest(this.props.rfp) && RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status) {
           bidActions = (
                <CanAccess 
                    action="rfp:bid"
                    yes={
                        <RFPOfferActions 
                            rfp={ this.props.rfp }
                            bulk={ true }
                            rejectReasons={ this.state.rejectReasons }
                            version={ this.state.version }
                            onActionPerformed={ this._onUpdated } 
                        />
                    }
                />
           );
        } else if (!RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status) && this.state.numberOfPendingOffers > 0) {
            bidActions = (
                <CanAccess 
                    action="rfp:read"
                    yes={ 
                        <TenderCandidateOfferActions
                            bulk={ true }
                            onAccept={ this._acceptAllTenderCandidateOffers }
                            onReject={ this._rejectAllTenderCandidateOffers } 
                        />
                    }
                />
            );
        }

        return bidActions;
    }

    _formPendingOffersWarning() {
        const { numberOfPendingOffers } = this.state;

        if (numberOfPendingOffers > 0) {
            return (
                <div className="pending-offers-warning">
                    <Banner 
                        type="warn" 
                        size="small" 
                        content={ `You have ${ numberOfPendingOffers } pending ${ StringUtils.pluralize(numberOfPendingOffers, 'offer') } on this Routing Guide.` } 
                    />
                </div>
            );
        }

        return <></>;
    }

    _formFilterPopup() {
        const showStatusFilter = !RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status) && this.state.candidateView;
        return (
            <FilterPopup
                defaultFilter={ this.state.filter }
                showTenderCandidateStatusFilter={ showStatusFilter }
                fetchOrigins={ this._fetchOrigins }
                fetchDestinations={ this._fetchDestinations }
                onFilterChange={ this._onFilterChange }
            />
        );
    }

    render() {
        const filtered = Object.values(this.state.filter).some(filter => filter !== null);
        if (this.state.data.length === 0 && !this.state.loading && this.state.pageNumber === 1 && !filtered) {
            return (
                <div className="rfp-lane-list-container">
                    <ContentCard>
                        <div className="empty-list">
                            <FontAwesomeIcon className="lane-icon" icon={ lane } />
                            <h5>There are currently no lanes added.</h5>
                            { RFP_AUCTION_STATUSES.PENDING === this.props.rfp.status &&
                                <CanAccess 
                                    action="rfp:write"
                                    yes={
                                        <>
                                            <p>Please add lanes in order to continue with RFP.</p>
                                            <RFPCSVUpload rfp={ this.props.rfp } onCreated={ this._onUpdated } />
                                        </>
                                    }
                                />
                            }
                        </div>
                    </ContentCard>
                </div>
            );
        }
        
        let noResultsFiltered = <></>;
        if (this.state.data.length === 0 && !this.state.loading && this.state.pageNumber === 1) {
            noResultsFiltered = (
                <div className="rfp-lane-list-container empty-list filter-result">
                    <FontAwesomeIcon className="lane-icon" icon={ search } />
                    <h5>No Lanes Here</h5>
                    <p>There were no results that matched your filter.</p>
                </div>
            );
        }

        return (
            <div className="rfp-lane-list-container">
                <PageHeader title="Lanes">
                    { this._formPendingOffersWarning() }
                    <div className="header-actions">
                        { this._formBidActions() }
                        { RFP_AUCTION_STATUSES.PENDING === this.props.rfp.status && 
                            <CanAccess 
                                action="rfp:write"
                                yes={
                                    <RFPCSVUpload
                                        rfp={ this.props.rfp }
                                        size="small"
                                        onCreated={ this._onUpdated } 
                                    />
                                }
                            />
                        }       
                        { this._formFilterPopup() }
                    </div>
                </PageHeader>

                <div className="rfp-lanes-filters">
                    <TagList filter={ this.state.filter } mapper={ this._mapFilterForTags } onFilterChange={ this._onFilterChange } />
                </div>

                { this.state.data.map(rfpLane => (
                    <RFPLaneCard
                        key={ rfpLane.id }
                        rfp={ this.props.rfp }
                        viewMode={ this.props.viewMode }
                        rfpLane={ rfpLane }
                        rejectReasons={ this.state.rejectReasons }
                        onUpdated={ this._onUpdated }
                        version={ this.state.version } 
                    />
                )) }

                { noResultsFiltered }

                <Pagination
                    pageSize={ this.state.pageSize }
                    pageNumber={ this.state.pageNumber }
                    available={ this.state.available }
                    onSetPage={ this._onPageNumberChange }
                    onSetPageSize={ this._onPageSizeChange }
                    fixed={ false }
                />
            </div>
        );
    }
});
