import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthorizationService from 'service/AuthorizationService';
import ObjectUtils from 'utils/ObjectUtils';

export default class CanAccess extends Component {

    static propTypes = {
        action: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]).isRequired,
        data: PropTypes.shape({
            userId: PropTypes.string,
            accessedUserId: PropTypes.string
        }),
        loading: PropTypes.node,
        yes: PropTypes.node,
        no: PropTypes.node,
        authorizationService: PropTypes.instanceOf(AuthorizationService)
    }

    static defaultProps = {
        loading: null,
        yes: null,
        no: null,
        data: null,
        authorizationService: AuthorizationService.instance()
    }

    static actionChanged(prevAction, currAction) {
        return (typeof(prevAction) !== typeof(currAction)) ||
                (Array.isArray(prevAction) && !ObjectUtils.arraysEqual(prevAction, currAction)) ||
                (!Array.isArray(prevAction) && prevAction !== currAction);
    }

    static getDerivedStateFromProps(props, state) {
        if (CanAccess.actionChanged(props.action, state.action)) {
            return {
                hasRole: undefined,
                action: props.action,
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    state = {
        hasRole: undefined,
        action: this.props.action
    }

    _mounted = false;

    componentDidMount() {
        this._mounted = true;
        this._updateHasRole();
    }

    componentDidUpdate(prevProps) {
        if (CanAccess.actionChanged(prevProps.action, this.props.action) || JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this._updateHasRole();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async _updateHasRole() {
        const actions = Array.isArray(this.props.action) ? this.props.action : [ this.props.action ];
        let hasRole = false;

        for (const action of actions) {
            hasRole = await this._hasRole(action);

            if (hasRole) {
                break;
            }
        }

        if (this._mounted) {
            this.setState(() => ({ hasRole }));
        }
    }

    /**
     * @returns {Promise<boolean>}
     * @private
     */
    _hasRole(action = this.props.action, data = this.props.data) {
        return this.props.authorizationService.canAccess(action, data);
    }

    render() {
        if (this.state.hasRole === undefined) {
            return (this.props.loading);
        }

        return this.state.hasRole ? this.props.yes : this.props.no;
    }
}
