import { Component } from 'react';

import  NotificationMenu from "component/notifications/NotificationMenu";

import './QuickActions.scss';

export default class QuickActions extends Component {

    render() {
        return (
            <div className="header-quick-actions">
                <div className="quick-action notifications">
                    <NotificationMenu />
                </div>
            </div>
        );
    }
}
