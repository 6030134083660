import { Component } from 'react';
import PropTypes from 'prop-types';

import './TextEmphasisBox.scss';

export default class TextEmphasisBox extends Component {
    static propTypes = {
        color: PropTypes.oneOf(['orange', 'cyan', 'blue', 'grey', 'dark-grey', 'red']),
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        wide: PropTypes.bool,
        className: PropTypes.string,
        children: PropTypes.any
    };

    static defaultProps = {
        color: '',
        size: 'large',
        wide: false,
        className: '',
        children: null
    };

    render() {
        const { color, size, wide, className, children } = this.props;

        return (
            <div className={ `text-emphasis-box ${ className } ${ size } ${ color } ${ wide ? 'wide' : '' }` }>
                { children }
            </div>
        );
    }
}
