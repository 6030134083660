import { Component } from 'react';
import PropTypes from 'prop-types';

import './CustomTextInput.scss';

export default class CustomTextArea extends Component {

    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        rows: PropTypes.number,
        cols: PropTypes.number,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        fieldName: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        maxLength: PropTypes.number,
        acceptsWhitespace: PropTypes.bool,
        characterCounter: PropTypes.bool,
        onValueChange: PropTypes.func,
    }

    static defaultProps = {
        id: undefined,
        label: "",
        rows: 1,
        cols: 1,
        placeholder: "",
        value: "",
        required: false,
        fieldName: null,
        disabled: false,
        maxLength: null,
        acceptsWhitespace: false,
        characterCounter: false,
        onValueChange: () => { /* */ }
    }

    constructor(props) {
        super(props);
        this._onValueChange = this._onValueChange.bind(this);
    }

    state = {
        isValid: true
    }

    _onValueChange(event) {
        const value = event.target.value || "";
        this.setState({ isValid: !this.props.required || (!this.props.acceptsWhitespace && value.trim() !== "") || (this.props.acceptsWhitespace && value.length > 0) })
        this.props.onValueChange(event);
    }

    render() {
        const requiredIndicator = this.props.required ? <span className="required-indicator">*</span> : <></>;
        const inputStyle = `${ !this.state.isValid ? "invalid" : "completed" }`;
        let characterCounter;

        if (this.props.characterCounter) {
            characterCounter = (
                <div className="textarea-information">
                    <p className="textarea-character-count">
                        { this.props.value.length}/{ this.props.maxLength } Characters
                    </p>
                </div>
            );
        }

        return (
            <div id={ this.props.id } className="input-field">
                <small className="input-label">
                    { this.props.label }
                    { requiredIndicator }
                </small>
                <div className="textarea-input">
                    <textarea
                        rows={ this.props.rows }
                        cols={ this.props.cols }
                        placeholder={ this.props.placeholder }
                        name={ this.props.fieldName } 
                        onChange={ this._onValueChange } 
                        value={ this.props.value } 
                        className={ inputStyle }
                        disabled={ this.props.disabled }
                        maxLength={ this.props.maxLength }
                    />
                </div>
                { characterCounter } 
            </div>
        );
    }
}
