import { Component } from 'react';
import PropTypes from 'prop-types';
import { faCheck as applyIcon } from '@fortawesome/pro-solid-svg-icons';

import Popup from 'component/Popup';
import Button from 'component/Button';
import RestService from 'service/RestService';

export default class RFPApply extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        onSubmit: () => {/**/}
    }

    state = {
        showPopup: false,
        loading: false
    };

    constructor(props) {
        super(props);
        this._onSubmit = this._onSubmit.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onShowPopup = this._onShowPopup.bind(this);
    }

    _onSubmit() {
        this.setState({ loading: true });

        RestService.instance()
            .post(`auction/rfp/${ this.props.rfp.id }/apply`)
            .then(() => this.setState({ showPopup: false, loading: false }))
            .then(() => this.props.onSubmit());
    }

    _onCancel() {
        this.setState({ showPopup: false });
    }

    _onShowPopup() {
        this.setState({ showPopup: true });
    }

    render() {
        return (
            <Popup
                id="rfp-apply-popup"
                closeButton={ false }
                show={ this.state.showPopup }
                onClose={ this._onCancel }
                trigger={
                    <Button type="primary"
                            size="small"
                            leftIcon={ applyIcon }
                            onClick={ this._onShowPopup }>
                        Apply
                    </Button>
                 }
            >
                <div className="rfp-apply-popup">
                    <p className="heading">Apply this RFP</p>

                    <p className="description">Once you apply this RFP, a new routing guide will be created.</p>
                    <p className="description">
                        The created routing guide will be effective during the period defined by the effective dates. Further on, you can make changes on the routing guide. RFP will be available as read-only.
                    </p>

                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._onCancel }>
                            Cancel
                        </Button>

                        <Button size="small" onClick={ this._onSubmit }>
                            Apply
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
