import { Component } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH as menu } from '@fortawesome/pro-light-svg-icons';

import Dropdown from "component/Dropdown";
import CarrierStatusIcon  from 'component/carrier/CarrierStatusIcon';
import DeclareWinnerPopup from 'component/load/DeclareWinnerPopup';
import TableRow from 'component/table/custom/TableRow';
import TableCell from 'component/table/custom/TableCell';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './SpotMarketBidTableRow.scss';

export default class SpotMarketBidTableRow extends Component {
    static propTypes = {
        bid: PropTypes.object.isRequired,
        auction: PropTypes.object.isRequired,
        showActions: PropTypes.bool,
        lastElement: PropTypes.bool,
        onDeclareWinner: PropTypes.func
    };

    static defaultProps = {
        showActions: true,
        lastElement: false,
        onDeclareWinner: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onDeclareWinner = this._onDeclareWinner.bind(this);
        this._onCancelWinnerDeclaration = this._onCancelWinnerDeclaration.bind(this);
        this._onOpenWinnerDeclarationPopup = this._onOpenWinnerDeclarationPopup.bind(this);
    }

    state = {
        showBrokerActionsDropdown: false
    }

    _onDeclareWinner() {
        this.setState({
            showBrokerActionsDropdown: false
        });

        this.props.onDeclareWinner();
    }

    _onCancelWinnerDeclaration() {
        this.setState({ showBrokerActionsDropdown: false });
    }

    _onOpenWinnerDeclarationPopup(disabled) {
        if (!disabled) {
            this.setState({ showBrokerActionsDropdown: true });
        }
    }

    _formBrokerBidActionsMenu(disabled) {
        const declareWinnerPopup = 
            <DeclareWinnerPopup
                auctionId={ this.props.auction.id }
                bid={ this.props.bid }
                candidate={ this.props.bid.candidate }
                onDeclareWinner={ this._onDeclareWinner }
                onCancelWinnerDeclaration={ this._onCancelWinnerDeclaration }
            />
        const actions = [declareWinnerPopup];

        return (
            <div className="broker-bid-actions">
                <Dropdown 
                    id="broker-actions-dropdown"
                    direction="bottom-left"
                    show={ this.state.showBrokerActionsDropdown }
                    onClose={ this._onCancelWinnerDeclaration }
                    trigger={
                        <a href="#!" className={ `menu-icon-container ${ disabled ? 'disabled' : '' }` } onClick={ () => this._onOpenWinnerDeclarationPopup(disabled) } disabled={ disabled }>
                            <FontAwesomeIcon icon={ menu } className="icon" />
                        </a>
                    }
                    actions={ actions }
                />
            </div>
        );
    }


    render() {
        const { auction, bid, bid: { candidate: { carrier } }, showActions, lastElement } = this.props;

        return (
            <TableRow className="sm-bids-content-row" fontSize="small">
                <TableCell className={ `name ${ lastElement ? 'last-element' : '' }` } padding="small">
                    <div className="name-content">
                        <CarrierStatusIcon carrier={ carrier } className="carrier-warning" />
                        <Link to={ `/carrier/${ carrier.id }` } className="name-link">
                            <div className="name-ellipsis" title={ carrier.name }>
                                { carrier.name } ({ carrier.businessId })
                            </div>
                        </Link>
                    </div>
                </TableCell>
                <TableCell className="bid" padding="small" alignment="right">
                    ${ NumberUtils.formatWithDecimalNotation(bid.price) }
                </TableCell>
                <TableCell className="per-mile" padding="small" alignment="right">
                    ${ NumberUtils.formatWithDecimalNotation(PriceUtils.calculatePerMilePrice(bid.price, auction.load.routingGuideLane.distance)) }
                </TableCell>
                <TableCell className="date-of-bid" padding="small">
                    { DateUtils.format(bid.time) }
                </TableCell>
                <TableCell className={ `actions ${ lastElement ? 'last-element' : '' }` } padding="small">
                    { this._formBrokerBidActionsMenu(!carrier.active || !showActions) }
                </TableCell>
            </TableRow>
        );
    }
}
