import { Component } from 'react';
import PropTypes from 'prop-types';
import {
    faPen as edit,
    faPlusCircle as add,
    faTruckMoving as solidVan,
    faSnowflake as solidSnowflake,
    faInfoCircle as info
} from '@fortawesome/pro-solid-svg-icons';
import {
    faTruckMoving as van,
    faSnowflake as snowflake
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
    FORM_STATUSES,
    LANE_TYPES, 
    LOAD_TENDERING_WRITE_PERMISSION, 
    TRAILER_TYPE,
    PAYMENT_OPTIONS, 
    PAYMENT_OPTION_MAPPING,
    LANE_TYPE_PROPERTIES
} from 'common/constants';
import ContentCard from 'component/card/ContentCard';
import CustomTextInput from 'component/form/CustomTextInput';
import CustomSelect from 'component/form/CustomSelect';
import CustomSwitch from 'component/form/CustomSwitch';
import CustomRadioButton from 'component/form/CustomRadioButton';
import FormStatusModal from 'component/form/FormStatusModal';
import Button from 'component/Button';
import Banner from 'component/Banner';
import LoadTermsOfServicePopup from 'component/load/LoadTermsOfServicePopup';
import Tooltip from 'component/Tooltip';
import Popup from 'component/Popup';
import LoadingPopup from 'component/popups/LoadingPopup';
import CanAccess from 'component/CanAccess';
import DateUtils from 'utils/DateUtils';
import ObjectUtils from 'utils/ObjectUtils';
import StringUtils from 'utils/StringUtils';

import './WinnerDetailsComponent.scss';

export default class WinnerDetailsComponent extends Component {

    static propTypes = {
        driver: PropTypes.shape({
            driverName: PropTypes.string,
            driverPhoneNumber: PropTypes.string,
            truckId: PropTypes.string,
            trailerId: PropTypes.string,
            trailerType: PropTypes.string,
            paymentOption: PropTypes.string
        }),
        initiallyEditing: PropTypes.bool, // If true and details are not added, form will be shown by default
        canEditAndSend: PropTypes.bool,
        onSendDetails: PropTypes.func,
        carrierName: PropTypes.string,
        showInformMessage: PropTypes.bool,
        showWarningMessage: PropTypes.bool,
        laneType: PropTypes.string,
        showCarrierToS: PropTypes.bool, 
        acceptToS: PropTypes.func,
        driverInfoRequired: PropTypes.bool,
        bidApproved: PropTypes.bool,
        auction: PropTypes.object,
        auctionId: PropTypes.string,
        price: PropTypes.number,
        disableDriverInfoRequiredSwitch: PropTypes.bool,
        onDriverInfoRequiredChange: PropTypes.func
    }

    static defaultProps = {
        driver: null,
        initiallyEditing: false,
        canEditAndSend: true,
        onSendDetails: () => { /* */ },
        carrierName: '',
        showInformMessage: false,
        showWarningMessage: false,
        laneType: null,
        showCarrierToS: false, 
        acceptToS: () => { /* */ },
        driverInfoRequired: true,
        bidApproved: false,
        auction: null,
        price: null,
        auctionId: '',
        disableDriverInfoRequiredSwitch: false,
        onDriverInfoRequiredChange: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onInputValueChange = this._onInputValueChange.bind(this);
        this._onPaymentOptionChange = this._onPaymentOptionChange.bind(this);
        this._handleCancelEdit = this._handleCancelEdit.bind(this);
        this._handleSendDetails = this._handleSendDetails.bind(this);
        this._toggleEditing = this._toggleEditing.bind(this);
        this._onTrailerTypeChange = this._onTrailerTypeChange.bind(this);
        this._onAcceptToS = this._onAcceptToS.bind(this);
        this._onCloseToSPopup = this._onCloseToSPopup.bind(this);
        this._handleDriverDetailsSubmit = this._handleDriverDetailsSubmit.bind(this);
        this._onDriverInfoRequiredChange = this._onDriverInfoRequiredChange.bind(this);
        this._onDriverInfoRequiredChangePopupAccept = this._onDriverInfoRequiredChangePopupAccept.bind(this);
        this._onDriverInfoRequiredChangePopupCancel = this._onDriverInfoRequiredChangePopupCancel.bind(this);
        this._formDriverDetailsInfoEditForm = this._formDriverDetailsInfoEditForm.bind(this);
        this._onCloseSubmitDetailsPopup = this._onCloseSubmitDetailsPopup.bind(this);
    }

    static _defaultContent = {
        driverName: '',
        driverPhoneNumber: '',
        truckId: '',
        trailerId: '',
        trailerType: '',
        paymentOption: ''
    }

    state = {
        auctionId: this.props.auctionId,
        content: WinnerDetailsComponent._defaultContent,
        editing: this.props.initiallyEditing && !this.props.driver,
        submittedAt: '',
        submittedBy: '',
        submittedByCarrier: null,
        paymentOptionChoice: null,
        trailerType: null,
        showCarrierToS: this.props.showCarrierToS,
        showBrokerInfoToSPopup: false,
        showDriverInfoRequiredChangePopup: false,
        isDriverInfoRequiredLoading: false,
        submitDetailsPopupStatus: undefined,
        submitDetailsErrorMessage: undefined,
        invalidInput: false
    }

    _trailerOptions = {
        'VAN': {
            value: TRAILER_TYPE.VAN,
            label: 'Van',
            icon: van,
            solidIcon: solidVan,
            className: 'dry'
        },
        'REEFER': {
            value: TRAILER_TYPE.REEFER,
            label: 'Reefer',
            icon: snowflake,
            solidIcon: solidSnowflake,
            className: 'reefer'
        }
    };

    _tooltipText = 'This is a dry load, but the equipment type\nbeing used on each load is required by\nShipEX Logistics for insurance purposes.';

    componentDidMount() {
        this._setContent();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showCarrierToS !== this.props.showCarrierToS) {
            // This couldn't be checked separately as updates happen asynchronously
            if (this.state.auctionId !== this.props.auctionId) {
                this.setState({ showCarrierToS: this.props.showCarrierToS, auctionId: this.props.auctionId });
            } else if (this.state.showCarrierToS) {
                // We don't want to spam carrier with the TOS popup if they already said they don't want to accept it for this auction
                this.setState({ showCarrierToS: this.props.showCarrierToS });
            }            
        }

        if (JSON.stringify(prevProps.driver) !== JSON.stringify(this.props.driver) || prevProps.initiallyEditing !== this.props.initiallyEditing) {
            this.setState({ editing: this.props.initiallyEditing && !this.props.driver }, () => this._setContent());
        } else if (prevState.editing !== this.state.editing) {
            this._setContent();
        }
    }

    /**
     * Depending on whether carrier details have been provided and whether 
     * the user viewing the page can edit the carrier details form,
     * different content is rendered within the component from the props.
     *
     * @private
     */
    _setContent() {
        const { driver } = this.props;
        const { editing } = this.state;

        if (driver) {
            let trailerType = this._getDriverTrailerType(driver);

            this.setState({
                content: {
                    driverName: driver.driverName || '',
                    driverPhoneNumber: driver.driverPhoneNumber || '',
                    truckId: driver.truckId || '',
                    trailerId: driver.trailerId || '',
                    trailerType: this._trailerOptions[driver?.trailerType]?.value || '',
                    paymentOption: driver.paymentOption || ''
                },
                trailerType,
                paymentOptionChoice: driver.paymentOption,
                submittedAt: DateUtils.format(new Date(driver.submittedAt)),
                submittedBy: driver.submittedBy ? driver.submittedBy.name : '',
                submittedByCarrier: driver.submittedByCarrier
            });
        } else if (!editing) {
            this.setState({
                content: {
                    driverName: '----',
                    driverPhoneNumber: '----',
                    truckId: '----',
                    trailerId: '----',
                    trailerType: null,
                    paymentOption: '----'
                },
                trailerType: null,
                paymentOptionChoice: null,
                submittedAt: '',
                submittedBy: '',
            });
        } else {
            this.setState({
                content: WinnerDetailsComponent._defaultContent,
                paymentOptionChoice: null,
                trailerType: null
            });
        }
    }

    _getDriverTrailerType(driver) {
        return this._trailerOptions[driver.trailerType] ?? null;
    }

    _toggleEditing() {
        this.setState({
            editing: !this.state.editing
        });
    }

    _onInputValueChange(event) {
        const value = event.target.value;
        const fieldName = event.target.name;
        if (fieldName) {
            this.setState({
                content: {
                    ...this.state.content,
                    [fieldName]: value
                },
                invalidInput: false
            });
        }
    }

    _onTrailerTypeChange(value) {
        const trailerOption = JSON.parse(value);

        this.setState({ 
            content: {
                ...this.state.content,
                trailerType: trailerOption.value
            },
            trailerType: trailerOption,
            invalidInput: false
        });
    }

    _selectedValuesMapper(trailerType) {
        return (
            <div className="select-option">
                <FontAwesomeIcon icon={ trailerType.icon } className="option-icon" />
                { trailerType.label }
            </div>
        );
    }

    _onPaymentOptionChange(value) {
        this.setState({ 
            paymentOptionChoice: value, 
            content: {
                ...this.state.content,
                paymentOption: value
            },
            invalidInput: false
        });
    }

    _handleSendDetails(event) {
        event.preventDefault();

        if (this._isFormValid()) {
            this.setState({ submitDetailsPopupStatus: FORM_STATUSES.LOADING });

            // TODO: Investigate connection restored issue on Firefox
            this.props.onSendDetails(this._getTrimmedFormContent())
                .then(() => this.setState({ submitDetailsPopupStatus: undefined }))
                .catch(error => this.setState({ 
                    submitDetailsPopupStatus: FORM_STATUSES.ERROR,
                    submitDetailsErrorMessage: !error.response || error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data 
                }))
                .finally(() => this.setState({ trailerType: null, editing: this.props.initiallyEditing }));
        }
    }

    _handleCancelEdit(event) {
        event.preventDefault();
        this.setState({ invalidInput: false });

        if (this.props.initiallyEditing && !this.props.driver) {
            // Do not toggle editing when we don't have carrier details
            // and we click on the clear button (we should only empty the form)
            this._setContent();
        } else {
            this._toggleEditing();
        }
    }

    _getTrimmedFormContent() {
        const { driverName, driverPhoneNumber, truckId, trailerId, trailerType, paymentOption } = this.state.content;

        return {
            ...(this.props.driverInfoRequired && {
                driverName: driverName.trim(),
                driverPhoneNumber: driverPhoneNumber.trim(),
                truckId: truckId.trim(),
                trailerId: trailerId.trim(),
                trailerType: LANE_TYPES.DRY === this.props.laneType ? trailerType : null, //if it's refrigerated/flatbed lane, we don't need to save anything as trailer type
            }),
            paymentOption: paymentOption ? paymentOption.trim() : null
        }
    }

    _isFormValid() {
        if (this.props.driverInfoRequired) {
            const { content, content: { trailerType, ...contentWithoutTrailerType } } = this.state;
            const dataForValidation = LANE_TYPES.DRY === this.props.laneType ? { ...content } : contentWithoutTrailerType;
            delete dataForValidation.paymentOption;

            const regex = /\d/g; // Match any digit
            const matches = content.driverPhoneNumber.match(regex);
            const validPhoneNumber = matches && matches.length > 9;
            return ObjectUtils.areAllValuesPresent(dataForValidation) && validPhoneNumber;
        }

        return !StringUtils.isBlank(this.state.content.paymentOption);
    }

    /**
     * Checks if the form has been edited in case carrier details were provided
     */
    _isEdited() {
        if (!this.props.driver) {
            return false; 
        }
        
        const { driver: { driverName, driverPhoneNumber, truckId, trailerId, trailerType, paymentOption }, driverInfoRequired } = this.props;

        const providedDetails = {
            ...(driverInfoRequired && {
                driverName,
                driverPhoneNumber,
                truckId,
                trailerId,
                trailerType,
            }),
            paymentOption
        };

        return !ObjectUtils.equal(providedDetails, this._getTrimmedFormContent());
    }

    async _onAcceptToS(event) {
        try {
            await this.props.acceptToS();
            this.setState({ showCarrierToS: false });
            if (!this._isSendDisabled()) {
                this._handleDriverDetailsSubmit(event, false);
                this.setState({ invalidInput: false });
            }
        } catch (error) {
            console.error('An error occurred while accepting terms of service for a load.', error);
        }  
    }

    _onCloseToSPopup() {
        this.setState({ showCarrierToS: false });
    }

    _handleDriverDetailsSubmit(event, showCarrierToSOnSubmit = this.props.showCarrierToS) {
        event.preventDefault();

        if (this._isFormValid()) {
            this.setState({ invalidInput: false });
        } else {
            this.setState({ invalidInput: true });
            return;
        }

        if (showCarrierToSOnSubmit) {
            this.setState({ showCarrierToS: true });
        } else {
            this._handleSendDetails(event);
        }
    }

    /**
     * Determines whether the send button is disabled based on the canEditAndSend prop and the form status.
     * To send details form must be valid.
     * If carrier details are not provided it checks the canEditAndSend prop.
     * If carrier details are provided it checks the canEditAndSend prop and if anything has changed.
     *
     * @returns {boolean}
     */
    _isSendDisabled() {
        const { driver, canEditAndSend } = this.props;

        return (!this.props.driverInfoRequired && !this._isFormValid()) ||
            (driver && canEditAndSend && !this._isEdited()) || 
            !canEditAndSend;
    }

    _determineEditButtonTextAndIcon() {
        const { driver } = this.props;

        const icon = driver ? edit : add;
        const text = driver ? "Edit Details" : "Add carrier details";

        return { icon, text };
    }

    _formInfoMessage() {
        const { editing } = this.state;
        const { carrierName, showInformMessage, showWarningMessage, canEditAndSend } = this.props;

        const warningMessage = canEditAndSend ? 'The deadline for submission has expired, contact carrier or add details manually.' : 'The deadline for submission has expired, carrier details cannot be submitted. Contact ShipEX for more details.';

        let infoDiv = <></>;
        if (editing && showInformMessage) {
            infoDiv = (
                <Banner 
                    size="medium" 
                    content={ <>We will inform carrier&nbsp;<b className="carrier-name" title={ carrierName }>{ carrierName }</b>&nbsp;about carrier details changes.</> }
                />
            );
        } else if (showWarningMessage) {
            infoDiv = (
                <Banner size="medium" type="warn" content={ warningMessage } />
            );
        }

        return infoDiv;
    }

    _formCardSubheading() {
        if (this.state.submittedBy) {
            return <small className="card-subheading">Submitted by&nbsp;<b className="carrier-name" title={ this.state.submittedBy }>{ this.state.submittedBy }</b>&nbsp;at&nbsp;<b>{ this.state.submittedAt }</b></small>;
        }

        return <></>;
    }

    _formTrailerTypeTooltip() { 
        return (
            <span className="tooltip-icon">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction="top">
                    { this._tooltipText }
                </Tooltip>
            </span>
        );
    }

    _getTrailerTypeContent(trailerType) {
        return trailerType || '----';
    }

    _onDriverInfoRequiredChange() {
        this.setState({ 
            showDriverInfoRequiredChangePopup: true
        });
    }

    async _onDriverInfoRequiredChangePopupAccept() {
        this.setState({ showDriverInfoRequiredChangePopup: false, isDriverInfoRequiredLoading: true });
        await this.props.onDriverInfoRequiredChange(this.props.auctionId);
        this.setState({ isDriverInfoRequiredLoading: false });
    }

    _onDriverInfoRequiredChangePopupCancel() {
        this.setState({ showDriverInfoRequiredChangePopup: false });
    }

    _formDriverInfoRequiredChangePopup() {
        let infoMessage = <></>;

        if (this.props.bidApproved) {
            infoMessage = <Banner size="medium" content={ <small>As a result, dispatch details will be revealed to carrier.</small> } />;
        }

        return (
            <CanAccess
                action={ LOAD_TENDERING_WRITE_PERMISSION }
                yes={
                    <Popup
                        id="driver-info-not-required-popup"
                        show={ this.state.showDriverInfoRequiredChangePopup }
                        onClose={ this._onDriverInfoRequiredChangePopupCancel }
                        trigger={ 
                            <CustomSwitch
                                size="small"
                                label="Required"
                                checked={ this.props.driverInfoRequired }
                                disabled={ this.props.disableDriverInfoRequiredSwitch }
                                onChange={ this._onDriverInfoRequiredChange }
                            />
                        }
                    >
                        <div className="driver-info-not-required-popup">
                            <p className="heading">Driver Details</p>

                            <p className="description">Are you sure you want to mark <b>Driver Details as not required</b>? Once you do that, you won't be able to mark them as required again.</p>
                            { infoMessage }

                            <div className="accept-popup-actions">
                                <div className="buttons-wrapper">
                                    <Button type="tertiary" size="small" onClick={ this._onDriverInfoRequiredChangePopupCancel }>
                                        Cancel
                                    </Button>

                                    <Button size="small" onClick={ this._onDriverInfoRequiredChangePopupAccept }>
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Popup>
                }
            />
        );
    }

    _formDriverInfoRequiredLoadingPopup() {
        return (
            <LoadingPopup 
                show={ this.state.isDriverInfoRequiredLoading } 
                status={ FORM_STATUSES.LOADING }
            >
                <div>
                    <h6>Updating driver info</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </LoadingPopup>
        );
    }

    _formToSPopUp() {
        if (this.props.auction) {
            return (
                <LoadTermsOfServicePopup
                    auction={ this.props.auction }
                    price={ this.props.price }
                    showPopup={ this.state.showCarrierToS }
                    onClosePopup={ this._onCloseToSPopup }
                    onActionPerformed={ this._onAcceptToS }
                />
            );
        }
    }

    _onCloseSubmitDetailsPopup() {
        if (FORM_STATUSES.LOADING !== this.state.submitDetailsPopupStatus) {
            this.setState({ submitDetailsPopupStatus: undefined });
        }
    }

    _formSubmitDetailsPopup() {
        let content;

        switch (this.state.submitDetailsPopupStatus) {
            case FORM_STATUSES.LOADING:
                content = (
                    <FormStatusModal status={ FORM_STATUSES.LOADING }>
                        <div>
                            <h6>Submitting Details</h6>
                            <p>This will only take a moment. Please wait...</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            case FORM_STATUSES.ERROR:
                content = (
                    <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseSubmitDetailsPopup }>
                        <div>
                            <h6>Failed To Submit Details</h6>
                            <p>{ this.state.submitDetailsErrorMessage }</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            default:
                return <></>;
        }

        return (
            <div className="submit-details-popup-container">
                <Popup
                    id="popup-submit-details"
                    show={ true }
                    size="medium"
                    onClose={ this._onCloseSubmitDetailsPopup }
                    trigger={ <></> }
                >
                    <div className="declare-winner-popup">
                        { content }
                    </div>
                </Popup>
            </div>
        );
    }

    _formDriverInfoReadView() {
        const { content } = this.state;
        const { driverName, driverPhoneNumber, truckId, trailerId, trailerType, paymentOption } = content;
        const { driver, laneType, driverInfoRequired, canEditAndSend } = this.props;
        const { icon, text } = this._determineEditButtonTextAndIcon();
        const transportationIcon = trailerType && this._trailerOptions[trailerType].solidIcon;
        
        const requiredIndicator = !driver ? 
            <CanAccess
                action={ LOAD_TENDERING_WRITE_PERMISSION }
                yes={ <></> }
                no={ <span className="required-indicator">*</span> }
            /> : 
            <></>;

        const editButton = (
            <div className="edit-button-wrapper">
                <Button
                    type="tertiary"
                    size="small"
                    disabled={ !canEditAndSend }
                    onClick={ this._toggleEditing }
                    leftIcon={ icon }
                >
                    { text }
                </Button>
            </div>
        );

        return (
            <>
                <div className="info-provided">
                    <div className="section">
                        <div className="carrier-details-subtitle-wrapper">
                            <strong className="carrier-details-subtitle">
                            <b>Driver Details { driverInfoRequired && requiredIndicator }</b>
                            </strong>
                            { this._formDriverInfoRequiredChangePopup() }
                        </div>

                        { !driverInfoRequired ? (
                        <div className="component driver-info-not-required-component">
                            <div className="field">
                                <small className="title">Driver info</small>
                                <p className="content">
                                    No driver details are required for this load.
                                </p>
                            </div>
                        </div>
                        ) : (
                        <div className="component">
                            <div className={ `field name ${ LANE_TYPE_PROPERTIES[laneType].field }` }>
                                <small className="title">Full Name</small>
                                <p className="content" title={ driverName }>
                                    { StringUtils.replaceBlankWithPlaceholder(driverName, '----') }
                                </p>
                            </div>

                            <div className="field phone">
                                <small className="title">Phone</small>
                                <p className="content" title={ driverPhoneNumber }>
                                    { StringUtils.replaceBlankWithPlaceholder(driverPhoneNumber, '----') }
                                </p>
                            </div>

                            <div className="field truck">
                                <small className="title">Truck ID</small>
                                <p className="content" title={ truckId }>
                                    { StringUtils.replaceBlankWithPlaceholder(truckId, '----') }
                                </p>
                            </div>

                            <div className={ `field trailer ${ LANE_TYPE_PROPERTIES[laneType].field }` }>
                                <small className="title">Trailer ID</small>
                                <p className="content" title={ trailerId }>
                                    { StringUtils.replaceBlankWithPlaceholder(trailerId, '----') }
                                </p>
                            </div>

                            { LANE_TYPES.DRY === laneType && (
                            <div className="field trailer-type">
                                <small className="title">
                                    Trailer Type
                                    { this._formTrailerTypeTooltip() }
                                </small>
                                <p className="content">
                                    { this.state.trailerType && <FontAwesomeIcon icon={ transportationIcon } className="transportation-icon" /> }
                                    { this._getTrailerTypeContent(trailerType && this._trailerOptions[trailerType].label) }
                                </p>
                            </div>
                            )}
                        </div>
                        )}
                    </div>

                     <div className="section">
                        <strong className="carrier-details-subtitle">
                            <b>Payment Details</b>
                        </strong>
                        <div className="component">
                            <div className="field">
                                <small className="title">Payment type</small>
                                <p className="content">
                                    { StringUtils.replaceBlankWithPlaceholder(PAYMENT_OPTION_MAPPING[paymentOption], '----') }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                { editButton }
            </>
        );
    }

    _formDriverDetailsInfoEditForm() {
        const { content } = this.state;
        const { laneType, canEditAndSend } = this.props;
        const { driverName, driverPhoneNumber, truckId, trailerId } = content;
        const inputDisabled = !canEditAndSend;

        if (this.props.driverInfoRequired) {
            return (
                <div className={ `input-fields ${ LANE_TYPE_PROPERTIES[laneType].field }` }>
                    <CustomTextInput
                        label="Full Name"
                        placeholder="Enter driver's name..."
                        value={ driverName }
                        fieldName="driverName"
                        disabled={ inputDisabled }
                        onValueChange={ this._onInputValueChange }
                    />

                    <CustomTextInput
                        label="Phone"
                        placeholder="Enter phone..."
                        value={ driverPhoneNumber }
                        fieldName="driverPhoneNumber"
                        disabled={ inputDisabled }
                        onValueChange={ this._onInputValueChange }
                    />

                    <CustomTextInput
                        label="Truck ID"
                        placeholder="Enter truck ID..."
                        value={ truckId }
                        fieldName="truckId"
                        disabled={ inputDisabled }
                        onValueChange={ this._onInputValueChange }
                    />

                    <CustomTextInput
                        label="Trailer ID"
                        placeholder="Enter trailer ID..."
                        value={ trailerId }
                        fieldName="trailerId"
                        disabled={ inputDisabled }
                        onValueChange={ this._onInputValueChange }
                    />

                    { LANE_TYPES.DRY === laneType && (
                        <CustomSelect
                            label="Trailer Type"
                            tooltipText={ this._tooltipText }
                            fieldName="trailerType"
                            placeholder="Select a trailer type..."
                            values={ Object.values(this._trailerOptions) }
                            selectedValue={ this.state.trailerType ? JSON.stringify(this.state.trailerType) : null }
                            onSelect={ this._onTrailerTypeChange }
                            disabled={ inputDisabled }
                            customValueMapper={ this._selectedValuesMapper }
                        />
                    )}
                </div>
            );
        } else {
            return (
                <div className="component driver-info-not-required-form">
                    <p>No driver details are required for this load.</p>
                </div>
            );
        }
    }

    _formDriverInfoEditView() {
        const content = { ...this.state.content };
        const { driver, driverInfoRequired, initiallyEditing, canEditAndSend } = this.props;
        
        const formEdited = driverInfoRequired ? Object.values(content).some((value) => value.trim() !== '') : content.paymentOption.trim() !== '';
        const cancelButtonDisabled = initiallyEditing && !formEdited;
        const cancelButtonText = !driver && initiallyEditing ? 'Clear' : 'Cancel';
        const inputDisabled = !canEditAndSend;
        const sendDisabled = this._isSendDisabled();

        const requiredIndicator = !driver ? 
            <CanAccess
                action={ LOAD_TENDERING_WRITE_PERMISSION }
                yes={ <></> }
                no={ <span className="required-indicator">*</span> }
            /> : 
            <></>;

        const paymentOptions = [
            { 
                value: PAYMENT_OPTIONS.DIRECT_PAYMENT_2_DAY_QUICK_PAY_5_PCT_FEE, 
                label: '2-Day Quick Pay with 5% Fee',
                disabled: inputDisabled
            },
            { 
                value: PAYMENT_OPTIONS.DIRECT_PAYMENT_3_DAY_QUICK_PAY_3_PCT_FEE, 
                label: '3-Day Quick Pay with 3% Fee',
                disabled: inputDisabled
            }
        ];

        let errorMessage = <></>;
        if (this.state.invalidInput && driverInfoRequired) {
            errorMessage = (
                <div className="driver-details-invalid-error-message">
                    All fields must be entered, driver phone number must have 10 digits.
                </div>
            );
        }
                
        return (
            <form className="details-form">
                <div className="carrier-details-subtitle-wrapper">
                    <strong className="carrier-details-subtitle">
                        <b>Driver Details { driverInfoRequired && requiredIndicator }</b>
                    </strong>
                </div>

                { this._formDriverDetailsInfoEditForm() }

                { errorMessage }
                  
                <strong className="carrier-details-subtitle">
                    <b>Payment Details</b>
                </strong>

                <small className="carrier-details-description">Not applicable for carriers that use a factoring company.</small>

                <div className="payment-details">
                    <CustomRadioButton
                        onChange={ this._onPaymentOptionChange }
                        options={ paymentOptions }
                        selectedValue={ this.state.paymentOptionChoice }
                        direction="horizontal"
                    />

                    <div className="input-field button-wrapper">
                        <Button
                            type="tertiary"
                            onClick={ this._handleCancelEdit }
                            disabled={ cancelButtonDisabled }
                        >
                            { cancelButtonText }
                        </Button>
                        <Button
                            onClick={ this._handleDriverDetailsSubmit }
                            disabled={ sendDisabled || this.state.invalidInput }
                            type="primary"
                            submit={ true }
                        >
                            Submit Details
                        </Button>
                    </div>
                </div>
            </form>
        );
    }


    render() {
        const driverInfo = !this.state.editing ? this._formDriverInfoReadView() : this._formDriverInfoEditView();

        return (
            <div className="carrier-details">
                { this._formToSPopUp() }
                { this._formDriverInfoRequiredLoadingPopup() }
                { this._formSubmitDetailsPopup() }
                <ContentCard
                    heading="Carrier Details"
                    subheading={ this._formCardSubheading() }
                    isExpandable={ true }
                    info={ this._formInfoMessage() }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    className="main-card"
                >
                    { driverInfo }
                </ContentCard>
            </div>
        );
    }
}
