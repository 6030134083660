import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as warning, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import Tooltip from 'component/Tooltip';
import TitledCardComponent from 'component/card/TitledCardComponent';
import LaneVisualization from 'component/lane/LaneVisualization';
import CargoTypeComponent from 'component/lane/CargoTypeComponent';
import PriceUtils from 'utils/PriceUtils';
import NumberUtils from 'utils/NumberUtils';

import './LaneHeaderCard.scss';
import './TenderCandidateLaneHeaderCard.scss';

export default class TenderCandidateLaneHeaderCard extends Component {

    static propTypes = {
        routingGuideLane: PropTypes.object.isRequired,
        relevantOffer: PropTypes.object.isRequired
    };

    _formStatusIcon() {
        if (TENDER_CANDIDATE_STATUS.PENDING.value === this.props.relevantOffer.status) {
            return <FontAwesomeIcon icon={ warning } className="status-icon" />;
        }

        return <></>;
    }

    _formStatusTooltip() {
        if (TENDER_CANDIDATE_STATUS.DELETED.value === this.props.relevantOffer.status) {
            return (
                <div className="status-tooltip-container">
                    <FontAwesomeIcon icon={ info } className="tooltip-icon" />
                    <Tooltip direction="bottom">
                        You have been removed from this lane.<br/>
                        Please contact ShipEX for more information.
                    </Tooltip>
                </div>
            );
        }

        return <></>;
    }

    render() {
        const { routingGuideLane, routingGuideLane: { rfpAuction }, relevantOffer } = this.props;

        const status = TENDER_CANDIDATE_STATUS[relevantOffer.status];
        const statusIcon = this._formStatusIcon();
        const statusTooltip = this._formStatusTooltip();

        const pricePerMile = PriceUtils.calculatePerMilePrice(relevantOffer.price.value, routingGuideLane.distance);
        const priceDisplay = NumberUtils.prefixNumber(relevantOffer.price.value, '$', false, 'decimal');
        const pricePerMileDisplay = NumberUtils.prefixNumber(pricePerMile, '$', false, 'decimal');

        return (
            <div className="lane-header-card-container">
                <div className="component lane-header-card">
                    <div className="lane-header-card-title-row">
                        <LaneVisualization lane={ routingGuideLane } size="large" className="neutral" labeled={ true } />
                    </div>
                    <div className="lane-header-card-details-row">
                        <div className="lane-header-card-details-row-group">
                            <CargoTypeComponent type={ routingGuideLane.lane.type } />

                            <TitledCardComponent type="small" title="ROUTING GUIDE" className="lane-header-card-component rg-name">
                                <strong><b title={ rfpAuction.name }>{ rfpAuction.name }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="left" title="STATUS" className={ `lane-header-card-component offer-status ${ status.style }` }>
                                <strong><b>{ status.candidateFacingText }</b></strong>
                                { statusTooltip }
                            </TitledCardComponent>
                        </div>
                        <div className={ `lane-header-card-details-row-group ${ status.style }` }>
                            <TitledCardComponent type="small" align="right" title="PRICE PER MILE" className="lane-header-card-component with-icon">
                                { statusIcon }
                                <strong><b>{ pricePerMileDisplay }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="right" title="TOTAL PRICE" className="lane-header-card-component with-icon">
                                { statusIcon }
                                <strong><b>{ priceDisplay }</b></strong>
                            </TitledCardComponent>

                             <TitledCardComponent type="small" align="right" title="VOLUME" className="lane-header-card-component with-icon">
                                { statusIcon }
                                <strong><b>{ relevantOffer.volume }</b></strong>
                            </TitledCardComponent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
