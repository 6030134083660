import _debounce from 'lodash/debounce';

export default class FunctionUtils { 

    /**
     * Creates a debounced function that delays invoking the function func for wait miliseconds 
     * after the last time the debounced function was invoked. Parameter leading
     * decides if the func will be invoked before the wait or after it. 
     * debounce.cancel can be called to cancel the delayed invocation of func.
     * debouce.flush can be called to immediately invoke the func.
     * 
     * @param {Function} func function to debounce
     * @param {number} wait wait time between two consecutive invocations in miliseconds
     * @param {boolean} leading true if function should be invoked on the leading side of the wait time, false to choose trailing side
     * @returns the new debounced function
     */
    static debounce(func, wait, leading = true) {
        const trailing = !leading;
        return _debounce(func, wait, { leading, trailing });
    }
}
