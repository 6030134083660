import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding help center
 */
export default class HelpCenterApiService {

  /**
   * Gets all of the manuals for help center
   * 
   * @returns {Promise}
   */
  static getUserManuals() {
    return RestService.instance().get('help-center/manuals');
  }
  
  /**
   * Gets URL for manual download
   * 
   * @param {string} fileId id of manual to be downloaded
   * @returns {string}
   */
  static getDownloadPDFManualURL(fileId) {
    return `help-center/manual/${ fileId }`;
  }
}
