import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { faFilter as filterIcon } from '@fortawesome/pro-solid-svg-icons';

import TagList from 'component/filter/TagList';
import Button from 'component/Button';
import ObjectUtils from 'utils/ObjectUtils';

import './CollapsableFilter.scss';

export default class CollapsableFilter extends Component {

    static propTypes = {
        triggerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        filter: PropTypes.object,
        onFilterChange: PropTypes.func,
        collapsable: PropTypes.bool
    };

    static defaultProps = {
        triggerRef: { current: null },
        filter: {},
        onFilterChange: () => { /* */ },
        collapsable: true
    };

    constructor(props) {
        super(props);
        this._onToggleCollapsed = this._onToggleCollapsed.bind(this); 
    }

    state = {
        collapsed: true
    };

    _onToggleCollapsed() {
        this.setState(prevState => ({ collapsed: !prevState.collapsed }));
    }

    render() {
        let trigger, content;

        if (this.props.collapsable && this.state.collapsed) {
            content = <TagList filter={ this.props.filter } onFilterChange={ this.props.onFilterChange } />;
        } else {
            content = this.props.children;
        }

        const filterCount = Object.keys(ObjectUtils.removeEmptyKeys(this.props.filter)).length;
        let filterCountIcon = filterCount ? <div className="filter-count-icon"><sup>{ filterCount }</sup></div> : <></>;

        const triggerButton = (
            <Button
                type="tertiary"
                size="small"
                leftIcon={ !filterCount ? filterIcon : null}
                onClick={ this._onToggleCollapsed }
            >
                { filterCountIcon }Filter
            </Button>
        );

        if (this.props.triggerRef.current) {
            trigger = ReactDOM.createPortal(triggerButton, this.props.triggerRef.current);
        } else {
            trigger = triggerButton;
        }

        return (
            <div className="collapsable-filter-container">
                { trigger }
                { content }
            </div>
        );
    }
}
