import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { faRoad as inactiveLaneIcon, faUserAlt as inactiveUserIcon } from '@fortawesome/pro-light-svg-icons';
import { faRoad as activeLaneIcon, faUserAlt as activeUserIcon  } from '@fortawesome/pro-solid-svg-icons';

import { FINISHED_RFP_AUCTION_STATUSES, RG_DETAILS_VIEW_MODE, ONGOING_RFP_AUCTION_STATUSES } from 'common/constants';
import CanAccess from 'component/CanAccess';
import WSComponent from 'component/WSComponent';
import RFPHeaderCard from 'component/card/rfp/RFPHeaderCard';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Tab from 'component/navigation/Tab';
import RFPRoundOverviewCard from 'component/card/rfp/RFPRoundOverviewCard';
import RFPLaneList from 'page/RFPLaneList';
import RFPCarrierList from 'page/RFPCarrierList';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';

import './RFPDetails.scss';

class RFPDetails extends WSComponent {

    constructor(props) {
        super(props);

        this._fetchRFP = this._fetchRFP.bind(this);
        this._onUpdated = this._onUpdated.bind(this);
        this._onViewRFP = this._onViewRFP.bind(this);
        this._onExitViewOnlyMode = this._onExitViewOnlyMode.bind(this);
    }

    state = {
        loading: true,
        rfp: undefined,
        viewMode: undefined,
        version: 0
    };

    static propTypes = {
        match: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
    };

    async componentDidMount() {
        super.componentDidMount();
        const viewMode = await this._getViewMode();
        this._fetchRFP(viewMode);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const viewMode = await this._getViewMode();
            this._fetchRFP(viewMode);
        }
    }

    async _getViewMode() {
        const accessDetails = await RFPAuctionApiService.getAccessDetails(this.props.match.params.id);
        const canSeeRFP = accessDetails.canReadRFP;
        const canSeeRG = accessDetails.canReadRG;

        const mode = (new URLSearchParams(this.props.location.search)).get('mode');

        let viewMode;

        if (canSeeRFP && canSeeRG) {
            viewMode = 'read_only' === mode ? RG_DETAILS_VIEW_MODE.RFP_READ_ONLY : RG_DETAILS_VIEW_MODE.RFP_AND_ROUTING_GUIDE;
        } else if (canSeeRFP) {
            viewMode = RG_DETAILS_VIEW_MODE.RFP_ONLY;
        } else if (canSeeRG) {
            viewMode = RG_DETAILS_VIEW_MODE.ROUTING_GUIDE_ONLY;
        } else {
            this.props.history.replace('/404');
        }

        return viewMode;
    }

    _fetchRFP(viewMode) {
        RFPAuctionApiService.get(this.props.match.params.id)
            .then(rfp => {
                this.setState(prevState => ({
                    rfp,
                    viewMode,
                    loading: false,
                    version: prevState.version + 1
                }));

                if (RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === viewMode && (!FINISHED_RFP_AUCTION_STATUSES.includes(rfp.status) || rfp.synthetic)) {
                    this._onExitViewOnlyMode();
                }
            })
            .catch(() => this.props.history.replace('/404'));
    }

    _onUpdated() {
        this._fetchRFP(this.state.viewMode);
    }

    _onViewRFP() {
        const params = new URLSearchParams(this.props.location.search);
        params.append('mode', 'read_only');

        this.setState({ viewMode: RG_DETAILS_VIEW_MODE.RFP_READ_ONLY }, () => {
            this.props.history.push({
                pathname: window.location.pathname,
                search: `?${ params.toString() }`
            });
        });
    }

    _onExitViewOnlyMode() {
        const params = new URLSearchParams(this.props.location.search);
        params.delete('mode');

        this.setState({ viewMode: RG_DETAILS_VIEW_MODE.RFP_AND_ROUTING_GUIDE }, () => {
            this.props.history.push({
                pathname: window.location.pathname,
                search: params.toString()
            });
        });
    }

    render() {
        if (this.state.loading || !this.state.rfp) {
            return null; // TODO: Render loading view.
        }

        return (
            <div className="page rfp-details-page">
                <Breadcrumbs crumbs={ [
                    { path: '/rfp/list', name: 'Routing Guides', backCrumb: false },
                    { path: '', name: this.state.rfp.name, backCrumb: false },
                    { path: '/rfp/list', name: 'Back to Routing Guides', backCrumb: true }
                ] } />

                <RFPHeaderCard rfp={ this.state.rfp }
                               account={ this.props.account }
                               viewMode={ this.state.viewMode }
                               onRFPUpdated={ this._onUpdated }
                               onViewRFP={ this._onViewRFP }
                               onExitViewOnlyMode={ this._onExitViewOnlyMode }
                               version={ this.state.version } />
                { ONGOING_RFP_AUCTION_STATUSES.includes(this.state.rfp.status) && 
                    <RFPRoundOverviewCard rfp={ this.state.rfp } account={ this.props.account } />
                }
                <CanAccess action={ ['rfp:read:all', 'routing-guide:read'] }
                           yes={
                                <NavigableTabs url={ this.props.match.url }>
                                    <Tab default id="lanes" title="Lanes" activeIcon={ activeLaneIcon } inactiveIcon={ inactiveLaneIcon }>
                                        <RFPLaneList
                                            rfp={ this.state.rfp }
                                            viewMode={ this.state.viewMode }
                                            onUpdated={ this._onUpdated }
                                            version={ this.state.version }
                                        />
                                    </Tab>

                                    <Tab id="carriers" title="Carriers" activeIcon={ activeUserIcon } inactiveIcon={ inactiveUserIcon }>
                                        <RFPCarrierList
                                            rfp={ this.state.rfp }
                                            viewMode={ this.state.viewMode }
                                            onUpdated={ this._onUpdated }
                                        />
                                    </Tab>
                                </NavigableTabs>
                           }
                           no={ 
                                <RFPLaneList 
                                    rfp={ this.state.rfp }
                                    viewMode={ this.state.viewMode }
                                    onUpdated={ this._onUpdated }
                                    version={ this.state.version }
                                />
                           } />
            </div>
        );
    }
}

export default withRouter(RFPDetails);
