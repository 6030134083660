import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import CarrierStatusIcon  from 'component/carrier/CarrierStatusIcon';
import TableRow from 'component/table/custom/TableRow';
import TableCell from 'component/table/custom/TableCell';
import StringUtils from 'utils/StringUtils';

import './SpotMarketParticipantsTableRow.scss';

export default class SpotMarketParticipantsTableRow extends Component {
    static propTypes = {
        candidate: PropTypes.object.isRequired,
        lastElement: PropTypes.bool
    };

    static defaultProps = {
        lastElement: false
    }

    render() {
        const { candidate: { carrier }, lastElement } = this.props;

        return (
            <TableRow className="sm-participants-content-row" fontSize="small">
                <TableCell className={ `name ${ lastElement ? 'last-element' : '' }` } padding="small">
                    <div className="name-content">
                        <CarrierStatusIcon carrier={ carrier } className="carrier-warning" />
                        <Link to={ `/carrier/${ carrier.id }` } className="name-link">
                            <div className="name-ellipsis" title={ carrier.name }>
                                { carrier.name } ({ carrier.businessId })
                            </div>
                        </Link>
                    </div>
                </TableCell>
                <TableCell className="origin" padding="small">
                    { this.props.candidate.external ? 'TMW Sync' : 'Added Manually' }
                </TableCell>
                <TableCell className="email" padding="small">
                    <div className="email-ellipsis" title={ carrier.email }>
                        { carrier.email }
                    </div>  
                </TableCell>
                <TableCell className={ `phone ${ lastElement ? 'last-element' : '' }` } padding="small" alignment="right">
                    { StringUtils.replaceBlankWithPlaceholder(carrier.profileInformation?.phone, '-') }
                </TableCell>
            </TableRow>
        );
    }
}
