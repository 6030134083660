import { createRef, Fragment } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
     faInfoCircle as infoIcon,
     faCheckCircle as checkIcon,
     faExclamationCircle as importantIcon,
     faLongArrowRight as arrowIcon,
     faEye as seenIcon,
     faTimesCircle as closeIcon
 } from '@fortawesome/pro-solid-svg-icons';

import AuthenticationService from 'service/AuthenticationService';
import Notification from 'model/Notification';
import Separator from 'component/Separator';
import SafeStateComponent from 'component/SafeStateComponent';
import AsyncUtils from 'utils/AsyncUtils';
import DateUtils from 'utils/DateUtils';

import './NotificationItem.scss';

const notificationSeverity = {
    INFO: 'INFO',
    ALERT: 'ALERT',
    SUCCESS: 'SUCCESS'
}

const notificationType = {
    LOAD: 'LOAD',
    DOCUMENT: 'DOCUMENT'
}

export default withRouter(class NotificationItem extends SafeStateComponent {
    static _closeDragPixels = 100;

    static propTypes = {
        id: PropTypes.any.isRequired,
        onClose: PropTypes.func,
        onSeen: PropTypes.func,
        notification: PropTypes.instanceOf(Notification),
        timeoutSeconds: PropTypes.number,
        embedded: PropTypes.bool,
        empty: PropTypes.bool
    }

    static defaultProps = {
        onClose: () => {/* */},
        onSeen: () => {/* */},
        notification: null,
        timeoutSeconds: null,
        embedded: false,
        empty: false
    }

    /**
     *
     * @type {React.RefObject<unknown> | {current: null} | {current: null}}
     * @private
     */
    _selfRef = null;

    state = {
        visible: false
    }

    constructor(props) {
        super(props);
        this._selfRef = createRef();
    }

    static notificationSeverityClass(severity) {
        switch (severity) {
            case notificationSeverity.ALERT:
                return  [ importantIcon, " alert " ]
            case notificationSeverity.SUCCESS:
                return [ checkIcon, " success " ]
            default:
                return [ infoIcon, " info " ]
        }
    }

    /**
     *
     * @type {Timeout | {current: null} | {current: null}}
     * @private
     */
     _timer = null;


    componentDidMount() {
        // Ensures that state will not be collapsed, and the animation will be played after rendering.
        super.componentDidMount();
        AsyncUtils.timerPromise(10)
            .then(() => this.setState({ visible: true }));

        const element = this._selfRef.current;

        if (this.props.empty) {
            return;
        }

        // If timeoutSeconds props is sent, notification item will disappear after X seconds
        if (this.props.timeoutSeconds) {
            this._timer = setTimeout(() => this.setState({ visible: false }, () => AsyncUtils.timerPromise(500).then(() => this.props.onNotificationTimeout(this.props.id))), this.props.timeoutSeconds * 1000);
        }

        if (!this.props.embedded) {
            element.onmousedown = (downEvent) => {
                const initialX = downEvent.clientX;
                let currentX = 0;

                element.style.cursor = 'grabbing';

                document.onmousemove = (dragEvent) => {
                    dragEvent.preventDefault();

                    currentX = Math.max(0, (dragEvent.clientX - initialX));
                    element.style.left = currentX + 'px';

                    if (currentX >= NotificationItem._closeDragPixels) {
                        element.style.opacity = '0.5';
                    } else {
                        element.style.opacity = null;
                    }
                }

                document.onmouseup = () => {
                    document.onmousemove = null;
                    document.onmouseup = null;

                    element.style.cursor = null;

                    if (currentX < NotificationItem._closeDragPixels) {
                        element.style.left = 0;
                    } else {
                        this.setState(
                            { visible: false },
                            () => AsyncUtils.timerPromise(500).then(() => this.props.onClose(this.props.id))
                        );
                    }
                }
            };
        }
    }

    componentWillUnmount() {
        const element = this._selfRef.current;
        if (!this.props.empty) {
            element.onmousedown = null;
            document.onmousemove = null;
            document.onmouseup = null;
            if (this._timer) {
              clearTimeout(this._timer);
            }
        }
        super.componentWillUnmount();
    }

    async _onNotificationClick() {
        const account = await AuthenticationService.instance().getAccount();
        const notification = this.props.notification;
        let pathname;

        if (notificationType.LOAD === notification.type) {
            pathname = `/load/${ notification.load.businessId }/auction/${ notification.auctionId }`;
        } else if (notificationType.DOCUMENT === notification.type) {
            pathname = `/carrier/${ account.carrierId }/documents`;
        }

        if (this.props.timeoutSeconds) {
            this._timer = setTimeout(() => this.setState({ visible: false }, () => this.props.onNotificationTimeout(this.props.id)), 0);
        }

        if (!notification.seen) {
            this.props.onSeen(this.props.id);
        }

        this.props.onClose();
        this.props.history.push({
            pathname
        });
    }

    _onNotificationHover(isMouseOver, isNotificationSeen) {
        if (isMouseOver && isNotificationSeen) {
            this.setState({
                hovered: true
            });
        } else if (isMouseOver) {
            clearTimeout(this._timer);
        }
        else {
            this.setState({
                hovered: false
            });
            if (this.props.timeoutSeconds) {
                this._timer = setTimeout(() => !this.state.hovered ? this.setState({ visible: false }, () => this.props.onNotificationTimeout(this.props.id)) : {}, this.props.timeoutSeconds * 1000);
            }
        }
    }

    _closeIcon(embedded, seen) {
        if (!embedded) {
            return (
                <FontAwesomeIcon
                    icon={ closeIcon }
                    className="notification-action close"
                    onClick={ () => this.setState(
                        { visible: false },
                        () => AsyncUtils.timerPromise(500).then(() => this.props.onClose(this.props.id))
                    ) }
                />
            );
        }

        if (!seen) {
            return (
                <FontAwesomeIcon
                    icon={ seenIcon }
                    className="notification-action seen"
                    onClick={ () => this.setState(
                        { visible: false },
                        () => AsyncUtils.timerPromise(500).then(() => this.props.onSeen(this.props.id))
                    ) }
            />);
        }
        return <></>;
    }

    render() {
        const notification = this.props.notification;

        if (!notification) {
            return <></>;
        }

        let [ notificationSeverityIcon, notificationSeverityClass ] = NotificationItem.notificationSeverityClass(notification.severity);

        notificationSeverityClass = ((notification && !notification.seen) || !!this.state.hovered ) ? notificationSeverityClass : " seen";
        const embeddedClass = (this.props.embedded ? ' embedded ' : ' notification-component ')
        const visibleClass = (this.state.visible ? ' visible ' : '');
        const className =  ' notification-item ' + embeddedClass + visibleClass + notificationSeverityClass;
        const contentClassName = "notification-item-content " + embeddedClass;


        let time = <></>;
        let separator = <></>;
        if (this.props.embedded) {
            time = (
                <sup className={ "time inline " + notificationSeverityClass }>
                    { DateUtils.difference(new Date(), new Date(notification.time)) } ago
                </sup>
            )
        } else {
            separator = <Separator />;
        }

        let orderDetails = <></>;
        if (notification.load) {
            orderDetails = (
                <>
                    { separator }
                    <sup className={"notification-info "}>
                        <span className="title"> Order </span>
                        <span className="text"> #{ notification.load.businessId } </span>
                        <span className="title"> Lane </span>
                        <span className="text lane-left">{ notification.load.routingGuideLane.lane.origin.city }, { notification.load.routingGuideLane.lane.origin.state }</span>
                        <span className={ notificationSeverityClass }>
                            <FontAwesomeIcon icon={arrowIcon}></FontAwesomeIcon>
                        </span>
                        <span className="text lane-right"> { notification.load.routingGuideLane.lane.destination.city }, { notification.load.routingGuideLane.lane.destination.state }  </span>
                        <span className="inline">
                            <span className="title"> Pickup </span>
                            <span className="text"> { DateUtils.formatLocal(new Date(notification.load.pickupTime), notification.load.pickupTimeOffset) } </span>
                        </span>
                    </sup>
                </>
            );
        }


        return (
            <div ref={ this._selfRef } className={ className } onMouseEnter={() => this._onNotificationHover(true, notification.seen)} onMouseLeave={() => this._onNotificationHover(false, notification.seen)}>
                <div className="notification-item-header"  onClick={ this._onNotificationClick.bind(this) }>
                    <small className={ "notification-type-icon " + notificationSeverityClass  }>
                        { <FontAwesomeIcon icon={ notificationSeverityIcon } className="notification-type-icon" /> }
                    </small>
                </div>
                <div className={ contentClassName }  onClick={ this._onNotificationClick.bind(this) }>
                    <small className={ "message " + notificationSeverityClass }>
                        <Markdown 
                            options={ {
                                overrides: {
                                    strong: {
                                        component:  ({ children, ...props }) => (
                                            <b { ...props }>{ children }</b>
                                        ),
                                    },
                                },
                                wrapper: Fragment 
                            } }
                            >
                        { notification.message }
                        </Markdown>
                    </small>
                    { time }
                    { orderDetails }
                </div>
                <small className="notification-actions">
                    { this._closeIcon(this.props.embedded, notification.seen) }
                </small>
            </div>
        );
    }
});
