import RestService from 'service/RestService';
import { RG_DETAILS_VIEW_MODE, FINISHED_RFP_AUCTION_STATUSES } from 'common/constants';

export default class RoutingGuideUtils {

    /**
     * Fetches names of applied and completed routing guides with search params.
     * If a carrier is making this request, it will not list names of synthetic RFPs.
     * 
     * @param {Object} params search parameter
     * @returns list of routing guide names
     */
    static fetchNames(params) {
        return RestService.instance().get('routing-guide/names', params);
    }

    static isRFPView(viewMode, rfpStatus) {
        return RG_DETAILS_VIEW_MODE.RFP_ONLY === viewMode || RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === viewMode
             || (RG_DETAILS_VIEW_MODE.ROUTING_GUIDE_ONLY !== viewMode && !FINISHED_RFP_AUCTION_STATUSES.includes(rfpStatus));
    }

    static fetchRoutingGuideOrigins(rfp, params) {
        return RestService.instance().get(`routing-guide/${ rfp.id }/lane/region/origin`, params);
    }

    static fetchRoutingGuideDestinations(rfp, params) {
        return RestService.instance().get(`routing-guide/${ rfp.id }/lane/region/destination`, params);
    }
}
