import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle as warningIcon,
    faSignOut as signOut,
    faUser as userIcon
} from '@fortawesome/pro-solid-svg-icons';

import { USER_ROLES_MAPPING, USER_STATUS } from 'common/constants';
import Img from 'component/Img';
import AuthenticationService from 'service/AuthenticationService';
import RestService from 'service/RestService';
import SafeStateComponent from 'component/SafeStateComponent';
import Tooltip from 'component/Tooltip';
import Dropdown from 'component/Dropdown';
import DocumentUtils from 'utils/DocumentUtils';
import StringUtils from 'utils/StringUtils';
import UserApiService from 'service/api/UserApiService';

import './AccountInfo.scss';

export default withRouter(class AccountInfo extends SafeStateComponent {
    state = {
        account: null,
        initials: '?',
        avatarSrc: undefined,
        showAccountDropdown: false,
        documentTypes: null
    }

    async componentDidMount() {
        super.componentDidMount();
        const authenticationService = AuthenticationService.instance()

        if (authenticationService) {
            const account = await authenticationService.getAccount();

            if (account) {
                RestService.instance().get(`user/external/${ encodeURIComponent(account.id) }/user`).then(user => {
                    this.setState({
                        account: account,
                        user: user,
                        initials: this._userInitials(account.name),
                        avatarSrc: `/static/managed/avatar/${encodeURIComponent(account.id)}`
                    });

                    if (USER_STATUS.PENDING.field === user.status) {
                        UserApiService.activate(user.id);
                    }
                });
            }

            const documentTypes = await DocumentUtils.fetchDocumentTypes(true);
            this.setState({ documentTypes });
        }
    }

    _userInitials(name) {
        return StringUtils.firstTwoInitials(name);
    }

    _logout() {
        AuthenticationService.instance().logout();
    }

    _onProfileClick() {
        if (this.state.user) {
            if (this.state.user.roles && this.state.user.roles.find(role => role.name === 'CARRIER')) {
                const pathname = `/user/${ this.state.user.id }`;
                this.props.history.push({
                    pathname
                });
            }
        }
    }

    render() {
        let warning = (<></>);

        if (this.state.user?.roles.find(role => role.name === 'CARRIER') && this.state.user?.carrier && DocumentUtils.hasInvalidDocument(this.state.user?.carrier.id)) {
            warning = (
                <div className="account-warning-icon">
                    <FontAwesomeIcon icon={ warningIcon } />
                    <Tooltip direction="bottom">You have missing and/or expired documents.</Tooltip>
                </div>
            )
        }

        const accountAvatar = (
            <Img
                src={ this.state.avatarSrc }
                className="account-info-picture avatar"
                fallback={(
                    <div className="account-info-picture">
                        <div className="initials" title={ this.state.initials }>
                            { this.state.initials }
                        </div>
                    </div>
                )}
            />
        );

        let accountDropdownActions = [];
        const { account } = this.state;
        if (account && account.roles.indexOf(USER_ROLES_MAPPING.CARRIER) !== -1) {
            accountDropdownActions.push(
                <div className="dropdown-item" onClick={ this._onProfileClick.bind(this) }>
                    <FontAwesomeIcon icon={ userIcon } />
                    <p className="text">Profile</p>
                </div>
            );
        }

        accountDropdownActions.push(
            <div className="dropdown-item" onClick={ this._logout.bind(this) }>
                <FontAwesomeIcon icon={ signOut } />
                <p className="text">Sign Out</p>
            </div>
        );

        const background = (
            <Dropdown
                id="account-dropdown"
                direction="bottom-left"
                show={ this.state.showAccountDropdown }
                onClose={ () => this.setState({ showAccountDropdown: false }) }
                trigger={(
                    <a href="#account-dropdown" onClick={ () => this.setState({ showAccountDropdown: true }) } >
                        { accountAvatar }
                    </a>
                )}
                actions={ accountDropdownActions }/>
        );

        return (
            <div className="header-account-info">
                { background }
                { warning }
            </div>
        );
    }
});
