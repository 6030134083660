import RestService from "service/RestService";

export default class LaneUtils {

    /**
     * Transforms the Region object into a string formatted as City, State.
     *
     * @param {Object} region
     * @returns {string}
     */
    static formatRegion(region) {
        if (region) {
            return region.city + ', ' + region.state;
        } else {
            return 'Unknown';
        }
    }

    static fetchOrigins(params) {
        return RestService.instance().get(`regions/origins`, params);
    }

    static fetchDestinations(params) {
        return RestService.instance().get(`regions/destinations`, params);
    }
}
