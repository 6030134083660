import RestService from 'service/RestService';

/**
  * Service class that provides static methods and endpoints for API calls regarding routing guide notifications
  */
export default class RoutingGuideNotificationApiService {
    static SUBSCRIPTIONS_CSV_TEMPLATE_ENDPOINT = 'routing-guide/subscriptions/csv/template';

    /**
     * Fetches notification subscriptions on the routing guide with the provided id
     * 
     * @param {string} id if of the routing guide
     * @param {Object} params filtering params including type, search, page number and page size
     * @returns {Promise}
     */
    static filter(id, params) {
        return RestService.instance().get(`routing-guide/${ id }/subscriptions`, params);
    }

    /**
     * Adds a notification subscription with the type RFP Manager for every user with one of emails 
     * provided in the CSV file to the routing guide with the provided id
     * 
     * @param {string} id if of the routing guide
     * @param {Object} formData form data containing a csv file with a list of user emails
     * @param {string} delimiter delimiter used in the csv file
     * @returns {Promise}
     */
    static addRFPManagersViaCsv(id, formData, delimiter) {
        return RestService.instance().post(`routing-guide/${ id }/subscriptions/rfp-managers/csv`, formData, null, { delimiter });
    }

    /**
     * Adds notification subscription with the provided type for every user with one of the provided emails 
     * to the routing guide with the provided id
     * 
     * @param {string} id id of the routing guide
     * @param {array} subscriptions a list of user emails
     * @param {string} type type of subscription
     * @returns {Promise}
     */
    static addDirectly(id, subscriptions, type) {
        return RestService.instance().post(`routing-guide/${ id }/subscriptions`, { subscriptions, type });
    }

    /**
     * Adds notification subscription with the type Lane Specific Logistics Coordinator with specified broker, origin, destination and lane type
     * 
     * @param {string} id id of the routing guide
     * @param {Object} params information consisting of broker, origin, destination and lane type
     * @returns {Promise}
     */
    static addLaneSpecificLogisticsCoordinator(id, params) {
        return RestService.instance().post(`routing-guide/${ id }/subscriptions/lane-specific-logistics-coordinators`, params);
    }

    /**
     * Adds a notification subscription with the type Default Logistics Coordinator for every user with one of emails 
     * provided in the CSV file to the routing guide with the provided id
     * 
     * @param {string} id if of the routing guide
     * @param {Object} formData form data containing a csv file with a list of user emails
     * @param {string} delimiter delimiter used in the csv file
     * @returns {Promise}
     */
    static addDefaultLogisticsCoordinatorsViaCsv(id, formData, delimiter) {
        return RestService.instance().post(`routing-guide/${ id }/subscriptions/default-logistics-coordinators/csv`, formData, null, { delimiter });
    }

    /**
     * Checks if the logged in user is subscribed to the routing guide with the provided id
     * 
     * @param {string} id id of the routing guide
     * @returns {Promise}
     */
    static isSubscribed(id) {
        return RestService.instance().get(`routing-guide/${ id }/subscriptions/subscribed`);
    }

    /**
     * Removes a notification subscription from the routing guide
     * 
     * @param {string} id id of the routing guide
     * @param {string} subscriptionId id of the subscription
     * @returns {Promise}
     */
    static removeSubscription(id, subscriptionId) {
        return RestService.instance().delete(`routing-guide/${ id }/subscriptions/${ subscriptionId }`);
    }

    /**
     * Retrieves general contact information
     * 
     * @returns general contact information
     */
    static getGeneralContactInformation() {
        return RestService.instance().get('routing-guide/subscriptions/general-contact-information');
    }
}
