import { Component } from 'react'
import PropTypes from 'prop-types';

/**
 * An image. Can be used as a drop-in replacement for `<img>`.
 * Adds some performance optimizations to image loading to prevent stutter,
 * as well as to enable loaders and fallback elements in case the image failed to load.
 */
export default class Img extends Component {

    static propTypes = {
        src: PropTypes.string,
        alt: PropTypes.string,
        className: PropTypes.string,
        loader: PropTypes.element,
        fallback: PropTypes.element
    }

    static defaultProps = {
        fallback: (<></>)
    }

    state = {
        image: undefined,
        loading: false
    }

    componentDidMount() {
        this._updateImage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this._updateImage();
        }
    }

    _updateImage() {
        const { src } = this.props;

        if (src) {
            this.setState({ loading: true });

            const imagePromise = new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => image.decode().then(resolve).catch(reject);
                image.onerror = reject;
                image.src = src;
            });

            imagePromise
                .then(() => this.setState({ image: src, loading: false }))
                .catch(() => this.setState({ image: undefined, loading: false }));
        }
    }

    render() {
        if (this.state.loading && this.props.loader) {
            return this.props.loader;
        }

        if (this.state.image) {
            return (<img src={ this.state.image } className={ this.props.className } alt={ this.props.alt } />);
        } else {
            return this.props.fallback;
        }
    }
}
