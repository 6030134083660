import { Component } from 'react';
import PropTypes from 'prop-types';

import { BID_STATUSES, CANDIDATE_TYPE, FORM_STATUSES } from 'common/constants';
import FormStatusModal from 'component/form/FormStatusModal';
import Popup from 'component/Popup';
import Button from 'component/Button';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';

import './DeclareWinnerPopup.scss';

export default class DeclareWinnerPopup extends Component {
    static propTypes = {
        auctionId: PropTypes.string,
        bid: PropTypes.object,
        candidate: PropTypes.object,
        onDeclareWinner: PropTypes.func,
        onCancelWinnerDeclaration: PropTypes.func
    }

    static defaultProps = {
        auctionId: null,
        bid: null,
        candidate: null,
        onDeclareWinner: () => { /* */ },
        onCancelWinnerDeclaration: () => { /* */ }
    }

    constructor (props) {
        super(props);

        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onDeclare = this._onDeclare.bind(this);
    }

    state = {
        showPopup: false,
        popupStatus: undefined,
        errorMessage: undefined
    }

    _onOpen(event) {
        event.stopPropagation(); 
        this.setState({ showPopup: true, popupStatus: undefined });
    }

    _onClose() {
        if (FORM_STATUSES.LOADING !== this.state.popupStatus) {
            this.setState({ showPopup: false }); 
            this.props.onCancelWinnerDeclaration();
        }
    }

    _onDeclare() {
        this.setState({ popupStatus: FORM_STATUSES.LOADING });

        TenderAuctionBrokerApiService.declareWinner(this.props.auctionId, { candidateId: this.props.candidate.id, bidId: this.props.bid?.id })
            .then(() => this.setState({ popupStatus: FORM_STATUSES.SUCCESS }, this._onClose))
            .then(this.props.onDeclareWinner)
            .catch(error => this.setState({ 
                popupStatus: FORM_STATUSES.ERROR,
                errorMessage: error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data 
            }));
    }

    render() {
        let popupSize = 'small';
        const { bid, candidate } = this.props;
        const { popupStatus } = this.state;
        const currentOnDemandOffer = bid && bid.current && CANDIDATE_TYPE.ON_DEMAND === candidate.type && BID_STATUSES.PENDING === bid.status;
        let content, title, descriptionContent, triggerLabel;
        if (currentOnDemandOffer) {
            triggerLabel = 'Accept Offer for Carrier';
            title = FORM_STATUSES.LOADING === popupStatus ? 'Accepting Offer' : 'Accept Offer for Carrier';
            descriptionContent = (
                <>
                    Are you sure you want to accept this offer for carrier
                    <span className="carrier-name"> { candidate.carrier.name } </span>?
                </>
            );
        } else {
            triggerLabel = 'Declare as a Winner';
            title = FORM_STATUSES.LOADING === popupStatus ? 'Declaring a Winner' : 'Declare as a Winner';
            descriptionContent = (
                <>
                    Are you sure you want to declare
                    <span className="carrier-name"> { candidate.carrier.name } </span>
                    as a winner for this auction?
                </>
            );
        }

        switch (popupStatus) {
            case FORM_STATUSES.LOADING:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.LOADING }>
                        <div>
                            <h6>{ title }</h6>
                            <p>This will only take a moment. Please wait...</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            case FORM_STATUSES.ERROR:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClose }>
                        <div>
                            <h6>Winner Declaration Failure</h6>
                            <p>{ this.state.errorMessage }</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            default:
                content = (
                    <>
                        <p className="heading" id="declare-winner-popup-heading">{ title }</p>
                        <p className="description">
                           { descriptionContent }
                        </p>
                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._onClose }>
                                Cancel
                            </Button>

                            <Button size="small" onClick={ this._onDeclare }>
                                Confirm
                            </Button>
                        </div>
                    </>
                );
        }

        return (
            <div className="declare-winner-popup-container">
                <Popup
                    id="popup-declare-winner"
                    size={ popupSize }
                    show={ this.state.showPopup }
                    onClose={ this._onClose }
                    trigger={
                        <a href="#popup-declare-winner" onClick={ this._onOpen } className="dropdown-item">
                            <small className="action-name">{ triggerLabel }</small>
                        </a>
                    }
                >
                    <div className="declare-winner-popup">
                        { content }
                    </div>
                </Popup>
            </div>
        );
    }
}
