import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasksAlt as activityIcon } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar as calendarIcon } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, ACTIVITY_TYPES, ACTIVITY_TYPE_OPTIONS } from 'common/constants';
import Search from 'component/filter/Search';
import Select from 'component/form/CustomSelect';
import CascadeSelect from 'component/form/CustomCascadeSelect';
import MultipleSelect from 'component/form/CustomMultipleSelect';
import ActivityEmptyState from 'component/activity/ActivityEmptyState';
import ActivityLogInfiniteScroll from 'component/activity/ActivityLogInfiniteScroll';
import CarrierActivityLogApiService from 'service/api/CarrierActivityLogApiService';
import CarrierApiService from 'service/api/CarrierApiService';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';
import LaneApiService from 'service/api/LaneApiService';
import LaneUtils from 'utils/LaneUtils';

import './CarrierActivityLog.scss';

const TIMESTAMP = [
    { title: 'Last 7 days', field: moment().subtract(7, 'days').startOf('day'), keyWord: '7_DAYS' },
    { title: 'Last 15 days', field: moment().subtract(15, 'days').startOf('day'), keyWord: '15_DAYS' },
    { title: 'Last 30 days', field: moment().subtract(30, 'days').startOf('day'), keyWord: null },
    { title: 'Last 3 months', field: moment().subtract(3, 'months').startOf('day'), keyWord: '3_MONTHS' }
];

class CarrierActivityLog extends Component {

    static propTypes = {
        carrier: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this._incrementPageNumber = this._incrementPageNumber.bind(this);

        this._onSelectMember = this._onSelectMember.bind(this);
        this._onSearchMember = this._onSearchMember.bind(this);
        this._onUnselectMember = this._onUnselectMember.bind(this);
        this._onClearMember = this._onClearMember.bind(this);

        this._onSelectRG = this._onSelectRG.bind(this);
        this._onSearchRG = this._onSearchRG.bind(this);
        this._onUnselectRG = this._onUnselectRG.bind(this);
        this._onClearRG = this._onClearRG.bind(this);

        this._onSelectLane = this._onSelectLane.bind(this);
        this._onSearchLane = this._onSearchLane.bind(this);
        this._onUnselectLane = this._onUnselectLane.bind(this);
        this._onClearLane = this._onClearLane.bind(this);

        this._onSelectType = this._onSelectType.bind(this);
        this._onUnselectType = this._onUnselectType.bind(this);
        this._onClearType = this._onClearType.bind(this);

        this._onSelectTime = this._onSelectTime.bind(this);

        this._populateMembersFromURL = this._populateMembersFromURL.bind(this);
        this._populateActivityFromURL = this._populateActivityFromURL.bind(this);
        this._populateRGFromURL = this._populateRGFromURL.bind(this);
        this._populateLaneFromURL = this._populateLaneFromURL.bind(this);

        this._clearAllFilters = this._clearAllFilters.bind(this);
    }

    state = {
        activities: [],
        pageNumber: DEFAULT_PAGE_NUMBER,
        selectedTypes: [],
        hasMore: false,
        isLoading: false,
        createdBefore: new Date(),
        members: [],
        selectedMembers: [],
        searchMember: null,
        routingGuides: [],
        selectedRoutingGuides: [],
        searchRG: null,
        lanes: [],
        selectedLanes: [],
        searchLane: null,
        selectedTime: null,
        returnToTop: false
    }

    componentDidMount() {
        this._fetchActivity();
        this._fetchMembers();
        this._fetchRoutingGuides();
        this._fetchLanes();
        this._populateActivityFromURL();
        this._populateTimestampFromURL();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevSearch = this._getParamFromUrl(prevProps, 'userSearch');
        const search = this._getParamFromUrl(this.props, 'userSearch');

        const prevTimestamp = this._getParamFromUrl(prevProps, 'time');
        const timestamp = this._getParamFromUrl(this.props, 'time');

        const changeOccurred = prevSearch !== search
            || prevTimestamp !== timestamp
            || prevState.selectedMembers !== this.state.selectedMembers
            || prevState.selectedRoutingGuides !== this.state.selectedRoutingGuides
            || prevState.selectedTypes !== this.state.selectedTypes
            || prevState.selectedLanes !== this.state.selectedLanes;

        if (prevState.returnToTop !== changeOccurred) {
            this.setState({ returnToTop: changeOccurred });
        }   

        if (changeOccurred && this.state.pageNumber !== DEFAULT_PAGE_NUMBER) {
            this.setState({ pageNumber: DEFAULT_PAGE_NUMBER });
        } else if (prevState.pageNumber !== this.state.pageNumber || changeOccurred) {
            this._fetchActivity();
        }

        if (prevState.searchMember !== this.state.searchMember) {
            this._fetchMembers();
        }

        if (prevState.searchRG !== this.state.searchRG) {
            this._fetchRoutingGuides();
        }

        if (prevState.searchLane !== this.state.searchLane) {
            this._fetchLanes();
        }
    }

    _fetchActivity() {
        this.setState({ isLoading: true });
        const activityProps = { 
            pagination: {
                pageNumber: this.state.pageNumber,
                pageSize: DEFAULT_PAGE_SIZE,
            },
            createdBefore: this.state.createdBefore,
            userSearch: this._getParamFromUrl(this.props, 'userSearch'),
            members: this.state.selectedMembers.length !== 0 ? this._getAllParamsFromUrl(this.props, 'member') : null,
            routingGuides: this.state.selectedRoutingGuides.length !== 0 ? this._getAllParamsFromUrl(this.props, 'routingGuide') : null,
            lanes: this.state.selectedLanes.length !== 0 ? this._getAllParamsFromUrl(this.props, 'lane') : null,
            activityTypes: this.state.selectedTypes.length !== 0 ? this._getAllParamsFromUrl(this.props, 'activity') : null,
            createdAfter: TIMESTAMP.find(time => time.keyWord === this._getParamFromUrl(this.props, 'time'))?.field
        }

        CarrierActivityLogApiService.filter(this.props.carrier.id, activityProps)
            .then((activities) => {
                const hasMore = (this.state.pageNumber * DEFAULT_PAGE_SIZE) < activities.available;
                this.setState(prevState => {
                    return {
                        activities: this.state.pageNumber !== DEFAULT_PAGE_NUMBER ? prevState.activities.concat(activities.data) : activities.data,
                        hasMore,
                        isLoading: false
                    };
                });
            })
    }

    _populateActivityFromURL() {
        const activityTypes = this._getAllParamsFromUrl(this.props, 'activity');
        const selectedTypes = Object.values(ACTIVITY_TYPES).filter(activity => activityTypes.includes(activity.value)).map(a => a.label);
        
        this.setState({ selectedTypes });
    }

    _populateTimestampFromURL() {
        const timestamp = this._getParamFromUrl(this.props, 'time');
        const selectedTime= TIMESTAMP.find(time => time.keyWord === timestamp)?.title;
        
        this.setState({ selectedTime });
    }

    _fetchMembers() {
        CarrierApiService.getAllMembers(this.props.carrier.id, this.state.searchMember)
            .then((response) => {
                const members = response.data.map(member => { 
                    return { name: member.name, value: member.id }; 
                });

                this.setState({ members })
            })
            .then(this._populateMembersFromURL);
    }

    _fetchRoutingGuides() {
        RFPAuctionApiService.list({ rgName: this.state.searchRG })
        .then((response) => {
             const routingGuides = response.data.map(routingGuide => { 
                 return { name: routingGuide.name, value: routingGuide.id }; 
             });
 
             this.setState({ routingGuides })
        })
        .then(this._populateRGFromURL);
     }

     _fetchLanes() {
        LaneApiService.get(this.state.searchLane)
            .then((response) => {
                const lanes = response.data.map(lane => { 
                    const name = LaneUtils.formatRegion(lane.origin) + ' -> ' + LaneUtils.formatRegion(lane.destination);
                    const value = lane.id;
                    return { name, value }; 
                });

                this.setState({ lanes })
            })
            .then(this._populateLaneFromURL);
     }
    
    _populateMembersFromURL() {
        const members = this._getAllParamsFromUrl(this.props, 'member');
        const selectedMembers = this.state.members.filter(member => members.includes(member.value)).map(m => m.name);

        this.setState({ selectedMembers });
    } 

    _populateRGFromURL() {
        const routingGuides = this._getAllParamsFromUrl(this.props, 'routingGuide');
        const selectedRoutingGuides = this.state.routingGuides.filter(rg => routingGuides.includes(rg.value)).map(r => r.name);

        this.setState({ selectedRoutingGuides });
    }

    _populateLaneFromURL() {
        const lanes = this._getAllParamsFromUrl(this.props, 'lane');
        const selectedLanes = this.state.lanes.filter(lane => lanes.includes(lane.value)).map(l => l.name);

        this.setState({ selectedLanes });
    }

    _incrementPageNumber() {
        this.setState(prevState => { 
            return { pageNumber: prevState.pageNumber + 1 };
        });
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _getAllParamsFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).getAll(param);
    }

    _clearParamFromURL(param) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        searchParams.delete(param);

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _clearParamsFromURL(params) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        params.forEach(param => searchParams.delete(param));

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _onSelectMember(_value, member) {
        if (member) {
            member= JSON.parse(member.key);
            
            this.setState((prevState) => {
                return { selectedMembers: prevState.selectedMembers.concat(member.name), searchMember: null };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);

            searchParams.append('member', member.value);

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSearchMember(searchMember) {
        this.setState({ searchMember });
    }

    _onUnselectMember(_value, member) {
        if (member) {
            member = JSON.parse(member.key);
            
            this.setState((prevState) => {
                const selectedMembers = [ ...prevState.selectedMembers ];
                const index = selectedMembers.findIndex(type => type === member.name);

                if (index > -1) {
                    selectedMembers.splice(index, 1);
                }

                return { selectedMembers,  searchMember: null };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);
            const newSearchParamArray = [];

            for (const [key, newValue] of searchParams.entries()) {
                const memberValues = this.state.members.find((m) => m.value === newValue);
                if (key !== 'member' || (memberValues?.name !== member.name && this.state.selectedMembers.indexOf(memberValues?.name) !== -1)) {
                    newSearchParamArray.push([key, newValue]);
                }
            }

            const newSearchParams = new URLSearchParams(newSearchParamArray);
            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + newSearchParams.toString()
            });
        }
    }

    _onClearMember() {
        this.setState({ selectedMembers: [] });
        this._clearParamFromURL('member');
    }

    _onSelectRG(_value, routingGuide) {
        if (routingGuide) {
            routingGuide = JSON.parse(routingGuide.key);
            
            this.setState((prevState) => {
                return { selectedRoutingGuides: prevState.selectedRoutingGuides.concat(routingGuide.name), searchRG: null  };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);

            searchParams.append('routingGuide', routingGuide.value);

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSearchRG(searchRG) {
        this.setState({ searchRG });
    }

    _onUnselectRG(_value, routingGuide) {
        if (routingGuide) {
            routingGuide = JSON.parse(routingGuide.key);
            
            this.setState((prevState) => {
                const selectedRoutingGuides = [ ...prevState.selectedRoutingGuides ];
                const index = selectedRoutingGuides.findIndex(rg => rg === routingGuide.name);

                if (index > -1) {
                    selectedRoutingGuides.splice(index, 1);
                }

                return { selectedRoutingGuides, searchRG: null  };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);
            const newSearchParamArray = [];

            for (const [key, newValue] of searchParams.entries()) {
                const routingGuideValues = this.state.routingGuides.find(r => r.value === newValue);
                if (key !== 'routingGuide' || (routingGuideValues.name !== routingGuide.name && this.state.selectedRoutingGuides.indexOf(routingGuide.name) !== -1)) {
                    newSearchParamArray.push([key, newValue]);
                }
            }

            const newSearchParams = new URLSearchParams(newSearchParamArray);
            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + newSearchParams.toString()
            });
        }
    }

    _onClearRG() {
        this.setState({ selectedRoutingGuides: [] });
        this._clearParamFromURL('routingGuide');
    }

    _onSelectLane(_value, lane) {
        if (lane) {
            lane = JSON.parse(lane.key);
            
            this.setState((prevState) => {
                return { selectedLanes: prevState.selectedLanes.concat(lane.name), searchLane: null };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);

            searchParams.append('lane', lane.value);

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSearchLane(searchLane) {
        this.setState({ searchLane });
    }

    _onUnselectLane(_value, lane) {
        if (lane) {
            lane = JSON.parse(lane.key);
            
            this.setState((prevState) => {
                const selectedLanes = [ ...prevState.selectedLanes ];
                const index = selectedLanes.findIndex(rg => rg === lane.name);

                if (index > -1) {
                    selectedLanes.splice(index, 1);
                }

                return { selectedLanes, searchLane: null };
            });

            const searchParams = new URLSearchParams(this.props.location?.search);
            const newSearchParamArray = [];

            for (const [key, newValue] of searchParams.entries()) {
                const laneValues = this.state.lanes.find(r => r.value === newValue);
                if (key !== 'lane' || (laneValues.name !== lane.name && this.state.selectedLanes.indexOf(lane.name) !== -1)) {
                    newSearchParamArray.push([key, newValue]);
                }
            }

            const newSearchParams = new URLSearchParams(newSearchParamArray);
            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + newSearchParams.toString()
            });
        }
    }

    _onClearLane() {
        this.setState({ selectedLanes: [] });
        this._clearParamFromURL('lane');
    }

   _onSelectType(value) {
        const activityType = ACTIVITY_TYPES[value];
        this.setState((prevState) => {
            return { selectedTypes: prevState.selectedTypes.concat(activityType.label) };
        });

        const searchParams = new URLSearchParams(this.props.location?.search);

        searchParams.append('activity', value);

        this.props.history.replace({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _onUnselectType(value) {
        const activityType = ACTIVITY_TYPES[value];
        this.setState((prevState) => {
            const selectedTypes = [ ...prevState.selectedTypes ];
            const index = selectedTypes.findIndex(type => type === activityType.label);

            if (index > -1) {
                selectedTypes.splice(index, 1);
            }

            return { selectedTypes };
        });

        const searchParams = new URLSearchParams(this.props.location?.search);
        const newSearchParamArray = [];

        for (const [key, newValue] of searchParams.entries()) {
            const activityTypeValue = ACTIVITY_TYPES[value];
            if (key !== 'activity' || (newValue !== value && this.state.selectedTypes.indexOf(activityTypeValue.label) !== -1)) {
                newSearchParamArray.push([key, newValue]);
            }
        }

            const newSearchParams = new URLSearchParams(newSearchParamArray);
            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + newSearchParams.toString()
            });
            
    }

    _onClearType() {
        this.setState({ selectedTypes: [] });
        this._clearParamFromURL('activity');
    }

    _onSelectTime(_value, time) {
        if (time) {
            time = JSON.parse(time.key);
            const searchParams = new URLSearchParams(this.props.location?.search);
            
            this.setState({
                selectedTime: time.title
            });

            if ('Last 30 days' === time.title) {
                searchParams.delete('time');
            } else {
                searchParams.set('time', time.keyWord);
            }

            this.props.history.replace({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _clearAllFilters() {
        this.setState({ 
            selectedLanes: [],
            selectedRoutingGuides: [],
            selectedMembers: [],
            selectedTypes: [] 
        });
        this._clearParamsFromURL(['lane', 'routingGuide', 'member', 'activity']);
    }

    _formActions() {
        let clearAll = <></>;
        const filters = [this.state.selectedLanes, this.state.selectedMembers, this.state.selectedRoutingGuides, this.state.selectedTypes];
        if (filters.findIndex(filter => filter.length !== 0) >= 0) {
            clearAll = (
                <a className="clear-all-container" href="# " onClick={ this._clearAllFilters }>
                    <small className="clear-all-link">Clear All Filters</small>
                </a>
            );
        }

        return (
            <div className="filter-actions">
            <div className="actions-container">
                <div className="action-field search">
                    <Search placeholder="Search by User name, email..." label="Search" fieldName="userSearch" allowClear={ true } replace={ false } />
                </div>
                <div className="action-field activity-type">
                    <CascadeSelect
                        label="Activity Type"
                        values={ ACTIVITY_TYPE_OPTIONS }
                        multiple={ true }
                        onSelect={ this._onSelectType }
                        onUnselect={ this._onUnselectType }
                        onClear={ this._onClearType }
                        allowClear={ true }
                        selectedValue={ this.state.selectedTypes }
                        placeholder="All Types"
                    />
                </div>
                <div className="action-field member">
                    <MultipleSelect
                        selectedValue={ this.state.selectedMembers }
                        onSelect={ this._onSelectMember }
                        onUnselect={ this._onUnselectMember }
                        onClear={ this._onClearMember }
                        onSearch={ this._onSearchMember }
                        withSearch={ true }
                        fieldName="name"
                        values={ this.state.members }
                        label="Member"
                        allowClear={ true }
                        placeholder="All Members"
                    />
                </div>
                <div className="action-field routing-guide">
                    <MultipleSelect
                        selectedValue={ this.state.selectedRoutingGuides }
                        onSelect={ this._onSelectRG }
                        onUnselect={ this._onUnselectRG }
                        onClear={ this._onClearRG }
                        onSearch={ this._onSearchRG }
                        withSearch={ true }
                        fieldName="name"
                        values={ this.state.routingGuides }
                        label="Routing Guide"
                        allowClear={ true }
                        placeholder="All Routing Guides"
                    />
                </div>
                <div className="action-field lane">
                    <MultipleSelect
                        selectedValue={ this.state.selectedLanes }
                        onSelect={ this._onSelectLane }
                        onUnselect={ this._onUnselectLane }
                        onClear={ this._onClearLane }
                        onSearch={ this._onSearchLane }
                        withSearch={ true }
                        fieldName="name"
                        values={ this.state.lanes }
                        label="Lane"
                        allowClear={ true }
                        placeholder="All Lanes"
                    />
                </div>
            </div> 
            { clearAll } 
            </div> 
        );
    }

    _formContent() {
        if (this.state.activities.length !== 0) {
            return(
                <ActivityLogInfiniteScroll
                    activities={ this.state.activities }
                    hasMore={ this.state.hasMore }
                    isLoading={ this.state.isLoading }
                    fetchMoreData={ this._incrementPageNumber }
                    className="carrier-activity-infinite-scroll"
                    returnToTop={ this.state.returnToTop } 
                />
            );
        } 
        
        return (
            <ActivityEmptyState className="activity-log-empty-state" />
        );
    }

    render() {
        return (
            <div className="carrier-activity-log">
                <div className="heading">
                    <div className="activity-log-information">
                        <h5 className="title">
                            <FontAwesomeIcon icon={ activityIcon } className="icon" />
                            Activity
                        </h5>
                        <strong className="description">
                            Activity log from all carrier members across all Routing Guides, Lanes, Loads.
                        </strong>
                    </div>
                    <div className="timestamp">
                        <Select
                            selectedValue={ this.state.selectedTime || 'Last 30 days'}
                            onSelect={ this._onSelectTime }
                            values={ TIMESTAMP }
                            prefixIcon={ <FontAwesomeIcon icon={ calendarIcon } className="icon" />}
                        />
                    </div>
                </div>
                { this._formActions() }
                { this._formContent() }
            </div>
        );
    }
}

export default withRouter(CarrierActivityLog);
