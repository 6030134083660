import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch as searchIcon } from '@fortawesome/pro-solid-svg-icons';

import CustomTextInput from 'component/form/CustomTextInput';
import StringUtils from 'utils/StringUtils';

import './Search.scss';

export default withRouter(class Search extends Component {

    static propTypes = {
        fieldName: PropTypes.string,
        path: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        allowClear: PropTypes.bool,
        replace: PropTypes.bool,
        updateUrl: PropTypes.bool,
        onSubmit: PropTypes.func
    }

    static defaultProps = {
        fieldName: 'search',
        path: '',
        placeholder: 'Search...',
        label: null,
        allowClear: false,
        replace: true,
        updateUrl: true,
        onSubmit: () => { /* */ }
    }

    constructor(props) {
        super(props);
        this._onInputValueChange = this._onInputValueChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
    }

    state = {
        value: ''
    }

    componentDidMount() {
        if (this.props.updateUrl) {
            this.setState({
                value: new URLSearchParams(this.props.location.search).get(this.props.fieldName) || ''
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateUrl) {
            const previousSearch = new URLSearchParams(prevProps.location.search).get(this.props.fieldName);
            const search = new URLSearchParams(this.props.location.search).get(this.props.fieldName);
    
            if (previousSearch !== search) {
                this.setState({ value: search || '' });
            }
        }
    }

    _onInputValueChange(event) {
        const value = event.target.value;
        this.setState({ value });
    }

    _onSubmit(event) {
        event.preventDefault();
        const value = this.state.value.trim();

        if (StringUtils.isBlank(value) && !this.props.allowClear) {
            this.props.onSubmit();
            return;
        }

        if (this.props.updateUrl) {
            let searchParams = new URLSearchParams((this.props.location || {}).search);
    
            if (this.props.replace) {
                searchParams = StringUtils.isBlank(value) ? '' : new URLSearchParams({ [this.props.fieldName] : value });
            } else if (!StringUtils.isBlank(value)) {
                searchParams.set(this.props.fieldName, value);
            } else if (StringUtils.isBlank(value) && this.props.allowClear) {
                searchParams.delete(this.props.fieldName, value);
            }
    
            this.props.history.push({
                pathname: this.props.path,
                search: '?' + searchParams.toString()
            });
        }

        this.props.onSubmit(value);
    }

    render() {
        const iconStyle = StringUtils.isBlank(this.state.value) ? 'unfocused icon' : 'icon';

        return (
            <div className="search-filter-container">
                <form className="search-filter" onSubmit={ this._onSubmit }>
                    <CustomTextInput
                        label={ this.props.label }
                        placeholder={ this.props.placeholder }
                        prefixIcon={ <FontAwesomeIcon className={ iconStyle } icon={ searchIcon } onClick={ this._onSubmit }/> }
                        value={ this.state.value }
                        fieldName={ this.props.fieldName }
                        onValueChange={ this._onInputValueChange }
                    />
                    <input type="submit" className="submit"/>
                </form>
            </div>
        );
    }
});
