import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasksAlt as activityIcon } from '@fortawesome/pro-solid-svg-icons';

import CustomDrawer from 'component/CustomDrawer';
import Button from 'component/Button';
import ActivityEmptyState from 'component/activity/ActivityEmptyState';
import ActivityLogInfiniteScroll from 'component/activity/ActivityLogInfiniteScroll';

import './ActivityLogDrawer.scss';

export default class ActivityLogDrawer extends Component {
    static propTypes = {
        activities: PropTypes.array,
        hasMore: PropTypes.bool,
        isLoading: PropTypes.bool,
        fetchMoreData: PropTypes.func,
        description: PropTypes.string
    }

    static defaultProps = {
        activities: [],
        hasMore: false,
        isLoading: false,
        fetchMoreData: () => { /* */ },
        description: ''
    };

    constructor(props) {
        super(props);

        this._openDrawer = this._openDrawer.bind(this);
        this._closeDrawer = this._closeDrawer.bind(this);
    }

    state = {
        open: false
    }

    _openDrawer() {
        this.setState({ open: true });
    }

    _closeDrawer() {
        this.setState({ open: false });
    }

    render() {
        return (
            <div className="activity-log-drawer">
                <Button type="tertiary" size="small" onClick={ this._openDrawer } leftIcon={ activityIcon } >
                    Activity Log
                </Button>
                <CustomDrawer
                    title={
                        <div className="activity-log-title">
                            <h5 className="activity-log-heading">
                                <FontAwesomeIcon icon={ activityIcon } className="icon" />
                                Activity
                            </h5>
                            <strong className="activity-log-description">
                                { this.props.description }
                            </strong>
                        </div>
                    } 
                    visible={ this.state.open } 
                    onClose={ this._closeDrawer }
                    width={ 592 }
                >
                    { this.props.activities.length !== 0 ? 
                        <ActivityLogInfiniteScroll 
                            activities={ this.props.activities }
                            hasMore={ this.props.hasMore}
                            isLoading={ this.props.isLoading }
                            fetchMoreData={ this.props.fetchMoreData } 
                            includeLabeledSeparator={ false }
                            hasFirstSeparator={ false }
                            className="activity-drawer-infinite-scroll"
                        /> : <ActivityEmptyState className="activity-drawer-empty-state" /> 
                    }
                </CustomDrawer>
            </div>
        );
    }
}
