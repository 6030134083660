import { Component } from 'react';
import { faPlusCircle, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';

import Popup from 'component/Popup';
import Button from 'component/Button';
import PropTypes from 'prop-types';
import CustomDateTimePicker from 'component/form/CustomDateTimePicker';
import CustomSelect from 'component/form/CustomSelect';
import RestService from 'service/RestService';

import './RFPCreateRound.scss';

const VALUES = [
    {
        "title": 'BIDDING',
        "label": 'Bidding Round'
    },
    {
        "title": "CONFIRMATION",
        "label": 'Confirmation Round'
    }
];

export default class RFPCreateRound extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        onSubmit: PropTypes.func,
        enabled: PropTypes.bool
    };

    static defaultProps = {
        enabled: true,
        onSubmit: () => {/**/}
    }

    constructor(props) {
        super(props);

        this._onTypeChange = this._onTypeChange.bind(this);
        this._onDeadlineChange = this._onDeadlineChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._showPopup = this._showPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
    }

    state = {
        showPopup: false,
        type: 'BIDDING',
        deadline: this._defaultDeadline(),
        valid: true
    };

    _onTypeChange(type) {
        this.setState({ type });
    }

    _rfpStartDate() {
        return moment(new Date(this.props.rfp.startDate)).startOf('day');
    }

    _maxDeadline() {
        return this._rfpStartDate().subtract(1, 'day').endOf('day');
    }

    _defaultDeadline() {
        const rfpStart = this._rfpStartDate();
        let deadline = moment().add(1, 'week');

        if (deadline.isAfter(rfpStart)) {
            deadline = this._maxDeadline();
        }

        return deadline.startOf('minute');
    }

    _onDeadlineChange(deadline) {
        this.setState({ 
            deadline, 
            valid: !!deadline && new Date(deadline).setSeconds(0) > Date.now() && new Date(deadline).setSeconds(0) < new Date(this.props.rfp.startDate)
        });
    }

    _onSubmit() {
        if (new Date(this.state.deadline).setSeconds(0) <= Date.now() || new Date(this.state.deadline).setSeconds(0) >= new Date(this.props.rfp.startDate)) {
            this.setState({ valid: false });
            return;
        }

        RestService.instance()
            .post(
                `auction/rfp/${ this.props.rfp.id }/round`,
                {
                    type: this.state.type,
                    deadline: this.state.deadline
                }
            )
            .then(() => this.setState({ showPopup: false }))
            .then(() => this.props.onSubmit());
    }

    _showPopup() {
        this.setState({ showPopup: true, deadline: this._defaultDeadline() });
    }

    _closePopup() {
        this.setState({ showPopup: false, deadline: this._defaultDeadline() });
    }

    render() {
        const isPending = 'PENDING' === this.props.rfp.status;

        return (
            <Popup
                id="create-rfp-round-popup"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._closePopup }
                trigger={
                    <Button id="rfp-create-round-button"
                            type="primary"
                            size="small"
                            disabled={ !this.props.enabled }
                            leftIcon={ isPending ? faPaperPlane : faPlusCircle }
                            onClick={ this._showPopup }>
                        { isPending ? 'Publish RFP' : 'Start a New Round' }
                    </Button>
                }>
                <div className="create-rfp-round-popup">
                    <h6 className="heading">{ isPending ? 'Publish RFP' : 'Start a New Round' }</h6>

                    <p className="description">
                        Note: The round deadline must be before the RFP term start date.
                    </p>

                    { !isPending && (
                        <>
                            <small className="input-label"><b>Round Type</b></small>
                            <CustomSelect id="rfp-round-type-select"
                                          values={ VALUES }
                                          selectedValue={ this.state.type }
                                          onSelect={ this._onTypeChange }/>
                        </>
                    ) }

                    <small className="input-label"><b>Deadline</b></small>
                    <CustomDateTimePicker id="rfp-round-time-picker"
                                          date={ this.state.deadline }
                                          minDate={ moment() }
                                          maxDate={ this._maxDeadline() }
                                          handleChange={ this._onDeadlineChange } />

                    <div className="buttons-wrapper">
                        <Button id="rfp-create-round-cancel-button"
                                type="tertiary"
                                onClick={ this._closePopup }>
                            Cancel
                        </Button>

                        <Button id="rfp-create-round-submit-button"
                                disabled={ !this.state.valid }
                                onClick={ this._onSubmit }>
                            { isPending ? 'Publish' : 'Create' }
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
