import { Component } from 'react';

import AddCarrier from 'component/AddCarrier';
import CarrierApiService from 'service/api/CarrierApiService';

export default class AddNewCarrier extends Component {

    constructor(props) {
        super(props);

        this._addCarrier = this._addCarrier.bind(this);
        this._onValueChanged = this._onValueChanged.bind(this);
    }

    state = {
        businessId: undefined
    };

    _addCarrier(businessId) {
        return CarrierApiService.add(businessId);
    }

    _onValueChanged(businessId) {
        this.setState({ businessId });
    }
    
    render() {
        const successElement = (
            <div>
                <h6>Carrier Added</h6>
                <p>Carrier with carrier code <b>{ this.state.businessId }</b> has been added to the application.</p>
            </div>
        );

        return (
            <AddCarrier 
                successElement={ successElement }
                popupTitle="Add New Carrier"
                buttonText="Add New Carrier"
                buttonType="primary"
                submitButtonText="Add Carrier"
                addCarrier={ this._addCarrier }
                onValueChanged={ this._onValueChanged }
            />
        );
    }
}
