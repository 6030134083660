export default class NumberUtils {

    static ordinal(number) {
        const suffix = ["th", "st", "nd", "rd"];
        const value = number % 100;
        return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
    }

    /**
     * Converts numbers into the US formatting rounded to two decimals
     * 
     * @param {number} number 
     * @returns {string}
     */
    static formatWithDecimalNotation(number) {
        return (Math.round(number * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    /**
     * Converts numbers into the US formatting rounded to zero decimals
     * 
     * @param {number} number 
     * @returns {string}
     */
    static formatAsAWholeNumber(number) {
        return (number).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    /**
     * Adds a plus sign before non-negative numbers, returns '/' if provided parameter is not a number
     * 
     * @param {number} number 
     * @returns number with a sign prefix
     */
    static formatWithSign(number) {
        if (typeof number !== 'number') {
            return '/';
        }
        
        return number < 0 ? number : '+' + number;
    }

    /**
     * Appends prefix to the number with the option to have it before or after the minus sign, if applicable.
     * The string form of the number is then formatted according to the format option which can have values 'decimal' and 'whole'.
     * If no format option is passed, the string will retain the formatting from the input parameter.
     * 
     * @param {number} number 
     * @param {string} prefix
     * @param {string} format
     * @param {boolean} beforeSign 
     * @returns {string}
     */
    static prefixNumber(number, prefix, beforeSign, format) {
        let absNumber = Math.abs(number);
        
        let formattedNumber = absNumber;
        if ('decimal' === format) {
            formattedNumber = this.formatWithDecimalNotation(absNumber);
        } else if ('whole' === format) {
            formattedNumber = this.formatAsAWholeNumber(absNumber);
        }

        let prefixAndSign = `${ number < 0 ? '-' : ''}${ prefix }`;
        if (beforeSign) {
            prefixAndSign = `${ prefix }${ number < 0 ? '-' : ''}`;
        }

        return `${ prefixAndSign }${ formattedNumber }`;
    }

    /**
     * Rounds numbers into wished number of decimals based on the number provided in decimals param
     * 
     * @param {number} number 
     * @param {number} decimals
     * @returns {number}
     */
    static roundToDecimals(number, decimals = 2) { 
        const n = Math.pow(10, decimals);
        return Number((Math.round(number * n)) / n);
    }

    /**
     * Returns all numbers in a range from start to end
     * 
     * @param {number} start
     * @param {number} end
     * @returns {Array}
     */
    static getRange(start, end) {
        const result = [];

        for (let i = start; i <= end; i++) {
            result.push(i);
        }
        
        return result;
    }

    /**
     * Truncates numbers to wished number of decimals, returns null if provided argument is not a number
     * 
     * @param {number} number 
     * @param {number} decimals 
     * @returns 
     */
    static truncate(number, decimals = 2) {
        if (number === null || number === '' || isNaN(number)) {
            return null;
        }

        let [integerPart, decimalPart] = number.toString().split('.');

        if (decimals === 0) {
            return integerPart;
        }

        if (!decimalPart) {
            decimalPart = '';
        }

        const numberOfDecimals = decimalPart.length;

        if (numberOfDecimals < decimals) {
            decimalPart += '0'.repeat(decimals - numberOfDecimals);
        } else if (numberOfDecimals > decimals) {
            decimalPart = decimalPart.substr(0, decimals);
        }

        return integerPart + '.' + decimalPart;
    }
}
