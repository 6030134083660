import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'component/Button';
import DownloadFileComponent from 'component/DownloadFileComponent';
import CustomLoader from 'component/form/CustomLoader';
import Popup from 'component/Popup';
import TenderAuctionApiService from 'service/api/TenderAuctionApiService';

export default class DownloadRateConfirmationPopup extends Component {
    static propTypes = {
        auctionId: PropTypes.string.isRequired,
        carrierId: PropTypes.string.isRequired,
        carrierAction: PropTypes.bool,
        onDownload: PropTypes.func
    };

    static defaultProps = {
        carrierAction: true,
        onDownload: () => { /* */ }
    };

    constructor (props) {
        super(props);

        this._onDownloadStart = this._onDownloadStart.bind(this);
        this._onDownloadEnd = this._onDownloadEnd.bind(this);
    }

    state = {
        downloading: false
    };

    _onDownloadStart() {
        this.setState({ downloading: true });
    }

    _onDownloadEnd() {
        this.setState({ downloading: false });
        this.props.onDownload();
    }

    render() {
        const params = { carrierId: this.props.carrierId };

        let trigger;
        if (this.props.carrierAction) {
            trigger = (
                <Button onClick={ this._onDownloadStart }>
                    Rate Confirmation
                </Button>
            );
        } else {
            trigger = (
                <span href="#popup-download-rate-confirmation" onClick={ this._onDownloadStart } className="dropdown-item">
                    <small className="action-name">Download Rate Confirmation</small>
                </span>
            );
        }

        return (
            <Popup
                id="popup-download-rate-configuration"
                size="medium"
                show={ this.state.downloading }
                trigger={ 
                    <DownloadFileComponent
                        className="download-rate-confirmation-action"
                        trigger={ trigger }
                        reference={{ endpoint: TenderAuctionApiService.getDownloadRateConfirmationURL(this.props.auctionId), params }}
                        isStaticResource={ false }
                        onDownload={ this._onDownloadEnd }
                    />
                }
            >
                <div>
                    <CustomLoader size="large" />
                    <h6 className="heading">Downloading Rate Confirmation</h6>
                    <p className="description">This may take a while. Please wait...</p>
                </div>
            </Popup>
        );
    }
}
