import { Component } from 'react';
import PropTypes from 'prop-types';

import { SURVEY_QUESTION_TYPE } from 'common/constants';
import Button from 'component/Button';
import ProgressBar from 'component/ProgressBar';
import CustomTextArea from 'component/form/CustomTextArea';
import SurveyRadioButtonGroup from 'component/survey/SurveyRadioButtonGroup';

import './SurveyQuestions.scss';

const MAX_TEXT_AREA_CHARACTER = 350;

export default class SurveyQuestions extends Component {
    
    static propTypes = {
        surveyId: PropTypes.string.isRequired,
        questions: PropTypes.array.isRequired,
        activeStep: PropTypes.number,
        answers: PropTypes.array,
        onSurveyCompleted: PropTypes.func.isRequired,
        onSurveyClosed: PropTypes.func.isRequired,
        saveAnswers: PropTypes.func.isRequired,
        setActiveStep: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this._onAnswerValueChange = this._onAnswerValueChange.bind(this);
        this._handleNextStep = this._handleNextStep.bind(this);
        this._handlePreviousStep = this._handlePreviousStep.bind(this);
    }

    _onAnswerValueChange(event) {
        const { answers, activeStep } = this.props;
        const prevAnswer = answers.find(a => a.ordinal === activeStep);
        const answer = event.target.value;
        let surveyAnswers;

        if (prevAnswer) {
            prevAnswer.answer = answer;
            surveyAnswers = [ ...answers ];
        } else {
            surveyAnswers = [
                ...answers,
                {
                    ordinal: activeStep, 
                    questionId: this.props.questions[activeStep].id,
                    answer
                } 
            ]
        }

        this.props.saveAnswers(surveyAnswers);
    }

    _handleNextStep() {
        if (this.props.activeStep !== this.props.questions.length - 1) {
            this.props.setActiveStep(this.props.activeStep + 1);
        } else {
            this.props.onSurveyCompleted();
        } 
    }

    _handlePreviousStep() {
        this.props.setActiveStep(this.props.activeStep - 1);
    }

    _formSurveyQuestionsContent() {
        const { questions, activeStep } = this.props;
        const backButtonVisibilityClass = activeStep ? 'visible' : 'hidden';
        const buttonText = activeStep === questions.length - 1 ? 'Complete Survey' : 'Next';
        const activeQuestion = questions[activeStep];
        const activeAnswer = this.props.answers.find(a => a.ordinal === activeStep);
        let questionType = 'rating';

        let content;
        content = (
            <SurveyRadioButtonGroup
                numberOfRates={ 5 }
                selectedValue={ activeAnswer?.answer }
                onChange={ this._onAnswerValueChange }
            />
        );

        if (SURVEY_QUESTION_TYPE.COMMENT === activeQuestion.type) {
            questionType = 'comment';
            content = (
                <>
                    <CustomTextArea
                        placeholder="Start typing..."
                        rows={ 4 }
                        value={ activeAnswer?.answer }
                        maxLength={ MAX_TEXT_AREA_CHARACTER }
                        acceptsWhitespace={ true }
                        onValueChange={ this._onAnswerValueChange }
                        characterCounter={ true }
                    />
                </>
            );
        }

        return (
            <>
                <ProgressBar
                    currentValue={ activeStep }
                    totalValue={ questions.length }
                />
                <p className="survey-question-step">
                    STEP { activeQuestion.ordinal }/{ questions.length }
                </p>
                <p className="survey-question-text">
                    { activeQuestion.question }
                </p>
                <div className={ `survey-question-content ${ questionType }` }>
                    { content }                   
                    <div className="survey-rating-labels">
                        <div className="low-label">
                            { activeQuestion.lowLabel }
                        </div>
                        <div className="high-label">
                            { activeQuestion.highLabel }        
                        </div>
                    </div>
                </div>
    
                <div className="survey-question-actions">
                    <div className="termination-section">
                        <Button
                            type="tertiary"
                            size="small"
                            onClick={ this.props.onSurveyClosed }
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="navigation-section">
                        <Button
                            className={ `survey-back-button ${ backButtonVisibilityClass }` }
                            type="tertiary"
                            size="small"
                            onClick={ this._handlePreviousStep }
                        >
                            Back
                        </Button>
    
                        <Button
                            type="primary"
                            size="small"
                            onClick={ this._handleNextStep }
                            disabled= { activeQuestion.required && !activeAnswer }
                        >
                            { buttonText }
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    render() {
        if (this.props.questions.length) {
            return (
                <div className="survey-question-component">
                    { this._formSurveyQuestionsContent() }
                </div>
            )
        }
    }
}
