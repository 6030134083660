import { cloneElement, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as closeIcon } from '@fortawesome/pro-light-svg-icons';

import './Popup.scss';

export default class Popup extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        trigger: PropTypes.element.isRequired,
        size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
        closeButton: PropTypes.bool,
        dimBackground: PropTypes.bool,
        show: PropTypes.bool,
        onClose: PropTypes.func
    }

    static defaultProps = {
        size: 'small',
        closeButton: false,
        dimBackground: true,
        show: false,
        onClose: () => { /* */ }
    }

    /**
     *
     * @type {React.RefObject | {current: null}}
     * @private
     */
    _triggerRef = createRef();

    /**
     *
     * @type {React.RefObject | {current: null}}
     * @private
     */
    _popupRef = createRef();

    render() {
        const visibilityClass = this.props.show ? " visible" : " hidden";
        const className = `popup-container static ${ visibilityClass }`;
        
        // eslint-disable-next-line
        const overlay = <a className={ 'overlay' + (this.props.dimBackground ? ' dimmed' : '') } onClick={ (e) => { e.preventDefault(); e.stopPropagation(); this.props.onClose(); } } />

        // eslint-disable-next-line
        const closeButton = <a href="#" className="close" onClick={ (e) => { e.preventDefault(); e.stopPropagation(); this.props.onClose(); }}>
            <FontAwesomeIcon icon={ closeIcon } />
        </a>

        return (
            <>
                { cloneElement(this.props.trigger, { ref: this._triggerRef }) }
                <div ref={ this._popupRef } id={ this.props.id } className={ className }>
                    { overlay }
                    <div className={ `popup ${ this.props.size }` } onClick={ e => e.stopPropagation() } >
                        { this.props.closeButton && closeButton }
                        { this.props.children }
                    </div>
                </div>
            </>
        );
    }
}
