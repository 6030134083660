import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { USER_STATUS } from 'common/constants';
import CanAccess from 'component/CanAccess';
import RemoveMember from 'component/carrier/RemoveMember';
import ReactivateMember from 'component/carrier/ReactivateMember';
import TableRow from 'component/table/custom/TableRow';
import TableCell from 'component/table/custom/TableCell';
import DateUtils from 'utils/DateUtils';

import './MemberTableRow.scss';

export default class MemberTableRow extends Component {

    static propTypes = {
        member: PropTypes.object.isRequired,
        removeMember: PropTypes.func,
        reactivateMember: PropTypes.func 
    };

    static defaultProps = {
        removeMember: () => { /* */ },
        reactivateMember: () => { /* */ }
    };

    _formAction() {
        if (USER_STATUS.REMOVED.field === this.props.member.status) {
            return <ReactivateMember member={ this.props.member } onReactivateMember={ this.props.reactivateMember } />;
        }

        return <RemoveMember member={ this.props.member } onRemoveMember={ this.props.removeMember } />;
    }

    render() {
        const { member } = this.props;
        const name = member.name ? member.name : '-';
        const email = member.email;
        const phone = member.phone ? member.phone : '-';
        const status = !member.carrier.active && USER_STATUS.REMOVED.field !== member.status ? 'Terminated' : USER_STATUS[member.status].label;
        const dateAdded = member.dateAdded ? DateUtils.format(member.dateAdded) : '-';

        return (
            <TableRow className={ `member-content-row ${ status.toLowerCase() }` } fontSize="small">
                <TableCell className="name" padding="small">
                    <Link to={ `/user/${ member.id }` } className="name-link">
                        <div className="name-ellipsis" title={ name }>
                            { name }
                        </div>
                    </Link>
                </TableCell>
                <TableCell className="email" padding="small">
                    <div className="email-ellipsis" title={ email }>
                        { email }
                    </div>  
                </TableCell>
                <TableCell className="phone" padding="small">
                        { phone }
                </TableCell>
                <TableCell className="member-status" padding="small">
                        { status }
                </TableCell>
                <TableCell className="date-added" padding="small">
                    { dateAdded }
                </TableCell>
                <CanAccess
                    action="carrier:write" 
                    yes={ 
                        <TableCell className="actions" padding="small" alignment="center">
                            { this._formAction() }
                        </TableCell>
                    }
                />
            </TableRow>
        )
    }
}
