import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faDownload as download, 
    faTimesCircle as exit, 
    faEye as eye,
    faInfoCircle as info,
    faAddressCard as dedicatedCarrier,
    faHouseUser as spotMarketCarrier
} from '@fortawesome/pro-solid-svg-icons';

import RestService from 'service/RestService';
import AuthorizationService from 'service/AuthorizationService';
import CanAccess from 'component/CanAccess';
import DateUtils from 'utils/DateUtils';
import ObjectUtils from 'utils/ObjectUtils';
import Button from 'component/Button';
import Banner from 'component/Banner';
import TitledCardComponent from 'component/card/TitledCardComponent';
import ActionDropdown from 'component/ActionDropdown';
import DownloadFileComponent from 'component/DownloadFileComponent';
import ViewAttachments from 'component/rfp/ViewAttachments';
import RFPActivityLog from 'component/rfp/RFPActivityLog';
import RFPAuctionStatus from 'component/rfp/RFPAuctionStatus';
import RFPCreateRound from 'component/rfp/RFPCreateRound';
import RFPConfirm from 'component/rfp/RFPConfirm';
import RFPApply from 'component/rfp/RFPApply';
import RFPBillTo from 'component/rfp/RFPBillTo';
import Tooltip from 'component/Tooltip';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import RFPUtils from 'utils/RFPUtils';
import RoutingGuideUtils from 'utils/RoutingGuideUtils';
import { 
    RFP_AUCTION_STATUSES, 
    USER_ROLES_MAPPING, 
    AUCTION_STATUSES,
    FINISHED_RFP_AUCTION_STATUSES,
    RG_DETAILS_VIEW_MODE,
    ONGOING_RFP_AUCTION_STATUSES,
    RFP_WRITE_PERMISSION
} from 'common/constants';

import './RFPHeaderCard.scss';

export default withRouter(class RFPHeaderCard extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        viewMode: PropTypes.string,
        onRFPUpdated: PropTypes.func,
        onViewRFP: PropTypes.func,
        onExitViewOnlyMode: PropTypes.func,
        version: PropTypes.number
    };

    static defaultProps = {
        viewMode: null,
        onRFPUpdated: () => { /* */ },
        onViewRFP: () => { /* */ },
        onExitViewOnlyMode: () => { /* */ },
        version: 0
    }

    constructor(props) {
        super(props);
        this._onUpdate = this._onUpdate.bind(this);
        this._onDelete = this._onDelete.bind(this);
        this._onDuplicate = this._onDuplicate.bind(this);
    }

    state = {
        loading: true,
        canConfirm: true,
        subscribed: null,
        statistics: {},
        attachments: []
    }

    componentDidMount() {
        this._fetchData();
        this._fetchSubscription();

        // TODO: Add this condition when we figure out how to include ShipEX loads in the awarded volume
        /*
        if (RFPUtils.isConfirmationReview(this.props.rfp) && await AuthorizationService.instance().canAccess("rfp:write")) {
            RestService.instance()
                .get(`auction/rfp/${ this.props.rfp.id }/can-confirm`)
                .then(response => this.setState({ canConfirm: response.canConfirm }));
        }
        */
    }

    componentDidUpdate(prevProps) {
        if (!ObjectUtils.equal(prevProps.rfp, this.props.rfp)) {
            this._fetchData();
            this._fetchSubscription();
        } else if (prevProps.viewMode !== this.props.viewMode || prevProps.version !== this.props.version) {
            this._fetchData();
        }
    }

    _onUpdate() {
        this._fetchData();
        this.props.onRFPUpdated();
    }

    _onDelete() {
        this.props.history.push({
            pathname: '/rfp/list'
        });
    }

    _onDuplicate(id) {
        this.props.history.push({
            pathname: `/rfp/${ id }/lanes`
        });
    }

    _fetchData() {
        this.setState({ loading: true });

        let statisticsApiUrl;
        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            statisticsApiUrl = `auction/rfp/${ this.props.rfp.id }/statistics`;
        } else {
            statisticsApiUrl = `routing-guide/${ this.props.rfp.id }/statistics`;
        }
        
        RestService.instance()
            .get(statisticsApiUrl)
            .then(data => this.setState({
                    statistics: data,
                    loading: false
                })
            );

        RestService.instance()
            .get(`auction/rfp/${ this.props.rfp.id }/attachment`)
            .then(attachments => this.setState({
                    attachments: attachments.data
                })
            );
    }

    _fetchSubscription() {
        const eligibleRoles = [USER_ROLES_MAPPING.BROKER, USER_ROLES_MAPPING.RFP_MANAGER];
        const canBeSubscribed = this.props.account.roles.some(role => eligibleRoles.includes(role));
        
        if (canBeSubscribed) {
            RoutingGuideNotificationApiService.isSubscribed(this.props.rfp.id).then(subscribed => this.setState({ subscribed }));
        }
    }

    _shouldShowPublishWarning(statistics) {
        return Object.keys(statistics).length !== 0 && AUCTION_STATUSES.PENDING === this.props.rfp.status
            && !(statistics['CARRIERS'] && statistics['LANES']);
    }

    _createRoundEnabled() {
        const has = statistic => {
            const stat = this.state.statistics[statistic];

            return stat !== undefined && stat > 0;
        };

        return [RFP_AUCTION_STATUSES.PENDING, RFP_AUCTION_STATUSES.IN_REVIEW].includes(this.props.rfp.status) && has('CARRIERS') && has('LANES');
    }

    _formActionButtons() {
        const rfp = this.props.rfp;
        const rfpView = RoutingGuideUtils.isRFPView(this.props.viewMode, rfp.status);

        let primaryAction = null;
        
        if (!rfpView) {
            primaryAction = (
                <DownloadFileComponent
                    trigger={
                        <Button size="small">
                            <FontAwesomeIcon className="download-icon" icon={ download } />
                            <small>
                                <b>Download Routing Guide</b>
                            </small>
                        </Button>
                    }
                    reference={ `routing-guide/${ this.props.rfp.id }/csv` }
                    isStaticResource={ false }
                />
            );
        } else if (FINISHED_RFP_AUCTION_STATUSES.includes(rfp.status) || RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS.includes(rfp.status)) { 
            // Nothing to do here
        } else if (RFP_AUCTION_STATUSES.CONFIRMED === rfp.status) {
            primaryAction = (
                <RFPApply rfp={ rfp } onSubmit={ this._onUpdate } />
            );
        } else if (RFPUtils.isConfirmationReview(rfp)) {
            primaryAction = (
                <RFPConfirm rfp={ rfp } onSubmit={ this._onUpdate } disabled={ !this.state.canConfirm } />
            );
        } else {
            primaryAction = (
                <RFPCreateRound rfp={ rfp }
                                enabled={ this._createRoundEnabled() }
                                onSubmit={ this._onUpdate } />
            );
        }

        const canUserEditRFP = AuthorizationService.instance().canUserAccess(this.props.account, 'rfp:write');

        const isRFPCompleted = RFP_AUCTION_STATUSES.COMPLETED === rfp.status;
        const isRFPApplied = FINISHED_RFP_AUCTION_STATUSES.includes(rfp.status);
        const canEdit = !isRFPCompleted && canUserEditRFP;
        const canDuplicate = !rfp.synthetic && canUserEditRFP;
        const canCompleteRG = RFP_AUCTION_STATUSES.APPLIED === rfp.status && canUserEditRFP;
        const canSeeNotificationSubscriptions = AuthorizationService.instance().canUserAccess(this.props.account, 'notification-subscriptions:read');

        return (
            <CanAccess
                action={ ['rfp:read:all', 'routing-guide:read'] }
                yes={
                    <>
                        <ActionDropdown
                            size="small"
                            seeAttachmentsProps={{ 
                                disabled: false,
                                attachments: this.state.attachments,
                                rfp: rfp,
                                onUpdate: this._onUpdate
                            }}
                            uploadAttachmentsProps={{ 
                                disabled: !canEdit,
                                rfp: rfp,
                                onUpdate: this._onUpdate
                            }}
                            editRFPProps={{ 
                                disabled: !canEdit,
                                rfp,
                                onUpdate: this._onUpdate
                            }}
                            duplicateRFPProps={{
                                disabled: !canDuplicate,
                                rfp: this.props.rfp,
                                onUpdate: this._onDuplicate
                            }}
                            deleteRFPProps={{ 
                                disabled: RFP_AUCTION_STATUSES.PENDING !== rfp.status,
                                rfp,
                                onDelete: this._onDelete
                            }}
                            downloadRoutingGuideProps={{ 
                                disabled: RFP_AUCTION_STATUSES.PENDING === rfp.status || !rfpView,
                                rfpId: rfp.id
                            }}
                            viewRFPProps={{
                                disabled: RG_DETAILS_VIEW_MODE.RFP_AND_ROUTING_GUIDE !== this.props.viewMode || !isRFPApplied || rfp.synthetic,
                                onViewRFP: this.props.onViewRFP
                            }}
                            completeRGProps={{
                                disabled: !canCompleteRG,
                                rfp,
                                onComplete: this._onUpdate
                            }}
                            notificationCenterProps={{
                                disabled: !canSeeNotificationSubscriptions,
                                routingGuideId: rfp.id
                            }}
                        />
                        { primaryAction }
                    </>
                }
                no={
                    <div className="carrier-rfp-actions">
                        <RFPActivityLog rfp={ rfp } carrierId={ this.props.account.carrierId } />

                        <ViewAttachments
                            attachments={ this.state.attachments }
                            rfp={ rfp }
                            trigger="button"
                            onUpdate={ this._onUpdate }
                        />
                    </div>
                }
            />
        );
    }

    _formRfpStatistics() {
        const statistics = ObjectUtils.removeEmptyKeys(this.state.statistics);
        const shouldWarn = this._shouldShowPublishWarning(statistics);
        const iconProperties = {
            'DEDICATED CARRIERS': { icon: dedicatedCarrier, style: 'dedicated-icon' },
            'SM CARRIERS': { icon: spotMarketCarrier, style: 'sm-icon' },
        };

        return Object.keys(statistics).reduce((content, key) => {
            const title = key;
            const value = statistics[key];
            const style = shouldWarn && value === 0 && (key === 'CARRIERS' || key === 'LANES') ? 'warning' : '';

            content.push(
                <TitledCardComponent
                    id={ `rfp-header-stats-${ key.toLowerCase() }` }
                    key={ `header-card-statistics-${ key }` }
                    type="small"
                    title={ title }
                    align="right"
                    className="rfp-header-card-statistics-row-column-field"
                >
                    <strong className={ style }>
                        <b>{ value }</b> 
                        { iconProperties[title] && 
                            <FontAwesomeIcon icon={ iconProperties[title].icon } className={ iconProperties[title].style } /> 
                        }
                    </strong>
                </TitledCardComponent>
            );

            return content;
        }, []);       
    }

    _formRfpWarning() {
        let message;

        if (RFPUtils.isConfirmationReview(this.props.rfp) && this.state.canConfirm === false) {
            message = 'All lanes must be fully awarded in order to confirm an RFP.';
        } else if (this._shouldShowPublishWarning(this.state.statistics)) {
            message = 'RFP can\'t be published until lanes and carriers are added.';
        } else {
            return <></>;
        }

        return (
            <div id="rfp-header-warning" className="rfp-header-card-warning-row">
                <Banner type="warn" content={ message } size="medium"/>
            </div>
        );
    }

    _formExitViewOnlyModeOption() {
        if (RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === this.props.viewMode) {
            return (
                <div className="rfp-header-card-exit-view-only-mode-row">
                    <div>
                        <Banner 
                            size="small" 
                            content={ <>You are currently seeing the <b>Initial RFP</b> that was created for this routing guide</> }
                        />
                    </div>
                    <div>
                        <small><b>
                            <a href="#!" role="button" onClick={ this.props.onExitViewOnlyMode }>
                                Exit RFP View Mode
                                <FontAwesomeIcon icon={ exit } className="exit-icon" />
                            </a>
                        </b></small>
                    </div>
                </div>
            );
        }
    }

    _getTitle() {
        const { rfp } = this.props;
        const rfpView = RoutingGuideUtils.isRFPView(this.props.viewMode, rfp.status);

        let icon = <></>;
        if (RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === this.props.viewMode) {
            icon = (
                <div className="eye-icon-container">
                    <div className="eye-icon">
                        <small><FontAwesomeIcon icon={ eye } /></small>
                    </div>
                    <Tooltip direction="bottom">
                        RFP View Mode
                    </Tooltip>
                </div>
            );
        }

        const title = rfpView ? 'RFP OVERVIEW' : 'ROUTING GUIDE';

        return [icon, title];
    }

    _getDetails() {
        const effectiveOn = DateUtils.formatDate(this.props.rfp.startDate);
        const expiresOn = DateUtils.formatDate(this.props.rfp.endDate);
        const round = RFPUtils.latestRound(this.props.rfp);
        return (
            <div className="rfp-header-card-statistics-row-column">
                <CanAccess
                    action={ ['rfp:read:all', 'routing-guide:read'] }
                    yes={
                        <TitledCardComponent
                            id="rfp-header-bill-to"
                            type="small"
                            title="BILL TO IDS"
                            className="rfp-header-card-statistics-row-column-field">
                                <strong><b><RFPBillTo rfp={ this.props.rfp } /></b></strong>
                        </TitledCardComponent>
                    }
                />
                <TitledCardComponent
                    id="rfp-header-effective-on"
                    type="small"
                    title="EFFECTIVE ON"
                    className="rfp-header-card-statistics-row-column-field">
                        <strong><b>{ effectiveOn }</b></strong>
                </TitledCardComponent>
                <TitledCardComponent
                    id="rfp-header-expires-on"
                    type="small"
                    title="EXPIRES ON"
                    className="rfp-header-card-statistics-row-column-field"
                >
                    <strong><b>{ expiresOn }</b></strong>
                </TitledCardComponent>
                <RFPAuctionStatus
                    id="rfp-header-status"
                    showGeneralStatus={ ONGOING_RFP_AUCTION_STATUSES.includes(this.props.rfp.status) }
                    status={ this.props.rfp.status }
                    round = { round }
                    className="rfp-header-card-statistics-row-column-field" 
                />
            </div>
        );
    }

    _getDynamicLanesMessage(rfpStatus, permissions, dynamicLanesStatus) {
        const isDynamicLanesOnMessage = dynamicLanesStatus ? 'on' : 'off';
        const willLanesBeCreatedMessage = !dynamicLanesStatus ? 'not ' : '';

        if (!permissions.includes(RFP_WRITE_PERMISSION) || RFP_AUCTION_STATUSES.COMPLETED === rfpStatus) {
            return `Automatic creation of lanes is turned ${ isDynamicLanesOnMessage }.\nNew lanes will ${ willLanesBeCreatedMessage }be created when loads matching\nthis routing guide become available.`;     
        }

        return RFP_AUCTION_STATUSES.APPLIED === rfpStatus ?
            `Automatic creation of lanes is turned ${ isDynamicLanesOnMessage }.\nNew lanes will ${ willLanesBeCreatedMessage }be created when loads matching\nthis routing guide become available.\n\nTo change this go to Actions -> Edit Information.` : 
            `Automatic creation of lanes is turned ${ isDynamicLanesOnMessage }.\nNew lanes will ${ willLanesBeCreatedMessage }be created when loads matching\nthis routing guide become available. This setting will\nbecome effective once the routing guide is applied.\n\nTo change this go to Actions -> Edit Information.`;
    }

    _getDynamicLanes(rfp, permissions) {
        const { status, dynamic } = rfp;

        const dynamicLanesTooltipMessage = this._getDynamicLanesMessage(status, permissions, dynamic);

        return (
            <TitledCardComponent
                id="rfp-header-stats-dynamic-lanes"
                key="header-card-statistics-dynamic-lanes"
                type="small"
                title="DYNAMIC LANES"
                align="right"
                className="rfp-header-card-statistics-row-column-field dynamic-lanes"
            >
                <strong className={ dynamic ? '' : 'not-dynamic-lanes' }><b>{ dynamic ? 'On' : 'Off' }</b></strong>
                <div className="icon-div">
                    <FontAwesomeIcon icon={ info } className="icon" />
                    <Tooltip direction="bottom-left" >
                        { dynamicLanesTooltipMessage }
                    </Tooltip>
                </div>
            </TitledCardComponent>
        );
    }

    render() {
        const [icon, title] = this._getTitle();
        const { rfp, account: { roles, permissions } } = this.props;

        const dynamicLanes = !roles.includes(USER_ROLES_MAPPING.CARRIER) ? this._getDynamicLanes(rfp, permissions) : <></>; //Carrier is the only type of user that shouldn't see this label.

        let notificationsSubscription = <></>;
        if (this.state.subscribed !== null) {
            const message = this.state.subscribed ? 'You are getting notifications for this RFP.' : 'You are not assigned to receive notifications for this RFP. Contact your administrator for further changes.';

            notificationsSubscription = (
                <TitledCardComponent
                    id="rfp-header-stats-notifications"
                    key="header-card-statistics-notifications"
                    type="small"
                    title="NOTIFICATIONS"
                    align="right"
                    className="rfp-header-card-statistics-row-column-field notifications"
                >
                    <strong className={ this.state.subscribed ? '' : 'not-subscribed' }><b>{ this.state.subscribed ? 'On' : 'Off' }</b></strong>
                    <div className="icon-div">
                        <FontAwesomeIcon icon={ info } className="icon" />
                        <Tooltip direction="bottom-left">
                            { message }
                        </Tooltip>
                    </div>
                </TitledCardComponent>
            );
        }

        return (
            <div className="rfp-header-card-container">
                <div className="component rfp-header-card">
                    { this._formExitViewOnlyModeOption() }
                    <div className="rfp-header-card-title-row">
                        <div className="rfp-header-card-title-row-column">
                            <sup>{ title }</sup>
                            <span className="rfp-header-card-title">
                                { icon }
                                <h5 id="rfp-header-name" title={ rfp.name }>
                                    { rfp.name }
                                </h5>
                            </span>
                        </div>
                        <div className="rfp-header-card-title-row-column actions">
                            { this._formActionButtons() }
                        </div>
                    </div>
                    <div className="rfp-header-card-statistics-row">
                        { this._getDetails() }
                        <div className="rfp-header-card-statistics-row-column">
                            { this._formRfpStatistics() }
                            { dynamicLanes }
                            { notificationsSubscription }
                        </div>
                    </div>
                    <CanAccess
                        action="rfp:write"
                        yes={ this._formRfpWarning() }
                    />
                </div>
            </div>
        );
    }
});
