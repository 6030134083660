import { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import 'antd/dist/antd.css';
import './CustomSwitch.scss';

export default class CustomSwitch extends Component {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        size: PropTypes.oneOf(['small', 'default']),
        label: PropTypes.string,
        description: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        size: 'default',
        label: '',
        description: '',
        className: '',
        disabled: false,
        onChange: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onLabelClick = this._onLabelClick.bind(this);
    }

    _onLabelClick() {
        if (!this.props.disabled) {
            this.props.onChange();
        }
    }

    render() {
        const { size, label, description, checked, className, disabled, onChange } = this.props;

        return (
            <div className={ `switch-component ${ className }` }>
                <div className="switch-wrapper">
                    <Switch size={ size } checked={ checked } disabled={ disabled } onChange={ onChange } className={ `custom-switch ${ checked && 'checked' }` } />
                    <span className={ `switch-label ${ !disabled ? 'disabled-label' : '' }` } onClick={ this._onLabelClick }>{ label }</span>
                </div>
                <small className="info-message">{ description }</small>
            </div>
        );
    }
}
