import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import ActivityTypeIcon from 'component/activity/ActivityTypeIcon';
import DateUtils from 'utils/DateUtils';

import './ActivityRow.scss';

export default class ActivityRow extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired
    }

    markupComponent =  ({ children, ...props }) => (
        <b { ...props }>{ children }</b>
    );

    render() {
        const { activity } = this.props;
        const name = activity.user.name;
        const date = DateUtils.format(activity.created);
        const message = activity.message;

        return (
            <div className="activity-row">
                <ActivityTypeIcon activityType={ activity.activity } />
                <div className="activity">
                    <div className="activity-information">
                        <p className="activity-submitter" title={ name}><b>{ name }</b></p>
                        <small className="activity-created">{ date }</small>
                    </div>
                    <p>
                        <Markdown 
                            options={ {
                                overrides: {
                                    strong: {
                                        component:  this.markupComponent,
                                    },
                                },
                                wrapper: Fragment 
                            } }
                        >
                            { message }
                        </Markdown>
                    </p>
                </div>
            </div>
        );
    }
}
