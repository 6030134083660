import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import Dropdown from 'component/Dropdown';

import './RFPBillTo.scss';

export default class RFPBillTo extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        size: PropTypes.oneOf(['small', 'medium'])
    };

    static defaultProps = {
        size: 'medium'
    }

    constructor(props) {
        super(props);

        this._showPopover = this._showPopover.bind(this);
        this._closePopover = this._closePopover.bind(this);
    }

    state = {
        showPopover: false
    };

    _showPopover() {
        this.setState({ showPopover: true });
    }

    _closePopover() {
        this.setState({ showPopover: false });
    }

    render() {
        const billToIds = this.props.rfp.billTo;
        const billTo = billToIds.length === 0 ? '-' : billToIds[0];

        let other = '';
        let popover = <></>;
        if (billToIds.length > 1) {
            other = `, +${ billToIds.length - 1 } more`;
            popover = (
                <div className="bill-to-popover">
                    <Dropdown 
                        id="bill-to-popover"
                        direction="bottom-right"
                        show={ this.state.showPopover }
                        centerArrowOnTrigger={ false }
                        onClose={ this._closePopover }
                        trigger={(
                            <small>
                                <a href="#!" className="popover-icon-container" onClick={ this._showPopover }>
                                    <FontAwesomeIcon icon={ info } className="icon" />
                                </a>
                            </small>
                        )}
                    >
                        <ul className="bill-to-popover-content">
                            { billToIds.map((id, index) => <li key={ `tooltip-bill-to-${ index }` }><small>{ id }</small></li>) }
                        </ul>
                    </Dropdown>
                </div>
            );
        }

        return (
            <div className={ `bill-to-ids ${ this.props.size }` }>
                <span className="first-bill-to" title={ billTo }>{ billTo }</span>
                { other }
                { popover }
            </div>
        );
    }
}
