import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CanAccess from 'component/CanAccess';
import CarrierStatusIcon  from 'component/carrier/CarrierStatusIcon';
import RemoveLaneCarrier from 'component/lane/RemoveLaneCarrier';
import EditCarrierNegotiatedRate from 'component/lane/EditCarrierNegotiatedRate';
import TableRow from 'component/table/custom/TableRow';
import TableCell from 'component/table/custom/TableCell';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';

import './LaneCarrierTableRow.scss';

export default class LaneCarrierTableRow extends Component {

    static propTypes = {
        lane: PropTypes.object.isRequired,
        laneCarrier: PropTypes.object.isRequired,
        canEdit: PropTypes.bool,
        editNegotiatedRate: PropTypes.func,
        removeCarrier: PropTypes.func,
        setShouldUpdate: PropTypes.func
    };

    static defaultProps = {
        canEdit: false,
        editNegotiatedRate: () => { /* */ },
        removeCarrier: () => { /* */ },
        setShouldUpdate: () => { /* */ }
    }

    _formActions() {
        const { laneCarrier, lane, editNegotiatedRate, removeCarrier, setShouldUpdate } = this.props;

        return (
            <>
                <EditCarrierNegotiatedRate
                    lane={ lane }
                    laneCarrier={ laneCarrier }
                    actionDisabled={ !laneCarrier.carrier.active }
                    editNegotiatedRate={ editNegotiatedRate } 
                />
                <RemoveLaneCarrier 
                    lane={ lane }
                    laneCarrier={ laneCarrier }
                    onRemoveCarrier={ removeCarrier }
                    setShouldUpdate={ setShouldUpdate }
                />
            </>
        );
    }

    render() {
        const { laneCarrier } = this.props;
        const name = `${ laneCarrier.carrier.name } (${ laneCarrier.carrier.businessId })`;
        const email = laneCarrier.carrier.email || '/';
        const negotiatedRate = laneCarrier.negotiatedRate ?  NumberUtils.prefixNumber(laneCarrier.negotiatedRate, '$', false, 'decimal') : '-';

        return (
            <TableRow className="sm-content-row" fontSize="small">
                <TableCell className="name" padding="small">
                    <div className="name-content">
                        <CarrierStatusIcon carrier={ laneCarrier.carrier } className="carrier-warning" />
                        <Link to={ `/carrier/${ laneCarrier.carrier.id }` } className="name-link">
                            <div className="name-ellipsis" title={ name }>
                                { name }
                            </div>
                        </Link>
                    </div>
                </TableCell>
                <TableCell className="negotiated-rate" padding="small" alignment="right">
                    { negotiatedRate }
                </TableCell>
                <TableCell className="email" padding="small">
                    <div className="email-ellipsis" title={ email }>
                        { email }
                    </div>  
                </TableCell>
                <TableCell className="date" padding="small" alignment={ this.props.canEdit ? 'left' : 'right' }>
                    { laneCarrier.dateAdded ? DateUtils.format(laneCarrier.dateAdded) : '/' }
                </TableCell>
                <CanAccess
                    action="routing-guide:write"
                    yes={
                        <TableCell className="actions" padding="small" alignment="center">
                            { this._formActions() }
                        </TableCell>
                    }
                />
            </TableRow>
        )
    }
}
