import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList as rfpIcon, faFileContract as tosIcon } from '@fortawesome/pro-solid-svg-icons';

import { DEFAULT_PAGE_NUMBER, RFP_AUCTION_STATUSES, SORT_DIRECTIONS } from 'common/constants';
import CanAccess from 'component/CanAccess';
import Button from 'component/Button';
import PageHeader from 'component/PageHeader';
import WSComponent from 'component/WSComponent';
import Search from 'component/filter/Search';
import Select from 'component/form/CustomSelect';
import LaneCarrierCSVUpload from 'component/lane/LaneCarrierCSVUpload';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Tab from 'component/navigation/Tab';
import Pagination from 'component/navigation/Pagination';
import CreateRFP from 'component/rfp/CreateRFP';
import RFPAuctionStatus from 'component/rfp/RFPAuctionStatus';
import RFPBillTo from 'component/rfp/RFPBillTo';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import TableCell from 'component/table/custom/TableCell';
import AuthorizationService from 'service/AuthorizationService';
import RFPAuctionApiService from 'service/api/RFPAuctionApiService';
import DateUtils from 'utils/DateUtils';
import RFPUtils from 'utils/RFPUtils';

import './RoutingGuideList.scss';

const BROKER_TOOLTIP = 'A list of all Routing Guides';
const MANAGER_TOOLTIP = 'A list of all Routing Guides, with an option to create new RFPs';
const CARRIER_TOOLTIP = 'A list of all Routing Guides which you have been invited to participate in';
const PAGINATION_VALUES = [
    { size: 8 },
    { size: 16 },
    { size: 24 }
];
const CARRIER_STATUS_VALUES = [
    { title: 'All', field: 'ALL' },
    { title: 'Round In Progress', field: RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS },
    { title: 'Round In Review', field: RFP_AUCTION_STATUSES.IN_REVIEW },
    { title: 'Confirmed', field: RFP_AUCTION_STATUSES.CONFIRMED },
    { title: 'Applied', field: RFP_AUCTION_STATUSES.APPLIED },
    { title: 'Completed', field: RFP_AUCTION_STATUSES.COMPLETED }
];
const BROKER_STATUS_VALUES = [
    { title: 'All', field: 'ALL' },
    { title: 'Applied', field: RFP_AUCTION_STATUSES.APPLIED },
    { title: 'Completed', field: RFP_AUCTION_STATUSES.COMPLETED }
];
const MANAGER_STATUS_VALUES = [
    { title: 'All', field: 'ALL' },
    { title: 'Not Published', field: RFP_AUCTION_STATUSES.PENDING },
    { title: 'Round In Progress', field: RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS },
    { title: 'Round In Review', field: RFP_AUCTION_STATUSES.IN_REVIEW },
    { title: 'Confirmed', field: RFP_AUCTION_STATUSES.CONFIRMED },
    { title: 'Applied', field: RFP_AUCTION_STATUSES.APPLIED },
    { title: 'Completed', field: RFP_AUCTION_STATUSES.COMPLETED }
];
const SORT_OPTIONS = {
    START_DATE: 'START_DATE',
    END_DATE: 'END_DATE',
    UPDATED: 'UPDATED'
};

export default class RoutingGuideList extends WSComponent {

    state = {
        loading: true,
        selectedStatus: null,
        statusValues: [],
        tooltip: null,
        rfp: {
            data: [],
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: 8,
            available: 0
        }
    };

    constructor(props) {
        super(props);

        this._fetchData = this._fetchData.bind(this);
        this._onPageNumberChange = this._onPageNumberChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
        this._onSelectStatus = this._onSelectStatus.bind(this);
        this._onCreateRFP = this._onCreateRFP.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();

        const autorizationService = AuthorizationService.instance();
        const managerView = await autorizationService.canAccess('rfp:read:all');
        const brokerView = await autorizationService.canAccess('routing-guide:read');
        const carrierView = await autorizationService.canAccess('rfp:read');

        let statusValues, tooltip;
        if (managerView) {
            statusValues = MANAGER_STATUS_VALUES;
            tooltip = MANAGER_TOOLTIP;
        } else if (brokerView) {
            statusValues = BROKER_STATUS_VALUES;
            tooltip = BROKER_TOOLTIP;
        } else if (carrierView) {
            statusValues = CARRIER_STATUS_VALUES;
            tooltip = CARRIER_TOOLTIP;
        }

        const status = this._getParamFromUrl(this.props, 'status');

        this.setState({
            selectedStatus: status ? statusValues.find(statusValue => statusValue.field === status)?.title : 'All',
            statusValues,
            tooltip
        });

        this._fetchData();
    }

    componentDidUpdate(prevProps) {
        const previousStatus = this._getParamFromUrl(prevProps, 'status');
        const status = this._getParamFromUrl(this.props, 'status');

        const previousSearch = this._getParamFromUrl(prevProps, 'rgName');
        const search = this._getParamFromUrl(this.props, 'rgName');

        const previousSort = this._getParamFromUrl(prevProps, 'sort');
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (prevProps.location.pathname !== this.props.location.pathname ||
            previousStatus !== status ||
            previousSearch !== search ||
            previousSort !== sort) {
            this.setState(
                previousState => ({
                    selectedStatus: previousState.statusValues.find(statusValue => statusValue.field === status)?.title,
                    rfp: {
                        ...previousState.rfp,
                        pageNumber: DEFAULT_PAGE_NUMBER
                    }
                }),
                this._fetchData
            );
        }
    }

    _getParamFromUrl(props, param) {
        const searchProp = (props.location || {}).search;
        return new URLSearchParams(searchProp).get(param);
    }

    _fetchData() {
        this.setState({
            loading: true
        });

        const auctionType = this.props.location.pathname.split('/').pop().toUpperCase();
        const sort = this._getParamFromUrl(this.props, 'sort');
        const status = (this.state.statusValues.find(statusValue => statusValue.title === this.state.selectedStatus) || {}).field;

        const params = {
            pageNumber: this.state.rfp.pageNumber,
            pageSize: this.state.rfp.pageSize,
            rfpAuctionType: auctionType !== 'LIST' ? auctionType : null,
            rfpAuctionStatus: status !== 'ALL' ? status : null,
            rgName: this._getParamFromUrl(this.props, 'rgName'),
            sort: sort ? [sort, ""] : null
        };

        RFPAuctionApiService.list(params)
            .then(rfp => this.setState({
                    rfp,
                    loading: false
                })
            );
    }

    _onPageNumberChange(pageNumber) {
        this.setState(
            previousState => ({
                rfp: {
                    ...previousState.rfp,
                    pageNumber
                }
            }),
            this._fetchData
        );
    }

    _onPageSizeChange(pageSize) {
        this.setState(
            previousState => ({
                rfp: {
                    ...previousState.rfp,
                    pageSize
                }
            }),
            this._fetchData
        );
    }

    _onSelectStatus(_value, status) {
        if (status) {
            status = JSON.parse(status.key);
            const searchParams = new URLSearchParams((this.props.location || {}).search);

            if ('ALL' === status.field) {
                this.setState({
                    selectedStatus: null
                });

                searchParams.delete('status');
            } else {
                this.setState({
                    selectedStatus: status.title
                });

                searchParams.set('status', status.field);
            }

            this.props.history.push({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSort(value, direction) {
        const searchParams = new URLSearchParams((this.props.location || {}).search);

        if (SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete('sort');
        } else {
            searchParams.set('sort', `${ value },${ direction }`);
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value) {
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (!sort) {
            return SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }

    _onCreateRFP() {
        const currentPath = this.props.location.pathname;
        const newPath = '/rfp/list/all';
        
        if (currentPath === newPath) {
            this._fetchData();
        } else {
            this.props.history.push({
                pathname: newPath
            });
        }
    }

    render() {
        const uploadCarriersNode = (
            <CanAccess
                action="routing-guide:write"
                yes={
                    <div className="action-field">
                        <LaneCarrierCSVUpload bulk={ true } size="regular" />
                    </div>
                }
            />
        );

        const rfpActions = (
            <CanAccess
                action="rfp:write"
                yes={
                    <div className="action-field">
                        <CreateRFP onUpdate={ this._onCreateRFP } />
                    </div>
                }
                no={
                    <CanAccess
                        action="rfp:read"
                        yes={
                            <div className="action-field">
                                <Link to="/rfp/terms-of-service">
                                    <Button type="tertiary"
                                            size="regular"
                                            leftIcon={ tosIcon }>
                                        Site Terms of Service
                                    </Button>
                                </Link>
                            </div>
                        }
                    />
                }
            />
        );

        const availableActions = (
            <div className="actions-container">
                <div className="action-field search">
                    <Search placeholder="Search by Routing Guide name..." label="Search" fieldName="rgName" allowClear={ true } replace={ false } />
                </div>
                <div className="action-field status">
                    <Select
                        selectedValue={ this.state.selectedStatus || 'All' }
                        onSelect={ this._onSelectStatus }
                        values={ this.state.statusValues }
                        label="Status"
                    />
                </div>
                { uploadCarriersNode }
                { rfpActions }
            </div>
        );

        const searchResultsDisplayed = this._getParamFromUrl(this.props, 'rgName');

        let content, pagination;
        if (this.state.rfp.data.length > 0) {
            content = (
                <Table className="rfp-table">
                    <TableRow isHeader={ true }>
                        <TableHeaderCell className="rg-name">Routing Guide</TableHeaderCell>
                        <CanAccess
                            action={ ['rfp:read:all', 'routing-guide:read'] }
                            yes={ <TableHeaderCell className="bill-to">Bill To Ids</TableHeaderCell> }
                        />
                        <TableHeaderCell
                            className="effective-on"
                            sortable={ true }
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.START_DATE, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.START_DATE) }
                        >
                            Effective on
                        </TableHeaderCell>
                        <TableHeaderCell
                            className="expires-on"
                            sortable={ true }
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.END_DATE, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.END_DATE) }
                        >
                            Expires on
                        </TableHeaderCell>
                        <TableHeaderCell className="status">Status</TableHeaderCell>
                        <TableHeaderCell
                            className="update"
                            alignment="right"
                            sortable={ true }
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.UPDATED, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.UPDATED) }
                        >
                            Last Update
                        </TableHeaderCell>
                    </TableRow>
                    { this.state.rfp.data.map(rg => {
                        const round = RFPUtils.latestRound(rg);

                        return (
                            <TableRow key={ rg.id }>
                                <TableCell className="rg-name-content">
                                    <Link to={ `/rfp/${ rg.id }` }>{ rg.name }</Link>
                                </TableCell>
                                <CanAccess
                                    action={ ['rfp:read:all', 'routing-guide:read'] }
                                    yes={ <TableCell><RFPBillTo rfp={ rg } size="small" /></TableCell> }
                                />
                                <TableCell>{ DateUtils.formatDate(new Date(rg.startDate)) }</TableCell>
                                <TableCell>{ DateUtils.formatDate(new Date(rg.endDate)) }</TableCell>
                                <TableCell>
                                    <RFPAuctionStatus id={ `rfp-card-status-${ rg.id }` } status={ rg.status } round={ round } withTitle={ false } />
                                </TableCell>
                                <TableCell alignment="right">{ DateUtils.format(new Date(rg.updated)) }</TableCell>
                            </TableRow>
                        );
                    }) }
                </Table>
            );

            pagination = (
                <Pagination
                    pageNumber={ this.state.rfp.pageNumber }
                    pageSize={ this.state.rfp.pageSize }
                    available={ this.state.rfp.available }
                    paginationValues={ PAGINATION_VALUES }
                    onSetPage={ this._onPageNumberChange }
                    onSetPageSize={ this._onPageSizeChange }
                    colorTheme="light"
                />
            );
        } else if (!this.state.loading && !searchResultsDisplayed) {
            content = (
                <div className="rfp-list empty-list">
                    <FontAwesomeIcon className="rfp-icon" icon={ rfpIcon } />
                    <CanAccess
                        action={ ['routing-guide:read', 'rfp:read:all'] }
                        yes={
                            <>
                                <h5>No Routing Guides Here</h5>
                                <p>There are currently no available Routing Guides to show on this screen.</p>
                            </>
                        }
                        no={
                            <>
                                <h5>No Routing Guides Here</h5>
                                <p>Your e-mail invitation for the upcoming RFP will be sent soon.</p>
                            </>
                        }
                    />
                </div>
            );
        } else if (!this.state.loading) {
            content = (
                <div className="rfp-list empty-list">
                    <FontAwesomeIcon className="rfp-icon" icon={ rfpIcon } />
                    <h5>No Routing Guides Here</h5>
                    <p>No results matched your search.</p>
                </div>
            );
        }

        let ongoingAuctionsTab = null;
        if (AuthorizationService.instance().canUserAccess(this.props.account, ['rfp:read:all', 'rfp:read'])) {
            ongoingAuctionsTab = (
                <Tab id="ongoing" title="RFP In Progress">
                    { availableActions }
                    { content }
                    { pagination }
                </Tab>
            );
        }

        return (
            <div className="page rfp-list-page">
                <PageHeader 
                    title="Routing Guides" 
                    tooltip={ this.state.tooltip }
                />
                <div className="rfp-tabs">
                    <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                        <Tab default id="all" title="All Routing Guides">
                            { availableActions }
                            { content }
                            { pagination }
                        </Tab>
                        { ongoingAuctionsTab }
                        <Tab id="applied" title="Applied">
                            { availableActions }
                            { content }
                            { pagination }
                        </Tab>
                        <Tab id="completed" title="Completed">
                            { availableActions }
                            { content }
                            { pagination }
                        </Tab>
                    </NavigableTabs>
                </div>
            </div>
        );
    }
}
