import { Component } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import Popup from 'component/Popup';
import Button from 'component/Button';
import RestService from 'service/RestService';

export default class RFPConfirm extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        disabled: false,
        onSubmit: () => {/**/}
    }

    constructor(props) {
        super(props);

        this._onSubmit = this._onSubmit.bind(this);
        this._showPopup = this._showPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
    }

    state = {
        showPopup: false
    };

    _onSubmit() {
        RestService.instance()
            .post(`auction/rfp/${ this.props.rfp.id }/confirm`)
            .then(() => this._closePopup())
            .then(() => this.props.onSubmit());
    }

    _showPopup() {
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.setState({ showPopup: false });
    }

    render() {
        return (
            <Popup
                id="rfp-confirm-popup"
                show={ this.state.showPopup }
                onClose={ this._closePopup }
                trigger={
                    <Button type="primary"
                            size="small"
                            disabled={ this.props.disabled }
                            leftIcon={ faPlusCircle }
                            onClick={ this._showPopup }>
                        Confirm RFP
                    </Button>
                }>
                <div className="rfp-confirm-popup">
                    <p className="heading">Confirm RFP</p>

                    <p className="description">
                        Are you sure you want to confirm this RFP? Once confirmed, you cannot make any further changes regarding candidates' positions and awarded volumes. You will still be able to change the RFP's name, effective dates, duration and Bill To IDs.
                    </p>

                    <p className="description">
                        After this, you will need to apply this RFP as the effective routing guide.
                    </p>

                    <div className="buttons-wrapper">
                        <Button type="tertiary"
                                size="small"
                                onClick={ this._closePopup }>
                            Cancel
                        </Button>

                        <Button onClick={ this._onSubmit } size="small">
                            Confirm
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
