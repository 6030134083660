import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding lanes
 */
export default class LaneApiService {

  /**
   * Get all lanes
   * 
   * @param search 
   * @return {Promise}
   */
  static get(search) {
    return RestService.instance().get(`lanes`, { search });
  }
}
