import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy as winnerIcon } from '@fortawesome/pro-light-svg-icons';

import { BID_STATUSES, FORM_STATUSES, MAX_PRICE } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomTextInput from 'component/form/CustomTextInput';
import CustomNumberInput from 'component/form/CustomNumberInput';
import FormStatusModal from 'component/form/FormStatusModal';
import TenderAuctionBidApiService from 'service/api/TenderAuctionBidApiService';
import PriceUtils from 'utils/PriceUtils';
import NumberUtils from 'utils/NumberUtils';
import StringUtils from 'utils/StringUtils';

import './AddOnDemandCarrierPopup.scss';

export default class AddOnDemandCarrierPopup extends Component {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        onClosePopup: PropTypes.func,
        onAddOnDemandCarrier: PropTypes.func
    };

    static defaultProps = {
        onClosePopup: () => { /* */ },
        onAddOnDemandCarrier: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._onBusinessIdChanged = this._onBusinessIdChanged.bind(this);
        this._onPriceChanged = this._onPriceChanged.bind(this);
        this._onSendOffer = this._onSendOffer.bind(this);
    }

    state = {
        businessId: undefined,
        price: undefined,
        showPopup: false,
        popupState: undefined,
        errorTitle: '',
        errorMessage: '' 
    };

    _onOpenPopup() {
        this.setState({ showPopup: true });
    }

    _onClosePopup() {
        if (FORM_STATUSES.LOADING !== this.state.popupState) {
            this.setState({ 
                showPopup: false,
                popupState: undefined,
                businessId: undefined,
                price: undefined,
                errorMessage: '',
                errorTitle: ''
            });

            this.props.onClosePopup();
        }
    }

    _onBusinessIdChanged(event) {
        this.setState({ businessId: event.target.value });
    }

    _onPriceChanged(price) {
        this.setState({ price });
    }

    _onSendOffer() {
        this.setState({ popupState: FORM_STATUSES.LOADING });

        TenderAuctionBidApiService.addOnDemandOffer(this.props.auction.id, this.state.businessId, this.state.price)
            .then(onDemandOffer => {
                if (BID_STATUSES.NOT_SENT === onDemandOffer.status) {
                    this.setState({ 
                        popupState: FORM_STATUSES.ERROR,
                        errorTitle: 'TMW Sync Failed',
                        errorMessage: 'Offer was not sent to carrier due to their terminated status in the TMW.'
                     });
                } else {
                    this.setState({ popupState: FORM_STATUSES.SUCCESS });
                }
            })
            .then(this.props.onAddOnDemandCarrier)
            .catch((error) => {
                const errorMessage = error.response.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data;
                this.setState({ 
                    popupState: FORM_STATUSES.ERROR, 
                    errorTitle: 'Failed to Send Offer',
                    errorMessage
                });
            });
    }

    _formOnDemandCarrierButton() {
        return (
            <div href="#popup-on-demand-carrier" onClick={ this._onOpenPopup } className="dropdown-item">
                <FontAwesomeIcon className="action-icon" icon={ winnerIcon }/>
                <small className="action-name add-on-demand-carrier-action-name">Add On-Demand Carrier</small>
            </div>
        );
    }

    _formAddOnDemandCarrierForm() {
        const { price, businessId } = this.state;
        const perMilePrice = price ? PriceUtils.calculatePerMilePrice(price, this.props.auction.load.routingGuideLane.distance) : null;
        const perMilePriceToShow = perMilePrice ? NumberUtils.prefixNumber(perMilePrice, '$', false, 'decimal') : '-';
        const disableSendOffer = StringUtils.isBlank(businessId) || !price;

        return (
            <div className="broker-actions-popup add-on-demand-carrier-popup">
                <h6 className="heading">Add On-Demand Carrier</h6>
                <p className="description">
                    To add a new on-demand carrier, please enter the required information. 
                    After you click <b>Send Offer</b>, an offer will be sent to the carrier and if they accept it, they will be declared a winner on this load.
                </p>

                <p className="description">
                    Please note that sending an on-demand offer will stop the current tendering process.
                </p>

                <CustomTextInput
                    label="TMW Carrier Code"
                    placeholder="Enter TMW Carrier Code..."
                    value={ businessId }
                    fieldName="businessId"
                    onValueChange={ this._onBusinessIdChanged }
                />
                <br />
                <CustomNumberInput
                    label="Offer Price"
                    placeholder="0.00"
                    prefix="$"
                    initialValue={ price?.toString() }
                    value={ price?.toString() }
                    onChange={ this._onPriceChanged }
                    min={ 0 }
                    max={ MAX_PRICE }
                    decimals={ 2 }
                />
                <small className="per-mile-price">Per Mile Price: { perMilePriceToShow }</small>

                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this._onClosePopup } >
                        Discard
                    </Button>

                    <Button disabled={ disableSendOffer } onClick={ this._onSendOffer }>
                        Send Offer
                    </Button>
                </div>
            </div>
        );
    }

    _formLoadingPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.LOADING }>
               <div>
                    <h6>Sending Offer</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </FormStatusModal>
        );
    }

    _formSuccessPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                <div className="add-on-demand-carrier-popup">
                    <h6>Offer Sent to Carrier</h6>
                    <p>An on-demand offer has been sent to the carrier with carrier code <span className="carrier-name">{ this.state.businessId }</span>.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formErrorPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClosePopup }>
                <div>
                    <h6>{ this.state.errorTitle }</h6>
                    <p>{ this.state.errorMessage }</p>
                </div>
            </FormStatusModal>
        );
    }

    render() {
        const { popupState } = this.state;
        let content;

        switch (popupState) {
            case FORM_STATUSES.LOADING:
                content = this._formLoadingPopup();
                break;
            case FORM_STATUSES.SUCCESS:
                content = this._formSuccessPopup();
                break;
            case FORM_STATUSES.ERROR:
                content = this._formErrorPopup();
                break;
            default:  
                content = this._formAddOnDemandCarrierForm();      
        }
    
        return (
            <Popup
                id="popup-on-demand-carrier"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._onClosePopup }
                trigger={ this._formOnDemandCarrierButton() }
            >
                { content }
            </Popup>
        );
    }
}
