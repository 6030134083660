import { Component } from 'react';
import PropTypes from 'prop-types';

import { LANE_TYPE_PROPERTIES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import Banner from 'component/Banner';
import CustomSelect from 'component/form/CustomSelect';
import CustomCheckbox from 'component/form/CustomCheckbox';
import Separator from 'component/Separator';
import RestService from 'service/RestService';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';

import './RFPOfferActions.scss';

export default class RFPOfferActions extends Component {

    static propTypes = {
        bid: PropTypes.object,
        rfp: PropTypes.object,
        rejectReasons: PropTypes.array,
        bulk: PropTypes.bool,
        version: PropTypes.number,
        onActionPerformed: PropTypes.func
    };

    static defaultProps = {
        bid: null,
        rfp: null,
        rejectReasons: [],
        bulk: false,
        version: 0,
        onActionPerformed: () => {/**/}
    };

    state = {
        showAcceptPopup: false,
        showRejectPopup: false,
        rejectReason: null,
        acceptTermsAndConditions: false
    };

    constructor(props) {
        super(props);

        this._onAccept = this._onAccept.bind(this);
        this._onReject = this._onReject.bind(this);
        this._onReasonSelected = this._onReasonSelected.bind(this);
        this._onOpenRejectPopup = this._onOpenRejectPopup.bind(this);
        this._onCloseRejectPopup = this._onCloseRejectPopup.bind(this);
        this._onOpenAcceptPopup = this._onOpenAcceptPopup.bind(this);
        this._onCloseAcceptPopup = this._onCloseAcceptPopup.bind(this);
        this._onAcceptTermsAndConditionsCheckboxChange = this._onAcceptTermsAndConditionsCheckboxChange.bind(this);
    }

    componentDidMount() {
        if (this.props.bulk) {
            this._fetchCanConfirm();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.version !== prevProps.version) {
            this._fetchCanConfirm();
        }
    }

    _fetchCanConfirm() {
        if (this.props.rfp) {
            RestService.instance()
                .get(`auction/rfp/${ this.props.rfp.id }/bid/can-confirm`)
                .then(response => this.setState({
                    canConfirm: response.canConfirm,
                    numberOfConfirmableBids: response.numberOfConfirmableBids
                }));
        }
    }

    _onAccept() {
        let api;
        if (this.props.bulk) {
            const rfp = this.props.rfp;
            api = `auction/rfp/${ rfp.id }/bid/accept-all`;
        } else {
            const bid = this.props.bid;
            api = `auction/rfp/${ bid.auction.id }/lane/${ bid.lane.id }/bid/${ bid.id }/accept`;
        }

        RestService.instance()
            .post(api)
            .then(() => this._onCloseAcceptPopup())
            .then(() => this._fetchCanConfirm())
            .then(() => this.props.onActionPerformed());
    }

    _onReject() {
        let api;
        if (this.props.bulk) {
            const rfp = this.props.rfp;
            api = `auction/rfp/${ rfp.id }/bid/reject-all`;
        } else {
            const bid = this.props.bid;
            api = `auction/rfp/${ bid.auction.id }/lane/${ bid.lane.id }/bid/${ bid.id }/reject`;
        }

        const body = { id: this.state.rejectReason.id };

        RestService.instance()
            .post(api, body)
            .then(() => this._onCloseRejectPopup())
            .then(() => this._fetchCanConfirm())
            .then(() => this.props.onActionPerformed())
    }

    _onReasonSelected(value, option) {
        this.setState({ rejectReason: JSON.parse(option.key) });
    }

    _onOpenRejectPopup() {
        this.setState({ showRejectPopup: true });
    }

    _onCloseRejectPopup() {
        this.setState({ showRejectPopup: false, rejectReason: null });
    }

    _onOpenAcceptPopup() {
        this.setState({ showAcceptPopup: true });
    }

    _onCloseAcceptPopup() {
        this.setState({ showAcceptPopup: false, acceptTermsAndConditions: false });
    }

    _onAcceptTermsAndConditionsCheckboxChange() {
        this.setState(prevState => ({
            acceptTermsAndConditions: !prevState.acceptTermsAndConditions
        }));
    }

    _offerDetails() {
        const bid = this.props.bid;
        const origin = LaneUtils.formatRegion(bid.lane.lane.origin);
        const destination = LaneUtils.formatRegion(bid.lane.lane.destination);
        const mileage = `${ bid.lane.distance } mi.`;
        const perMilePrice = `$${ NumberUtils.formatWithDecimalNotation(bid.price) }`;
        const perLoadPrice = `$${ NumberUtils.formatWithDecimalNotation(bid.price * bid.lane.distance) }`;
        const laneType = LANE_TYPE_PROPERTIES[bid.lane.lane.type].field;
        const loadedOn = LANE_TYPE_PROPERTIES[bid.lane.lane.type].shortcut

        return { laneType, origin, destination, mileage, perMilePrice, perLoadPrice, awardedVolume: bid.awardedVolume, loadedOn };
    }

    _rejectPopupContent() {
        let content;
        let buttonText;
        let buttonType;
        let title;

        if (this.props.bulk) {
            buttonText = 'Reject All';
            buttonType = 'danger';
            title = <h6 className="heading">Reject All Offers</h6>;
            content = (
                <p className="description">
                    Are you sure you want to reject all <b>({ this.state.numberOfConfirmableBids })</b> offers? Note that doing this will reject all the offers you haven't already responded to.
                </p>
            );
        } else {
            const { laneType, origin, destination, mileage, perMilePrice, perLoadPrice, awardedVolume, loadedOn } = this._offerDetails();
            
            buttonText = 'Reject';
            buttonType = 'tertiary';
            title = <h6 className="heading">Reject Offer</h6>;
            content = (
                <div className="description">
                    ShipEX Logistics is offering you <b>{ awardedVolume }</b> loads per year on the <b>{ origin }</b> to <b>{ destination }</b> lane.<br /><br />
                    The load details are as follows:<br />
                    <ul className="offer-details">
                        <li>Rate: { perMilePrice } per mile, { perLoadPrice } all-in</li>
                        <li>Mileage: { mileage }</li>
                        <li>EQ Type: { laneType } load, can be loaded on a { loadedOn }</li>
                    </ul>
                    Are you sure you wish to reject this offer?
                </div>
            );
        }

        return { content, buttonText, buttonType, title };
    }

    _acceptPopupContent() {
        let content;
        let buttonText;
        let title;

        if (this.props.bulk) {
            buttonText = 'Accept All';
            title = <h6 className="heading">Accept All Offers</h6>;
            content = (
                <p className="description">
                    Are you sure you want to accept all <b>({ this.state.numberOfConfirmableBids })</b> offers? Note that doing this will accept all the offers you haven't already responded to.
                </p>
            );
        } else {
            const { laneType, origin, destination, mileage, perMilePrice, perLoadPrice, awardedVolume, loadedOn } = this._offerDetails();
            
            buttonText = 'Accept';
            title = <h6 className="heading">Accept { awardedVolume === 0 ? 'Backup ' : '' }Offer</h6>;
            content = (
                <div className="description">
                    ShipEX Logistics is offering you <b>{ awardedVolume }</b> loads per year on the <b>{ origin }</b> to <b>{ destination }</b> lane.<br /><br />
                    The load details are as follows:<br />
                    <ul className="offer-details">
                        <li>Rate: { perMilePrice } per mile, { perLoadPrice } all-in</li>
                        <li>Mileage: { mileage }</li>
                        <li>EQ Type: { laneType } load, can be loaded on a { loadedOn }</li>
                    </ul>
                    Do you wish to accept this offer?
                </div>
            );
        }

        return { content, buttonText, title };
    }

    _acceptZeroWarningMessage() {
        let warningMessage = <></>;
        if (!this.props.bulk && this.props.bid.awardedVolume === 0) {
            warningMessage = (
                <div className="rfp-warning-message">
                    <Banner 
                        type="warn" 
                        content="By accepting this backup offer, you accept to receive any potential tenders for this lane at your backup rate." />
                </div>
            );
        } else if (this.props.bulk) {
            warningMessage = (
                <div className="rfp-warning-message">
                    <Banner 
                        type="warn" 
                        content="In case you have been offered 0 volume on a certain lane, by accepting that backup offer, you accept to receive any potential tenders for that lane at your backup rate." />
                </div>
            );
        }

        return warningMessage;
    }

    _rejectPopup() {
        const popupContent = this._rejectPopupContent();
        const { content, buttonText, buttonType, title } = popupContent;

        return (
            <Popup
                id="rfp-reject-offer"
                show={ this.state.showRejectPopup }
                size="medium"
                onClose={ this._onCloseRejectPopup }
                trigger={
                    <Button type={ buttonType }
                            size="small"
                            className="rfp-reject-button"
                            onClick={ this._onOpenRejectPopup }>
                        { buttonText }
                    </Button>
                }>
                <div className="rfp-offer-popup">
                    { title }
                    { content }
                    <CustomSelect 
                        label="Select reason"
                        selectedValue={ (this.state.rejectReason || {}).reason }
                        onSelect={ this._onReasonSelected }
                        values={ this.props.rejectReasons }
                        fieldName="reason"
                    />

                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onCloseRejectPopup }>Cancel</Button>
                        <Button type="danger" disabled={ !this.state.rejectReason } onClick={ this._onReject }>{ buttonText }</Button>
                    </div>
                </div>
            </Popup>
        );
    }

    _acceptPopup() {
        const popupContent = this._acceptPopupContent();
        const { content, buttonText, title } = popupContent;
        const warningMessage = this._acceptZeroWarningMessage();

        const termsAndConditions = (
            <>
                <p className="subheading">ShipEX Dedicated RFP Terms and Conditions</p>
                <div className="accept-popup-terms-and-conditions">
                    <p className="subheading-title">
                        <span>Operational Detail</span>
                        <span>Updated March 2023</span>
                    </p>
                    <p><b>Tender Process</b></p>
                    <br />
                    <ul>
                        <li>Over 70% of all loads will be tendered more than 5-7 days prior to ship date. All remaining volume will be tendered within 48-72 hours prior to ship date.</li>
                        <ul>
                            <li>This will be done via email.</li>
                        </ul>
                        <li>Rate confirmation will not show appointment details until driver information for each load is provided and entered in our TMS system.</li>
                        <ul>
                            <li>A pickup number for the load and all other details will then be released to awarded carrier.</li>
                        </ul>
                        <li>If load is not accepted within 90 minutes, tender will be retendered to secondary carrier.</li>
                        <li>All loads are live load and unload.</li>
                        <li>Appointments are preset and show on the rate confirmations (after driver information is input into TMS).</li>
                        <ul>
                            <li>For any changes needed to appointments please reach to <a href="mailto:dispatch@shipexlogistics.com">dispatch@shipexlogistics.com</a> during business hours, as well as afterhours.</li>
                        </ul>
                    </ul>

                    <p><b>Load Tracking</b></p>
                    <br />
                    <ul>
                        <li>
                            ALL loads are required to have Four Kites tracking. If Carrier has not connected their ELD through Four Kites, a text message will be sent to each driver for acceptance. It is the carrier's responsibility to provide check calls/tracking updates in the event of a system failure. Failure to comply with tracking requirements may result in a fine of $250 per load.
                        </li>
                    </ul>

                    <p><b>Rate Confirmation Details</b></p>
                    <br />
                    <p>Carriers will receive a ShipEX Logistics load confirmation with details for each load as follows:</p>
                    <ul>
                        <li>Four Kites is required on every single load PRIOR to arrival at the shipper. Please remember to have the driver(s) download the app to allow for proper tracking to take place.</li>
                        <li>If driver/s phone is not compatible with Four Kites, carrier must complete a check-call by 0900 MST, as well as 1500 MST (daily). This must be done via EMAIL ONLY. A missed check call will result in a $50 fine per occurrence.</li>
                        <li>A $250 fine will be imposed if a Carrier calls Shipper or Receiver to reschedule appointments. Any date/time change requests MUST go through ShipEX Logistics PRIOR to missing the given appointment(s).</li>
                        <li>Missed or late pickup/delivery appointments with no prior warning will be subject to a re-evaluation of awarded volume and if necessary, a $250 penalty. Carrier will also be responsible for any additional cost that may be incurred for missed or late pick up/deliveries from shippers or consignees.</li>
                        <li>Before departing shipper location, Carrier's driver MUST unsure that load has been sealed by shipper. It is the carrier's responsibility to make sure the load is sealed. If there are any issues, do not leave the facility and call ShipEX Logistics immediately.</li>
                        <li>Receiver MUST sign seal intact on the bill of lading before opening trailer. Call ShipEX Logistics immediately if receiver will not sign.</li>
                        <li>It is Carrier's responsibility to ensure the CORRECT/MATCHING bill of lading is signed by the receiver prior to departing. The bill of lading should always match the rate confirmation issued.</li>
                        <li>Carrier is responsible for immediate notification to ShipEX Logistics of any overage, shortage, damage, or mis-shipped product. Carrier agrees to assume all liability of loss and/or claim.</li>
                        <li>Carrier's authorized signature on this confirmation verifies carrier has insurance in the limits required by Broker for any loss or damage to shipment/liability to the general public.</li>
                        <li>Hours of Service are the sole responsibility of the CARRIER & CARRIER agrees to comply with all local, state and federal laws & regulations including but not limited to those set forth by DOT & other governing agencies.</li>
                        <li>Carrier agrees that any refrigerated equipment will meet the Transport Refrigeration Unit (TRU) regulations. If carrier fails to do so Carrier will be liable for all fines associated with TRU's laws and regulations.</li>
                        <li>Arrival & Departure times at both shipper and receiver must be reported to ShipEX Logistics.</li>
                        <li>Any directions given by ShipEX Logistics or its Customers, whether orally and/or electronically, are for informational purposes only. It is the Carrier's sole responsibility to confirm that it may lawfully and safely operate its vehicle and its contents over any road, highway, bridge, and/or route. Carrier shall be solely responsible for any fines, penalties, or citations that may be levied as a result of operating its vehicle equipment and its content in any way that may be found to be in violation of any regulation, law, or ordinance.</li>
                    </ul>

                    <p><b>Claims</b></p>
                    <br />
                    <ul>
                        <li>Upon delivery, if there are any shortages/overages or damaged goods, please forward a copy of the BOL, with notations from consignee, over to your dispatcher AND to <a href="mailto:claims@shipexlogistics.com">claims@shipexlogistics.com</a>.</li>
                        <li>In case of a rejected shipment, please contact our office immediately at 801-973-4411 and/or <a href="mailto:dispatch@shipexlogistics.com">dispatch@shipexlogistics.com</a>.</li>
                    </ul>

                    <p><b>Invoicing and Payment</b></p>
                    <br />
                    <ul>
                        <li>Upon completion of loads please submit your invoice, a clear signed copy of the matching bill of lading and the rate confirmation to ShipEX Logistics at: <a href="mailto:accounting@shipex.com">accounting@shipex.com</a>.</li>
                        <li>For payment status or any other related questions please send an email to <a href="mailto:payments@shipex.com">payments@shipex.com</a>.</li>
                        <li>Any missing PODs requestions will be issued a $150 administrative fee per occurrence.</li>
                    </ul>
                </div>
            </>
        );

        return (
            <Popup
                id="rfp-accept-offer"
                size="large"
                show={ this.state.showAcceptPopup }
                onClose={ this._onCloseAcceptPopup }
                trigger={
                    <Button type="primary"
                            size="small"
                            className="rfp-accept-button"
                            onClick={ this._onOpenAcceptPopup }>
                        { buttonText }
                    </Button>
                }>
                <div className="rfp-offer-popup">
                    { title }
                    <br/>
                    { content }
                    { warningMessage }
                    { termsAndConditions }

                    <Separator />

                    <div className="accept-popup-actions">
                        <CustomCheckbox
                            onChange={ this._onAcceptTermsAndConditionsCheckboxChange }
                            checked={ this.state.acceptTermsAndConditions }
                            text="I accept these Terms and Conditions" 
                        />
                        <div className="buttons-wrapper">
                            <Button type="tertiary" onClick={ this._onCloseAcceptPopup }>Cancel</Button>
                            <Button 
                                type="primary" 
                                disabled={ !this.state.acceptTermsAndConditions }
                                onClick={ this._onAccept }
                            >
                                { buttonText }
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        if (this.props.bulk && !this.state.canConfirm) {
            return <></>;
        }
        
        return (
            <div className="rfp-offer-popups">
                { this._rejectPopup() }
                { this._acceptPopup() }
            </div>
        );
    }
}
