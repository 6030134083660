import { Component, createRef, cloneElement } from 'react';
import PropTypes from 'prop-types';

import RestService from 'service/RestService';

export default class DownloadFileComponent extends Component {

    static propTypes = {
        reference: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                endpoint: PropTypes.string,
                params: PropTypes.object
            }), 
            PropTypes.arrayOf(
                PropTypes.shape({
                    endpoint: PropTypes.string,
                    params: PropTypes.object
                })
            )
        ]).isRequired,
        className: PropTypes.string,
        filename: PropTypes.string, // If not sent, filename from the content disposition header will be used
        trigger: PropTypes.element,
        isStaticResource: PropTypes.bool,
        disabled: PropTypes.bool,
        canceled: PropTypes.bool,
        onDownload: PropTypes.func,
        onDownloadFailed: PropTypes.func
    }

    static defaultProps = {
        className: '',
        filename: null,
        trigger: <></>,
        isStaticResource: true,
        disabled: false,
        canceled: false,
        onDownload: () => { /* */ },
        onDownloadFailed: () => { /* */ }
    }

    _link = createRef();
    _cancelDownload = null;
    _canceled = false;

    componentDidUpdate(prevProps) {
        if (this.props.canceled && !prevProps.canceled && this._cancelDownload) {
            this._canceled = true;
            this._cancelDownload();
        }
    }

    componentWillUnmount() {
        this._resetLink();

        if (this._cancelDownload) {
            this._canceled = true;
            this._cancelDownload();
        }
    }

    async _download() {
        if (this.props.disabled) {
            return;
        }
        
        let references = this.props.reference;
        if (typeof references === 'string' || references instanceof String) {
            references = [{ endpoint: references }];
        } else if (!Array.isArray(this.props.reference)) {
            references = [references];
        }

        for (const reference of references) {
            this._resetLink();

            if (this._canceled) {
                return;
            }

            const { responsePromise, cancel } = await RestService.instance().download(reference.endpoint, this.props.isStaticResource, reference.params);
            this._cancelDownload = cancel;

            try {
                const response = await responsePromise;
                
                if (this._canceled) {
                    return;
                }

                const blob = new Blob([response.data], { type: 'blob' });
                const href = window.URL.createObjectURL(blob);
        
                this._link.current.download = this._getFilename(response);
                this._link.current.href = href;
        
                this._link.current.click();
            } catch (error) {
                this.props.onDownloadFailed();
                console.error('Failed to download resource.', error);
            }

            this._cancelDownload = null;
        }

        this.props.onDownload();
    }

    _getFilename(response) {
        let filename = this.props.filename || 'file';
        const contentDispositionHeader = response.headers['content-disposition'];

        if (contentDispositionHeader) {
            filename = contentDispositionHeader.split('filename=')[1];
            if (filename && filename.startsWith('"') && filename.endsWith('"')) {
                filename = filename.slice(1, filename.length - 1);
            }
        }

        return filename;
    }

    _resetLink() {
        if ((this._link.current || {}).href) {
            window.URL.revokeObjectURL(this._link.current.href);
            this._link.current.href = null;
        }
    }

    render() {
        // eslint-disable-next-line
        const downloadLink = (
            <a role="button" href="#!" className={ this.props.className } onClick={ this._download.bind(this) }>
                { cloneElement(this.props.trigger, { disabled: this.props.disabled }) }
            </a>
        );

        // eslint-disable-next-line
        const hiddenLink = <a style={{ display: 'none' }} ref={ this._link } />;

        return (
            <>
                { downloadLink }
                { hiddenLink }
            </>
        );
    }
}
