import { Component } from 'react';
import PropTypes from 'prop-types';

import ActivityLogDrawer from 'component/activity/ActivityLogDrawer';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, FINISHED_RFP_AUCTION_STATUSES } from 'common/constants';
import CarrierActivityLogApiService from 'service/api/CarrierActivityLogApiService';

export default class RFPActivityLog extends Component {
    static propTypes = {
        rfp: PropTypes.object.isRequired,
        carrierId: PropTypes.string
    }

    static defaultProps = {
        carrierId: null
    }

    constructor(props) {
        super(props);

        this._incrementPageNumber = this._incrementPageNumber.bind(this);
    }

    state = {
        activities: [],
        hasMore: false,
        isLoading: false,
        pageNumber: DEFAULT_PAGE_NUMBER,
        createdBefore: new Date()
    }

    componentDidMount() {
        this._fetchRFPActivities();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pageNumber !== this.state.pageNumber || prevProps.rfp !== this.props.rfp) {
            this._fetchRFPActivities();
        }
    }

    _fetchRFPActivities() {
        if (this.props.carrierId) {
            this.setState({ isLoading: true });
            
            const props = {
                pagination: {
                    pageNumber: this.state.pageNumber,
                    pageSize: DEFAULT_PAGE_SIZE,
                },
                routingGuides: [this.props.rfp.id],
                createdBefore: this.state.createdBefore
            }
    
            CarrierActivityLogApiService.filter(this.props.carrierId, props)
                .then((activities) => {
                    const hasMore = (this.state.pageNumber * DEFAULT_PAGE_SIZE) < activities.available;
                    this.setState(prevState => {
                        return {
                            activities: this.state.pageNumber !== DEFAULT_PAGE_NUMBER ? prevState.activities.concat(activities.data) : activities.data,
                            hasMore,
                            isLoading: false
                        };
                    });    
                });
        }
    }

    _incrementPageNumber() {
        this.setState(prevState => { 
            return { pageNumber: prevState.pageNumber + 1 };
        });
    }

    render() {
        const isRoutingGuide = FINISHED_RFP_AUCTION_STATUSES.includes(this.props.rfp.status);
        return (
            <ActivityLogDrawer 
                activities={ this.state.activities } 
                hasMore={ this.state.hasMore } 
                isLoading={ this.state.isLoading }
                fetchMoreData={ this._incrementPageNumber }
                description={ `Activity log from all carrier members in this ${ isRoutingGuide ? 'Routing Guide' : 'RFP' }` }
            />
        );
    }
}
