import { Component } from 'react';

import CanAccess from 'component/CanAccess';
import PageHeader from 'component/PageHeader';
import SMCarriersSyncRules from 'component/SMCarriersSyncRules';

import './Settings.scss';

export default class Settings extends Component {

    render() {
        return (
            <div className="page settings-page">
                <PageHeader title="Settings"></PageHeader>
                <div className="settings-page-body">
                    <div className="settings-page-content">
                        <CanAccess
                            action="sm-carriers-sync-config:read"
                            yes={ <SMCarriersSyncRules /> }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
