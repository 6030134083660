import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'component/Button';
import Popup from 'component/Popup';
import CustomSelect from 'component/form/CustomSelect';
import CustomTextArea from 'component/form/CustomTextArea';

import './RejectCurrentOfferPopup.scss';

export default class RejectCurrentOfferPopup extends Component {
    static propTypes = {
        onManualRejectOffer: PropTypes.func,
        onCancel: PropTypes.func,
        manualRejectReasons: PropTypes.array,
        candidate: PropTypes.object
    }

    static defaultProps = {
        onManualRejectOffer: () => { /* */ },
        onCancel: () => { /* */ },
        manualRejectReasons: [],
        candidate: null
    }

    constructor(props) {
        super(props);

        this._onReasonSelected = this._onReasonSelected.bind(this);
        this._onInputValueChange = this._onInputValueChange.bind(this);
        this._showPopup = this._showPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
        this._onReject = this._onReject.bind(this);
    }

    state = {
        showPopup: false,
        note: "",
        selectedRejectReason: null
    }

    /**
     * Handles reject reason select event
     * 
     * @param {string} value
     * @param {Object} option
     * @private
     */
    _onReasonSelected(_value, option) {
        this.setState({ selectedRejectReason: JSON.parse(option.key), note: "" });
    }

    /**
     * Handles custom reject reason input change
     * 
     * @param {Object} event 
     */
    _onInputValueChange(event) {
        const value = event.target.value;
        this.setState({ note: value });
    }

     _formReasonNoteField() {
        if (this.state.selectedRejectReason?.noteRequired) {
            return (
                <div className="reason-text-area">
                    <CustomTextArea 
                        label="Note" 
                        rows={ 3 }
                        placeholder="Enter your reason..."
                        required={ true } 
                        value={ this.state.note } 
                        fieldName="reason" 
                        onValueChange={ this._onInputValueChange }
                    />
                </div>
            );
        }
    }

    _isRejectDisabled() {
        const { selectedRejectReason, note } = this.state;
        return !selectedRejectReason || (selectedRejectReason.noteRequired && note.trim() === "");
    }

    _showPopup(e) {
        e.stopPropagation();
        this.setState({ showPopup: true });
    }

    _closePopup() {
        this.setState({ showPopup: false, note: "", selectedRejectReason: null });
        this.props.onCancel();
    }

    _onReject() {
        this.props.onManualRejectOffer(this.state.selectedRejectReason, this.state.note.trim());
        this._closePopup();
    }

    render() {
        return (
            <Popup
                id="popup-reject-offer"
                closeButton={ false }
                show={ this.state.showPopup }
                onClose={ this._closePopup }
                trigger={
                    <a href="#popup-reject-offer" onClick={ this._showPopup } className="dropdown-item">
                        <small className="reject-offer-for-carrier-action-name action-name">Reject Offer for Carrier</small>
                    </a>
                }
            >
                <div className="reject-offer-popup">
                    <p className="heading">Reject Offer for Carrier</p>
                    <p className="description">
                        Are you sure you want to reject the offer for the carrier 
                        <span className="carrier-name"> { this.props.candidate.carrier.name }</span>?
                    </p>
                    <p className="description">
                        Please select a reason for this action.
                    </p>
                    
                    <CustomSelect
                        label="Reason" 
                        values={ this.props.manualRejectReasons } 
                        selectedValue={ this.state.selectedRejectReason ? this.state.selectedRejectReason.reason : null }
                        onSelect={ this._onReasonSelected } 
                        fieldName="reason"
                    />
                    { this._formReasonNoteField() }

                    <div className="buttons-wrapper">
                        <Button 
                            type="tertiary"
                            size="small"
                            onClick={ this._closePopup } 
                        >
                            Cancel
                        </Button>

                        <Button
                            type="danger"
                            size="small"
                            disabled={ this._isRejectDisabled() }
                            onClick={ this._onReject }
                        >
                            Reject
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}
