import { Component } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES, NOTIFICATION_CENTER_TYPES, NOTIFICATION_SUBSCRIPTION_TYPES, USER_ROLES } from 'common/constants';
import Button from 'component/Button';
import CustomMultipleTextInput from 'component/form/CustomMultipleTextInput';
import FormStatusModal from 'component/form/FormStatusModal';
import Popup from 'component/Popup';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import UserApiService from 'service/api/UserApiService';
import StringUtils from 'utils/StringUtils';

export default class AddNotificationSubscriptionsManuallyPopup extends Component {

    static propTypes = {
        routingGuideId: PropTypes.string,
        type: PropTypes.oneOf([NOTIFICATION_CENTER_TYPES.RFP_MANAGERS, NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS]),
        size: PropTypes.oneOf(['regular', 'small']),
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        routingGuideId: null,
        type: NOTIFICATION_CENTER_TYPES.RFP_MANAGERS,
        size: 'regular',
        onSubmit: () => { /**/ }
    };

    constructor(props) {
        super(props);

        this._onShowPopup = this._onShowPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._onUserAdded = this._onUserAdded.bind(this);
        this._onUserRemoved = this._onUserRemoved.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
    }

    state = {
        showPopup: false,
        loading: null,
        numberOfAddedUsers: 0,
        users: []
    };

    _onShowPopup() {
        this.setState({ showPopup: true, users: []});
    }

    _onClosePopup() {
        if (this.state.numberOfAddedUsers !== 0) {
            this.props.onSubmit();
        }
        
        this.setState({ showPopup: false, loading: null, users: [], numberOfAddedUsers: 0 });
    }

    _onSubmit() {
        this.setState({ loading: true });

        const userEmails = this.state.users.map(user => user.value);
        let type;

        switch (this.props.type) {
            case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                type = NOTIFICATION_SUBSCRIPTION_TYPES.RFP_MANAGER
                break;
            case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                type = NOTIFICATION_SUBSCRIPTION_TYPES.DEFAULT_LOGISTICS_COORDINATOR
                break;
            default:
                // This should never happen
                console.error(`Unrecognized type ${ this.props.type }`);
        }


        RoutingGuideNotificationApiService.addDirectly(this.props.routingGuideId, userEmails, type)
            .then(numberOfAddedUsers => this.setState({ numberOfAddedUsers }))
            .finally(() => this.setState({ loading: false }));
    }

    _onUserAdded(userEmail) {
        // We use flushSync to ensure users are set to the state before the submit method is called
        flushSync(() => {
            let role;

            switch (this.props.type) {
                case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                    role = USER_ROLES.RFP_MANAGER;
                    break;
                case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                    role = [ USER_ROLES.BROKER, USER_ROLES.RFP_MANAGER ];
                    break;
                default:
                // Nothing to do here
            }

            UserApiService.isRoleActive(userEmail, role)
                .then(valid => this.setState(prevState => ({ users: [ ...prevState.users, { value: userEmail, valid } ] })));
        });
    }

    _onUserRemoved(userEmail) {
        this.setState(prevState => {
            const users = [ ...prevState.users ];
            const index = users.findIndex(user => user.value === userEmail);

            if (index > -1) {
                users.splice(index, 1);
            }

            return { users };
        });
    }

    render() {
        let content;

        if (this.state.loading === null) {
            let description = '';
            
            switch (this.props.type) {
                case NOTIFICATION_CENTER_TYPES.RFP_MANAGERS:
                    description = (
                        <>
                            <p className="description">Add RFP Managers by entering their emails in order to turn on notifications.</p>
                            <p className="description">RFP Managers will receive all RFP related notifications.</p>
                        </>
                    );
                    break;
                case NOTIFICATION_CENTER_TYPES.LOGISTICS_COORDINATORS:
                    description = (
                        <>
                            <p className="description">Add users by entering their emails in order to turn on notifications.</p>
                            <p className="description">Users will receive notifications for loads on lanes not covered by lane specific LCs.</p>
                        </>
                    );
                    break;
                default:
                // Nothing to do here
            }

            content = (
                <>
                    <h6 className="heading"><b>Notification Center</b></h6>
                    
                    { description }

                    <CustomMultipleTextInput
                        label="Users"
                        tooltipText="To add multiple users press the enter key after each email entry."
                        className="add-users-input-field"
                        placeholder="Enter User Emails..."
                        values={ this.state.users }
                        onValueAdded={ this._onUserAdded }
                        onValueRemoved={ this._onUserRemoved }
                    />
                    
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onClosePopup }>
                            Cancel
                        </Button>
                        <Button onClick={ this._onSubmit } disabled={ !this.state.users.length }>
                            Done
                        </Button>
                    </div>
                </>
            );
        } else if (this.state.loading) {
            content = (
                <FormStatusModal status={ FORM_STATUSES.LOADING } onContinue={ this._onClosePopup }>
                    <h6>Adding Users</h6>
                    <small>This will only take a moment. Please wait...</small>
                </FormStatusModal>
            );
        } else if (this.state.numberOfAddedUsers === 0) {
            content = (
                <FormStatusModal status={ FORM_STATUSES.EMPTY_SUCCESS } onContinue={ this._onClosePopup }>
                    <h6>No New Users Added</h6>
                    <small>The list of users remains unchanged. Make sure you have entered valid user emails and these users are not already added as notification subscriptions.</small>
                </FormStatusModal>
            );
        } else {
            content = (
                <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                    <h6>Subscriptions Added</h6>
                    <small><b>{ this.state.numberOfAddedUsers }</b> { StringUtils.pluralize(this.state.numberOfAddedUsers, 'user') } { this.state.numberOfAddedUsers > 1 ? 'have' : 'has' } been successfully added.</small>
                </FormStatusModal>
            );
        }

        return (
            <Popup
                id="add-notification-subscriptions-popup"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._onClosePopup }
                trigger={
                    <Button size={ this.props.size } leftIcon={ addIcon } onClick={ this._onShowPopup }>
                        Add Users Directly
                    </Button>
                }
            >
                <div className="add-notification-subscriptions-popup">
                    { content }
                </div>
            </Popup>
        );
    }
}
