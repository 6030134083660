import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen as helpCenter } from '@fortawesome/pro-light-svg-icons';

import MessageBubble from 'component/MessageBubble';
import Button from 'component/Button';
import Tooltip from 'component/Tooltip';
import AuthenticationService from 'service/AuthenticationService';

import './HelpCenterInfoBubble.scss';

export default class HelpCenterInfoBubble extends Component {
    state = {
        helpCenterSeen: false,
        account: null
    }
    
    constructor(props) {
        super(props);
        this._onGotItClick = this._onGotItClick.bind(this);
    }

    componentDidMount() {
        AuthenticationService.instance().getAccount().then(account => {
            this.setState({
                helpCenterSeen: !!localStorage.getItem(`help-center-seen-by-${ account.id }`),
                account: account
            });  
        }).catch(error => console.error(error.message));        
    }

    _onGotItClick() {
        localStorage.setItem(`help-center-seen-by-${ this.state.account.id }`, true);
        this.setState({
            helpCenterSeen: true
        });
    }

    render() {
        return (
            <div className="help-center-link">
                <NavLink to="/help-center" id="nav-link-help-center" className="page-navigation-item">
                    <FontAwesomeIcon icon={ helpCenter } />
                    { this.state.helpCenterSeen && <Tooltip>Help Center</Tooltip> }
                </NavLink>
                { !this.state.helpCenterSeen && 
                    <MessageBubble>
                        <p className="help-center-guide">
                            If you need any help, this is where you can download our <b>User Manual Guides</b>.
                        </p>
                        <Button 
                            className="help-center-dismiss-guide"
                            type="tertiary"
                            size="small"
                            onClick={ this._onGotItClick }
                        >
                            Got it
                        </Button>
                    </MessageBubble> 
                }    
            </div>
        );
    }
}
