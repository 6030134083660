import { Children, Component } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Tab from 'component/navigation/Tab';

import './Tabs.scss';

/**
 * A Navigable Tabs wrapper. Utilizes react-router and nested routes to persist tab selection.
 *
 * IMPORTANT: There can only ever be a single navigable tab wrapper per page!
 * Additional tab wrappers should use the simple wrapper.
 */
export default class NavigableTabs extends Component {

    static propTypes = {
        url: PropTypes.string.isRequired,
        colorTheme: PropTypes.oneOf(['light', 'medium']),
        absolute: PropTypes.bool
    }

    static defaultProps = {
        colorTheme: 'medium',
        absolute: false
    }

    constructor(props) {
        super(props);

        this.state = {
            defaultTab: null
        };

        this._links = this._setLinks(props);
        this._tabs = this._setTabs(props);
    }

    componentDidMount() {
        const activeTab = window.location.pathname.split('/').pop();
        const url = this.props.url.replace(/\/$/, '');

        let defaultTab;

        for (const child of this.props.children) {
            if (child?.props.id === activeTab) {
                return;
            }

            if (child?.props.default) {
                defaultTab = child.props.id;
            }
        }

        this.setState({
            defaultTab: (
                <Route exact path={ window.location.pathname }>
                    <Redirect to={ `${ url }/${ defaultTab }` } />
                </Route>
            )
        });
    }

    _setLinks(props) {
        const url = this.props.url.replace(/\/$/, '');
        const activeTab = window.location.pathname.split('/').pop();

        return Children.map(props.children, child => {
            if (!child) {
                return <></>;
            }

            let icon = <></>;
            if (child.props.activeIcon && child.props.id === activeTab) {
                icon = <FontAwesomeIcon icon={ child.props.activeIcon } />;
            } else if (child.props.inactiveIcon) {
                icon = <FontAwesomeIcon icon={ child.props.inactiveIcon } />;
            }

            return (
                <NavLink to={ `${ url }/${ child.props.id }` } className={ `tab-link ${ props.colorTheme }` } onClick={ child.props.onClick }>
                    { icon }
                    { child.props.title }
                </NavLink>
            );
        })
    }

    _setTabs(props) {
        const url = this.props.url.replace(/\/$/, '');

        return Children.map(
            props.children,
            child => {
                if (!child) {
                    return null;
                }

                if (child.type !== Tab) {
                    throw new Error('Invalid child provided to Tabs component. All children must be tabs.')
                }

                return (
                    <Route path={ `${ url }/${ child.props.id }` }>
                        { child }
                    </Route>
                );
            }
        );
    }

    render() {
        return (
            <div className={ `tab-container ${ this.props.absolute ? 'absolute' : '' }` }>
                <div className={ `tab-navigation-container ${ this.props.absolute ? 'absolute' : '' }` }>
                    <div className="tab-placeholder left"/>
                    { this._setLinks(this.props) }
                    <div className="tab-placeholder" />
                </div>
                <div className={ `tab-content-container ${ this.props.colorTheme } ${ this.props.absolute ? 'absolute' : '' }` }>
                    <div className="tab-content">
                        <Switch>
                            { this.state.defaultTab }
                            { this._setTabs(this.props) }
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
