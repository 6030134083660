import {
    faLoveseat as dryIcon,
    faSnowflake as reeferIcon,
    faPalletAlt as flatbedIcon
} from '@fortawesome/pro-solid-svg-icons';

export const ONGOING_AUCTION_STATUSES = ['OFFERING_TO_CARRIERS', 'AWAITING_WINNER_RESPONSE', 'AWAITING_WINNER_DETAILS', 'AWAITING_WINNER_DECLARATION', 'CARRIER_FALLOFF'];
export const TERMINAL_AUCTION_STATUSES = ['READY', 'CANCELED'];
export const CONFIRMED_AUCTION_STATUSES = ['AWAITING_WINNER_DETAILS', 'UNDER_REVIEW', 'READY'];

export const CARRIER_ONGOING_SM_AUCTION_STATUSES = ['OFFERING_TO_CARRIERS', 'AWAITING_WINNER_DECLARATION'];

export const STARTED_LOAD_STATUSES = ['IN_PROGRESS', 'COMPLETED'];

export const FAILED_BID_STATUSES = ['EXPIRED', 'REJECTED', 'REJECTED_BY_BROKER'];
export const WINNING_BID_STATUSES = ['WINNER', 'CONFIRMED'];
export const TERMINAL_BID_STATUSES = ['REJECTED', 'REJECTED_BY_BROKER', 'EXPIRED', 'FALLOFF', 'FALLOFF_BY_BROKER'];
export const FALLOFF_BID_STATUSES = ['FALLOFF', 'FALLOFF_BY_BROKER'];

export const AUCTION_BID_STATUS_MAPPING = {
    PENDING: 'Waiting for Response',
    CONFIRMED: 'Carrier Details are Missing',
    REJECTED: 'Rejected',
    REJECTED_BY_BROKER: 'Rejected by Broker',
    EXPIRED: 'Expired',
    WINNER: 'Ready to go',
    FALLOFF: 'Falloff',
    FALLOFF_BY_BROKER: 'Falloff by Broker',
    NOT_SENT: 'Offer not Sent'
};

export const TERMINAL_AUCTION_BID_STATUS_MAPPING = {
    REJECTED: 'Rejected Load',
    REJECTED_BY_BROKER: 'Load Rejected by Broker',
    EXPIRED: 'Offer Expired',
    FALLOFF: 'Falloff',
    FALLOFF_BY_BROKER: 'Falloff by Broker',
    PENDING: 'Lost Load',
    CONFIRMED: 'Won Load',
    WINNER: 'Won Load',
    NOT_SENT: 'Offer not Sent'
};

export const TERMINAL_AUCTION_BID_CLASS_MAPPING = {
    REJECTED: 'failed',
    REJECTED_BY_BROKER: 'failed',
    EXPIRED: 'expired',
    FALLOFF: 'falloff',
    FALLOFF_BY_BROKER: 'falloff',
    PENDING: 'failed',
    CONFIRMED: 'success',
    WINNER: 'success'
};

export const AUCTION_PHASE_MAPPING = {
    DEDICATED: 'dedicated',
    SPOT_MARKET: 'spot-market'
};

export const CUSTOM_AUCTION_PHASE = {
    DEDICATED: 'dedicated',
    SPOT_MARKET: 'spot-market',
    TRANSITION: 'transition'
};

export const AUCTION_STATUSES = {
    PENDING: 'PENDING',
    OFFERING_TO_CARRIERS: 'OFFERING_TO_CARRIERS',
    AWAITING_WINNER_RESPONSE: 'AWAITING_WINNER_RESPONSE',
    AWAITING_WINNER_DETAILS: 'AWAITING_WINNER_DETAILS',
    UNDER_REVIEW: 'UNDER_REVIEW',
    AWAITING_WINNER_DECLARATION: 'AWAITING_WINNER_DECLARATION',
    CARRIER_FALLOFF: 'CARRIER_FALLOFF',
    READY: 'READY',
    CANCELED: 'CANCELED'
};

export const OFFERING_AUCTION_STATUSES = [
    AUCTION_STATUSES.OFFERING_TO_CARRIERS, 
    AUCTION_STATUSES.AWAITING_WINNER_RESPONSE
];

export const AUCTION_STATUS_MAPPING = {
    PENDING: 'Pending',
    OFFERING_TO_CARRIERS: 'Offering to Carriers',
    AWAITING_WINNER_DETAILS: 'Carrier Details are Missing',
    UNDER_REVIEW: 'Under Review',
    AWAITING_WINNER_DECLARATION: 'Waiting for Winner Declaration',
    CARRIER_FALLOFF: 'Falloff',
    READY: 'Ready to go',
    CANCELED: 'Canceled'
};

export const AUCTION_STATUSES_FILTER_MAPPING = {
    AWAITING_WINNER_DETAILS: 'Carrier Details are Missing',
    UNDER_REVIEW: 'Under Review',
    READY: 'Ready to go',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
    EXPIRED: 'Expired'
};

export const AUCTION_STATUS_CLASS_MAPPING = {
    PENDING: 'pending',
    OFFERING_TO_CARRIERS: 'in-progress',
    AWAITING_WINNER_DETAILS: 'in-progress',
    UNDER_REVIEW: 'in-progress',
    AWAITING_WINNER_DECLARATION: 'in-progress',
    CARRIER_FALLOFF: 'in-progress',
    READY: 'completed',
    CANCELED: 'failed'
};

export const TMW_LOAD_STATUS_MAPPING = {
    PENDING: 'Pending',
    AVAILABLE: 'Available',
    ASSIGNED: 'Assigned',
    DISPATCHED: 'Dispatched',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled'
};

export const PAYMENT_OPTIONS = {
    FACTORING_COMPANY: 'FACTORING_COMPANY',
    DIRECT_PAYMENT_NORMAL_NET_30_DAY_PAY: 'DIRECT_PAYMENT_NORMAL_NET_30_DAY_PAY',
    DIRECT_PAYMENT_2_DAY_QUICK_PAY_5_PCT_FEE: 'DIRECT_PAYMENT_2_DAY_QUICK_PAY_5_PCT_FEE',
    DIRECT_PAYMENT_3_DAY_QUICK_PAY_3_PCT_FEE: 'DIRECT_PAYMENT_3_DAY_QUICK_PAY_3_PCT_FEE'
};

export const PAYMENT_OPTION_MAPPING = {
    FACTORING_COMPANY: 'Factoring Company',
    DIRECT_PAYMENT_NORMAL_NET_30_DAY_PAY: 'Direct Payment: Normal Net 30 Day Pay',
    DIRECT_PAYMENT_2_DAY_QUICK_PAY_5_PCT_FEE: 'Quick Pay: 2-Day Quick Pay with 5% Fee',
    DIRECT_PAYMENT_3_DAY_QUICK_PAY_3_PCT_FEE: 'Quick Pay: 3-Day Quick Pay with 3% Fee'
};

export const USER_ROLES = {
    CARRIER: 'CARRIER',
    BROKER: 'BROKER',
    RFP_MANAGER: 'RFP_MANAGER',
    ADMINISTRATOR: 'ADMINISTRATOR'
};

export const USER_ROLES_MAPPING = {
    CARRIER: 'Carrier',
    BROKER: 'Broker',
    RFP_MANAGER: 'RFPManager',
    ADMINISTRATOR: 'Administrator'
};

export const AUCTION_PHASE = {
    DEDICATED: 'DEDICATED',
    SPOT_MARKET: 'SPOT_MARKET'
};

export const BID_STATUSES = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    REJECTED_BY_BROKER: 'REJECTED_BY_BROKER',
    EXPIRED: 'EXPIRED',
    WINNER: 'WINNER',
    FALLOFF: 'FALLOFF',
    FALLOFF_BY_BROKER: 'FALLOFF_BY_BROKER',
    NOT_SENT: 'NOT_SENT'
};

export const LOAD_STATUSES = {
    AVAILABLE: 'AVAILABLE',
    ASSIGNED: 'ASSIGNED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED'
};

export const LOAD_CANCELABLE_AUCTION_STATUSES = [
    AUCTION_STATUSES.AWAITING_WINNER_DETAILS,
    AUCTION_STATUSES.UNDER_REVIEW,
    AUCTION_STATUSES.READY
];

export const OFFER_NOT_CANCELABLE_BID_STATUSES = ['FALLOFF', 'FALLOFF_BY_BROKER', 'REJECTED', 'REJECTED_BY_BROKER', 'EXPIRED', 'PENDING'];
export const LOST_STYLE_BID_STATUSES = ['REJECTED', 'REJECTED_BY_BROKER', 'PENDING'];

export const SCREENS = {
    LOAD_BOARD: 'load-board',
    BOOKED_LOADS: 'booked-loads',
    LOAD_HISTORY: 'load-history',
    LOAD_SEARCH: 'loads-search'
};

export const CANDIDATE_TYPE = {
    SIGNED_CARRIER: 'SIGNED_CARRIER',
    SPOT_MARKET: 'SPOT_MARKET',
    ON_DEMAND: 'ON_DEMAND'
};

export const OFFERED_CANDIDATE_TYPE = [CANDIDATE_TYPE.SIGNED_CARRIER, CANDIDATE_TYPE.ON_DEMAND];

export const EXCLUSION_REASONS = {
    FALLOFF: 'FALLOFF',
    TERMINATED: 'TERMINATED',
    QUOTA_FULFILLED: 'QUOTA_FULFILLED',
    REJECTED_OFFER: 'REJECTED_OFFER',
    LIMIT_EXCEEDED: 'LIMIT_EXCEEDED'
};

export const LANE_TYPES = {
    DRY: 'DRY',
    REFRIGERATED: 'REFRIGERATED',
    FLATBED: 'FLATBED'
};

export const LANE_TYPE_PROPERTIES = {
    DRY: {
        icon: dryIcon,
        tooltip: 'Dry Cargo Type',
        label: 'Dry',
        field: 'dry',
        shortcut: 'V or R'
    },
    REFRIGERATED: {
        icon: reeferIcon,
        tooltip: 'Reefer Cargo Type',
        label: 'Refrigerated',
        field: 'reefer',
        shortcut: 'R'
    },
    FLATBED: {
        icon: flatbedIcon,
        tooltip: 'Flatbed Cargo Type',
        label: 'Flatbed',
        field: 'flatbed',
        shortcut: 'FB'
    }
};

export const DECLARE_WINNER_ENABLED_STATUSES = [
    AUCTION_STATUSES.OFFERING_TO_CARRIERS,
    AUCTION_STATUSES.AWAITING_WINNER_DECLARATION,
    AUCTION_STATUSES.CARRIER_FALLOFF,
    AUCTION_STATUSES.AWAITING_WINNER_RESPONSE
];

export const FORM_STATUSES = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    EMPTY_SUCCESS: 'EMPTY_SUCCESS',
    ERROR: 'ERROR',
    CONFIRMATION: 'CONFIRMATION'
};

export const RFP_AUCTION_STATUSES = {
    PENDING: 'PENDING',
    OFFERING_TO_CARRIERS: 'OFFERING_TO_CARRIERS',
    IN_REVIEW: 'IN_REVIEW',
    CONFIRMED: 'CONFIRMED',
    APPLIED: 'APPLIED',
    COMPLETED: 'COMPLETED'
};

export const TERMINAL_RFP_AUCTION_STATUSES = ['CONFIRMED', 'APPLIED', 'COMPLETED'];
export const FINISHED_RFP_AUCTION_STATUSES = ['APPLIED', 'COMPLETED'];
export const ONGOING_RFP_AUCTION_STATUSES = ['OFFERING_TO_CARRIERS', 'IN_REVIEW'];

export const RFP_ROUND_TYPE = {
    BIDDING: 'BIDDING',
    CONFIRMATION: 'CONFIRMATION'
};

export const RFP_BID_STATUSES = {
    PROPOSED: 'PROPOSED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED'
};

export const MAX_PRICE = 10000000000;
export const MAX_NUMBER_OF_WEEKS = 1000000000;

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGINATION_VALUES = [
    { size: 10 },
    { size: 20 },
    { size: 40 }
];

export const USER_DOCUMENT_STATUSES = {
    PENDING: {
        value: 'PENDING',
        label: 'Pending'
    },
    APPROVED: {
        value: 'APPROVED',
        label: 'Approved'
    },
    REJECTED: {
        value: 'REJECTED',
        label: 'Rejected'
    }
};

export const READ_ALL_DOCUMENTS_PERMISSION = 'user-documents:read:all';
export const RFP_WRITE_PERMISSION = 'rfp:write';
export const LOAD_TENDERING_WRITE_PERMISSION = 'load-tendering:write';

export const SORT_DIRECTIONS = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
    NONE: 'NONE'
};

export const RG_DETAILS_VIEW_MODE = {
    RFP_ONLY: 'RFP_ONLY',
    RFP_READ_ONLY: 'RFP_READ_ONLY',
    ROUTING_GUIDE_ONLY: 'ROUTING_GUIDE_ONLY',
    RFP_AND_ROUTING_GUIDE: 'RFP_AND_ROUTING_GUIDE'
};

export const TENDER_CANDIDATE_STATUS = {
    ACCEPTED: {
        value: 'ACCEPTED',
        text: 'Active',
        candidateFacingText: 'Active',
        color: 'cyan',
        style: 'active'
    },
    PENDING: {
        value: 'PENDING',
        text: 'Pending',
        candidateFacingText: 'Pending',
        color: 'orange',
        style: 'pending'
    },
    REJECTED: {
        value: 'REJECTED',
        text: 'Offer Declined',
        candidateFacingText: 'Rejected',
        color: 'red',
        style: 'rejected'
    },
    EXPIRED: {
        value: 'EXPIRED',
        text: 'Offer Expired',
        candidateFacingText: 'Expired',
        color: 'orange',
        style: 'expired'
    },
    DELETED: {
        value: 'DELETED',
        text: 'Removed Manually',
        candidateFacingText: 'Inactive',
        color: 'grey',
        style: 'deleted'
    },
    REPLACED: {
        value: 'REPLACED',
        text: 'Replaced',
        candidateFacingText: 'Replaced',
        color: 'dark-grey',
        style: 'replaced'
    }
};

export const TRAILER_TYPE = {
    VAN: 'VAN',
    REEFER: 'REEFER',
    FLATBED: 'FLATBED'
};

export const USER_STATUS = {
    ACTIVE: { label: 'Active', field: 'ACTIVE' },
    PENDING: { label: 'Pending', field: 'PENDING' },
    REMOVED: { label: 'Removed', field: 'REMOVED' }
};

export const ACTIVITY_TYPES = {
    BID: { value: 'BID', label: 'Placed a bid', iconType: 'SUBMIT' },
    BOOK_NOW: { value: 'BOOK_NOW', label: 'Used book now', iconType: 'SUBMIT' },
    ACCEPT_OFFER: { value: 'ACCEPT_OFFER', label: 'Accepted an offer', iconType: 'ACCEPT' },
    REJECT_OFFER: { value: 'REJECT_OFFER', label: 'Rejected an offer', iconType: 'REJECT' },
    CARRIER_FALLOFF: { value: 'CARRIER_FALLOFF', label: 'Canceled load', iconType: 'REJECT' },
    CARRIER_EDIT_WINNER_DETAILS: { value: 'CARRIER_EDIT_WINNER_DETAILS', label: 'Edited Carrier details', iconType: 'UPDATE' },
    ASSIGN_CARRIER_DISPATCHER: { value: 'ASSIGN_CARRIER_DISPATCHER', label: 'Assigned Carrier Dispatcher', iconType: 'SUBMIT' },
    REMOVE_CARRIER_DISPATCHER: { value: 'REMOVE_CARRIER_DISPATCHER', label: 'Removed Carrier Dispatcher', iconType: 'REJECT' },
    EDIT_CARRIER_DISPATCHER: { value: 'EDIT_CARRIER_DISPATCHER', label: 'Edited Carrier Dispatcher', iconType: 'UPDATE' },
    SUBMIT_RFP_AUCTION_BID: { value: 'SUBMIT_RFP_AUCTION_BID', label: 'Submitted RFP Auction bid', iconType: 'SUBMIT' },
    SUBMIT_RFP_AUCTION_BIDS_VIA_CSV:{ value: 'SUBMIT_RFP_AUCTION_BIDS_VIA_CSV', label: 'Submitted RFP Auction bid via CSV', iconType: 'SUBMIT' },
    ACCEPT_RFP_AUCTION_BID_OFFER: { value: 'ACCEPT_RFP_AUCTION_BID_OFFER', label: 'Accepted RFP Auction bid offer', iconType: 'ACCEPT' },
    ACCEPT_ALL_RFP_AUCTION_BID_OFFERS: { value: 'ACCEPT_ALL_RFP_AUCTION_BID_OFFERS', label: 'Accepted all RFP Auction bid offers', iconType: 'ACCEPT' },
    REJECT_RFP_AUCTION_BID_OFFER: { value: 'REJECT_RFP_AUCTION_BID_OFFER', label: 'Rejected RFP Auction bid offer', iconType: 'REJECT' },
    REJECT_ALL_RFP_AUCTION_BID_OFFERS: { value: 'REJECT_ALL_RFP_AUCTION_BID_OFFERS', label: 'Rejected all RFP Auction bid offers', iconType: 'REJECT' },
    ACCEPT_RFP_TOS: { value: 'ACCEPT_RFP_TOS', label: 'Accepted RFP Terms of Service', iconType: 'ACCEPT' },
    ACCEPT_TENDER_CANDIDATE_OFFER: { value: 'ACCEPT_TENDER_CANDIDATE_OFFER', label: 'Accepted price change offer', iconType: 'ACCEPT' },
    ACCEPT_ALL_TENDER_CANDIDATE_OFFERS: { value: 'ACCEPT_ALL_TENDER_CANDIDATE_OFFERS', label: 'Accepted all price change offers', iconType: 'ACCEPT' },
    REJECT_TENDER_CANDIDATE_OFFER: { value: 'REJECT_TENDER_CANDIDATE_OFFER', label: 'Rejected price change offer', iconType: 'REJECT' },
    REJECT_ALL_TENDER_CANDIDATE_OFFERS: { value: 'REJECT_ALL_TENDER_CANDIDATE_OFFERS', label: 'Rejected all price change offers', iconType: 'REJECT' },
    LANE_EXCLUSION: { value: 'LANE_EXCLUSION', label: 'Not accepting load on lane', iconType: 'REJECT' },
    CHANGE_FAX: { value: 'CHANGE_FAX', label: 'Changed fax', iconType: 'UPDATE' },
    CHANGE_PHONE_NUMBER: { value: 'CHANGE_PHONE_NUMBER', label: 'Changed phone number', iconType: 'UPDATE' },
    CHANGE_NAME: { value: 'CHANGE_NAME', label: 'Changed name', iconType: 'UPDATE' },
    CHANGE_EMAIL: { value: 'CHANGE_EMAIL', label: 'Changed email', iconType: 'UPDATE' }
};

export const ACTIVITY_TYPE_OPTIONS = [
    {
        value: 'load_tendering',
        label: 'Load Tendering',
        children: [
            ACTIVITY_TYPES.BID,
            ACTIVITY_TYPES.BOOK_NOW,
            ACTIVITY_TYPES.ACCEPT_OFFER,
            ACTIVITY_TYPES.REJECT_OFFER,
            ACTIVITY_TYPES.CARRIER_FALLOFF,
            ACTIVITY_TYPES.CARRIER_EDIT_WINNER_DETAILS
        ]
    },
    {
        value: 'carrier_dispatcher',
        label: 'Carrier dispatcher',
        children: [
            ACTIVITY_TYPES.ASSIGN_CARRIER_DISPATCHER,
            ACTIVITY_TYPES.REMOVE_CARRIER_DISPATCHER,
            ACTIVITY_TYPES.EDIT_CARRIER_DISPATCHER
        ]
    },
    {
        value: 'rfp',
        label: 'Request for Proposal',
        children: [
            ACTIVITY_TYPES.SUBMIT_RFP_AUCTION_BID,
            ACTIVITY_TYPES.SUBMIT_RFP_AUCTION_BIDS_VIA_CSV,
            ACTIVITY_TYPES.ACCEPT_RFP_AUCTION_BID_OFFER,
            ACTIVITY_TYPES.ACCEPT_ALL_RFP_AUCTION_BID_OFFERS,
            ACTIVITY_TYPES.REJECT_RFP_AUCTION_BID_OFFER,
            ACTIVITY_TYPES.REJECT_ALL_RFP_AUCTION_BID_OFFERS,
            ACTIVITY_TYPES.ACCEPT_RFP_TOS
        ]
    },
    {
        value: 'lane_activity',
        label: 'Lane Activity',
        children: [
            ACTIVITY_TYPES.ACCEPT_TENDER_CANDIDATE_OFFER,
            ACTIVITY_TYPES.ACCEPT_ALL_TENDER_CANDIDATE_OFFERS,
            ACTIVITY_TYPES.REJECT_TENDER_CANDIDATE_OFFER,
            ACTIVITY_TYPES.REJECT_ALL_TENDER_CANDIDATE_OFFERS,
            ACTIVITY_TYPES.LANE_EXCLUSION
        ]
    },
    {
        value: 'member_updates',
        label: 'Member Updates',
        children: [
            ACTIVITY_TYPES.CHANGE_FAX,
            ACTIVITY_TYPES.CHANGE_PHONE_NUMBER,
            ACTIVITY_TYPES.CHANGE_NAME,
            ACTIVITY_TYPES.CHANGE_EMAIL
        ]
    }
];

export const NOTIFICATION_CENTER_TYPES = {
    RFP_MANAGERS: 'rfp-managers',
    LOGISTICS_COORDINATORS: 'logistics-coordinators'
};

export const NOTIFICATION_SUBSCRIPTION_TYPES = {
    RFP_MANAGER: 'RFP_MANAGER',
    LANE_SPECIFIC_LOGISTICS_COORDINATOR: 'LANE_SPECIFIC_LOGISTICS_COORDINATOR',
    DEFAULT_LOGISTICS_COORDINATOR: 'DEFAULT_LOGISTICS_COORDINATOR'
};

export const SURVEY_QUESTION_TYPE = {
    COMMENT: 'COMMENT',
    RATING: 'RATING'
};

export const TRIGGER_TYPES = {
    BUTTON: 'button',
    LABEL: 'label'
};

export const OTHER_DELIMITER = 'Other';
