import { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import PageHeader from 'component/PageHeader';
import Button from 'component/Button';
import RestService from 'service/RestService';
import { deriveTosLocalStorageKey } from 'component/rfp/tos/RFPTosProtectedPage';

import './RFPTermsOfService.scss';

export default withRouter(class RFPTermsOfService extends Component {

    constructor(props) {
        super(props);
        this._onAgreeChange = this._onAgreeChange.bind(this);
        this._onAcceptTOS = this._onAcceptTOS.bind(this);
    }

    state = {
        agreed: false
    };

    async _onAcceptTOS() {
        await RestService.instance().post('auction/rfp/tos');

        const tosLocalStorageKey = await deriveTosLocalStorageKey();
        localStorage.setItem(tosLocalStorageKey, 'true');

        this.props.history.replace(this.props.location.state || '/rfp/list');
    }

    _onAgreeChange(event) {
        this.setState({ agreed: event.target.checked });
    }

    render() {
        return (
            <div className="page rfp-terms-of-service-page">
                <div className="component rfp-terms-of-service-content">
                    <img src="/logistics-logo.svg" alt="ShipEX Logistics" className="logo" />

                    <PageHeader title="Site Terms of Use" tooltip="Last Modified: March 11, 2022" />

                    <h6>Acceptance of the Terms of Use</h6>
                    <small>
                        These terms of use are entered into by and between You and ShipEX Logistics LLC
                        ("Company," "we," or "us"). The following terms and conditions, together with any documents they
                        expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use
                        of the software application, including any content, functionality and services offered on or
                        through the software application (the "Site"), whether as a guest or a registered user.
                    </small>
                    <small>
                        Please read the Terms of Use carefully before you start to use the Site. By using the Site or by
                        clicking to accept or agree to the Terms of Use when this option is made available to you, you
                        accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at
                        <Link to='/rfp/terms-of-service'> Terms of Service</Link>, incorporated herein by reference.
                        If you do not want to agree  to these Terms of Use or the Privacy Policy, you must not access or
                        use the Site.
                    </small>
                    <small>
                        This Site is offered and available to users who are 18 years of age or older. By using this
                        Site, you represent and warrant that you are of legal age and authorized to form a binding
                        contract with the Company and meet all of the foregoing eligibility requirements. If you do not
                        meet all of these requirements, you must not access or use the Site.
                    </small>

                    <h6>Changes to the Terms of Use</h6>
                    <small>
                        We may revise and update these Terms of Use from time to time in our sole discretion.
                        All changes are effective immediately when we post them.
                    </small>
                    <small>
                        Your continued use of the Site following the posting of revised Terms of Use means that you
                        accept and agree to the changes. You are expected to check this page each time you access this
                        Site so you are aware of any changes, as they are binding on you.
                    </small>

                    <h6>Accessing the Site and Account Security</h6>
                    <small>
                        Subject to these Terms of Use, the Company hereby grants to you an on-exclusive,
                        non-sublicensable, non-transferable and non-assignable, limited right and license to access and
                        use the Site during the term of the particular Request for Quotation (“Event”) or a time sooner
                        as decided by the Company.
                    </small>
                    <small>
                        We reserve the right to withdraw or amend this Site, and any service or material we provide on
                        the Site, in our sole discretion without notice. We will not be liable if for any reason all or
                        any part of the Site is unavailable at any time or for any period. From time to time, we may
                        restrict access to some parts of the Site, or the entire Site, to users, including registered
                        users.
                    </small>
                    <small>
                        You are responsible for both: (a) making all arrangements necessary for you to have access to
                        the Site; and (b) ensuring that all persons who access the Site through your internet connection
                        are aware of these Terms of Use and comply with them.
                    </small>
                    <small>
                        To access the Site or some of the resources it offers, you may be asked to provide certain
                        registration details or other information. It is a condition of your use of the Site that all
                        the information you provide on the Site is correct, current and complete. You agree that all
                        information you provide to register with this Site or otherwise, including, but not limited to,
                        through the use of any interactive features on the Site, is governed these Terms of Use as well
                        as the Confidentiality Agreement signed by you and the Company in connection with the use of
                        this Site and incorporated herewith, and you consent to all actions we take with respect to your
                        information consistent therewith.
                    </small>
                    <small>
                        If you choose, or are provided with, a username, password or any other piece of information as
                        part of our security procedures, you must treat such information as confidential, and you must
                        not disclose it to any other person or entity. The Company has no obligation to verify the
                        identity of any person who gains access to the site on your behalf.  We have the right to
                        disable any username, password or other identifier, whether chosen by you or provided by us,
                        at any time in our sole discretion for any or no reason, including if, in our opinion, you have
                        violated any provision of these Terms of Use.
                    </small>

                    <h6>Intellectual Property Rights</h6>
                    <small>
                        The Site and its entire contents, features and functionality (including but not limited to all
                        information, software, text, displays, images, video and audio, and the design, selection and
                        arrangement thereof), are owned by the Company, its licensors or other providers of such
                        material and are protected by United States and international copyright, trademark, patent,
                        trade secret and other intellectual property or proprietary rights laws.
                    </small>
                    <small>
                        These Terms of Use permit you to use the Site for the purposes of participating in the
                        particular Event. You agree not to: (a) reproduce, distribute, modify, create derivative works
                        of, publicly display, publicly perform, republish, download, store or transmit any of the
                        material on our Site; (b) use any illustrations, photographs, video or audio sequences or any
                        graphics separately from the accompanying text; or (c) delete or alter any copyright, trademark
                        or other proprietary rights notices from copies of materials from this site.
                    </small>
                    <small>
                        If you print, copy, modify, download or otherwise use or provide any other person with access to
                        any part of the Site in breach of the Terms of Use, your right to use the Site will cease
                        immediately and you must, at our option, return or destroy any copies of the materials you have
                        made. No right, title or interest in or to the Site or any content on the Site is transferred to
                        you, and all rights not expressly granted are reserved by the Company. Any use of the Site not
                        expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate
                        copyright, trademark and other laws.
                    </small>

                    <h6>Trademarks</h6>
                    <small>
                        The Company name, the terms ShipEX and ShipEX Logistics, the Company logo and all related names,
                        logos, product and service names, designs and slogans are trademarks of the Company or its
                        affiliates or licensors. You must not use such marks without the prior written permission of the
                        Company. All other names, logos, product and service names, designs and slogans on this Site are
                        the trademarks of their respective owners.
                    </small>

                    <h6>Prohibited Uses</h6>
                    <small>
                        You may use the Site only for lawful purposes and in accordance with these Terms of Use. You
                        agree not to use the Site: (a) in any way that violates any applicable federal, state, local or
                        international law or regulation (including, without limitation, any laws regarding the export of
                        data or software to and from the US or other countries); (b) for the purpose of exploiting,
                        harming or attempting to exploit or harm minors in any way by exposing them to inappropriate
                        content, asking for personally identifiable information or otherwise; (c) to send, knowingly
                        receive, upload, download, use or re-use any material which does not comply with these Terms of
                        Use; (d) to transmit, or procure the sending of, any advertising or promotional material,
                        including any "junk mail," "chain letter," "spam," or any other similar solicitation; (e) to
                        impersonate or attempt to impersonate the Company, a Company employee, another user or any other
                        person or entity (including, without limitation, by using e-mail addresses associated with any
                        of the foregoing); (f) to engage in any other conduct that restricts or inhibits anyone's use or
                        enjoyment of the Site, or which, as determined by us, may harm the Company or users of the Site,
                        or expose them to liability.
                    </small>
                    <small>
                        Additionally, you agree not to: (a) use the Site in any manner that could disable, overburden,
                        damage, or impair the site or interfere with any other party's use of the Site, including their
                        ability to engage in real time activities through the Site; (b) use any robot, spider or other
                        automatic device, process or means to access the Site for any purpose, including monitoring or
                        copying any of the material on the Site; (c) use any manual process to monitor or copy any of
                        the material on the Site, or for any other purpose not expressly authorized in these Terms of
                        Use, without our prior written consent; (d) use any device, software or routine that interferes
                        with the proper working of the Site; (e) introduce any viruses, trojan horses, worms, logic
                        bombs or other material which is malicious or technologically harmful; (f) attempt to gain
                        unauthorized access to, interfere with, damage or disrupt any parts of the Site, the server on
                        which the Site is stored, or any server, computer or database connected to the Site; (g) attack
                        the Site via a denial-of-service attack or a distributed denial-of-service attack; (h) otherwise
                        attempt to interfere with the proper working of the Site; (i) disseminate, store or transmit
                        files, graphics, software or other material that infringes the intellectual property rights or
                        other proprietary rights of a third party.
                    </small>

                    <h6>Notices</h6>
                    <small>
                        You consent to receive notices and other communications from Company by electronic or written
                        means in connection with your use of the Site.
                    </small>

                    <h6>User Information</h6>
                    <small>
                        In connection with the Event, the Site allows you to submit information to the Company. By
                        providing information to the Site, you grant us and our affiliates and service providers, and
                        each of their and our respective licensees, successors and assigns the right to view, use,
                        display and otherwise the submitted information for the purpose of evaluating the potential
                        business arrangement related to the particular Event. The information you submit is governed by
                        the separate Confidentiality Agreement executed by the Company and you in connection with a
                        particular Event and incorporated herein.
                    </small>
                    <small>
                        You represent and warrant that you own or control all rights in and to the information submitted
                        and that all of the information submitted complies with these Terms of Use.
                    </small>
                    <small>
                        You understand and acknowledge that you are responsible for the information you submit, and you,
                        not the Company, have fully responsibility for such content, including its legality,
                        reliability, accuracy and appropriateness. The Company is not responsible, or liable to any
                        third party, for the content or accuracy of any information provided by you.
                    </small>

                    <h6>Termination</h6>
                    <small>
                        We have the right to terminate or suspend your access to all or part of the Site for any or no
                        reason, including without limitation, any violation of these Terms of Use. Company is not liable
                        to you or anyone else for damages arising from Company’s actions to terminate or suspend access
                        to the Site.
                    </small>

                    <h6>Disclaimer of Warranties</h6>
                    <small>
                        WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                        VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                        COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY
                        SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
                        OR ON ANY SITE LINKED TO IT.
                    </small>
                    <small>
                        YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR
                        OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED
                        ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                        IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                        REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
                        AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                        ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SITE , ITS CONTENT OR ANY SERVICES
                        OR ITEMS OBTAINED THROUGH THE SITE  WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
                        THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE  OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                        THE SITE  WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                    </small>
                    <small>
                        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY
                        OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                        AND FITNESS FOR PARTICULAR PURPOSE.
                    </small>
                    <small>
                        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                        APPLICABLE LAW.
                    </small>

                    <h6>Limitation on Liability</h6>
                    <small>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR
                        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF
                        ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
                        USE, THE SITE , ANY SITE S LINKED TO IT, ANY CONTENT ON THE SITE  OR SUCH OTHER SITE S OR ANY
                        SERVICES OR ITEMS OBTAINED THROUGH THE SITE  OR SUCH OTHER SITE S, INCLUDING ANY DIRECT,
                        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
                        PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
                        OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
                        CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                    </small>
                    <small>
                        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                        LAW.
                    </small>

                    <h6>Indemnification</h6>
                    <small>
                        You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and
                        service providers, and its and their respective officers, directors, employees, contractors,
                        agents, licensors, suppliers, successors and assigns from and against any claims, liabilities,
                        damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys'
                        fees) arising out of or relating to your violation of these Terms of Use or your use of the
                        Site, including, but not limited to, the information you submit, any use of the Site 's content,
                        services and products other than as expressly authorized in these Terms of Use, or your use of
                        any information obtained from the Site.
                    </small>

                    <h6>Governing Law and Jurisdiction</h6>
                    <small>
                        All matters relating to the Site and these Terms of Use, and any dispute or claim arising
                        therefrom or related thereto (in each case, including non-contractual disputes or claims), shall
                        be governed by and construed in accordance with the internal laws of the State of Utah without
                        giving effect to any choice or conflict of law provision or rule (whether of the State of Utah
                        or any other jurisdiction).
                    </small>
                    <small>
                        Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the
                        Site shall be instituted exclusively in the federal courts of the United States or the courts of
                        the State of Utah, in each case located in the City of Salt Lake City and County of Salt Lake
                        County. You waive any and all objections to the exercise of jurisdiction over you by such courts
                        and to venue in such courts.
                    </small>

                    <h6>Limitation on Time to File Claims</h6>
                    <small>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR
                        THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE,
                        SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                    </small>

                    <h6>Waiver and Severability</h6>
                    <small>
                        No waiver of by the Company of any term or condition set forth in these Terms of Use shall be
                        deemed a further or continuing waiver of such term or condition or a waiver of any other term or
                        condition, and any failure of the Company to assert a right or provision under these Terms of
                        Use shall not constitute a waiver of such right or provision.
                    </small>
                    <small>
                        If any provision of these Terms of Use is held by a court or other tribunal of competent
                        jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                        eliminated or limited to the minimum extent such that the remaining provisions of the Terms of
                        Use will continue in full force and effect.
                    </small>

                    <h6>Entire Agreement</h6>
                    <small>
                        The Terms of Use and the Confidentiality Agreement constitute the sole and entire agreement
                        between you and the Company with respect to the use of the Site and supersede all prior and
                        contemporaneous understandings, agreements, representations and warranties, both written and
                        oral, with respect to the use of the Site.
                    </small>

                    <div className="rfp-terms-of-service-checkbox-container">
                        <input type="checkbox"
                               id="rfp-terms-of-service-checkbox"
                               className="rfp-terms-of-service-checkbox"
                               checked={ this.state.agreed }
                               onChange={ this._onAgreeChange } />
                        <label htmlFor="rfp-terms-of-service-checkbox">I agree with the Terms and Conditions</label>
                    </div>

                    <div className="rfp-terms-of-service-actions">
                        <Button disabled={ !this.state.agreed }
                                onClick={ this._onAcceptTOS }>Accept</Button>
                    </div>
                </div>
            </div>
        );
    }
});
