import PropTypes from 'prop-types';

import {
    AUCTION_PHASE,
    AUCTION_STATUSES,
    BID_STATUSES,
    CARRIER_ONGOING_SM_AUCTION_STATUSES,
    CONFIRMED_AUCTION_STATUSES,
    DEFAULT_PAGE_NUMBER,
    FALLOFF_BID_STATUSES,
    FAILED_BID_STATUSES,
    OFFERING_AUCTION_STATUSES,
    OFFER_NOT_CANCELABLE_BID_STATUSES,
    TERMINAL_BID_STATUSES,
    WINNING_BID_STATUSES,
    LOAD_STATUSES,
} from 'common/constants';
import Banner from 'component/Banner';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import DownloadRateConfirmationPopup from 'component/load/DownloadRateConfirmationPopup';
import HeadingComponent from 'component/load/HeadingComponent';
import LogisticsCoordinators from 'component/load/LogisticsCoordinators';
import WinnerDetailsComponent from 'component/load/WinnerDetailsComponent';
import DispatchDetailsComponent from 'component/load/DispatchDetailsComponent';
import OfferInformationComponent from 'component/load/OfferInformationComponent';
import CoordinatorDetailsComponent from 'component/load/CoordinatorDetailsComponent';
import PaymentDetailsCard from 'component/load/PaymentDetailsCard';
import WSComponent from 'component/WSComponent';
import CarrierActions from 'component/load/CarrierActions';
import Pagination from 'component/navigation/Pagination';
import AuctionUtils from 'utils/AuctionUtils';
import DateUtils from 'utils/DateUtils';
import Separator from 'component/Separator';
import BidsList from 'component/load/BidsList';
import CurrentBid from 'component/load/CurrentBid';
import LoadTermsOfServicePopup from 'component/load/LoadTermsOfServicePopup';
import PaymentDetailsApiService from 'service/api/PaymentDetailsApiService';
import TenderAuctionCarrierApiService from 'service/api/TenderAuctionCarrierApiService';
import TenderAuctionApiService from 'service/api/TenderAuctionApiService';
import WebSocketService from 'service/WebSocketService';

import './CarrierLoadDetails.scss';

const BID_PAGINATION_VALUES = [
    { size: 12 },
    { size: 24 },
    { size: 48 }
];

export default class CarrierLoadDetails extends WSComponent {

    constructor(props) {
        super(props);

        const auctionId = this._getAuctionId();

        this.subscriptions = [
            {
                topic: `/topic/carriers/auctions/${ auctionId }/updates`,
                handler: this._handleAuctionUpdate.bind(this)
            },
            {
                topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/updates`,
                handler: this._handleAuctionUpdate.bind(this)
            },
            {
                topic: `/topic/auctions/${ auctionId }/spot-market/bid`,
                handler: this._handleNewBid.bind(this)
            },
            {
                //TODO: Create a handler that will only refresh winner details in case winner details are edited
                topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/winner-details`,
                handler: this._handleAuctionUpdate.bind(this)
            },
            {
                topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/offers`,
                handler: this._handleAuctionUpdate.bind(this)
            }
        ];

        this.state = {
            auction: null,
            relevantBids: {},
            bids: [],
            bidsPage: DEFAULT_PAGE_NUMBER,
            bidsPageSize: 12,
            bidsAvailable: 0,
            dispatchDetails: null,
            paymentDetails: [],
            falloffReasons: [],
            hasSpotMarketPhasePassed: false,
            negotiatedRate: null,
            showToSPopup: false
        };

        this._fetchData = this._fetchData.bind(this);
        this._fetchAvailableCarrierDispatchers = this._fetchAvailableCarrierDispatchers.bind(this);
        this._addExistingCarrierDispatcher = this._addExistingCarrierDispatcher.bind(this);
        this._addNewCarrierDispatcher = this._addNewCarrierDispatcher.bind(this);
        this._editCarrierDispatcher = this._editCarrierDispatcher.bind(this);
        this._removeCarrierDispatcher = this._removeCarrierDispatcher.bind(this);
        this._carrierDispatchersContentProvider = this._carrierDispatchersContentProvider.bind(this);
        this._fetchBids = this._fetchBids.bind(this);
        this._setBidsPage = this._setBidsPage.bind(this);
        this._setBidsPageSize = this._setBidsPageSize.bind(this);
        this._onAcceptToS = this._onAcceptToS.bind(this);
        this._cancelOffer = this._cancelOffer.bind(this);
        this._sendDetails = this._sendDetails.bind(this);
        this._postBid = this._postBid.bind(this);
        this._bookNow = this._bookNow.bind(this);
        this._onOpenToS = this._onOpenToS.bind(this);
        this._onCloseToS = this._onCloseToS.bind(this);
    }

    static propTypes = {
        ws: PropTypes.instanceOf(WebSocketService),
        account: PropTypes.object
    };

    static defaultProps = {
        ws: WebSocketService.instance(),
        account: null
    };

    /**
     * The interval id for rerendering the component.
     *
     * @type { Number }
     * @private
     */
    _interval;

    async componentDidMount() {
        super.componentDidMount();

        this._fetchData();

        // Used to rerender in order to count down offer expiration
        this._interval = setInterval(() => {
            this.setState({
                time: Date.now()
            })
        }, 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)) {
            const loadId = this.props.match.params?.loadId;
            const auctionId = this.props.match.params?.auctionId;
            const auction = this.props.location.state?.auction;

            if (auctionId !== prevProps.match.params?.auctionId) {
                this._unsubscribeAll();
                this.subscriptions = [
                    {
                        topic: `/topic/carriers/auctions/${ auctionId }/updates`,
                        handler: this._handleAuctionUpdate.bind(this)
                    },
                    {
                        topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/updates`,
                        handler: this._handleAuctionUpdate.bind(this)
                    },
                    {
                        topic: `/topic/auctions/${ auctionId }/spot-market/bid`,
                        handler: this._handleNewBid.bind(this)
                    },
                    {
                        topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/winner-details`,
                        handler: this._handleAuctionUpdate.bind(this)
                    },
                    {
                        topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/${ auctionId }/offers`,
                        handler: this._handleAuctionUpdate.bind(this)
                    }
                ];
                this._subscribeAll();
                this.setState({
                    bidsPage: DEFAULT_PAGE_NUMBER,
                    bidsPageSize: 12,
                    bidsAvailable: 0,
                    dispatchDetails: null
                });
            }

            if (auction) {
                this._processAuction(auction);
            } else {
                this._fetchAuction(loadId, auctionId);
            }
        }

        if (prevState.bidsPage !== this.state.bidsPage
            || prevState.bidsPageSize !== this.state.bidsPageSize) {
            this._fetchBids();
        }
    }

    _handleNewBid() {
        this._fetchBids();
        this._fetchRelevantBids();
    }

    componentWillUnmount() {
        clearInterval(this._interval);
        super.componentWillUnmount();
    }

    _getAuctionId() {
        return this.props.match.params.auctionId;
    }
    
    _fetchRelevantBids() {
        TenderAuctionCarrierApiService.getRelevantBids(this.state.auction.id).then(relevantBids => {
            this.setState({
                relevantBids
            }, () => {
                this._fetchDispatchDetails(relevantBids.carrierRelevantBid, this.state.auction);
                this._fetchPaymentDetails(relevantBids.carrierRelevantBid, this.state.auction);
            });
        });    
    }

    _fetchBids() {
        if (AUCTION_PHASE.SPOT_MARKET === this.state.auction.phase) {
            TenderAuctionCarrierApiService.getSpotMarketBids(this.state.auction.id, this.state.bidsPage, this.state.bidsPageSize)
                .then((bids) => {
                    this.setState({
                        bids: bids.data,
                        bidsAvailable: bids.available
                    });
                });
        }
    }

    /**
     * Fetches the auction with provided auction id and load business id
     *
     * @param { String } loadId
     * @param { String } auctionId
     * @private
     */
    async _fetchAuction(loadId, auctionId) {
        try {
            let auction;
            if (!auctionId) {
                auction = await TenderAuctionCarrierApiService.getLatestTenderAuction(loadId);
            } else {
                auction = await TenderAuctionCarrierApiService.getTenderAuction(auctionId, loadId);
            }

            await this._processAuction(auction);
            this._checkSpotMarketStatus(auction.id);
            this._fetchAvailableCarrierDispatchers();
            this._fetchNegotiatedRate();
        } catch (e) {
            console.error(e);
            this.props.history.replace("/404");
        }
    }

    _fetchData() {
        const loadId = this.props.match.params.loadId;
        const auctionId = this._getAuctionId();
        this._fetchAuction(loadId, auctionId);
    }

    _fetchNegotiatedRate() {
        const { id, phase, status } = this.state.auction;

        if (AUCTION_PHASE.SPOT_MARKET === phase && AUCTION_STATUSES.OFFERING_TO_CARRIERS === status) {
            TenderAuctionCarrierApiService.getNegotiatedRate(id)
                .then(negotiatedRate => this.setState({ negotiatedRate: negotiatedRate || null }))
                .catch(error => console.error('An error occurred while fetching negotiated rate.', error));
        }
    }

    async _checkSpotMarketStatus(auctionId) {
        TenderAuctionApiService.hasSpotMarketPhasePassed(auctionId)
            .then(hasSpotMarketPhasePassed => this.setState({ hasSpotMarketPhasePassed }))
            .catch(error => console.error('An error occurred while checking spot market status.', error));
    }

    _handleAuctionUpdate(notification) {
        const auctionId = AuctionUtils.parseFromNotification(notification);

        if (!this.state.auction || !auctionId || auctionId !== this.state.auction.id) {
            return;
        }

        this._fetchData();
    }

    async _processAuction(auction) {
        if (auction) {
            if (!this.props.match.params.auctionId) {
                this.props.history.push(`/load/${ this.props.match.params.loadId }/auction/${ auction.id }`, { auction });
            } else {
                this.setState({
                    auction
                }, () => {
                    this._fetchBids();
                    this._fetchFalloffReasons();
                    this._fetchRelevantBids();
                });
            }
        }
    }

    /**
     * Fetches all possible falloff reasons
     *
     * @private
     */
    _fetchFalloffReasons() {
        TenderAuctionCarrierApiService.getFalloffReasons().then(reasons => {
            this.setState({ falloffReasons: reasons.data });
        });
    }

    /**
     * Fetches and stores dispatch details to state if carrier has provided carrier details
     *
     * @param {Object} bid carrier's relevant bid
     * @param {Object} auction current auction
     */
    _fetchDispatchDetails(bid, auction) {
        if (BID_STATUSES.WINNER === bid?.status && bid?.candidate.acceptedTermsOfService && AUCTION_STATUSES.READY === auction.status) {
            TenderAuctionCarrierApiService.getDispatchDetails(auction.id).then(dispatchDetails => {
                this.setState({ dispatchDetails });
            });
        }
    }

     /**
     * Fetches and stores payment details to state if carrier is the confirmed carrier
     *
     * @param {Object} bid carrier's relevant bid
     */
    _fetchPaymentDetails(bid, auction) {
        if (WINNING_BID_STATUSES.includes(bid?.status) && CONFIRMED_AUCTION_STATUSES.includes(auction.status)) {
            PaymentDetailsApiService.getOnAuction(auction.id)
                .then(response => this.setState({ paymentDetails: response.data }))
                .catch(error => console.error('An error occurred while fetching payment details for auction', error));
        }
    }

    _fetchAvailableCarrierDispatchers() {
        TenderAuctionCarrierApiService.getAvailableCarrierDispatchers().then(response => this.setState(prevState => {
            const { auction } = prevState;
            const notAlreadyAdded = response.data.filter(dispatcher => !auction.carrierDispatchers.find(cd => cd.id === dispatcher.id));
            return { 
                availableCarrierDispatchers: notAlreadyAdded
            };
        }));
    }

    _onOpenToS() {
        this.setState({ showToSPopup: true });
    }

    _onCloseToS() {
        this.setState({ showToSPopup: false });
    }

    /**
     * Sends carrier details
     *
     * @param { Object } details
     * @private
     */
    _sendDetails(details) {
        return TenderAuctionCarrierApiService.sendDetails(this.state.auction.id, details)
            .then(this._fetchData);
    }

    /**
     * Sends request to cancel offer
     *
     * @param { Object } details
     * @private
     */
    _cancelOffer(reason) {
        return TenderAuctionCarrierApiService.cancelOffer(this.state.auction.id, reason.id)
           .then(this._fetchData); 
    }

    /* Sends the request to post a bid
     *
     * @param { Object } price
     * @private
     */
    _postBid(price) {
        TenderAuctionCarrierApiService.postBid(this.state.auction.id, price)
            .then(this._fetchData);
    }

    /**
     * Sends request to book the load immediately for 'book now' price
     *
     * @param { Object } price
     * @private
     */
    _bookNow() {
        TenderAuctionCarrierApiService.bookNow(this.state.auction.id)
            .then(this._fetchData);
    }

    _setBidsPage(bidsPage) {
        this.setState({ bidsPage });
    }

    _setBidsPageSize(bidsPageSize) {
        this.setState({ bidsPageSize });
    }

    /**
     * Determines the breadcrumbs based on the relevant bid status which positions it on a certain screen
     *
     * @private
     */
    _formBreadcrumbs() {
        const { auction, relevantBids } = this.state;
        const { status, load } = auction;
        const { businessId } = load;
        let crumbs = [];

        if (CONFIRMED_AUCTION_STATUSES.includes(status) && relevantBids.carrierRelevantBid?.current && LOAD_STATUSES.COMPLETED !== load.status) {
            crumbs=[
                { path: '/booked-loads', name: 'Booked Loads', backCrumb: false },
                { path: '', name: `Order #${ businessId }`, backCrumb: false },
                { path: '/booked-loads', name: 'Back to Booked Loads', backCrumb: true },
            ];
        } else if (LOAD_STATUSES.COMPLETED === load.status && AUCTION_STATUSES.READY === status && relevantBids.carrierRelevantBid?.current) {
            crumbs= [
                { path: '/load-history', name: 'Load History', backCrumb: false },
                { path: '', name: `Order #${ businessId }`, backCrumb: false },
                { path: '/load-history', name: 'Back to Load History', backCrumb: true },
            ];
        } else {
            crumbs= [
                { path: '/load-board', name: 'Load Board', backCrumb: false },
                { path: '', name: `Order #${ businessId }`, backCrumb: false },
                { path: '/load-board', name: 'Back to Load Board', backCrumb: true },
            ];
        }

        return (
            <Breadcrumbs crumbs={ crumbs } />
        );
    }

    _addExistingCarrierDispatcher(dispatcher) {
        return TenderAuctionCarrierApiService.addExistingCarrierDispatcher(this._getAuctionId(), dispatcher.id).then(this._fetchData);
    }

    _addNewCarrierDispatcher(dispatcher) {
        return TenderAuctionCarrierApiService.addNewCarrierDispatcher(this._getAuctionId(), dispatcher).then(this._fetchData);
    }

    _editCarrierDispatcher(id, dispatcher) {
       TenderAuctionCarrierApiService.editCarrierDispatcher(id, dispatcher).then(this._fetchData);
    }

    _removeCarrierDispatcher(id) {
        TenderAuctionCarrierApiService.removeCarrierDispatcher(this._getAuctionId(), id).then(this._fetchData);
    }

    async _onAcceptToS() {
        try {
            await TenderAuctionCarrierApiService.acceptToS(this._getAuctionId());
            if (this.state.showToSPopup) {
                this._onCloseToS();
            }

            this._fetchData();
        } catch (error) {
            console.error('An error occurred while accepting terms of service for a load.', error);
        }
    }

    _carrierDispatchersContentProvider(dispatcher) {
        const emptyInfoText = 'There are currently no Carrier Dispatchers for this load.';
        const removePopupHeading = <p className="heading">Remove Carrier Dispatcher</p>;

        let removePopupDescription = <></>;
        if (dispatcher) {
            removePopupDescription = (
                <p className="description">
                    Are you sure you want to remove the Carrier Dispatcher <b> { dispatcher.name } </b> from this load?
                </p>
            );
        }

        const editInfoText = 'Editing will affect this Carrier Dispatcher\'s information on all loads they are assigned to.';
        const editPopupHeading = <p className="heading">Edit Carrier Dispatcher</p>;
        const editPopupDescription = (
            <>
                <p className="description">
                    Are you sure you want to submit these changes?
                </p>
                <p className="description">
                    Please note that the changes will be applied to all loads this dispatcher is assigned to.
                </p>
            </>
        );

        const addExistingButtonText = 'Add Existing Dispatcher';
        const addExistingButtonDescription = 'Assign one of the existing dispatchers to this load.';
        const addNewButtonText = 'Add New Dispatcher';
        const addNewButtonDescription = 'Input details for a new dispatcher and assign them to this load.';
        const mainLabel = 'Dispatcher';

        return { 
            emptyInfoText,
            removePopupHeading,
            removePopupDescription,
            editInfoText,
            editPopupHeading,
            editPopupDescription,
            addExistingButtonText,
            addExistingButtonDescription,
            addNewButtonText,
            addNewButtonDescription,
            mainLabel
        };
    }

    _formCarrierDispatcherDetails() {
        const { relevantBids, auction } = this.state;

        if (!relevantBids.carrierRelevantBid || (WINNING_BID_STATUSES.indexOf(relevantBids.carrierRelevantBid.status) === -1 && FALLOFF_BID_STATUSES.indexOf(relevantBids.carrierRelevantBid.status) === -1)) {
            return <></>;
        }

        return (
            <div className="carrier-dispatchers-card">
                <CoordinatorDetailsComponent 
                    heading="Carrier Dispatchers"
                    editable={ CONFIRMED_AUCTION_STATUSES.includes(auction.status) }
                    coordinators={ auction.carrierDispatchers }
                    existingCoordinators={ this.state.availableCarrierDispatchers }
                    onAddExisting={ this._addExistingCarrierDispatcher }
                    onAddNew={ this._addNewCarrierDispatcher }
                    onEdit={ this._editCarrierDispatcher }
                    onRemove={ this._removeCarrierDispatcher }
                    contentProvider={ this._carrierDispatchersContentProvider } 
                />
            </div>
        );
    }

    _formAcceptToSBanner() {
        const { relevantBids, auction } = this.state;
        const { carrierRelevantBid } = relevantBids;

        if (!carrierRelevantBid?.candidate.acceptedTermsOfService && WINNING_BID_STATUSES.includes(carrierRelevantBid?.status) && CONFIRMED_AUCTION_STATUSES.includes(auction.status)) {
            return (
                <Banner 
                    type="warn" 
                    content={
                        <p>
                            <b>Dispatch details not available</b> - In order to see dispatch details, you must accept our Terms of Service.
                        </p>
                    }
                    action={
                        <LoadTermsOfServicePopup
                            auction={ auction }
                            price={ carrierRelevantBid.price }
                            showPopup={ this.state.showToSPopup }
                            trigger={
                                <p className="accept-tos-trigger" onClick={ this._onOpenToS }>
                                    <b >Accept Terms of Service</b>
                                </p>
                            }
                            onClosePopup={ this._onCloseToS }
                            onActionPerformed={ this._onAcceptToS }
                        />
                    }
                    className="accept-tos-banner"
                />
            );
        }

        return <></>;
    }

    /**
     * Creates the whole page based on the auction phase and status
     *
     * @private
     */
    _formDetailsViewPage() {
        const { auction, relevantBids, hasSpotMarketPhasePassed } = this.state;
        const { phase, status, load } = auction;

        if (!AuctionUtils.isAuctionVisibleToCarrier(auction, relevantBids.carrierRelevantBid, hasSpotMarketPhasePassed)) {
            return <div className="load-details-page loading" />
        }

        let content, tosBanner = <></>;
        if (AUCTION_PHASE.SPOT_MARKET === phase && CARRIER_ONGOING_SM_AUCTION_STATUSES.includes(status)) {
            content = this._formSpotMarketDetailsView();
        } else if (OFFERING_AUCTION_STATUSES.includes(status) && BID_STATUSES.PENDING === relevantBids.carrierRelevantBid?.status && relevantBids.carrierRelevantBid?.current) {
            content = this._formOfferedLoadDetailsView();
        } else {
            content = this._formBookedLoadDetailsView();
            tosBanner = this._formAcceptToSBanner();
        }

        return (
            <div className="page load-details-page">
                { this._formBreadcrumbs() }
                { tosBanner }
                <HeadingComponent load={ load } account={ this.props.account } />
                { content }
                { this._formCarrierDispatcherDetails() }
                <LogisticsCoordinators routingGuideLane={ load.routingGuideLane } />
            </div>
        );
    }

    /**
     * Creates the booked loads details view page
     *
     * @private
     */
    _formBookedLoadDetailsView() {
        const { auction, relevantBids, falloffReasons } = this.state;
        const { status, load, phase } = auction;
        const details = relevantBids.carrierRelevantBid && relevantBids.carrierRelevantBid.details.length > 0 ? relevantBids.carrierRelevantBid.details.find(d => d.current === true) : null;

        let downloadRateConfirmationAction = <></>;
        if (!!relevantBids.carrierRelevantBid && WINNING_BID_STATUSES.includes(relevantBids.carrierRelevantBid.status) && CONFIRMED_AUCTION_STATUSES.includes(auction.status)) {
            downloadRateConfirmationAction = (
                <div>
                    <DownloadRateConfirmationPopup
                        auctionId={auction.id}
                        carrierId={ relevantBids.carrierRelevantBid.candidate.carrier.id }
                    />
                </div>
            );
        }

        const carrierActions = (
            <>
                <CarrierActions
                    type="cancel"
                    cancelOfferProps={{
                        onCancelOffer: this._cancelOffer,
                        availableFalloffReasons: falloffReasons,
                        disabled: AuctionUtils.isAuctionCompleted(auction) || !relevantBids.carrierRelevantBid || OFFER_NOT_CANCELABLE_BID_STATUSES.includes(relevantBids.carrierRelevantBid?.status)
                    }}
                />
                { downloadRateConfirmationAction }
            </>
        );
        
        const bidInformationCard = (
            <OfferInformationComponent
                account={ this.props.account }
                hasInvalidDocuments={ false }
                bid={ relevantBids.carrierRelevantBid }
                load={ load }
                auctionId={ auction.id }
                auctionDeadline={ new Date(auction.deadline) }
                actions={ carrierActions }
                auctionStatus={ status }
                auctionPhase={ phase }
            />
        );

        let winnerDetailsCard = <></>;
        let dispatchDetailsCard = <></>;
        let paymentDetailsCard = <></>;

        if (!!relevantBids.carrierRelevantBid && !FAILED_BID_STATUSES.includes(relevantBids.carrierRelevantBid.status) && BID_STATUSES.PENDING !== relevantBids.carrierRelevantBid.status) {
            const detailsSubmissionDeadline = DateUtils.isBefore(new Date(load.pickupTime), new Date(auction.deadline)) ? auction.deadline : load.pickupTime;
            const detailsSubmissionDeadlinePassed = DateUtils.isBefore(new Date(detailsSubmissionDeadline), new Date());
            
            winnerDetailsCard = (
                <WinnerDetailsComponent
                    auctionId={ auction.id }
                    auction={ auction }
                    price={ relevantBids.carrierRelevantBid.price }
                    driver={ details }
                    initiallyEditing={ true }
                    onSendDetails={ this._sendDetails }
                    canEditAndSend={ AUCTION_STATUSES.CANCELED !== auction.status && !TERMINAL_BID_STATUSES.includes(relevantBids.carrierRelevantBid.status) && !detailsSubmissionDeadlinePassed }
                    showWarningMessage={ !details && detailsSubmissionDeadlinePassed && AUCTION_STATUSES.CARRIER_FALLOFF !== status }
                    laneType={ load.routingGuideLane.lane.type }
                    showCarrierToS={ !relevantBids.carrierRelevantBid.candidate.acceptedTermsOfService && CONFIRMED_AUCTION_STATUSES.includes(auction.status) }
                    acceptToS={ this._onAcceptToS }
                    driverInfoRequired={ !!relevantBids.carrierRelevantBid.candidate.driverInfoRequired }
                    account={ this.props.account }
                />
            );

            dispatchDetailsCard = (
                <DispatchDetailsComponent
                    dispatchDetails={ this.state.dispatchDetails }
                    providedWinnerDetails={ !!details || !relevantBids.carrierRelevantBid.candidate.driverInfoRequired }
                    isCarrier={ true }
                    isTenderCanceled={ AUCTION_STATUSES.CANCELED === status }
                    didCarrierFalloff={ FALLOFF_BID_STATUSES.includes(relevantBids.carrierRelevantBid.status) }
                    isBidApproved={ relevantBids.carrierRelevantBid.approved }
                    toSAccepted={ relevantBids.carrierRelevantBid.candidate.acceptedTermsOfService }
                />
            );

            paymentDetailsCard = <PaymentDetailsCard paymentDetails={ this.state.paymentDetails } />;
        }

        return (
            <>
                { bidInformationCard }
                { winnerDetailsCard }
                { dispatchDetailsCard }
                { paymentDetailsCard }
            </>
        );
    }

    /**
     * Creates the offered load details view page (where carrier can accept/reject an offer)
     *
     * @private
     */
    _formOfferedLoadDetailsView() {
        const { auction, relevantBids: { carrierRelevantBid } } = this.state;
        const { load, status } = auction;
        const [ duration ] = AuctionUtils.determineTimeRemaining(auction, carrierRelevantBid);

        return (
            <OfferInformationComponent
                account={ this.props.account }
                hasInvalidDocuments={ false }
                bid={ carrierRelevantBid }
                load={ load }
                showStatus={ false }
                auctionDeadline={ new Date(auction.deadline) }
                auctionStatus={ status }
                isOffered={ true }
                offerExpiration={ { duration, expirationDate: new Date(carrierRelevantBid.expiration) } }
                actions={
                    <CarrierActions
                        type="accept/reject"
                        acceptRejectOfferProps={ {
                            auction: auction,
                            price: carrierRelevantBid.price,
                            lane: load.routingGuideLane.lane,
                            onActionPerformed: this._fetchData
                        } }
                    />
                }
            />
        );
    }

    /* Creates the spot market loads details view page
     *
     * @private
     */
    _formSpotMarketDetailsView() {
        const carrierId = this.props.account?.carrierId;
        const { auction, bids, relevantBids } = this.state;
        const { load } = auction;

        return (
            <div className="sm-main-content">
                <div className="component bid-history-div">
                    <h6 className="title">
                        Bid History
                    </h6>

                    <Separator />
                    <div className="content">
                        <BidsList
                            bids={ bids }
                            carrierId={ carrierId }
                            distance={ load.routingGuideLane.distance }
                        />      
                    </div> 
                    { this.state.bidsAvailable !== 0 && 
                        <Pagination 
                            pageNumber={ this.state.bidsPage }
                            pageSize={ this.state.bidsPageSize }
                            available={ this.state.bidsAvailable }
                            paginationValues={ BID_PAGINATION_VALUES }
                            onSetPage={ this._setBidsPage }
                            onSetPageSize={ this._setBidsPageSize }
                            fixed={ false }
                            colorTheme="light"
                        />
                    }
                </div>

                <div className="current-bid-div">
                    <CurrentBid
                        auction={ auction }
                        lowestBid={ relevantBids.auctionRelevantBid }
                        carrierBestBid={ relevantBids.carrierRelevantBid }
                        negotiatedRate={ this.state.negotiatedRate }
                        onPostBid={ this._postBid }
                        onBookNow={ this._bookNow }
                    />
                </div>
            </div>
        );
    }

    render() {
        let detailsViewPage = <></>;

        if (this.state.auction) {
            detailsViewPage = this._formDetailsViewPage();
        }

        return ( detailsViewPage );
    }
}
