import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltSmile as messageIcon } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

import { FORM_STATUSES } from 'common/constants';
import Button from 'component/Button';
import SurveyQuestions from 'component/survey/SurveyQuestions';
import Popup from 'component/Popup';
import SurveyFloatingButton from 'component/survey/SurveyFloatingButton';
import FormStatusModal from 'component/form/FormStatusModal';
import SurveyApiService from 'service/api/SurveyApiService';
import DateUtils from 'utils/DateUtils';
import StringUtils from 'utils/StringUtils';

import './SurveyPopup.scss';

const STATES = {
    PREVIEW: 'preview',
    QUESTIONS: 'questions',
    COMPLETED: 'completed'
};

export default class SurveyPopup extends Component {
    constructor(props) {
        super(props);

        this._closePopup = this._closePopup.bind(this);
        this._openSurvey = this._openSurvey.bind(this);
        this._handleSurveyCompleted = this._handleSurveyCompleted.bind(this);
        this._updateShownField = this._updateShownField.bind(this);
        this._handleSurveyPopUpOpened = this._handleSurveyPopUpOpened.bind(this);
        this._saveAnswers = this._saveAnswers.bind(this);
        this._setActiveStep = this._setActiveStep.bind(this);
        this._onCancelSurvey = this._onCancelSurvey.bind(this);
        this._onContinueSurvey = this._onContinueSurvey.bind(this);
        this._onUnload = this._onUnload.bind(this);
    }

    state = {
        activeState: STATES.PREVIEW,
        candidate: null,
        showPopup: true,
        showCancelSurvey: false,
        activeStep: 0,
        answers: []
    }

    componentDidMount() {
        this._fetchActiveSurvey();
    }

    componentDidUpdate() {
        if (this.state.showPopup) {
            window.addEventListener('beforeunload', this._onUnload);
        } else {
            window.removeEventListener('beforeunload', this._onUnload);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this._onUnload);
    }

    _onUnload(event) {
        if (STATES.QUESTIONS === this.state.activeState) {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to cancel this survey? Any given feedback will not be submitted.';
        }
    }

    _fetchActiveSurvey() {
        SurveyApiService.getActive()
            .then(response => {
                if (response) {
                    this.setState({
                        candidate: response,
                        showPopup: !response.shown
                    });

                    if (response.shown === null) {
                        this._updateShownField();
                    }
                }
            });
    }

    _updateShownField() {
        SurveyApiService.updateShownField(this.state.candidate.survey.id)
            .then(shown => {
                this.setState(prevState => {
                    const candidate = { ...prevState.candidate };
                    candidate.shown = shown;
                    return  { candidate };
                });
            });
    }

    _closePopup() {
        this.setState({
            showPopup: false,
            showCancelSurvey: false,
            activeStep: 0,
            answers: []
        });
    }

    _handleSurveyPopUpOpened() {
        this.setState({
            activeState: STATES.PREVIEW,
            showPopup: true
        });
    }

    _openSurvey() {
        this.setState({ activeState: STATES.QUESTIONS });
    }

    _handleSurveyCompleted() {
        SurveyApiService.submitAnswers(this.state.candidate.survey.id, this.state.answers)
            .then(() => {
                this.setState({ activeState: STATES.COMPLETED });

                setTimeout(this._closePopup, 4500);
            });
    }

    _saveAnswers(answers) {
        this.setState({ answers });
    }

    _setActiveStep(activeStep) {
        this.setState({ activeStep });
    }

    _formSurveyPreview() {
        const numberOfQuestions = this.state.candidate.survey.questions.length;

        return (
            <div className="survey-popup-component">
                <FontAwesomeIcon className="survey-popup-icon" icon={ messageIcon } />
                <h6 className="survey-popup-heading"><b>Take a quick survey?</b></h6>
    
                <div className="survey-popup-content">
                    <p>We strongly value your feedback and would appreciate it if you could answer { numberOfQuestions } { StringUtils.pluralize(numberOfQuestions, 'question') } for us.
                       This should take no longer than a few minutes.
                    </p>
                </div>
                            
                <div className="survey-popup-actions">
                    <Button 
                        type="tertiary"
                        size="regular"
                        onClick={ this._closePopup }
                        className="first-button"
                    >
                        No, Thank You
                    </Button>
    
                    <Button 
                        type="primary"
                        size="regular"
                        onClick={ this._openSurvey }
                    >
                        Take A Survey
                    </Button>
                </div>
            </div>
        );
    }

    _formSurveyCompleted() { 
        return (
            <div className="complete-survey-container">
                <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._closePopup }>
                    <div>
                        <h6>Survey Complete</h6>
                        <p>Thank you for taking the time to give us your feedback.</p>
                    </div> 
                </FormStatusModal>
            </div>
        );
    }

    _formSurveyQuestions() {
        return (
            <SurveyQuestions
                surveyId={ this.state.candidate.survey.id }
                questions={ this.state.candidate.survey.questions }
                activeStep={ this.state.activeStep }
                answers={ this.state.answers }
                onSurveyCompleted={ this._handleSurveyCompleted }
                onSurveyClosed={ this._onCancelSurvey }
                saveAnswers={ this._saveAnswers }
                setActiveStep={ this._setActiveStep }
            />
        );
    }

    _onCancelSurvey() {
        this.setState({ showCancelSurvey: true });
    }

    _onContinueSurvey() {
        this.setState({ showCancelSurvey: false });
    }

    _formCancelSurvey() {
        return (
            <div className="cancel-survey-container">
                <div className="cancel-survey-heading">
                    <b>Cancel survey</b>
                </div>
                <div className="cancel-survey-content">
                    Are you sure you want to cancel this survey? Any given feedback will not be submitted.
                </div>
                <div className="cancel-survey-actions">
                    <Button
                        type="tertiary"
                        size="small"
                        onClick={ this._onContinueSurvey }
                    >
                        Back
                    </Button>

                    <Button
                        type="danger"
                        size="small"
                        onClick={ this._closePopup }
                    >
                        Continue
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const now = moment();
        const endDate = moment(this.state.candidate?.shown).add(this.state.candidate?.survey.durationHours, 'hours');
        const { activeState } = this.state;

        // We want to show popup only if active survey exists and user has remaining days left and has not already completed survey
        if (!this.state.candidate || this.state.candidate.completed !== null || DateUtils.isAfter(now, endDate)) {
            return <></>;
        }
        
        let content;
        if (this.state.showCancelSurvey) {
            content = this._formCancelSurvey();
        } else if (STATES.PREVIEW === activeState) {
            content = this._formSurveyPreview();
        } else if (STATES.QUESTIONS === activeState) {
            content = this._formSurveyQuestions();
        } else if (STATES.COMPLETED === activeState) {
            content = this._formSurveyCompleted();
        }

        let trigger = <></>;
        if (!this.state.showPopup && STATES.COMPLETED !== activeState) {
            trigger = (
                <SurveyFloatingButton
                    onClick={ this._handleSurveyPopUpOpened }
                    remainingTime={ DateUtils.difference(now, endDate) }
                    survey={ this.state.candidate.survey }
                />
            );
        }

        return (
            <div className={ `survey-popup-page ${ STATES.QUESTIONS === activeState ? 'survey-popup-questions' : '' }` }>
                <Popup
                    id="popup-survey-popup"
                    size={ this.state.showCancelSurvey ? "small" : "medium" }
                    show={ this.state.showPopup }
                    onClose={ STATES.QUESTIONS === activeState ? this._onCancelSurvey : this._closePopup }
                    trigger={ trigger }
                >
                    { content }
                </Popup>
            </div>
        );
    }
}
