import RestService from 'service/RestService';

/**
 * Service class that provides static methods and endpoints for API calls regarding tender auction candidates
 */
export default class TenderAuctionCandidateApiService {

    /**
     * Gets a list of offered candidates
     * 
     * @param auctionId id of auction for which candidates are 
     * @returns list of dedicated and on-demand candidates
     */
    static async fetchOfferedCandidates(auctionId) {
        const candidates = await RestService.instance().get(`auction/tendering/${ auctionId }/candidates/offered`);
        return candidates;
    }

    /** 
     * Gets a page of spot market candidates which are part of auction
     * 
     * @param {string} auctionId id of auction
     * @param {string} search string for candidates search
     * @param {number} pageNumber number of page for the spot market candidates
     * @param {number} pageSize number of spot market candidates per page
     * @param {string} sort sorting order
     * @returns {Promise}
     */
    static filterSMCandidates(auctionId, search, pageNumber, pageSize, sort) {
        return RestService.instance().get(`auction/tendering/${ auctionId }/candidates/spot-market`, { search, pageNumber, pageSize, sort});
    }

    /**
     * Checks if there are spot market carriers on auction
     * 
     * @param {string} auctionId
     * @returns {Promise}
     */
    static hasSpotMarketCarriers(auctionId) {
        return RestService.instance().get(`auction/tendering/${ auctionId }/candidates/spot-market/exists`);
    }
}
