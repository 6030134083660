import { Component } from 'react';

import { SCREENS } from 'common/constants';
import AuthorizationService from 'service/AuthorizationService';
import Search from 'component/filter/Search';

export default class GlobalSearch extends Component {

    state = {
        isCarrier: true
    }

    async componentDidMount() {
        this.setState({
            isCarrier: !(await AuthorizationService.instance().canAccess('load:list'))
        });
    }

    render() {
        const placeholder = this.state.isCarrier ? 'Search orders by order ID' : 'Search orders by order ID or carrier ID';

        return (
            <Search
                path={ `/${ SCREENS.LOAD_SEARCH }` }
                placeholder={ placeholder }
            />
        );
    }
}
