import RestService from 'service/RestService';

/**
  * Service class that provides static methods for API calls regarding Routing Guide
  */
export default class RoutingGuideApiService {


    /**
     * Gets candidates on Routing Guide
     * 
     * @param {string} id id of routing guide
     * @param {string} search optional search string 
     * @param {number} pageSize number of candidates to be fetched
     * @param {number} pageNumber number of page from which candidates are being fetched
     * @returns {Promise}
     */
    static getCandidates(id, search, pageNumber, pageSize) {
      return  RestService.instance().get(`routing-guide/${ id }/candidate`, { search, pageNumber, pageSize });
    }
}
