import { Component } from 'react';
import PropTypes from 'prop-types';
import { faTruck as truckIcon } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import Button from 'component/Button';
import ContentCard from 'component/card/ContentCard';
import FormStatusModal from 'component/form/FormStatusModal';
import DispatchDetailsStopOverview from 'component/load/DispatchDetailsStopOverview';
import Banner from 'component/Banner';
import Popup from 'component/Popup';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';

import './DispatchDetailsComponent.scss';

export default class DispatchDetailsComponent extends Component {
    static propTypes = {
        dispatchDetails: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            stopType: PropTypes.oneOf(['PICKUP', 'DELIVERY']),
            ordinal: PropTypes.number,
            earliestTime: PropTypes.string,
            latestTime: PropTypes.string,
            reference: PropTypes.string,
            companyId: PropTypes.string,
            companyName: PropTypes.string,
            companyLocation: PropTypes.object
        })),
        carrierName: PropTypes.string,
        loadBusinessId: PropTypes.string,
        auctionId: PropTypes.string,
        providedWinnerDetails: PropTypes.bool,
        isCarrier: PropTypes.bool,
        isTenderCanceled: PropTypes.bool,
        didCarrierFalloff: PropTypes.bool,
        dispatchDisabled: PropTypes.bool,
        isBidApproved: PropTypes.bool,
        toSAccepted: PropTypes.bool,
        onCarrierDispatch: PropTypes.func
    };

    static defaultProps = {
        dispatchDetails: [],
        carrierName: null,
        loadBusinessId: null,
        auctionId: null,
        providedWinnerDetails: false,
        isCarrier: false,
        isTenderCanceled: false,
        didCarrierFalloff: false,
        dispatchDisabled: false,
        isBidApproved: true,
        toSAccepted: true,
        onCarrierDispatch: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onOpenDispatchCarrierPopup = this._onOpenDispatchCarrierPopup.bind(this);
        this._onCloseDispatchCarrierPopup = this._onCloseDispatchCarrierPopup.bind(this);
        this._onCarrierDispatch = this._onCarrierDispatch.bind(this);
    }

    state = {
        showDispatchCarrierPopup: false,
        dispatchCarrierPopupStatus: undefined,
        errorMessage: undefined
    }

    _onOpenDispatchCarrierPopup(event) {
        event.stopPropagation();
        this.setState({ showDispatchCarrierPopup: true, dispatchCarrierPopupStatus: undefined });
    }

    _onCloseDispatchCarrierPopup() {
        if (FORM_STATUSES.LOADING !== this.state.dispatchCarrierPopupStatus) {
            this.setState({ showDispatchCarrierPopup: false }); 
        }
    }

    _onCarrierDispatch() {
        this.setState({ dispatchCarrierPopupStatus: FORM_STATUSES.LOADING });

        TenderAuctionBrokerApiService.dispatchCarrier(this.props.auctionId)
            .then(() => this.setState({ dispatchCarrierPopupStatus: FORM_STATUSES.SUCCESS }, this._onCloseDispatchCarrierPopup))
            .catch(error => this.setState({ 
                dispatchCarrierPopupStatus: FORM_STATUSES.ERROR, 
                errorMessage: error.response.data.status === 500 ? 'Something went wrong. Please try again later.' : error.response.data
            }))
            .finally(this.props.onCarrierDispatch);
    }

    _formDispatchCarrierAction() {
        let content;
        let popupSize = 'small';
        switch (this.state.dispatchCarrierPopupStatus) {
            case FORM_STATUSES.LOADING:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.LOADING }>
                        <div>
                            <h6>Dispatching Carrier</h6>
                            <p>This will only take a moment. Please wait...</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            case FORM_STATUSES.ERROR:
                popupSize = 'medium';
                content = (
                    <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseDispatchCarrierPopup }>
                        <div>
                            <h6>Carrier Dispatch Failure</h6>
                            <p>{ this.state.errorMessage }</p>
                        </div>
                    </FormStatusModal>
                );
                break;
            default:
                content = (
                    <>
                        <p className="heading">Confirm Carrier Dispatch</p>

                        <p className="description">Once the carrier details are provided and the carrier is dispatched, the load/dispatch details will become available to that carrier.</p>

                        <p className="description">Please confirm the dispatching of carrier <b>{ this.props.carrierName }</b> on load <b>#{ this.props.loadBusinessId }</b>.</p>

                        <Banner content={ <>This carrier booked the load using the <b>Book Now</b> feature.</> } />

                        <div className="buttons-wrapper">
                            <Button type="tertiary" size="small" onClick={ this._onCloseDispatchCarrierPopup } >
                                Cancel
                            </Button>

                            <Button size="small" onClick={ this._onCarrierDispatch } >
                                Confirm
                            </Button>
                        </div>
                    </>
                );
        }

        return (
            <Popup 
                id="dispatch-carrier-popup"
                key="dispatch-carrier-popup"
                size={ popupSize }
                show={ this.state.showDispatchCarrierPopup }
                onClose={ this._onCloseDispatchCarrierPopup }
                trigger={
                    <Button 
                        className="dispatch-carrier-action" 
                        leftIcon={ truckIcon } 
                        onClick={ this._onOpenDispatchCarrierPopup } 
                        disabled={ this.props.dispatchDisabled }
                    >
                        Dispatch Carrier
                    </Button>
                }
            >
                <div>
                    { content }
                </div>
            </Popup>
        );
    }

    render() {
        const { dispatchDetails, providedWinnerDetails, isCarrier, isTenderCanceled, didCarrierFalloff, isBidApproved, toSAccepted } = this.props;

        let infoDiv = <></>, actions;
        if (isCarrier) {
            if (didCarrierFalloff) {
                infoDiv = (
                    <Banner content="Load/dispatch details can't be shown if you cancel the load." />
                );
            } else if (isTenderCanceled) {
                infoDiv = (
                    <Banner content="Load/dispatch details can't be shown if the load is canceled." />
                );
            } else if (!providedWinnerDetails) {
                infoDiv = (
                    <Banner content="Load/dispatch details will be revealed in the application after you submit carrier details." />
                );
            } else if (!toSAccepted) {
                infoDiv = (
                    <Banner content="In order to see load/dispatch details, Terms of Service must be accepted." />
                );
            }
            else if (!isBidApproved) {
                infoDiv = (
                    <Banner content="Once the load/dispatch details are available, you will be notified via email." />
                );
            }
        } else {
            if (didCarrierFalloff || isTenderCanceled) {
                infoDiv = <></>;
            } else if (!isBidApproved) {
                infoDiv = (
                    <Banner type="warn" content="The carrier won't be able to see the load/dispatch details until you dispatch them." />
                );

                actions = this._formDispatchCarrierAction();
            } else if (!providedWinnerDetails) {
                infoDiv = (
                    <Banner content="Load/dispatch details won't be sent to the carrier until carrier details are provided." />
                );
            } else if (!toSAccepted) {
                infoDiv = (
                    <Banner content="Load/dispatch details will be visible to carrier once they accept Terms of Service." />
                );
            } else {
                infoDiv = (
                    <Banner type="success" content="Load/dispatch details have been sent to the carrier." />
                );
            }
        }

        const stopDetails = [];
        if ((dispatchDetails || []).length > 0 && (!isCarrier || (providedWinnerDetails && !didCarrierFalloff && !isTenderCanceled && toSAccepted))) {
            for (const dispatchDetail of dispatchDetails) {
                stopDetails.push(<DispatchDetailsStopOverview key={ dispatchDetail.id } stop={ dispatchDetail } isCarrier={ isCarrier } />);
            }
        } else {
            stopDetails.push(<DispatchDetailsStopOverview key="pickup-empty" stop={{ id: 'pickup-empty', stopType: 'PICKUP' }} isCarrier={ isCarrier } />);
            stopDetails.push(<DispatchDetailsStopOverview key="delivery-empty" stop={{ id: 'delivery-empty', stopType: 'DELIVERY' }} isCarrier={ isCarrier } />);
        }

        return (
            <div className="dispatch-details">
                <ContentCard 
                    heading="Load/Dispatch Details"
                    isExpandable={ true }
                    info={ infoDiv }
                    actions={ actions }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    className="main-card"
                >
                    { stopDetails }
                </ContentCard>
            </div>
        );
    }
}
