import { Component } from 'react';
import PropTypes from 'prop-types';
import { FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import FormStatusModal from 'component/form/FormStatusModal';

/**
 * This popup should be used when we wait for a response for a longer period. It's default state is LOADING,
 * and it should be changed per need. Common use is to switch state to SUCCESS after request is completed, 
 * or in ERROR state if request fails. 
 */
export default class LoadingPopup extends Component {

    static propTypes = {
        status: PropTypes.string,
        show: PropTypes.bool,
        onClose: PropTypes.func,
        successElement: PropTypes.element,
        emptySuccessElement: PropTypes.element,
        errorElement: PropTypes.element
    }

    static defaultProps = {
        status: FORM_STATUSES.LOADING,
        show: false,
        onClose: () => { /* */ },
        successElement: <></>,
        emptySuccessElement: <></>,
        errorElement: <></>

    }

    _formStatusLayout() {
        let content;
        switch (this.props.status) {
            case FORM_STATUSES.LOADING:
                content = this.props.children;
                break;
            case FORM_STATUSES.SUCCESS:
                content = this.props.successElement;
                break;
            case FORM_STATUSES.EMPTY_SUCCESS:
                content = this.props.emptySuccessElement;
                break;
            case FORM_STATUSES.ERROR:
                content = this.props.errorElement;
                break;
            default:
                return <></>;
        }

        return (
            <FormStatusModal status={ this.props.status } onContinue={ this.props.onClose } >
                { content }
            </FormStatusModal>
        );
    }

    render() {
        return (
            <div>
                <Popup
                    id="loading-popup"
                    size="medium"
                    show={ this.props.show }
                    onClose={ this.props.onClose }
                    trigger={ <></> }
                >
                   { this._formStatusLayout() }
                </Popup>
            </div>
        );
    }
}
