import { Component } from 'react';
import PropTypes from 'prop-types';

import { RFP_AUCTION_STATUSES, RFP_ROUND_TYPE } from 'common/constants';
import TitledCardComponent from 'component/card/TitledCardComponent';
import TextEmphasisBox from 'component/TextEmphasisBox';

export default class RFPAuctionStatus extends Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        showGeneralStatus : PropTypes.bool,
        round: PropTypes.object,
        size: PropTypes.oneOf([ 'regular', 'large' ]),
        withTitle: PropTypes.bool,
        className: PropTypes.string
    };

    static defaultProps = {
        round: null,
        showGeneralStatus: false,
        size: 'regular',
        withTitle: true,
        className: ''
    };

    _status() {
        let statusText, color;
        const { status } = this.props;
        if (this.props.showGeneralStatus && (status === RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS || status === RFP_AUCTION_STATUSES.IN_REVIEW)) {
            color = 'dark-grey';
            statusText = 'RFP - In Progress';
        } else {
            switch (this.props.status) {
                case RFP_AUCTION_STATUSES.PENDING:
                    color = 'grey';
                    statusText = 'Not Published';
                    break;
                case RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS:
                    color = 'blue';
                    statusText = this._offeringToCarriers();
                    break;
                case RFP_AUCTION_STATUSES.IN_REVIEW:
                    color = 'orange'
                    statusText = this._inReview();
                    break;
                case RFP_AUCTION_STATUSES.CONFIRMED:
                    color = 'dark-grey'
                    statusText = 'Confirmed';
                    break;
                case RFP_AUCTION_STATUSES.APPLIED:
                    color = 'cyan'
                    statusText = 'Applied';
                    break;
                case RFP_AUCTION_STATUSES.COMPLETED:
                    color = 'grey';
                    statusText = 'Completed';
                    break;
                default:
                    throw new Error('Unknown rfp status ' + this.props.status);
            }
        }

        return (
            <TextEmphasisBox size="small" color={ color } wide={ true }>
                { statusText }
            </TextEmphasisBox>
        );
    }

    _offeringToCarriers() {
        switch (this.props.round.type) {
            case RFP_ROUND_TYPE.BIDDING:
                return `Round #${ this.props.round.ordinal + 1 } - Bidding in Progress`;
            case RFP_ROUND_TYPE.CONFIRMATION:
                return 'Confirmation Round - In Progress';
            default:
                throw Error('Unknown rfp round type ' + this.props.round.type);
        }
    }

    _inReview() {
        switch (this.props.round.type) {
            case RFP_ROUND_TYPE.BIDDING:
                return `Round #${ this.props.round.ordinal + 1 } - In Review`;
            case RFP_ROUND_TYPE.CONFIRMATION:
                return 'Confirmation Round - In Review';
            default:
                throw Error('Unknown rfp round type ' + this.props.round.type);
        }
    }

    render() {
        let content = this._status();
        if (this.props.withTitle) {
            content = (
                <TitledCardComponent id={ this.props.id } type="small" title="STATUS" className={ `rfp-status ${ this.props.className }` }>
                    { content }
                </TitledCardComponent>
            );
        }

        return content;
    }
}
