import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan as cancel } from '@fortawesome/pro-light-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomSelect from 'component/form/CustomSelect';
import CustomTextArea from 'component/form/CustomTextArea';
import FormStatusModal from 'component/form/FormStatusModal';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';

export default class CancelTenderAuctionPopup extends Component {

    static propTypes = {
        auction: PropTypes.object.isRequired,
        onCancelTenderAuction: PropTypes.func,
        onClosePopup: PropTypes.func
    };

    static defaultProps = {
        onCancelTenderAuction: () => { /* */ },
        onClosePopup: () => { /* */ }
    };

    state = {
        showCancelPopup: false,
        cancelReason: null,
        note: '',
        reasons: [],
        popupStatus: undefined
    }

    componentDidMount() {
       this._setReasonsValue();
    }

    constructor (props) {
        super(props);
        this._onInputValueChange = this._onInputValueChange.bind(this);
        this._onReasonSelected = this._onReasonSelected.bind(this);
        this._onCancelTenderAuction = this._onCancelTenderAuction.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
    }

    _setReasonsValue() {
        TenderAuctionBrokerApiService.getAvailableCancelReasons()
            .then(response => {
                const reasons = this._mapReasonsToSelectValues(response.data);
                this.setState({ reasons });
            });
    }

    _mapReasonsToSelectValues(reasons) {
        return reasons.map(r => {
            return { ...r, title: r.reason }
        });
    }

    _onReasonSelected(_value, option) {
        this.setState({ cancelReason: JSON.parse(option.key), note: "" });
    }

    _onInputValueChange(event) {
        const value = event.target.value;
        this.setState({ note: value });
    }

    _onOpenPopup() {
        this.setState({ showCancelPopup: true });
    }

    _formCancelTenderAuctionButton() {
        return (
            <div onClick={ this._onOpenPopup } className="dropdown-item">
                <FontAwesomeIcon className="action-icon cancel" icon={ cancel }/>
                <small className="action-name cancel-action-button">Cancel Tender</small>
            </div>
        );
    }

    _formReasonNoteField() {
        if (this.state.cancelReason?.noteRequired) {
            return (
                <div className="note-input">
                    <CustomTextArea
                        label="Note"
                        rows={ 3 }
                        placeholder="Enter your reason..."
                        required={ true }
                        value={ this.state.note }
                        fieldName="reason"
                        onValueChange={ this._onInputValueChange }
                    />
                </div>
            );
        }
    }

    _onCancelTenderAuction() {
        let cancelReason = this.state.cancelReason;
        cancelReason.note = this.state.note;

        this.setState({ popupStatus: FORM_STATUSES.LOADING });
        TenderAuctionBrokerApiService.cancelAuction(this.props.auction.id, cancelReason)
                .then(() => this.setState({ popupStatus: FORM_STATUSES.SUCCESS }))
                .then(this.props.onCancelTenderAuction)
                .catch(() => this.setState({ popupStatus: FORM_STATUSES.ERROR }));
    }


    _onClosePopup() {
        if (FORM_STATUSES.LOADING !== this.state.popupStatus) {
            this.setState({ 
                showCancelPopup: false,
                cancelReason: null,
                note: '',
                popupStatus: undefined
            });

            this.props.onClosePopup();
        }
    }

    _formCancelTenderPopup() {
        const { cancelReason } = this.state;

        return (
            <div className="action-dropdown-popup">
                <h6 className="heading">Cancel Tender</h6>
                <p className="description">
                    Are you sure you want to cancel this auction? Canceling an auction will disable all further interactions with it, except restart. You can restart the auction for this load if it is still available on TMW.
                </p>
                <p className="description">
                    Please select the reason for canceling this auction.
                </p>
                <p className="subheading">Reason</p>
                <CustomSelect
                    values={ this.state.reasons }
                    selectedValue={ cancelReason ? cancelReason.title : null }
                    onSelect={ this._onReasonSelected }
                />
                { this._formReasonNoteField() }
                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this._onClosePopup }>
                        Discard
                    </Button>

                    <Button
                        type="danger"
                        onClick={ this._onCancelTenderAuction }
                        disabled={ !cancelReason || (cancelReason.noteRequired && !this.state.note.trim()) }
                    >
                        Cancel Tender
                    </Button>
                </div>
            </div>
        );
    }

    _formLoadingPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.LOADING }>
               <div>
                    <h6>Cancelling Auction</h6>
                    <p>This will only take a moment. Please wait...</p>
                </div>
            </FormStatusModal>
        );
    } 

    _formSuccessPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                <div>
                    <h6>Auction Cancelled</h6>
                    <p>You have successfully cancelled this auction.</p>
                </div>
            </FormStatusModal>
        );
    }

    _formErrorPopup() {
        return (
            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onClosePopup } />
        );
    }

    render() {
        const { popupStatus } = this.state;
        let content;

        switch (popupStatus) {
            case FORM_STATUSES.LOADING:
                content = this._formLoadingPopup();
                break;
            case FORM_STATUSES.SUCCESS:
                content = this._formSuccessPopup();
                break;
            case FORM_STATUSES.ERROR:
                content = this._formErrorPopup();
                break;
            default:  
                content = this._formCancelTenderPopup();      
        }

        return (
            <Popup
                id="popup-cancel"
                size="medium"
                show={ this.state.showCancelPopup }
                onClose={ this._onClosePopup }
                trigger={ this._formCancelTenderAuctionButton() }
            >
                { content }
            </Popup>
        );
    }
}
