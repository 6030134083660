import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle as exit } from '@fortawesome/pro-solid-svg-icons';

import { FINISHED_RFP_AUCTION_STATUSES } from 'common/constants';
import Banner from 'component/Banner';
import TitledCardComponent from 'component/card/TitledCardComponent';
import RFPLaneStatus from 'component/rfp/RFPLaneStatus';
import CargoTypeComponent from 'component/lane/CargoTypeComponent';
import LaneVisualization from 'component/lane/LaneVisualization';
import AuthorizationService from 'service/AuthorizationService';

import './RFPLaneHeaderCard.scss';

class RFPLaneHeaderCard extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        rfpLane: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this._onExitViewOnlyMode = this._onExitViewOnlyMode.bind(this);
    }

    _onExitViewOnlyMode() {
        this.props.history.push({
            pathname: `/rfp/${ this.props.rfp.id }`
        });
    }

    _formExitViewOnlyModeOption() {
        const mode = (new URLSearchParams(this.props.location.search)).get('mode');
        const autorizationService = AuthorizationService.instance();
        const canAccessReadOnlyMode = autorizationService.canUserAccess(this.props.account, 'routing-guide:read');

        if (!this.props.rfp.synthetic && canAccessReadOnlyMode && 'read_only' === mode && FINISHED_RFP_AUCTION_STATUSES.includes(this.props.rfp.status)) {
            return (
                <div className="rfp-lane-header-card-exit-view-only-mode-row">
                    <div>
                        <Banner 
                            size="small" 
                            content={ <>You are currently seeing the <b>Initial RFP</b> that was created for this routing guide</> }
                        />
                    </div>
                    <div>
                        <small><b>
                            <a href="#!" role="button" onClick={ this._onExitViewOnlyMode }>
                                Exit RFP View Mode
                                <FontAwesomeIcon icon={ exit } className="exit-icon" />
                            </a>
                        </b></small>
                    </div>
                </div>
            );
        }

        return <></>;
    }

    render() {
        return (
            <div className="rfp-lane-header-card-container">
                <div className="component rfp-lane-header-card">
                    { this._formExitViewOnlyModeOption() }
                    <div className="rfp-lane-header-card-title-row">
                        <LaneVisualization lane={ this.props.rfpLane } size="large" className="neutral" labeled={ true } />
                    </div>
                    <div className="rfp-lane-header-card-details-row">
                        <div className="rfp-lane-header-card-details-row-group">
                            <CargoTypeComponent type={ this.props.rfpLane.lane.type } />

                            <RFPLaneStatus id="rfp-lane-header-status"
                                           rfp={ this.props.rfp }
                                           rfpLane={ this.props.rfpLane }
                                           variant="manager"
                                           type="small"
                                           className="rfp-lane-header-card-component" />
                        </div>
                        <div className="rfp-lane-header-card-details-row-group">
                            <TitledCardComponent type="small" align="right" title="CARRIERS" className="rfp-lane-header-card-component">
                                <strong><b>{ this.props.rfpLane.participation.carriers || '0' }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small"  align="right" title="BIDS" className="rfp-lane-header-card-component">
                                <strong><b>{ this.props.rfpLane.participation.bids || '-' }/{ this.props.rfpLane.participation.carriers || '0' }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small"  align="right" title="LANE VOL." className="rfp-lane-header-card-component">
                                <strong><b>{ this.props.rfpLane.volume }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="right" title="AWARDED VOL." className="rfp-lane-header-card-component">
                                <strong><b>{ this.props.rfpLane.awardedVolume }/{ this.props.rfpLane.volume }</b></strong>
                            </TitledCardComponent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RFPLaneHeaderCard);
