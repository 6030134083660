import { Component } from 'react';
import PropTypes from 'prop-types';

import { AUCTION_STATUSES_FILTER_MAPPING } from 'common/constants';
import CollapsableFilter from 'component/filter/CollapsableFilter';
import CustomSelect from 'component/form/CustomSelect';
import CustomRangePicker from 'component/form/CustomRangePicker';
import LaneUtils from 'utils/LaneUtils';
import ObjectUtils from 'utils/ObjectUtils';

import './TenderingFilters.scss';

const SELECT_SORT_OPTION = 'Select';

const ALL_OPTION = {
    title: 'All',
    field: 'all'
};

const CARGO_TYPE_OPTIONS = [
    ALL_OPTION,
    {
        title: 'Refrigerated',
        field: 'REFRIGERATED'
    },
    {
        title: 'Dry',
        field: 'DRY'
    },
    {
        title: 'Flatbed',
        field: 'FLATBED'
    }
];

const AUCTION_PHASE_OPTIONS = [
    ALL_OPTION,
    {
        title: 'Dedicated',
        field: 'DEDICATED'
    },
    {
        title: 'Spot Market',
        field: 'SPOT_MARKET'
    }
];

const SORT_OPTIONS = [
    {
        title: 'Origin (Ascending)',
        key: 'ORIGIN',
        type: 'ASC'
    },
    {
        title: 'Origin (Descending)',
        key: 'ORIGIN',
        type: 'DESC'
    },
    {
        title: 'Destination (Ascending)',
        key: 'DESTINATION',
        type: 'ASC'
    },
    {
        title: 'Destination (Descending)',
        key: 'DESTINATION',
        type: 'DESC'
    },
    {
        title: 'Pickup Date (Ascending)',
        key: 'PICKUP_DATE',
        type: 'ASC'
    },
    {
        title: 'Pickup Date (Descending)',
        key: 'PICKUP_DATE',
        type: 'DESC'
    },
    {
        title: 'Delivery Date (Ascending)',
        key: 'DELIVERY_DATE',
        type: 'ASC'
    },
    {
        title: 'Delivery Date (Descending)',
        key: 'DELIVERY_DATE',
        type: 'DESC'
    }
];

const DATE_FORMAT = 'YYYY-MM-DD';

export default class TenderingFilters extends Component {

    static propTypes = {
        triggerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        showStatusFilter: PropTypes.bool,
        showPhaseFilter: PropTypes.bool,
        showBookedStatusOptions: PropTypes.bool,
        showTerminalStatusOptions: PropTypes.bool,
        filter: PropTypes.object,
        version: PropTypes.number,
        fetchRoutingGuides: PropTypes.func,
        onFilterChange: PropTypes.func
    };

    static defaultProps = {
        triggerRef: { current: null },
        showStatusFilter: true,
        showPhaseFilter: true,
        showBookedStatusOptions: false,
        showTerminalStatusOptions: false,
        filter: {},
        version: 0,
        fetchRoutingGuides: () => { /* */ },
        onFilterChange: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onSelectRoutingGuide = this._onSelectRoutingGuide.bind(this);
        this._onSelectOrigin = this._onSelectOrigin.bind(this);
        this._onSelectDestination = this._onSelectDestination.bind(this);
        this._onSelectCargoType = this._onSelectCargoType.bind(this); 
        this._onSelectAuctionPhase = this._onSelectAuctionPhase.bind(this);
        this._onSelectStatus = this._onSelectStatus.bind(this);
        this._onSelectSort = this._onSelectSort.bind(this);

        this._onRoutingGuideSearchChange = this._onRoutingGuideSearchChange.bind(this);
        this._onOriginSearchChange = this._onOriginSearchChange.bind(this);
        this._onDestinationSearchChange = this._onDestinationSearchChange.bind(this);

        this._onPickupDateChange = this._onPickupDateChange.bind(this);
        this._onDeliveryDateChange = this._onDeliveryDateChange.bind(this);

        this._onClearAllFilters = this._onClearAllFilters.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
    }

    state = {
        selectedRoutingGuide: ALL_OPTION.title,
        selectedOrigin: ALL_OPTION.title,
        selectedDestination: ALL_OPTION.title,
        selectedAuctionPhase: ALL_OPTION.title,
        selectedCargoType: ALL_OPTION.title,
        selectedStatus: ALL_OPTION.title,
        selectedSort: SELECT_SORT_OPTION,
        routingGuideSearch: null,
        originSearch: null,
        destinationSearch: null,
        routingGuides: [],
        originRegions: [],
        destinationRegions: [],
        statusOptions: []
    };

    componentDidMount() {
        const filter = ObjectUtils.removeEmptyKeys(this.props.filter);
        const statusOptions = this._getStatusOptions();

        this._fetchRoutingGuides({});
        this._updateSelected(filter, statusOptions);
        this.setState({ statusOptions });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.version !== this.props.version) {
            this._fetchRoutingGuides({ search: this.state.routingGuideSearch });
            this._onOriginSearchChange(this.state.originSearch);
            this._onDestinationSearchChange(this.state.destinationSearch);
        }
        
        if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
            this._updateSelected(this.props.filter, this.state.statusOptions);
        }
    }

    _onSelectRoutingGuide(value) {
        this.setState({ routingGuideSearch: null });
        const filter = this._updateFilter('routing_guide', value);
        this.props.onFilterChange(filter);
    }

    _onSelectOrigin(value) {
        this.setState({ originSearch: null });
        const filter = this._updateFilter('origin', value);
        this.props.onFilterChange(filter);
    }

    _onSelectDestination(value) {
        this.setState({ destinationSearch: null });
        const filter = this._updateFilter('destination', value);
        this.props.onFilterChange(filter);
    }

    _onSelectCargoType(value, option) {
        const filter = this._updateFilter('cargo_type', value, option);
        this.props.onFilterChange(filter);
    }

    _onSelectAuctionPhase(value, option) {
        const filter = this._updateFilter('auction_phase', value, option);
        this.props.onFilterChange(filter);
    }

    _onSelectStatus(value, option) {
        const filter = this._updateFilter('tender_status', value, option);
        this.props.onFilterChange(filter);
    }

    _onSelectSort(value, option) {
        const filter = JSON.parse(JSON.stringify(this.props.filter));

        if (!value) {
            delete filter['sort'];
        } else {
            filter['sort'] = JSON.parse(option.key).key + ',' + JSON.parse(option.key).type;
        }

        this.props.onFilterChange(filter);
    }

    _onPickupDateChange(range) {
        const pickup_date_from = range && range[0] ? range[0] : null;
        const pickup_date_to = range && range[1] ? range[1] : null;

        const filter = {
            ...this.props.filter,
            pickup_date_from,
            pickup_date_to
        };

        this.props.onFilterChange(filter);
    }

    _onDeliveryDateChange(range) {
        const delivery_date_from = range && range[0] ? range[0] : null;
        const delivery_date_to = range && range[1] ? range[1] : null;

        const filter = {
            ...this.props.filter,
            delivery_date_from,
            delivery_date_to
        };

        this.props.onFilterChange(filter);
    }

    _onRoutingGuideSearchChange(search) {
        this.setState({ routingGuideSearch: search });
        this._fetchRoutingGuides({ search });
    }

    _onOriginSearchChange(search) {
        this.setState({ originSearch: search });
        const [ destinationCity, destinationState ] = this._getRegionFromFilter(this.props.filter.destination);
        this._fetchOriginsForDestination({ destinationCity, destinationState, search });
    }

    _onDestinationSearchChange(search) {
        this.setState({ destinationSearch: search });
        const [ originCity, originState ] = this._getRegionFromFilter(this.props.filter.origin);
        this._fetchDestinationsForOrigin({ originCity, originState, search });
    }

    _onClearAllFilters() {
        this._updateSelected({}, this.state.statusOptions);        
        this.props.onFilterChange({});
    }

    _getStatusOptions() {
        let statusOptions;

        if (!this.props.showStatusFilter) {
            statusOptions = [];
        } else if (this.props.showTerminalStatusOptions) {
            statusOptions = [
                ALL_OPTION,
                {
                    title: AUCTION_STATUSES_FILTER_MAPPING.COMPLETED,
                    field: 'COMPLETED'
                },
                {
                    title: AUCTION_STATUSES_FILTER_MAPPING.CANCELED,
                    field: 'CANCELED'
                },
                {
                    title: AUCTION_STATUSES_FILTER_MAPPING.EXPIRED,
                    field: 'EXPIRED'
                }
            ];
        } else if (this.props.showBookedStatusOptions) {
            statusOptions = [
                ALL_OPTION,
                {
                    title: 'Carrier Details are Missing',
                    field: 'AWAITING_WINNER_DETAILS'
                },
                {
                    title: 'Under Review',
                    field: 'UNDER_REVIEW'
                },
                {
                    title: 'Ready to go',
                    field: 'READY'
                }
            ];
        }

        return statusOptions;
    }

    async _fetchDestinationsForOrigin(params) {
        const response = await LaneUtils.fetchDestinations(params);
        this.setState({
            destinationRegions: [
                ALL_OPTION, 
                ...response.data.map(region => ({
                    title: LaneUtils.formatRegion(region),
                    field: region
                }))
            ]
        });
    }

    async _fetchOriginsForDestination(params) {
        const response = await LaneUtils.fetchOrigins(params);
        this.setState({
            originRegions: [
                ALL_OPTION, 
                ...response.data.map(region => ({
                    title: LaneUtils.formatRegion(region),
                    field: region
                }))
            ]
        });
    }

    _fetchRoutingGuides(params) {
        this.props.fetchRoutingGuides(params).then(response => {
            this.setState({
                routingGuides: [
                    ALL_OPTION, 
                    ...response.data.map(routingGuideName => ({ title: routingGuideName }))
                ]
            });
        });
    }

    _updateFilter(field, value, option) {
        const filter = JSON.parse(JSON.stringify(this.props.filter));

        if (!value || ALL_OPTION.title === value) {
            delete filter[field];
        } else {
            filter[field] = option ? JSON.parse(option.key).field : value;
        }

        return filter;
    }

    _updateSelected(filter, statusOptions) {
        const selectedRoutingGuide = filter.routing_guide || ALL_OPTION.title;
        const selectedOrigin = filter.origin || ALL_OPTION.title;
        const selectedDestination = filter.destination || ALL_OPTION.title;
        const selectedAuctionPhase = filter.auction_phase ? (AUCTION_PHASE_OPTIONS.find(phase => phase.field === filter.auction_phase) || {}).title : ALL_OPTION.title;
        const selectedCargoType = filter.cargo_type ? (CARGO_TYPE_OPTIONS.find(type => type.field === filter.cargo_type) || {}).title : ALL_OPTION.title;
        const selectedStatus = filter.tender_status ? (statusOptions.find(status => status.field === filter.tender_status) || {}).title : ALL_OPTION.title;
        const selectedSort = filter.sort ? (SORT_OPTIONS.find(item => item.key === filter.sort.split(',')[0] && item.type === filter.sort.split(',')[1]) || {}).title : SELECT_SORT_OPTION;

        if (selectedOrigin !== this.state.selectedOrigin || this.state.originRegions.length === 0) {
            const [ originCity, originState ] = this._getRegionFromFilter(filter.origin);
            this._fetchDestinationsForOrigin({ originCity, originState });
        }

        if (selectedDestination !== this.state.selectedDestination || this.state.destinationRegions.length === 0) {
            const [ destinationCity, destinationState ] = this._getRegionFromFilter(filter.destination);
            this._fetchOriginsForDestination({ destinationCity, destinationState });
        }

        this.setState({
            selectedRoutingGuide,
            selectedOrigin,
            selectedDestination,
            selectedAuctionPhase,
            selectedCargoType,
            selectedStatus,
            selectedSort
        });
    }

    /* Helper function to get city and state from region
       Region format: City, State
    */
    _getRegionFromFilter(region) {
        const city = region ? region.split(', ')[0] : undefined;
        const state = region ? region.split(', ')[1] : undefined;
        return [ city, state ];
    }

    _onFilterChange(filter) {
        this._updateSelected(filter, this.state.statusOptions);
        this.props.onFilterChange(filter);
    }

    render() {
        let statusFilter = <></>;
        if (this.props.showStatusFilter) {
            statusFilter = (
                <div className="field">
                    <CustomSelect
                        label="Status"
                        size="small"
                        selectedValue={ this.state.selectedStatus }
                        allowClear={ ALL_OPTION.title !== this.state.selectedStatus }
                        onSelect={ this._onSelectStatus }
                        values={ this.state.statusOptions }
                    />
                </div>
            );
        }

        let clearAll = <></>;
        if (!ObjectUtils.isBlankOrEmpty(this.props.filter)) {
            clearAll = (
                <a className="clear-all-container" href="# " onClick={ this._onClearAllFilters }>
                    <small className="clear-all-link">Clear All</small>
                </a>
            );
        }

        return (
            <CollapsableFilter 
                triggerRef={ this.props.triggerRef }
                filter={ this.props.filter }
                onFilterChange={ this._onFilterChange }
            >
                <div className="tendering-filters-container">
                    <div className="field">
                        <CustomSelect
                            label="Routing Guide"
                            size="small"
                            selectedValue={ this.state.selectedRoutingGuide }
                            onSelect={ this._onSelectRoutingGuide }
                            withSearch={ true }
                            allowClear={ ALL_OPTION.title !== this.state.selectedRoutingGuide }
                            values={ this.state.routingGuides }
                            onSearch={ this._onRoutingGuideSearchChange }
                        />
                    </div>
                    <div className="field">
                        <CustomSelect
                            label="Origin"
                            size="small"
                            selectedValue={ this.state.selectedOrigin }
                            onSelect={ this._onSelectOrigin }
                            withSearch={ true }
                            allowClear={ ALL_OPTION.title !== this.state.selectedOrigin }
                            values={ this.state.originRegions }
                            onSearch={ this._onOriginSearchChange }
                        />
                    </div>
                    <div className="field">
                        <CustomSelect
                            label="Destination"
                            size="small"
                            selectedValue={ this.state.selectedDestination }
                            onSelect={ this._onSelectDestination }
                            withSearch={ true }
                            allowClear={ ALL_OPTION.title !== this.state.selectedDestination }
                            values={ this.state.destinationRegions }
                            onSearch={ this._onDestinationSearchChange }
                        />
                    </div>
                    <div className="field range-picker-field">
                        <CustomRangePicker
                            label="Pickup Date"
                            size="small"
                            dateFrom={ this.props.filter.pickup_date_from }
                            dateTo={ this.props.filter.pickup_date_to }
                            dateFormat={ DATE_FORMAT }
                            handleChange={ this._onPickupDateChange }
                        />
                    </div>
                    <div className="field range-picker-field">
                        <CustomRangePicker
                            label="Delivery Date"
                            size="small"
                            dateFrom={ this.props.filter.delivery_date_from }
                            dateTo={ this.props.filter.delivery_date_to }
                            dateFormat={ DATE_FORMAT }
                            handleChange={ this._onDeliveryDateChange }
                        />
                    </div>
                    <div className="field">
                        <CustomSelect
                            label="Cargo Type"
                            size="small"
                            selectedValue={ this.state.selectedCargoType }
                            allowClear={ ALL_OPTION.title !== this.state.selectedCargoType }
                            onSelect={ this._onSelectCargoType }
                            values={ CARGO_TYPE_OPTIONS }
                        />
                    </div>
                    { this.props.showPhaseFilter &&
                        <div className="field">
                            <CustomSelect
                                label="Auction Phase"
                                size="small"
                                selectedValue={ this.state.selectedAuctionPhase }
                                allowClear={ ALL_OPTION.title !== this.state.selectedAuctionPhase }
                                onSelect={ this._onSelectAuctionPhase }
                                values={ AUCTION_PHASE_OPTIONS }
                            />
                        </div>
                    }
                    { statusFilter }
                    <div className="field">
                        <CustomSelect
                            label="Sort By"
                            size="small"
                            selectedValue={ this.state.selectedSort }
                            allowClear={ SELECT_SORT_OPTION !== this.state.selectedSort }
                            onSelect={ this._onSelectSort }
                            values={ SORT_OPTIONS }
                        />
                    </div>
                    { clearAll }
                </div>
            </CollapsableFilter>
        );
    }
}
