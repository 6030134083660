import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPen as editIcon,
    faTimes as exitEditIcon,
    faCheck as saveEditIcon  
} from '@fortawesome/pro-regular-svg-icons';

import { 
    faTruckMoving as vanIcon,
    faSnowflake as reeferIcon,
    faPalletAlt as flatbedIcon,
    faInfoCircle as infoIcon   
} from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import FormStatusModal from 'component/form/FormStatusModal';
import Separator from 'component/Separator';
import Tooltip from 'component/Tooltip';
import Popup from 'component/Popup';
import Button from 'component/Button';
import CustomTextInput from 'component/form/CustomTextInput';
import UserApiService from 'service/api/UserApiService';
import StringUtils from 'utils/StringUtils';

import './ProfileInformation.scss';

const placeholder = '-';

const EQUIPMENT_TYPES_PROPERTIES = {
    VAN:  {
        icon: vanIcon,
        label: 'Van'
    },
    REEFER: {
        icon: reeferIcon,
        label: 'Reefer'
    },
    FLATBED: {
        icon: flatbedIcon,
        label: 'Flatbed'
    }
};

export default class ProfileInformation extends Component {

    static propTypes = {
        carrier: PropTypes.object,
        user: PropTypes.object,
        canEdit: PropTypes.bool,
        refreshProfile: PropTypes.func
    }

    static defaultProps = {
        carrier: {},
        user: null,
        canEdit: true,
        refreshProfile: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onInputValueChange = this._onInputValueChange.bind(this);

        this._exitEditingName = this._exitEditingName.bind(this);
        this._saveNameChanges = this._saveNameChanges.bind(this);

        this._exitEditingFax = this._exitEditingFax.bind(this);
        this._saveFaxChanges = this._saveFaxChanges.bind(this);

        this._exitEditingPhone = this._exitEditingPhone.bind(this);
        this._savePhoneChanges = this._savePhoneChanges.bind(this);

        this._exitEditingEmail = this._exitEditingEmail.bind(this);
        this._openEditEmailPopup = this._openEditEmailPopup.bind(this);
        this._saveEmailChanges = this._saveEmailChanges.bind(this);
    }

    state = {
        lockNameEdit: true,
        lockFaxEdit: true,
        lockPhoneEdit: true,
        lockEmailEdit: true,
        name: '',
        fax: '',
        phone: '',
        email: '',
        showEditEmailPopup: false,
        isEmailChangeSuccess: true
    };

    componentDidMount() {
        if (this.props.user) {
            this.setState({
                name: StringUtils.replaceBlankWithPlaceholder(this.props.user.name, ''),
                fax: StringUtils.replaceBlankWithPlaceholder(this.props.user.fax, ''),
                phone: StringUtils.replaceBlankWithPlaceholder(this.props.user.phone, ''),
                email: StringUtils.replaceBlankWithPlaceholder(this.props.user.email, '')
            })
        }
    }
    
    _flipLockField(key) {
        this.setState(prevState => {
            const newState = { ...prevState };
            newState[key] = !prevState[key];

            return newState;
        });
    }

    _exitEditingName() {
        this.setState({
            name: StringUtils.replaceBlankWithPlaceholder(this.props.user.name, '')
        });

        this._flipLockField('lockNameEdit');
    }

    _saveNameChanges() {
        if (this.state.name.trim() !== '' && this.state.name.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.name, '')) {
            UserApiService.update(this.props.user.id, { name: this.state.name.trim() })
                .then(() => {
                    this._flipLockField('lockNameEdit');
                    this.props.refreshProfile(this.props.user.id);
                });
        }
    }

    _exitEditingFax() {
        this.setState({
            fax: StringUtils.replaceBlankWithPlaceholder(this.props.user.fax, '')
        });

        this._flipLockField('lockFaxEdit');
    }

    _saveFaxChanges() {
        if (this.state.fax.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.fax, '')) {
            UserApiService.update(this.props.user.id, { fax: this.state.fax.trim() })
                .then(() => {
                    this._flipLockField('lockFaxEdit');
                    this.props.refreshProfile(this.props.user.id);
                });
        }
    }

    _exitEditingPhone() {
        this.setState({
            phone: StringUtils.replaceBlankWithPlaceholder(this.props.user.phone, '')
        });

        this._flipLockField('lockPhoneEdit');
    }
    
    _savePhoneChanges() {
        if (this.state.phone.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.phone, '')) {
            UserApiService.update(this.props.user.id, { phone: this.state.phone.trim() })
                .then(() => {
                    this._flipLockField('lockPhoneEdit');
                    this.props.refreshProfile(this.props.user.id);
                });
        }
    }

    _exitEditingEmail() {
        this.setState({
            email: StringUtils.replaceBlankWithPlaceholder(this.props.user.email, ''),
            showEditEmailPopup: false,
            isEmailChangeSuccess: true
        });

        this._flipLockField('lockEmailEdit');
    }  

    _openEditEmailPopup() {
        if (this.state.email.trim() !== '' && this.state.email !== StringUtils.replaceBlankWithPlaceholder(this.props.user.email, '')) {
            this.setState({ showEditEmailPopup: true });
        }
    }

    _saveEmailChanges() {
        UserApiService.update(this.props.user.id, { email: this.state.email.trim() })
            .then(() => {
                this._flipLockField('lockEmailEdit');
                this.props.refreshProfile(this.props.user.id);
            })
            .catch(() => this.setState({ isEmailChangeSuccess: false }));
    }

    _onInputValueChange(event) {
        const value = event.target.value;
        const key = event.target.name;

        this.setState(prevState => {
            const newState = { ...prevState };
            newState[key] = value;

            return newState;
        });
    }


    _formEmailChangePopup() {
        let content;
        if (this.state.isEmailChangeSuccess) {
            content = (
                <div className="edit-user-email-popup">
                    <p className="heading">Confirm changes</p>
                        
                    <p className="description">
                        Please note that email address is not only used as a login credential for the system 
                        but is also the primary means of communication between user and the platform. 
                        Therefore, when changing email address, it is important to confirm the change.
                    </p>

                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._exitEditingEmail } >
                            Cancel
                        </Button>

                        <Button size="small" type="primary" onClick={ this._saveEmailChanges }>
                            Accept 
                        </Button>
                    </div>
                </div>
            );
        } else {
            content = (
                <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._exitEditingEmail }>
                    <h6>Something went wrong</h6>
                    <small>Entered email is not valid</small>
                </FormStatusModal>
            );
        }

        return (
            <div className="edit-email">
                <Popup
                    id="popup-edit-user-email"
                    show={ this.state.showEditEmailPopup }
                    onClose={ this._exitEditingEmail }
                    trigger={ 
                        <FontAwesomeIcon 
                            icon={ saveEditIcon } 
                            className={ `icon ${ this.state.email.trim() === '' || this.state.email === StringUtils.replaceBlankWithPlaceholder(this.props.user.email, '')  ? 'disabled' : '' }` } 
                            onClick={ this._openEditEmailPopup } 
                        /> 
                    }
                >
                   { content }
                </Popup>
            </div>
        );
    }

    _formEditOptions(field) {
        const editProperties = {
            'NAME': { 
                lock: 'lockNameEdit',
                editable: true,
                valid: this.state.name.trim() !== '' && this.state.name.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.name, ''),
                exit: this._exitEditingName, 
                save: this._saveNameChanges 
            },
            'FAX': {
                lock: 'lockFaxEdit',
                editable: true,
                valid: this.state.fax.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.fax, ''),
                exit: this._exitEditingFax,
                save: this._saveFaxChanges 
            },
            'PHONE': {
                lock: 'lockPhoneEdit',
                editable: true, 
                valid: this.state.phone.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.phone, ''),
                exit: this._exitEditingPhone,
                save: this._savePhoneChanges
            },
            'EMAIL': { 
                lock: 'lockEmailEdit',
                editable: this.props.user.email !== this.props.user.carrier.email,
                valid: this.state.name.trim() !== '' && this.state.name.trim() !== StringUtils.replaceBlankWithPlaceholder(this.props.user.name, ''),
                exit: this._exitEditingEmail,
                save: this._saveEmailChanges
            }
        };

        let options;

        if (this.state[editProperties[field].lock] || !editProperties[field].editable) {
            options = (
                <FontAwesomeIcon 
                    icon={ editIcon } 
                    className={ `icon ${ field === 'EMAIL' && this.props.user.email === this.props.user.carrier.email ? 'disabled' : ''}` } 
                    onClick={() => this._flipLockField(editProperties[field].lock)}
                />
            ); 
        } else {
            options = (
                <>
                    <FontAwesomeIcon icon={ exitEditIcon } className="icon" onClick={ editProperties[field].exit } />
                    { field === 'EMAIL' ? this._formEmailChangePopup() : <FontAwesomeIcon icon={ saveEditIcon } className={ `icon ${ !editProperties[field].valid ? 'disabled' : '' }` } onClick={ editProperties[field].save } /> }
                </>
            );
        }
        return (
            <div className="edit-options">
                { options }
            </div>
        );
    }

    _formContactInfoEdit() {
        const { user } = this.props;
        const isDefaultUser = user.email === user.carrier.email;
        return (
            <div className="row-info-card contact">
                <div className="row-info-card-heading">
                    <strong className="title">Contact</strong>
                    <br/>
                    <small className="description">You can update contact information here.</small>
                </div>    
                <div className="row-info-card-content">
                    <div className="row-info-card-column">
                        <div className="field first-name">
                            <CustomTextInput 
                                className="input-field" 
                                inputClassName="input-value"
                                label="Full Name" 
                                value={ this.state.name }
                                fieldName="name"
                                onValueChange={ this._onInputValueChange }
                                locked={ this.state.lockNameEdit }
                                suffixIcon={ this._formEditOptions('NAME') }
                            />
                        </div>
                        <div className="field fax">
                            <CustomTextInput 
                                className="input-field" 
                                inputClassName="input-value"
                                label="Fax" 
                                value={ this.state.fax }
                                fieldName="fax"
                                onValueChange={ this._onInputValueChange }
                                placeholder="Enter your fax number..."
                                locked={ this.state.lockFaxEdit }
                                suffixIcon={ this._formEditOptions('FAX') }
                            />
                        </div>
                    </div>
                    <div className="row-info-card-column">
                        <div className="field phone">
                            <CustomTextInput 
                                className="input-field" 
                                inputClassName="input-value"
                                label="Phone" 
                                value={ this.state.phone }
                                fieldName="phone"
                                onValueChange={ this._onInputValueChange }
                                placeholder="Enter your phone number..."
                                locked={ this.state.lockPhoneEdit }
                                suffixIcon={ this._formEditOptions('PHONE') }
                            />
                        </div> 
                        <div className="field email">
                            <CustomTextInput 
                                className="input-field"
                                inputClassName="input-value" 
                                label="Email"
                                tooltipText={ isDefaultUser ? `The email address is set as the default email for ${ user.carrier.name } and cannot be modified.` : ''} 
                                value={ this.state.email }
                                fieldName="email"
                                onValueChange={ this._onInputValueChange }
                                placeholder="Enter your email number..."
                                locked={ this.state.lockEmailEdit }
                                disabled={ isDefaultUser }
                                suffixIcon={ this._formEditOptions('EMAIL') }
                            />
                        </div> 
                    </div>                 
                </div>
            </div>
        );
    }

    _formContactInfoView() {
        const { carrier, user } = this.props;
        let firstName, phone, fax, email;
        if (user) {
            firstName = StringUtils.replaceBlankWithPlaceholder(user.name, placeholder);
            phone = StringUtils.replaceBlankWithPlaceholder(user.phone, placeholder);
            fax = StringUtils.replaceBlankWithPlaceholder(user.fax, placeholder);
            email = user.email;
        } else {
            const profileInformation = carrier.profileInformation;
            firstName = StringUtils.replaceBlankWithPlaceholder(profileInformation?.firstName, placeholder);
            phone = StringUtils.replaceBlankWithPlaceholder(profileInformation?.phone, placeholder);
            fax = StringUtils.replaceBlankWithPlaceholder(profileInformation?.fax, placeholder);
            email = carrier.email;
        }

        return (
            <div className="row-info-card contact">
                <div className="row-info-card-heading">
                    <strong className="title">Contact</strong>
                    <br />
                    <small className="description">You can view contact information here.</small>
                </div>
                <div className="row-info-card-content">
                    <div className="row-info-card-column">
                        <div className="field first-name">
                            <small className="label">First Name</small>
                            <br />
                            <strong className="value" title={ firstName }>{ firstName }</strong>
                        </div>
                        <div className="field fax">
                            <small className="label">Fax</small>
                            <br />
                            <strong className="value" title={ fax }>{ fax }</strong>
                        </div>
                    </div>
                    <div className="row-info-card-column">
                        <div className="field phone">
                            <small className="label">Phone</small>
                            <br />
                            <strong className="value" title={ phone }>{ phone }</strong>
                        </div>
                        <div className="field email">
                            <small className="label">Email</small>
                            <br />
                            <strong className="value" title={ email }>{ email }</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _formCarrierInformation() {
        const { user } = this.props;
        const name = user.carrier?.name;

        return (
            <div className="row-info-card carrier-information">
                <div className="row-info-card-heading">
                    <strong className="title">Carrier Information</strong>
                    <br/>
                    <small className="description">Please search for the documents and payment details within the specific Carrier Details section.</small>
                    <Link to={ `/carrier/${ user.carrier?.id }` } className="carrier-link">
                        <Button type="primary" size="small">Carrier Details</Button>
                    </Link>
                </div>
                <div className="row-info-card-content">
                    <div className="row-info-card-column">
                        <div className="field carrier-name">
                            <small className="label">Carrier Name</small>
                            <br/>
                            <strong className="value" title={ name }>{ name }</strong>
                        </div>
                    </div>          
                </div>
            </div>
        );
    }

    _formPhysicalAddress() {
        const { carrier } = this.props;
        const profileInformation = carrier.profileInformation || {};
        const carrierRegion = profileInformation.location ? `${profileInformation.location.region.city}, ${profileInformation.location.region.state}` : '----';
        const address = StringUtils.replaceBlankWithPlaceholder(profileInformation.location?.address, placeholder);
        const zipCode = StringUtils.replaceBlankWithPlaceholder(profileInformation.location?.zipCode, placeholder);

        return (
            <div className="row-info-card physical-address">
                <div className="row-info-card-heading">
                    <strong className="title">Physical Address</strong>
                    <br/>
                    <small className="description">You can view physical address information here.</small>
                </div>
                <div className="row-info-card-content">
                    <div className="row-info-card-column">
                        <div className="field carrier-region">
                            <small className="label">City and State</small>
                            <br/>
                            <strong className="value" title={ carrierRegion }>{ carrierRegion }</strong>
                        </div>
                        <div className="field zip-code">
                            <small className="label">Zip Code</small>
                            <br/>
                            <strong className="value" title={ zipCode }>{ zipCode }</strong>
                        </div>
                    </div>
                    <div className="row-info-card-column">
                        <div className="field address">
                            <small className="label">Street Address</small>
                            <br/>
                            <strong className="value" title={ address }>{ address }</strong>
                        </div> 
                    </div>                 
                </div>
            </div>
        );
    }

    _formEquipmentTypes() {
        const { equipmentTypes } = this.props.carrier;

        if (equipmentTypes.length === 0) {
            return placeholder;
        }

        return equipmentTypes.map(type => {
            const equipmentTypeName = type.name;
            return (
                <div key={ type.id }>
                    <FontAwesomeIcon icon={ EQUIPMENT_TYPES_PROPERTIES[equipmentTypeName].icon } className="equipment-type-icon" />
                    { EQUIPMENT_TYPES_PROPERTIES[equipmentTypeName].label }
                </div> 
            );
        });
    }

    _formIdentification() {
        const { carrier } = this.props;
        const profileInformation = carrier.profileInformation || {};

        const id = StringUtils.replaceBlankWithPlaceholder(carrier.businessId, placeholder);
        const dotNumber = StringUtils.replaceBlankWithPlaceholder(profileInformation.dotNumber, placeholder);
        const fedNumber = StringUtils.replaceBlankWithPlaceholder(profileInformation.fedNumber, placeholder);
        const mcNumber = StringUtils.replaceBlankWithPlaceholder(profileInformation.mcNumber, placeholder);
        const scac = StringUtils.replaceBlankWithPlaceholder(profileInformation.scac, placeholder);

        return (
            <div className="row-info-card identification">
                <div className="row-info-card-heading">
                    <strong className="title">Identification</strong>
                    <br/>
                    <small className="description">You can view Carrier ID and technical information here.</small>
                </div>
                <div className="row-info-card-content">
                    <div className="row-info-card-column">
                        <div className="field carrier-id">
                            <small className="label">Carrier ID</small>
                            <br/>
                            <strong className="value" title={ id }>{ id }</strong>
                        </div>
                        <div className="field dot-number">
                            <small className="label">DOT Number</small>
                            <br/>
                            <strong className="value" title={ dotNumber }>{ dotNumber }</strong>
                        </div>
                        <div className="field mc-number">
                            <small className="label">MC Number</small>
                            <br/>
                            <strong className="value" title={ mcNumber }>{ mcNumber }</strong>
                        </div>
                    </div>
                    <div className="row-info-card-column">
                        <div className="field equipment-type">
                            <small className="label">
                                Trailer types
                                <div className="info-icon-div">
                                    <FontAwesomeIcon icon={ infoIcon } className="info-icon" />
                                    <Tooltip direction="top">Equipment type used by this carrier.</Tooltip>
                                </div>
                            </small>
                            <br/>
                            <strong className="value">{ this._formEquipmentTypes() }</strong>
                        </div> 
                        <div className="field fed-number">
                            <small className="label">FED Number</small>
                            <br/>
                            <strong className="value" title={ fedNumber }>{ fedNumber }</strong>
                        </div> 
                        <div className="field scas">
                            <small className="label">SCAC</small>
                            <br/>
                            <strong className="value" title={ scac }>{ scac }</strong>
                        </div> 
                    </div>                 
                </div>
            </div>
        );
    }

    render() {
        const { user, canEdit} = this.props;
        return (
            <div className="profile-overview">
                { user && canEdit ? this._formContactInfoEdit() : this._formContactInfoView() }
                <Separator/>
                { !user ?
                    <>
                        { this._formPhysicalAddress() }  
                        <Separator/>
                        { this._formIdentification() }
                    </> : this._formCarrierInformation() 
                }
            </div>
        );
    }
}
