import { Component } from 'react';

import DownloadPDFCard from 'component/card/DownloadPDFCard';
import HelpCenterApiService from 'service/api/HelpCenterApiService';

import './HelpCenter.scss';

export default class HelpCenter extends Component {
    state = {
        manuals: []
    }

    componentDidMount() {
        HelpCenterApiService.getUserManuals()
            .then(manuals => { this.setState({ manuals }) })
            .catch(error => console.error(error.message));
    }

    render() {

        return (
            <div className="page help-center-page">
                <div className="help-center-content">
                    <h5 className="help-center-title">Help Center</h5>
                    <strong className="help-center-description">Just getting started? Download User Manuals to help you learn how to use our application’s core features.</strong>
                    <div className="pdf-files">
                        { this.state.manuals.map((manual) => <DownloadPDFCard key={ manual.manualId } fileTitle={ manual.name } fileId={ manual.manualId } />) }
                    </div>
                    <small>If you have any questions, please feel free to contact us on:</small>
                    <a href="mailto:support@shipexlogistics.com">
                        <small className="support-email">support@shipexlogistics.com</small>
                    </a>
                </div>
            </div>
        );
    }
}
