import { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    faUser as carriersActive,
    faHistory as history,
    faUsers as carriersIcon
} from '@fortawesome/pro-solid-svg-icons';
import { faUserAlt as carriersInactive } from '@fortawesome/pro-light-svg-icons';

import * as Constants from 'common/constants';
import ContentCard from 'component/card/ContentCard';
import SimpleTabs from 'component/navigation/SimpleTabs';
import Tab from 'component/navigation/Tab';
import Pagination from 'component/navigation/Pagination';
import TableEmptyState from 'component/table/TableEmptyState';
import Table from 'component/table/custom/Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import DedicatedCarrierTableRow from 'component/lane/DedicatedCarrierTableRow';
import DedicatedCarrierForm from 'component/lane/DedicatedCarrierForm';
import AuthorizationService from 'service/AuthorizationService';
import RestService from 'service/RestService';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';

import './DedicatedCarriers.scss';

const TABS = {
    ACTIVE: 'active',
    HISTORY: 'history'
};

export default class DedicatedCarriers extends Component {

    static propTypes = {
        account: PropTypes.object.isRequired,
        routingGuideLane: PropTypes.object.isRequired,
        documentTypes: PropTypes.array,
        fetchNumberOfDedicatedCarriers: PropTypes.func
    };

    static defaultProps = {
        documentTypes: [],
        fetchNumberOfDedicatedCarriers: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.state = {
            activeTab: TABS.ACTIVE,
            carriers: [],
            page: Constants.DEFAULT_PAGE_NUMBER,
            pageSize: Constants.DEFAULT_PAGE_SIZE,
            loading: false,
            available: 0,
            maxPosition: 1,
            expanded: true,
            canEdit: false
        };

        this._fetchData = this._fetchData.bind(this);
        this._addDedicatedCarrier = this._addDedicatedCarrier.bind(this);
        this._updateDedicatedCarrier = this._updateDedicatedCarrier.bind(this);
        this._removeDedicatedCarrier = this._removeDedicatedCarrier.bind(this);
        this._onTabChange = this._onTabChange.bind(this);
        this._setPage = this._setPage.bind(this);
        this._setPageSize = this._setPageSize.bind(this);
        this._onExpandedChanged = this._onExpandedChanged.bind(this);
    }

    componentDidMount() {
        this._fetchData();
        const canEdit = AuthorizationService.instance().canUserAccess(this.props.account, 'tender-candidates:write');
        this.setState({ canEdit });
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.activeTab !== this.state.activeTab
            || prevState.page !== this.state.page
            || prevState.pageSize !== this.state.pageSize) {
            this._fetchData();
        }
    }

    _fetchData() {
        this.setState({ loading: true });

        if (TABS.ACTIVE === this.state.activeTab) {
            this._fetchActiveCarriers();
        } else if (TABS.HISTORY === this.state.activeTab) {
            this._fetchCarriersHistory();
        }

        this._fetchMaxActivePosition();
        this._fetchCarriersWithExceededLimit();
    }

    _fetchActiveCarriers() {
        TenderCandidateApiService.listActive(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, this.state.page, this.state.pageSize)
            .then(response => this.setState({ 
                carriers: response.data,
                available: response.available,
                loading: false
            }))
            .catch(error => console.error('An error occurred while fetching active tender candidates.', error));
    }

    _fetchCarriersHistory() {
        TenderCandidateApiService.listHistoric(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, this.state.page, this.state.pageSize)
            .then(response => this.setState({
                carriers: response.data,
                available: response.available,
                loading: false
            }))
            .catch(error => console.error('An error occurred while fetching historic tender candidates.', error));
    }

    _fetchMaxActivePosition() {
        TenderCandidateApiService.getMaxPosition(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id)
            .then(maxPosition => this.setState({ maxPosition }))
            .catch(error => console.error('An error occurred while fetching max active position.', error));
    }

    _fetchCarriersWithExceededLimit() {
        // TODO: figure out a more appropriate endpoint for this and move to the appropriate api service
        RestService.instance()
            .get(`auction/tendering/candidates-weekly-load-count?laneId=${ this.props.routingGuideLane.id }`)
            .then(carriersWithWeeklyLoadCount => {
                this.setState(prevState => {
                    for (const el of carriersWithWeeklyLoadCount) {
                        let carrier = prevState.carriers.find(c => c.carrier.id === el.carrierId);
                        if (carrier) {
                            carrier.numberOfLoads = el.numberOfLoads;
                        }
                    }

                    return { carriers: [ ...prevState.carriers ] };
                });
            });
    }

    _addDedicatedCarrier(carrier) {
        return TenderCandidateApiService.create(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, carrier);
    }

    _updateDedicatedCarrier(id, carrier) {
        return TenderCandidateApiService.update(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, id, carrier)
            .then(this.props.fetchNumberOfDedicatedCarriers);
    }

    _removeDedicatedCarrier(carrier) {
        TenderCandidateApiService.remove(this.props.routingGuideLane.rfpAuction.id, this.props.routingGuideLane.id, carrier.id)
            .then(this._fetchData)
            .then(this.props.fetchNumberOfDedicatedCarriers)
            .catch(error => console.error('An error occurred while removing dedicated carrier.', error));
    }

    _onTabChange(tabId) {
        if (!Object.values(TABS).includes(tabId)) {
            console.error('Unrecognized tab id.');
            return;
        } else if (tabId === this.state.activeTab) {
            // Nothing to do here
            return;
        }

        this.setState({ activeTab: tabId, page: Constants.DEFAULT_PAGE_NUMBER, available: 0 });
    }

    _setPage(page) {
        this.setState({ page });
    }

    _setPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _onExpandedChanged(expanded) {
        this.setState({ expanded });
    }

    _formContent() {
        let content = <></>;
        if (!this.state.loading && this.state.carriers.length === 0) {
            content =  this._formEmptyTableState();
        } else if (!this.state.loading) {
            content =  this._formCarriersTable();
        }

        return (
            <>
                { content }
                <Pagination
                    pageNumber={ this.state.page }
                    pageSize={ this.state.pageSize }
                    available={ this.state.available }
                    onSetPage={ this._setPage }
                    onSetPageSize={ this._setPageSize }
                    colorTheme="light"
                    fixed={ false }
                />
            </>
        );
    }

    _formEmptyTableState() {
        const auctionApplied = Constants.RFP_AUCTION_STATUSES.APPLIED === this.props.routingGuideLane.rfpAuction.status;
        const EMPTY_STATE = {
            [TABS.ACTIVE]: {
                title: 'No Carriers Added',
                description: auctionApplied ? <>To proceed, please add dedicated carriers to this lane.</> : <></>
            },
            [TABS.HISTORY]: {
                title: `History is ${ auctionApplied ? 'Currently' : '' } Empty`,
                description: auctionApplied ? <>Historic records of dedicated carriers' changes will be shown here.</> : <></> 
            }       
        };

        const { activeTab } = this.state;
        const emptyStateValue = EMPTY_STATE[activeTab];

        return (
            <TableEmptyState
                icon={ carriersIcon }
                title={ emptyStateValue.title }
                description={ emptyStateValue.description }
                role="tender-candidates:write"
            />
        );
    }

    _formCarriersTable() {
        let rankHeaderCell = <></>;
        let relevantDateHeader = 'Last Update';
        let showActions = false;
        let actionsHeaderCell = <></>;
        if (TABS.ACTIVE === this.state.activeTab) {
            rankHeaderCell = <TableHeaderCell className="rank" alignment="right">Rank</TableHeaderCell>;
            relevantDateHeader = 'Date Added';

            showActions = this.state.canEdit && Constants.RFP_AUCTION_STATUSES.APPLIED === this.props.routingGuideLane.rfpAuction.status;
            if (showActions) {
                actionsHeaderCell = <TableHeaderCell className="actions" alignment="right">Actions</TableHeaderCell>;
            }
        }

        return (
            <div className="dedicated-carriers-table">
                <Table>
                    <TableRow isHeader={ true } className={ `${ !showActions ? 'without-actions' : '' } ${ this.state.activeTab }` }>
                        { rankHeaderCell }
                        <TableHeaderCell className="name">Carrier Name</TableHeaderCell>
                        <TableHeaderCell className="price" alignment="right">Total Price</TableHeaderCell>
                        <TableHeaderCell className="price" alignment="right">Price Per Mile</TableHeaderCell>
                        <TableHeaderCell className="weekly-capacity" alignment="right">Weekly Capacity</TableHeaderCell>
                        <TableHeaderCell className="date-added" alignment="right">{ relevantDateHeader }</TableHeaderCell>
                        <TableHeaderCell className="carrier-status" alignment={ showActions ? 'left' : 'right' }>Status</TableHeaderCell>
                        { actionsHeaderCell }
                    </TableRow>
                    { this.state.carriers.map(carrier => {
                        return (
                            <DedicatedCarrierTableRow
                                key={ carrier.id }
                                routingGuideLane={ this.props.routingGuideLane }
                                dedicatedCarrier={ carrier }
                                numberOfLoads={ carrier.numberOfLoads }
                                documentTypes={ this.props.documentTypes }
                                type={ this.state.activeTab }
                                maxPosition={ this.state.maxPosition }
                                showActions={ showActions }
                                updateCarrier={ this._updateDedicatedCarrier }
                                removeCarrier={ this._removeDedicatedCarrier }
                                onActionPerformed={ this._fetchData }
                            />
                        );
                    }) }
                </Table>
            </div>
        );
    }

    render() {
        const table = this._formContent();

        let addCarriersAction = <></>;
        if (this.state.canEdit && this.state.expanded && Constants.RFP_AUCTION_STATUSES.APPLIED === this.props.routingGuideLane.rfpAuction.status) {
            addCarriersAction = (
                <DedicatedCarrierForm
                    mode="add"
                    routingGuideLane={ this.props.routingGuideLane }
                    maxPosition={ this.state.maxPosition }
                    onSubmit={ this._addDedicatedCarrier }
                    onActionPerformed={ this._fetchData }
                />
            );
        }

        return (
            <div className="dedicated-carriers-card">
                <ContentCard
                    isExpandable={ true }
                    heading="Dedicated Carriers"
                    headingSeparator={ false }
                    actions={ addCarriersAction }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    onExpandedStateChanged={ this._onExpandedChanged }
                    className="dedicated-carriers-content-card main-card"
                >
                    <SimpleTabs id="deducated-carriers-tabs" onTabChange={ this._onTabChange }>
                        <Tab default id={ TABS.ACTIVE } title="Carriers" activeIcon={ carriersActive } inactiveIcon={ carriersInactive }>
                            { table }
                        </Tab>
                        <Tab id={ TABS.HISTORY } title="History" activeIcon={ history } inactiveIcon={ history }>
                            { table }
                        </Tab>
                    </SimpleTabs>
                </ContentCard>
            </div>
        )
    }
}
