import PropTypes from 'prop-types';

export default class Token {

    static propType = () => PropTypes.shape({
        accessToken: PropTypes.string.isRequired,
        expiresOn: PropTypes.object
    })

    constructor({ accessToken, expiresOn }) {
        this.accessToken = accessToken;
        this.expiresOn = expiresOn;
    }
}
