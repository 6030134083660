import { Component } from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES, USER_ROLES } from 'common/constants';
import Button from 'component/Button';
import CustomSelect from 'component/form/CustomSelect';
import FormStatusModal from 'component/form/FormStatusModal';
import Popup from 'component/Popup';
import RegionApiService from 'service/api/RegionApiService';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import UserApiService from 'service/api/UserApiService';
import LaneUtils from 'utils/LaneUtils';

const ALL_OPTION = {
    title: 'All',
    field: null
};

const CARGO_TYPE_OPTIONS = [
    ALL_OPTION,
    {
        title: 'Refrigerated',
        field: 'REFRIGERATED'
    },
    {
        title: 'Dry',
        field: 'DRY'
    },
    {
        title: 'Flatbed',
        field: 'FLATBED'
    }
];

export default class AddLaneSpecificLogisticsCoordinatorsPopup extends Component {

    static propTypes = {
        routingGuideId: PropTypes.string,
        size: PropTypes.oneOf(['small', 'regular']),
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        routingGuideId: null,
        size: 'regular',
        onSubmit: () => { /* */ }
    };

    constructor(props) {
        super(props);

        this._onShowPopup = this._onShowPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._onSubmit = this._onSubmit.bind(this);

        this._fetchUsers = this._fetchUsers.bind(this);
        this._fetchOrigins = this._fetchOrigins.bind(this);
        this._fetchDestinations = this._fetchDestinations.bind(this);

        this._onUserSelect = this._onUserSelect.bind(this);
        this._onOriginSelect = this._onOriginSelect.bind(this);
        this._onDestinationSelect = this._onDestinationSelect.bind(this);
        this._onCargoTypeSelect = this._onCargoTypeSelect.bind(this);
    }

    state = {
        showPopup: false,
        loading: null,
        successfullyAdded: false,
        users: [],
        origins: [],
        destinations: [],
        selectedUser: null,
        selectedOrigin: null,
        selectedDestination: null,
        selectedCargoType: null
    };

    componentDidMount() {
        this._fetchUsers();
        this._fetchOrigins();
        this._fetchDestinations();
    }

    _onShowPopup() {
        this.setState({ showPopup: true });
    }

    _onClosePopup() {
        this.setState({
            showPopup: false,
            loading: null,
            successfullyAdded: false,
            selectedUser: null,
            selectedOrigin: null,
            selectedDestination: null,
            selectedCargoType: null
        });
    }

    _onSubmit() {
        this.setState({ loading: true });

        const { selectedUser, selectedOrigin, selectedDestination, selectedCargoType } = this.state;

        const params = {
            userId: !selectedUser?.field ? null : selectedUser.field.id,
            originId: !selectedOrigin?.field ? null : selectedOrigin.field.id,
            destinationId: !selectedDestination?.field ? null : selectedDestination.field.id,
            laneType: !selectedCargoType?.field ? null : selectedCargoType.field
        }

        RoutingGuideNotificationApiService.addLaneSpecificLogisticsCoordinator(this.props.routingGuideId, params)
            .then(successfullyAdded => this.setState({ successfullyAdded }))
            .then(this.props.onSubmit)
            .catch(error => console.error('An error occurred while adding lane specific logistics coordinator.', error))
            .finally(() => this.setState({ loading: false }));
    }

    _fetchUsers(search) {
        UserApiService.list({ roles: [ USER_ROLES.BROKER, USER_ROLES.RFP_MANAGER ], status: 'ACTIVE', search })
            .then(response => this.setState({
                users: [ALL_OPTION, ...response.data.map(user => ({
                    title: user.name,
                    field: user
                }))]
            }))
            .catch(error => console.error('An error occurred while fetching users.', error));
    }

    _fetchOrigins(search) {
        RegionApiService.fetchRegions(search)
            .then(response => this.setState({
                origins: [ALL_OPTION, ...response.data.map(region => ({
                    title: LaneUtils.formatRegion(region),
                    field: region
                }))]
            }))
            .catch(error => console.error('An error occurred while fetching origins.', error));
    }

    _fetchDestinations(search) {
        RegionApiService.fetchRegions(search)
            .then(response => this.setState({
                destinations: [ALL_OPTION, ...response.data.map(region => ({
                    title: LaneUtils.formatRegion(region),
                    field: region
                }))]
            }))
            .catch(error => console.error('An error occurred while fetching destinations.', error));
    }

    _onUserSelect(_value, option) {
        const selectedUser = option ? JSON.parse(option.key) : null;
        this.setState({ selectedUser });
    }

    _onOriginSelect(_value, option) {
        const selectedOrigin = option ? JSON.parse(option.key) : null;
        this.setState({ selectedOrigin });
    }

    _onDestinationSelect(_value, option) {
        const selectedDestination = option ? JSON.parse(option.key) : null;
        this.setState({ selectedDestination });
    }

    _onCargoTypeSelect(_value, option) {
        const selectedCargoType = JSON.parse(option.key);
        this.setState({ selectedCargoType });
    }

    render() {
        let content;

        if (this.state.loading === null) {
            const { selectedUser, selectedOrigin, selectedDestination, selectedCargoType } = this.state;

            content = (
                <>
                    <h6 className="heading"><b>Add New Specific Coordinator</b></h6>
                    
                    <p className="description">To add a new Lane Specific Coordinator, please enter the following information.</p>

                    <CustomSelect
                        label="User"
                        placeholder="Please choose user"
                        selectedValue={ selectedUser?.title }
                        onSelect={ this._onUserSelect }
                        withSearch={ true }
                        allowClear={ true }
                        values={ this.state.users }
                        onSearch={ this._fetchUsers }
                    />

                    <CustomSelect
                        label="Origin"
                        placeholder="Please choose origin"
                        selectedValue={ selectedOrigin?.title }
                        onSelect={ this._onOriginSelect }
                        withSearch={ true }
                        allowClear={ true }
                        values={ this.state.origins }
                        onSearch={ this._fetchOrigins }
                    />

                    <CustomSelect
                        label="Destination"
                        placeholder="Please choose destination"
                        selectedValue={ selectedDestination?.title }
                        onSelect={ this._onDestinationSelect }
                        withSearch={ true }
                        allowClear={ true }
                        values={ this.state.destinations }
                        onSearch={ this._fetchDestinations }
                    />

                    <CustomSelect
                        label="Cargo Type"
                        placeholder="Please choose cargo type"
                        selectedValue={ selectedCargoType?.title }
                        onSelect={ this._onCargoTypeSelect }
                        values={ CARGO_TYPE_OPTIONS }
                    />
                    
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onClosePopup }>
                            Cancel
                        </Button>
                        <Button
                            onClick={ this._onSubmit }
                            disabled={ !selectedUser?.field || (!selectedOrigin?.field && !selectedDestination?.field) }
                        >
                            Add New
                        </Button>
                    </div>
                </>
            );
        } else if (this.state.loading) {
            content = (
                <FormStatusModal status={ FORM_STATUSES.LOADING } onContinue={ this._onClosePopup }>
                    <h6>Adding New Coordinator</h6>
                    <small>This will only take a moment. Please wait...</small>
                </FormStatusModal>
            );
        } else if (!this.state.successfullyAdded) {
            content = (
                <FormStatusModal status={ FORM_STATUSES.EMPTY_SUCCESS } onContinue={ this._onClosePopup }>
                    <h6>No New Coordinators Added</h6>
                    <small>The list of coordinators remains unchanged. Make sure you have entered valid information.</small>
                </FormStatusModal>
            );
        } else {
            content = (
                <FormStatusModal status={ FORM_STATUSES.SUCCESS } onContinue={ this._onClosePopup }>
                    <h6>Coordinator Added</h6>
                    <small>Coordinator has been successfully added.</small>
                </FormStatusModal>
            );
        }

        return (
            <Popup
                id="add-notification-subscriptions-popup"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._onClosePopup }
                trigger={
                    <Button size={ this.props.size } leftIcon={ addIcon } onClick={ this._onShowPopup }>
                        Add New
                    </Button>
                }
            >
                <div className="add-notification-subscriptions-popup">
                    { content }
                </div>
            </Popup>
        );
    }
}
