import { Component } from 'react';
import PropTypes from 'prop-types';
import './Separator.scss';

export default class Separator extends Component {

    static propTypes = {
        orientation: PropTypes.oneOf(['horizontal', 'vertical'])
    }

    static defaultProps = {
        orientation: 'horizontal'
    }

    render() {
        return (
            <div className={ 'separator ' + this.props.orientation }></div>
        );
    }
}
