import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import CanAccess from 'component/CanAccess';
import TextEmphasisBox from 'component/TextEmphasisBox';
import TableCell from 'component/table/custom/TableCell';
import TableRow from 'component/table/custom/TableRow';
import CarrierStatusIcon  from 'component/carrier/CarrierStatusIcon';
import DedicatedCarrierForm from 'component/lane/DedicatedCarrierForm';
import RemoveDedicatedCarrier from 'component/lane/RemoveDedicatedCarrier';
import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './DedicatedCarriers.scss';

export default class DedicatedCarrierTableRow extends Component {

    static propTypes = {
        routingGuideLane: PropTypes.object.isRequired,
        dedicatedCarrier: PropTypes.object.isRequired,
        numberOfLoads: PropTypes.number,
        documentTypes: PropTypes.array,
        type: PropTypes.oneOf(['active', 'history']),
        maxPosition: PropTypes.number,
        showActions: PropTypes.bool,
        updateCarrier: PropTypes.func,
        removeCarrier: PropTypes.func,
        onActionPerformed: PropTypes.func,
    };

    static defaultProps = {
        numberOfLoads: 0,
        documentTypes: [],
        type: 'active',
        maxPosition: 1,
        showActions: true,
        updateCarrier: () => { /* */ },
        removeCarrier: () => { /* */ },
        onActionPerformed: () => { /* */ }
    }

    _formWeeklyLimit(carrier, numberOfLoads) {
        const weeklyLimitText = `${ numberOfLoads }/${ carrier.weeklyLimit }`;
        const weeklyLimitStyle = numberOfLoads >= carrier.weeklyLimit ? 'exceeded' : '';
    
        return { weeklyLimitStyle, weeklyLimitText };
    }

    _getRelevantDate() {
        const { dedicatedCarrier: { dateAdded, updated }, type } = this.props;
        const relevantDate = 'active' === type ? dateAdded : updated;

        return relevantDate ? DateUtils.format(relevantDate) : '/';
    }

    _formCarrierStatus() {
        const { status, offerExpiration } = this.props.dedicatedCarrier;
        const color = TENDER_CANDIDATE_STATUS[status].color;

        let statusText = TENDER_CANDIDATE_STATUS[status].text;
        if (TENDER_CANDIDATE_STATUS.PENDING.value === status) {
            const timeLeft = DateUtils.difference(new Date(), new Date(offerExpiration), { largestUnitOnly: true, oneLetterUnit: true, fewSecondsIdentifier: '1m' });
            statusText = statusText + `: ${ timeLeft } left`;
        }

        return (
            <TextEmphasisBox size="small" color={ color } wide={ true } className="status-box">
                { statusText }
            </TextEmphasisBox>
        );
    }

    _formActions() {
        if (!this.props.showActions) {
            return <></>;
        }

        return (
            <CanAccess 
                action="tender-candidates:write"
                yes={
                    <TableCell className="actions" alignment="right" padding="small">
                        <>
                            <DedicatedCarrierForm
                                mode="edit"
                                routingGuideLane={ this.props.routingGuideLane }
                                dedicatedCarrier={ this.props.dedicatedCarrier }
                                maxPosition={ this.props.maxPosition }
                                onSubmit={ body => this.props.updateCarrier(this.props.dedicatedCarrier.id, body) }
                                onActionPerformed={ this.props.onActionPerformed }
                            />
                            <RemoveDedicatedCarrier dedicatedCarrier={ this.props.dedicatedCarrier } onRemoveCarrier={ this.props.removeCarrier } />
                        </>
                    </TableCell>
                }
            />
        );
    }

    render() {
        const { dedicatedCarrier, numberOfLoads, type, showActions } = this.props;
        const businessId = dedicatedCarrier.carrier.businessId ? ` (${ dedicatedCarrier.carrier.businessId })` : '';
        const name = `${ dedicatedCarrier.carrier.name }${ businessId }`;
        const price = PriceUtils.calculatePerMilePrice(dedicatedCarrier.price.value, this.props.routingGuideLane.distance);
        const { weeklyLimitStyle, weeklyLimitText } = this._formWeeklyLimit(dedicatedCarrier, numberOfLoads);
        const relevantDate = this._getRelevantDate();
        const status = this._formCarrierStatus();
        const actions = this._formActions();

        return (
            <TableRow fontSize="small" className={ `${ !showActions ? 'without-actions' : '' } ${ this.props.type }` }>
                { 'active' === type ? <TableCell className="rank" padding="small" alignment="right">{ dedicatedCarrier.position }</TableCell> : <></> }
                <TableCell className="name" padding="small">
                    <div className={ `name-content ${ this.props.type }` }>
                    <CarrierStatusIcon  carrier={ dedicatedCarrier.carrier } className="carrier-warning" />
                        <Link to={ `/carrier/${ dedicatedCarrier.carrier.id }` } className="name-link">
                            <div className="name-ellipsis" title={ name }>{ name }</div>
                        </Link>
                    </div>
                </TableCell>
                <TableCell className="price" alignment="right" padding="small" >
                    { NumberUtils.prefixNumber(dedicatedCarrier.price.value, '$', false, 'decimal') }
                </TableCell>
                <TableCell className="price" alignment="right" padding="small" >
                    { NumberUtils.prefixNumber(price, '$', false, 'decimal') }
                </TableCell>
                <TableCell className={ `weekly-capacity ${ weeklyLimitStyle }` } alignment="right" padding="small">
                    { weeklyLimitText }
                </TableCell>
                <TableCell className="date-added" alignment="right" padding="small">{ relevantDate }</TableCell>
                <TableCell className="carrier-status" padding="small" alignment={ showActions ? 'left' : 'right' }>{ status }</TableCell>
                { actions }
            </TableRow>
        );
    }
}
