import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as warning, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH as menu } from '@fortawesome/pro-light-svg-icons';

import {
    AUCTION_STATUSES,
    AUCTION_STATUS_MAPPING,
    AUCTION_PHASE,
    AUCTION_BID_STATUS_MAPPING,
    BID_STATUSES,
    CANDIDATE_TYPE,
    EXCLUSION_REASONS,
    FALLOFF_BID_STATUSES,
    FAILED_BID_STATUSES,
    TERMINAL_BID_STATUSES,
    OFFERING_AUCTION_STATUSES
} from 'common/constants';
import Dropdown from 'component/Dropdown';
import Banner from 'component/Banner';
import TextEmphasisBox from 'component/TextEmphasisBox';
import TimeDisplay from 'component/TimeDisplay';
import Tooltip from 'component/Tooltip';
import SafeStateComponent from 'component/SafeStateComponent';
import DeclareWinnerPopup from 'component/load/DeclareWinnerPopup';
import ExtendOfferPopup from 'component/load/ExtendOfferPopup';
import RejectCurrentOfferPopup from 'component/load/RejectCurrentOfferPopup';
import AuctionUtils from 'utils/AuctionUtils';
import DateUtils from 'utils/DateUtils';
import DocumentUtils from 'utils/DocumentUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './CandidateOverviewCard.scss';

export default class CandidateOverviewCard extends SafeStateComponent {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        bid: PropTypes.object,
        currentBid: PropTypes.object,
        candidate: PropTypes.object.isRequired,
        bordered: PropTypes.bool,
        carrierNameColored: PropTypes.bool,
        relevant: PropTypes.bool,
        onDeclareWinner: PropTypes.func,
        onExtendOffer: PropTypes.func,
        onManualRejectOffer: PropTypes.func,
        account: PropTypes.object,
        carriersWithWeeklyLoadCount: PropTypes.arrayOf(PropTypes.shape({
            carrierId: PropTypes.string,
            numberOfLoads: PropTypes.number
        })),
        manualRejectReasons: PropTypes.array,
        displayActions: PropTypes.bool
    };

    static defaultProps = {
        bid: null,
        currentBid: null,
        relevant: false,
        bordered: true,
        carrierNameColored: false,
        onDeclareWinner: () => { /* */ },
        onExtendOffer: () => { /* */ },
        onManualRejectOffer: () => {/* */},
        account: null,
        carriersWithWeeklyLoadCount: [],
        manualRejectReasons: [],
        displayActions: true
    };

    constructor(props) {
        super(props);

        this._onDeclareWinner = this._onDeclareWinner.bind(this);
        this._onExtendOffer = this._onExtendOffer.bind(this);
        this._showBrokerActionsPopup = this._showBrokerActionsPopup.bind(this);
        this._closeBrokerActionsPopup = this._closeBrokerActionsPopup.bind(this);
    }

    state = {
        showBrokerActionsDropdown: false,
        falloffReason: null,
        documentTypes: null,
        showManualRejectPopup: false,
        selectedRejectReason: null,
        note: "",
        rejectionData: null
    }

    async componentDidMount() {
        super.componentDidMount();

        AuctionUtils.fetchBidFalloffReason(this.props.bid, this.props.account).then(falloffReason => {
            if (falloffReason) {
                this.setState({ falloffReason });
            }
        });

        AuctionUtils.fetchRejectedOfferData(this.props.bid, this.props.account).then(rejectionData => {
            if (rejectionData) {
                this.setState({ rejectionData });
            }
        });

        this._fetchDocumentTypes();
    }

    async _fetchDocumentTypes() {
        const documentTypes = await DocumentUtils.fetchDocumentTypes(false);
        this.setState({ documentTypes });
    }

     /**
     * Determines the status class as well as the text emphasis that should be used to style and emphasise data depending on the status of a bid.
     * Takes into account if the auction is canceled.
     *
     * @returns {string}
     * @private
     */
    _determineEmphasisStyle() {
        const { auction, bid } = this.props;
        const status = bid?.status;
        const current = bid?.current || false;
        const auctionCanceled = AUCTION_STATUSES.CANCELED === auction.status;

        let emphasisStyle = '';
        let textEmphasisBoxColor = '';

        if (current && !auctionCanceled && (BID_STATUSES.PENDING === status || BID_STATUSES.CONFIRMED === status)) {
            emphasisStyle = 'pending';
            textEmphasisBoxColor = 'orange';
        } else if (BID_STATUSES.WINNER === status) {
            emphasisStyle = 'ready';
            textEmphasisBoxColor = 'cyan';
        } else if (FALLOFF_BID_STATUSES.indexOf(status) !== -1) {
            emphasisStyle = 'falloff';
            textEmphasisBoxColor = 'grey';
        } else if (BID_STATUSES.NOT_SENT === status) {
            emphasisStyle = 'failed';
            textEmphasisBoxColor = 'grey';
        } else if (FAILED_BID_STATUSES.indexOf(status) !== -1 || auctionCanceled) {
            emphasisStyle = 'failed';
        }

        return [ emphasisStyle, textEmphasisBoxColor ];
    }

    /**
     * Determines the time which should be displayed in the status column of a bid card.
     * For current bids it displays the time bid became current (updated time).
     * For bids that are not current:
     *  - pending or expired: expiration date
     *  - falloff or winner: bid update time
     *  - confirmed bid: auction deadline (since the next relevant time is deadline for sending carrier details)
     *
     * @param {Object} bid
     * @param {string} deadline
     * @returns {Array}
     * @private
     */
    static _determineTimeDisplayForBid(bid, deadline) {
        const startDate = Date.now();

        let endDate;
        if (bid && !bid.current) {
            endDate = new Date(bid.updated);
        } else if (bid) {
            if (BID_STATUSES.PENDING === bid.status || BID_STATUSES.EXPIRED === bid.status) {
                endDate = new Date(bid.expiration);
            } else if (TERMINAL_BID_STATUSES.indexOf(bid.status) !== -1 || BID_STATUSES.WINNER === bid.status) {
                endDate = new Date(bid.updated);
            } else if (BID_STATUSES.CONFIRMED === bid.status) {
                endDate = new Date(deadline);
            }
        }

        return DateUtils.findTimeBetween(startDate, endDate);
    }

    static _getRelevanceClass(relevant, exclusion) {
        return relevant && !exclusion ? 'relevant' : 'irrelevant';
    }

    static _formExpirationIcon(current, relevant, hasInvalidDocuments, exclusion, hasBeenTerminated) {
        let warningIcon = <></>;

        if (!current && (hasBeenTerminated || hasInvalidDocuments)) {
            const iconStyle = CandidateOverviewCard._getRelevanceClass(relevant, exclusion);
            warningIcon = (
                <div className="warning-icon">
                    <FontAwesomeIcon icon={ warning } className={ `icon ${ iconStyle }` } />
                    <Tooltip direction="bottom">
                        { hasBeenTerminated ? 'Terminated carrier' : 'Missing or expired documents' }
                    </Tooltip>
                </div>
            );
        }

        return warningIcon;
    }

    static _formBidPriceDisplay(fullPrice, statusClass) {
        let loadPriceDisplay = `$${ NumberUtils.formatWithDecimalNotation(fullPrice) }`;
        if (!!statusClass) {
            loadPriceDisplay = <TextEmphasisBox children={ loadPriceDisplay } color={ statusClass } size="medium" />;
        }

        return loadPriceDisplay;
    }

    _formBidDate() {
        const bid = this.props.bid;
        return bid ? DateUtils.format(new Date(bid.time)) : '----';
    }

    static _formStatusLabel(bid, auctionCanceled, skipped) {
        if (auctionCanceled && BID_STATUSES[bid.status] === BID_STATUSES.PENDING) {
            return AUCTION_STATUS_MAPPING[AUCTION_STATUSES.CANCELED];
        }

        if (skipped) {
            return 'Skipped';
        }

        return AUCTION_BID_STATUS_MAPPING[bid.status];
    }

    _formSkippedBidTooltip() {
        const active = this.props.candidate.carrier.active;
        const reason = active ? "Someone else was declared as the winner." : "Terminated carrier";
        return (
            <div className="title-tooltip">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction="bottom">
                    { reason }
                </Tooltip>
            </div>
        );
    }

    static _isSkipped(candidate, bid, currentBid) {
        if (bid) {
            return !bid.current && BID_STATUSES.PENDING === bid.status;
        } else {
            return currentBid && currentBid.candidate.position > candidate.position;
        }
    }

    static _formTimeDisplay(bid, deadline, auctionCanceled) {
        let timeDisplay = <small>----</small>;

        if (bid && BID_STATUSES.NOT_SENT !== bid.status && !auctionCanceled) {
            const [ duration, timeDescriptor ] = CandidateOverviewCard._determineTimeDisplayForBid(bid, deadline);
            timeDisplay = <TimeDisplay duration={ duration } descriptor={ timeDescriptor } numberStyle="normal" />;
        }

        return timeDisplay;
    }

    _formStatusTooltip() {
        const { falloffReason, rejectionData } = this.state;
        let tooltipText;
        
        if (falloffReason) {
            tooltipText = falloffReason.reason;
        } else if (rejectionData) {
            const additionalNote = rejectionData.noteRequired ? `\nand note: "${ rejectionData.note }"` : "";
            tooltipText = `Rejected by ${ rejectionData.rejectedBy.name } with reason: "${ rejectionData.reason }"${ additionalNote }`;
        } else if (BID_STATUSES.NOT_SENT === this.props.bid.status) {
            tooltipText = `Offer was not sent to carrier due to \n  their terminated status in TMW.`;   
        } else {
            return <></>;
        }

        return (
            <div className="title-tooltip">
                <FontAwesomeIcon icon={ info } />
                <Tooltip direction="bottom">
                    { tooltipText }
                </Tooltip>
            </div>
        );
    }

    static _determineExclusionStatusText(exclusion) {
        let reason, tooltip;
        switch(exclusion.reason) {
            case EXCLUSION_REASONS.QUOTA_FULFILLED:
                reason = 'Not Accepting Loads';
                tooltip = `This carrier doesn't want to receive any offers at the time of this load's pickup date.`;
                break;
            case EXCLUSION_REASONS.FALLOFF:
                reason = 'Excluded';
                tooltip = 'This carrier is excluded due to previous falloff from this load.';
                break;
            case EXCLUSION_REASONS.TERMINATED:
                reason = 'Excluded';
                tooltip = exclusion.candidate.carrier.active ? 'This carrier was terminated and became active during this auction.' : 'This carrier has been terminated.';
                break;
            case EXCLUSION_REASONS.REJECTED_OFFER:
                reason = 'Excluded';
                tooltip = 'This carrier has rejected this load before.';
                break;
            case EXCLUSION_REASONS.LIMIT_EXCEEDED:
                reason = 'Excluded';
                tooltip = 'This carrier has exceeded their weekly limit.';
                break;
            default:
                reason = 'Excluded';
                tooltip = exclusion.note;
                break;
        }

        return { reason, tooltip };
    }

    _formStatusDisplay(statusClass, fieldContentClass, hasInvalidDocuments, exclusion) {
        const { auction, bid, candidate, currentBid } = this.props;
        const deadline = auction.deadline;
        const auctionCanceled = AUCTION_STATUSES.CANCELED === auction.status;
        const skipped = CandidateOverviewCard._isSkipped(candidate, bid, currentBid);
        const timeDisplay = CandidateOverviewCard._formTimeDisplay(bid, deadline, auctionCanceled);

        let statusLabel = <></>;
        let statusTime = <small className="status-time">----</small>;
        let statusTooltip = <></>;

        if (bid && !skipped) {
            const statusName = CandidateOverviewCard._formStatusLabel(bid, auctionCanceled, skipped);
            statusLabel = <b className={ `status-label ${ statusClass }` }>{ statusName }</b>
            statusTooltip = this._formStatusTooltip();
            statusTime = timeDisplay;
        } else if (exclusion) {
            const exclusionStatus = CandidateOverviewCard._determineExclusionStatusText(exclusion);
            statusLabel = <b className="status-label failed">{ exclusionStatus.reason }</b>;
            statusTooltip = (
                <div className="title-tooltip">
                    <FontAwesomeIcon icon={ info } />
                    <Tooltip direction="bottom">
                        { exclusionStatus.tooltip }
                    </Tooltip>
                </div>
            );
        } else if (skipped) {
            statusLabel = <b className="status-label">Skipped</b>;
            statusTooltip = this._formSkippedBidTooltip();
            statusTime = timeDisplay;
        } else if (hasInvalidDocuments) {
            statusLabel = <b className="status-label failed">Invalid documents</b>;
        }

        return (
            <>
                <small className="field-title">
                    Status { statusLabel } { statusTooltip }
                </small>
                <div className={ fieldContentClass }>
                    { statusTime }
                </div>
            </>
        );
    }

    _formWarningBox(hasInvalidDocuments) {
        const { bid, candidate } = this.props;
        const current = bid?.current || false;
        const hasBeenTerminated = !candidate.carrier.active;

        let warningBox = <></>;
        if (current && hasBeenTerminated) {
            warningBox = <Banner type="error" content="Terminated carrier" size="medium" textStyle="bold" />;
        } else if (current && hasInvalidDocuments) {
            warningBox = <Banner type="error" content="Missing or expired documents" size="medium" textStyle="bold" />;
        }

        return warningBox;
    }

    static _determineStyleClass(emphasisStyle, auctionCanceled, bordered) {
        const classes = [
            bordered ? 'bordered' : 'borderless',
            auctionCanceled ? 'failed' : emphasisStyle
        ];

        return classes.join(' ');
    }

    static _formCarrierNameClass(isCurrent, hasInvalidDocuments, carrierNameColored, emphasisStyle) {
        let style = carrierNameColored ? emphasisStyle : '';

        if (isCurrent && hasInvalidDocuments) {
            style += ' expired';
        }

        return style;
    }

    _formWeeklyLimitDisplay() {
        const { candidate, relevant, carriersWithWeeklyLoadCount } = this.props;
        const carrierWithWeeklyLoadCount = carriersWithWeeklyLoadCount.find(el => el.carrierId === candidate.carrier.id);
        const weeklyLimitText = candidate.weeklyLimit != null ? `${ carrierWithWeeklyLoadCount ? carrierWithWeeklyLoadCount.numberOfLoads : 0 }/${ candidate.weeklyLimit }` : '----';

        let weeklyLimitStyle = '';
        if (relevant && candidate.weeklyLimit && carrierWithWeeklyLoadCount?.numberOfLoads >= candidate.weeklyLimit) {
            weeklyLimitStyle = 'exceeded';
        }

        return { weeklyLimitStyle, weeklyLimitText };
    }

    _formBidCard() {
        const { auction, bid, candidate, relevant, bordered, carrierNameColored } = this.props;
        const { documentTypes } = this.state;

        const distance = auction.load.routingGuideLane.distance;
        const isCurrent = bid?.current || false;
        const auctionCanceled = AUCTION_STATUSES.CANCELED === auction.status;
        const hasInvalidDocuments = DocumentUtils.hasInvalidDocuments(candidate.carrier.documents, documentTypes);
        const fullPrice = (bid || candidate).price;
        const pricePerMile = PriceUtils.formatPerMilePrice(fullPrice, distance);
        const exclusion = AuctionUtils.findCandidateExclusion(auction, candidate);

        const [ emphasisStyle, textEmphasisBoxColor ] = this._determineEmphasisStyle();
        const fieldContentClass = `field-content ${ CandidateOverviewCard._getRelevanceClass(relevant, exclusion) }`;
        const carrierNameClass = CandidateOverviewCard._formCarrierNameClass(isCurrent, hasInvalidDocuments, carrierNameColored, emphasisStyle);
        const statusClass = CandidateOverviewCard._determineStyleClass(emphasisStyle, auctionCanceled, bordered);

        const expirationIcon = CandidateOverviewCard._formExpirationIcon(isCurrent, relevant, hasInvalidDocuments, exclusion, !candidate.carrier.active);
        const bidPriceDisplay = CandidateOverviewCard._formBidPriceDisplay(fullPrice, textEmphasisBoxColor);
        const bidDate = this._formBidDate();
        const statusDisplay = this._formStatusDisplay(emphasisStyle, fieldContentClass, hasInvalidDocuments, exclusion);
        const warningBox = this._formWarningBox(hasInvalidDocuments);
        const brokerActionsMenu = this.props.displayActions ? this._formBrokerBidActionsMenu() : <></>;
        const { weeklyLimitStyle, weeklyLimitText } = this._formWeeklyLimitDisplay();

        return (
            <div key={ candidate.id } className={ `component bid ${ statusClass }` }>
                <div className="data">
                    <div className="field name">
                        <small className="field-title">
                            { CANDIDATE_TYPE.ON_DEMAND === candidate.type && 'On-Demand'} Carrier <b>#{ candidate.position }</b>
                        </small>
                        <strong className={ `${ fieldContentClass } ${ carrierNameClass }` }>
                            { expirationIcon }
                            <div className="carrier-name">
                                <Link to={ `/carrier/${ candidate.carrier.id }` } title={ candidate.carrier.name }>
                                    { candidate.carrier.name }
                                </Link>
                            </div>
                        </strong>
                    </div>
                    <div className="field bid-total">
                        <small className="field-title">
                            Total Price
                        </small>
                        <h6 className={ fieldContentClass }>
                            { bidPriceDisplay }
                        </h6>
                    </div>
                    <div className="field bid-per-mile">
                        <small className="field-title">
                            Per Mile
                        </small>
                        <small className={ fieldContentClass }>
                            ${ pricePerMile }
                        </small>
                    </div>
                    <div className="field date">
                        <small className="field-title">
                            Date of Offer
                        </small>
                        <small className={ fieldContentClass }>
                            { bidDate }
                        </small>
                    </div>
                    <div className="field weekly-limit">
                        <small className={ `field-title ${ weeklyLimitStyle }` }>
                            Weekly Capacity
                        </small>
                        <div className={ `${ fieldContentClass } ${ weeklyLimitStyle }` }>
                            { weeklyLimitText }
                        </div>
                    </div>
                    <div className="field offer-status">
                        { statusDisplay }
                    </div>
                    { brokerActionsMenu }
                </div>
                <div className="banner-wrapper">
                    { warningBox }
                </div>
            </div>
        );
    }

    _onDeclareWinner() {
        this.setState({
            showBrokerActionsDropdown: false
        });

        this.props.onDeclareWinner();
    }

    _onExtendOffer(expiration) {
        this.setState({
            showBrokerActionsDropdown: false
        });

        const { auction, bid } = this.props;
        this.props.onExtendOffer(auction, bid, expiration);
    }

    _showBrokerActionsPopup() {
        this.setState({ showBrokerActionsDropdown: true });
    }

    _closeBrokerActionsPopup() {
        this.setState({ showBrokerActionsDropdown: false });
    }

    _formBrokerBidActionsMenu() {
        const { auction, bid, candidate } = this.props;
        let brokerActions = <></>;
        const actions = [];

        const canRejectOffer = bid &&
            (BID_STATUSES.PENDING === bid.status || BID_STATUSES.NOT_SENT === bid.status) &&
            bid.current &&
            OFFERING_AUCTION_STATUSES.includes(auction.status) &&
            AUCTION_PHASE.DEDICATED === auction.phase;

        const canExtendOffer = bid &&
            candidate.carrier.active &&
            BID_STATUSES.PENDING === bid.status &&
            bid.current &&
            OFFERING_AUCTION_STATUSES.includes(auction.status) &&
            AUCTION_PHASE.DEDICATED === auction.phase;

        if (canExtendOffer) {
            actions.push(
                <ExtendOfferPopup
                    bid={ bid }
                    auction={ auction }
                    onExtendOffer={ this._onExtendOffer }
                    onCancelExtendOffer={ this._closeBrokerActionsPopup }
                />
            );
        }

        if (AuctionUtils.canDeclareWinner(auction) && candidate.carrier.active) {
            actions.push(
                <DeclareWinnerPopup
                    auctionId={ auction.id }
                    bid={ bid }
                    candidate={ candidate }
                    onDeclareWinner={ this._onDeclareWinner }
                    onCancelWinnerDeclaration={ this._closeBrokerActionsPopup }
                />
            );
        }

        if (canRejectOffer) {
            actions.push(
                <RejectCurrentOfferPopup
                    onManualRejectOffer={ this.props.onManualRejectOffer }
                    onCancel={ this._closeBrokerActionsPopup }
                    manualRejectReasons={ this.props.manualRejectReasons }
                    candidate={ candidate }
                />
            );
        }

        if (actions.length > 0) {
            brokerActions = (
                <div className="broker-bid-actions">
                    <Dropdown id="broker-actions-dropdown"
                        direction="bottom-left"
                        show={ this.state.showBrokerActionsDropdown }
                        onClose={ this._closeBrokerActionsPopup }
                        trigger={(
                            <a href="#!" className="menu-icon-container" onClick={ this._showBrokerActionsPopup }>
                                <FontAwesomeIcon icon={ menu } className="icon"/>
                            </a>
                        )}
                        actions={ actions }/>
                </div>
            );
        }

        return brokerActions;
    }

    render() {
        return this._formBidCard();
    }
}
