export default class DomUtils {

    static getTransformStyle(element) {
        return DomUtils.getStyle(element, 'transform');
    }

    static getStyle(element, property) {
        const transform = getComputedStyle(element)[property];
        return transform !== 'none' ? transform : '';
    }
}
