import RestService from "service/RestService";
import CarrierApiService from "service/api/CarrierApiService";
import * as Constants from "common/constants";

export default class DocumentUtils {
    /**
     * Determines whether or not there are expired documents.
     * A document is considered expired if it is APPROVED, relevant and has an expiration date that has passed.
     *
     * @param {Array} documents
     * @returns {boolean}
     */
    static hasExpirations(documents) {
        return documents.some(document =>
            Constants.USER_DOCUMENT_STATUSES.APPROVED.value === document.status &&
            document.relevant &&
            document.expiresOn &&
            this.documentExpired(document)
        );
    }

    /**
     * Determines if the document has expired
     * 
     * @param {Object} document
     * @returns {boolean}
     */
    static documentExpired(document) {
        return new Date(document.expiresOn) < Date.now();
    }

    /**
     * Determines whether or not there are missing documents.
     * A document has to be approved in order to not be considered missing.
     *
     * @param {Array} documents
     * @param {Array} documentTypes
     * @returns {boolean}
     */
    static hasMissingDocuments(documents, documentTypes) {
        if (!documentTypes) {
            return false;
        }

        const hasRequiredDocuments = documentTypes.filter(type => type.required && type.groupId === null).every(requiredType => (
            documents.some(carrierDocument => carrierDocument.type.businessId === requiredType.businessId && Constants.USER_DOCUMENT_STATUSES.APPROVED.value === carrierDocument.status && carrierDocument.relevant)
        ));

        if (!hasRequiredDocuments) {
            return true;
        }

        const groupDocuments = documentTypes.filter(type => type.groupId !== null).reduce((group, type) => {
            const groupId = type.groupId;
            group[groupId] = [ ...(group[groupId] || []), type ];
            return group;
        }, {});

        for (const groupTypes of Object.values(groupDocuments)) {
            const hasGroupDocuments = groupTypes.some(groupType => (
                documents.some(carrierDocument => carrierDocument.type.businessId === groupType.businessId && Constants.USER_DOCUMENT_STATUSES.APPROVED.value === carrierDocument.status && carrierDocument.relevant)
            ));

            if (!hasGroupDocuments) {
                return true;
            }
        }

        return false;
    }

    /**
     * Determines whether there are invalid documents by utilizing the hasMissingDocuments and hasExpirations methods.
     *
     * @param {Array} documents
     * @param {Array} documentTypes
     * @returns
     */
    static hasInvalidDocuments(documents, documentTypes) {
        return this.hasExpirations(documents) || this.hasMissingDocuments(documents, documentTypes);
    }

    /**
     * Determines whether there are invalid documents by endpoint.
     *
     * @param {Array} carrierId
     * @returns
     */
     static hasInvalidDocument(carrierId) {
        return CarrierApiService.hasInvalidDocuments(carrierId);
    }

    /**
     * Fetches document types with the option to only fetch required types if the first parameter is set to true.
     * 
     * @param {boolean} required
     * @returns {Promise}
     */
    static fetchDocumentTypes(required) {
        return RestService.instance().get(`documents/types?requiredOnly=${ required }`);
    }

    /**
     * Fetches available document reject reasons.
     * 
     * @param {Object} account
     * @returns {Promise} 
     */
    static fetchAvailableRejectReasons(account) {
        const eligibleRoles = [Constants.USER_ROLES_MAPPING.BROKER, Constants.USER_ROLES_MAPPING.ADMINISTRATOR];
        const canReject = account && account.roles.find(role => eligibleRoles.indexOf(role) !== -1);
        if (canReject) {
            return RestService.instance().get(`broker/documents/reject-reasons`);
        }

        return Promise.resolve();
    }

    /**
     * Fetches the rejection reason for the document with the provided id.
     * 
     * @param {string} documentId
     * @returns {Promise} 
     */
    static fetchRejectionReason(documentId) {
        return RestService.instance().get(`documents/${ documentId }/reject-reason`);
    }

    /**
     * Sends request to approve the document with the provided document id.
     * 
     * @param {string} documentId 
     * @param {moment} expirationDate 
     * @returns {Promise} 
     */
    static approveDocument(documentId, expirationDate) {
        return RestService.instance().put(`broker/documents/${ documentId }/approve`, expirationDate);
    }

    /**
     * Sends request to reject the document with the provided document id.
     * 
     * @param {string} documentId
     * @param {Object} reason
     * @param {string} note
     * @returns {Promise} 
     */
    static rejectDocument(documentId, reason, note) {
        const data = { id: reason.id, note };
        return RestService.instance().put(`broker/documents/${ documentId }/reject`, data);
    }

    /**
     * Sends request to edit expiration date of the document with the provided id.
     * 
     * @param {string} documentId 
     * @param {moment} expirationDate 
     * @returns {Promise}
     */
    static editDocumentExpirationDate(documentId, expirationDate) {
        return RestService.instance().put(`broker/documents/${ documentId }/expiration`, expirationDate);
    }

    /**
     * Sends request to upload document.
     * 
     * @param {Array} types
     * @param {File} file
     * @param {string} carrierId
     * @param {Object} account
     * @returns {Promise}
     */
    static uploadDocument(types, file, carrierId, account) {
        const formData = new FormData();
        formData.append('document', file);

        const isBroker = account.roles.indexOf(Constants.USER_ROLES_MAPPING.BROKER) !== -1;
        const isAdministrator = account.roles.indexOf(Constants.USER_ROLES_MAPPING.ADMINISTRATOR) !== -1;
        const apiPrefix = isBroker || isAdministrator ? `broker/user/${ carrierId }` : 'carrier';

        const headers = {
            'X-Document-Types': types.join(','),
            'X-Uploaded-File-Size': file.size
        };

        return RestService.instance().post(`${ apiPrefix }/documents`, formData, headers);
    }

    static canReadAllDocumentReasons(account) {
        return account.permissions.indexOf(Constants.READ_ALL_DOCUMENTS_PERMISSION) !== -1;
    }
}
