import { Component } from 'react';
import PropTypes from 'prop-types';

import './Table.scss';

export default class Table extends Component {

    static propTypes = {
        className: PropTypes.string
    };

    static defaultProps = {
        className: ''
    };

    render() {
        return (
            <table className={ `simple-table ${ this.props.className }` }>
                <tbody>
                    { this.props.children }
                </tbody>
            </table>
        );
    }
}
