import { Component } from 'react';
import PropTypes from 'prop-types';

import DateUtils from 'utils/DateUtils';
import NumberUtils from 'utils/NumberUtils';
import ContentCard from 'component/card/ContentCard';
import TableColumn from 'component/table/simple/TableColumn';
import Table from 'component/table/simple/Table';
import TableRow from 'component/table/simple/TableRow';

import './PaymentDetailsCard.scss';

export default class PaymentDetailsCard extends Component {
    static propTypes = {
        paymentDetails: PropTypes.array,
        showCarrier: PropTypes.bool
    };

    static defaultProps = {
        paymentDetails: [],
        showCarrier: false
    };

    render() {
        if (this.props.paymentDetails.length === 0) {
            return <></>;
        }

        return (
            <div className="payment-details">
                <ContentCard
                    heading="Payments"
                    isExpandable={ true }
                    expandLabel="Show Details"
                    collapseLabel="Hide Details"
                    className="main-card"
                >
                    <Table>
                        <TableRow header={ true }>
                            { this.props.showCarrier && <TableColumn>Carrier</TableColumn> }
                            <TableColumn>Pay Type</TableColumn>
                            <TableColumn>Description</TableColumn>
                            <TableColumn align="right">Amount</TableColumn>
                            <TableColumn align="right">Estimated Pay Date</TableColumn>
                        </TableRow>

                        { this.props.paymentDetails.map(payment => (
                            <TableRow key={ payment.id }>
                                { this.props.showCarrier && 
                                    <TableColumn title={ payment.carrier.name } className="carrier-name column-with-overflow">
                                        { payment.carrier.name }
                                    </TableColumn>
                                }
                                <TableColumn>
                                    { payment.type }
                                </TableColumn>
                                <TableColumn title={ payment.description } className="description column-with-overflow">
                                    { payment.description || '/' }
                                </TableColumn>
                                <TableColumn align="right">
                                    { NumberUtils.prefixNumber(payment.amount, '$', false, 'decimal') }
                                </TableColumn>
                                <TableColumn align="right">
                                    { payment.payDate ? DateUtils.formatDate(payment.payDate) : 'In Process' }
                                </TableColumn>
                            </TableRow>
                        )) }
                    </Table>
                </ContentCard>
            </div>
        );
    }
}
