import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMinusCircle as minusIcon,
    faBadgeDollar as dollarIcon,
    faArrowRight as arrow
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCheckCircle as checkIcon, 
    faGavel as gavelIcon
} from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES, LANE_TYPE_PROPERTIES } from 'common/constants';
import Button from 'component/Button';
import FormStatusModal from 'component/form/FormStatusModal';
import CancelLoadPopup from 'component/load/CancelLoadPopup';
import CurrentBid from 'component/load/CurrentBid';
import LoadTermsOfServicePopup from 'component/load/LoadTermsOfServicePopup';
import Popup from 'component/Popup';
import Separator from 'component/Separator';
import Tooltip from 'component/Tooltip';
import TenderAuctionCarrierApiService from 'service/api/TenderAuctionCarrierApiService';
import DateUtils from 'utils/DateUtils';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';

import './CarrierActions.scss';

export default class CarrierActions extends Component {

    static propTypes = {
        type: PropTypes.oneOf(['cancel', 'accept/reject', 'spot-market']),
        size: PropTypes.oneOf(['regular', 'small']),
        cancelOfferProps: PropTypes.shape({
            onCancelOffer: PropTypes.func,
            availableFalloffReasons: PropTypes.array,
            disabled: PropTypes.bool
        }),
        acceptRejectOfferProps: PropTypes.shape({
            auction: PropTypes.object,
            price: PropTypes.number,
            lane: PropTypes.object,
            onActionPerformed: PropTypes.func
        }),
        spotMarketActionsProps: PropTypes.shape({
            auction: PropTypes.object,
            negotiatedRate: PropTypes.number,
            currentBid: PropTypes.object, 
            carrierBid: PropTypes.object,
            onActionPerformed: PropTypes.func
        }),
        onLinkEnable: PropTypes.func,
        onLinkDisable: PropTypes.func
    };

    static defaultProps = {
        type: 'cancel',
        size: 'regular',
        cancelOfferProps: {
            onCancelOffer: () => { /* */ },
            availableFalloffReasons: [],
            disabled: false
        },
        acceptRejectOfferProps: {
            auctionId: null,
            price: null,
            lane: null,
            onActionPerformed: () => { /* */ }
        },
        spotMarketActionsProps: {
            auction: null,
            negotiatedRate: null,
            currentBid: null, 
            carrierBid: null,
            onActionPerformed: () => { /* */ }
        },
        onLinkEnable: () => { /* */ },
        onLinkDisable: () => { /* */ }
    };

    constructor(props) {
        super(props);
        this._onOpenRejectOfferPopup = this._onOpenRejectOfferPopup.bind(this);
        this._onCloseRejectOfferPopup = this._onCloseRejectOfferPopup.bind(this);
        this._onRejectOffer = this._onRejectOffer.bind(this);
        this._onOpenAcceptOfferPopup = this._onOpenAcceptOfferPopup.bind(this);
        this._onCloseAcceptOfferPopup = this._onCloseAcceptOfferPopup.bind(this);
        this._onAcceptOffer = this._onAcceptOffer.bind(this);
        this._onDoNotReceiveOffersForLaneCheckboxChange = this._onDoNotReceiveOffersForLaneCheckboxChange.bind(this);
        this._handleEndDateChange = this._handleEndDateChange.bind(this);
        this._onOpenBookNowPopup = this._onOpenBookNowPopup.bind(this);
        this._onCloseBookNowPopup = this._onCloseBookNowPopup.bind(this);
        this._onBookNow = this._onBookNow.bind(this);
        this._onOpenBidPopup = this._onOpenBidPopup.bind(this);
        this._onCloseBidPopup = this._onCloseBidPopup.bind(this);
        this._onPostBid = this._onPostBid.bind(this);
    }

    _dateFormat = 'YYYY-MM-DD';

    state = {
        showCancelModal: false,
        showAcceptOfferModal: false,
        showRejectOfferModal: false,
        acceptOfferModalStatus: undefined,
        rejectOfferModalStatus: undefined,
        doNotReceiveOffersForLane: false,
        acceptTermsAndConditions: false,
        showBookNowModal: false,
        bookNowModalStatus: undefined,
        biddingFormModalStatus: undefined,
        showBidModal: false,
        biddingFormVersion: 0,
        endDate: null
    }

    _formOfferPopupHeader(auction) {
        const lane = auction.load.routingGuideLane.lane;
        const origin = LaneUtils.formatRegion(lane.origin);
        const destination = LaneUtils.formatRegion(lane.destination);

        return (
            <>
                <div className="popup-header">
                    <div className="popup-header-section">
                        <div className="popup-header-section-title">#{ auction.load.businessId }</div>
                        <div className="popup-header-section-description">
                            <FontAwesomeIcon icon={ LANE_TYPE_PROPERTIES[lane.type].icon } className="popup-header-icon" />
                            <div>{ LANE_TYPE_PROPERTIES[lane.type].label }</div>
                        </div>
                    </div>

                    <Separator orientation="vertical" />

                    <div className="popup-header-section region">
                        <div className="popup-header-section-title" title={ origin }>{ origin }</div>
                        <div className="popup-header-section-description">
                            { DateUtils.formatLocal(new Date(auction.load.pickupTime), auction.load.pickupTimeOffset) }
                        </div>
                    </div>

                    <div className="popup-header-section">
                        <FontAwesomeIcon icon={ arrow } className="popup-header-icon arrow" />
                    </div>

                    <div className="popup-header-section region">
                        <div className="popup-header-section-title" title={ destination }>{ destination }</div>
                        <div className="popup-header-section-description">
                            { DateUtils.formatLocal(new Date(auction.load.deliveryTime), auction.load.deliveryTimeOffset) }
                        </div>
                    </div>
                </div>
            
                <Separator />
            </>
        );
    }

    _onDoNotReceiveOffersForLaneCheckboxChange() {
        this.setState(prevState => ({
            doNotReceiveOffersForLane: !prevState.doNotReceiveOffersForLane
        }));
    }

    _handleEndDateChange(endDate) {
        const date = endDate ? endDate.endOf('day') : null;
        this.setState({ endDate: date });
    }

    _onOpenRejectOfferPopup(event) {
        event.stopPropagation();
        this.setState({ showRejectOfferModal: true, rejectOfferModalStatus: undefined });
        this.props.onLinkDisable();
    }

    _onCloseRejectOfferPopup(event) {
        if (event) {
            event.preventDefault();
        }

        if (FORM_STATUSES.LOADING !== this.state.rejectOfferModalStatus) {
            this.setState({ showRejectOfferModal: false, doNotReceiveOffersForLane: false, endDate: null });
            this.props.onLinkEnable();
        }
    }

    _onRejectOffer(event) {
        event.preventDefault();

        this.setState({ rejectOfferModalStatus: FORM_STATUSES.LOADING });

        TenderAuctionCarrierApiService.rejectOffer(this.props.acceptRejectOfferProps.auction.id)
            .then(() => this.setState({ rejectOfferModalStatus: FORM_STATUSES.SUCCESS }, this._onCloseRejectOfferPopup))
            .then(this.props.acceptRejectOfferProps.onActionPerformed)
            .catch(() => this.setState({ rejectOfferModalStatus: FORM_STATUSES.ERROR }));
    }

    _onOpenAcceptOfferPopup(event) {
        event.stopPropagation();
        this.setState({ showAcceptOfferModal: true, acceptOfferModalStatus: undefined });
        this.props.onLinkDisable();
    }

    _onCloseAcceptOfferPopup(event) {
        if (event) {
            event.preventDefault();
        }

        if (FORM_STATUSES.LOADING !== this.state.acceptOfferModalStatus) {
            this.setState({ showAcceptOfferModal: false, acceptTermsAndConditions: false });
            this.props.onLinkEnable();
        }
    }

    _onAcceptOffer(event) {
        event.preventDefault();
        this.setState({ acceptOfferModalStatus: FORM_STATUSES.LOADING });

        TenderAuctionCarrierApiService.acceptOffer(this.props.acceptRejectOfferProps.auction.id)
            .then(() => this.setState({ acceptOfferModalStatus: FORM_STATUSES.SUCCESS }, this._onCloseAcceptOfferPopup))
            .then(this.props.acceptRejectOfferProps.onActionPerformed)
            .catch(() => this.setState({ acceptOfferModalStatus: FORM_STATUSES.ERROR }));
    }

    _isRejectDisabled() {
        const { doNotReceiveOffersForLane, endDate } = this.state;
        return doNotReceiveOffersForLane && !endDate;
    }

    _formExcludeFromLaneCheckbox() {
        return <></>;

        //TODO: Uncomment this part of the code when we decide to use lane exclusions again
        /*const { lane } = this.props.acceptRejectOfferProps;
        const { origin, destination } = lane;

        let endDatePicker = <></>;
        if (this.state.doNotReceiveOffersForLane) {
            endDatePicker = (
                <div className="end-date-picker">
                    <CustomDatePicker
                        date={ this.state.endDate }
                        minDate={ moment() }
                        dateFormat={ this._dateFormat }
                        handleChange={ this._handleEndDateChange }
                    />
                </div>
            );
        }

        return (
            <>
                <CustomCheckbox
                    onChange={ this._onDoNotReceiveOffersForLaneCheckboxChange }
                    checked={ !!this.state.doNotReceiveOffersForLane }
                    text={ `I would like to be removed from receiving offers for ${ origin.city }, ${ origin.state } to ${ destination.city }, ${ destination.state } for loads with pickup date before the specified date.` }
                />
                { endDatePicker }
            </>
        );
        */
    }

    _formAcceptRejectActions() {
        const { size, acceptRejectOfferProps: { auction, price } } = this.props;
        const { showAcceptOfferModal, showRejectOfferModal, acceptOfferModalStatus, rejectOfferModalStatus } = this.state;

        let acceptTrigger, rejectTrigger;

        if (size === 'small') {
            acceptTrigger = (
                <div className="action-button-container">
                    <Button
                        type="secondary-alt"
                        link="#popup-accept"
                        className="auctions-list-action accept"
                        leftIcon={ checkIcon }
                        onClick={ this._onOpenAcceptOfferPopup }
                    />
                    <Tooltip direction="top">Accept Offer</Tooltip>
                </div>
            );

            rejectTrigger = (
                <div className="action-button-container reject">
                    <Button
                        type="secondary-alt"
                        link="#popup-reject"
                        className="auctions-list-action reject"
                        leftIcon={ minusIcon }
                        onClick={ this._onOpenRejectOfferPopup }
                    />
                    <Tooltip direction="top">Reject Offer</Tooltip>
                </div>
            );
        } else {
            acceptTrigger = (
                <Button link="#popup-accept" onClick={ this._onOpenAcceptOfferPopup }>
                    Accept
                </Button>
            );

            rejectTrigger = (
                <Button type="tertiary" link="#popup-reject" onClick={ this._onOpenRejectOfferPopup }>
                    Reject
                </Button>
            );
        }

        let acceptPopup, rejectPopup;

        switch (acceptOfferModalStatus) {
            case FORM_STATUSES.LOADING:
                acceptPopup = (
                    <Popup
                        id="popup-accept"
                        size="medium"
                        show={ showAcceptOfferModal }
                        onClose={ this._onCloseAcceptOfferPopup }
                        trigger={ acceptTrigger }
                    >
                        <div className="offer-popup accept-popup">
                            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                                <div>
                                    <h6>Accepting Offer</h6>
                                    <p>This will only take a moment. Please wait...</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            case FORM_STATUSES.ERROR:
                acceptPopup = (
                    <Popup
                        id="popup-accept"
                        size="medium"
                        show={ showAcceptOfferModal }
                        onClose={ this._onCloseAcceptOfferPopup }
                        trigger={ acceptTrigger }
                    >
                        <div className="offer-popup accept-popup">
                            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseAcceptOfferPopup }>
                                <div>
                                    <h6>Offer Acceptance Failure</h6>
                                    <p>Something went wrong. Please refresh the page before trying again.</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            default:
                acceptPopup = (
                    <LoadTermsOfServicePopup
                        auction={ auction }
                        price={ price }
                        showPopup={ showAcceptOfferModal }
                        id="popup-accept" 
                        trigger={ acceptTrigger }
                        onClosePopup={ this._onCloseAcceptOfferPopup }
                        onActionPerformed={ this._onAcceptOffer }
                    />
                );
        }

        switch (rejectOfferModalStatus) {
            case FORM_STATUSES.LOADING:
                rejectPopup = (
                    <Popup
                        id="popup-reject"
                        size="medium"
                        show={ showRejectOfferModal }
                        onClose={ this._onCloseRejectOfferPopup }
                        trigger={ rejectTrigger }
                    >
                        <div className="offer-popup reject-popup">
                            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                                <div>
                                    <h6>Rejecting Offer</h6>
                                    <p>This will only take a moment. Please wait...</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            case FORM_STATUSES.ERROR:
                rejectPopup = (
                    <Popup
                        id="popup-reject"
                        size="medium"
                        show={ showRejectOfferModal }
                        onClose={ this._onCloseRejectOfferPopup }
                        trigger={ rejectTrigger }
                    >
                        <div className="offer-popup reject-popup">
                            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseRejectOfferPopup }>
                                <div>
                                    <h6>Offer Rejection Failure</h6>
                                    <p>Something went wrong. Please refresh the page before trying again.</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            default:
                const lane = auction.load.routingGuideLane.lane;
                const origin = LaneUtils.formatRegion(lane.origin);
                const destination = LaneUtils.formatRegion(lane.destination);

                rejectPopup = (
                    <Popup
                        id="popup-reject"
                        show={ showRejectOfferModal }
                        onClose={ this._onCloseRejectOfferPopup }
                        trigger={ rejectTrigger }
                    >
                        <div className="offer-popup reject-popup">
                            <p className="heading">Reject Offer</p>
                            <p className="description">
                                Are you sure you want to reject a load offer 
                                <span className="highlighted"> #{ auction.load.businessId } ({ LANE_TYPE_PROPERTIES[lane.type].label } Load) </span>
                                on the lane from <span className="highlighted">{ origin }</span> to <span className="highlighted">{ destination } </span> 
                                with the total price of <span className="highlighted">${ NumberUtils.formatWithDecimalNotation(price) }</span>?
                            </p>

                            <div className="buttons-wrapper">
                                <Button type="tertiary" size="small" onClick={ this._onCloseRejectOfferPopup }>
                                    Cancel
                                </Button>

                                <Button
                                    type="danger"
                                    size="small"
                                    disabled={ this._isRejectDisabled() }
                                    onClick={ this._onRejectOffer }
                                >
                                    Reject
                                </Button>
                            </div>
                        </div>
                    </Popup>
                );
        }

        if (size === 'small') {
            return (
                <div className="buttons-wrapper">
                    { acceptPopup }
                    { rejectPopup }
                </div>
            );
        }

        return (
            <div className="buttons-wrapper">
                { rejectPopup }
                { acceptPopup }
            </div>
        );
    }

    _onOpenBookNowPopup(event) {
        event.stopPropagation();
        this.setState({ showBookNowModal: true, bookNowModalStatus: undefined });
        this.props.onLinkDisable();
    }

    _onCloseBookNowPopup(event) {
        if (event) {
            event.preventDefault();
        }

        if (FORM_STATUSES.LOADING !== this.state.bookNowModalStatus) {
            this.setState({ showBookNowModal: false, acceptTermsAndConditions: false });
            this.props.onLinkEnable();
        }
    }

    _onBookNow(event) {
        event.preventDefault();

        this.setState({ bookNowModalStatus: FORM_STATUSES.LOADING });
        
        TenderAuctionCarrierApiService.bookNow(this.props.spotMarketActionsProps.auction.id)
            .then(() => this.setState({ bookNowModalStatus: FORM_STATUSES.SUCCESS }, this._onCloseBookNowPopup))
            .then(this.props.spotMarketActionsProps.onActionPerformed)
            .catch(() => this.setState({ bookNowModalStatus: FORM_STATUSES.ERROR }));
    }

    _onOpenBidPopup(event) {
        event.stopPropagation();
        this.setState({ showBidModal: true, biddingFormModalStatus: undefined });
        this.props.onLinkDisable();
    }

    _onCloseBidPopup(event) {
        if (event) {
            event.preventDefault();
        }

        if (FORM_STATUSES.LOADING !== this.state.biddingFormModalStatus) {
            this.setState(prevState => ({ showBidModal: false, biddingFormVersion: prevState.biddingFormVersion + 1 }));
            this.props.onLinkEnable();
        }
    }

    _onPostBid(price) {
        this.setState({ biddingFormModalStatus: FORM_STATUSES.LOADING });

        TenderAuctionCarrierApiService.postBid(this.props.spotMarketActionsProps.auction.id, price)
            .then(() => this.setState({ biddingFormModalStatus: FORM_STATUSES.SUCCESS }, this._onCloseBidPopup))
            .then(this.props.spotMarketActionsProps.onActionPerformed)
            .catch(() => this.setState({ biddingFormModalStatus: FORM_STATUSES.ERROR }));
    }

    _formSpotMarketActions() {
        const { auction, negotiatedRate, currentBid, carrierBid } = this.props.spotMarketActionsProps;

        const biddingFormTrigger = (
            <div className="action-button-container">
                <Button
                    type="secondary-alt"
                    link="#popup-bidding-form"
                    className="auctions-list-action"
                    leftIcon={ gavelIcon }
                    onClick={ this._onOpenBidPopup }
                />
                <Tooltip direction="top">Place a Bid</Tooltip>
            </div>
        );

        let biddingFormPopup;
        switch (this.state.biddingFormModalStatus) {
            case FORM_STATUSES.LOADING:
                biddingFormPopup = (
                    <Popup
                        id="popup-bidding-form"
                        size="medium"
                        show={ this.state.showBidModal }
                        onClose={ this._onCloseBidPopup }
                        trigger={ biddingFormTrigger }
                    >
                        <div className="popup-bidding-form offer-popup">
                            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                                <div>
                                    <h6>Placing a Bid</h6>
                                    <p>This will only take a moment. Please wait...</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            case FORM_STATUSES.ERROR:
                biddingFormPopup = (
                    <Popup
                        id="popup-bidding-form"
                        size="medium"
                        show={ this.state.showBidModal }
                        onClose={ this._onCloseBidPopup }
                        trigger={ biddingFormTrigger }
                    >
                        <div className="popup-bidding-form offer-popup">
                            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseBidPopup }>
                                <div>
                                    <h6>Placing a Bid Failed</h6>
                                    <p>Something went wrong. Please refresh the page before trying again.</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            default:
                biddingFormPopup = (
                    <Popup
                        id="popup-bidding-form"
                        size="medium"
                        show={ this.state.showBidModal }
                        onClose={ this._onCloseBidPopup }
                        trigger={ biddingFormTrigger }
                    >
                        <div className="popup-bidding-form offer-popup">
                            { this._formOfferPopupHeader(auction) }
                            <CurrentBid
                                auction={ auction }
                                lowestBid={ currentBid }
                                carrierBestBid={ carrierBid }
                                negotiatedRate={ negotiatedRate }
                                enableBookNow={ false }
                                popupActions={ true }
                                version={ this.state.biddingFormVersion }
                                onPostBid={ this._onPostBid }
                                onCancel={ this._onCloseBidPopup }
                            />
                        </div>
                    </Popup>
                );
        }

        const bookNowDisabled = !auction.bookNowPrice || (!!negotiatedRate && negotiatedRate < auction.bookNowPrice) || (!!currentBid && currentBid.price <= auction.bookNowPrice);
        let bookNowTrigger = (
            <Button
                type="secondary-alt"
                link="#popup-book-now"
                className="auctions-list-action"
                leftIcon={ dollarIcon }
                onClick={ this._onOpenBookNowPopup }
                disabled={ bookNowDisabled }
            />
        );

        if (!bookNowDisabled) {
            bookNowTrigger = (
                <div className="action-button-container">
                    { bookNowTrigger }
                    <Tooltip direction="top">Book now</Tooltip>
                </div>
            );
        }

        let bookNowPopup;
        switch (this.state.bookNowModalStatus) {
            case FORM_STATUSES.LOADING:
                bookNowPopup = (
                    <Popup
                        id="popup-book-now"
                        size="medium"
                        show={ this.state.showBookNowModal }
                        onClose={ this._onCloseBookNowPopup }
                        trigger={ bookNowTrigger }
                    >
                        <div className="offer-popup accept-popup">
                            <FormStatusModal status={ FORM_STATUSES.LOADING }>
                                <div>
                                    <h6>Book Now In Progress</h6>
                                    <p>This will only take a moment. Please wait...</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            case FORM_STATUSES.ERROR:
                bookNowPopup = (
                    <Popup
                        id="popup-book-now"
                        size="medium"
                        show={ this.state.showBookNowModal }
                        onClose={ this._onCloseBookNowPopup }
                        trigger={ bookNowTrigger }
                    >
                        <div className="offer-popup accept-popup">
                            <FormStatusModal status={ FORM_STATUSES.ERROR } onContinue={ this._onCloseBookNowPopup }>
                                <div>
                                    <h6>Book Now Failed</h6>
                                    <p>Something went wrong. Please refresh the page before trying again.</p>
                                </div>
                            </FormStatusModal>
                        </div>
                    </Popup>
                );
                break;
            default:
                bookNowPopup = (
                    <LoadTermsOfServicePopup
                        auction={ auction }
                        price={ auction.bookNowPrice }
                        showPopup={ this.state.showBookNowModal }
                        id="popup-book-now"
                        title="Booking Now Load"
                        description={
                            <>
                                If you book this load now for <b>${ NumberUtils.formatWithDecimalNotation(auction.bookNowPrice) }</b> you will secure it and you will become an awarded carrier.
                            </>
                        }
                        trigger={ bookNowTrigger }
                        onClosePopup={ this._onCloseBookNowPopup }
                        onActionPerformed={ this._onBookNow }
                    />
                );
        }

        return (
            <div className="buttons-wrapper">
                { biddingFormPopup }
                { bookNowPopup }
            </div>
        );
    }

    render() {
        const { type } = this.props;

        let actions;
        switch (type) {
            case 'cancel':
                const { onCancelOffer, availableFalloffReasons, disabled } = this.props.cancelOfferProps;

                actions = (
                    <CancelLoadPopup
                        onCancelOffer={ onCancelOffer }
                        falloffReasons={ availableFalloffReasons }
                        disabled={ disabled }
                    />
                );
                break;
            case 'accept/reject':
                actions = this._formAcceptRejectActions();
                break;
            case 'spot-market':
                actions = this._formSpotMarketActions();
                break;
            default:
                actions = <></>;
        }

        return (
            <div className="carrier-actions">
                { actions }
            </div>
        );
    }
}
