import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil as edit } from '@fortawesome/pro-solid-svg-icons';

import { MAX_PRICE } from 'common/constants';
import Button from 'component/Button';
import Popup from 'component/Popup';
import Tooltip from 'component/Tooltip';
import CustomCheckbox from 'component/form/CustomCheckbox';
import CustomNumberInput from 'component/form/CustomNumberInput';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './EditBookNowPricePopup.scss';

export default class EditBookNowPricePopup extends Component {
    static propTypes = {
        onSubmitPrice: PropTypes.func,
        initialPrice: PropTypes.number,
        lanePrice: PropTypes.number,
        distance: PropTypes.number
    }

    static defaultProps = {
        onSubmitPrice: () => { /* */ },
        initialPrice: null,
        lanePrice: null,
        distance: null
    }

    state = {
        showPopup: false,
        newPrice: null,
        perMilePrice: null,
        inheritLanePrice: false,
        invalidInput: true,
        resetBookNowInput: false,
        showConfirmationDialog: false
    }

    constructor(props) {
        super(props);
        this._onCheckboxChange = this._onCheckboxChange.bind(this);
        this._onBookNowPriceChange = this._onBookNowPriceChange.bind(this);
        this._onCloseEditPopup = this._onCloseEditPopup.bind(this);
        this._onBookNowPriceClear = this._onBookNowPriceClear.bind(this);
        this._onOpenEditPopup = this._onOpenEditPopup.bind(this);
        this._onSubmitPrice = this._onSubmitPrice.bind(this);
        this._onToggleConfirmationDialog = this._onToggleConfirmationDialog.bind(this);
    }

    componentDidMount() {
        const { initialPrice, distance, lanePrice } = this.props;

        if (initialPrice) {
            this.setState({
                perMilePrice: PriceUtils.calculatePerMilePrice(initialPrice, distance),
                inheritLanePrice: initialPrice === lanePrice,
                newPrice: initialPrice
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { initialPrice, lanePrice, distance } = this.props;

        if (prevProps.initialPrice !== initialPrice) {
            this.setState({ perMilePrice: PriceUtils.calculatePerMilePrice(initialPrice, distance) });
        }

        if (prevState.showPopup !== this.state.showPopup) {
            this.setState({
                invalidInput: true,
                inheritLanePrice: !!(initialPrice && initialPrice === lanePrice),
                newPrice: initialPrice
            });
        }
    }

    _onBookNowPriceChange(value) {
        const { initialPrice, distance } = this.props;

        const invalidInput = value === initialPrice 
            || ((!!value || value === 0) && value <= 0)    
            || value >= MAX_PRICE
            || (!value && !initialPrice);
        const perMilePrice = value ? PriceUtils.calculatePerMilePrice(value, distance) : null;

        this.setState({
            newPrice: value,
            perMilePrice,
            invalidInput
        });
    }

    _onBookNowPriceClear() {
        this._onBookNowPriceChange('');
    }

    _onCheckboxChange() {
        this.setState(prevState => {
            const { initialPrice, lanePrice } = this.props;
            const { newPrice } = prevState;

            let invalidInput;
            if (!prevState.inheritLanePrice) {
                invalidInput = lanePrice === initialPrice;
            } else {
                invalidInput = newPrice === initialPrice || (newPrice !== null && newPrice <= 0) || newPrice >= MAX_PRICE;
            }

            return {
                inheritLanePrice: !prevState.inheritLanePrice,
                invalidInput
            };
        });
    }

    _onCloseEditPopup() {
        const { initialPrice, distance } = this.props;

        this.setState({
            showPopup: false,
            newPrice: null,
            perMilePrice: initialPrice ? PriceUtils.calculatePerMilePrice(initialPrice, distance) : null,
            inheritLanePrice: false,
            resetBookNowInput: true,
            showConfirmationDialog: false
        });
    }

    _onOpenEditPopup(event) {
        event.stopPropagation();
        this.setState({ showPopup: true, resetBookNowInput: false });
    }

    _onToggleConfirmationDialog() {
        this.setState(prevState => {
            let { invalidInput, newPrice, inheritLanePrice, perMilePrice } = prevState;

            if (prevState.showConfirmationDialog) {
                invalidInput = true;
                newPrice = this.props.initialPrice;
                inheritLanePrice = this.props.lanePrice && this.props.initialPrice === this.props.lanePrice;
                perMilePrice = newPrice ? PriceUtils.calculatePerMilePrice(newPrice, this.props.distance) : null;
            }

            return {
                showConfirmationDialog: !prevState.showConfirmationDialog,
                invalidInput,
                newPrice,
                inheritLanePrice,
                perMilePrice
            };
        });
    }

    _onSubmitPrice() {
        const { inheritLanePrice, newPrice } = this.state;
        const { lanePrice } = this.props;

        const price = inheritLanePrice ? lanePrice : newPrice;
        this.props.onSubmitPrice(price);
        this.setState({
            showPopup: false,
            inheritLanePrice: false,
            showConfirmationDialog: false
        });
    }

    render() {
        const { initialPrice, lanePrice, distance } = this.props;
        const { perMilePrice, invalidInput, inheritLanePrice, showPopup, resetBookNowInput } = this.state;

        let currentBookNowPrice;

        if (initialPrice) {
            currentBookNowPrice = <span>The book now price for this load is currently <b>${ NumberUtils.formatWithDecimalNotation(initialPrice) }</b>.&nbsp;</span>
        } else {
            currentBookNowPrice = <span>The book now price for this load is currently not set.&nbsp;</span>
        }

        const displayedPerMilePrice = perMilePrice ? `$${ NumberUtils.formatWithDecimalNotation(perMilePrice) }` : '-';

        let inheritLanePriceCheckbox, currentLanePrice;
        if (lanePrice) {
            const perMileLanePrice = PriceUtils.formatPerMilePrice(lanePrice, distance);

            inheritLanePriceCheckbox = (
                <CustomCheckbox
                    onChange={ this._onCheckboxChange }
                    checked={ this.state.inheritLanePrice }
                    text={ <>Use default lane price <b>${ NumberUtils.formatWithDecimalNotation(lanePrice) }</b> (${ perMileLanePrice })</> }>
                </CustomCheckbox>
            );

            currentLanePrice = <span>The default lane price is <b>${ NumberUtils.formatWithDecimalNotation(lanePrice) }</b>.&nbsp;</span>
        }

        let body, size;
        if (this.state.showConfirmationDialog) {
            const { newPrice } = this.state;
            size = 'small';

            const price = inheritLanePrice ? lanePrice : newPrice;

            let description;
            if (price !== '') {
                description = (
                    <>
                        Please confirm that you want to set the book now price for this load to <b>${ NumberUtils.formatWithDecimalNotation(price) }</b> (${ PriceUtils.formatPerMilePrice(price, distance) } per mile).
                    </>
                );
            } else {
                description = "Please confirm that you want to remove the book now price from this load.";
            }

            body = (
                <div className="edit-book-now-popup">
                    <p className="heading">
                        Edit Book Now Price
                    </p>
                    <p className="description">
                        { description }
                    </p>
                    <div className="buttons-wrapper">
                        <Button
                            type="tertiary"
                            size="small"
                            onClick={ this._onToggleConfirmationDialog }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            disabled={ !!invalidInput }
                            onClick={ this._onSubmitPrice }
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            );
        } else {
            size = 'medium';
            body = (
                <div className="edit-book-now-popup">
                    <h6 className="heading">
                        Edit Book Now Price
                    </h6>
                    <p className="description">
                        { currentBookNowPrice }
                        { currentLanePrice }
                        All book now prices are shown and counted as total prices. They can be manually edited, cleared from the load, or restored to the default lane price if one is set.
                    </p>
                    <p className="prompt">
                        Price <span className="total-price-label">(Total Price)</span>
                    </p>
                    <CustomNumberInput
                        allowClear={ true }
                        disabled={ inheritLanePrice }
                        value={ typeof this.state.newPrice === 'number' ? this.state.newPrice.toString() : this.state.newPrice }
                        placeholder={ "Enter your new price" }
                        onChange={ this._onBookNowPriceChange }
                        onClear={ this._onBookNowPriceClear }
                        prefix="$"
                        className="book-now-price"
                        shouldValidate={ false }
                        isValid={ !invalidInput }
                        initialValue={ initialPrice ? initialPrice.toFixed(2) : null }
                        resetValue={ resetBookNowInput }
                    />
                    <small className="per-mile-price-label">
                        Per Mile Price: { displayedPerMilePrice }
                    </small>
                    { inheritLanePriceCheckbox }
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onCloseEditPopup }>
                            Discard
                        </Button>
                        <Button
                            type="primary"
                            disabled={ !!invalidInput }
                            onClick={ this._onToggleConfirmationDialog }
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="edit-book-now-price-popup-container">
                <Popup
                    id="popup-edit-book-now"
                    size={ size }
                    show={ showPopup }
                    onClose={ this._onCloseEditPopup }
                    trigger={
                        <a href="#popup-edit-book-now" onClick={ this._onOpenEditPopup }>
                            <FontAwesomeIcon icon={ edit } className="edit-icon" />
                            <Tooltip direction="top">
                                Edit Book Now Price
                            </Tooltip>
                        </a>
                    }
                >
                    { body }
                </Popup>
            </div>
        );
    }
}
