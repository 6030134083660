import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import CustomLoader from './form/CustomLoader';

import './InfiniteScroll.scss';

export default class InfiniteScroll extends Component {
    static propTypes = {
        fetchMoreData: PropTypes.func,
        hasMore: PropTypes.bool,
        isLoading: PropTypes.bool,
        className: PropTypes.string,
        returnToTop: PropTypes.bool,
        endMessage: PropTypes.string
    };

    static defaultProps = {
        fetchMoreData: () => { /* */ },
        hasMore: false,
        isLoading: false,
        className: '',
        returnToTop: false,
        endMessage: ''
    };

    constructor(props) {
        super(props);

        this._infiniteScroll = createRef();
        this._handleScroll = this._handleScroll.bind(this);
    }

    componentDidUpdate() {
        if (this.props.returnToTop) {
           this._infiniteScroll.current.scrollTop = 0; 
        }
    }

    _handleScroll() {
        if (this.props.hasMore && !this.props.isLoading && !this.props.returnToTop) {
            const scrollElement = this._infiniteScroll.current;
            const scrollHeight = scrollElement.scrollHeight;
            const divHeight = scrollElement.clientHeight;
            const scrollerEndPoint = scrollHeight - divHeight;
            const divScrollerTop = scrollElement.scrollTop;
    
            if (divScrollerTop === scrollerEndPoint) {
                this.props.fetchMoreData();
            }
        }
    }

    render() {
        return (
            <div id="scrollLayout" className={ `infinite-scroll ${ this.props.className }`} ref={ this._infiniteScroll } onScroll={ this._handleScroll }>
                { this.props.children }
                { this.props.isLoading && 
                    <div className="loader">
                        <CustomLoader />
                    </div>
                }
                { !this.props.isLoading && !this.props.hasMore && <small className="end-message">{ this.props.endMessage }</small>}
            </div>
        );
    }
}
