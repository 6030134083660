import { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import CanAccess from 'component/CanAccess';
import { AccessCase, CanAccessSwitch } from 'component/CanAccessSwitch';
import RFPTosProtectedPage from 'component/rfp/tos/RFPTosProtectedPage';
import ApplicationDetails from 'page/ApplicationDetails';
import Settings from 'page/Settings';
import AuctionsList from 'page/AuctionsList';
import CarrierLaneDetails from 'page/CarrierLaneDetails';
import CarrierLoadDetails from 'page/CarrierLoadDetails';
import CarrierProfile from 'page/CarrierProfile';
import HelpCenter from 'page/HelpCenter';
import LaneDetails from 'page/LaneDetails';
import LoadDetails from 'page/LoadDetails';
import NotificationCenter from 'page/NotificationCenter';
import NotificationList from 'page/NotificationList';
import RFPDetails from 'page/RFPDetails';
import RFPLaneDetails from 'page/RFPLaneDetails';
import RFPTermsOfService from 'page/RFPTermsOfService';
import RoutingGuideList from 'page/RoutingGuideList';
import UserProfile from 'page/UserProfile';
import Unknown from 'page/Unknown';
import UsersOverview from 'page/UsersOverview';
import WithNavigationPage from 'page/WithNavigationPage';

export default class Router extends Component {
    static propTypes = {
        account: PropTypes.object.isRequired
    };

    render() {
        const account = this.props.account;

        if (!account) {
            return (<></>);
        }

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        { /* Redirect root to the appropriate destination, based on user permissions */ }
                        <CanAccessSwitch>
                            <AccessCase action={ ['load-tendering:read', 'offered-loads:read'] }>
                                <Redirect to="/load-board" />
                            </AccessCase>

                            <AccessCase action={ ['rfp:read', 'rfp:read:all'] }>
                                <Redirect to="/rfp/list" />
                            </AccessCase>

                            <AccessCase>
                                <Unknown />
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <Route path="/load-board">
                        <CanAccess
                            action={ ['load-tendering:read', 'offered-loads:read'] }
                            yes={
                                <WithNavigationPage>
                                    <AuctionsList screen='load-board' key='load-board' account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/booked-loads">
                        <CanAccess
                            action={ ['booked-loads:read', 'carrier-booked-loads:read'] }
                            yes={
                                <WithNavigationPage>
                                    <AuctionsList screen='booked-loads' key='booked-loads' account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/load-history">
                        <CanAccess
                            action={ ['load-history:read', 'tender-history:read'] }
                            yes={
                                <WithNavigationPage>
                                    <AuctionsList screen='load-history' key='load-history' account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/lane/:laneId">
                        <CanAccessSwitch>
                            <AccessCase action="routing-guide:read">
                                <WithNavigationPage>
                                    <LaneDetails account={ account } />
                                </WithNavigationPage>
                            </AccessCase>

                            <AccessCase action="rfp:read">
                                <WithNavigationPage>
                                    <CarrierLaneDetails account={ account } />
                                </WithNavigationPage>
                            </AccessCase>

                            <AccessCase>
                                <Unknown />
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <Route exact path="/loads-search">
                        <CanAccess
                            action={ ['load:list', 'carrier-load:list'] }
                            yes={
                                <WithNavigationPage>
                                    <AuctionsList screen='loads-search' key='loads-search' account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route
                        exact
                        path="/load/:loadId"
                        render={ props => (
                            <CanAccess
                                action="details-view:read"
                                yes={
                                    <WithNavigationPage>
                                        <LoadDetails account={ account } />
                                    </WithNavigationPage>
                                }
                                no={
                                    <CanAccess
                                        action="carrier-details-view:read"
                                        yes={ <Redirect to={ `/load/${ props.match.params.loadId }/auction` } /> }
                                        no={ <Unknown /> }
                                    />
                                }
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/load/:loadId/auction"
                        render={ props => (
                            <CanAccess
                                action="details-view:read"
                                yes={ <Redirect to={ `/load/${ props.match.params.loadId }` } /> }
                                no={
                                    <CanAccess
                                        action="carrier-details-view:read"
                                        yes={
                                            <WithNavigationPage>
                                                <CarrierLoadDetails account={ account } />
                                            </WithNavigationPage>
                                        }
                                        no={ <Unknown /> }
                                    />
                                }
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/load/:loadId/auction/:auctionId"
                        render={ props => (
                            <CanAccess
                                action="details-view:read"
                                yes={ <Redirect to={ `/load/${ props.match.params.loadId }` } /> }
                                no={
                                    <CanAccess
                                        action="carrier-details-view:read"
                                        yes={
                                            <WithNavigationPage>
                                                <CarrierLoadDetails account={ account } />
                                            </WithNavigationPage>
                                        }
                                        no={ <Unknown /> }
                                    />
                                }
                            />
                        )}
                    />

                    <Route exact path="/rfp/terms-of-service">
                        <WithNavigationPage>
                            <RFPTermsOfService />
                        </WithNavigationPage>
                    </Route>

                    <Route path="/rfp/list">
                        <CanAccess
                            action={ ['rfp:read', 'rfp:read:all', 'routing-guide:read'] }
                            yes={
                                <RFPTosProtectedPage>
                                    <WithNavigationPage>
                                        <RoutingGuideList account={ account } />
                                    </WithNavigationPage>
                                </RFPTosProtectedPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/rfp/:id/lanes/:laneId">
                        <CanAccess
                            action="rfp:read:all"
                            yes={
                                <RFPTosProtectedPage>
                                    <WithNavigationPage>
                                        <RFPLaneDetails account={ account } />
                                    </WithNavigationPage>
                                </RFPTosProtectedPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route path="/rfp/:id/notification-center">
                        <CanAccess
                            action="notification-subscriptions:read"
                            yes={
                                <WithNavigationPage>
                                    <NotificationCenter account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route path="/rfp/:id">
                        <CanAccess
                            action={ ['rfp:read', 'rfp:read:all', 'routing-guide:read'] }
                            yes={
                                <RFPTosProtectedPage>
                                    <WithNavigationPage>
                                        <RFPDetails account={ account } />
                                    </WithNavigationPage>
                                </RFPTosProtectedPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route path="/users">
                        <CanAccess
                            action="user:read:all"
                            yes={
                                <WithNavigationPage>
                                    <UsersOverview account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/user">
                        <Redirect to={ `/user/${ encodeURIComponent(account.internalId) }` } />
                    </Route>

                    <Route path="/user/:id">
                        <CanAccess
                            action="user:read"
                            yes={
                                <WithNavigationPage>
                                    <UserProfile account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/carrier">
                        <Redirect to={ `/carrier/${ encodeURIComponent(account.carrierId) }` } />
                    </Route>

                    <Route path="/carrier/:id">
                        <CanAccess
                            action="carrier:read"
                            yes={
                                <WithNavigationPage>
                                    <CarrierProfile account={ account } />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/notifications">
                        <CanAccess
                            action="notifications:read"
                            yes={
                                <WithNavigationPage>
                                    <NotificationList key='notifications' />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route exact path="/help-center">
                        <CanAccess
                            action="help-center:read"
                            yes={
                                <WithNavigationPage>
                                    <HelpCenter />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route path="/application-details">
                        <WithNavigationPage>
                            <ApplicationDetails />
                        </WithNavigationPage>
                    </Route>

                    <Route exact path="/settings">
                        <CanAccess
                            action="sm-carriers-sync-config:read"
                            yes={
                                <WithNavigationPage>
                                    <Settings />
                                </WithNavigationPage>
                            }
                            no={ <Unknown /> }
                        />
                    </Route>

                    <Route>
                        <Unknown />
                    </Route>

                </Switch>
            </BrowserRouter>
        );
    }
}
