import { createRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHeartBroken as emptyLoadTendering,
    faThumbsUp as emptyActionRequired,
    faTrailer as emptyBookedLoads,
    faHistory as emptyLoadHistory,
    faSearch as emptySearch,
    faSpinner as spinnerIcon
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight as arrow } from '@fortawesome/pro-regular-svg-icons';

import moment from 'moment';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, SCREENS } from 'common/constants';
import Button from 'component/Button';
import CanAccess from 'component/CanAccess';
import AuctionCard from 'component/card/AuctionCard';
import TenderingFilters from 'component/filter/TenderingFilters';
import ContactUsPopover from 'component/load/ContactUsPopover';
import AuctionTableHeaderRow from 'component/load/AuctionTableHeaderRow';
import DownloadStatisticsPopup from 'component/load/DownloadStatisticsPopup';
import LoadRequirementsPopover from 'component/load/LoadRequirementsPopover';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Pagination from 'component/navigation/Pagination';
import Tab from 'component/navigation/Tab';
import PageHeader from 'component/PageHeader';
import Table from 'component/table/custom/Table';
import TableEmptyState from 'component/table/TableEmptyState';
import WSComponent from 'component/WSComponent';
import WebSocketService from 'service/WebSocketService';
import TenderAuctionApiService from 'service/api/TenderAuctionApiService';
import TenderAuctionBrokerApiService from 'service/api/TenderAuctionBrokerApiService';
import RoutingGuideNotificationApiService from 'service/api/RoutingGuideNotificationApiService';
import ObjectUtils from 'utils/ObjectUtils';
import RoutingGuideUtils from 'utils/RoutingGuideUtils';
import StringUtils from 'utils/StringUtils';

import './AuctionsList.scss';

const TABS = {
    AVAILABLE_LOADS: 'available-loads',
    CURRENT_BIDS: 'current-bids',
    LOAD_OFFERS: 'load-offers',
    ACTION_REQUIRED: 'action-required'
};

export default class AuctionsList extends WSComponent {
    static propTypes = {
        ws: PropTypes.instanceOf(WebSocketService),
        screen: PropTypes.oneOf([...Object.values(SCREENS)]),
        account: PropTypes.object
    };

    static defaultProps = {
        ws: WebSocketService.instance(),
        screen: SCREENS.LOAD_BOARD,
        account: null
    };

    constructor(props) {
        super(props);

        if (this.props.account?.carrierId) {
            this.subscriptions = [
                {
                    topic: '/topic/carriers/auctions/spot-market/updates',
                    handler: () => this._handleAuctionUpdate()
                },
                {
                    topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/updates`,
                    handler: () => this._handleAuctionUpdate()
                },
                {
                    topic: `/topic/carriers/${ this.props.account.carrierId }/auctions/offers`,
                    handler: () => this._handleAuctionUpdate()
                },
                {
                    topic: '/topic/carriers/auctions/updates',
                    handler: () => this._handleAuctionUpdate()
                },

            ];
        } else {
            this.subscriptions = [
                {
                    topic: '/topic/auctions/updates',
                    handler: () => this._handleAuctionUpdate()
                }
            ];

            if (SCREENS.LOAD_BOARD === this.props.screen || SCREENS.LOAD_SEARCH === this.props.screen) {
                this.subscriptions = this.subscriptions.concat(
                    {
                        topic: '/topic/auctions/created',
                        handler: () => this._handleAuctionUpdate()
                    }
                );
            }
        }

        this.state = {
            generalContactInformation: {},
            auctions: [],
            activeTab: this._getValidTab(),
            page: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            available: 0,
            time: Date.now(),
            filter: {},
            showFilters: false,
            loading: true,
            version: 0
        };

        this._fetchData = this._fetchData.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._onAuctionChanged = this._onAuctionChanged.bind(this);
        this._updateShowFilters = this._updateShowFilters.bind(this);
    }

    /**
     * The interval id for rerendering the component.
     *
     * @type { Number }
     * @private
     */
    _interval;

    _filterTriggerRef = createRef();

    componentDidMount() {
        super.componentDidMount();

        this._fetchGeneralContactInformation();

        const searchParams = new URLSearchParams(this.props.location.search);
        this._setFilterFromSearchParams(searchParams, () => this._fetchData(true));
        this._interval = setInterval(() => {
            this.setState({
                time: Date.now()
            });
        }, 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const activeTab = this._getValidTab();
            this.setState({
                activeTab,
                filter: {},
                page: DEFAULT_PAGE_NUMBER,
                pageSize: DEFAULT_PAGE_SIZE,
                loading: true
            }, () => this._fetchData(true));
            return;
        }

        const searchParams = new URLSearchParams(this.props.location.search);
        const filter = AuctionsList._getFilterFromSearchParams(searchParams);
        if (JSON.stringify(filter) !== JSON.stringify(this.state.filter)) {
            this.setState(
                { filter },
                () => this._fetchData(true)
            );
        } else if (this.props.screen !== prevProps.screen || this.state.page !== prevState.page || this.state.pageSize !== prevState.pageSize) {
            this._fetchData(true);
        }
    }

    componentWillUnmount() {
        clearInterval(this._interval);
        super.componentWillUnmount();
    }

    _fetchGeneralContactInformation() {
        RoutingGuideNotificationApiService.getGeneralContactInformation()
            .then(generalContactInformation => this.setState({ generalContactInformation }))
            .catch(error => console.error('An error occurred while fetching general contact information.', error));
    }

    _getValidTab() {
        const activeTab = this.props.location.pathname.split('/').pop();

        if (this.props.account.carrierId && TABS.ACTION_REQUIRED === activeTab) {
            return TABS.AVAILABLE_LOADS;
        }
        
        for (const tab in TABS) {
            if (TABS[tab] === activeTab) {
                return activeTab;
            }
        }

        return TABS.AVAILABLE_LOADS;
    }

    _handleAuctionUpdate() {
        this.setState(prevState => ({ version: prevState.version + 1 }));
        this._fetchData();
    }

    static _getFilterFromSearchParams(searchParams) {
        return {
            routing_guide: searchParams.get("routing_guide"),
            origin: searchParams.get("origin"),
            destination: searchParams.get("destination"),
            pickup_date_from: searchParams.get("pickup_date_from") ? moment(searchParams.get("pickup_date_from")) : null,
            pickup_date_to: searchParams.get("pickup_date_to") ? moment(searchParams.get("pickup_date_to")) : null,
            delivery_date_from: searchParams.get("delivery_date_from") ? moment(searchParams.get("delivery_date_from")) : null,
            delivery_date_to: searchParams.get("delivery_date_to") ? moment(searchParams.get("delivery_date_to")) : null,
            cargo_type: searchParams.get("cargo_type"),
            auction_phase: searchParams.get("auction_phase"),
            tender_status: searchParams.get("tender_status"),
            sort: searchParams.get("sort"),
            search: searchParams.get("search")
        };
    }

    _setFilterFromSearchParams(searchParams, callback, dependingOnFilter = true) {
        const oldFilter = this.state.filter;
        this.setState({
                filter: AuctionsList._getFilterFromSearchParams(searchParams)
        }, () => {
            if (JSON.stringify(this.state.filter) !== JSON.stringify(oldFilter) || !dependingOnFilter) {
                callback();
            }
        });
    }

    async _fetchData(showLoadingSpinner = false) {
        if (showLoadingSpinner) {
            this.setState({ loading: true });
        }

        let pickupDateStart;
        if (this.state.filter.pickup_date_from) {
            pickupDateStart = new Date(moment(this.state.filter.pickup_date_from).startOf('day'));
        }

        let pickupDateEnd;
        if (this.state.filter.pickup_date_to) {
            pickupDateEnd = new Date(moment(this.state.filter.pickup_date_to).endOf('day'));
        }

        let deliveryDateStart;
        if (this.state.filter.delivery_date_from) {
            deliveryDateStart = new Date(moment(this.state.filter.delivery_date_from).startOf('day'));
        }

        let deliveryDateEnd;
        if (this.state.filter.delivery_date_to) {
            deliveryDateEnd = new Date(moment(this.state.filter.delivery_date_to).endOf('day'));
        }

        const loadingTab = this.state.activeTab;
        let api;

        switch (this.props.screen) {
            case SCREENS.LOAD_BOARD:
                switch (this.state.activeTab) {
                    case TABS.AVAILABLE_LOADS:
                        api = TenderAuctionApiService.availableLoads;
                        break;
                    case TABS.CURRENT_BIDS:
                        api = TenderAuctionApiService.currentBids;
                        break;
                    case TABS.LOAD_OFFERS:
                        api = TenderAuctionApiService.loadOffers;
                        break;
                    case TABS.ACTION_REQUIRED:
                        api = TenderAuctionApiService.actionRequired;
                        break;
                    default:
                        // This should never happen
                        console.error(`Unrecongized tab ${ this.state.activeTab }`);
                }
                break;
            case SCREENS.BOOKED_LOADS:
                api = TenderAuctionApiService.booked;
                break;
            case SCREENS.LOAD_HISTORY:
                api = TenderAuctionApiService.history;
                break;
            case SCREENS.LOAD_SEARCH:
                api = TenderAuctionApiService.search;
                break;
            default:
                // This should never happen
                console.error(`Unrecongized screen ${ this.props.screen }`);
        }

        const { data: tenderAuctions, available } = await api({
            pageNumber: this.state.page,
            pageSize: this.state.pageSize,
            sort: this.state.filter.sort ? [this.state.filter.sort, ""] : null,
            tenderStatuses: this.state.filter.tender_status ? [this.state.filter.tender_status] : [],
            routingGuideName: this.state.filter.routing_guide,
            originCity: this.state.filter.origin ? this.state.filter.origin.split(", ")[0] : null,
            originState: this.state.filter.origin ? this.state.filter.origin.split(", ")[1] : null,
            destinationCity: this.state.filter.destination ? this.state.filter.destination.split(", ")[0] : null,
            destinationState: this.state.filter.destination ? this.state.filter.destination.split(", ")[1] : null,
            pickupDateStart,
            pickupDateEnd,
            deliveryDateStart,
            deliveryDateEnd,
            laneType: this.state.filter.cargo_type,
            phase: this.state.filter.auction_phase,
            search: this.state.filter.search
        });

        const auctionPromises = await tenderAuctions.map(auction => {
            return TenderAuctionBrokerApiService.enrichTenderAuctionWithBids(auction);
        });
        const auctions = await Promise.all(auctionPromises);

        if (loadingTab === this.state.activeTab) {
            this.setState({
                auctions,
                available,
                time: Date.now(),
                loading: false
            }, this._updateShowFilters);
        } 
    }

    _onAuctionChanged(auction) {
        this.setState(prevState => {
            let auctions = prevState.auctions;
            const index = auctions.findIndex(a => a.id === auction.id);

            if (index !== -1) {
                auctions[index] = auction;
                return { auctions };
            }
        });
    }

    _onFilterChange(filter) {
        filter = ObjectUtils.removeEmptyKeys(filter);

        for (const key of Object.keys(filter)) {
            if (moment.isMoment(filter[key])) {
                filter[key] = filter[key].format();
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + new URLSearchParams(filter).toString()
        });

        this.setState({ page: 1 });
    }

    _getScreenInfo() {
        const isCarrier = this.props.account.carrierId;

        let title, tooltipText;

        switch (this.props.screen) {
            case SCREENS.LOAD_BOARD:
                title = 'Load Board';
                if (isCarrier) {
                    tooltipText = 'This page represents a list of all loads that are\ncurrently offered to you and loads that are published\non Spot Market and available for you to bid on.';
                } else {
                    tooltipText = 'Load Board screen shows all loads which are not yet\naccepted or booked by the carriers in this application.';
                }
                break;
            case SCREENS.BOOKED_LOADS:
                title = 'Booked Loads';
                if (isCarrier) {
                    tooltipText = 'A load will appear on this page when you are\ndeclared as a winner or you accept or book a load offer.';
                } else {
                    tooltipText = 'A load will appear on this page when a carrier is declared\nas a winner or they accept or book a load offer.';
                }
                break;
            case SCREENS.LOAD_HISTORY:
                title = 'Load History';
                if (isCarrier) {
                    tooltipText = 'This page shows a list of your completed loads.';
                } else {
                    tooltipText = `A load will appear in Load History once 24 hours\nhas passed after it's pickup date and time.`;
                }
                break;
            case SCREENS.LOAD_SEARCH:
                const search = this.state.filter.search;
                const numberOfItems = this.state.available;
                title = <>Search results for "{ search }" <span className="page-title-description">({ numberOfItems } { StringUtils.pluralize(numberOfItems, 'item') })</span></>;
                break;
            default:
                // Nothing to do here
        }

        return [title, tooltipText];
    }

    _updateShowFilters() {
        let showFilters;

        if (SCREENS.LOAD_SEARCH === this.props.screen) {
            showFilters = false;
        } else {
            showFilters = (this.state.auctions || []).length > 0 || !ObjectUtils.isBlankOrEmpty(this.state.filter);
        }

        this.setState({ showFilters });
    }

    _formContent() {
        const { auctions, activeTab, loading, filter, showFilters } = this.state;
        const { screen, account } = this.props;

        const isCarrier = !!account.carrierId;
        const isLoadBoardScreen = SCREENS.LOAD_BOARD === screen;
        const isBookedLoadsScreen = SCREENS.BOOKED_LOADS === screen;
        const isLoadHistoryScreen = SCREENS.LOAD_HISTORY === screen;
        const isSearchScreen = SCREENS.LOAD_SEARCH === screen;

        let filterButton, tenderingFilters;
        if (showFilters) {
            filterButton = <div ref={ this._filterTriggerRef } />;
            tenderingFilters = (
                <TenderingFilters
                    triggerRef={ this._filterTriggerRef }
                    showStatusFilter={ isBookedLoadsScreen || (isLoadHistoryScreen && !isCarrier) }
                    showPhaseFilter={ !isLoadBoardScreen || TABS.ACTION_REQUIRED === activeTab}
                    showTerminalStatusOptions={ isLoadHistoryScreen }
                    showBookedStatusOptions={ isBookedLoadsScreen }
                    filter={ filter }
                    version={ this.state.version }
                    fetchRoutingGuides={ RoutingGuideUtils.fetchNames }
                    onFilterChange={ this._onFilterChange }
                />
            );
        }

        const pagination = (
            <Pagination
                pageNumber={ this.state.page }
                pageSize={ this.state.pageSize }
                available={ this.state.available }
                fixed={ false }
                colorTheme="light"
                onSetPage={ page => this.setState({ page }) }
                onSetPageSize={ pageSize => this.setState({ pageSize }) }
                resultsText="loads"
            />
        );

        let content;

        if (!loading && (auctions || []).length === 0) {
            content = (
                <>
                    { this._formEmptyTable() }
                    { pagination }
                </>
            );
        } else if (loading) {
            content = (
                <div className="loading-table">
                    <FontAwesomeIcon className="loading-icon" icon={ spinnerIcon } spin />
                </div>
            );
        } else {
            const auctionTableRows = auctions.map((auction, index) => (
                <AuctionCard 
                    key={ `${ auction.id }${ index }` } 
                    auction={ auction }
                    account={ account }
                    showBids={ isLoadBoardScreen && TABS.AVAILABLE_LOADS !== activeTab && !isCarrier }
                    searchView={ isSearchScreen }
                    availableLoadsView={ isLoadBoardScreen && TABS.AVAILABLE_LOADS === activeTab }
                    onActionPerformed={ this._fetchData }
                    onAuctionChanged={ this._onAuctionChanged }
                />
            ));

            content = (
                <>
                    <div className="auctions-table-container">
                        <Table className="auctions-table">
                            <AuctionTableHeaderRow
                                isCarrier={ isCarrier }
                                showBookNow={ isLoadBoardScreen || !isCarrier}
                                showRank={ isLoadBoardScreen && isCarrier && TABS.AVAILABLE_LOADS !== activeTab }
                                showRate={ !isLoadBoardScreen || TABS.AVAILABLE_LOADS !== activeTab }
                                showBids={ isLoadBoardScreen && !isCarrier && TABS.AVAILABLE_LOADS !== activeTab }
                                showCarrier={ !isCarrier && (!isLoadBoardScreen || TABS.AVAILABLE_LOADS !== activeTab) }
                                showExpiration={ isLoadBoardScreen && isCarrier }
                                showActions={ isLoadBoardScreen && isCarrier }
                                showStatus={ !isLoadBoardScreen || !isCarrier }
                            />
                            { auctionTableRows }
                        </Table>
                    </div>

                    { pagination }
                </>
            );
        }

        content = (
            <>
                <div className="content-header">
                    <LoadRequirementsPopover />
                    { filterButton }
                </div>
                { tenderingFilters }
                { content }
            </>
        );

        if (!isLoadBoardScreen) {
            return (
                <div className="auction-list-page-content-background">
                    <div className="auction-list-page-content">
                        { content }
                    </div>
                </div>
            );
        }

        return (
            <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                <Tab default id={ TABS.AVAILABLE_LOADS } title="Available Loads">
                    { content }
                </Tab>
                <Tab id={ TABS.CURRENT_BIDS } title="Current Bids">
                    { content }
                </Tab>
                <Tab id={ TABS.LOAD_OFFERS } title="Load Offers">
                    { content }
                </Tab>
                { !isCarrier ?
                    <Tab id={ TABS.ACTION_REQUIRED } title="Action Required">
                        { content }
                    </Tab> 
                    : null
                }
            </NavigableTabs>
        );
    }

    _formEmptyTable() {
        if (!ObjectUtils.isBlankOrEmpty(this.state.filter)) {
            return (
                <TableEmptyState
                    icon={ emptySearch }
                    title="No Loads Found"
                    description={ <>No loads match your filter settings.</> }
                    includeBackground={ false }
                />
            );
        }

        const isCarrier = this.props.account.carrierId;
        let icon, title, description, actions;

        switch (this.props.screen) {
            case SCREENS.LOAD_BOARD:
                icon = emptyLoadTendering;
                title = 'No Loads Here';

                switch (this.state.activeTab) {
                    case TABS.AVAILABLE_LOADS:
                        description = <>{ isCarrier ? 'Currently, there are no loads available to bid on.' : 'Currently, there are no available loads without bids.' }</>;
                        break;
                    case TABS.CURRENT_BIDS:
                        description = <>{ isCarrier ? 'There are no loads you are currently bidding on.' : 'There are no loads that carriers are currently bidding on.' }</>;
                        break;
                    case TABS.LOAD_OFFERS:
                        description = <>{ isCarrier ? 'Currently, there are no load offers being sent to you.' : 'Currently, there are no load offers being sent to carriers.' }</>;
                        break;
                    case TABS.ACTION_REQUIRED:
                        icon = emptyActionRequired;
                        description = <>Currently, there are no loads that require your action.</>;
                        break;
                    default:
                        // This should never happen
                        console.error(`Unrecongized tab ${ this.state.activeTab }`);
                }

                break;
            case SCREENS.BOOKED_LOADS:
                icon = emptyBookedLoads;
                title = 'No Booked Loads';
                if (isCarrier) {
                    description = <>Check your Load Board to book a new load.<br />After booking a new load, that load will be listed here.</>;
                } else {
                    description = <>Check the Load Board for new potential loads that will be booked.<br />You will be notified for each new booked load.</>;
                }
                actions = (
                    <Button rightIcon={ arrow } navLink="/load-board">Load Board</Button>
                );
                break;
            case SCREENS.LOAD_HISTORY:
                icon = emptyLoadHistory;
                title = 'No Loads Here';
                if (isCarrier) {
                    description = <>Load history will be shown here. To see the loads that are<br />currently offered to you go to the Load Board page.</>;
                } else {
                    description = <>Load history will be shown here. To see the loads that are<br />not booked yet go to the Load Board page.</>;
                }
                actions = (
                    <Button rightIcon={ arrow } navLink="/load-board">Load Board</Button>
                );
                break;
            case SCREENS.LOAD_SEARCH:
                icon = emptySearch;
                title = 'No Loads Found';
                description = <>No results matched your search criteria.</>;
                break;
            default:
                // This should never happen
                console.error(`Unsupported auctions list screen ${ this.props.screen}`);
        }

        return (
            <TableEmptyState
                icon={ icon }
                title={ title }
                description={ description }
                includeBackground={ false }
                actions={ actions }
            />
        );
    }

    render() {
        const [ title, tooltipText ] = this._getScreenInfo();

        const statisticsButton = (
            <CanAccess
                action="statistics:read"
                yes={ <DownloadStatisticsPopup /> }
            />
        );

        return (
            <div className="page auction-list-page">
                <PageHeader title={ title } tooltip={ tooltipText }>
                    { statisticsButton }
                    <ContactUsPopover generalContactInformation={ this.state.generalContactInformation } />
                </PageHeader>

                { this._formContent() }
            </div>
        );
    }
}
