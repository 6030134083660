import { Component } from 'react';
import PropTypes from 'prop-types';

import CustomCheckbox from 'component/form/CustomCheckbox';
import CustomSelect from 'component/form/CustomSelect';

import './CustomMultipleSelect.scss';

export default class CustomMultipleSelect extends Component {

    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        withSearch: PropTypes.bool,
        allowClear: PropTypes.bool,
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        values: PropTypes.arrayOf(PropTypes.object),
        fieldName: PropTypes.string,
        direction: PropTypes.oneOf(['top', 'bottom']),
        placeholder: PropTypes.string,
        size: PropTypes.oneOf(['small', 'regular']),
        onSearch: PropTypes.func,
        onSelect: PropTypes.func,
        onUnselect: PropTypes.func,
        customDropdownRender:PropTypes.func,
        onClear: PropTypes.func
    }

    static defaultProps = {
        id: undefined,
        label: undefined,
        withSearch: false,
        allowClear: false,
        selectedValue: [],
        placeholder: 'Select',
        values: [],
        fieldName: 'title',
        direction: 'bottom',
        size: 'regular',
        onSearch: () => { /* */ },
        onSelect: () => { /* */ },
        onUnselect: () => { /* */ },
        onClear: () => { /* */ },
        customDropdownRender: undefined
    }

    constructor(props) {
        super(props);

        this._optionMapper = this._optionMapper.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._tagRenderer = this._tagRenderer.bind(this);
    }

    _optionMapper(option) {
        return <CustomCheckbox 
            text={ option.label || option[this.props.fieldName] } 
            checked={ this.props.selectedValue.includes(option.label || option[this.props.fieldName]) }
            preventDefaultAction={ true }
        />;
    }

    _tagRenderer(props) {
        const { value } = props;
        if (this.props.selectedValue.length > 1) {
            return (
                <div className="multiple-selected">
                    Multiple 
                </div>
            );
        } else {
            return (
                <div className='one-selected'>
                    { value }
                </div>
            );
        }
    }

    _onSelect(key, options) {
        const newSelect = options.filter(o => !this.props.selectedValue.includes(o.value))[0];
        const newKey = key.filter(k => !this.props.selectedValue.includes(k))[0];
        this.props.onSelect(newKey, newSelect);
    }

    render() {
        return (
            <CustomSelect
                id={ this.props.id }
                label={ this.props.label }
                multiple={ true }
                withSearch={ this.props.withSearch }
                allowClear={ this.props.allowClear }
                selectedValue={ this.props.selectedValue }
                values={ this.props.values }
                placeholder={ this.props.placeholder }
                fieldName={ this.props.fieldName }
                direction={ this.props.direction }
                size={ this.props.size }
                onSearch={ this.props.onSearch }
                onSelect={ this._onSelect }
                onUnselect={ this.props.onUnselect }
                onClear={ this.props.onClear }
                customValueMapper={ this._optionMapper }
                customTagRenderer={ this._tagRenderer }
                customDropdownRender={this.props.customDropdownRender}
                maxTagCount={ 1 }
                maxTagPlaceholder={ (this.props.selectedValue.length).toString() }
            />
        );
    }
}
