import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as success, faInfoCircle as info, faTimesCircle as error } from '@fortawesome/pro-solid-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import Button from 'component/Button';
import CustomLoader from 'component/form/CustomLoader';

import './FormStatusModal.scss';

export default class FormStatusModal extends Component {

    static propTypes = {
        status: PropTypes.oneOf(Object.values(FORM_STATUSES)).isRequired,
        onContinue: PropTypes.func
    }

    static defaultProps = {
        onContinue: () => { /* */ }
    }

    _getStatusElements() {
        let style = "";
        let icon;
        let button = <></>;

        switch (this.props.status) {
            case FORM_STATUSES.LOADING:
                style = 'loading';
                icon = <CustomLoader size="large" />;
                break;
            case FORM_STATUSES.SUCCESS:
                style = 'success';
                icon = <FontAwesomeIcon icon={ success } />;
                button = <Button className="continue-button" onClick={ this.props.onContinue }>Continue</Button>;
                break;
            case FORM_STATUSES.EMPTY_SUCCESS:
                style = 'info';
                icon = <FontAwesomeIcon icon={ info } />;
                button = <Button className="continue-button" type="tertiary" onClick={ this.props.onContinue }>Close</Button>;
                break;
            case FORM_STATUSES.ERROR:
                style = 'error';
                icon = <FontAwesomeIcon icon={ error } />;
                button = <Button className="continue-button" type="tertiary" onClick={ this.props.onContinue }>Close</Button>;
                break;
            default:
                throw new Error('Invalid form status!');
        }

        return { icon, button, style }
    }

    render() {
        const { icon, button, style } = this._getStatusElements();

        return (
            <div className={ `form-status-div ${ style }` }>
                <div className="status-icon">
                    { icon }
                </div>
                <div className="form-status-content">
                    { this.props.children }
                </div>
                { button }
            </div>
        );
    }
}
