import PropTypes from 'prop-types';

export default class Order {

    static propType = () => PropTypes.shape({
        id: PropTypes.string.isRequired,
        pickupTime: PropTypes.string.isRequired,
        pickupTimeOffset: PropTypes.number.isRequired,
        deliveryTime: PropTypes.string.isRequired,
        deliveryTimeOffset: PropTypes.number.isRequired,
        routingGuideLane: PropTypes.object.isRequired,
        bids: PropTypes.array,
        candidates: PropTypes.array,
        exclusions: PropTypes.array,
        suggestedPrice: PropTypes.number,
        bookNowPrice: PropTypes.number
    })

    constructor({ id, pickupTime, pickupTimeOffset, deliveryTime, deliveryTimeOffset, tenderDeadline, routingGuideLane, bids, candidates, exclusions, suggestedPrice, bookNowPrice }) {
        this.id = id;
        this.pickupTime = pickupTime;
        this.pickupTimeOffset = pickupTimeOffset;
        this.deliveryTime = deliveryTime;
        this.deliveryTimeOffset = deliveryTimeOffset;
        this.tenderDeadline = tenderDeadline;
        this.routingGuideLane = routingGuideLane;
        this.bids = bids;
        this.candidates = candidates;
        this.exclusions = exclusions;
        this.suggestedPrice = suggestedPrice;
        this.bookNowPrice = bookNowPrice;
    }
}
