import { Component } from 'react';
import PropTypes from 'prop-types';

import { LANE_TYPE_PROPERTIES } from 'common/constants';
import Popup from 'component/Popup';
import Button from 'component/Button';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './TenderCandidateOfferActions.scss';

export default class TenderCandidateOfferActions extends Component {

    static propTypes = {
        tenderCandidate: PropTypes.object,
        routingGuideLane: PropTypes.object,
        bulk: PropTypes.bool,
        onAccept: PropTypes.func,
        onReject: PropTypes.func
    };

    static defaultProps = {
        tenderCandidate: null,
        routingGuideLane: null,
        bulk: false,
        onAccept: () => { /* */ },
        onReject: () => { /* */ }
    };

    state = {
        showAcceptPopup: false,
        showRejectPopup: false
    };

    constructor(props) {
        super(props);

        this._onOpenAcceptPopup = this._onOpenAcceptPopup.bind(this);
        this._onCloseAcceptPopup = this._onCloseAcceptPopup.bind(this);
        this._onOpenRejectPopup = this._onOpenRejectPopup.bind(this);
        this._onCloseRejectPopup = this._onCloseRejectPopup.bind(this);
        this._onAccept = this._onAccept.bind(this);
        this._onReject = this._onReject.bind(this);
    }

    _onOpenAcceptPopup(event) {
        event.stopPropagation();
        this.setState({ showAcceptPopup: true });
    }

    _onCloseAcceptPopup() {
        this.setState({ showAcceptPopup: false });
    }

    _onOpenRejectPopup(event) {
        event.stopPropagation();
        this.setState({ showRejectPopup: true });
    }

    _onCloseRejectPopup() {
        this.setState({ showRejectPopup: false });
    }

    _onAccept() {
        this.props.onAccept(this.props.tenderCandidate);
        this._onCloseAcceptPopup();
    }

    _onReject() {
        this.props.onReject(this.props.tenderCandidate);
        this._onCloseRejectPopup();
    }

    _offerDetails() {
        const { tenderCandidate, routingGuideLane } = this.props;
        const { lane } = routingGuideLane;

        const origin = LaneUtils.formatRegion(lane.origin);
        const destination = LaneUtils.formatRegion(lane.destination);

        const pricePerMile = PriceUtils.calculatePerMilePrice(tenderCandidate.price.value, routingGuideLane.distance);
        const pricePerMileDisplay = NumberUtils.prefixNumber(pricePerMile, '$', false, 'decimal');

        const laneType = LANE_TYPE_PROPERTIES[lane.type].field;

        return { origin, destination, laneType, pricePerMile : pricePerMileDisplay };
    }

    _rejectPopupContent() {
        let content;
        let buttonText;
        let buttonType;
        let title;

        if (this.props.bulk) {
            buttonText = 'Reject All';
            buttonType = 'danger';
            title = 'Reject All Offers'
            content = (
                <p className="description">
                    Are you sure you want to reject all <b>pending</b> offers? Note that doing this will reject all the offers you haven't already responded to.
                </p>
            );
        } else {
            const { origin, destination, laneType, pricePerMile } = this._offerDetails();
            
            buttonText = 'Reject';
            buttonType = 'tertiary';
            title = 'Reject Offer';
            content = (
                <div className="description">
                    Please confirm that you reject the rate of <b>{ pricePerMile }</b> per mile on the <b>{ laneType }</b> lane <b>{ origin }</b> to <b>{ destination }</b>.
                    Note that this means you won't be receiving load offers for this lane.
                </div>
            );
        }

        return { content, buttonText, buttonType, title };
    }

    _acceptPopupContent() {
        let content;
        let buttonText;
        let title;

        if (this.props.bulk) {
            buttonText = 'Accept All';
            title = <h6 className="heading">Accept All Offers</h6>;
            content = (
                <p>
                    Are you sure you want to accept all <b>pending</b> offers? Note that doing this will accept all the offers you haven't already responded to.
                </p>
            );
        } else {
            const { origin, destination, laneType, pricePerMile } = this._offerDetails();
            
            buttonText = 'Accept';
            title = <h6 className="heading">Accept Offer</h6>;
            content = (
                <div className="description">
                    Please confirm that you accept the rate of <b>{ pricePerMile }</b> per mile on the <b>{ laneType }</b> lane <b>{ origin }</b> to <b>{ destination }</b>.
                </div>
            );
        }

        return { content, buttonText, title };
    }

    _formAcceptPopup() {
        const { content, buttonText, title } = this._acceptPopupContent();

        return (
            <Popup
                id="tender-candidate-accept-offer"
                show={ this.state.showAcceptPopup }
                onClose={ this._onCloseAcceptPopup }
                trigger={
                    <Button type="primary" size="small" onClick={ this._onOpenAcceptPopup }>
                        { buttonText }
                    </Button>
                }>
                <div className="tender-candidate-offer-popup">
                    { title }
                    { content }
                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._onCloseAcceptPopup }>Cancel</Button>
                        <Button type="primary" size="small" onClick={ this._onAccept }>{ buttonText }</Button>
                    </div>
                </div>
            </Popup>
        );
    }

    _formRejectPopup() {
        const { content, buttonText, buttonType, title } = this._rejectPopupContent();

        return (
            <Popup
                id="tender-candidate-reject-offer"
                show={ this.state.showRejectPopup }
                onClose={ this._onCloseRejectPopup }
                trigger={
                    <Button type={ buttonType } size="small" onClick={ this._onOpenRejectPopup }>
                        { buttonText }
                    </Button>
                }>
                <div className="tender-candidate-offer-popup">
                    <h6 className="heading">{ title }</h6>
                    { content }
                    <div className="buttons-wrapper">
                        <Button type="tertiary" size="small" onClick={ this._onCloseRejectPopup }>Cancel</Button>
                        <Button type="danger" size="small" onClick={ this._onReject }>{ buttonText }</Button>
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        return (
            <div className="tender-candidate-offer-actions">
                { this._formRejectPopup() }
                { this._formAcceptPopup() }
            </div>
        );
    }
}
