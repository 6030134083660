import { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import 'antd/dist/antd.css';
import './CustomDrawer.scss'

export default class CustomDrawer extends Component {
    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
    };

    static defaultProps = {
        title: '',
        onClose: () => { /* */ },
        width: 256,
        height: 256,
        placement: 'right'
    };

    render() {
        return (
            <Drawer 
                title={ this.props.title } 
                visible={ this.props.visible } 
                onClose={ this.props.onClose } 
                width={ this.props.width } 
                height={ this.props.height }
            >
                { this.props.children }
            </Drawer>
        );
    }
}
