import { Component } from 'react';
import moment from 'moment';
import { faDownload as download } from '@fortawesome/pro-solid-svg-icons';

import Button from 'component/Button';
import DownloadFileComponent from 'component/DownloadFileComponent';
import CustomRangePicker from 'component/form/CustomRangePicker';
import Popup from 'component/Popup';
import CustomLoader from 'component/form/CustomLoader';

import './DownloadStatisticsPopup.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

export default class DownloadStatisticsPopup extends Component {

    constructor(props) {
        super(props);
        this._onTimeFrameChange = this._onTimeFrameChange.bind(this);
        this._onOpenPopup = this._onOpenPopup.bind(this);
        this._onClosePopup = this._onClosePopup.bind(this);
        this._disabledDate = this._disabledDate.bind(this);
        this._onDownloadStart = this._onDownloadStart.bind(this);
    }

    state = {
        loading: false,
        showPopup: false,
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    };

    _onOpenPopup() {
        this.setState({ showPopup: true });
    }

    _onDownloadStart() {
        this.setState({ loading: true });
    }

    _onClosePopup() {
        this.setState({
            loading: false,
            showPopup: false, 
            startDate: moment().subtract(7, 'days'),
            endDate: moment()
        });
    }

    _onTimeFrameChange(range) {
        this.setState({
            startDate: range && range[0] ? range[0] : null,
            endDate: range && range[1] ? range[1] : null
        });
    }

    _disabledDate(date) {
        return moment().endOf('day').isBefore(date);
    };

    render() {
        const params = {
            startTime: this.state.startDate ? new Date(this.state.startDate.startOf('day')) : null,
            endTime: this.state.endDate ? new Date(this.state.endDate.endOf('day')) : null
        };

        const references = [
            { endpoint: 'statistics/carrier', params },
            { endpoint: 'statistics/broker', params }
        ];

        const loading = this.state.loading;

        let content;

        if (loading) {
            content = (
                <>
                    <CustomLoader size="large" />
                    <h6 className="heading">Downloading Statistics</h6>
                    <p className="description">This may take a while. Please wait...</p>
                </>
            );
        } else {
            content = (
                <>
                    <h6 className="heading">Download Statistics</h6>
                    <p className="description">
                        Clicking the button below will trigger download of two groups of statistics in the CSV format:
                    </p>
                    <ul>
                        <li><b>Carrier Statistics</b> - number of accepted, rejected and missed offers, as well as canceled loads by carrier code</li>
                        <li><b>Broker Statistics</b> - number of canceled auctions by Brokers</li>
                    </ul>
                    <p className="description">
                        Please select dates below to specify exact time frame for exporting statistics.
                    </p>
                    <CustomRangePicker
                        label="Time Frame"
                        dateFrom={ this.state.startDate }
                        dateTo={ this.state.endDate }
                        dateFormat={ DATE_FORMAT }
                        handleChange={ this._onTimeFrameChange }
                        disabledDate={ this._disabledDate }
                    />
                </>
            );
        }

        if (this.state.showPopup) {
            content = (
                <>
                    { content }
                    <div className="buttons-wrapper">
                        <Button type="tertiary" onClick={ this._onClosePopup }>
                            Cancel
                        </Button>

                        <DownloadFileComponent
                            trigger={
                                <Button onClick={ this._onDownloadStart }>
                                    Download
                                </Button>
                            }
                            reference={ references }
                            isStaticResource={ false }
                            onDownload={ this._onClosePopup }
                            disabled={ loading }
                        />
                    </div>
                </>
            );
        }

        return (
            <Popup
                id="popup-download-statistics"
                size="medium"
                show={ this.state.showPopup }
                onClose={ this._onClosePopup }
                trigger={ 
                    <Button
                        type="tertiary"
                        size="small"
                        leftIcon={ download }
                        onClick={ this._onOpenPopup }
                    >
                        Download Statistics
                    </Button>
                }
            >
                <div className={ `download-statistics-popup${ loading ? ' loading' : '' }` }>
                    { content }
                </div>
            </Popup>
        );
    }
}
