import { Component } from 'react';

import './Table.scss';

export default class Table extends Component {
    render() {
        return (
            <table { ...this.props } className={ `custom-table ${ this.props.className }` }>
                <tbody>
                    { this.props.children }
                </tbody>
            </table>
        );
    }
}
