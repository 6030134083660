import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes as close
} from '@fortawesome/pro-regular-svg-icons';

import Tooltip from 'component/Tooltip';

import './Tag.scss';

export default class Tag extends Component {

    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.string.isRequired,
        leftIcon: PropTypes.element,
        tooltipText: PropTypes.string,
        className: PropTypes.string,
        closeable: PropTypes.bool,
        onClose: PropTypes.func
    }

    static defaultProps = {
        title: null,
        leftIcon: null,
        tooltipText: null,
        className: null,
        closeable: true,
        onClose: () => {/* */}
    }

    render() {
        const { closeable, title, leftIcon, tooltipText, className } = this.props;

        let titleDiv = <></>;
        if (title) {
            titleDiv = (
                <small className="title">
                    { this.props.title }:
                </small>
            );
        }

        let leftIconDiv = <></>;
        if (leftIcon) {
            leftIconDiv = (
                <small className="left-icon">
                    { leftIcon }
                </small>
            ); 
        }

        let closeIconDiv = <></>;
        if (closeable) {
            closeIconDiv = (
                <small>
                    <FontAwesomeIcon onClick={ () => this.props.onClose() } className="close-icon" icon={ close } />
                </small>
            );
        }

        let tooltipDiv = <></>;
        if (tooltipText) {
            tooltipDiv = (
                <Tooltip direction="bottom">
                    { tooltipText }
                </Tooltip>
            );
        }

        return (
            <div className="tag-wrapper">
                <div className={ className ? `tag ${ className }` : 'tag' }>
                    { leftIconDiv }
                    { titleDiv }
                    <small className="text" onClick={ this.props.onClose }>
                        { this.props.text }
                    </small>
                    { closeIconDiv }
                </div>
                { tooltipDiv }
            </div>
        );
    }
}
