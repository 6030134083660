import { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

export default class TableRow extends Component {

    static propTypes = {
        header: PropTypes.bool,
        className: PropTypes.string
    };

    static defaultProps = {
        header: false,
        className: ''
    };

    render() {
        let children = this.props.children.filter(c => c);

        if (this.props.header) {
            children = Children.map(children, child => cloneElement(child, { header: true }));
        }

        return (
            <tr className={ `simple-table-row ${ this.props.className } ${ this.props.header ? 'simple-table-header' : '' }` }>
                { children }
            </tr>
        );
    }
}
