import PropTypes from 'prop-types';

import LogisticsCoordinators from 'component/load/LogisticsCoordinators';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import WSComponent from 'component/WSComponent';
import DedicatedCarriers from 'component/lane/DedicatedCarriers';
import LaneHeaderCard from 'component/lane/LaneHeaderCard';
import LaneCarriers from 'component/lane/LaneCarriers';
import WebSocketService from 'service/WebSocketService';
import LaneCarrierApiService from 'service/api/LaneCarrierApiService';
import RoutingGuideLaneApiService from 'service/api/RoutingGuideLaneApiService';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';
import LaneUtils from 'utils/LaneUtils';
import DocumentUtils from 'utils/DocumentUtils';

export default class LaneDetails extends WSComponent {

    static propTypes = {
        ws: PropTypes.instanceOf(WebSocketService),
        account: PropTypes.object
    }

    static defaultProps = {
        ws: WebSocketService.instance(),
        account: null
    }

    constructor(props) {
        super(props);

        this._fetchData = this._fetchData.bind(this);
        this._handleLaneUpdate = this._handleLaneUpdate.bind(this);
        this._fetchNumberOfDedicatedCarriers = this._fetchNumberOfDedicatedCarriers.bind(this);
    }

    state = {
        laneId: (this.props.match.params || {}).laneId,
        documentTypes: null,
        routingGuideLane: null,
        numberOfDedicatedCarriers: 0,
        numberOfSpotMarketCarriers: 0,
        version: 0
    }

    componentDidMount() {
        super.componentDidMount();
        this._fetchData();
        this._fetchDocumentTypes();
    }

    _subscribe(routingGuideLaneId, laneId) {
        this._unsubscribeAll();
        this.subscriptions = [
            {
                topic: `/user/queue/lanes/${ routingGuideLaneId }`,
                handler: this._handleLaneUpdate
            },
            {
                topic: `/user/queue/lanes/${ laneId }`,
                handler: this._handleLaneUpdate
            }
        ];
        this._subscribeAll();
    }

    _handleLaneUpdate() {
        this.setState(prevState => ({ version: prevState.version + 1 }));
        this._fetchData();
    }

    _fetchData() {
        RoutingGuideLaneApiService.get(this.state.laneId)
            .then(routingGuideLane => {
                if (!this.state.routingGuideLane) {
                    this._subscribe(routingGuideLane.id, routingGuideLane.lane.id);
                }

                this.setState({ routingGuideLane }, () => {
                    this._fetchNumberOfDedicatedCarriers();
                    this._fetchNumberOfSpotMarketCarriers();
                });
            })
            .catch(error => {
                console.error('An error occurred while fetching lane data.', error);
                this.props.history.replace('/404');
            });
    }

    _fetchNumberOfDedicatedCarriers() {
        TenderCandidateApiService.getCount(this.state.routingGuideLane.rfpAuction.id, this.state.routingGuideLane.id)
            .then(numberOfDedicatedCarriers => this.setState({ numberOfDedicatedCarriers }))
            .catch(error => console.error('An error occurred while fetching dedicated carriers count.', error));
    }

    _fetchNumberOfSpotMarketCarriers() {
        LaneCarrierApiService.count(this.state.routingGuideLane.lane.id, false)
            .then(numberOfSpotMarketCarriers => this.setState({ numberOfSpotMarketCarriers }))
            .catch(error => console.error('An error occurred while fetching spot market carrier count.', error));
    }

    async _fetchDocumentTypes() {
        const documentTypes = await DocumentUtils.fetchDocumentTypes(false);
        this.setState({ documentTypes });
    }

    _formHeading() {
        return (
            <LaneHeaderCard 
                account={ this.props.account }
                routingGuideLane={ this.state.routingGuideLane } 
                numberOfDedicatedCarriers={ this.state.numberOfDedicatedCarriers }
                numberOfSpotMarketCarriers={ this.state.numberOfSpotMarketCarriers }
            />
        );
    }

    render() {
        if (this.state.routingGuideLane) {
            const routingGuideLane = this.state.routingGuideLane;
            const origin = LaneUtils.formatRegion(routingGuideLane.lane.origin);
            const destination = LaneUtils.formatRegion(routingGuideLane.lane.destination);
            const heading = this._formHeading();

            const breadCrumbs = (
                <Breadcrumbs crumbs={ [
                    { path: '/rfp/list', name: 'Routing Guides', backCrumb: false },
                    { path: `/rfp/${ this.state.routingGuideLane.rfpAuction.id }`, name: this.state.routingGuideLane.rfpAuction.name, backCrumb: false },
                    { path: "", name: `${ origin } -> ${ destination }`, backCrumb: false },
                    { path: `/rfp/${ this.state.routingGuideLane.rfpAuction.id }`, name: `Back to ${ this.state.routingGuideLane.rfpAuction.name }`, backCrumb: true }
                ] } />
            );

            return (
                <div className="page lane-details-page">
                    { breadCrumbs }
                    { heading }
                    <DedicatedCarriers
                        account={ this.props.account }
                        routingGuideLane={ this.state.routingGuideLane }
                        documentTypes={ this.state.documentTypes }
                        fetchNumberOfDedicatedCarriers={ this._fetchNumberOfDedicatedCarriers }
                    />
                    <LaneCarriers
                        lane={ this.state.routingGuideLane.lane }
                        version={ this.state.version }
                    />
                    <LogisticsCoordinators routingGuideLane={ routingGuideLane } />
                </div>
            );
        }
        return <></>;
    }
}
