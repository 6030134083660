import AuthenticationService from 'service/AuthenticationService';

export default class AuthorizationService {
    static _instance = new AuthorizationService();

    static instance() {
        return this._instance;
    }

    /**
     * Checks if the logged in user has an appropriate permission to access some resources. Accepts a single action 
     * or an array of actions. Returns true if the user has the exact permission in the first case, or if the user
     * has at least one permission in provided array otherwise. Takes additional dynamic rules into consideration.
     * 
     * @param {string|Array} action a single or an array of actions to match to the user's permissions
     * @param {Object} data additional information needed for the dynamic rules
     * @returns true if the user has an appropriate permission, false otherwise
     */
    async canAccess(action, data) {
        const authenticationService = AuthenticationService.instance();
        const account = await authenticationService.getAccount();

        return this.canUserAccess(account, action, data);
    }

    /**
     * Checks if the user with provided account has an appropriate permission to access some resources. Accepts a single 
     * action or an array of actions. Returns true if the user has the exact permission in the first case, or if the user
     * has at least one permission in provided array otherwise. Takes additional dynamic rules into consideration.
     * 
     * @param {Object} account an account of the user the permissions are being checked for
     * @param {string|Array} action a single or an array of actions to match to the user's permissions
     * @param {Object} data additional information needed for the dynamic rules
     * @returns true if the user has an appropriate permission, false otherwise
     */
    canUserAccess(account, action, data) {
        const userRoles = account.roles;
        const userPermissions = account.permissions;
        
        const actions = Array.isArray(action) ? action : [action];

        for (const action of actions) {
            let allowed = userPermissions.includes(action);

            if (!allowed) {
                for (const userRole of userRoles) {
                    const dynamicRule = AuthorizationService._dynamicRules[userRole];

                    if (dynamicRule) {
                        const ruleCondition = dynamicRule[action];

                        if (ruleCondition) {
                            allowed = ruleCondition(data);
                        }
                    }
                }
            }

            if (allowed) {
                return true;
            }
        }

        return false;
    }

    static _dynamicRules = {
        Carrier: {
            'user:read': ({ userId, accessedUserId }) => {
                // only in case it is their own page
                return !accessedUserId || userId === accessedUserId;
            }
        }
    }
}
