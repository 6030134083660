import { Component } from 'react';
import { ReactComponent as NotFoundBackground } from 'file/svg/404.svg';
import { ReactComponent as Logo } from 'file/svg/logo.svg';
import Button from 'component/Button';

import './Unknown.scss';

export default class Unknown extends Component {

    render() {
        return (
            <div className="unknown-page">
                <NotFoundBackground className="background" />
                <Logo className="logo" />
                <div className="title">
                    404
                </div>
                <h4 className="subtitle">
                    Page Not Found!
                </h4>
                <h6 className="description">
                    The page you are looking for might have been removed,
                    had its name changed, or is temporarily unavailable.
                </h6>
                <Button type="brand" link="/">
                    Back to Home
                </Button>
            </div>
        );
    }
}
