import { Children, cloneElement, Component, isValidElement } from 'react';
import { withRouter } from 'react-router';

export default withRouter(class PropForwardingComponent extends Component {

    static processedChildren(props) {
        const forwardedProps = { ...props };
        delete forwardedProps.children;

        return Children.map(
            props.children,
            child => isValidElement(child) ? cloneElement(child, { ...forwardedProps }) : child
        );
    }

    render() {
        return PropForwardingComponent.processedChildren(this.props);
    }
});
