import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CanAccess from 'component/CanAccess';

import './TableEmptyState.scss';

export default class TableEmptyState extends Component {

    static propTypes = {
        icon: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.element.isRequired,
        actions: PropTypes.node,
        includeBackground: PropTypes.bool,
        role: PropTypes.string
    }

    static defaultProps = {
        actions: null,
        includeBackground: true,
        role: undefined
    };

    render() {
        return (
            <div className={ `empty-table ${ this.props.includeBackground ? 'with-background' : '' } ` }>
                <div className="empty-table-icon-container">
                    <FontAwesomeIcon className="empty-table-icon" icon={ this.props.icon } />
                </div>   
                <strong><b>{ this.props.title }</b></strong>
                { this.props.role ? (
                    <CanAccess
                        action={ this.props.role }
                        yes={ <p className="empty-table-description">{ this.props.description }</p> }
                        no={ <></> }
                    />
                ) 
                : (
                    <p className="empty-table-description">{ this.props.description }</p>
                )}
                <div className="empty-table-actions">
                    { this.props.actions }
                </div>
            </div>
        );
    }
}
