import { Component, createElement } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import FunctionUtils from 'utils/FunctionUtils';
import './Button.scss';

export default class Button extends Component {

    static propTypes = {
        id: PropTypes.string,
        type: PropTypes.oneOf(['primary', 'secondary', 'secondary-alt', 'tertiary', 'tertiary-alt', 'danger', 'brand']).isRequired,
        size: PropTypes.oneOf(['regular', 'small']),
        disabled: PropTypes.bool.isRequired,
        leftIcon: PropTypes.object,
        rightIcon: PropTypes.object,
        link: PropTypes.string,
        navLink: PropTypes.string,
        className: PropTypes.string,
        submit: PropTypes.bool,
        onClick: PropTypes.func
    }

    static defaultProps = {
        id: undefined,
        type: 'primary',
        size: 'regular',
        disabled: false,
        leftIcon: null,
        rightIcon: null,
        className: '',
        submit: false,
        onClick: () => { /* */ }
    }

    _elementType() {
        if (this.props.navLink) {
            return NavLink;
        }

        if (this.props.link) {
            return 'a';
        }

        return 'button';
    }

    _buttonType() {
        if (this.props.submit) {
            return 'submit';
        }

        if (this.props.link || this.props.navLink) {
            return '';
        }

        return 'button';
    }

    render() {
        const iconOnly = !this.props.children && (this.props.leftIcon !== this.props.rightIcon);
        const iconOnlyClass = iconOnly ? 'no-content' : '';

        let leftIcon = (<></>);
        if (this.props.leftIcon) {
            leftIcon = (<FontAwesomeIcon icon={ this.props.leftIcon } className={ `icon left ${iconOnlyClass}` } />);
        }

        let rightIcon = (<></>);
        if (this.props.rightIcon) {
            rightIcon = (<FontAwesomeIcon icon={ this.props.rightIcon } className={ `icon right ${iconOnlyClass}` } />);
        }

        const children = [leftIcon, ...(this.props.children || []), rightIcon].map((item, index) =>
            <span key={ index }>{ item }</span>
        );

        const debouncedOnClick = FunctionUtils.debounce(this.props.onClick.bind(this), 1000).bind(this);

        let props = {
            id: this.props.id,
            className: `button ${this.props.type} ${this.props.size} ${this.props.className}`,
            onClick: this.props.disabled ? undefined : debouncedOnClick,
            disabled: this.props.disabled,
            to: this.props.navLink,
            href: this.props.link,
            type: this._buttonType()
        };

        return createElement(this._elementType(), props, children);
    }
}
