import WebSocketService from 'service/WebSocketService';
import SafeStateComponent from './SafeStateComponent';

/**
 * Not intended for standalone usage!
 * Components which use WebSocket connections to the backend should extend this, instead of the React.Component.
 * <p>
 * Provides correct lifecycle for subscriptions, and handles re-connections gracefully.
 * <p>
 * Expects a {@link WebSocketService ws} instance to be provided as a prop.
 * If the component that extends WSComponent defines any props, passing {@link WebSocketService ws} instance is mandatory.
 */
export default class WSComponent extends SafeStateComponent {

    static defaultProps = {
        ws: WebSocketService.instance()
    };

    /**
     * An array of subscription objects, used to handle messages from the backend.
     * @type {[{ topic, handler }]}
     */
    subscriptions = [];

    onConnect = () => { /* */ };

    onDisconnect = () => { /* */ };

    async componentDidMount() {
        super.componentDidMount();

        if (this.props.ws) {
            this.props.ws.registerOnConnectHandler(this._subscribeAll.bind(this));
            this.props.ws.registerOnConnectHandler(this.onConnect.bind(this));

            this.props.ws.registerOnDisconnectHandler(this._unsubscribeAll.bind(this));
            this.props.ws.registerOnDisconnectHandler(this.onDisconnect.bind(this));
        }

        await this._subscribeAll();
    }

    async componentWillUnmount() {
        if (this.props.ws) {
            this.props.ws.unregisterOnConnectHandler(this._subscribeAll.bind(this));
            this.props.ws.unregisterOnConnectHandler(this.onConnect.bind(this));

            this.props.ws.unregisterOnDisconnectHandler(this._unsubscribeAll.bind(this));
            this.props.ws.unregisterOnDisconnectHandler(this.onDisconnect.bind(this));
        }

        this._unsubscribeAll();
        super.componentWillUnmount();
    }

    /**
     * Registers all subscription handlers.
     * @private
     */
    async _subscribeAll() {
        if (this.props.ws) {
            for (const sub of this.subscriptions) {
                if (!sub.subscription) {
                    sub.subscription = await this.props.ws.subscribe(sub.topic, message => sub.handler(message.body));
                }
            }
        }
    }

    /**
     * Unregisters all subscription handlers.
     * Handlers will still be present, and invoking {@link _subscribeAll} will re-register them.
     * @private
     */
    _unsubscribeAll() {
        if (this.props.ws) {
            for (const sub of this.subscriptions) {
                if (sub.subscription) {
                    sub.subscription.unsubscribe();
                    sub.subscription = null;
                }
            }
        }
    }

    /**
     * Sends a message object to a particular destination endpoint.
     */
    async sendMessage(destination, message) {
        return await this.props.ws.send(destination, message);
    }
}
