import { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faEdit as edit,
    faInfoCircle as info
} from '@fortawesome/pro-solid-svg-icons';

import Button from 'component/Button';
import CustomNumberInput from 'component/form/CustomNumberInput';
import ContentCard from 'component/card/ContentCard';
import CustomCheckbox from 'component/form/CustomCheckbox';
import Tooltip from 'component/Tooltip';
import CanAccess from 'component/CanAccess';
import SMCarriersSyncApiService from 'service/api/SMCarriersSyncApiService';

import './SMCarriersSyncRules.scss';

export default class SMCarriersSyncRules extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            orderCount: null,
            orderDaysBack: null,
            distanceFromOrigin: null,
            distanceFromDestination: null,
            includeDedicatedCarriers: false,
            editing: false,
            isValid: false,
            editingValues: {
                orderCount: null,
                orderDaysBack: null,
                distanceFromOrigin: null,
                distanceFromDestination: null,
                includeDedicatedCarriers: false
            }
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onOrderCountChange = this._onOrderCountChange.bind(this);
        this._onOrderCountReset = this._onOrderCountReset.bind(this);
        this._onOrderDaysBackChange = this._onOrderDaysBackChange.bind(this);
        this._onOrderDaysBackReset = this._onOrderDaysBackReset.bind(this);
        this._onDistanceFromOriginChange = this._onDistanceFromOriginChange.bind(this);
        this._onDistanceFromOriginReset = this._onDistanceFromOriginReset.bind(this);
        this._onDistanceFromDestinationChange = this._onDistanceFromDestinationChange.bind(this);
        this._onDistanceFromDestinationReset = this._onDistanceFromDestinationReset.bind(this);
        this._onIncludeDedicatedCarriersCheckboxChange = this._onIncludeDedicatedCarriersCheckboxChange.bind(this);
        this._showEnterTMWSyncRules = this._showEnterTMWSyncRules.bind(this);
    }

    componentDidMount() {
        this._fetchData();
    }

    _fetchData() {
        SMCarriersSyncApiService.get()
            .then(data => { 
                const { orderCount, orderDaysBack, distanceFromOrigin, distanceFromDestination, includeDedicatedCarriers } = data;

                this.setState({
                    orderCount,
                    orderDaysBack,
                    distanceFromOrigin,
                    distanceFromDestination,
                    includeDedicatedCarriers,
                    editingValues: data
                });
            })
            .catch(error => console.error('An error occurred while fetching SM carriers sync rules.', error));
    }

    _onSubmit() {
        const { orderCount, orderDaysBack, distanceFromOrigin, distanceFromDestination, includeDedicatedCarriers } = this.state.editingValues;

        const body = {
            orderCount,
            orderDaysBack,
            distanceFromOrigin,
            distanceFromDestination,
            includeDedicatedCarriers
        };

        SMCarriersSyncApiService.update(body)
            .then(data => {
                const { orderCount, orderDaysBack, distanceFromOrigin, distanceFromDestination, includeDedicatedCarriers } = data;

                this.setState({
                    orderCount,
                    orderDaysBack,
                    distanceFromOrigin,
                    distanceFromDestination,
                    includeDedicatedCarriers
                });
            })
            .catch(error => console.error('An error occurred while updating SM carriers sync rules.', error))
            .finally(() => this.setState({ editing: false, isValid: false }));
    }

    _onCancel() {
        this.setState(prevState => {
            const { orderCount, orderDaysBack, distanceFromOrigin, distanceFromDestination, includeDedicatedCarriers } = prevState;
            
            return {
                editingValues: {
                    orderCount,
                    orderDaysBack,
                    distanceFromOrigin,
                    distanceFromDestination,
                    includeDedicatedCarriers
                },
                editing: false,
                isValid: false
            };
        });
    }

    _showEnterTMWSyncRules() {
        this.setState({ editing: true });
    }

    _isFormValid({ orderCount, orderDaysBack, distanceFromOrigin, distanceFromDestination, includeDedicatedCarriers }) {
        return !!orderCount && !!orderDaysBack && !!distanceFromOrigin && !!distanceFromDestination && (
            orderCount !== this.state.orderCount
            || orderDaysBack !== this.state.orderDaysBack
            || distanceFromOrigin !== this.state.distanceFromOrigin
            || distanceFromDestination !== this.state.distanceFromDestination
            || includeDedicatedCarriers !== this.state.includeDedicatedCarriers);
    }

    _onOrderCountChange(orderCount) {
        this.setState(prevState => {
            const editingValues = { ...prevState.editingValues, orderCount };
            const isValid = this._isFormValid(editingValues);

            return { editingValues, isValid };
        });
    }

    _onOrderDaysBackChange(orderDaysBack) {
        this.setState(prevState => {
            const editingValues = { ...prevState.editingValues, orderDaysBack };
            const isValid = this._isFormValid(editingValues);

            return { editingValues, isValid };
        });
    }

    _onDistanceFromOriginChange(distanceFromOrigin) {
        this.setState(prevState => {
            const editingValues = { ...prevState.editingValues, distanceFromOrigin };
            const isValid = this._isFormValid(editingValues);

            return { editingValues, isValid };
        });
    }

    _onDistanceFromDestinationChange(distanceFromDestination) {
        this.setState(prevState => {
            const editingValues = { ...prevState.editingValues, distanceFromDestination };
            const isValid = this._isFormValid(editingValues);

            return { editingValues, isValid };
        });
    }

    _onIncludeDedicatedCarriersCheckboxChange() {
        this.setState(prevState => {
            const editingValues = { ...prevState.editingValues, includeDedicatedCarriers: !prevState.editingValues.includeDedicatedCarriers };
            const isValid = this._isFormValid(editingValues);

            return { editingValues, isValid };
        });
    }

    _onOrderCountReset() {
        this._onOrderCountChange(null);
    }

    _onOrderDaysBackReset() {
        this._onOrderDaysBackChange(null);
    }

    _onDistanceFromOriginReset() {
        this._onDistanceFromOriginChange(null);
    }

    _onDistanceFromDestinationReset() {
        this._onDistanceFromDestinationChange(null);
    }

    _formPreviewTMWSyncRules() {
        return (
            <div className="tmw-preview-sync-rules">
                Invite Spot Market Carrier if they meet the following rules:
                <ul>
                    <li>
                        Carrier has transported at least <b>{ this.state.orderCount }</b> loads in the last <b>{ this.state.orderDaysBack }</b> days on lanes not more than <b>{ this.state.distanceFromOrigin }</b> miles from <b>Origin</b> and <b>{ this.state.distanceFromDestination }</b> miles
                        from <b>Destination</b>.
                    </li>
                    <li>
                        { this.state.includeDedicatedCarriers ? 'Any dedicated carriers from the auction in question won\'t be skipped during the sync.' : 'Any dedicated carriers from the auction in question will be skipped during the sync.' }
                    </li>
                </ul>
            </div>   
        );
    }

    _formEnterTMWSyncRules() {
        return (
            <div className="tmw-enter-sync-rules-content">
                <div className="tmw-enter-sync-rules">
                    <div className="fields">
                        <p>Invite Spot Market Carrier if they have transported at least</p>
                        <CustomNumberInput
                            className="tmw-sync-rules-input-field"
                            initialValue={ this.state.orderCount ? this.state.orderCount.toString() : null }
                            value={ this.state.editingValues.orderCount ? this.state.editingValues.orderCount.toString() : null }
                            onChange={ this._onOrderCountChange }
                            allowClear={ true }
                            onClear={ this._onOrderCountReset }
                            placeholder="Enter Number"
                            focusOnMount={ false }
                        />
                        <p>successful loads within the last</p>
                        <CustomNumberInput
                            className="tmw-sync-rules-input-field"
                            initialValue={ this.state.orderDaysBack ? this.state.orderDaysBack.toString() : null }
                            value={ this.state.editingValues.orderDaysBack ? this.state.editingValues.orderDaysBack.toString() : null }
                            onChange={ this._onOrderDaysBackChange }
                            allowClear={ true }
                            onClear={ this._onOrderDaysBackReset }
                            placeholder="Enter Number"
                            focusOnMount={ false }
                        />
                        <p>days.</p>
                    </div>
                        
                    <div className="fields">
                        <p>Maximum distance from Origin (miles):</p>
                        <CustomNumberInput
                            className="tmw-sync-rules-input-field"
                            initialValue={ this.state.distanceFromOrigin ? this.state.distanceFromOrigin.toString() : null }
                            value={ this.state.editingValues.distanceFromOrigin ? this.state.editingValues.distanceFromOrigin.toString() : null }
                            onChange={ this._onDistanceFromOriginChange }
                            allowClear={ true }
                            onClear={ this._onDistanceFromOriginReset }
                            placeholder="Enter Number"
                            focusOnMount={ false }
                        />
                    </div>
                        
                    <div className="fields">
                        <p>Maximum distance from Destination (miles):</p>
                        <CustomNumberInput
                            className="tmw-sync-rules-input-field"
                            initialValue={ this.state.distanceFromDestination ? this.state.distanceFromDestination.toString() : null }
                            value={ this.state.editingValues.distanceFromDestination ? this.state.editingValues.distanceFromDestination.toString() : null }
                            onChange={ this._onDistanceFromDestinationChange }
                            allowClear={ true }
                            onClear={ this._onDistanceFromDestinationReset }
                            placeholder="Enter Number"
                            focusOnMount={ false }
                        />
                    </div>   

                    <div className="fields">
                        <CustomCheckbox
                            onChange={ this._onIncludeDedicatedCarriersCheckboxChange }
                            checked={ this.state.editingValues.includeDedicatedCarriers }
                            text="Include Dedicated Carriers from this auction" 
                        />
                    </div>                      
                </div>
                    
                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this._onCancel }>
                        Cancel
                    </Button>

                    <Button disabled={ !this.state.isValid } onClick={ this._onSubmit }>
                        Save Changes
                    </Button>
                </div> 
            </div>
        );
    }

    _formCardHeading() {
        return (
            <>
                TMW Synchronization Rules
                <span className="tooltip-icon">
                    <FontAwesomeIcon icon={ info } className="icon" />
                    <Tooltip direction="right">
                        In order to determine the eligibility of the Spot Market Carriers, you need to define the thresholds for the eligibility rules.
                    </Tooltip>
                </span>
            </>
        );
    }

    render() {
        const content = !this.state.editing ? this._formPreviewTMWSyncRules() : this._formEnterTMWSyncRules();
        
        let actions = <></>;
        if (!this.state.editing) {
            actions = (
                <CanAccess
                    action="sm-carriers-sync-config:write"
                    yes={
                        <Button
                            leftIcon={ edit }
                            onClick={ this._showEnterTMWSyncRules }
                        >
                            Edit Rules
                        </Button>
                    }
                />
            );
        }

        return (
            <div className="tmw-sync-rules-card">
                <ContentCard
                    className="tmw-sync-content-card"
                    heading={ this._formCardHeading() }
                    actions={ actions }
                >
                    { content }
                </ContentCard>
            </div>
        );
    }
}
