import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as notificationIconLight } from '@fortawesome/pro-light-svg-icons';
import { faBell as notificationIconSolid } from '@fortawesome/pro-solid-svg-icons';
import PubSub from 'pubsub-js';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER } from 'common/constants';
import WSComponent from 'component/WSComponent';
import NotificationPane from 'component/notifications/NotificationPane';
import NotificationOverlay from 'component/notifications/NotificationOverlay';
import Dropdown from 'component/Dropdown';
import Notification from 'model/Notification'
import RestService from 'service/RestService';
import { NOTIFICATION_TOPICS } from 'common/PubSubTopics';

import './NotificationMenu.scss';

export default class NotificationMenu extends WSComponent {

    notificationUpdateSubToken = null;

    static notificationStateClass(dropdownOpened, notificationsRead) {
        let notificationClass = "";

        if (notificationsRead) {
            notificationClass += " read ";
        } else {
            notificationClass += " unread "
        }

        if (dropdownOpened) {
            notificationClass += " opened ";
        }

        return notificationClass;
    }

    constructor(props) {
        super(props);
        this.subscriptions = [
            {
                topic: '/topic/notifications',
                handler: (event) => this._handleNotificationEvent(event)

            },
            {
                topic: '/user/queue/notifications',
                handler: (event) => this._handleNotificationEvent(event)
            }
        ];

        this.state = {
            page: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            showNotificationsDropdown: false,
            read: true,
            unreadNotificationCount: 0,
            latestNotifications: [],
            newNotifications: []
        };

        this._subscribeAll = this._subscribeAll.bind(this);
        this._unsubscribeAll = this._unsubscribeAll.bind(this);
        this._handleNotificationEvent = this._handleNotificationEvent.bind(this);
        this._onNotificationUpdate = this._onNotificationUpdate.bind(this);
    }

    _onNotificationUpdate() {
        this._fetchLatestNotifications();
     };

    componentDidMount() {
        super.componentDidMount();
        this.notificationUpdateSubToken = PubSub.subscribe(NOTIFICATION_TOPICS.NOTIFICATION_MENU_UPDATE, this._onNotificationUpdate);
        document.addEventListener('mousedown', this.handleClickOutside);
        this._fetchLatestNotifications();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        PubSub.unsubscribe(this.notificationUpdateSubToken);
        super.componentWillUnmount();
    }

    _fetchLatestNotifications() {
        RestService.instance().get("notifications", {
            pageNumber: this.state.page,
            pageSize: this.state.pageSize
        }).then(details => {
            this.setState({
                latestNotifications: details.data.map(resp => new Notification(JSON.parse(resp.notification.metadata.payload), resp.seen)),
                available: details.available,
                time: Date.now()
            }, () => {
                RestService.instance().get("notifications/count", {
                    seen: false
                }).then(count => {
                    this.setState({
                        unreadNotificationCount: count,
                        read: count === 0
                    })
                })
            });
        });
    }

    async _setNotificationRead(notificationId) {
        let notifications = this.state.latestNotifications || [];
        let unreadNotificationCount = this.state.unreadNotificationCount;
        if (!notificationId) {
            //set all notifications as read
            await RestService.instance().post("notifications/see");
            notifications.forEach(n => n.seen = true);
            unreadNotificationCount = 0;
        } else {
            await RestService.instance().post("notifications/see?notificationId=" + notificationId, { });
            (notifications.find(n => n.id === notificationId) || {}).seen = true;
            unreadNotificationCount--;
        }
        PubSub.publish(NOTIFICATION_TOPICS.NOTIFICATION_LIST_UPDATE, notificationId);
        this.setState({
            latestNotifications: notifications,
            unreadNotificationCount,
            read: unreadNotificationCount === 0
        })
    }

    async _removeNotification(notificationId) {
        this.setState(prevState => ({
            newNotifications: prevState.newNotifications.filter(n => n.id !== notificationId)
        }));
    }

    _handleNotificationEvent(event) {
        if (event) {
            event = JSON.parse(event);

            this.setState(prevState => {
                let newNotifications = [];
                // If notification dropdown is shown, do not show popup notifications
                if (!prevState.showNotificationsDropdown) {
                    newNotifications = prevState.newNotifications.slice(0, 3);
                    newNotifications.unshift(new Notification(event));
                }

                return {
                    read: false,
                    newNotifications,
                    latestNotifications: [new Notification(event), ...prevState.latestNotifications],
                    unreadNotificationCount: prevState.unreadNotificationCount + 1
                };
            });
        }
    }

    _handleDropdownClose() {
        this.setState({ showNotificationsDropdown: false });
    }

    render() {
        const className = "notification-menu " + NotificationMenu.notificationStateClass(this.state.notificationDropdownOpen, this.state.read);
        let notificationPane = (
            <NotificationPane
                notifications={ this.state.latestNotifications }
                unreadNotificationCount={ this.state.unreadNotificationCount }
                onNotificationRead={ this._setNotificationRead.bind(this) }
                onDropdownClose={ this._handleDropdownClose.bind(this) }
                scrolledToTop={ !this.state.showNotificationsDropdown }
            />
        );

        const notificationIcon = this.state.read ? notificationIconLight : notificationIconSolid;
        return (
            <div className={ className }>
                <Dropdown
                    id="notification-dropdown"
                    direction="bottom-left"
                    show={ this.state.showNotificationsDropdown }
                    onClose={ this._handleDropdownClose.bind(this) }
                    trigger={(
                        <a href="#notification-dropdown"
                           className="notification-dropdown-link"
                           onClick={ () => this.setState({ showNotificationsDropdown: true, newNotifications: [] }) }>
                            <FontAwesomeIcon icon={ notificationIcon } className="icon" />
                            { !this.state.read && <div className="red-dot-indicator" /> }
                        </a>
                    )}
                >
                    { notificationPane }
                </Dropdown>
                <NotificationOverlay
                    notifications={ this.state.newNotifications }
                    onNotificationTimeout={ this._removeNotification.bind(this) }
                    onNotificationClose={ this._removeNotification.bind(this) }
                    onNotificationRead={ this._setNotificationRead.bind(this) }
                />
            </div>
        );
    }
}
