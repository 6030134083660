import { NavLink } from 'react-router-dom';
import PubSub from 'pubsub-js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell as bell } from "@fortawesome/pro-solid-svg-icons";

import AuthenticationService from 'service/AuthenticationService';
import WSComponent from 'component/WSComponent';
import Separator from 'component/Separator';
import RestService from 'service/RestService';
import Notification from 'model/Notification'
import NotificationItem from 'component/notifications/NotificationItem';
import Pagination from 'component/navigation/Pagination';
import { USER_ROLES_MAPPING, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'common/constants';
import { NOTIFICATION_TOPICS } from 'common/PubSubTopics';

import './NotificationList.scss';

export default class NotificationList extends WSComponent {
    notificationListUpdateSubToken = null;

    state = {
        page: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        available: 0,
        notifications: [],
        unreadNotificationCount: 0,
        read: false,
        account: null
    }

    constructor(props) {
        super(props);
        this.subscriptions = [
            {
                topic: '/topic/notifications',
                handler: (event) => this._handleNotificationEvent(event)

            },
            {
                topic: '/user/queue/notifications',
                handler: (event) => this._handleNotificationEvent(event)
            }
        ];
        this._handleNotificationEvent = this._handleNotificationEvent.bind(this);
        this._getAccount = this._getAccount.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.notificationListUpdateSubToken = PubSub.subscribe(NOTIFICATION_TOPICS.NOTIFICATION_LIST_UPDATE, this._handleNotificationEvent);
        this._fetchNotifications();
        this._getAccount();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.notificationListUpdateSubToken);
        super.componentWillUnmount();
    }

    componentDidUpdate(_prevProps, prevState) {
        if (this.state.page !== prevState.page || this.state.pageSize !== prevState.pageSize) {
            this._fetchNotifications();
        }
    }

    _handleNotificationEvent(event) {
        if (event) {
            this._fetchNotifications();
        }
    }

    async _fetchNotifications() {
        RestService.instance().get("notifications", {
            pageNumber: this.state.page,
            pageSize: this.state.pageSize
        }).then(details => {
            this.setState({
                notifications: details.data.map(resp => new Notification(JSON.parse(resp.notification.metadata.payload), resp.seen)),
                available: details.available,
                time: Date.now()
            }, () => {
                RestService.instance().get("notifications/count", {
                    seen: false
                }).then(count => {
                    this.setState({
                        unreadNotificationCount: count,
                        read: count === 0
                    });
                });
            });
        });
    }

    async markAllRead() {
        await RestService.instance().post("notifications/see");
        let notifications = this.state.notifications || [];
        notifications.forEach(n => n.seen = true);

        PubSub.publish(NOTIFICATION_TOPICS.NOTIFICATION_MENU_UPDATE, null);
        this.setState({
            notifications,
            read: true
        });
    }

    async _seenNotification(id) {
        await RestService.instance().post("notifications/see?notificationId=" + id, { });
        PubSub.publish(NOTIFICATION_TOPICS.NOTIFICATION_MENU_UPDATE, id);
        let notifications = this.state.notifications || [];
        (notifications.find(n => n.id === id) || {}).seen = true;
        this.setState({
            notifications,
            unreadNotificationCount: this.state.unreadNotificationCount - 1,
            read: this.state.unreadNotificationCount - 1 === 0
        })
    }

    async _getAccount() {
        AuthenticationService.instance().getAccount()
            .then(account => this.setState({ account }))
    }

    _getNotificationPathname(notification) {
        const { account } = this.state;
        const load = notification.load;
        let pathname = '';
        if (load && (account.roles || []).indexOf(USER_ROLES_MAPPING.CARRIER) !== -1) {
            pathname =  `/load/${ load.businessId }/auction/${ notification.auctionId }`
        } else if (load && (account.roles || []).indexOf(USER_ROLES_MAPPING.BROKER) !== -1) {
            pathname =  `/load/${ load.businessId }`;
        } else if (!load) {
            pathname =  `/carrier/${ account.carrierId }/documents`;
        }

        return pathname;
    }

    render() {
        const { notifications } = this.state;
        let content;

        if ((notifications || []).length === 0) {
           content = (
                <div className="no-notifications">
                    <FontAwesomeIcon icon={ bell } className="bell-icon" />
                    <h5>No Notifications</h5>
                    <p>We currently don't have any notifications to show on this screen.</p>
                </div>
           );
        } else {
            const notificationCards = notifications.map((n, index) => (
            <span key={ n.id }>
                <NavLink to={ this._getNotificationPathname(n) } className="notification-card-link" key={ n.id }>
                <NotificationItem
                    key={ n.id }
                    id={ n.id }
                    embedded={ true }
                    notification={ n }
                    onSeen={ this._seenNotification.bind(this) }
                />
                </NavLink>
                { n.seen && (index !== this.state.notifications.length - 1 ) && <Separator /> }
            </span>
            ));

            content = (
                <div className="component notification-list">
                    { notificationCards }
                </div>
            );
        }

        const pagination = (
            <Pagination
                pageNumber={ this.state.page }
                pageSize={ this.state.pageSize }
                available={ this.state.available }
                onSetPage={ page => this.setState({ page }) }
                onSetPageSize={ pageSize => this.setState({ pageSize }) }
            />
        );

        let markAllReadAction;
        if (this.state.read) {
            markAllReadAction = (
                <sup className={ 'mark-read-link col-4 disabled' } onClick={ this.markAllRead.bind(this) }>
                    Mark All as Read
                </sup>
            );
        } else {
            const unreadNotificationCount = this.state.unreadNotificationCount;
            markAllReadAction = (
                <sup className="mark-read-link col-4">
                    <span className="link" onClick={ this.markAllRead.bind(this) }>
                        Mark All as Read { ("(" + unreadNotificationCount + ")") }
                    </span>
                </sup>
            );
        }

        return (
            <div className="page notification-page">
                <div className="row">
                    <h5 className="page-title col-8">
                        Notifications
                    </h5>
                    { markAllReadAction }
                </div>
                { content }
                { pagination }
            </div>
        );
    }
}
