import { Component } from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'component/InfiniteScroll';
import Separator from 'component/Separator';
import LabeledSeparator from 'component/LabeledSeparator';
import ActivityRow from 'component/activity/ActivityRow';
import DateUtils from 'utils/DateUtils';

export default class ActivityLogInfiniteScroll extends Component {
    static propTypes = {
        activities: PropTypes.array,
        hasMore: PropTypes.bool,
        isLoading: PropTypes.bool,
        fetchMoreData: PropTypes.func,
        includeLabeledSeparator: PropTypes.bool,
        hasFirstSeparator: PropTypes.bool,
        className: PropTypes.string,
        returnToTop: PropTypes.bool
    }

    static defaultProps = {
        activities: [],
        hasMore: false,
        isLoading: false,
        fetchMoreData: () => { /* */ },
        includeLabeledSeparator: true,
        hasFirstSeparator: true,
        className: '',
        returnToTop: false
    };

    _formRowLabel(dateCreated) {
        const dayDifference = DateUtils.getDaysDifference(new Date(dateCreated), new Date());

        switch(dayDifference) {
            case 0:
                return 'Today';
            case 1: 
                return 'Yesterday';
            default:
                return DateUtils.formatDate(dateCreated);       
        }
    }

    render() {
        let timeLabel = '';
        const activityRows = this.props.activities.map((activity, index) => {
            let separator = <></>;
            if (this.props.hasFirstSeparator || index !== 0) {
                const label = this._formRowLabel(activity.created);
                if (timeLabel === label || !this.props.includeLabeledSeparator) {
                    separator = <Separator />;
                } else {
                    timeLabel = label;
                    separator = <LabeledSeparator label={ label } />;
                }
            }

            return (
                 <div key={ activity.id }>
                    { separator }
                    <ActivityRow activity={ activity } key={ activity.id } />
                 </div>
            );     
        })

        return (
            <InfiniteScroll
                fetchMoreData={ this.props.fetchMoreData } 
                hasMore={ this.props.hasMore }
                isLoading={ this.props.isLoading }
                className={ this.props.className }
                returnToTop={ this.props.returnToTop }
                endMessage="You have reached the end of the activity log."
            >
                { activityRows }
            </InfiniteScroll>
        );
    }
}
