import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import { RFP_AUCTION_STATUSES, RG_DETAILS_VIEW_MODE } from 'common/constants';
import Separator from 'component/Separator';
import TitledCardComponent from 'component/card/TitledCardComponent';
import Button from 'component/Button';
import Popup from 'component/Popup';
import RFPLaneStatus from 'component/rfp/RFPLaneStatus';
import LaneVisualization from 'component/lane/LaneVisualization';
import RestService from 'service/RestService';
import LaneUtils from 'utils/LaneUtils';
import NumberUtils from 'utils/NumberUtils';
import RoutingGuideUtils from 'utils/RoutingGuideUtils';
import LaneCarrierApiService from 'service/api/LaneCarrierApiService';
import TenderCandidateApiService from 'service/api/TenderCandidateApiService';

export default class RFPLaneCardManagementContent extends Component {

    constructor(props) {
        super(props);

        this._onDeleteLane = this._onDeleteLane.bind(this);
        this._onOpenRemoveLanePopup = this._onOpenRemoveLanePopup.bind(this);
        this._onCloseRemoveLanePopup = this._onCloseRemoveLanePopup.bind(this);
    }

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        viewMode: PropTypes.string,
        rfpLane: PropTypes.object.isRequired,
        onUpdated: PropTypes.func
    };

    static defaultProps = {
        viewMode: null,
        onUpdated: () => {/**/}
    };

    state = {
        showRemoveLanePopup: false,
        dedicatedCarriersCount: null,
        spotMarketCarriersCount: null
    };

    componentDidMount() {
        if (!RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            this._fetchDedicatedCarriersCount();
            this._fetchSpotMarketCarriersCount();
        }
    }

    _fetchDedicatedCarriersCount() {
        TenderCandidateApiService.getCount(this.props.rfp.id, this.props.rfpLane.id)
            .then(result => this.setState({ dedicatedCarriersCount: result }))
            .catch(error => console.error('An error occurred while fetching dedicated carriers count.', error));
    }

    _fetchSpotMarketCarriersCount() {
        LaneCarrierApiService.count(this.props.rfpLane.lane.id, false)
            .then(result => this.setState({
                spotMarketCarriersCount: result
            }));
    }

    _onDeleteLane() {
        RestService.instance()
            .delete(`auction/rfp/${ this.props.rfp.id }/lane/${ this.props.rfpLane.id }`)
            .then(this.props.onUpdated);
    }

    _onOpenRemoveLanePopup() {
        this.setState({ showRemoveLanePopup: true });
    }

    _onCloseRemoveLanePopup() {
        this.setState({ showRemoveLanePopup: false });
    }

    _formRemoveLaneConfirmation() {
        if (RFP_AUCTION_STATUSES.PENDING !== this.props.rfp.status) {
            return <></>;
        }

        return (
            <Popup
                id={ `remove-rfp-lane-popup-${ this.props.rfpLane.id }` }
                closeButton={ false }
                show={ this.state.showRemoveLanePopup } 
                onClose={ this._onCloseRemoveLanePopup }
                trigger={
                    <Button id={ `remove-rfp-lane-popup-button-${ this.props.rfpLane.id }` }
                            type="tertiary"
                            size="small"
                            leftIcon={ faTrash }
                            onClick={ this._onOpenRemoveLanePopup } />
                }
            >
                <div className="remove-rfp-lane-popup">
                    <p className="heading">Remove Lane</p>
                    <p className="description">Are you sure you want to remove lane <b>{ LaneUtils.formatRegion(this.props.rfpLane.lane.origin) }</b> to <b>{ LaneUtils.formatRegion(this.props.rfpLane.lane.destination) }</b> from <b>{ this.props.rfp.name }</b>?</p>
                    <div className="buttons-wrapper">
                        <Button
                            id={ `rfp-delete-lane-cancel-button-${ this.props.rfpLane.id }` }
                            type="tertiary" 
                            size="small"
                            onClick={ this._onCloseRemoveLanePopup }
                        >
                            Cancel
                        </Button>

                        <Button
                            id={ `rfp-delete-lane-button-${ this.props.rfpLane.id }` }
                            type="danger" 
                            size="small"
                            onClick={ this._onDeleteLane }
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        let details, laneDetailsUrl;

        if (RoutingGuideUtils.isRFPView(this.props.viewMode, this.props.rfp.status)) {
            laneDetailsUrl = `/rfp/${ this.props.rfp.id }/lanes/${ this.props.rfpLane.id }`;

            details = (
                <>
                    <TitledCardComponent id={ `rfp-lane-card-bids-${ this.props.rfpLane.id }` }
                                         title="Bids"
                                         align="right"
                                         className="rfp-lane-card-column-container participation">
                        <strong><b>{ (this.props.rfpLane.participation || {}).bids }<span className="light">/{ (this.props.rfpLane.participation || {}).carriers }</span></b></strong>
                    </TitledCardComponent>

                    <Separator orientation="vertical" />

                    <TitledCardComponent id={ `rfp-lane-card-volume-${ this.props.rfpLane.id }` }
                                         title="Lane Vol."
                                         align="right"
                                         className="rfp-lane-card-column-container lane-vol">
                        <strong><b>{ this.props.rfpLane.volume }</b></strong>
                    </TitledCardComponent>

                    <Separator orientation="vertical" />

                    <TitledCardComponent id={ `rfp-lane-card-awarded-volume-${ this.props.rfpLane.id }` }
                                         title="Awarded Vol."
                                         align="right"
                                         className="rfp-lane-card-column-container awarded-vol">
                        <strong><b>{ this.props.rfpLane.awardedVolume }<span className="light">/{ this.props.rfpLane.volume }</span></b></strong>
                    </TitledCardComponent>

                    <Separator orientation="vertical" />

                    <RFPLaneStatus id={ `rfp-lane-card-status-${ this.props.rfpLane.id }` }
                                   rfp={ this.props.rfp }
                                   rfpLane={ this.props.rfpLane }
                                   variant="manager"
                                   className="rfp-lane-card-column-container bid-status" />
                </>
            );
        } else {
            laneDetailsUrl = `/lane/${ this.props.rfpLane.id }`;

            let bookNowPrice = '-';
            if (this.props.rfpLane.bookNowPrice) {
                bookNowPrice = NumberUtils.prefixNumber(this.props.rfpLane.bookNowPrice, '$', false, 'decimal');
            }

            details = (
                <>
                    <TitledCardComponent id={ `rfp-lane-card-dedicated-carriers-count-${ this.props.rfpLane.id }` }
                                         title="Dedicated Carriers"
                                         align="right"
                                         className="rfp-lane-card-column-container rfp-lane-card-dedicated-carriers-count">
                        <strong><b>{ this.state.dedicatedCarriersCount }</b></strong>
                    </TitledCardComponent>

                    <Separator orientation="vertical" />

                    <TitledCardComponent id={ `rfp-lane-card-spot-market-carriers-count-${ this.props.rfpLane.id }` }
                                         title="SM Carriers"
                                         align="right"
                                         className="rfp-lane-card-column-container rfp-lane-card-spot-market-carriers-count">
                        <strong><b>{ this.state.spotMarketCarriersCount }</b></strong>
                    </TitledCardComponent>

                    <Separator orientation="vertical" />

                    <TitledCardComponent id={ `rfp-lane-card-book-now-price-${ this.props.rfpLane.id }` }
                                         title="Book Now Price"
                                         className="rfp-lane-card-column-container rfp-lane-card-book-now-price">
                        <strong><b>{ bookNowPrice }</b></strong>
                    </TitledCardComponent>
                </>
            );
        }
        
        return (
            <>
                <div className="rfp-lane-card-visualization-container">
                    <LaneVisualization lane={ this.props.rfpLane } labeled={ true } />
                </div>

                <Separator orientation="vertical" />

                { details }

                <div className="rfp-lane-card-column-container actions">
                    { this._formRemoveLaneConfirmation() }

                    <Link 
                        id={ `rfp-lane-card-details-button-${ this.props.rfpLane.id }` } 
                        to={{ pathname: laneDetailsUrl, search: RG_DETAILS_VIEW_MODE.RFP_READ_ONLY === this.props.viewMode ? '?mode=read_only' : '' }}
                    >
                        <Button type="tertiary" size="small">Lane Details</Button>
                    </Link>
                </div>
            </>
        );
    }
}
