import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch as searchIcon } from '@fortawesome/pro-solid-svg-icons';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, SORT_DIRECTIONS } from 'common/constants';
import CanAccess from 'component/CanAccess';
import AddNewMember from 'component/carrier/AddNewMember';
import MemberTableRow from 'component/carrier/MemberTableRow';
import Search from 'component/filter/Search';
import Select from 'component/form/CustomSelect';
import Pagination from 'component/navigation/Pagination';
import Table from 'component/table/custom//Table';
import TableRow from 'component/table/custom/TableRow';
import TableHeaderCell from 'component/table/custom/TableHeaderCell';
import CarrierApiService from 'service/api/CarrierApiService';
import UserApiService from 'service/api/UserApiService';

import './MembersView.scss';

const STATUS_VALUES = [
    { title: 'All', field: null },
    { title: 'Active', field: 'ACTIVE' },
    { title: 'Removed', field: 'REMOVED' },
    { title: 'Pending', field: 'PENDING' }
];

const SORT_OPTIONS = {
    NAME: 'NAME',
    DATE_ADDED: 'DATE_ADDED'
};

class MembersView extends Component {
    static propTypes = {
        carrier: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this._onSetPageNumber = this._onSetPageNumber.bind(this);
        this._onSetPageSize = this._onSetPageSize.bind(this);
        this._onSelectStatus = this._onSelectStatus.bind(this);
        this._fetchMembers = this._fetchMembers.bind(this);
    }

    state = {
        members: [],
        selectedStatus: null,
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        available: 0
    };

    componentDidMount() {
        this._fetchMembers();
        this._populateFromURL();
    }

    componentDidUpdate(prevProps, prevState) {
        const previousSearch = this._getParamFromUrl(prevProps, 'memberSearch');
        const search = this._getParamFromUrl(this.props, 'memberSearch');

        const previousStatus = this._getParamFromUrl(prevProps, 'status');
        const status = this._getParamFromUrl(this.props, 'status');

        const previousSort = this._getParamFromUrl(prevProps, 'sort');
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (prevState.pageNumber !== this.state.pageNumber 
            || prevState.pageSize !== this.state.pageSize
            || previousSearch !== search
            || previousStatus !== status
            || previousSort !== sort) {
            this._fetchMembers();
        }
    }

    _onSetPageNumber(pageNumber) {
        this.setState({ pageNumber });
    }

    _onSetPageSize(pageSize) {
        this.setState({ pageSize });
    }

    _getParamFromUrl(props, param) {
        const searchProp = props.location?.search;
        return new URLSearchParams(searchProp).get(param);
    }

    _populateFromURL() {
        const urlStatus = this._getParamFromUrl(this.props, 'status');
        const selectedStatus = STATUS_VALUES.filter(status => status.field === urlStatus).map(s => s.title);

        this.setState({ selectedStatus });
    }

    _onSelectStatus(_value, status) {
        if (status) {
            status = JSON.parse(status.key);
            const searchParams = new URLSearchParams(this.props.location?.search);
            
            this.setState({
                selectedStatus: status.title
            });

            if ('All' === status.title) {
                searchParams.delete('status');
            } else {
                searchParams.set('status', status.field);
            }

            this.props.history.push({
                pathname: window.location.pathname,
                search: '?' + searchParams.toString()
            });
        }
    }

    _onSort(value, direction) {
        const searchParams = new URLSearchParams(this.props.location?.search);

        if (SORT_DIRECTIONS.NONE === direction) {
            searchParams.delete('sort');
        } else {
            searchParams.set('sort', `${ value },${ direction }`);
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: '?' + searchParams.toString()
        });
    }

    _getSortDirection(value) {
        const sort = this._getParamFromUrl(this.props, 'sort');

        if (!sort) {
            return SORT_DIRECTIONS.NONE;
        }

        const sortArray = sort.split(',');

        if (sortArray[0] !== value) {
            return SORT_DIRECTIONS.NONE;
        }

        return sortArray[1];
    }


    _fetchMembers() {
        const memberSearch = this._getParamFromUrl(this.props, 'memberSearch');
        const status = this._getParamFromUrl(this.props, 'status');
        const sort = this._getParamFromUrl(this.props, 'sort');

        CarrierApiService.getMembers(this.props.carrier.id, memberSearch, status, this.state.pageNumber, this.state.pageSize, sort ? [sort, ""] : null)
            .then(members => this.setState({
                members: members.data,
                available: members.available
            }));
    }

    _removeMember(memberId) {
        CarrierApiService.removeMember(this.props.carrier.id, memberId)
            .then(this._fetchMembers)
            .catch(error => console.error(error.message));
    }

    _reactivateMember(memberId) {
        UserApiService.activate(memberId)
            .then(this._fetchMembers)
            .catch(error => console.error(error.message));
    }

    _formMembersActions() {
        return (
            <div className="actions-container">
                <div className="action-field search">
                    <Search placeholder="Search by User name, email..." label="Search" fieldName="memberSearch" allowClear={ true } replace={ false } />
                </div>
                <div className="action-field status">
                    <Select
                        selectedValue={ this.state.selectedStatus || 'All' }
                        onSelect={ this._onSelectStatus }
                        values={ STATUS_VALUES }
                        label="Status"
                    />
                </div>
                <CanAccess
                    action="carrier:write" 
                    yes={
                        <div className="action-field add">
                            <AddNewMember carrier={ this.props.carrier } onAddMembers={ this._fetchMembers } />
                        </div>  
                    }
                />
            </div>    
        )
    }

    _formMembersTable() {
        const membersRows = this.state.members.map(member =>  
            <MemberTableRow 
                member={ member } 
                key={ member.id } 
                removeMember={ () => this._removeMember(member.id) }
                reactivateMember={ () => this._reactivateMember(member.id) } 
            />
        );

        return (
                <Table className="members-table">
                    <TableRow isHeader={ true } className="members-header-row">
                        <TableHeaderCell 
                            className="name" 
                            sortable={ true }
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.NAME, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.NAME) }>
                                Name
                        </TableHeaderCell>
                        <TableHeaderCell className="email">Email</TableHeaderCell>
                        <TableHeaderCell className="phone">Phone</TableHeaderCell>
                        <TableHeaderCell className="member-status">Status</TableHeaderCell>
                        <TableHeaderCell 
                            className="date-added"
                            sortable={ true }
                            onSort={ (direction) => this._onSort(SORT_OPTIONS.DATE_ADDED, direction) }
                            sortDirection={ this._getSortDirection(SORT_OPTIONS.DATE_ADDED) }>
                                Date Added
                        </TableHeaderCell>
                        <CanAccess
                            action="carrier:write" 
                            yes={ <TableHeaderCell>Actions</TableHeaderCell> }
                        />
                    </TableRow>
                    { membersRows }
                </Table>
        );
    }

    _formEmptyState() {
        return (
            <div className="members-empty-state">
                <div className="empty-state-icon">
                    <FontAwesomeIcon icon={ searchIcon } className="icon" />
                </div>
                <strong className="empty-state-title">No members found</strong>
                <p>No results matched your search criteria.</p>
            </div>
        );
    }

    render() {
        const pagination = (
            <Pagination
                pageNumber={ this.state.pageNumber }
                pageSize={ this.state.pageSize }
                available={ this.state.available }
                onSetPage={ this._onSetPageNumber }
                onSetPageSize={ this._onSetPageSize }
                fixed={ false }
                colorTheme="light"
            />
        );

        return (
            <div className='members-view'>
                { this._formMembersActions() }
                { this.state.available !== 0 ? this._formMembersTable() : this._formEmptyState() }
                { pagination }
            </div>
        );    
    }
}

export default withRouter(MembersView);
