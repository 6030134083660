import { Component } from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

export default class ProgressBar extends Component {

    static propTypes = {
        currentValue: PropTypes.number.isRequired,
        totalValue: PropTypes.number.isRequired,
        staticColor: PropTypes.bool
    }

    static defaultProps = {
        staticColor: true
    }

    _calculatePercentage() {
        const percentage = (this.props.currentValue / this.props.totalValue) * 100;
        return percentage > 100 ? 100 : percentage;
    }

    render() {
        const percentage = this._calculatePercentage();
        let progress;

        if (this.props.staticColor) {
            progress = 'static';
        } else if (percentage >= 90) {
            progress = 'high';
        } else if (percentage >= 75) {
            progress = 'medium';
        } else {
            progress = 'low';
        }

        return (
            <div className="progress-bar-container">
                <div className={ `progress-bar ${ progress }` } style={{ width: percentage + '%' }} />
            </div>
        ); 
    }
}
