import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { USER_ROLES_MAPPING, RFP_AUCTION_STATUSES } from 'common/constants';
import CanAccess from 'component/CanAccess';
import TitledCardComponent from 'component/card/TitledCardComponent';
import LaneVisualization from 'component/lane/LaneVisualization';
import CargoTypeComponent from 'component/lane/CargoTypeComponent';
import EditLaneBookNowPricePopup from 'component/lane/EditLaneBookNowPricePopup';
import Tooltip from 'component/Tooltip';
import RoutingGuideLaneNotificationApiService from 'service/api/RoutingGuideLaneNotificationApiService';
import NumberUtils from 'utils/NumberUtils';

import './LaneHeaderCard.scss';

export default class LaneHeaderCard extends Component {

    static propTypes = {
        account: PropTypes.object.isRequired,
        routingGuideLane: PropTypes.object.isRequired,
        numberOfDedicatedCarriers: PropTypes.number,
        numberOfSpotMarketCarriers: PropTypes.number
    };

    static defaultProps = {
        numberOfDedicatedCarriers: 0,
        numberOfSpotMarketCarriers: 0
    }

    state = {
        subscribed: null
    }

    componentDidMount() {
        this._fetchSubscription();
    }

    _fetchSubscription() {
        const canBeSubscribed = this.props.account.roles.includes(USER_ROLES_MAPPING.BROKER);

        if (canBeSubscribed) {
            RoutingGuideLaneNotificationApiService.isSubscribed(this.props.routingGuideLane.id).then(subscribed => this.setState({ subscribed }));
        }
    }

    render() {
        const { routingGuideLane } = this.props;
        const suggestedPrice = (routingGuideLane.suggestedPrice || routingGuideLane.suggestedPrice === 0) ? '$' + NumberUtils.formatWithDecimalNotation(routingGuideLane.suggestedPrice) : '-';
        const bookNowPrice = (routingGuideLane.bookNowPrice ? '$' + NumberUtils.formatWithDecimalNotation(routingGuideLane.bookNowPrice) : '-');
        const rgName = this.props.routingGuideLane.rfpAuction.name;
        
        let notificationsSubscription = <></>;
        if (this.state.subscribed !== null) {
            notificationsSubscription = (
                <TitledCardComponent type="small" align="right" title="NOTIFICATIONS" className="lane-header-card-component notifications">
                    <strong className={ this.state.subscribed ? '' : 'not-subscribed' }><b>
                        { this.state.subscribed ? 'On' : 'Off' }
                    </b></strong>
                    <div className="icon-div">
                        <FontAwesomeIcon icon={ info } className="icon" />
                        <Tooltip direction="bottom-left">
                            { this.state.subscribed ? 'You are assigned to receive notifications for loads on this lane.' : 'You are not assigned to receive notificatons for loads on this lane. Contact your administrator for further changes.' }
                        </Tooltip>
                    </div>
                </TitledCardComponent>
            );
        }

        return (
            <div className="lane-header-card-container">
                <div className="component lane-header-card">
                    <div className="lane-header-card-title-row">
                        <LaneVisualization lane={ routingGuideLane } size="large" className="neutral" labeled={ true } />
                    </div>
                    <div className="lane-header-card-details-row">
                        <div className="lane-header-card-details-row-group">
                            <CargoTypeComponent type={ routingGuideLane.lane.type } />

                            <TitledCardComponent type="small" title="ROUTING GUIDE" className="lane-header-card-component rg-name">
                                <strong><b title={ rgName }>{ rgName }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="left" title="SUGGESTED PRICE" className="lane-header-card-component">
                                <strong><b>{ suggestedPrice }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="left" title="BOOK NOW PRICE" className="lane-header-card-component">
                                <div className="book-now-price">
                                    <strong><b>{ bookNowPrice }</b></strong>
                                    <CanAccess 
                                        action="routing-guide:write"
                                        yes={ RFP_AUCTION_STATUSES.COMPLETED !== routingGuideLane.rfpAuction.status && 
                                                <EditLaneBookNowPricePopup routingGuideLane={ routingGuideLane } /> 
                                        }
                                    />
                                </div>
                            </TitledCardComponent>
                        </div>
                        <div className="lane-header-card-details-row-group">
                            <TitledCardComponent type="small" align="right" title="DEDICATED CARRIERS" className="lane-header-card-component">
                                <strong><b>{ this.props.numberOfDedicatedCarriers }</b></strong>
                            </TitledCardComponent>

                            <TitledCardComponent type="small" align="right" title="SM CARRIERS" className="lane-header-card-component">
                                <strong><b>{ this.props.numberOfSpotMarketCarriers }</b></strong>
                            </TitledCardComponent>

                            { notificationsSubscription }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
