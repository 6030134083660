import { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import MultipleSelect from "./CustomMultipleSelect";
import CustomCheckbox from './CustomCheckbox';
import Select from './CustomSelect';

import './CustomCascadeSelect.scss';

export default class CustomCascadeSelect extends Component {
    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        withSearch: PropTypes.bool,
        allowClear: PropTypes.bool,
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        values: PropTypes.arrayOf(PropTypes.object),
        multiple: PropTypes.bool,
        fieldName: PropTypes.string,
        direction: PropTypes.oneOf(['top', 'bottom']),
        placeholder: PropTypes.string,
        size: PropTypes.oneOf(['small', 'regular']),
        onSearch: PropTypes.func,
        onSelect: PropTypes.func,
        onUnselect: PropTypes.func,
        onClear: PropTypes.func
    }

    static defaultProps = {
        id: undefined,
        label: undefined,
        withSearch: false,
        allowClear: false,
        selectedValue: [],
        placeholder: 'Select',
        values: [],
        multiple: false,
        fieldName: 'title',
        direction: 'bottom',
        size: 'regular',
        onSearch: () => { /* */ },
        onSelect: () => { /* */ },
        onUnselect: () => { /* */ },
        onClear: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this._onSelect = this._onSelect.bind(this);
        this._onUnselect = this._onUnselect.bind(this);
        this._onClear = this._onClear.bind(this);
        this._dropdownRender = this._dropdownRender.bind(this);
    }

    state = {
        selectedKeys: []
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedValue !== this.props.selectedValue && this.props.selectedValue.length === 0 && this.state.selectedKeys.length !== 0) {
            this.setState({ selectedKeys: [] });
        }
    }

    _onSelect(selectItem) {
        if (selectItem) {
            this.props.onSelect(selectItem.key);
            this.setState(prevState => {
                return { selectedKeys: prevState.selectedKeys.concat(selectItem.key) };
            });
        }
    }

    _onUnselect(unselectItem) {
        if (unselectItem) {
            this.props.onUnselect(unselectItem.key);
            this.setState(prevState => {
                const selectedKeys = [ ...prevState.selectedKeys ];
                const index = selectedKeys.findIndex(type => type === unselectItem.key);
    
                if (index > -1) {
                    selectedKeys.splice(index, 1);
                }
    
                return { selectedKeys };
            });
        }
    }

    _onClear() {
        this.setState({ selectedKeys: [] });
        this.props.onClear()
    }

    _customValueMapper(value) {
        if (this.props.multiple) {
            return <CustomCheckbox 
                text={ value.label || value[this.props.fieldName] } 
                checked={ this.props.selectedValue.includes(value.label || value[this.props.fieldName]) } 
                preventDefaultAction={ true }
            />;
        }

        return value.label || value[this.props.fieldName];
    }

    _formMenuItem(option) {
        if (option.children) {
            return (
                <Menu.SubMenu title={ option.label } key={ option.value }>
                    { option.children.map(child => {
                        return this._formMenuItem(child);
                    })}
                </Menu.SubMenu>
            );
        }

        return (
            <Menu.Item key={ option.value }>
                { this._customValueMapper(option) }
            </Menu.Item>
        );
    }

    _formMenu() {
        return (
            <Menu selectable={ true } multiple={ this.props.multiple } selectedKeys={ this.state.selectedKeys } onSelect={ this._onSelect } onDeselect={ this._onUnselect }>
                { this.props.values.map(option => {
                    return this._formMenuItem(option);
                })}
            </Menu>
        );
    }

    _dropdownRender() {
        return this._formMenu();
    }

    render() {
        if (this.props.multiple) {
            return (
                <MultipleSelect
                    id={ this.props.id }
                    label={ this.props.label }
                    multiple={ true }
                    withSearch={ this.props.withSearch }
                    allowClear={ this.props.allowClear }
                    selectedValue={ this.props.selectedValue }
                    values={ this.props.values }
                    placeholder={ this.props.placeholder }
                    fieldName={ this.props.fieldName }
                    direction={ this.props.direction }
                    size={ this.props.size }
                    onSearch={ this.props.onSearch }
                    customDropdownRender={ this._dropdownRender }
                    onSelect={ this._onSelect }
                    onUnselect={ this._onUnselect }
                    onClear={ this._onClear }
                    customValueMapper={this._valueMapper }
                    customTagRenderer={ this._tagRenderer }
                    maxTagCount={ 1 }
                    maxTagPlaceholder={ (this.props.selectedValue.length).toString() }
                />
            );    
        }

        return (
            <Select
                id={ this.props.id }
                label={ this.props.label }
                multiple={ true }
                withSearch={ this.props.withSearch }
                allowClear={ this.props.allowClear }
                selectedValue={ this.props.selectedValue }
                values={ this.props.values }
                placeholder={ this.props.placeholder }
                fieldName={ this.props.fieldName }
                direction={ this.props.direction }
                size={ this.props.size }
                onSearch={ this.props.onSearch }
                customDropdownRender={ this._dropdownRender }
                onSelect={ this._onSelect }
                onUnselect={ this._onUnselect }
                onClear={ this._onClear }
                customValueMapper={this._valueMapper }
                customTagRenderer={ this._tagRenderer }
                maxTagCount={ 1 }
                maxTagPlaceholder={ (this.props.selectedValue.length).toString() }
            />
        );
    }
}
