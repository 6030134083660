import { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from "antd";
import moment from 'moment'

import NumberUtils from "utils/NumberUtils";

import 'antd/dist/antd.css';
import './CustomDateTimePicker.scss'

export default class CustomDateTimePicker extends Component {

    static propTypes = {
        id: PropTypes.string,
        date: PropTypes.instanceOf(moment),
        minDate: PropTypes.instanceOf(moment),
        maxDate: PropTypes.instanceOf(moment),
        dateFormat: PropTypes.string,
        handleChange: PropTypes.func,
        className: PropTypes.string,
        allowClear: PropTypes.bool
    }

    static defaultProps = {
        id: undefined,
        date: null,
        minDate: null,
        maxDate: null,
        className: "",
        allowClear: false,
        dateFormat: "YYYY-MM-DD HH:mm",
        handleChange: () => { /* */ }
    }

    constructor(props) {
        super(props);

        this.disabledDate = this.disabledDate.bind(this);
        this.disabledTime = this.disabledTime.bind(this)
        this.handleChange = this.handleChange.bind(this);
    }

    disabledDate(current) {
        let disabled = !current;
        if (current) {
            if (this.props.minDate) {
                disabled = disabled || this.props.minDate.isAfter(current, 'day')
            }
            if (this.props.maxDate) {
                disabled = disabled || current.isAfter(this.props.maxDate, 'day')
            }
        }
        return disabled;
    }

    disabledTime(current) {
        
        if (current) {
            const minHoursRange = NumberUtils.getRange(0, this.props.minDate.hour() - 1);
            const maxHoursRange = NumberUtils.getRange(this.props.maxDate.hour() + 1, 24);

            const minMinutesRange = hour => (hour === this.props.minDate.hour() ? NumberUtils.getRange(0, this.props.minDate.minutes()) : []);
            const maxMinutesRange = hour => (hour === this.props.maxDate.hour() ? NumberUtils.getRange(this.props.maxDate.minutes() + 1, 60) : []);

            if (current.isSame(this.props.minDate, 'day') && current.isSame(this.props.maxDate, 'day')) {
                return ({
                    disabledHours: () => minHoursRange.concat(maxHoursRange),
                    disabledMinutes: hour => minMinutesRange(hour).concat(maxMinutesRange(hour))
                });
            } else if (current.isSame(this.props.maxDate, 'day')) {
                return ({
                    disabledHours: () => maxHoursRange,
                    disabledMinutes: hour => maxMinutesRange(hour)
                });
            } else if (current.isSame(this.props.minDate, 'day')) {
                return ({
                    disabledHours: () => minHoursRange,
                    disabledMinutes: hour => minMinutesRange(hour)
                });
            }
        }

        return ({
            disabledHours: false,
            disabledMinutes: false
        });
    }

    handleChange(date) {
        this.props.handleChange(date ? date.startOf('minute') : null);
    }

    render() {
        return (
            <DatePicker
                id={ this.props.id }
                showTime={{ format: 'HH:mm' }}
                showNow={ false }
                className={ this.props.className }
                format={ this.props.dateFormat }
                disabledDate={ this.disabledDate }
                disabledTime={ this.disabledTime }
                value={ moment(this.props.date, this.props.dateFormat) }
                defaultValue={ moment(this.props.date, this.props.dateFormat) }
                onSelect={ this.handleChange }
                onChange={ this.handleChange }
                allowClear={ this.props.allowClear }
            />
        );
    }
}
