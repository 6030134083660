import { Component } from 'react';
import PropTypes from 'prop-types';

import { CANDIDATE_TYPE, MAX_PRICE } from 'common/constants';
import CustomNumberInput from 'component/form/CustomNumberInput';
import Button from 'component/Button';
import LoadTermsOfServicePopup from 'component/load/LoadTermsOfServicePopup';
import NumberUtils from 'utils/NumberUtils';
import PriceUtils from 'utils/PriceUtils';

import './BiddingForm.scss';

export default class BiddingForm extends Component {
    static propTypes = {
        auction: PropTypes.object.isRequired,
        distance: PropTypes.number.isRequired,
        bookNowPrice: PropTypes.number,
        negotiatedRate: PropTypes.number,
        lowestBid: PropTypes.object,
        bestCarrierBid: PropTypes.object,
        enableBookNow: PropTypes.bool,
        popupActions: PropTypes.bool,
        version: PropTypes.number,
        onPostBid: PropTypes.func,
        onBookNow: PropTypes.func,
        onCancel: PropTypes.func
    };

    static defaultProps = {
        bookNowPrice: null,
        lowestBid: null,
        negotiatedRate: null,
        bestCarrierBid: null,
        enableBookNow: true,
        popupActions: false,
        version: 0,
        onPostBid: () => { /* */ },
        onBookNow: () => { /* */ },
        onCancel: () => { /* */ }
    };

    static _untouchedState = {
        totalPrice: null,
        validPrice: false,
        formErrorMessage: '',
        totalPriceToShow: null
    };

    static _touchedState = {
        totalPrice: '',
        validPrice: false,
        formErrorMessage: '',
        totalPriceToShow: ''
    };

    state = BiddingForm._untouchedState;

    constructor(props) {
        super(props);
        this._onTotalPriceChange = this._onTotalPriceChange.bind(this);
        this._onInvalidPriceInput = this._onInvalidPriceInput.bind(this);
        this._determineBiddingDecrement = this._determineBiddingDecrement.bind(this);
        this._onAcceptTermsAndConditions = this._onAcceptTermsAndConditions.bind(this);
        this._handlePostBid = this._handlePostBid.bind(this);
        this._onCloseBookNowPopup = this._onCloseBookNowPopup.bind(this);
        this._onOpenBookNowPopup = this._onOpenBookNowPopup.bind(this);
    }

    componentDidMount() {
        this._determineBiddingDecrement();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lowestBid !== this.props.lowestBid) {
            this._determineBiddingDecrement();
        }

        if (prevProps.auction.id !== this.props.auction.id) {
            this._resetToUntouchedState();
        }

        if (prevProps.version !== this.props.version) {
            this._resetToUntouchedState();
        }
    }

    _onCloseBookNowPopup() {
        this.setState({ showBookNowPopup: false });
    }

    _onOpenBookNowPopup() {
        this.setState({ showBookNowPopup: true });
    }

    /**
     * This method determines what bidding decrement will be 
     * For auctions that do not have book now price, decrement will always be $100
     * In case book now price exists and distance between origin and destination is 1000 miles or above, 
     * decrement will have value $100 until it comes $300 above book now price, when it will be changed to $50.
     * In case book now price exists and distance between origin and destination is below 1000 miles,
     * decrement will have value $100 until it comes $200 above book now price, when it will be changed to $50.
     * Decrement will not exist for the first bid or in case the lowest bid if lower or equal to intended decrement
     */
    _determineBiddingDecrement() {
        let decrement = 0;

        if (!!this.props.lowestBid) {
            const bookNowDifference = this.props.distance < 1000 ? 200 : 300;
            const bookNowRange = (this.props.bookNowPrice + bookNowDifference) >= this.props.lowestBid.price;
            decrement = !!this.props.bookNowPrice && bookNowRange ? 50 : 100;

            if (this.props.lowestBid.price <= decrement) {
                decrement = 0;
            }         
        }
        
        this.setState({ decrement });
    }
    
    _resetState() {
        this.setState({ ...BiddingForm._touchedState });
    }

    _resetToUntouchedState() {
        this.setState({ ...BiddingForm._untouchedState });
    }

    /**
     * Handles total price input change and modifies the price input accordingly
     *
     * @param { Number } value
     * @private
     */
    _onTotalPriceChange(value) {
        this.setState({ validPrice: true });

        if (value === null) {
            this._resetToUntouchedState();
            return;
        }

        this.setState({
            totalPrice: value,
            totalPriceToShow: value.toString()
        });
    }

    _onInvalidPriceInput(invalidValue) {
        if (invalidValue <= 0) {
            this.setState({
                formErrorMessage: "It's not possible to bid a price lower than or equal to zero."
            });
        } else if (invalidValue !== "") {
            if (this.props.negotiatedRate && invalidValue > this.props.negotiatedRate) {
                this.setState({
                    formErrorMessage: 'You are not allowed to bid above the negotiated rate.'
                });
            } else {
                let formErrorMessage = 'Please decrease your bid to participate in the tender.';
                if (this.state.decrement) {
                    const errorMessageExplanation = `Your bid has to be lower than the current bid by at least $${ this.state.decrement }. `;
                    formErrorMessage = errorMessageExplanation.concat(formErrorMessage);
                }
                
                this.setState({ formErrorMessage });
            }
        }

        this.setState({ validPrice: false });
    }

    _onAcceptTermsAndConditions() {
        this.props.onBookNow();
        this.setState({ showBookNowPopup: false });
    }

    /**
     * Handles the 'bid' button click
     *
     * @param { Object } event
     * @private
     */
    _handlePostBid(event) {
        event.preventDefault();

        if (!this.state.validPrice) {
            return;
        }

        this.props.onPostBid(this.state.totalPrice);
        this._resetState();
    }

    _formBookNowPopup() {
        const { lowestBid, bookNowPrice, negotiatedRate, auction } = this.props;

        const bestSpotMarketBid = lowestBid && CANDIDATE_TYPE.SPOT_MARKET === lowestBid.candidate.type ? lowestBid : null;

        if (bestSpotMarketBid && bestSpotMarketBid.price <= bookNowPrice) {
            // Remove the book now button if the best spot market bid is better/lower than the book now price
            return <></>;
        }

        const price = NumberUtils.formatWithDecimalNotation(bookNowPrice);

        return (
            <LoadTermsOfServicePopup
                auction={ auction }
                price={ bookNowPrice }
                showPopup={ this.state.showBookNowPopup }
                id="popup-book-now"
                title="Booking Now Load"
                description={
                    <>
                        If you book this load now for <b>${ price }</b> you will secure it and you will become an awarded carrier.
                    </>
                }
                trigger={
                    <Button
                        type="secondary-alt"
                        className="full-width-button"
                        link="#popup-book-now"
                        onClick={ this._onOpenBookNowPopup }
                        disabled={ !!negotiatedRate && negotiatedRate < bookNowPrice }
                    >
                        { `Book Now for $${ price }` }
                    </Button> 
                }
                onClosePopup={ this._onCloseBookNowPopup }
                onActionPerformed={ this._onAcceptTermsAndConditions }
            />
        );
    }

    render() {
        const { lowestBid, bestCarrierBid, bookNowPrice, negotiatedRate, distance, enableBookNow } = this.props;
        const { validPrice, totalPrice, totalPriceToShow, formErrorMessage } = this.state;

        let maxBid = ((lowestBid || {}).price - this.state.decrement) || MAX_PRICE;
        if (negotiatedRate && (!lowestBid || negotiatedRate < lowestBid.price)) {
            maxBid = negotiatedRate;
        }
        
        const bidButtonText = bestCarrierBid ? 'Bid Again' : 'Place a Bid';
        const perMilePriceToShow = totalPrice ? `$${ PriceUtils.formatPerMilePrice(totalPrice, distance) }` : "-";
        const allowMaxLimit = this.state.decrement !== 0 || (!!negotiatedRate && (!lowestBid || negotiatedRate < lowestBid.price));

        let bookNowButton = <></>;
        if (enableBookNow && bookNowPrice) {
            bookNowButton = this._formBookNowPopup();
        }

        let errorMessage = <></>;
        if (!validPrice && (!!totalPrice || totalPrice === 0)) {
            errorMessage = (
                <small className="error-description">
                    { formErrorMessage }
                </small>
            );
        }

        let actions;
        if (this.props.popupActions) {
            actions = (
                <div className="buttons-wrapper">
                    <Button type="tertiary" onClick={ this.props.onCancel }>
                        Cancel
                    </Button>

                    <Button type="secondary" disabled={ !validPrice } onClick={ this._handlePostBid }>
                        { bidButtonText }
                    </Button>
                </div>
            );
        } else {
            actions = (
                <Button
                    type="secondary"
                    className="full-width-button"
                    disabled={ !validPrice }
                    onClick={ this._handlePostBid }
                >
                    { bidButtonText }
                </Button>
            );
        }

        const inputLabel = (
            <small className="input-label">
                <b>Total Price</b><span className="input-label-description"> (Fuel surcharge included)</span>
            </small>
        );

        return (
            <div className="bid-form">
                <div className="inputs">
                    <CustomNumberInput
                        label={ inputLabel }
                        prefix="$"
                        placeholder="0.00"
                        value={ totalPriceToShow }
                        min={ 0 }
                        max={ Number(maxBid) }
                        onChange={ this._onTotalPriceChange }
                        onInvalidInput={ this._onInvalidPriceInput }
                        isValid={ validPrice }
                        allowMaxLimit={ allowMaxLimit }
                    />
                </div>
                <small className="per-mile-description">
                    Per Mile Price: { perMilePriceToShow }
                </small>
                { errorMessage }
                { !this.props.popupActions && actions }
                { bookNowButton }
                <small className="italic-text">
                    * Bidding on this load means you commit to booking this load in case your bid wins.
                </small>
                { this.props.popupActions && actions }
            </div>
        );
    }
}
