import { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentUtils from 'utils/DocumentUtils';
import DocumentCard from 'component/card/DocumentCard';
import Separator from 'component/Separator';

export default class DocumentsList extends Component {

    static propTypes = {
        documents: PropTypes.array,
        account: PropTypes.object
    }

    static defaultProps = {
        documents: null,
        account: null
    }

    state = {
        requiredDocumentTypes: null,
        availableRejectReasons: []
    }

    componentDidMount() {
        this._fetchAvailableRejectReasons();
        this._fetchRequiredDocumentTypes();
    }

    _fetchRequiredDocumentTypes() {
        DocumentUtils.fetchDocumentTypes(true).then(requiredDocumentTypes => {
            this.setState({
                requiredDocumentTypes
            });
        });
    }

    _fetchAvailableRejectReasons() {
        DocumentUtils.fetchAvailableRejectReasons(this.props.account).then(response => {
            this.setState({
                availableRejectReasons: (response || {}).data
            });
        });
    }

    _onDocumentApprove(documentId, expirationDate) {
        DocumentUtils.approveDocument(documentId, expirationDate);
    }

    _onDocumentReject(documentId, rejectReason, note) {
        DocumentUtils.rejectDocument(documentId, rejectReason, note);
    }

    _onDocumentExpirationEdit(documentId, expirationDate) {
        DocumentUtils.editDocumentExpirationDate(documentId, expirationDate);
    }

    _findMissingDocuments() {
        const { documents } = this.props;
        const { requiredDocumentTypes } = this.state;

        if (!requiredDocumentTypes) {
            return [];
        }

        let missingDocuments = [];
        for (const requiredDocumentType of requiredDocumentTypes) {
            let hasDocument = false;
            for (const carrierDocument of documents) {
                if (carrierDocument.type.businessId === requiredDocumentType.businessId) {
                    hasDocument = true;
                    break;
                }
            }

            if (!hasDocument) {
                missingDocuments.push(requiredDocumentType);
            }
        }

        return missingDocuments;
    }

    _flattenDocuments(documents) {
        const flattened = [];

        documents.forEach(document => {
            if (document.relevant) {
                flattened.push({
                    id: document.id,
                    reference: document.reference,
                    storageId: document.storageId,
                    name: document.type.name,
                    required: document.type.required,
                    created: document.created,
                    expiresOn: document.expiresOn,
                    status: document.status,
                    uploadedBy: document.uploadedBy,
                    carrier: document.carrier
                });
            }
        });

        return flattened;
    }

    _formDocumentDisplay(documents) {
        return documents.map((document, index) => {
            return (
                <div key={ document.id + '_' + index }>
                    <DocumentCard
                        account={ this.props.account }
                        document={ document } 
                        availableRejectReasons={ this.state.availableRejectReasons }
                        onDocumentApprove={ (expirationDate) => this._onDocumentApprove(document.id, expirationDate) }
                        onDocumentReject={ (reason, note) => this._onDocumentReject(document.id, reason, note) }
                        onDocumentExpirationEdit={ (expirationDate) => this._onDocumentExpirationEdit(document.id, expirationDate) }
                    />
                    { index !== documents.length - 1 ? <Separator /> : <></> }
                </div>
            )
        });
    }

    render() {
        const { documents } = this.props;
        const missingDocuments = this._findMissingDocuments();
        const flattenedDocuments = this._flattenDocuments(documents);

        const availableDocumentsDisplay = this._formDocumentDisplay(flattenedDocuments);

        let categorySeparator = <></>;
        if (missingDocuments.length > 0 && documents.length > 0) {
            categorySeparator = <Separator />;
        }

        const missingDocumentsDisplay = this._formDocumentDisplay(missingDocuments);

        return (
            <>
                { availableDocumentsDisplay }
                { categorySeparator }
                { missingDocumentsDisplay }
            </>
        );
    }
}
