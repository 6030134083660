import { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import 'antd/dist/antd.css';

export default class CustomLoader extends Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'regular', 'large'])
    }

    static defaultProps = {
        size: 'regular'
    }

    _getSize() {
        if ('regular' === this.props.size) {
            return 'default';
        }

        return this.props.size;
    }

    render() {
        return <Spin size={ this._getSize() } />;
    }
}
