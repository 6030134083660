import RestService from 'service/RestService';
import Auth from 'model/bootstrap/Auth';
import Configuration from 'model/bootstrap/Configuration';

export default class ConfigurationService {

    /**
     * @type {ConfigurationService|null}
     * @private
     */
    static _instance = null;

    /**
     * @type {Configuration|null}
     * @private
     */
    _config = null;

    /**
     * Returns a global instance of the configuration service.
     *
     * @param refresh {boolean} forces a refresh of the service.
     * @returns {ConfigurationService}
     */
    static instance(refresh) {
        if (!this._instance || refresh) {
            this._instance = new ConfigurationService();
        }

        return this._instance;
    }

    /**
     * Invokes the /api/v1/bootstrap endpoint in order to retrieve all dynamic configuration properties.
     *
     * @returns {Promise<void>}
     */
    async init() {
        if (!this._config) {
            const restService = new RestService({
                baseUrl: this.backendRESTv1(),
                authEnabled: false
            });

            this._config = await restService.get('bootstrap')
                .then(response => new Configuration(response))
                .catch(error => {
                    console.warn('Bootstrap request failed.', error);
                    return new Configuration({ auth: { mode: 'DISABLED' } });
                });
        }
    }

    isInitialized() {
        return !!this._config;
    }

    backendWS() {
        return '/ws';
    }

    backendREST() {
        return '/api';
    }

    backendRESTv1() {
        return this.backendREST() + '/v1';
    }

    backendDownload() {
        return '/static/managed';
    }

    authenticationConfig() {
        return new Auth(this._config.auth);
    }

    pendoApiKey() {
        return this._config.pendoApiKey;
    }
}
