import RestService from 'service/RestService';

/**
 * Service class that provides static methods for API calls regarding RFP auctions
 */
export default class RFPAuctionApiService {


    /**
     * Lists RFP auctions
     * 
     * @param {object} params id of the RFP auction
     * @returns {Promise}
     */
    static list(params) {
      return RestService.instance().get('auction/rfp', params);
    }

    /**
     * Gets the RFP Auction with the provided id
     * 
     * @param {string} id id of the RFP auction
     * @returns {Promise}
     */
    static get(id) {
        return RestService.instance().get(`auction/rfp/${ id }`);
    }

    /**
     * Fetches the RFP access details for the user making the request.
     * 
     * @param {string} rfpId id of the RFP 
     * @returns {Promise}
     */
    static getAccessDetails(rfpId) {
        return RestService.instance().get(`auction/rfp/${ rfpId }/access-details`);
    }

    /**
     * Gets the URL for downloading the RFP bid template.
     * 
     * @param {string} rfpId id of the RFP
     * @returns {string}
     */
    static getDownloadBidTemplateURL(rfpId) {
        return `auction/rfp/${ rfpId }/bid/template`;
    }

    /**
     * Gets lane on RFP Auction
     * 
     * @param {string} rfpId id of the RFP auction
     * @param {string} laneId id of lane
     * @returns {Promise}
     */
    static getLane(rfpId, laneId) {
      return RestService.instance().get(`auction/rfp/${ rfpId }/lane/${ laneId }`);
    }

    /**
     * Gets bids for lane on RFP Auction
     * 
     * @param {string} rfpId id of the RFP auction
     * @param {string} laneId id of lane
     * @param {string} pageSize number of bids to be fetched
     * @param {string} pageNumber number of page from which bids are being fetched
     * @returns {Promise}
     */
    static getBids(rfpId, laneId, pageSize, pageNumber) {
      return RestService.instance().get(`auction/rfp/${ rfpId }/lane/${ laneId }/bid`,{ pageSize, pageNumber });
    }

    /**
     * Gets candidates on RFP Auction
     * 
     * @param {string} rfpId id of the RFP auction
     * @param {string} search optional search string 
     * @param {number} pageSize number of candidates to be fetched
     * @param {number} pageNumber number of page from which candidates are being fetched
     * @returns {Promise}
     */
    static getCandidates(rfpId, search, pageNumber, pageSize) {
      return  RestService.instance().get(`auction/rfp/${ rfpId }/candidate`, { search, pageNumber, pageSize });
    }

    /**
     * Removes candidate from rfp auction
     * 
     * @param {string} rfpId id of the RFP auction
     * @param {string} candidateId id of candidate to be removed
     * @returns {Promise}
     */
    static removeCandidate(rfpId, candidateId) {
      return RestService.instance().delete(`auction/rfp/${ rfpId }/candidate/${ candidateId }`);
    }

    /**
     * Delete RFP with forwarded id
     * 
     * @param {string} rfpId id of the RFP auction
     * @returns {Promise}
     */
    static deleteRFP(rfpId) {
      return RestService.instance().delete(`auction/rfp/${ rfpId }`);
    }

    /**
     * Complete RFP with forwarded id
     * 
     * @param {string} rfpId id of the RFP auction
     * @returns {Promise}
     */
    static completeRFP(rfpId) {
      return RestService.instance().post(`auction/rfp/${ rfpId }/complete`);
    }
}
