import { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { RFP_AUCTION_STATUSES, ONGOING_RFP_AUCTION_STATUSES } from 'common/constants';
import ProgressBar from 'component/ProgressBar';
import TitledCardComponent from 'component/card/TitledCardComponent';
import RFPAuctionStatus from 'component/rfp/RFPAuctionStatus';
import RFPUtils from 'utils/RFPUtils';
import DateUtils from 'utils/DateUtils';

import './RFPRoundOverviewCard.scss';

export default class RFPRoundOverviewCard extends Component {

    static propTypes = {
        rfp: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
    };

    _getRoundDetails() {
        const currentRound = RFPUtils.latestRound(this.props.rfp);

        let biddingOpens = '-';
        let biddingCloses = '-';

        if (ONGOING_RFP_AUCTION_STATUSES.includes(this.props.rfp.status)) {
            biddingOpens = DateUtils.format(currentRound.created);
            biddingCloses = DateUtils.format(currentRound.deadline);
        }

        return [biddingOpens, biddingCloses, currentRound];
    }

    render() {
        const [biddingOpens, biddingCloses, round] = this._getRoundDetails();
        const duration = moment(round.deadline).diff(moment(round.created));
        const timePassed = moment().diff(moment(round.created));
        
        return (
            <div className="rfp-round-overview-container">
                <div className="component rfp-round-overview-card">
                    <div className='rfp-round-overview-information'>
                        <TitledCardComponent
                            id="rfp-round-overview-status"
                            type="small"
                            title="ROUND OVERVIEW"
                            className="rfp-round-overview-information-field"
                        >
                            <RFPAuctionStatus id="rfp-header-status"
                                              status={ this.props.rfp.status }
                                              withTitle = { false } 
                                              round={ round } />
                        </TitledCardComponent>
                        { RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status && 
                            <TitledCardComponent
                                id="rfp-round-overview-bidding-opens"
                                type="small"
                                title="BIDDING OPENS"
                                className="rfp-round-overview-information-field"
                            > 
                                <strong><b>{ biddingOpens }</b></strong>
                            </TitledCardComponent>
                        }
                        <TitledCardComponent
                            id="rfp-round-overview-bidding-closes"
                            type="small"
                            title={ RFP_AUCTION_STATUSES.OFFERING_TO_CARRIERS === this.props.rfp.status ? 'BIDDING CLOSES' : 'BIDDING CLOSED' }
                            className="rfp-round-overview-information-field"
                        >
                            <strong><b>{ biddingCloses }</b></strong>
                        </TitledCardComponent>
                    </div>    
                </div>
               { RFP_AUCTION_STATUSES.IN_REVIEW !== this.props.rfp.status && <ProgressBar currentValue={ timePassed } totalValue={ duration } staticColor={ false } /> }
            </div>
        );
    }
}
