import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarStar as calendarIcon } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle as infoIcon } from '@fortawesome/pro-solid-svg-icons';

import Dropdown from 'component/Dropdown';
import HolidayApiService from 'service/api/HolidayApiServise';
import DateUtils from 'utils/DateUtils';

import './HolidayNotice.scss';

//Threshold represents how much time in advance we would like to look for holiday.
//Format has to be PnDTnHnMn.nS where nD represents n number of days, 
//nH n number of hours, nM n number of minutes and nS stands for n number of seconds.
const THRESHOLD = 'P2DT0H0M0.0S';
export default class HolidayNotice extends Component {

    constructor(props) {
        super(props);

        this._openHolidayInfoPopover = this._openHolidayInfoPopover.bind(this);
        this._closeHolidayInfoPopover = this._closeHolidayInfoPopover.bind(this);
    }

    state = {
        showHolidayInfo: false,
        holiday: null
    }

    componentDidMount() {
        HolidayApiService.getUpcoming(THRESHOLD).then(holiday => this.setState({ holiday }))
            .catch(error => console.error(error.message));
    }

    _openHolidayInfoPopover(event) {
        this.setState({ showHolidayInfo: true });
        event.stopPropagation();
    }

    _closeHolidayInfoPopover() {
        this.setState({ showHolidayInfo: false });
    }

    render() {
        const { holiday } = this.state;

        if (holiday) {
            const dateBegin = DateUtils.formatDate(holiday.start);
            const dateEnd = DateUtils.formatDate(holiday.end);
            let dates;

            if (holiday.duration >= 3) {
                dates = <>starting from  <b>{ dateBegin }</b> to <b>{ dateEnd }</b></>;
            } else {
                dates = dateBegin !== dateEnd ? <>on <b>{ dateBegin }</b> and <b> { dateEnd }</b></> : <>on <b>{ dateBegin }</b></>;
            }

            return (
                <Dropdown 
                    id="holiday-info-dropdown"
                    direction="bottom-left"
                    centerArrowOnTrigger={ false }
                    show={ this.state.showHolidayInfo }
                    onClose={ this._closeHolidayInfoPopover }
                    trigger={
                        <a href="#holiday-info-dropdown" className="holiday-notice" onClick={ this._openHolidayInfoPopover }>
                            <FontAwesomeIcon icon={ calendarIcon } className="calendar-icon" />
                            <small className="holiday-break">Holiday Break!</small>
                            <FontAwesomeIcon icon={ infoIcon } className={ `info-icon ${ this.state.showHolidayInfo ? 'active' : '' }` } />
                        </a>
                    }
                >
                    <div className="holiday-popover-content">
                        <p className="holiday-popover-label"><b>Holiday and load tendering break!</b></p>
                        <p className="holiday-popover-message">
                            In observance of { holiday.name } <b>all load tendering</b> will be paused { dates }.
                            <br /><br />
                            During this time, <b>current offers will not expire</b> and <b>no new offers will be sent through.</b>&nbsp;
                            Please reach out to ShipEX Logistics with any questions.
                        </p>
                    </div>
                </Dropdown> 
            );
        }

        return <></>;
    }
}
