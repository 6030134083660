import RestService from 'service/RestService';

/**
 * Service class that provides static methods for API calls regarding survey
 */
export default class SurveyApiService {

   /**
    * Fetches active survey for candidate with values for shown and completed field
    * 
    * @returns {Promise}
    */
   static getActive() {
      return RestService.instance().get('survey');
   }

   /**
    * Updates shown field after carrier has seen the survey for the first time
    * 
    * @param {string} surveyId id of of the survey
    * @returns {Promise}
    */
   static updateShownField(surveyId) {
      return RestService.instance().put(`survey/${ surveyId }/shown`);
   }

   /**
    * Completes user survey
    *
    * @param {string} id id of the survey
    * @param {Object} data data containing user answers
    * @returns {Promise}
    */
   static submitAnswers(id, data) {
      return RestService.instance().post(`survey/${ id }/complete`, data);
   }
}
