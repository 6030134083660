import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faColumns as columnIconSolid,
    faFile as documentIconSolid,
    faCloudUploadAlt as upload,
    faFileInvoiceDollar as paymentIconSolid,
    faUser as membersIconSolid,
    faTasksAlt as activityIconSolid,
    faExclamationTriangle as warningIcon
 } from '@fortawesome/pro-solid-svg-icons';
import {
    faColumns as columnIconLight,
    faFile as documentIconLight,
    faFileInvoiceDollar as paymentIconLight,
    faUser as membersIconLight,
    faTasksAlt as activityIconLight
 } from '@fortawesome/pro-light-svg-icons';
import { faLongArrowLeft as backIcon } from '@fortawesome/pro-regular-svg-icons';

import { FORM_STATUSES } from 'common/constants';
import WSComponent from 'component/WSComponent';
import CanAccess from 'component/CanAccess';
import Button from 'component/Button';
import UploadDocumentPopup from 'component/UploadFilePopup';
import Tooltip from 'component/Tooltip';
import MembersView from 'component/carrier/MembersView';
import CarrierActivityLog from 'component/carrier/CarrierActivityLog';
import PaymentDetailsList from 'component/carrier/PaymentDetailsList';
import DocumentsList from 'component/DocumentsList'
import ContentCard from 'component/card/ContentCard';
import ProfileInformation from 'component/carrier/ProfileInformation';
import Breadcrumbs from 'component/navigation/Breadcrumbs';
import NavigableTabs from 'component/navigation/NavigableTabs';
import Tab from 'component/navigation/Tab';
import CustomSelect from 'component/form/CustomSelect';
import WebSocketService from 'service/WebSocketService';
import AuthorizationService from 'service/AuthorizationService';
import CarrierApiService from 'service/api/CarrierApiService';
import DocumentUtils from 'utils/DocumentUtils';
import StringUtils from 'utils/StringUtils';
import ObjectUtils from 'utils/ObjectUtils';

import './CarrierProfile.scss';

export default class CarrierProfile extends WSComponent {

    static propTypes = {
        account: PropTypes.object,
        ws: PropTypes.instanceOf(WebSocketService)
    }

    static defaultProps = {
        account: null,
        ws: WebSocketService.instance()
    }

    constructor(props) {
        super(props);

        this.subscriptions = [
            {
                topic: '/user/queue/documents/created',
                handler: () => this._handleNotification()

            },
            {
                topic: `/user/queue/documents/updated`,
                handler: () => this._handleNotification()
            }
        ];

        this.state = {
            carrier: null,
            defaultEmail: '/',
            showUploadPopup: false,
            uploadStatus: null,
            documentTypes: [],
            selectedTypes: [],
            invalidDocuments: false
        };

        this._onUploadDocument = this._onUploadDocument.bind(this);
        this._onTypeSelectionChanged = this._onTypeSelectionChanged.bind(this);
        this._openUploadPopup = this._openUploadPopup.bind(this);
        this._closeUploadPopup = this._closeUploadPopup.bind(this);
        this._setDocumentStatus = this._setDocumentStatus.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        const carrierId = this._getCarrierId();

        if (carrierId) {
            this._fetchCarrierInfo(carrierId);
        }

        this._fetchDocumentTypes();
    }

    componentDidUpdate(prevProps) {
        if (!ObjectUtils.equal(this.props.match.params, prevProps.match.params)) {
            const carrierId = this._getCarrierId();

            if (carrierId) {
                this._fetchCarrierInfo(carrierId);
            }

            this._fetchDocumentTypes();
        }
    }

    _handleNotification() {
        const carrierId = this._getCarrierId();

        if (carrierId) {
            this._fetchCarrierInfo(carrierId);
        }
    }

    _getCarrierId() {
        return (this.props.match.params || {}).id;
    }

    _fetchCarrierInfo(id) {
        if (id) {
            CarrierApiService.get(id)
                .then(carrier => this.setState({ carrier }))
                .catch(() => this.props.history.replace("/404"));
        }
    }

    async _fetchDocumentTypes() {
        const documentTypes = await DocumentUtils.fetchDocumentTypes(false);
        this.setState({
            documentTypes
        });
    }

    _onUploadDocument(file) {
        this.setState({ uploadStatus: FORM_STATUSES.LOADING });

        const carrierId = this._getUserId();
        const documentTypes = this.state.documentTypes
            .filter(type => this.state.selectedTypes.indexOf(type.name) !== -1)
            .map(type => type.businessId);
        
        DocumentUtils.uploadDocument(documentTypes, file, carrierId, this.props.account)
            .then(() => {
                this.setState({ uploadStatus: FORM_STATUSES.SUCCESS });
            })
            .catch(() => this.setState({ uploadStatus: FORM_STATUSES.ERROR }));
    }

    /**
     * Handles the selection and deselection of document types from the custom select.
     * 
     * @param {string} value
     * @param {Object} option
     */
    _onTypeSelectionChanged(value) {
        this.setState({ selectedTypes: value });
    }

    _openUploadPopup() {
        this.setState({ showUploadPopup: true });
    }

    _closeUploadPopup() {
        this.setState({ showUploadPopup: false, uploadStatus: null, selectedTypes: [] });
    }

    _setDocumentStatus(invalidDocuments) {
        this.setState({ invalidDocuments });
    }
    
    _formUploadDocumentPopup() {
        const labels = {
            title: "Add New Document",
            uploadSubtitle: "Upload Document",
            buttonText: "Add Document"
        };

        const trigger = (
           <Button type="primary"
                   size="small"
                   leftIcon={ upload }
                   onClick={ this._openUploadPopup }
            >
                Add a New Document
            </Button>
        );

        const typeSelect = (
            <div className="popup-section">
                <br/>
                <small className="subheading">
                    Document Types
                </small>
                <CustomSelect
                    multiple={ true }
                    values={ this.state.documentTypes } 
                    selectedValue={ this.state.selectedTypes }
                    onSelect={ this._onTypeSelectionChanged }
                    fieldName="name"
                />
            </div>
        );

        return (
            <CanAccess
                action={ ["user-documents:write", "user-documents:write:self"] }
                yes={
                    <UploadDocumentPopup
                        show={ this.state.showUploadPopup }
                        labels={ labels }
                        trigger={ trigger }
                        children={ typeSelect }
                        uploadStatus={ this.state.uploadStatus }
                        addDisabled={ this.state.selectedTypes.length === 0 }
                        onUploadFile={ this._onUploadDocument }
                        onClose={ this._closeUploadPopup }
                    />
                }
            />
        );
    }

    _formBreadcrumbs() {
        if (this.props.account.carrierId === this._getCarrierId()) {
            return (
                <Breadcrumbs 
                    crumbs={[
                        { path: `/user/${ this.props.account.internalId }`, name: 'My Profile', backCrumb: false },
                        { path: '', name: this.state.carrier.name, backCrumb: false }
                    ]}
                />
            );
        } else if (AuthorizationService.instance().canUserAccess(this.props.account, 'user:read:all')) {
            return (
                <Breadcrumbs 
                    crumbs={[
                        { path: '/users', name: 'Users Overview', backCrumb: false },
                        { path: '', name: this.state.carrier.name, backCrumb: false }
                    ]}
                />
            );
        }

        return <></>;
    }

    render() {
        if (this.state.carrier) {
            const { carrier, documentTypes } = this.state;

            const initials = StringUtils.firstTwoInitials(carrier.name);

            const invalid = !carrier.active || DocumentUtils.hasInvalidDocuments(carrier.documents, documentTypes);

            let warning = <></>;
            if (!carrier.active) {
                warning = (
                    <div className="account-warning-icon">
                        <FontAwesomeIcon icon={ warningIcon } />
                        <Tooltip direction="bottom">Terminated carrier</Tooltip>
                    </div>
                );
            } else if (invalid) {
                warning = (
                    <div className="account-warning-icon">
                        <FontAwesomeIcon icon={ warningIcon } />
                        <Tooltip direction="bottom">There are missing and/or expired documents.</Tooltip>
                    </div>
                );
            }

            let activityTab;
            if (AuthorizationService.instance().canUserAccess(this.props.account, 'activity:read')) {
                activityTab = (
                    <Tab id="activity-log" title="Activity Log" activeIcon={ activityIconSolid } inactiveIcon={ activityIconLight }>
                        <CarrierActivityLog carrier={ carrier } />
                    </Tab> 
                );
            }

            return (
                <div className="page carrier-profile-page">
                    { this._formBreadcrumbs() }
                    <div className="component heading-card">
                        <div className={ `carrier-name-icon ${ invalid ? 'invalid-docs' : '' }` }>
                            <div className="initials" title={ initials }>
                                { initials }
                            </div>
                            { warning }
                        </div>
                        <div className="carrier-name">
                            <div className="label">Carrier</div>
                            <div className="name">{ carrier.name }</div>
                        </div>
                        { this.props.account.carrierId === this._getCarrierId() &&
                            <Link to={ `/user/${ this.props.account.internalId }` } className="back-option">
                                <FontAwesomeIcon icon={ backIcon } className="back-icon" />
                                <small className="back-label"><b> Back to My Profile</b></small> 
                            </Link>
                        }    
                    </div>
                    <div className="profile-tabs">
                        <NavigableTabs url={ this.props.match.url } colorTheme="light" absolute={ true }>
                            <Tab default id="overview" title="Overview" activeIcon={ columnIconSolid } inactiveIcon={ columnIconLight } >
                                <ProfileInformation carrier={ carrier } />
                            </Tab>
                            <Tab id="documents" title="Documents" activeIcon={ documentIconSolid } inactiveIcon={ documentIconLight } >
                                <div className="document-card">
                                    <ContentCard 
                                        heading="Documents"
                                        isExpandable={ false } 
                                        actions={ this._formUploadDocumentPopup() }
                                        className="documents-list"
                                    >
                                        <DocumentsList
                                            documents={ carrier.documents }
                                            account={ this.props.account }
                                        />
                                    </ContentCard>
                                </div>
                            </Tab>
                            <Tab id="payments" title="Payments" activeIcon={ paymentIconSolid } inactiveIcon={ paymentIconLight }>
                                <PaymentDetailsList carrierId={ carrier.id } />
                            </Tab>
                            <Tab id="members" title="Members" activeIcon={ membersIconSolid } inactiveIcon={ membersIconLight } >
                                <MembersView carrier={ carrier } />
                            </Tab>
                            { activityTab }
                        </NavigableTabs>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="profile-not-found">
                    <h5 className="heading">
                        No Profile Information
                    </h5>
                    <p>
                        There is no profile information to display for this carrier.
                    </p>
                </div>
            );
        }
    }
}
