import { TENDER_CANDIDATE_STATUS } from 'common/constants';
import RestService from 'service/RestService';

/**
 * Service class that provides static methods for API calls regarding tender candidates/dedicated carriers
 */
export default class TenderCandidateApiService {

    /**
     * Fetches all tender candidates on the routing guide lane.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {number} pageNumber number of the page to fetch
     * @param {number} pageSize size of the page to fetch
     * @returns {Promise}
     */
    static listAll(routingGuideId, routingGuideLaneId, pageNumber, pageSize) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates`, { pageNumber, pageSize });
    }

    /**
     * Fetches active tender candidates on the routing guide lane.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {number} pageNumber number of the page to fetch
     * @param {number} pageSize size of the page to fetch
     * @returns {Promise}
     */
    static listActive(routingGuideId, routingGuideLaneId, pageNumber, pageSize) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/active`, { pageNumber, pageSize });
    }

    /**
     * Fetches historic records for tender candidates on the routing guide lane.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {number} pageNumber number of the page to fetch
     * @param {number} pageSize size of the page to fetch
     * @returns {Promise}
     */
    static listHistoric(routingGuideId, routingGuideLaneId, pageNumber, pageSize) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/history`, { pageNumber, pageSize });
    }

    /**
     * Fetches the maximum position occupied by any ACCEPTED or PENDING tender candidate on the routing guide lane.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @returns {Promise}
     */
    static getMaxPosition(routingGuideId, routingGuideLaneId) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/active/max-position`);
    }

    /**
     * Fetches the number of tender candidates on the routing guide lane.
     * 
     * @param {string} routingGuideId id og the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @returns {Promise}
     */
    static getCount(routingGuideId, routingGuideLaneId) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/count`);
    }

    /**
     * Fetches the relevant tender candidate offer on the routing guide lane
     * for the user making the request.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @returns {Promise}
     */
    static getRelevantOfferForUser(routingGuideId, routingGuideLaneId) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/relevant`);
    }

    /**
     * Fetches the number of pending offers on a routing guide for the user making the request.
     * 
     * @param {string} routingGuideId 
     * @returns {Promise}
     */
    static getNumberOfPendingOffers(routingGuideId) {
        return RestService.instance()
            .get(`routing-guide/${ routingGuideId }/tender-candidates/count`, { status: TENDER_CANDIDATE_STATUS.PENDING.value });
    }

    /**
     * Performs an API request to create a new tender candidate 
     * on the routing guide lane with the id specified by the first parameter.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {Object} candidate candidate to create
     * @returns {Promise}
     */
    static create(routingGuideId, routingGuideLaneId, candidate) {
        return RestService.instance()
            .post(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates`, candidate);
    }

    /**
     * Performs an API request to update the tender candidate.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {string} id id of the tender candidate to update 
     * @param {Object} candidate updated candidate information
     * @returns {Promise}
     */
    static update(routingGuideId, routingGuideLaneId, id, candidate) {
        return RestService.instance()
            .put(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/${ id }`, candidate);
    }

    /**
     * Performs an API request to delete the tender candidate.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {string} id id of the tender candidate
     * @returns {Promise}
     */
    static remove(routingGuideId, routingGuideLaneId, id) {
        return RestService.instance()
            .delete(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/${ id }`);
    }

    /**
     * Performs an API request to accept the tender candidate offer.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {string} id id of the tender candidate
     * @returns {Promise}
     */
    static accept(routingGuideId, routingGuideLaneId, id) {
        return RestService.instance()
            .post(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/${ id }/accept`);
    }

     /**
     * Performs an API request to reject the tender candidate offer.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @param {string} routingGuideLaneId id of the routing guide lane
     * @param {string} id id of the tender candidate
     * @returns {Promise}
     */
    static reject(routingGuideId, routingGuideLaneId, id) {
        return RestService.instance()
            .post(`routing-guide/${ routingGuideId }/routing-guide-lanes/${ routingGuideLaneId }/tender-candidates/${ id }/reject`);
    }

    /**
     * Performs an API request to accept all the tender candidate offers on a routing guide for the user making the request.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @returns {Promise}
     */
    static acceptAll(routingGuideId) {
        return RestService.instance()
            .post(`routing-guide/${ routingGuideId }/tender-candidates/accept-all`);
    }

    /**
     * Performs an API request to reject all the tender candidate offers on a routing guide for the user making the request.
     * 
     * @param {string} routingGuideId id of the routing guide
     * @returns {Promise}
     */
    static rejectAll(routingGuideId) {
        return RestService.instance()
            .post(`routing-guide/${ routingGuideId }/tender-candidates/reject-all`);
    }
}
